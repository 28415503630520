export const _blanks = '__BLANKS__';

export type SalesforceLoginHistoryItem = {
  sfId: string;
  crmOrgId: string;
  sfUserId: string;
  apiType: string;
  apiVersion: string;
  application: string;
  browser: string;
  clientVersion: string;
  countryIso: string;
  loginUrl: string;
  platform: string;
  sourceIp: string;
  status: string;
  loginTime: Date;
  loginType: string;
  httpMethod: string;
  sfUsername: string;
  country: string;
};

export type SalesforceLoginHistoryFilter = {
  country: { id: string; name: string }[];
  users: { id: string; name: string }[];
  loginType?: string[];
  loginUrl?: string[];
  application?: string[];
  status: string[];
  crmOrgs: string[];
};

export type LoginHistorySortByKeys =
  | 'sourceIp'
  | 'countryIso'
  | 'loginType'
  | 'status'
  | 'browser'
  | 'platform'
  | 'application'
  | 'clientVersion'
  | 'apiType'
  | 'apiVersion'
  | 'loginUrl'
  | 'loginTime';

export type SalesforceLoginHistoryFiltersPayload = {
  countryIso?: string[];
  sfUserId?: string[];
  loginType?: string[];
  loginUrl?: string[];
  application?: string[];
  status?: string[];
  sortBy: LoginHistorySortByKeys;
  sortKey: 'ASC' | 'DESC';
  withFilters: boolean;
  endTime?: string;
  startTime?: string;
};

export type SalesforceLoginHistoryDto = {
  loginHistoryList: SalesforceLoginHistoryItem[];
  filters?: SalesforceLoginHistoryFilter;
};
