import { useDispatch, useSelector } from 'react-redux';
import { selectUserInfoData, setUserInfo } from '../../../reducers/userInfoReducer';
import { AccountType } from '@server/account-type';
import { useCallback } from 'react';
import { AI_QUESTIONS_LIMIT } from '@server/homepage';
import usePaywall from '../../dashboard/paywall/usePaywall';

export const useLimitMessageCount = () => {
  const { isPaywallActive } = usePaywall();
  const userInfo = useSelector(selectUserInfoData);
  const dispatch = useDispatch();

  const isFreeUser = userInfo?.account?.type === AccountType.Free && isPaywallActive;
  const messagesCount =
    userInfo?.preferences?.freeAiQuestionsCount === undefined
      ? AI_QUESTIONS_LIMIT
      : userInfo?.preferences?.freeAiQuestionsCount;
  const hasMoreMessagesLeft = isFreeUser && messagesCount !== 0;
  const keepPromptsOpen = isFreeUser;

  const updateMessageCount = useCallback(() => {
    if (!isFreeUser) {
      return;
    }

    const preferences = {
      ...userInfo?.preferences,
      freeAiQuestionsCount:
        (userInfo?.preferences?.freeAiQuestionsCount
          ? userInfo?.preferences?.freeAiQuestionsCount
          : AI_QUESTIONS_LIMIT) - 1,
    };
    dispatch(setUserInfo({ ...userInfo, preferences }));
  }, [userInfo, dispatch, isFreeUser]);

  return {
    isFreeUser,
    messagesLeftCount: messagesCount,
    keepPromptsOpen,
    hasMoreMessagesLeft,
    updateMessageCount,
  };
};
