import { Box } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';
import { memo } from 'react';
import { NodeProps } from '@xyflow/react';
import { EditableLabel } from '../../common/EditableLabel';
import { getObjectTypeColor } from '../helpers/getObjectTypeColor';

import { BaseGhostNode } from './BaseGhostNode';
import { RFNodeEditRegularNode } from '../canvas-types/nodeTypesData';

export const EditableNode = memo(
  ({
    id,
    data: { onEditNameConfirm, onEditNameCancel, editNameValidateClbk, metadata },
  }: NodeProps<RFNodeEditRegularNode>) => {
    const { objectType, isInGroupMouseMoveMode, parentId } = metadata;

    const { step: backgroundColor, connection: handleColor } = getObjectTypeColor(
      objectType as ObjectTypeValues,
    );

    return (
      <BaseGhostNode
        handleColor={handleColor}
        backgroundColor={backgroundColor}
        sx={{
          justifyContent: 'space-between',
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box>
          {!isInGroupMouseMoveMode && (
            <EditableLabel
              defaultIsEditing
              value={''}
              onValueConfirm={(value) => {
                if (!value.length) {
                  onEditNameCancel?.(id, parentId, metadata);
                } else {
                  onEditNameConfirm?.(id, value, metadata);
                }
              }}
              maxCharLength={40}
              height={30}
              padding={'8px'}
              placeholder={'New Value'}
              inputSx={{
                fontSize: '16px',
                fontWeight: 600,
                color: colors.grey[900],
                maxWidth: '220px',

                borderRadius: '4px',

                minWidth: '100px',

                '&.editing': {
                  borderColor: colors.grey[500],
                },

                '&.editing.empty::before': {
                  content: '"•"',
                  position: 'absolute',
                  fontWeight: 600,
                  fontSize: '14px',
                  left: '85px',
                  display: 'inline-block',
                  color: colors.blush[500],
                },
              }}
              errorSx={{
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '15px',
                marginTop: '20px',
              }}
              validateClbk={editNameValidateClbk(parentId)}
              onCancel={(isConfirmed) => onEditNameCancel?.(id, parentId, metadata, isConfirmed)}
            />
          )}
        </Box>
      </BaseGhostNode>
    );
  },
);
