import { styled } from '@mui/material/styles';
import { Table, TableBody, TableHead } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';

export const StyledTable__deprecated = styled(Table)({
  backgroundColor: colors.white,
  borderSpacing: 0,
  borderRadius: '6px',
  tableLayout: 'fixed',
});

export const StyledTable = styled(Table)({
  backgroundColor: colors.white,
  borderSpacing: 0,
  tableLayout: 'auto',
});

export const StyledTableHead__deprecated = styled(TableHead)({
  '.MuiTableCell-head': {
    lineHeight: '15px',
    minWidth: 'auto',
    minHeight: '49px',
    color: colors.grey[900],
    borderBottom: `1px solid ${colors.grey[300]}`,
  },
});

export const StyledTableHead = styled(TableHead)({
  '.MuiTableCell-head': {
    background: colors.grey[100],
    borderBottom: 'none',
  },
});

export const StyledTableBody = styled(TableBody)({
  '.MuiTableRow-root': {
    '&.active': {
      backgroundColor: colors.storm[70],
    },
    '&:hover': {
      backgroundColor: colors.grey[150],
    },
    '.MuiTableCell-root': {
      borderBottom: `1px solid ${colors.grey[120]}`,
    },
    '&:last-of-type .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
});

export const StyledTableBody__deprecated = styled(TableBody)({
  '.MuiTableRow-root': {
    '&.active': {
      backgroundColor: colors.storm[70],
    },
    '&:hover': {
      backgroundColor: colors.grey[100],
    },
    '.MuiTableCell-root': {
      borderBottom: `1px solid ${colors.grey[120]}`,
    },
    '&:last-of-type .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
});
