import { Box, InputBase, SxProps, Theme } from '@mui/material';
import jsep from 'jsep';
import { useCallback, useEffect, useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { RuleBuilderCallExpression } from './RuleBuilderCallExpression';
import {
  constructLogicString,
  extractIdentifierInCallExpressions,
  getStrByLength,
} from './helpers';
import StyledTooltip from '../StyledTooltip';
import { Typography, colors, Button } from '@sweep-io/sweep-design';
import {
  RuleBuilderData,
  RuleBuilderEntryConstrain,
  RuleBuilderRowComponentProps,
} from './rule-builder-types';
import { Edit } from '@sweep-io/sweep-design/dist/icons';

const errorMessages = {
  strict: 'Please make sure you only use numbers and operators (AND / OR).',
  literal: 'You are referencing a rule that is not defined.',
  operator: 'You can only have one operator within a group (AND / OR).',
  duplicate: 'You can only reference a rule once.',
  consecutive: 'Please add an operator between every rule / group',
  structure: 'Please make sure there is always an operator between every two rules.',
};

export enum ParserTypes {
  SequenceExpression = 'SequenceExpression',
  CallExpression = 'CallExpression',
  Identifier = 'Identifier',
  Literal = 'Literal',
}

export interface RuleBuilderProps<T extends RuleBuilderEntryConstrain = any> {
  ruleBuilderData?: RuleBuilderData<T>;
  onChange: (ruleBuilderData: RuleBuilderData<T>) => any;
  readonly?: boolean;
  sx?: SxProps<Theme>;
  newRowProvider: () => T;
  RowComponent: React.ComponentType<RuleBuilderRowComponentProps<T>>;
  displayErrors?: boolean;
  errorIds: string[];
  headerRowComponent?: JSX.Element | string;
}

export function RuleBuilder<T extends RuleBuilderEntryConstrain = any>({
  ruleBuilderData,
  onChange,
  readonly,
  sx,
  newRowProvider,
  RowComponent,
  errorIds,
  displayErrors,
  headerRowComponent,
}: RuleBuilderProps<T>) {
  const hasErrors = Boolean(errorIds.length);

  const [displayErrorPopover, setDisplayErrorPopover] = useState(false);

  const [hasLogicStringError, setLogicStringError] = useState(false);

  const [innerData, setInnerData] = useState<RuleBuilderData<T>>(
    ruleBuilderData || {
      entries: [],
      logicString: '',
    },
  );

  useEffect(() => {
    if (ruleBuilderData) {
      setDisplayErrorPopover(hasErrors);
    }
  }, [hasErrors, ruleBuilderData]);

  const [parsedCriteria, setParsedCriteria] = useState<any>(
    extractIdentifierInCallExpressions(getStrByLength(innerData.logicString)),
  );

  const [manualLogicString, setManualLogicString] = useState(innerData.logicString);
  const [isStructuredLayout, toggleIsStructuredLayout] = useState(true);

  /**
   * @param {string[]} invalidCriterionIds Contains invalid criterion ids.
   * @param {string} hasLogicStringError Contains logic string error message.
   * @param {boolean} highlightInvalidState Passed to rule builder allows to highlight invalid rows
   */

  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorAnimation, setShowErrorAnimation] = useState(hasErrors);

  const [currentPopupErrorId, setCurrentPopupErrorId] = useState<string | undefined>();

  const nextPopupErrorId = useCallback(() => {
    if (errorIds.length === 0) {
      setCurrentPopupErrorId(undefined);
    }

    if (!currentPopupErrorId) {
      setCurrentPopupErrorId(errorIds[0]);
      return;
    }
    let idx = errorIds.findIndex((id) => id === currentPopupErrorId);
    idx++;
    if (idx >= errorIds.length) {
      idx = 0;
    }
    setCurrentPopupErrorId(errorIds[idx]);
  }, [currentPopupErrorId, errorIds]);

  useEffect(() => {
    if (errorIds.length) {
      setCurrentPopupErrorId(errorIds[0]);
    } else {
      setCurrentPopupErrorId(undefined);
    }
  }, [errorIds]);

  const clearLogicStringErrorState = () => {
    setErrorMessage('');
    setLogicStringError(false);
  };

  const setInnerCriteriaAndTriggerChange = (newRuleBuilderCondition: RuleBuilderData<T>) => {
    setInnerData(newRuleBuilderCondition);
    setManualLogicString(newRuleBuilderCondition.logicString);
    onChange(newRuleBuilderCondition);
  };

  useEffect(() => {
    if (ruleBuilderData && !isEqual(ruleBuilderData, innerData)) {
      setInnerData(ruleBuilderData);
      setManualLogicString(ruleBuilderData.logicString);

      const strByLength = getStrByLength(ruleBuilderData.logicString);
      setParsedCriteria(extractIdentifierInCallExpressions(strByLength));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleBuilderData]);

  const handleLogicManualChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setManualLogicString(event.target.value);
    try {
      jsep(manualLogicString);
    } catch (error) {}
    setLogicStringError(false);
  };

  const handleSaveManualLogicString = () => {
    let tempStr: any;
    try {
      const basicParse = getStrByLength(manualLogicString);
      tempStr = extractIdentifierInCallExpressions(basicParse);
      const splitStr = manualLogicString
        .toLocaleUpperCase()
        .split('(')
        .join(' ')
        .split(')')
        .join(' ')
        .split(' ');
      const isInvalidCharsArr = (value: any) => {
        switch (value) {
          case 'AND':
            return false;
          case 'OR':
            return false;
          case '':
            return false;
          default:
            if (!isNaN(value)) {
              return false;
            }
            return value;
        }
      };
      const isInvalidChars = splitStr.filter(isInvalidCharsArr);
      if (isInvalidChars.length) {
        setErrorMessage(errorMessages.strict);
        setLogicStringError(true);
        return false;
      } else if (!isInvalidChars.length && errorMessage === errorMessages.strict) {
        clearLogicStringErrorState();
      }

      let isConsecutiveType = false;
      let lastType: string;

      const testConsecutiveType = (givenStr: any) => {
        givenStr.forEach((element: any, index: number, originArray: any) => {
          if (index > 0) {
            lastType = originArray[index - 1].type;
            if (element.type === lastType) {
              isConsecutiveType = true;
              return;
            }
            if (
              element.type === ParserTypes.SequenceExpression &&
              lastType === ParserTypes.Literal
            ) {
              isConsecutiveType = true;
              return;
            }
            if (
              (element.type === ParserTypes.SequenceExpression &&
                lastType === ParserTypes.CallExpression) ||
              (element.type === ParserTypes.CallExpression &&
                lastType === ParserTypes.SequenceExpression)
            ) {
              isConsecutiveType = true;
              return;
            }
          }
          if (element.type === ParserTypes.CallExpression) {
            testConsecutiveType(element.arguments);
          }
          if (element.type === ParserTypes.SequenceExpression) {
            testConsecutiveType(element.expressions);
          }
        });
      };
      testConsecutiveType(tempStr);

      if (isConsecutiveType) {
        setErrorMessage(errorMessages.consecutive);
        setLogicStringError(true);
        return false;
      }

      let isBadStructure = false;

      const testBadStructure = (givenStr: any) => {
        if (givenStr[0].type === ParserTypes.Identifier) {
          isBadStructure = true;
          return;
        }
        if (givenStr.type === ParserTypes.SequenceExpression) {
          if (!givenStr.expressions.find((el: any) => el.type === ParserTypes.Identifier)) {
            isBadStructure = true;
            return;
          }
        }

        givenStr.forEach((element: any) => {
          if (element.type === ParserTypes.CallExpression) {
            testBadStructure(element.arguments);
          }
          if (element.type === ParserTypes.SequenceExpression) {
            testBadStructure(element.expressions);
          }
        });
      };

      testBadStructure(tempStr);
      if (tempStr[tempStr.length - 1].type === ParserTypes.Identifier) {
        isBadStructure = true;
      }

      if (isBadStructure) {
        setErrorMessage(errorMessages.structure);
        setLogicStringError(true);
        return false;
      }

      let isSame = false;
      const findUnmatchedOperatorInGroup = (arr: any): any => {
        let tempOperator = '';
        const newParsedCriteria = [...arr];
        const resultOperator = newParsedCriteria.filter(
          (line) => line.type === ParserTypes.Identifier,
        );

        if (resultOperator.length) {
          tempOperator = resultOperator[0].name.toLocaleUpperCase();
          resultOperator.forEach((line: any) => {
            if (line.name.toLocaleUpperCase() !== tempOperator) {
              isSame = true;
              return isSame;
            }
          });
        }
        if (!isSame) {
          const restArr = newParsedCriteria.filter(
            (line) => line.type === ParserTypes.CallExpression,
          );
          if (restArr.length) {
            restArr.forEach((line: any) => {
              return findUnmatchedOperatorInGroup(line.arguments);
            });
          }

          const restArr2 = newParsedCriteria.filter(
            (line) => line.type === ParserTypes.SequenceExpression,
          );
          if (restArr2.length) {
            restArr2.forEach((line: any) => {
              return findUnmatchedOperatorInGroup(line.expressions);
            });
          }
        }
        return isSame;
      };
      const isSameResult = findUnmatchedOperatorInGroup(tempStr);
      if (isSameResult) {
        setErrorMessage(errorMessages.operator);
        setLogicStringError(true);
        return false;
      } else if (!isSameResult && errorMessage === errorMessages.operator) {
        clearLogicStringErrorState();
      }

      const literalRefArray: any[] = [];

      const extractAllLiteralsToArray = (arr: any) => {
        arr.forEach((line: any) => {
          if (line.type === ParserTypes.CallExpression) {
            return extractAllLiteralsToArray(line.arguments);
          }
          if (line.type === ParserTypes.SequenceExpression) {
            return extractAllLiteralsToArray(line.expressions);
          }
          if (line.type === ParserTypes.Literal) {
            return literalRefArray.push(line.value);
          }
        });
      };

      extractAllLiteralsToArray(tempStr);
      literalRefArray.sort();

      const hasDuplicates = (array: any) => {
        return new Set(array).size !== array.length;
      };
      if (hasDuplicates(literalRefArray)) {
        setErrorMessage(errorMessages.duplicate);
        setLogicStringError(true);
        return false;
      } else if (!hasDuplicates(literalRefArray) && errorMessage === errorMessages.duplicate) {
        clearLogicStringErrorState();
      }

      setManualLogicString(manualLogicString.toLocaleUpperCase());
      const criteriaSize = innerData.entries.length;
      const literalRefHighest = literalRefArray[literalRefArray.length - 1];
      if (literalRefHighest > criteriaSize || !literalRefHighest) {
        setErrorMessage(errorMessages.literal);
        setLogicStringError(true);
        return;
      } else if (!(literalRefHighest > criteriaSize) && errorMessage === errorMessages.literal) {
        clearLogicStringErrorState();
      }

      const arrayOfReplacement: (number | null)[] = [];
      innerData.entries.forEach((line, criteriaIndex) => {
        if (literalRefArray.includes(criteriaIndex + 1)) {
          arrayOfReplacement.push(criteriaIndex + 1);
        }
      });

      let newCritArray: T[] = [];

      const replaceValue = (arr: any, oldVal: number, newVal: number) => {
        arr.forEach((line: any) => {
          if (line.type === ParserTypes.CallExpression) {
            return replaceValue(line.arguments, oldVal, newVal);
          }
          if (line.type === ParserTypes.SequenceExpression) {
            return replaceValue(line.expressions, oldVal, newVal);
          }
          if (line.type === ParserTypes.Literal) {
            if (line.value === oldVal) {
              line.value = newVal;
              line.raw = newVal.toString();
              return line;
            }
          }
        });
      };

      newCritArray = arrayOfReplacement.map((value: number | null, index: number): any => {
        if (value) {
          replaceValue(tempStr, value, index + 1);
          return innerData.entries[value - 1];
        }
        return undefined;
      });
      const newSweepCondition: RuleBuilderData<T> = { ...innerData, entries: [] };
      // const newSweepCondition = exitCriterionModel(temp);

      newCritArray.forEach((item) => {
        newSweepCondition.entries.push(item);
      });

      setInnerCriteriaAndTriggerChange(
        convertParsedSweepConditionToLogicString(tempStr, newSweepCondition),
      );
      setParsedCriteria(extractIdentifierInCallExpressions(tempStr));
      toggleIsStructuredLayout(!isStructuredLayout);
    } catch (error: any) {
      setErrorMessage(error.message);
      setLogicStringError(true);
    }
  };

  const onErrorPause = () => {
    setShowErrorAnimation(true);
    setTimeout(() => setShowErrorAnimation(false), 1500);
  };

  const convertParsedSweepConditionToLogicString = (
    parsedCriteria: [],
    _ruleBuilderData?: RuleBuilderData<T>,
  ): RuleBuilderData<T> => {
    const ruleBuilderData: RuleBuilderData<T> = _ruleBuilderData
      ? { ..._ruleBuilderData }
      : { ...innerData };

    const newLogicString = constructLogicString(parsedCriteria);

    setManualLogicString(newLogicString);
    ruleBuilderData.logicString = newLogicString;
    return ruleBuilderData;
  };

  const isFirstItem = useMemo(
    () => ruleBuilderData?.entries && !ruleBuilderData.entries.length,
    [ruleBuilderData],
  );

  return (
    <Box
      sx={{ background: colors.grey[100], borderRadius: '4px', p: '12px 24px', ...sx }}
      data-testid="rule-builder"
    >
      {innerData.entries.length > 0 && (
        <Box
          sx={{
            textAlign: 'right',
            margin: '0px 10px 12px',
          }}
        >
          <Box
            sx={{
              display: 'inline-flex',
              fontSize: '12px',
              fontWeight: '500',
              color: colors.grey[900],
              alignItems: 'center',
            }}
            className="rule-builder-logic-preview"
          >
            {isStructuredLayout ? (
              <>
                <Typography variant="caption-bold" color={colors.grey[700]}>
                  Logic preview:
                </Typography>
                <Box
                  data-testid="rule-builder-logic-mode-button"
                  sx={{
                    marginLeft: '6px',
                    display: 'inline-flex',
                    cursor: readonly ? 'default' : 'pointer',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    !readonly && toggleIsStructuredLayout(!isStructuredLayout);
                  }}
                >
                  <Box
                    sx={{
                      fontSize: '11px',
                      fontWeight: '700',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      marginRight: '6px',
                      maxWidth: '300px',
                      whiteSpace: 'nowrap',
                    }}
                    data-testid="rule-builder-logic-preview"
                  >
                    <Typography variant="caption">{manualLogicString || 'None'}</Typography>
                  </Box>
                  {!readonly && (
                    <StyledTooltip title="Customize the logic manually">
                      <Edit />
                    </StyledTooltip>
                  )}
                </Box>
              </>
            ) : (
              <>
                <Typography variant="body">Edit Logic: </Typography>

                <Box
                  data-testid="rule-builder-logic-mode-back-button"
                  sx={{
                    fontWeight: '700',
                    fontSize: '13px',
                    lineHeight: '13px',
                    color: colors.blue[500],
                    marginLeft: '6px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    !hasLogicStringError && toggleIsStructuredLayout(!isStructuredLayout);
                  }}
                >
                  <Typography variant="body-bold">Back</Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>
      )}

      {isStructuredLayout && (
        <Box
          sx={{
            background: colors.grey[100],
            borderRadius: '4px',
            ' .rule_builder_group': {
              background: colors.grey[100],
              border: 'none',
            },
            ' .rule_builder_group .rule_builder_group': {
              background: 'hsl(229, 100%, 92%, .3)',
              border: '1px solid rgba(36, 84, 254, 0.3)',
              padding: '18px',
              borderRadius: '6px',
              marginBottom: '18px',
              '&:hover': {
                border: '1px solid rgba(36, 84, 254, 1)',
              },
            },
          }}
        >
          {!isFirstItem && headerRowComponent}
          <RuleBuilderCallExpression
            nextPopupErrorId={nextPopupErrorId}
            highlightInvalidState={displayErrors && hasErrors}
            readonly={readonly}
            parsedCriteria={parsedCriteria}
            ruleBuilderData={innerData}
            errorIds={errorIds}
            showErrorAnimation={showErrorAnimation}
            onErrorPause={onErrorPause}
            onLogicPartialChange={(_parsedCriteria) => {
              setParsedCriteria(_parsedCriteria);
              setInnerCriteriaAndTriggerChange(
                convertParsedSweepConditionToLogicString(_parsedCriteria),
              );
            }}
            onAddNewRule={(_parsedCriteria, _newSweepCondition) => {
              setParsedCriteria(_parsedCriteria);
              setInnerCriteriaAndTriggerChange(
                convertParsedSweepConditionToLogicString(_parsedCriteria, _newSweepCondition),
              );
            }}
            onRowChange={(index, criteria) => {
              const _newSweepCondition = { ...innerData };
              _newSweepCondition.entries[index] = criteria;
              setInnerCriteriaAndTriggerChange(_newSweepCondition);
            }}
            onDeleteLiteral={(_parsedCriteria, _newSweepCondition) => {
              const setUpdatedFalse = (arr: any[]) => {
                arr.forEach((line: any) => {
                  if (line.type === ParserTypes.CallExpression) {
                    return setUpdatedFalse(line.arguments);
                  }
                  if (line.type === ParserTypes.SequenceExpression) {
                    return setUpdatedFalse(line.expressions);
                  }
                  if (line.type === ParserTypes.Literal) {
                    line.updated = false;
                  }
                });
              };
              setUpdatedFalse(_parsedCriteria);
              setParsedCriteria(_parsedCriteria);
              const newExitCriteriaWithLogicString = convertParsedSweepConditionToLogicString(
                _parsedCriteria,
                _newSweepCondition,
              );

              setInnerCriteriaAndTriggerChange(newExitCriteriaWithLogicString);
            }}
            handleErrorPopoverClose={() => {
              setDisplayErrorPopover(false);
            }}
            displayErrorPopover={hasErrors && displayErrorPopover}
            newRowProvider={newRowProvider}
            RowComponent={RowComponent}
            currentPopupErrorId={currentPopupErrorId}
          />
        </Box>
      )}
      {!isStructuredLayout && (
        <Box
          sx={{
            background: colors.grey[100],
            borderRadius: '4px',
            padding: '23px 24px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              justifyContent: 'space-between',
              alignItems: 'center',
              background: colors.white,
              border: hasLogicStringError
                ? `1px solid ${colors.blush[600]}`
                : `1px solid ${colors.grey[300]}`,
              borderRadius: '6px',
              height: '48px',
              padding: '9px 9px 9px 18px',
              marginBottom: '24px',
              position: 'relative',
            }}
          >
            <InputBase
              sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '13px',
                textTransform: 'uppercase',
                color: colors.grey[900],
              }}
              placeholder="Customize the logic between conditions (i.e 1 AND 2)"
              fullWidth
              value={manualLogicString}
              onChange={handleLogicManualChange}
            />
            <Button
              onClick={handleSaveManualLogicString}
              size="small"
              variant="filled"
              disabled={hasLogicStringError}
            >
              Apply
            </Button>

            {hasLogicStringError && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '-18px',
                  color: colors.blush[600],
                  fontSize: '12px',
                }}
              >
                {errorMessage}
              </Box>
            )}
          </Box>
          <Box>
            {!isFirstItem && headerRowComponent}
            {innerData.entries.map((entry, idx: number) => {
              return (
                <Box key={entry.id} sx={{ marginBottom: '18px' }}>
                  <>
                    <RowComponent
                      data={entry}
                      index={idx}
                      readOnly
                      onRowChange={() => {}}
                      onRowDelete={() => {}}
                      hasError={errorIds.includes(entry.id)}
                    />
                  </>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
}
