import { AuditReportType, AuditTable } from './AuditTable';
import { InfoDialog } from '../dialogs/InfoDialog';
import { useSelector } from 'react-redux';
import { selectCustomIntegrations } from '../../../reducers/integrationsReducer';
import { Stack } from '@mui/material';
import { LogoDevIcon } from '../logo-dev-selector/LogoDevIcon';
import { Typography } from '@sweep-io/sweep-design';

interface ManageEnvDialogProps {
  onClose: () => void;
  auditType: AuditReportType;
  integrationId?: string;
}

export const AuditDialog = ({ onClose, auditType, integrationId }: ManageEnvDialogProps) => {
  const customIntegrations = useSelector(selectCustomIntegrations);
  const output = customIntegrations.find((d) => d.id === integrationId);

  const _name = (
    <Stack direction="row" spacing={1} alignItems="center" mb={2}>
      {output?.logo && <LogoDevIcon brand={output?.logo} size={24} />}
      <Typography variant="h2">{output?.name}</Typography>
      <Typography variant="h2">Integration activity</Typography>
    </Stack>
  );
  return (
    <InfoDialog
      handleClose={onClose}
      open
      showCloseButton
      titleJsx={_name}
      sx={{
        '.MuiDialogContent-root': {
          p: 3,
          pt: 2,
        },
      }}
      PaperPropsSx={{ width: '1246px' }}
      titleTypographyVariant="h2"
      closeButtonSize="small"
    >
      <AuditTable auditType={auditType} integrationId={integrationId} />
    </InfoDialog>
  );
};
