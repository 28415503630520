import { Stack } from '@mui/material';
import { ReactNode } from 'react';
import { colors, Typography } from '@sweep-io/sweep-design';

const EmptyState = ({
  image,
  line1,
  line2,
  titleLine,
  children,
  height,
}: {
  image: ReactNode;
  line1?: string;
  line2?: string;
  titleLine?: string;
  children?: ReactNode;
  height?: string | number;
}) => {
  //if there's no title, the text should be black
  const bodyColor = titleLine ? colors.grey[700] : colors.grey[900];
  return (
    <Stack gap={2} alignItems="center" height={height} justifyContent="center">
      {image}
      <Stack alignItems="center" gap={1}>
        {titleLine && <Typography variant="h3">{titleLine}</Typography>}
        {line1 && (
          <Typography variant="body" color={bodyColor}>
            {line1}
          </Typography>
        )}
        {line2 && (
          <Typography variant="body" color={bodyColor}>
            {line2}
          </Typography>
        )}
      </Stack>
      <Stack position="relative">{children}</Stack>
    </Stack>
  );
};

export default EmptyState;
