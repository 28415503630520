import { Box, Stack } from '@mui/material';
import { SearchInput } from '../common/SearchInput';
import { Button, colors, Typography } from '@sweep-io/sweep-design';
import pluralize from 'pluralize';
import { SortDisplayTypes, SweepSort } from '../common/SweepSort';
import { CompleteSetupToDeploy } from '../common/deploy-button/complete-sf-setup/CompleteSetupToDeploy';
import { AutomationsList } from './AutomationsList';
import { AutomationType } from '../../types/enums/AutomationType';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { useCallback, useState } from 'react';
import {
  AutomationSortOption,
  buildActionsMenuArray,
  SEARCH_BOX_WIDTH,
  sortAutomations,
} from './helper';
import { useCanvasAutomationFilters } from './useCanvasAutomationFilters';
import { ConfigurationCanvasFilters } from '../pages/configuration-canvas/ConfigurationCanvasFilters';
import AutomationsTable from './AutomationsTable';
import { useAutomationsContext } from './AutomationsContext';
import useConfirm from '../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import usePermission from '../common/permissions/usePermission';
import { useSelector } from 'react-redux';
import {
  selectDefaultCreationEnvironment,
  selectProductionCrmOrg,
} from '../pages/environments/environmentsReducer';
import { Commands } from '../../types/enums/Common';
import ConfirmDestructiveDialog from '../common/dialogs/ConfirmDestructiveDialog';
import useCheckManagedPackage from '../common/install-managed-package/useCheckManagedPackage';
import usePaywall from '../dashboard/paywall/usePaywall';
import { useIsSetupCompleted } from '../../hooks/useIsSetupCompleted';
import AutomationDeployPaywallBlock from '../common/deploy-button/AutomationDeployPaywallBlock';
import { InfoDialog } from '../common/dialogs/InfoDialog';
import { SlackLogsContent } from './slack/SlackLogsContent';
import { CompleteSfSetupForDeployDialog } from '../common/deploy-button/complete-sf-setup/CompleteSfSetupForDeployDialog';
import { SortOrder } from '../common/types';
import { SweepCanvasFiltersMap } from '../pages/configuration-canvas-filters/filterTypes';
import { HORIZONTAL_PADDING } from '../pages/configuration-canvas-panel/consts';
import { newSearchSx } from '../pages/canvas-pages/canvasPanelConsts';
import AutomationOrderDialog from './AutomationOrderDialog';
import { AuditReportType, AuditTable } from '../common/audit-table/AuditTable';
import { useFeatureToggle } from '../common/useFeatureToggle';
import { ViewInSalesforceButton } from './view-in-sf-button/ViewInSalesforceButton';
import { AutomationVersionHistoryDialog } from './AutomationVersionHistoryDialog';

const automationsCountTextMap = {
  [AutomationFormVariant.ASSIGNMENT_RULES]: 'Assignment',
  [AutomationFormVariant.PLAYBOOK_ALERT]: 'Alert',
  [AutomationFormVariant.DEDUP_MATCHING]: 'Automation',
  [AutomationFormVariant.AUTOMATIONS]: 'Automation',
  [AutomationFormVariant.SCHEDULED_ASSIGNMENTS]: 'Scheduled assignment',
  [AutomationFormVariant.SCHEDULED_REPORTS]: 'Scheduled report',
  [AutomationFormVariant.SCHEDULED_JOBS]: 'Scheduled job',
  [AutomationFormVariant.MARKETING_ATTRIBUTIONS]: 'Campaign attribution model',
};

const NoSearchResult = ({ clearFilters }: { clearFilters: () => void }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      mt={2}
      sx={{
        padding: `0 ${HORIZONTAL_PADDING}px`,
      }}
    >
      <Typography variant="body" color={colors.grey[800]}>
        No results found
      </Typography>
      <Button variant="flat" onClick={clearFilters}>
        Clear filters
      </Button>
    </Box>
  );
};
const PAYWALL_DIALOG_INITIAL = { isOpen: false, objectName: undefined };

interface AutomationsListWithSortProps {
  automations: AutomationStructureNew[];
  crmOrgId: string;
  closeAutomationDialog: () => void;
  onDeployAutomation: (automationItem: AutomationStructureNew, crmOrgIds: string[]) => any;
  onToggleActivation?: (
    automationJson: AutomationStructureNew,
    deployToOrgIds: string[],
    isActive: boolean,
  ) => any;
  onDeleteAutomation: (automationId: string, automationType: AutomationType) => any;
  hideSort?: boolean;
  getNewButtonWithTemplates: () => JSX.Element;
  onCreateData?: { newElement: Partial<AutomationStructureNew> };
  sortOptions?: AutomationSortOption[];
  setHoveredItem?: (automationId?: string) => any;
  filtersForExpandedMode: SweepCanvasFiltersMap;
  isExpandedMode: boolean;
  onDuplicateAutomation: (automationItem: AutomationStructureNew) => void;
  onEditAutomation: (automationItem: AutomationStructureNew) => void;
  viewInSalesforceLink?: string;
}

const AutomationsListWithSort = ({
  automations,
  crmOrgId,
  closeAutomationDialog,
  onDeployAutomation,
  onToggleActivation,
  onDuplicateAutomation,
  onEditAutomation,
  onDeleteAutomation,
  hideSort,
  getNewButtonWithTemplates,
  onCreateData,
  sortOptions,
  setHoveredItem,
  filtersForExpandedMode,
  isExpandedMode,
  viewInSalesforceLink,
}: AutomationsListWithSortProps) => {
  const { webhookActivity, automationsVersionHistory } = useFeatureToggle();
  const dataByVariant = useAutomationsContext();
  const { automationVariant, automationJson, setAutomationJson } = dataByVariant;
  const isAutomationInEdit = Object.keys(automationJson)?.length > 0;
  const [isAllowedCreate, isAllowedEdit, isAllowedDeploy, isAllowedDelete] = usePermission([
    dataByVariant.permissions.create,
    dataByVariant.permissions.edit,
    dataByVariant.permissions.deploy,
    dataByVariant.permissions.delete,
  ]);
  const productionEnvironment = useSelector(selectProductionCrmOrg);
  const defaultCreationEnvironment = useSelector(selectDefaultCreationEnvironment);
  const { openConfirm, onCancel, onConfirm } = useConfirm();
  const [search, setSearch] = useState('');
  const [automationForOrderDialog, setAutomationForOrderDialog] =
    useState<AutomationStructureNew>();
  const [automationForVersionHistoryDialog, setAutomationForVersionHistoryDialog] =
    useState<AutomationStructureNew>();

  const { filteredAutomations, clearFilters } = useCanvasAutomationFilters({
    automations,
    search,
    setSearch: (txt: string) => setSearch(txt),
  });

  //"sortBy" can be a value from the list's dropdown select, or from the one of the tables sortable columns
  const [sortBy, setSortBy] = useState(sortOptions ? sortOptions[0].value : undefined);
  const [sortOrder, setSortOrder] = useState(SortOrder.ASC); //relevant only to the table
  const selectedSortListOption = sortOptions?.find((option) => option.value === sortBy);

  const automationsToDisplay = sortAutomations({
    sortBy: sortBy,
    automations: filteredAutomations,
    selectedSortOption: selectedSortListOption ?? {
      dataAccessor: sortBy,
    }, //if "sortBy" is not one of the sort options, it means it is coming from the table, so we need to add a "dataAccessor" (for the sort logic)
    sortOrder: selectedSortListOption ? SortOrder.DESC : sortOrder,
  });

  const searchJsx = (
    <SearchInput
      TextFieldProps={{
        disabled: isAutomationInEdit,
        value: search,
        placeholder: 'Search',
        onChange: (e) => {
          setSearch(e.target.value);
        },
        fullWidth: true,
      }}
      withFixedMagnifyingGlassIcon={true}
      onClearButtonClick={() => setSearch('')}
      variant="small"
    />
  );

  const newButtonJsx = onCreateData ? (
    <Button
      disabled={isAutomationInEdit}
      startIconName="Plus"
      size="small"
      onClick={() => {
        setAutomationJson(onCreateData.newElement);
      }}
    >
      New
    </Button>
  ) : (
    getNewButtonWithTemplates()
  );

  const viewInSalesforceButtonJsx = viewInSalesforceLink ? (
    <ViewInSalesforceButton viewInSalesforceLink={viewInSalesforceLink} />
  ) : null;

  const onDeploy = useCallback(
    (automationItem: AutomationStructureNew, crmOrgIds: string[]) => {
      onDeployAutomation(automationItem, crmOrgIds);
    },
    [onDeployAutomation],
  );

  const onDelete = useCallback(
    (automationId: string, automationType: AutomationType) => {
      if (automationJson?.automationId === automationId) {
        closeAutomationDialog();
      }
      onDeleteAutomation(automationId, automationType);
    },
    [automationJson?.automationId, closeAutomationDialog, onDeleteAutomation],
  );

  const onToggle = useCallback(
    (automationJson: AutomationStructureNew, deployToOrgIds: string[], isActive: boolean) => {
      onToggleActivation && onToggleActivation(automationJson, deployToOrgIds, isActive);
    },
    [onToggleActivation],
  );

  const canDeploy = useIsSetupCompleted();

  const { getIsManagedPackagedInstalledWithRecheck } = useCheckManagedPackage();
  const { isPaywallActive } = usePaywall();

  const [paywallDialogProps, setPaywallDialogProps] = useState<{
    isOpen: boolean;
    objectName?: string;
  }>(PAYWALL_DIALOG_INITIAL);
  const [completeSfSetupDialog, setCompleteSfSetupDialog] = useState<{
    isOpen: boolean;
    crmOrgIdsToDeploy: string[];
    automationItem: AutomationStructureNew;
  }>();

  const [automationForSlackLog, setAutomationForSlackLog] = useState<AutomationStructureNew>();
  const [automationForActivityLog, setAutomationForActivityLog] =
    useState<AutomationStructureNew>();

  const deployOrShowDialog = useCallback(
    async (automationItem: AutomationStructureNew, crmOrgIds: string[]) => {
      if (isPaywallActive) {
        setPaywallDialogProps({ isOpen: true, objectName: automationItem.objectName });
        return;
      }
      getIsManagedPackagedInstalledWithRecheck({
        withPermissionsCheck: true,
      });
      if (canDeploy) {
        onDeploy(automationItem, crmOrgIds);
      } else {
        setCompleteSfSetupDialog({
          isOpen: true,
          crmOrgIdsToDeploy: crmOrgIds,
          automationItem,
        });
      }
    },
    [canDeploy, getIsManagedPackagedInstalledWithRecheck, isPaywallActive, onDeploy],
  );

  const getActionsMenuArray = useCallback(
    (automation: AutomationStructureNew) => {
      return buildActionsMenuArray({
        automation,
        isAllowedCreate,
        isAllowedEdit,
        isAllowedDeploy,
        isAllowedDelete,
        productionEnvironment,
        defaultCreationEnvironment,
        isAddIntegrationActivity: webhookActivity, // TODO: remove this after the integration activity FT is done
        isAddVersionHistory: automationsVersionHistory, // TODO: remove this after the version history FT is done
      });
    },
    [
      defaultCreationEnvironment,
      isAllowedCreate,
      isAllowedDelete,
      isAllowedDeploy,
      isAllowedEdit,
      productionEnvironment,
      webhookActivity,
      automationsVersionHistory,
    ],
  );

  const onActionMenuClick = useCallback(
    (automationItem: AutomationStructureNew) => async (actionName: string) => {
      switch (actionName) {
        case Commands.Integration_Activity:
          setAutomationForActivityLog(automationItem);
          break;
        case Commands.Slack_Activity:
          setAutomationForSlackLog(automationItem);
          break;
        case Commands.Edit:
          onEditAutomation && onEditAutomation(automationItem);
          break;
        case Commands.Duplicate:
          const newItem: AutomationStructureNew = {
            ...automationItem,
            automationId: '',
            name: 'Copy of ' + automationItem.name,
          };
          onDuplicateAutomation(newItem);
          break;
        case Commands.Delete:
          const isConfirmed = await openConfirm(
            <ConfirmDestructiveDialog
              open={true}
              onConfirm={onConfirm}
              onClose={onCancel}
              title="Are you sure you want to delete?"
              confirmText="Delete"
              cancelText="Cancel"
            >
              {(automationItem.status === 'Deployed' || automationItem?.deployedVersionId) &&
                'Deleting this automation will remove it from all environments immediately '}
            </ConfirmDestructiveDialog>,
          );
          if (isConfirmed) {
            onDelete(automationItem.automationId, automationItem.type);
          }
          break;
        case 'onToggleDefault':
          onToggle(
            automationItem,
            [defaultCreationEnvironment?.id ?? ''],
            !automationItem.isActive,
          );
          break;
        case 'onToggleBoth':
          onToggle(
            automationItem,
            [defaultCreationEnvironment?.id ?? '', productionEnvironment?.id ?? ''],
            !automationItem.isActive,
          );
          break;
        case 'onDeployDefault':
          deployOrShowDialog(automationItem, [defaultCreationEnvironment?.id ?? '']);
          break;
        case 'onDeployBoth':
          deployOrShowDialog(automationItem, [
            defaultCreationEnvironment?.id ?? '',
            productionEnvironment?.id ?? '',
          ]);
          break;
        case Commands.Order:
          setAutomationForOrderDialog(automationItem);
          break;
        case Commands.Version_History:
          setAutomationForVersionHistoryDialog(automationItem);
          break;
      }
    },
    [
      defaultCreationEnvironment?.id,
      deployOrShowDialog,
      onCancel,
      onConfirm,
      onDelete,
      onDuplicateAutomation,
      onEditAutomation,
      onToggle,
      openConfirm,
      productionEnvironment?.id,
      setAutomationForOrderDialog,
      setAutomationForVersionHistoryDialog,
    ],
  );

  const tableJsx = (
    <Stack
      className="table-jsx"
      sx={{
        gap: 2,
        height: '100%',
        padding: `0 ${HORIZONTAL_PADDING}px`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          ...newSearchSx,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: SEARCH_BOX_WIDTH }}>
            {searchJsx}
          </Box>
          <ConfigurationCanvasFilters
            filters={filtersForExpandedMode}
            automations={automations}
            staticView
            disableFitOnCanvasElements
          />
        </Box>
        <Stack direction="row" gap={2} alignItems="center">
          {viewInSalesforceButtonJsx}
          {newButtonJsx}
        </Stack>
      </Box>
      <CompleteSetupToDeploy entityName={dataByVariant.nameInMessage} />
      <AutomationsTable
        crmOrgId={crmOrgId}
        onSortCb={(sortBy: string, sortOrder: SortOrder) => {
          setSortBy(sortBy);
          setSortOrder(sortOrder);
        }}
        automations={automationsToDisplay}
        getActionsMenu={getActionsMenuArray}
        onActionMenuClick={onActionMenuClick}
        onEdit={onEditAutomation}
        onClearFilters={clearFilters}
      />
    </Stack>
  );

  const listJsx = (
    <>
      <Box
        className="list-jsx"
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          padding: `0 ${HORIZONTAL_PADDING}px`,
          ...newSearchSx,
          borderRadius: 0,
        }}
      >
        <Box flex={1}>{searchJsx}</Box>
        <Box>{newButtonJsx}</Box>
      </Box>

      {!hideSort && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: `0 ${HORIZONTAL_PADDING}px `,
          }}
        >
          <Typography variant="body" color={colors.grey[700]}>
            {pluralize(
              automationsCountTextMap[automationVariant],
              automationsToDisplay.length,
              true,
            )}
          </Typography>
          <Box display="flex" flexDirection="row" gap={1} alignItems="center">
            {!isExpandedMode && (
              <ConfigurationCanvasFilters
                filters={filtersForExpandedMode}
                automations={automations}
                staticView
                disableFitOnCanvasElements
                withPanelWrapper
              />
            )}
            {sortOptions && sortBy && (
              <SweepSort
                selectedValue={sortBy}
                onChange={setSortBy}
                options={sortOptions}
                displayType={SortDisplayTypes.IconAndLabel}
              />
            )}
          </Box>
        </Box>
      )}

      {automationsToDisplay.length === 0 && <NoSearchResult clearFilters={clearFilters} />}

      {automationsToDisplay.length > 0 && (
        <Box
          className="automations-list"
          sx={{
            height: '100%',
            overflow: 'hidden',
            flex: 1,
          }}
          height="100%"
        >
          <CompleteSetupToDeploy entityName={dataByVariant.nameInMessage} />
          <AutomationsList
            automations={automationsToDisplay}
            crmOrgId={crmOrgId}
            selectedId={automationJson?.automationId}
            setHoveredItem={setHoveredItem}
            getActionsMenu={getActionsMenuArray}
            onActionMenuClick={onActionMenuClick}
            onEdit={onEditAutomation}
          />
        </Box>
      )}
    </>
  );

  return (
    <>
      {isExpandedMode ? tableJsx : listJsx}

      {paywallDialogProps.isOpen && (
        <AutomationDeployPaywallBlock
          closeDialog={() => setPaywallDialogProps(PAYWALL_DIALOG_INITIAL)}
          entityNamePlural={dataByVariant.nameInMessageSingular}
          objectName={paywallDialogProps.objectName}
        />
      )}

      {automationForSlackLog && (
        <InfoDialog
          open={automationForSlackLog !== undefined}
          handleClose={() => {
            setAutomationForSlackLog(undefined);
          }}
          PaperPropsSx={{ paddingBottom: '16px', width: webhookActivity ? '1246px' : '1120px' }}
          showCloseButton
          titleJsx={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography variant={webhookActivity ? 'h2' : 'h1-bold'}>Slack activity</Typography>
              <Typography variant={webhookActivity ? 'h4' : 'h2'}>
                {automationForSlackLog.name}
              </Typography>
            </Box>
          }
        >
          {webhookActivity ? (
            <AuditTable auditType={AuditReportType.SLACK} automation={automationForSlackLog} />
          ) : (
            <SlackLogsContent automation={automationForSlackLog} />
          )}
        </InfoDialog>
      )}

      {automationForActivityLog && (
        <InfoDialog
          open={automationForActivityLog !== undefined}
          handleClose={() => {
            setAutomationForActivityLog(undefined);
          }}
          PaperPropsSx={{ paddingBottom: '16px', width: '1246px' }}
          showCloseButton
          titleJsx={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography variant={'h2'}>Integration activity</Typography>
              <Typography variant={'h4'}>{automationForActivityLog.name}</Typography>
            </Box>
          }
        >
          <AuditTable
            auditType={AuditReportType.AUTOMATION_WEBHOOK}
            automation={automationForActivityLog}
          />
        </InfoDialog>
      )}

      {completeSfSetupDialog?.isOpen && (
        <CompleteSfSetupForDeployDialog
          cancelDialog={async () => {
            setCompleteSfSetupDialog(undefined);
          }}
          onCompleteItemsCallback={async () => {
            setCompleteSfSetupDialog(undefined);
            await onDeploy(
              completeSfSetupDialog.automationItem,
              completeSfSetupDialog.crmOrgIdsToDeploy,
            );
          }}
          entityName={dataByVariant.nameInMessage}
        />
      )}
      {automationForOrderDialog && (
        <AutomationOrderDialog
          readonly={!isAllowedEdit}
          crmOrgId={crmOrgId}
          automation={automationForOrderDialog}
          closeDialog={() => {
            setAutomationForOrderDialog(undefined);
          }}
        />
      )}
      {automationForVersionHistoryDialog && (
        <AutomationVersionHistoryDialog
          automation={automationForVersionHistoryDialog}
          closeDialog={() => setAutomationForVersionHistoryDialog(undefined)}
        />
      )}
    </>
  );
};

export default AutomationsListWithSort;
