import { CSSObject, List, Theme, styled } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import {
  Automations,
  Binoculars,
  Devops,
  File,
  Funnels,
  Reporting,
  Rule,
  Users,
  PlaybookAlert,
  Play,
  Sparkle,
  Hubspot,
  Target,
} from '@sweep-io/sweep-design/dist/icons';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { DASHBOARD_HEADER_HEIGHT } from '../../constants';
import { appRoutes } from '../../constants/appRoutes';
import { selectDefaultCreationEnvironment } from '../pages/environments/environmentsReducer';
import {
  AlertsAnimation,
  AutomationsAnimation,
  DevOpsCenterAnimation,
  DocumentationAnimation,
  FileAnimation,
  FunnelsAnimation,
  HubspotAnimation,
  PlayAnimation,
  ReportingAnimation,
  RollupsAnimation,
  SupportAnimation,
  TargetAnimation,
  UsersAnimation,
  MarketingAttributionAnimation,
} from '../animations/lottie/lottieAnimations';
import Megaphone from './Megaphone.svg?react'; //temporary icon, TODO add to design system
import usePermission from '../common/permissions/usePermission';
import useSweepNavigate from '../common/useSweepNavigate';
import { SidebarListItem } from './SidebarListItem';
import { useFeatureToggle } from '../common/useFeatureToggle';
import { colors } from '@sweep-io/sweep-design';
import { useCallback } from 'react';
import useAiAgentCenter from '../aiAgentCenter/useAiAgentCenter';

const drawerWidth = 200;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} - 5px)`,
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
      '& .MuiDrawer-paper:hover': {
        ...openedMixin(theme),
      },
    }),
  }),
);

export const SidebarMenu = ({ open }: { open: boolean }) => {
  const defaultCreationEnv = useSelector(selectDefaultCreationEnvironment);

  const location = useLocation();
  const { navigate } = useSweepNavigate();
  const { pathname } = location;
  const [isSfChangeFeedAllowed] = usePermission(['read:sf-change-feed']);
  const envIdSuffix = defaultCreationEnv ? `/${defaultCreationEnv.id}` : '';
  const { sweepSupportChat, marketingAttributionModels } = useFeatureToggle();

  const { toggleIsExpanded, isExpanded } = useAiAgentCenter();

  const minimizeAiChatsAndNavigate = useCallback(
    (navigateTo: string) => {
      if (isExpanded) {
        toggleIsExpanded();
      }
      navigate(navigateTo);
    },
    [isExpanded, navigate, toggleIsExpanded],
  );

  return (
    <Drawer
      variant="permanent"
      id="sidebar-menu" // Do not remove this ID. used by DialogOnCanvas component
      open={open}
      PaperProps={{
        className: open ? 'open' : 'closed',
        sx: {
          pb: DASHBOARD_HEADER_HEIGHT + 'px',
          top: DASHBOARD_HEADER_HEIGHT,
          '&.closed': {
            '.MuiListItemText-root': {
              opacity: 0,
              transition: 'opacity 0.2s',
            },
            '&:hover': {
              '.MuiListItemText-root': {
                opacity: 1,
              },
            },
          },
        },
      }}
    >
      <List sx={{ px: 1, py: 1.5 }}>
        <SidebarListItem
          text="Get Started"
          icon={<Play variant="default" />}
          animatedIcon={<PlayAnimation size={16} />}
          isSelected={pathname.startsWith(appRoutes.getStarted.route)}
          callback={() => {
            minimizeAiChatsAndNavigate(appRoutes.getStarted.route);
          }}
        />

        <SidebarListItem
          text="Funnel Editor"
          icon={<Funnels variant="default" />}
          animatedIcon={<FunnelsAnimation size={16} />}
          isSelected={pathname.startsWith(appRoutes.funnelMapsPage.route)}
          callback={() => {
            minimizeAiChatsAndNavigate(appRoutes.funnelMapsPage.route);
          }}
        />

        <SidebarListItem
          text="Documentation"
          icon={<Binoculars variant="default" />}
          animatedIcon={<DocumentationAnimation size={16} />}
          isSelected={pathname.startsWith(appRoutes.documentation.route)}
          callback={() => {
            minimizeAiChatsAndNavigate(appRoutes.documentation.route + envIdSuffix);
          }}
        />

        {isSfChangeFeedAllowed && (
          <SidebarListItem
            text="Change Feed"
            icon={<File variant="default" />}
            animatedIcon={<FileAnimation size={16} />}
            isSelected={pathname.startsWith(appRoutes.sfChangeFeed.route)}
            callback={() => {
              minimizeAiChatsAndNavigate(appRoutes.sfChangeFeed.route);
            }}
          />
        )}

        <SidebarListItem
          text="Rollups"
          icon={<Rule variant="default" />}
          animatedIcon={<RollupsAnimation size={16} />}
          isSelected={pathname.startsWith(appRoutes.rollups.route)}
          callback={() => {
            minimizeAiChatsAndNavigate(appRoutes.rollups.route);
          }}
        />

        <>
          <SidebarListItem
            text="Automations"
            icon={<Automations variant="default" />}
            animatedIcon={<AutomationsAnimation size={16} />}
            isSelected={pathname.startsWith(appRoutes.automations.route)}
            callback={() => {
              minimizeAiChatsAndNavigate(appRoutes.automations.route + envIdSuffix);
            }}
          />
          <SidebarListItem
            text="Alerts"
            icon={<PlaybookAlert variant="default" />}
            animatedIcon={<AlertsAnimation size={16} />}
            isSelected={pathname.startsWith(appRoutes.alerts.route)}
            callback={() => {
              minimizeAiChatsAndNavigate(appRoutes.alerts.route + envIdSuffix);
            }}
          />
          <SidebarListItem
            text="Routing"
            icon={<Users variant="default" />}
            animatedIcon={<UsersAnimation size={16} />}
            isSelected={pathname.startsWith(appRoutes.routing.route)}
            callback={() => {
              minimizeAiChatsAndNavigate(appRoutes.routing.route + envIdSuffix);
            }}
          />
          <SidebarListItem
            text="Dedupe & Matching"
            icon={<Target variant="default" />}
            animatedIcon={<TargetAnimation size={16} />}
            isSelected={pathname.startsWith(appRoutes.dedup.route)}
            callback={() => {
              minimizeAiChatsAndNavigate(appRoutes.dedup.route + envIdSuffix);
            }}
          />
        </>

        <SidebarListItem
          text="Reporting"
          icon={<Reporting variant="default" />}
          animatedIcon={<ReportingAnimation size={16} />}
          isSelected={pathname.startsWith(appRoutes.reports.route)}
          callback={() => {
            minimizeAiChatsAndNavigate(appRoutes.reports.route);
          }}
        />

        {!marketingAttributionModels && (
          <SidebarListItem
            text="Hubspot"
            icon={
              <Hubspot
                variant="default"
                color={
                  pathname.startsWith(appRoutes.hubspot.route) ? colors.blue[500] : colors.grey[900]
                }
              />
            }
            animatedIcon={<HubspotAnimation size={16} />}
            isSelected={pathname.startsWith(appRoutes.hubspot.route)}
            callback={() => {
              minimizeAiChatsAndNavigate(appRoutes.hubspot.route);
            }}
          />
        )}

        {marketingAttributionModels && (
          <SidebarListItem
            text="Marketing Attribution"
            icon={
              <Megaphone
                color={
                  pathname.startsWith(appRoutes.marketingAttribution.route)
                    ? colors.blue[500]
                    : colors.grey[900]
                }
              />
            }
            animatedIcon={<MarketingAttributionAnimation size={16} />} //TODO update once we have a new animated icon
            isSelected={pathname.startsWith(appRoutes.marketingAttribution.route)}
            callback={() => {
              minimizeAiChatsAndNavigate(appRoutes.marketingAttribution.route);
            }}
          />
        )}

        <SidebarListItem
          text="Compare & Deploy"
          icon={<Devops variant="default" />}
          animatedIcon={<DevOpsCenterAnimation size={16} />}
          isSelected={pathname.startsWith(appRoutes.devopsCenter.route)}
          callback={() => {
            minimizeAiChatsAndNavigate(appRoutes.devopsCenter.route);
          }}
        />

        {sweepSupportChat && (
          <SidebarListItem
            text="Support AI"
            icon={<Sparkle variant="default" />}
            animatedIcon={<SupportAnimation size={16} />}
            isSelected={pathname.startsWith(appRoutes.aiAssistant.route)}
            callback={() => {
              minimizeAiChatsAndNavigate(appRoutes.aiAssistant.route);
            }}
          />
        )}
      </List>
    </Drawer>
  );
};
