import { Box } from '@mui/material';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  selectSlackIntegrationStatus,
  SlackConnectionStatus,
} from '../../../reducers/integrationsReducer';
import { Typography, colors } from '@sweep-io/sweep-design';
import { SlackNotConnected } from './SlackNotConnected';
import { SlackNewMessageEditor } from './SlackNewMessageEditor';
import { useRunOnce } from '../../common/useRunOnce';
import { SlackConstant } from './utils';
import { SlackSelectDealroomType } from './SlackSelectDealroomType';
import { SlackAdditionalFieldsTable } from './SlackAdditionalFieldsTable';

interface SlackRenameDealroomActionProps {
  readonly?: boolean;
  action: SlackRenameDealroomAutomationAction;
  crmOrgId: string;
  onChange: (SlackRenameDealroomAutomationAction: SlackRenameDealroomAutomationAction) => any;
  objectName: string;
}

export const SlackRenameDealroomAction = ({
  readonly,
  action,
  crmOrgId,
  objectName,
  onChange,
}: SlackRenameDealroomActionProps) => {
  const slackStatus = useSelector(selectSlackIntegrationStatus);

  useRunOnce(() => {
    updateOnChange({
      fields: [...(action.actionParams?.fields || [])],
      channelRef: action.actionParams?.channelRef
        ? { ...action.actionParams?.channelRef }
        : {
            type: SlackConstant.Sweep_Created_Slack_Channel,
          },
    });
  });

  const updateOnChange = useCallback(
    (updateProps: Partial<AutomationSlackRenameDealroomActionParams>) => {
      onChange({
        ...action,
        actionParams: {
          ...action.actionParams,
          ...updateProps,
        },
      });
    },
    [action, onChange],
  );

  return (
    <>
      {slackStatus !== SlackConnectionStatus.Connected && (
        <Box sx={{ marginTop: '32px' }}>
          <SlackNotConnected slackStatus={slackStatus} />
        </Box>
      )}

      {slackStatus === SlackConnectionStatus.Connected && (
        <Box
          sx={{
            background: colors.grey[100],
            padding: '24px 24px 19px',
            borderRadius: '8px',
            margin: '16px 0',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <SlackSelectDealroomType
            readonly={readonly}
            value={action?.actionParams?.channelRef}
            crmOrgId={crmOrgId}
            objectName={objectName}
            onChange={(val) => {
              updateOnChange({ channelRef: val });
            }}
          />

          <SlackNewMessageEditor
            placeholder="Set channel name. E.g., dealroom-{opportunity.name}"
            customOnKeyDown={(event) => {
              if (!(event.key.match(/[a-z0-9,]/) || event.key === '-' || event.key === '_')) {
                event.preventDefault();
              }
            }}
            infoTooltipTitle={
              'Channel names may only contain lowercase letters, numbers, hyphens, and underscores, and must be 80 characters or less.'
            }
            hideEmoji={true}
            isRequired={true}
            label="New Slack channel name"
            readonly={readonly}
            messageStruct={action.actionParams?.name}
            crmOrgId={crmOrgId}
            onChange={(newMsg) => {
              updateOnChange({ name: newMsg });
            }}
            objectName={objectName}
          />

          <SlackNewMessageEditor
            placeholder="Type message body..."
            isRequired={false}
            label="Message (optional)"
            readonly={readonly}
            messageStruct={action.actionParams?.message ?? { template: '', variables: [] }}
            crmOrgId={crmOrgId}
            onChange={(newMsg) => {
              updateOnChange({ message: newMsg });
            }}
            objectName={objectName}
          />

          <SlackAdditionalFieldsTable
            readonly={readonly}
            fields={action.actionParams?.fields}
            updateOnChange={updateOnChange}
            crmOrgId={crmOrgId}
            objectName={objectName}
          />

          <Typography variant="body">
            Note: this automation would work as expected only if the record has a dedicated deal
            room
          </Typography>
        </Box>
      )}
    </>
  );
};
