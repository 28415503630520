import { useCallback } from 'react';
import { selectIsLoadingRoleGroups, selectRoleGroups } from '../../reducers/roleGroupsReducer';
import { useSelector } from 'react-redux';
import {
  RoleGroupLevelType,
  RoleGroupType,
  RoleLevel,
  RoleLevels,
  UserDefinedRoleGroupsLevels,
} from '@server/role-group-interface';
import { SweepRoles } from '../../hooks/useUserInfo';
import { useRoleGroups } from '../dashboard/role-manager/useRoleManager';
import { useRunOnceWhenTruthy } from './useRunOnceWhenTruthy';
import { selectUserInfoData } from '../../reducers/userInfoReducer';

/**
 * NOTE: THIS HOOKS INITIALIZES ROLE GROUPS
 */
export const useSweepRoleGroups = () => {
  const currentUser = useSelector(selectUserInfoData);
  const roleGroups = useSelector(selectRoleGroups);
  const isLoading = useSelector(selectIsLoadingRoleGroups);
  const { fetchRoleGroups } = useRoleGroups();

  useRunOnceWhenTruthy(
    () => {
      if (!roleGroups && !isLoading) {
        fetchRoleGroups();
      }
    },
    !roleGroups && !!currentUser && !isLoading,
  );

  const isAdmin = useCallback(
    (userRoleGroupId: string) => {
      const userRoleGroup = roleGroups?.find((roleGroup) => roleGroup.id === userRoleGroupId);
      return (
        userRoleGroup?.type === RoleGroupType.SYSTEM && userRoleGroup.name === SweepRoles.Admin
      );
    },
    [roleGroups],
  );

  const isCollaborator = useCallback(
    (userRoleGroupId: string) => {
      const userRoleGroup = roleGroups?.find((roleGroup) => roleGroup.id === userRoleGroupId);
      return (
        userRoleGroup?.type === RoleGroupType.SYSTEM &&
        userRoleGroup.name === SweepRoles.Collaborator
      );
    },
    [roleGroups],
  );

  const getUserRole = useCallback(
    (userRoleGroupId: string) => roleGroups?.find((roleGroup) => roleGroup.id === userRoleGroupId),
    [roleGroups],
  );

  const hasUserRole = useCallback(
    ({
      crmOrgId,
      roleGroupLevels,
      checkLevel,
      roleLevel,
    }: {
      crmOrgId: string;
      roleGroupLevels?: UserDefinedRoleGroupsLevels;
      checkLevel: keyof RoleLevels;
      roleLevel: RoleLevel;
    }) => {
      if (roleGroupLevels?.type === RoleGroupLevelType.AccountBased) {
        return roleGroupLevels.levels[checkLevel] === roleLevel;
      }

      if (roleGroupLevels?.type === RoleGroupLevelType.OrgBased) {
        const item = roleGroupLevels.levels.find((item) => item.crmOrgId === crmOrgId);
        return item?.levels[checkLevel] === roleLevel;
      }
    },
    [],
  );

  const isUserWithRequestToDeployFunnels = (crmOrgId: string) =>
    isCollaborator(currentUser?.roleGroupId ?? '') ||
    !!hasUserRole({
      crmOrgId,
      roleGroupLevels: getUserRole(currentUser?.roleGroupId ?? '')?.roleGroupLevels,
      checkLevel: 'funnels',
      roleLevel: RoleLevel.REQUEST_TO_DEPLOY,
    });

  return {
    isAdmin,
    getUserRole,
    isUserWithRequestToDeployFunnels,
  };
};
