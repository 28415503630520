import AiAgentsMenu from './AiAgentsMenu';
import { Tooltip } from '@sweep-io/sweep-design';
import { ButtonBase } from '@mui/material';
import { AiAgent } from './aiAgentsConsts';
import usePopover from '../../hooks/usePopover';
import useAiAgentChats from './useAiAgentChats';
import { useFunnelMapPage } from '../pages/funnel-map-flow/useFunnelMapPage';
import { ReactNode } from 'react';

//This component should be used only in the funnel editor
const AiAgentsButtonAndMenu = ({
  aiAgents,
  button,
}: {
  aiAgents: AiAgent[];
  button: ReactNode;
}) => {
  const { openPopover, closePopover, isPopoverOpen, anchorRef, anchorElement } = usePopover();
  const { startChatWithAgent } = useAiAgentChats();
  const { onAiAgentCenterClick } = useFunnelMapPage(); //open the Agent Center in the Funnel Editor

  const onAgentClick = (agent: AiAgent) => {
    closePopover();
    startChatWithAgent({
      agent,
      biEventProps: { origin: 'stage_dialog', mode: 'collapsed' },
    });
    onAiAgentCenterClick();
  };
  const hasAgents = aiAgents.length > 0;

  if (!hasAgents) return null;

  return (
    <>
      <Tooltip title="AI Agent activated" placement="top">
        <ButtonBase
          ref={anchorRef}
          onClick={(e) => {
            e.stopPropagation();
            if (aiAgents.length === 1) {
              onAgentClick(aiAgents[0]);
              return;
            }
            openPopover();
          }}
        >
          {button}
        </ButtonBase>
      </Tooltip>
      <AiAgentsMenu
        isOpen={isPopoverOpen}
        aiAgents={aiAgents}
        anchorElement={anchorElement}
        closeMenu={closePopover}
        onAgentClick={onAgentClick}
      />
    </>
  );
};

export default AiAgentsButtonAndMenu;
