import { Box, CircularProgress, Stack } from '@mui/material';
import { useCallback, useState, useEffect } from 'react';
import teamsPng from './images/teamsIcon.png';
import CloseIcon from '@mui/icons-material/Close';
import { StyledChip } from '../common/StyledChip';
import { TeamsRecipientsNestedSelector } from './TeamsRecipientsNestedSelector';
import { colors, Typography } from '@sweep-io/sweep-design';
import { TeamsRecipientInfo, TeamsRecipientStruct, TeamsRecipientType } from './TeamsMessageAction';
import { telemetry } from '../../telemetry';
import { useIntegrations } from '../../hooks/useIntegrations';

interface TeamsRecipientsChipArrayProps {
  recipients: TeamsRecipientStruct[];
  onChange: (recipients: TeamsRecipientInfo[]) => void;
  readonly?: boolean;
  customButtonText?: string;
}

export const TeamsRecipientsChipArray = ({
  recipients,
  onChange,
  readonly,
  customButtonText = 'Add Recipient',
}: TeamsRecipientsChipArrayProps) => {
  const [recipientsWithLabels, setRecipientsWithLabels] = useState<TeamsRecipientInfo[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getTeamsRecipientLabelsForAutomation } = useIntegrations();

  useEffect(() => {
    const fetchLabels = async () => {
      if (!recipients?.length) {
        setRecipientsWithLabels([]);
        return;
      }

      // Handle recipients that already have labels (from select)
      const recipientsWithExistingLabels = recipients.filter((r) => r.label);
      const mappedRecipientsWithLabels = recipientsWithExistingLabels.map((r) => ({
        id: r.value as string,
        label: r.label as string,
        type: r.type as TeamsRecipientType,
      }));

      // Handle recipients without labels (from API)
      const recipientsWithoutLabels = recipients.filter((r) => !r.label);
      let apiRecipientsWithLabels: TeamsRecipientInfo[] = [];

      if (recipientsWithoutLabels.length > 0) {
        try {
          setIsLoading(true);
          const recipientIds = recipientsWithoutLabels.map((r) => `${r.value}|${r.type}`);
          apiRecipientsWithLabels = await getTeamsRecipientLabelsForAutomation(recipientIds);
        } catch (error) {
          telemetry.captureError(error);
        } finally {
          setIsLoading(false);
        }
      }

      // Combine both sets of recipients
      setRecipientsWithLabels([...mappedRecipientsWithLabels, ...apiRecipientsWithLabels]);
    };

    fetchLabels();
  }, [recipients, getTeamsRecipientLabelsForAutomation]);

  const handleDeleteChip = useCallback(
    (recipientToDelete: TeamsRecipientInfo) => {
      const newRecipients = recipientsWithLabels.filter((r) => r.id !== recipientToDelete.id);
      onChange(newRecipients);
    },
    [recipientsWithLabels, onChange],
  );

  const returnChipWithProps = useCallback(
    (recipient: TeamsRecipientInfo, isDelete: boolean) => {
      let ChipProps: any = {
        key: recipient.id,
        label: recipient.label,
        title: recipient.label,
        size: 'small',
        icon: (
          <img
            src={teamsPng}
            alt={`${recipient.type} icon`}
            style={{ width: '12px', marginLeft: '8px' }}
          />
        ),
      };

      if (isDelete) {
        ChipProps = {
          ...ChipProps,
          onDelete: () => handleDeleteChip(recipient),
          deleteIcon: <CloseIcon sx={{ color: '#000', fontSize: 12 }} />,
        };
      }

      return <StyledChip {...ChipProps} sx={{ textTransform: 'capitalize' }} key={ChipProps.key} />;
    },
    [handleDeleteChip],
  );

  const handleNewRecipient = useCallback(
    (recipient: TeamsRecipientInfo) => {
      onChange([...recipientsWithLabels, recipient]);
    },
    [recipientsWithLabels, onChange],
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Stack
        direction="row"
        spacing={1}
        flexWrap="wrap"
        useFlexGap
        sx={{
          minHeight: '40px',
          backgroundColor: colors.white,
          border: `1px solid ${colors.grey[300]}`,
          borderRadius: '4px',
          padding: '4px',
          alignContent: 'center',
          paddingLeft: '12px',
        }}
      >
        {recipientsWithLabels?.length === 0 && (
          <>
            <Typography variant={'body'} color={colors.grey[500]}>
              Users / Teams will appear here
            </Typography>
            {isLoading && <CircularProgress size={22} />}
          </>
        )}
        {recipientsWithLabels.map((recipient) => returnChipWithProps(recipient, !readonly))}
      </Stack>

      <TeamsRecipientsNestedSelector
        onChange={handleNewRecipient}
        readonly={readonly}
        customButtonText={customButtonText}
        selectedRecipients={recipientsWithLabels}
      />
    </Box>
  );
};
