import RightPanel from '../common/RightPanel';
import { Close } from '@sweep-io/sweep-design/dist/icons';
import { IconButton } from '@sweep-io/sweep-design';
import AiAgentCenterExpanded from './AiAgentCenterExpanded';
import AiAgentCenterCollapsed from './AiAgentCenterCollapsed';
import InitialFetchNoticeDialog from './InitialFetchNoticeDialog';
import useAiAgentCenter from './useAiAgentCenter';
import { useCallback, useState } from 'react';
import ProcessSelectionDialog from './ProcessSelectionDialog';
import CreateFunnelDialog from './CreateFunnelDialog';
import useSweepNavigate from '../common/useSweepNavigate';
import { appRoutes } from '../../constants/appRoutes';
import { useLocation } from 'react-router';
import { useRunOnceWhenTruthy } from '../common/useRunOnceWhenTruthy';
import { useFunnelMaps } from '../pages/funnel-maps/useFunnelMaps';
import { useSelector } from 'react-redux';
import { selectFunnelMapsIsLoading, selectFunnelMapsList } from '../../reducers/funnelMapsReducer';
import { selectDefaultCreationCrmOrgId } from '../../reducers/userInfoReducer';
import ConnectToSfDialog from '../pages/get-started/connect-org/ConnectToSfDialog';
import { Permission } from '@server/permissions';
import usePermission from '../common/permissions/usePermission';
import { useUnhappyStates } from '../pages/environments/useUnhappyStates';
import useConfirm from '../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import useSendBiEvent from '../../hooks/useSendBiEvent';
import { AI_AGENTS_EVENTS } from '../../services/events';
import { AgentType } from '@server/ai';
import NewMonitoringAgentDialog from './NewMonitoringAgentDialog';

const AiAgentCenterPanel = ({ closePanel, isOpened }: { closePanel: () => void; isOpened: boolean }) => {
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  const { isInitialFetch, noConnectedOrg } = useUnhappyStates();
  const [isProcessSelectionDialogOpened, setIsProcessSelectionDialogOpened] = useState(false);
  const [isNewMonitoringAgentDialogOpened, setIsNewMonitoringAgentDialogOpened] = useState(false);
  const [isCreateFunnelDialogOpened, setIsCreateFunnelDialogOpened] = useState(false);
  const [isConnectSfDialogOpen, setIsConnectSfDialogOpen] = useState(false);
  const { isExpanded, setIsAgentCreateActive } = useAiAgentCenter();
  const { navigate } = useSweepNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { fetchFunnelMaps } = useFunnelMaps();
  const funnelMaps = useSelector(selectFunnelMapsList);
  const isLoadingFunnelMaps = useSelector(selectFunnelMapsIsLoading);
  const connectSfPermissionStr: Permission[] = ['create:orgs'];
  const [isAllowedConnectSf] = usePermission(connectSfPermissionStr);
  const { openConfirm, onCancel } = useConfirm();
  const sendBiEvent = useSendBiEvent();

  useRunOnceWhenTruthy(
    () => {
      fetchFunnelMaps({ includeFunnelsData: true });
    },
    !isLoadingFunnelMaps && funnelMaps.length === 0 && !!crmOrgId,
  );

  const navigateAndActivate = useCallback(
    (funnelMapId: string) => {
      sendBiEvent({
        name: AI_AGENTS_EVENTS.newAgentClick,
        props: { origin: 'agent_card', app: 'dashboard' },
      });
      setIsAgentCreateActive(true);
      navigate(`${appRoutes.canvasUnited.route}/${funnelMapId}`);
    },
    [navigate, sendBiEvent, setIsAgentCreateActive],
  );

  const handleNoOrgFirst = useCallback(
    async (cb: () => void) => {
      if (noConnectedOrg) {
        if (isAllowedConnectSf) {
          setIsConnectSfDialogOpen(true);
        }
        return;
      }
      if (isInitialFetch) {
        await openConfirm(<InitialFetchNoticeDialog closeDialog={onCancel} />);
        return;
      }
      cb();
    },
    [isAllowedConnectSf, isInitialFetch, noConnectedOrg, onCancel, openConfirm],
  );

  const handleNewProcessAgent = useCallback(async () => {
    const isInCanvas = pathname.startsWith(appRoutes.canvasUnited.route);
    if (isInCanvas) {
      sendBiEvent({
        name: AI_AGENTS_EVENTS.newAgentClick,
        props: { origin: 'agent_card', app: 'canvas' },
      });
      setIsAgentCreateActive(true);
      return;
    }

    if (!isLoadingFunnelMaps && funnelMaps.length === 0) {
      setIsCreateFunnelDialogOpened(true);
      return;
    }
    setIsProcessSelectionDialogOpened(true);
  }, [funnelMaps.length, isLoadingFunnelMaps, pathname, sendBiEvent, setIsAgentCreateActive]);

  const handleNewMonitoringAgent = useCallback(() => {
    setIsNewMonitoringAgentDialogOpened(true);
  }, []);

  const handleNewAgentClick = useCallback(
    async (type: AgentType) => {
      switch (type) {
        case AgentType.ProcessOptimization:
          handleNoOrgFirst(handleNewProcessAgent);
          return;
        case AgentType.Monitoring:
          handleNoOrgFirst(handleNewMonitoringAgent);
          return;
      }
    },
    [handleNoOrgFirst, handleNewProcessAgent, handleNewMonitoringAgent],
  );

  const closeConnectSfDialog = useCallback(() => {
    setIsConnectSfDialogOpen(false);
  }, []);

  const closeButton = (
    <IconButton onClick={closePanel} variant="flat" size="small">
      <Close />
    </IconButton>
  );

  return (
    <>
      <RightPanel isOpened={isOpened} isExpanded={isExpanded}>
        {isExpanded && (
          <AiAgentCenterExpanded closeButton={closeButton} onNewAgentClick={handleNewAgentClick} />
        )}
        {!isExpanded && (
          <AiAgentCenterCollapsed closeButton={closeButton} onNewAgentClick={handleNewAgentClick} />
        )}
      </RightPanel>
      {isProcessSelectionDialogOpened && (
        <ProcessSelectionDialog
          closeDialog={() => setIsProcessSelectionDialogOpened(false)}
          onSelect={navigateAndActivate}
        />
      )}
      {isNewMonitoringAgentDialogOpened && (
        <NewMonitoringAgentDialog closeDialog={() => setIsNewMonitoringAgentDialogOpened(false)} />
      )}
      {isCreateFunnelDialogOpened && (
        <CreateFunnelDialog closeDialog={() => setIsCreateFunnelDialogOpened(false)} />
      )}
      {isConnectSfDialogOpen && (
        <ConnectToSfDialog
          onFinish={closeConnectSfDialog}
          closeDialog={closeConnectSfDialog}
          forceProduction={false}
        />
      )}
    </>
  );
};

export default AiAgentCenterPanel;
