import cloneDeep from 'lodash/cloneDeep';
import { Box, keyframes, ListItemIcon, MenuItem, MenuProps, Popover, Select } from '@mui/material';
import { Typography, colors, IconButton } from '@sweep-io/sweep-design';
import { Fragment, useMemo, useRef } from 'react';
import React from 'react';
import { handleUpdateLogicDelete } from './helpers';
import { ErrorPopover } from '../ErrorPopover';
import {
  RuleBuilderData,
  RuleBuilderEntryConstrain,
  RuleBuilderRowComponentProps,
} from './rule-builder-types';
import { Group, Plus } from '@sweep-io/sweep-design/dist/icons';
import { ParserTypes } from './RuleBuilder';

export function RuleBuilderCallExpression<T extends RuleBuilderEntryConstrain = any>({
  ruleBuilderData,
  onRowChange,
  parsedCriteria,
  onLogicPartialChange,
  onAddNewRule,
  onDeleteLiteral,
  errorIds,
  showErrorAnimation,
  onErrorPause,
  readonly,
  highlightInvalidState,
  handleErrorPopoverClose,
  displayErrorPopover,
  newRowProvider,
  RowComponent,
  currentPopupErrorId,
  nextPopupErrorId,
}: {
  ruleBuilderData: RuleBuilderData<T>;
  onRowChange: (index: number, entry: T) => any;
  parsedCriteria: any;
  onLogicPartialChange?: (parsedCriteria: any) => any;
  onAddNewRule?: (parsedCriteria: any, ruleBuilderData: RuleBuilderData<T>) => any;
  onDeleteLiteral: (parsedCriteria: any, ruleBuilderData: RuleBuilderData<T>, index: number) => any;
  errorIds: string[];
  showErrorAnimation: boolean;
  onErrorPause: () => any;
  readonly?: boolean;
  highlightInvalidState?: boolean;
  handleErrorPopoverClose: () => void;
  displayErrorPopover?: boolean;
  newRowProvider: () => T;
  RowComponent: React.ComponentType<RuleBuilderRowComponentProps<T>>;
  currentPopupErrorId?: string;
  nextPopupErrorId: () => void;
}) {
  const [addMenuElement, setAddMenuElement] = React.useState<HTMLButtonElement | null>(null);
  const criterionRefs = useRef<Array<HTMLDivElement | unknown>>([]);

  const handleMenuElementClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAddMenuElement(event.currentTarget);
  };
  const handleMenuElementClose = () => {
    setAddMenuElement(null);
  };
  const isMenuElementOpen = Boolean(addMenuElement);
  const menuElementID = isMenuElementOpen ? 'add-popover' : undefined;
  const isFirstItem = useMemo(
    () => ruleBuilderData.entries && !ruleBuilderData.entries.length,
    [ruleBuilderData],
  );

  const handleParsedCriteriaUpdate = (
    newParsedCriteria: any,
    idx: number,
    _parsedCriteria: any,
  ) => {
    if (newParsedCriteria[idx].type === ParserTypes.SequenceExpression) {
      newParsedCriteria[idx].expressions = _parsedCriteria;
    } else {
      newParsedCriteria[idx].arguments = _parsedCriteria;
    }
  };

  const renderGroup = (line: any, idx: number) => (
    <Fragment>
      <RuleBuilderCallExpression
        nextPopupErrorId={nextPopupErrorId}
        highlightInvalidState={highlightInvalidState}
        readonly={readonly}
        parsedCriteria={line.arguments || line.expressions}
        ruleBuilderData={ruleBuilderData}
        errorIds={errorIds}
        showErrorAnimation={showErrorAnimation}
        onErrorPause={onErrorPause}
        onRowChange={onRowChange}
        onDeleteLiteral={(_parsedCriteria, _newExitCriteria, index) => {
          let newParsedCriteria = [...parsedCriteria];
          handleParsedCriteriaUpdate(newParsedCriteria, idx, _parsedCriteria);
          newParsedCriteria = handleUpdateLogicDelete(newParsedCriteria, index);
          onDeleteLiteral(newParsedCriteria, _newExitCriteria, index);
        }}
        onLogicPartialChange={(_parsedCriteria) => {
          const newParsedCriteria = [...parsedCriteria];
          handleParsedCriteriaUpdate(newParsedCriteria, idx, _parsedCriteria);
          onLogicPartialChange && onLogicPartialChange(newParsedCriteria);
        }}
        onAddNewRule={(_parsedCriteria, _newExitCriteria) => {
          const newParsedCriteria = [...parsedCriteria];
          handleParsedCriteriaUpdate(newParsedCriteria, idx, _parsedCriteria);
          onAddNewRule && onAddNewRule(newParsedCriteria, _newExitCriteria);
        }}
        handleErrorPopoverClose={handleErrorPopoverClose}
        displayErrorPopover={displayErrorPopover}
        newRowProvider={newRowProvider}
        RowComponent={RowComponent}
        currentPopupErrorId={currentPopupErrorId}
      />
    </Fragment>
  );

  const renderLiteral = (line: any) => {
    const indx = line.value - 1;
    const entry = ruleBuilderData.entries[indx];
    const invalidIdx = errorIds.findIndex((value) => value === entry.id);

    const highlightErrorState = highlightInvalidState && errorIds[invalidIdx]; //should be highlighted on error

    const displayPopover = displayErrorPopover && currentPopupErrorId === entry.id;
    return (
      <Box position="relative">
        <Box
          ref={(ref) => (criterionRefs.current[indx] = ref)}
          id={'rule_identifier_' + entry.id}
          sx={{
            marginBottom: '12px',
            '& .MuiPaper-root:first-of-type': {
              border: highlightErrorState ? `1px solid ${colors.blush[500]}` : '',
              animation:
                showErrorAnimation && highlightErrorState ? `${wiggle} 1s linear 1` : 'none',
            },
            position: 'relative',
          }}
        >
          <RowComponent
            data={entry}
            index={indx}
            readOnly={readonly}
            onRowChange={(entry) => onRowChange(indx, entry)}
            onRowDelete={() => {
              const newRuleBuilderData = cloneDeep(ruleBuilderData);
              newRuleBuilderData.entries.splice(indx, 1);

              let newParsedCriteria = [...parsedCriteria];

              newParsedCriteria = handleUpdateLogicDelete(newParsedCriteria, indx);
              onDeleteLiteral(newParsedCriteria, newRuleBuilderData, indx);
            }}
            hasError={errorIds.includes(entry.id)}
          />
        </Box>

        <ErrorPopover
          isOpen={!!(highlightErrorState && displayPopover)}
          displayChevrons
          errorText="Fix invalid state"
          findNextIdx={nextPopupErrorId}
          currentIdx={invalidIdx + 1}
          totalItemsCount={Number(errorIds.length)}
          criterionRef={criterionRefs.current[indx]}
          onClose={handleErrorPopoverClose}
          placement="bottom"
        />
      </Box>
    );
  };

  const changeIdentifier = (parsedCriteria: any, operator: string) => {
    const newParsedCriteria = [...parsedCriteria];
    newParsedCriteria.forEach((criteria: any) => {
      if (criteria.type === ParserTypes.Identifier) {
        criteria.name = operator;
      }
    });
    return newParsedCriteria;
  };

  const renderIdentifier = (name: string) => {
    return (
      <Select
        disabled={readonly}
        data-testid="rule-builder-identifier"
        sx={{
          marginBottom: '12px',
          borderRadius: '4px',
          boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.08)',
          fontWeight: 500,
          minHeight: '24px',
          minWidth: '65px',
          textTransform: 'uppercase',
          background: name.toLowerCase() === 'and' ? colors.lilac[500] : colors.sky[500],
          color: colors.white,

          span: {
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: '20px',
          },

          svg: {
            top: 'calc(50% - .5em)',
            path: {
              stroke: colors.white,
            },
          },
          '& fieldset': {
            border: 'none',
            display: 'none',
          },
          '& .MuiSelect-select': {
            padding: '0 8px',
            minHeight: 0,

            '&.MuiInputBase-input': {
              paddingRight: '10px',
            },
          },
          '&.Mui-disabled': {
            //MUI styles grays out font color, we need it to stay white
            WebkitTextFillColor: colors.white,
            background: name.toLowerCase() === 'and' ? colors.lilac[500] : colors.sky[500],
            opacity: 0.7,
          },
        }}
        MenuProps={
          {
            'data-testid': 'rule-builder-identifier-menu',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            sx: {
              '.MuiMenu-paper': {
                marginLeft: '20px',
                marginTop: '-5px',
                boxShadow: '0px 2px 10px rgb(0 0 0 / 25%)',
              },
            },
          } as Partial<MenuProps>
        }
        value={name.toUpperCase()}
        label="Logic"
        displayEmpty
        onChange={(event) => {
          const newParsedCriteria = changeIdentifier(parsedCriteria, event.target.value as string);
          if (newParsedCriteria) {
            onLogicPartialChange && onLogicPartialChange(newParsedCriteria);
          }
        }}
      >
        <MenuItem sx={{ width: '140px' }} value="AND">
          <Typography variant="body">And</Typography>
        </MenuItem>
        <MenuItem value="OR">
          <Typography variant="body">Or</Typography>
        </MenuItem>
      </Select>
    );
  };

  const handleAddRule = () => {
    const newParsedCriteria = [...parsedCriteria];
    const result = newParsedCriteria.filter((line) => line.type === ParserTypes.Identifier);
    if (result.length) {
      newParsedCriteria.push({ ...result[0] });
    } else if (newParsedCriteria.length !== 0) {
      newParsedCriteria.push({ type: ParserTypes.Identifier, name: 'AND' });
    }
    const size = (ruleBuilderData.entries && ruleBuilderData.entries.length + 1) || 1;
    newParsedCriteria.push({
      type: ParserTypes.Literal,
      value: size,
      raw: size.toString(),
    });

    const newSweepCondition = cloneDeep(ruleBuilderData);
    newSweepCondition.entries.push(newRowProvider());
    onAddNewRule && onAddNewRule(newParsedCriteria, newSweepCondition);
    handleMenuElementClose();
  };

  const handleAddGroup = () => {
    const newParsedCriteria = [...parsedCriteria];
    const result = newParsedCriteria.filter((line) => line.type === ParserTypes.Identifier);
    const size = (ruleBuilderData.entries && ruleBuilderData.entries.length + 1) || 1;
    if (result.length) {
      newParsedCriteria.push({ ...result[0] });
      newParsedCriteria.push({
        type: ParserTypes.CallExpression,
        arguments: [{ type: ParserTypes.Literal, value: size, raw: size.toString() }],
      });
    } else {
      if (newParsedCriteria.length === 0) {
        newParsedCriteria.push({
          type: ParserTypes.SequenceExpression,
          expressions: [{ type: ParserTypes.Literal, value: size, raw: size.toString() }],
        });
      } else {
        newParsedCriteria.push({ type: ParserTypes.Identifier, name: 'AND' });
        newParsedCriteria.push({
          type: ParserTypes.CallExpression,
          arguments: [{ type: ParserTypes.Literal, value: size, raw: size.toString() }],
        });
      }
    }

    const newSweepCondition = cloneDeep(ruleBuilderData);

    newSweepCondition.entries.push(newRowProvider());
    onAddNewRule && onAddNewRule(newParsedCriteria, newSweepCondition);
    handleMenuElementClose();
  };

  let wasFirstIdentifier = false;
  const wiggle = keyframes`
      0%, 7% {
        transform: rotateZ(0);
      }
      15% {
        transform: rotateZ(-5deg);
      }
      20% {
        transform: rotateZ(3deg);
      }
      25% {
        transform: rotateZ(-3deg);
      }
      30% {
        transform: rotateZ(1deg);
      }
      35% {
        transform: rotateZ(-1deg);
      }
      40%, 100% {
        transform: rotateZ(0);
      }
    `;

  return (
    <>
      <Box
        className="rule_builder_group"
        sx={{ position: 'relative' }}
        data-testid="rule-builder-group"
      >
        {parsedCriteria.map((line: any, idx: number) => {
          const indexKey = JSON.stringify(line + idx);
          switch (line.type) {
            case ParserTypes.Literal:
              return (
                <Fragment key={ruleBuilderData.entries[line.value - 1]?.id}>
                  {renderLiteral(line)}
                </Fragment>
              );

            case ParserTypes.Identifier:
              if (wasFirstIdentifier) {
                return (
                  <p
                    style={{
                      textTransform: 'uppercase',
                      fontWeight: '700',
                      fontSize: '12px',
                      margin: '12px 0',
                      color:
                        line.name.toLowerCase() === 'and' ? colors.lilac[500] : colors.sky[500],
                    }}
                    key={indexKey}
                  >
                    {line.name}
                  </p>
                );
              } else {
                wasFirstIdentifier = true;
                return <Fragment key={indexKey}>{renderIdentifier(line.name)}</Fragment>;
              }

            case ParserTypes.CallExpression:
              return <Fragment key={indexKey}>{renderGroup(line, idx)}</Fragment>;

            case ParserTypes.SequenceExpression:
              return <Fragment key={indexKey}>{renderGroup(line, idx)}</Fragment>;
          }
          return null;
        })}

        {!readonly && (
          <Box
            className="rule_builder_add"
            sx={{
              position: 'relative',
              height: '32px',
              '&:hover .rule_builder_button': {
                background: colors.white,
                button: {
                  color: colors.grey[900],
                  borderColor: colors.grey[300],
                },
                '&:hover': {
                  button: {
                    color: colors.white,
                    borderColor: colors.grey[800],
                  },
                },
              },
            }}
          >
            {isFirstItem && (
              <Box sx={{ position: 'absolute', top: '3px', left: '48px' }}>
                <Typography variant="caption" color={colors.grey[800]} whiteSpace="nowrap">
                  Add conditions
                </Typography>
              </Box>
            )}

            <Box
              data-testid="rule-builder-add-button"
              className="rule_builder_button"
              sx={{
                position: 'absolute',
                bottom: '0px',
                width: '32px',
                height: '32px',
                borderRadius: '50px',
                background: isMenuElementOpen ? colors.white : isFirstItem ? colors.white : '',
                button: {
                  backgroundColor: 'transparent',
                  color: colors.grey[800],
                  borderColor: isMenuElementOpen
                    ? colors.grey[800]
                    : isFirstItem
                      ? colors.grey[300]
                      : 'transparent',
                },
              }}
            >
              <IconButton
                onClick={handleMenuElementClick}
                size="small"
                variant="outlined"
                aria-describedby={menuElementID}
              >
                <Plus />
              </IconButton>

              <Popover
                id={menuElementID}
                open={isMenuElementOpen}
                anchorEl={addMenuElement}
                onClose={handleMenuElementClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                sx={{
                  '& .MuiPopover-paper': {
                    marginLeft: '10px',
                    marginTop: '0px',
                    '& li.MuiButtonBase-root.MuiMenuItem-root': {
                      height: '40px',
                      p: '12px',
                      '& .MuiListItemIcon-root': {
                        color: colors.grey[900],
                        minWidth: '28px',
                      },
                    },
                  },
                }}
              >
                <Box className="rule_builder_add_menu" sx={{ p: 1 }}>
                  <MenuItem onClick={handleAddRule}>
                    <ListItemIcon>
                      <Plus />
                    </ListItemIcon>
                    <Typography variant="body">Add condition</Typography>
                  </MenuItem>
                  <MenuItem onClick={handleAddGroup}>
                    <ListItemIcon>
                      <Group />
                    </ListItemIcon>

                    <Typography variant="body"> Add group</Typography>
                  </MenuItem>
                </Box>
              </Popover>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
