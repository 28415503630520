import { ReactNode } from 'react';
import { SxProps, Theme } from '@mui/material';
import { SortOrder } from '../types';
import { SweepTypographyVariants } from '@sweep-io/sweep-design/dist/components/Typography/types';

export enum SortCompareTypes {
  String = 'string',
  Number = 'number',
  Custom = 'custom',
}

export type SortCompareFunction<TRow extends DataTableRow = any> = (
  a: TRow,
  b: TRow,
  order: SortOrder,
) => number;

export interface SortCompareType {
  type: SortCompareTypes;
}
export interface SortCompareCustom<TRow extends DataTableRow = any> extends SortCompareType {
  type: SortCompareTypes.Custom;
  compareFunction: (a: TRow, b: TRow, order: SortOrder) => number;
}
interface SortCompareString extends SortCompareType {
  type: SortCompareTypes.String;
}
interface SortCompareNumber extends SortCompareType {
  type: SortCompareTypes.Number;
}

export interface DataTableBaseColumn<
  TRow extends DataTableRow = any,
  TFieldName extends keyof TRow = any,
> {
  field: TFieldName;
  headerName: ReactNode;
  width?: number | string;
  minWidth?: number | string;
  className?: string;
  hidden?: boolean;
  justifyContent?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | string;
  renderer?: (value: TRow[TFieldName], id: string, field: TFieldName, row: TRow) => ReactNode;
}

export interface DataTableDraggableColumn extends DataTableBaseColumn {
  showDragHandle?: boolean;
}

export interface DataTableSortableColumn<TRow extends DataTableRow = any>
  extends DataTableBaseColumn {
  showSortIcon?: boolean;
  sortCompare?: SortCompareString | SortCompareNumber | SortCompareCustom<TRow>;
}

export type DataTableOnOrderChangeClbk = ({
  sourceIndex,
  destinationIndex,
}: {
  sourceIndex: number;
  destinationIndex: number;
}) => void;

export type DataTableOnSortChangeClbk = (props: { sortBy: string; sortOrder: SortOrder }) => void;

export interface DataTableRow {
  id: string;
  nestedRows?: DataTableRow[];
  _animation?: string;
  disabled?: boolean;
  isRowActive?: boolean;
  [key: string]: any;
}

export type RenderRow<TRow extends DataTableRow = any> = ({
  rowIdx,
  row,
  rowKey,
}: {
  rowIdx?: number;
  row?: TRow;
  rowKey?: string;
}) => ReactNode | undefined;

export type DataTableSortStateProps = {
  sortBy: string;
  sortOrder: SortOrder;
};

export enum DataTableVariant {
  narrow = 'NARROW',
  default = 'DEFAULT',
  medium = 'MEDIUM',
  small = 'SMALL',
}

export interface DataTableBaseProps<TRow extends DataTableRow = any> {
  columns: DataTableBaseColumn[]; // Header columns definition
  rows: TRow[]; // Rows data
  sxRowFunction?: (isDragging: boolean) => SxProps<Theme>;
  renderRow?: RenderRow;
  sx?: SxProps<Theme>;
  isLoading?: boolean;
  stickyHeader?: boolean;
  tableMaxHeight?: string;
  reachedLastRow?: {
    txt: string;
    rowsMaxCount: number;
  };
  tableEmptyStateJsx?: React.ReactNode;
  onRowClick?: (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    row: TRow,
    columnData: DataTableBaseColumn,
  ) => void;
  actionableButtonsOnHover?: boolean;
  TableFooter?: React.ReactNode;
  variant?: DataTableVariant;
  containerRef?: React.RefObject<HTMLDivElement>;
  rowTypographyVariant?: SweepTypographyVariants;
}

export interface DataTableDraggableProps<TRow extends DataTableRow = any>
  extends DataTableBaseProps<TRow> {
  /**
   * Callback function when row order changes.
   * This event is triggered when a row is dragged and dropped to a new position.
   **/
  columns: DataTableDraggableColumn[];
  onOrderChange?: DataTableOnOrderChangeClbk;
  allowReorder?: boolean;
  onRowClick?: (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    row: TRow,
    columnData: DataTableDraggableColumn,
  ) => void;
}

export interface DataTableSortableProps<TRow extends DataTableRow = any>
  extends DataTableBaseProps<TRow> {
  columns: DataTableSortableColumn[];
  defaultSortState?: DataTableSortStateProps;
  onSortChange?: DataTableOnSortChangeClbk; // Callback function when sort state changes
  onRowClick?: (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    row: TRow,
    columnData: DataTableSortableColumn,
  ) => void;
  isVirtualStaticRow?: boolean;
  hideChevronButton?: boolean;
  keepActionButtonsVisible?: boolean;
}
