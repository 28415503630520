import { Box } from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { DIMENSION_AVATAR_VERY_SMALL } from '../../../constants';
import { selectUserById } from '../../../reducers/accountUsersReducer';
import { selectUserInfoData } from '../../../reducers/userInfoReducer';
import { CommentBase, MentionedUser } from '../comments/types';
import { AvatarPicture } from '../../avatar/AvatarPicture';
import { humanizedTimeAgo } from '../../comments/helper';
import CommentInputWithAvatar from './CommentInputWithAvatar';
import ReplyMenu from './ReplyMenu';
import { TextWithMentions } from './TextWithMentions';
import { INACTIVE_USER_TXT_COLOR } from '../../../constants/user';
import { getIsUserInactive } from '../../../lib/getIsUserActive';

interface CommentWithActionsProps {
  reply: CommentBase;
  isMenuOpen: boolean;
  openMenu: () => void;
  closeMenu: () => void;
  deleteReplyCb: () => void;
  editReplyCb: (commentBody: string, mentionedUserIds: MentionedUser[]) => void;
  pinReplyCb?: (commentId: string) => void;
  isPinned?: boolean;
  isHighlighted?: boolean;
  deleteText: string;
}

const CommentWithActions = ({
  reply,
  isMenuOpen,
  openMenu,
  closeMenu,
  deleteReplyCb,
  editReplyCb,
  pinReplyCb,
  isPinned,
  isHighlighted,
  deleteText,
}: CommentWithActionsProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const { createdById, createdAt, commentBody } = reply;
  const creatorUserInfo = useSelector(selectUserById(createdById));
  const currentUserInfo = useSelector(selectUserInfoData);
  const createdAtString = humanizedTimeAgo(createdAt ?? '');

  const allowEdit = creatorUserInfo && currentUserInfo && creatorUserInfo.id === currentUserInfo.id;
  const isCreatorUserInactive = getIsUserInactive(creatorUserInfo?.status);

  return (
    <Box
      display="flex"
      className="comment-with-actions"
      gap={1.5}
      padding={1}
      borderRadius={1}
      borderBottom={isHighlighted && isPinned ? `1px solid ${colors.grey[120]}` : ''}
      bgcolor={
        //color will be added to design system as sun50
        isHighlighted && isPinned ? '#FFF7EA' : isHover ? colors.grey[100] : colors.white
      }
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
      onClick={() => setIsHover(false)}
    >
      {!isEditing && (
        <Box display="flex" flexDirection="column" flex={1}>
          <Box display="flex" gap={1} alignItems="center">
            {!isEditing && (
              <AvatarPicture
                dimension={DIMENSION_AVATAR_VERY_SMALL}
                avatar={creatorUserInfo}
                isInactive={isCreatorUserInactive}
              />
            )}
            <Box display="flex" gap={1} alignItems="baseline">
              <Typography
                variant="body-medium"
                color={isCreatorUserInactive ? INACTIVE_USER_TXT_COLOR : colors.grey[900]}
                ellipsis
              >
                {!!creatorUserInfo?.name
                  ? creatorUserInfo?.name
                  : creatorUserInfo?.email?.split('@')[0]}
              </Typography>
              <Typography variant="caption" color={colors.grey[700]} ellipsis>
                {createdAtString}
              </Typography>
            </Box>
          </Box>

          <Box pl={DIMENSION_AVATAR_VERY_SMALL + 8 + 'px'}>
            <Typography
              variant="body"
              color={isCreatorUserInactive ? INACTIVE_USER_TXT_COLOR : colors.grey[900]}
              wordBreak="break-word"
            >
              <TextWithMentions text={commentBody} />
            </Typography>
          </Box>
        </Box>
      )}

      {isEditing && (
        <CommentInputWithAvatar
          initialValue={commentBody}
          onConfirm={(commentBody: string, mentionedUserIds: MentionedUser[]) => {
            editReplyCb(commentBody, mentionedUserIds);
            setIsEditing(false);
            closeMenu();
          }}
          onBlur={() => setIsEditing(false)}
        />
      )}

      {allowEdit && !isEditing && (
        <Box visibility={isHover ? 'visible' : 'hidden'}>
          <ReplyMenu
            isMenuOpen={isHover ? isMenuOpen : false}
            openMenu={openMenu}
            closeMenu={closeMenu}
            deleteReplyCb={deleteReplyCb}
            activateEditReply={() => setIsEditing(true)}
            deleteText={deleteText}
            isPinned={isPinned}
            onPinToTop={pinReplyCb ? () => pinReplyCb(reply.id) : undefined}
          />
        </Box>
      )}
    </Box>
  );
};

export default CommentWithActions;
