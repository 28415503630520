import automationHeader from './images/automations-header.png';
import automationSection1 from './images/automations-section1.png';
import automationSection2 from './images/automations-section2.png';
import automationSection3 from './images/automations-section3.png';
import alertsHeader from './images/alerts-header.png';
import alertsSection1 from './images/alerts-section1.png';
import alertsSection2 from './images/alerts-section2.png';
import alertsSection3 from './images/alerts-section3.png';
import dedupeHeader from './images/dedupe-header.png';
import dedupeSection1 from './images/dedupe-section1.png';
import dedupeSection2 from './images/dedupe-section2.png';
import dedupeSection3 from './images/dedupe-section3.png';
import routingHeader from './images/routing-header.png';
import routingSection1 from './images/routing-section1.png';
import routingSection2 from './images/routing-section2.png';
import routingSection3 from './images/routing-section3.png';
import reportsHeader from './images/reports-header.png';
import reportsSection1 from './images/reports-section1.png';
import reportsSection2 from './images/reports-section2.png';
import reportsSection3 from './images/reports-section3.png';
import hubspotHeader from './images/hubspot-header.png';
import hubspotSection1 from './images/hubspot-section1.png';
import hubspotSection2 from './images/hubspot-section2.png';
import hubspotSection3 from './images/hubspot-section3.png';
import aiHeader from './images/ai-header.png';
import aiSection1 from './images/ai-section1.png';
import aiSection2 from './images/ai-section2.png';
import aiSection3 from './images/ai-section3.png';
import documentationHeader from './images/documentation-header.png';
import documentationSection1 from './images/documentation-section1.png';
import documentationSection2 from './images/documentation-section2.png';
import documentationSection3 from './images/documentation-section3.png';
import rollupsHeader from './images/rollups-header.png';
import rollupsSection1 from './images/rollups-section1.png';
import rollupsSection2 from './images/rollups-section2.png';
import rollupsSection3 from './images/rollups-section3.png';
import changeFeedHeader from './images/changeFeed-header.png';
import changeFeedSection1 from './images/changeFeed-section1.png';
import changeFeedSection2 from './images/changeFeed-section2.png';
import changeFeedSection3 from './images/changeFeed-section3.png';
import funnelsHeader from './images/funnels-header.png';
import funnelsSection1 from './images/funnels-section1.png';
import funnelsSection2 from './images/funnels-section2.png';
import funnelsSection3 from './images/funnels-section3.png';
import devopsHeader from './images/devops-header.png';
import devopsSection1 from './images/devops-section1.png';
import devopsSection2 from './images/devops-section2.png';
import devopsSection3 from './images/devops-section3.png';
import { appRoutes } from '../../../../../constants/appRoutes';
import { PaywallSource } from '../../paywallTypes';

export const getPageData = (route?: string) => {
  if (!route) {
    return;
  }
  switch (route) {
    case appRoutes.documentation.route:
      return {
        headerData: {
          headerText: 'Document your Salesforce org in real-time effortlessly',
          subHeaderText:
            'Create up-to-date, AI-powered documentation with clear metadata descriptions and comprehensive dependency mapping for seamless org management.',
          imgSrc: documentationHeader,
          imgAlt: 'Documentation header',
          name: 'Documentation',
          source: PaywallSource.DOCUMENTATION,
        },
        pageTitle: 'Optimize Salesforce management with AI-powered documentation',
        section1: {
          imgSrc: documentationSection1,
          imgAlt: 'Documentation section img',
          title: 'Real-time documentation of your Salesforce org',
          content:
            'Automatically generate up-to-date documentation of your Salesforce environment, ensuring accurate and current records of your configurations.',
        },
        section2: {
          imgSrc: documentationSection2,
          imgAlt: 'Documentation section 2 img',
          title: 'AI-generated descriptions of your metadata',
          content:
            'Leverage AI to create clear and comprehensive descriptions for all metadata components, enhancing understanding and management of your Salesforce setup.',
        },
        section3: {
          imgSrc: documentationSection3,
          imgAlt: 'Documentation section 3 img',
          title: 'Org-wide dependency mapping',
          content:
            'Visualize and analyze dependencies across your entire Salesforce org to better understand relationships and impacts within your configurations.',
        },
      };
    case appRoutes.sfChangeFeed.route:
      return {
        headerData: {
          headerText: 'Stay ahead with real-time change tracking for your Salesforce org',
          subHeaderText:
            'Monitor every change, receive instant alerts for updates, and gain a unified view of all modifications across your Salesforce environments.',
          imgSrc: changeFeedHeader,
          imgAlt: 'Change feed header img',
          name: 'Change Feed',
          source: PaywallSource.CHANGE_FEED,
        },
        pageTitle: `Achieve complete visibility and control with Sweep´s Salesforce change feed`,
        section1: {
          imgSrc: changeFeedSection1,
          imgAlt: 'Change feed section img',
          title: 'Track changes made in your Salesforce org, forever',
          content:
            'Monitor and record every modification within your Salesforce environment in real-time, ensuring a permanent and comprehensive audit trail.',
        },
        section2: {
          imgSrc: changeFeedSection2,
          imgAlt: 'Change feed section 2 img',
          title: 'Alerts for unwanted changes',
          content:
            'Receive immediate notifications for critical or unauthorized updates, enabling prompt response to potential issues.',
        },
        section3: {
          imgSrc: changeFeedSection3,
          imgAlt: 'Change feed section 3 img',
          title: 'Track across all your orgs in one place',
          content:
            'Consolidate change tracking across multiple Salesforce organizations into a single, unified view for streamlined oversight.',
        },
      };
    case appRoutes.funnelMapsPage.route:
      return {
        headerData: {
          headerText: 'Create new business processes with funnel editor',
          subHeaderText:
            'Design, optimize, and manage your business workflows with an intuitive and collaborative visual funnel editor.',
          imgSrc: funnelsHeader,
          imgAlt: 'Funnels header img',
          name: 'Funnels',
          source: PaywallSource.FUNNELS,
        },
        pageTitle: `Optimize and collaborate on your customer journey with Sweep's visual funnel editor`,
        section1: {
          imgSrc: funnelsSection1,
          imgAlt: 'Funnels section img',
          title: 'Full visualization of your customer journey',
          content:
            'Gain a comprehensive visual representation of your entire customer journey, allowing for better analysis and optimization of each stage.',
        },
        section2: {
          imgSrc: funnelsSection2,
          imgAlt: 'Funnels section 2 img',
          title: 'Business context into automations and validations',
          content:
            'Incorporate business context seamlessly into your automations and validations, ensuring that your processes align with organizational objectives.',
        },
        section3: {
          imgSrc: funnelsSection3,
          imgAlt: 'Funnels section 3 img',
          title: 'Collaborate with teammates',
          content:
            'Work collaboratively with your team in real-time within the Funnel Editor, enhancing efficiency and ensuring alignment across departments.',
        },
      };
    case appRoutes.rollups.route:
      return {
        headerData: {
          headerText: 'Simplify data aggregation with scalable rollups',
          subHeaderText:
            'Create up-to-date, AI-powered documentation with clear metadata descriptions and comprehensive dependency mapping for seamless org management.',
          imgSrc: rollupsHeader,
          imgAlt: 'Rollups header',
          name: 'Rollups',
          source: PaywallSource.ROLLUPS,
        },
        pageTitle: 'Optimize Salesforce management with AI-powered documentation',
        section1: {
          imgSrc: rollupsSection1,
          imgAlt: 'Rollups section img',
          title: 'Rollup data from any relationship',
          content:
            'Effortlessly aggregate data across both master-detail and lookup relationships within Salesforce, eliminating mid-configuration frustration.',
        },
        section2: {
          imgSrc: rollupsSection2,
          imgAlt: 'Rollups section 2 img',
          title: 'Scalable architecture – no CPU timeouts',
          content:
            'Benefit from a robust architecture designed to handle large data volumes efficiently, ensuring rollup processes complete without CPU timeouts.',
        },
        section3: {
          imgSrc: rollupsSection3,
          imgAlt: 'Rollups section 3 img',
          title: 'Use rollups to trigger automation',
          content:
            'Leverage rollup fields to initiate automated workflows and processes throughout your Salesforce organization, enhancing operational efficiency.',
        },
      };
    case appRoutes.automations.route:
      return {
        headerData: {
          headerText: 'Build custom Salesforce automations ',
          subHeaderText:
            'Create no-code, native Salesforce automations to streamline your team’s workflows.',
          imgSrc: automationHeader,
          imgAlt: 'Automations header',
          name: 'Automations',
          source: PaywallSource.AUTOMATIONS,
        },
        pageTitle: 'Unlock seamless automation for complex workflows in Salesforce',
        section1: {
          imgSrc: automationSection1,
          imgAlt: 'Automations section img',
          title: 'Record creation & updates',
          content:
            'Automate the creation and updating of Salesforce records to maintain data integrity and optimize workflows across teams.',
        },
        section2: {
          imgSrc: automationSection2,
          imgAlt: 'Automations section 2 img',
          title: 'Process orchestration',
          content:
            'Automate complex, multi-step workflows with conditional logic and tasks assignments to ensure consistent and efficient process execution.',
        },
        section3: {
          imgSrc: automationSection3,
          imgAlt: 'Automations section 3 img',
          title: 'Execute third party integrations',
          content:
            'Automate the creation and updating of Salesforce records to maintain data integrity and optimize workflows across teams.',
        },
      };
    case appRoutes.alerts.route:
      return {
        headerData: {
          headerText: 'Set up seamless Salesforce & Slack integration',
          subHeaderText:
            'Connect your team to the revenue process with real-time Salesforce updates directly in Slack.',
          imgSrc: alertsHeader,
          imgAlt: 'Alerts header',
          name: 'Alerts',
          source: PaywallSource.ALERTS,
        },
        pageTitle: 'Turn key pipeline events into actionable alerts',
        section1: {
          imgSrc: alertsSection1,
          imgAlt: 'Alerts section img',
          title: 'Custom alerts & notifications',
          content:
            'Automate the creation and updating of Salesforce records to maintain data integrity and optimize workflows across teams.',
        },
        section2: {
          imgSrc: alertsSection2,
          imgAlt: 'Alerts section 2 img',
          title: 'Update Salesforce directly from Slack',
          content:
            'Enable your team to update Salesforce directly from Slack, turning notifications into actionable tasks without leaving the platform.',
        },
        section3: {
          imgSrc: alertsSection3,
          imgAlt: 'Alerts section 3 img',
          title: 'Automate deal room creation & updates',
          content:
            'Automate the creation and updates of deal rooms in Slack based on key events to keep your team aligned and up-to-date.',
        },
      };
    case appRoutes.dedup.route:
      return {
        headerData: {
          headerText: 'Simplify data management with automated deduplication',
          subHeaderText:
            'Eliminate duplicates and ensure your Salesforce data stays accurate and consistent.',
          imgSrc: dedupeHeader,
          imgAlt: 'Dedupe header',
          name: 'Dedupe & Matching',
          source: PaywallSource.DEDUP,
        },
        pageTitle: 'Keep your data clean with deduplication and matching',
        section1: {
          imgSrc: dedupeSection1,
          imgAlt: 'Dedupe section img',
          title: 'Customize your deduplication logic',
          content:
            'Configure custom deduplication rules with advanced filters, tie-breakers, and timing control to support your data needs.',
        },
        section2: {
          imgSrc: dedupeSection2,
          imgAlt: 'Dedupe section 2 img',
          title: 'Auto-merge duplicates across Salesforce objects',
          content:
            'Automatically identify and merge duplicate records—such as leads, contacts, and accounts—across Salesforce objects, ensuring clean and consistent data.',
        },
        section3: {
          imgSrc: dedupeSection3,
          imgAlt: 'Dedupe section 3 img',
          title: 'Native matching for any Salesforce object',
          content:
            'No more manually matching leads to accounts. Automate this process and ensure new leads are accurately linked to existing accounts.',
        },
      };
    case appRoutes.routing.route:
      return {
        headerData: {
          headerText: 'Boost pipeline speed with automated assignment & routing',
          subHeaderText:
            'Create and manage groups, territories, and assignment rules for any Salesforce object.',
          imgSrc: routingHeader,
          imgAlt: 'Routing header',
          name: 'Routing',
          source: PaywallSource.ROUTING,
        },
        pageTitle: 'Effortlessly assign leads and opportunities with smart routing',
        section1: {
          imgSrc: routingSection1,
          imgAlt: 'Routing section img',
          title: 'Customizable assignment logic',
          content:
            'Create custom assignment rules tailored to any scenario, ensuring leads, opportunities, and other records connect with the right person based on your specific Salesforce setup.',
        },
        section2: {
          imgSrc: routingSection2,
          imgAlt: 'Routing section 2 img',
          title: 'Optimize territory & group management',
          content:
            'Define territories, teams, and workload limits to balance assignments efficiently. Quickly adjust rules based on evolving sales strategies, ensuring fair distribution across geographies, roles, or custom criteria.',
        },
        section3: {
          imgSrc: routingSection3,
          imgAlt: 'Routing section 3 img',
          title: 'Automate record assignment for faster routing',
          content:
            'Ensure every record reaches the right team member instantly using customizable routing criteria, round robin distribution, and scheduled assignments—reducing manual effort and improving response times.',
        },
      };
    case appRoutes.reports.route:
      return {
        headerData: {
          headerText: 'Access real-time insights with Salesforce reporting',
          subHeaderText:
            'Make data-driven decisions with advanced reporting and real-time dashboards that streamline your workflows and boost sales performance.',
          imgSrc: reportsHeader,
          imgAlt: 'Reports header',
          name: 'Reports',
          source: PaywallSource.REPORTS,
        },
        pageTitle: 'Track key sales metrics & boost pipeline performance',
        section1: {
          imgSrc: reportsSection1,
          imgAlt: 'Reports section img',
          title: 'Improve funnel conversion',
          content:
            'Gain a clear understanding of your conversion rates across your sales funnels. Identify areas for improvement so you can optimize your process and boost your pipeline predictability.',
        },
        section2: {
          imgSrc: reportsSection2,
          imgAlt: 'Reports section 2 img',
          title: 'Track velocity across all funnels',
          content:
            'Track the velocity of records through the different steps of a deal to identify bottlenecks and opportunities for optimization',
        },
        section3: {
          imgSrc: reportsSection3,
          imgAlt: 'Reports section 3 img',
          title: 'Automatically update reports with funnel changes',
          content:
            'Experience dynamic reporting that adjusts in real-time with changes to your sales funnels, eliminating the need to manually rebuild reports.',
        },
      };
    case appRoutes.devopsCenter.route:
      return {
        headerData: {
          headerText: 'Easily compare & deploy Sweep elements',
          subHeaderText:
            'Take full control of your Sweep configurations by seamlessly managing deployments across Sandbox, Production, and other Salesforce  environments.',
          imgSrc: devopsHeader,
          imgAlt: 'Devops header',
          name: 'Compare & Deploy',
          source: PaywallSource.DEVOPS_CENTER,
        },
        pageTitle: 'See what’s been deployed to any of your Salesforce environments',
        section1: {
          imgSrc: devopsSection1,
          imgAlt: 'Devops section img',
          title: 'Track Sweep elements across environments',
          content:
            'Gain a clear, side-by-side view of all deployed Sweep elements—including automations, lead routing rules, assignment logic, and more. Instantly identify differences and ensure consistency between environments.',
        },
        section2: {
          imgSrc: devopsSection2,
          imgAlt: 'Devops section 2 img',
          title: 'Deploy with confidence',
          content:
            'Compare versions of Sweep elements and identify changes before deploying. Whether you need to update a single rule, a set of automations, or a full process, you can deploy with precision and ease.',
        },
        section3: {
          imgSrc: devopsSection3,
          imgAlt: 'Devops section 3 img',
          title: 'Simplify Sweep deployment & version management',
          content:
            'Ensure every Sweep configuration is properly deployed across environments. Streamline updates, prevent misconfigurations, and maintain a smooth, reliable deployment process with built-in comparison and deployment tools.',
        },
      };
    case appRoutes.hubspot.route:
      return {
        headerData: {
          headerText: 'Automate HubSpot-to-Salesforce Campaign Syncing',
          subHeaderText:
            'Visualize and manage campaign workflows across platforms, ensuring both HubSpot and Salesforce stay in sync for complete marketing attribution.',
          imgSrc: hubspotHeader,
          imgAlt: 'Hubspot header',
          name: 'Hubspot',
          source: PaywallSource.HUBSPOT,
        },
        pageTitle: 'Bridge the gap between marketing and sales with seamless campaign attribution',
        section1: {
          imgSrc: hubspotSection1,
          imgAlt: 'Hubspot section img',
          title: 'Manage campaigns between Salesforce and HubSpot in one place',
          content:
            'Prevent duplicate records, mismatched campaign data, and attribution gaps by ensuring marketing and sales operate with a single, accurate source of truth.',
        },
        section2: {
          imgSrc: hubspotSection2,
          imgAlt: 'Hubspot section 2 img',
          title: 'Automate campaign enrollment & syncing',
          content:
            'Define automated rules in HubSpot to instantly sync contacts into Salesforce campaigns, eliminating manual effort and keeping systems aligned.',
        },
        section3: {
          imgSrc: hubspotSection3,
          imgAlt: 'Hubspot section 3 img',
          title: 'Unify marketing attribution reporting',
          content:
            'Combine HubSpot and Salesforce data into a single source of truth, creating reports that show the true ROI of your marketing campaigns.',
        },
      };
    case appRoutes.aiAssistant.route:
      return {
        headerData: {
          headerText: 'Transform end user support with AI',
          subHeaderText:
            'Respond faster, reduce time spent on support, and provide end users with a seamless experience. Automate routine tasks and deliver real-time answers directly within Salesforce.',
          imgSrc: aiHeader,
          imgAlt: 'AI header',
          name: 'AI Assistant',
          source: PaywallSource.AI_ASSISTANT,
        },
        pageTitle: 'Empower end user self-service with Salesforce support AI assistant',
        section1: {
          imgSrc: aiSection1,
          imgAlt: 'AI section img',
          title: 'Easily troubleshoot Salesforce issues',
          content:
            'End users can quickly copy and paste error messages into the chat for instant troubleshooting, with the option to escalate to an admin if needed.',
        },
        section2: {
          imgSrc: aiSection2,
          imgAlt: 'AI section 2 img',
          title: 'Automate ticket creation and sync with your system',
          content:
            'Create and sync support ticket from within Salesforce, ensuring integration with your existing support workflows.',
        },
        section3: {
          imgSrc: aiSection3,
          imgAlt: 'AI section 3 img',
          title: 'AI-powered, admin-reviewed support',
          content:
            'Users can ask the AI-powered "Virtual Admin" questions, and all conversations are recorded for your review, giving you control and insight into the process.',
        },
      };
    default:
      return undefined;
  }
};
