import { Typography, colors } from '@sweep-io/sweep-design';
import { SweepTypographyVariants } from '@sweep-io/sweep-design/dist/components/Typography/types';
import useResizeObserver from 'use-resize-observer';
import { useRef, useState } from 'react';
import { isEllipsisActive } from '../../lib/isEllipsisActive';
import StyledTooltip from './StyledTooltip';
import { TooltipProps } from '@mui/material';

type TruncatedTextTooltipProps = {
  children: any;
  zIndex?: number;
  variant?: SweepTypographyVariants;
  dataTestId?: string;
  color?: string;
  turnOffElipsis?: boolean;
  title?: string; //should be used in case "children" is a react-node (for example "Typography")
} & Partial<Omit<TooltipProps, 'children' | 'title'>>;

/**
 * Truncate text and display in a tooltip
 * @param maxWidth
 */

const TruncatedTextTooltip = ({
  children,
  zIndex = 11500,
  variant = 'body',
  dataTestId = '',
  color = colors.grey[900],
  turnOffElipsis,
  title,
  ...tooltipProps
}: TruncatedTextTooltipProps) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleOverflow = () => {
    setIsOverflow(isEllipsisActive(ref.current));
  };

  useResizeObserver<HTMLDivElement>({
    ref: ref,
    onResize: handleOverflow,
  });

  return (
    <StyledTooltip
      placement={'top-start'}
      disableHoverListener={!isOverflowed}
      {...tooltipProps}
      title={title ?? children}
      sx={{
        offset: '0 0 -25px 20px !important',
        zIndex,
        '& .MuiTooltip-tooltip': {
          wordBreak: 'break-word',
          whiteSpace: 'normal'
        }
      }}
    >
      <Typography
        ref={ref}
        variant={variant}
        whiteSpace="nowrap"
        ellipsis={!turnOffElipsis}
        data-testid={dataTestId}
        color={color}
      >
        {children}
      </Typography>
    </StyledTooltip>
  );
};

export { TruncatedTextTooltip };
