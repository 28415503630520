export enum AutomationType {
  Default = 'Default',
  Assignment = 'Assignment',
  Alert = 'Alert',
  Dedupe = 'Dedupe',
  Matching = 'Matching',
  ScheduledAssignment = 'ScheduledAssignment',
  ScheduledReport = 'ScheduledReport',
  ScheduledJob = 'ScheduledJob',
  MarketingAttributionModel = 'MarketingAttribution',
}
