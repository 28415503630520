import * as React from "react";
const SvgImpactAnalysisAvatar = (props) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("rect", { width: 24, height: 24, rx: 12, fill: "#E1EBFF", style: {
  fill: "#E1EBFF",
  fill: "color(display-p3 0.8833 0.9230 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clip0_2_29504)" }, /* @__PURE__ */ React.createElement("path", { d: "M7.10335 9.63147V11.8109H19.4537V9.63147H7.10335Z", fill: "#577EE4", style: {
  fill: "#577EE4",
  fill: "color(display-p3 0.3397 0.4954 0.8958)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M14.3683 4.54602H12.1888V16.8964H14.3683V4.54602Z", fill: "#577EE4", style: {
  fill: "#577EE4",
  fill: "color(display-p3 0.3397 0.4954 0.8958)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M13.2787 12.2627L11.7376 10.7216L4.54626 17.9129L6.08738 19.454L13.2787 12.2627Z", fill: "#2C1A4F", style: {
  fill: "#2C1A4F",
  fill: "color(display-p3 0.1730 0.1028 0.3083)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M9.68311 5.58417L8.14199 7.12529L16.875 15.8583L18.4162 14.3172L9.68311 5.58417Z", fill: "#577EE4", style: {
  fill: "#577EE4",
  fill: "color(display-p3 0.3397 0.4954 0.8958)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M14.8196 10.7214L13.2785 9.18025L11.7376 10.7216L13.2787 12.2627L14.8196 10.7214Z", fill: "#F8F9FA", style: {
  fill: "#F8F9FA",
  fill: "color(display-p3 0.9725 0.9765 0.9804)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M18.4156 7.1251L16.8745 5.58397L13.2785 9.18025L14.8196 10.7214L18.4156 7.1251Z", fill: "#577EE4", style: {
  fill: "#577EE4",
  fill: "color(display-p3 0.3397 0.4954 0.8958)",
  fillOpacity: 1
} })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "clip0_2_29504" }, /* @__PURE__ */ React.createElement("rect", { width: 16, height: 16, fill: "white", style: {
  fill: "white",
  fillOpacity: 1
}, transform: "translate(4 4)" }))));
export default SvgImpactAnalysisAvatar;
