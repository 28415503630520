import { Box, Popover, Select } from '@mui/material';
import { Button, IconButton, Typography, colors } from '@sweep-io/sweep-design';
import { Sort as SortIcon } from '@sweep-io/sweep-design/dist/icons';
import { useState } from 'react';
import { StyledListItem } from './StyledListItem';

export interface SweepSortOption<T extends string = string> {
  value: T;
  label: string;
}

export enum SortDisplayTypes {
  Icon = 'Icon',
  With_Value = 'With_Value',
  Label_Only = 'Label_Only',
  IconAndLabel = 'IconAndLabel',
}

interface SweepSortProps<T extends string = string> {
  options: SweepSortOption<T>[];
  selectedValue: string;
  onChange: (option: T) => any;
  displayType?: SortDisplayTypes;
}

export function SweepSort<T extends string = string>({
  options,
  selectedValue,
  onChange,
  displayType,
}: SweepSortProps<T>) {
  const [sortMenuElement, setSortMenuElement] = useState<HTMLButtonElement | null>(null);

  const handleMenuElementClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSortMenuElement(event.currentTarget);
  };
  const handleMenuElementClose = () => {
    setSortMenuElement(null);
  };
  const isMenuOpen = Boolean(sortMenuElement);
  const renderDisplayType = displayType || SortDisplayTypes.With_Value;

  const renderPopover = (
    <Popover
      open={isMenuOpen}
      anchorEl={sortMenuElement}
      onClose={handleMenuElementClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={{
        '& .MuiPopover-paper': {
          p: '8px',
        },
      }}
    >
      {options.map((option) => (
        <StyledListItem
          key={option.value}
          title={option.label}
          onClick={() => {
            onChange(option.value);
            handleMenuElementClose();
          }}
          isActive={option.value === selectedValue}
          sx={{ minWidth: '200px' }}
        />
      ))}
    </Popover>
  );
  return (
    <>
      {renderDisplayType === SortDisplayTypes.Icon && (
        <Box>
          <IconButton onClick={handleMenuElementClick} variant="outlined">
            <SortIcon />
          </IconButton>

          {renderPopover}
        </Box>
      )}
      {renderDisplayType === SortDisplayTypes.Label_Only && (
        <>
          <Box>
            <Button
              onClick={handleMenuElementClick}
              type="secondary"
              variant="flat"
              endIconName={isMenuOpen ? 'ChevronUp' : 'ChevronDown'}
            >
              Sort by
            </Button>
          </Box>

          {renderPopover}
        </>
      )}
      {renderDisplayType === SortDisplayTypes.IconAndLabel && (
        <>
          <Button
            onClick={handleMenuElementClick}
            type="secondary"
            size="tiny"
            variant="outlined"
            startIconName="Sort"
            pressed={isMenuOpen}
          >
            Sort
          </Button>

          {renderPopover}
        </>
      )}
      {renderDisplayType === SortDisplayTypes.With_Value && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <SortIcon color={colors.grey[700]} />
          <Typography variant="caption" color={colors.grey[700]}>
            Sort
          </Typography>
          <Select
            variant="outlined"
            sx={{
              background: colors.grey[100],
              minHeight: '32px',
              '&:hover': {
                background: colors.grey[120],
              },
              '& .MuiSelect-icon': {
                transform: 'scale(1.4)',
              },
              '& fieldset': { border: 'none' },
              '& .MuiSelect-select': {
                paddingLeft: '8px',
                paddingBottom: 0,
                paddingTop: 0,
                fontSize: '12px',
                fontWeight: '600',
              },
            }}
            value={selectedValue}
            onChange={(e) => onChange(e.target.value as T)}
          >
            {options.map((option) => (
              <StyledListItem
                key={option.value}
                title={option.label}
                onClick={(e) => onChange(e.target.value as T)}
                isActive={option.value === selectedValue}
                sx={{ minWidth: '200px' }}
              />
            ))}
          </Select>
        </Box>
      )}
    </>
  );
}
