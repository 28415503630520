import { Stack, Box } from '@mui/material';
import { Typography, Button } from '@sweep-io/sweep-design';
import emptyStateImage from './emptyState.png';
import { useMarketingAttributionModels } from './useMarketingAttributionModels';

const listItems: { strong: string; text: string }[] = [
  {
    strong: 'Easy Configuration:',
    text: 'Set up powerful attribution models with an intuitive interface—no custom code or lengthy Salesforce projects required.',
  },
  {
    strong: 'Centralized Data Object:',
    text: 'Access all your attribution-related data in one place, simplifying queries for in-depth marketing analysis.',
  },
  {
    strong: 'Advanced Insights & Reporting:',
    text: 'Track multiple attribution models simultaneously, providing real-time insights directly tied to revenue performance.',
  },
];

export const EmptyState = () => {
  const { addMarketingAttributionModelsTemplates } = useMarketingAttributionModels();

  return (
    <Stack justifyContent="center" height="100%" alignItems="center">
      <Stack
        direction="row"
        justifyContent="space-between"
        width="1456px"
        padding="10% 64px 0"
        height="100%"
        gap={12}
      >
        <Stack width="630px" gap={3} flex="auto">
          <Typography variant="h1">
            Up-level your marketing insights with Sweep's Attribution Product
          </Typography>

          <Box component="ul" sx={{ paddingInlineStart: 2.5, m: 0 }}>
            {listItems.map((item, idx) => (
              <Box
                component="li"
                key={item.strong}
                sx={{ mb: idx === listItems.length - 1 ? 0 : 1 }}
              >
                <Typography variant="body-bold">
                  {item.strong} <Typography variant="body">{item.text}</Typography>
                </Typography>
              </Box>
            ))}
          </Box>

          <Typography variant="body">
            Maximize your marketing ROI with attribution that connects every campaign to business
            outcomes.
          </Typography>
          <Box>
            <Button variant="filled" onClick={() => addMarketingAttributionModelsTemplates()}>
              Activate Attribution Models
            </Button>
          </Box>
        </Stack>
        <img src={emptyStateImage} alt="empty state" width="688px" height="288px" />
      </Stack>
    </Stack>
  );
};
