import { Box, TableCell as MuiTableCell } from '@mui/material';
import { TruncatedTextTooltip } from '../TruncatedTextTooltip';
import {
  DataTableBaseColumn,
  DataTableDraggableColumn,
  DataTableRow,
  DataTableSortableColumn,
  DataTableVariant,
} from './TableTypes';
import { colors } from '@sweep-io/sweep-design';
import classNames from 'classnames';
import { dataTableVariants } from './dataTableVariants';
import { ReactNode } from 'react';
import { CellDivider } from './CellDivider';

interface RowProps<TRow extends DataTableRow> {
  row: TRow;
  columns: DataTableSortableColumn<TRow>[] | DataTableBaseColumn[] | DataTableDraggableColumn[];
  onClick?: (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    columnData: DataTableSortableColumn<TRow> | DataTableBaseColumn | DataTableDraggableColumn,
  ) => void;
  variant: DataTableVariant;
  dragHandle?: ReactNode;
  allowReorder?: boolean;
  isRenderEmpty: boolean;
}

function TableRowCells<TRow extends DataTableRow = any>({
  row,
  columns,
  onClick,
  variant,
  dragHandle,
  allowReorder,
  isRenderEmpty,
}: RowProps<TRow>) {
  return columns.map((column, index) => {
    const cell = column.renderer
      ? column.renderer(row[column.field], row.id, column.field, row)
      : row[column.field];
    const isPlainText = typeof cell === 'string';
    const withHandle = allowReorder && 'showDragHandle' in column && column.showDragHandle;
    const withDivider = index < columns.length - 1;

    const cellContent =
      isPlainText && !column.renderer ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
          }}
          justifyContent={column.justifyContent}
        >
          <TruncatedTextTooltip
            variant={dataTableVariants[variant].fontVariant}
            color={row.disabled ? dataTableVariants[variant].fontColor : undefined}
          >
            {cell}
          </TruncatedTextTooltip>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            '& .MuiTypography-root': {
              color: dataTableVariants[variant].fontColor,
            },
            '& .avatar .MuiTypography-root': {
              color: colors.blue[500],
            },
          }}
          justifyContent={column.justifyContent}
        >
          {cell}
        </Box>
      );

    return (
      <MuiTableCell
        sx={{
          width: column.width ?? '100%',
          color: dataTableVariants[variant].fontColor,
          '& .MuiTypography-root': {
            color: dataTableVariants[variant].fontColor,
          },
          cursor: !!onClick ? 'pointer' : 'default',
          padding: dataTableVariants[variant].cellPadding,
        }}
        className={classNames(column.className, 'SweepDataTableCell')}
        key={`row${row.id}-col${index}`}
        onClick={!!onClick ? (e) => onClick(e, column) : undefined}
      >
        {!isRenderEmpty && withHandle && (
          <Box
            sx={{
              width: 'fit-content',
              display: 'flex',
              minWidth: column.minWidth ?? dataTableVariants[variant]?.minRowWidth,
              gap: 2,
              alignItems: 'center',
              justifyContent: 'center',
              color: dataTableVariants[variant].fontColor,
              '& .MuiTypography-root': {
                color: dataTableVariants[variant].fontColor,
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'grab',
              }}
            >
              <Box display="flex">{dragHandle}</Box>
            </Box>
            {cellContent}
            {withDivider && <CellDivider />}
          </Box>
        )}

        {!isRenderEmpty && !withHandle && (
          <Box
            display="flex"
            alignItems="center"
            alignContent="center"
            justifyContent="space-between"
          >
            <Box
              width={column.width ?? '100%'}
              sx={{
                minWidth: column.minWidth ?? dataTableVariants[variant]?.minRowWidth,
              }}
            >
              {cellContent}
            </Box>
            {withDivider && <CellDivider />}
          </Box>
        )}
      </MuiTableCell>
    );
  });
}

export default TableRowCells;
