import { useCallback, useMemo } from 'react';
import { calculateAndAssignEdgeConnectionTypes } from '../assignConnectionTypeToEdges';
import {
  convertGridPositionToXYPosition,
  convertXYPositionToGridIndex,
} from '../helpers/gridPositioningUtils';
import { createObjectOperations } from '../node-changes-event-handlers/objectOperations';
import { MarkerType, useReactFlow } from '@xyflow/react';
import { keyBy } from 'lodash';
import { VisibilityLayers } from '../../../types/enums/VisibilityLayers';
import { SweepCanvasEdge, CanvasEdgeConnectionType, CanvasElementType } from '../canvas-types';
import { RFEdgeFloatingEdge, RFEdgeData } from '../edges';
import { SweepCanvasReactFlowEdgeDataType } from '../edges/SweepCanvasReactFlowEdgeDataType';
import { getObjectTypeColor } from '../helpers/getObjectTypeColor';
import { isNbNode } from '../helpers/isNbNode';
import { useSweepCanvasState } from '../internal-context/CanvasStateContext';
import {
  NodePositionTransformation,
  shiftNodesRight,
} from '../helpers/nodePositionManagementHelper';
import { RFNodeEditRegularNode, RfNodeRegularNode } from '../canvas-types/nodeTypesData';
import { useNewEditableNodeFactory } from './useNewEditableNodeFactory';
import { useTemporaryTransformationsCtx } from './TemporaryTransformationsCtx';
import { useNodeAnimations } from '../useNodeAnimations';
import { updateGroupBoundingBox } from '../node-changes-event-handlers/updateGroupBoundingBox';
import {
  getEdgeHiddenProperty,
  getZoomLevels,
  useGetShowButtonsOnHighlight,
} from '../effects/useContextZoomEffect';
import { useHighlightNodesAndEdges } from '../highlight/useHighlightNodesAndEdges';
import { useSweepCanvasPropsCtx } from '../internal-context/SweepCanvasPropsCtx';
import { addXYPositions } from '../helpers/xYPositionOperation';
import { uniqueId } from '../../../lib/uniqueId';

export const useEdgesFactory = () => {
  const {
    sweepEdges,
    sweepNodes,
    sweepGroups,
    canvasMode,
    onEdgeDeleteClick,
    onGateClick,
    readonly,
    simpleHighlightedEntities,
    visibilityMap,
  } = useSweepCanvasPropsCtx();
  const { getCanvasEdges, getCanvasNodes, setCanvasEdges, setCanvasNodes } = useSweepCanvasState();
  const { clearHighlight } = useHighlightNodesAndEdges();
  const { withNodeRightShiftAnimation } = useNodeAnimations();
  const { getZoom } = useReactFlow();

  const _sweepNodesForEdges = useMemo(
    () =>
      [...sweepNodes, ...sweepGroups].map((node) => ({
        id: node.nodeType === 'group' ? `label-${node.id}` : node.id,
        objectType: node.objectType,
        parentId: node.nodeType === 'group' ? undefined : node.parentId,
        nodeType: node.nodeType,
        isNb: node.nodeType === 'node' && isNbNode(node),
      })),
    [sweepGroups, sweepNodes],
  );

  const sweepNodesForEdgesByKey = useMemo(
    () => keyBy(_sweepNodesForEdges, 'id'),
    [_sweepNodesForEdges],
  );

  const { createNewNode } = useNewEditableNodeFactory();
  const transformedOriginalValuesToRestore = useTemporaryTransformationsCtx();

  const onEdgeAddBetweenNodesClick = useCallback(
    (edgeId: string) => {
      const { getObject: getEdge } = createObjectOperations(getCanvasEdges());
      const edge = getEdge(edgeId);

      if (!edge) return;
      const nodes = getCanvasNodes();
      const { getObject: getNode } = createObjectOperations(nodes);

      const sourceNode = nodes.find(
        (node) => node.id === edge.source && node.type === CanvasElementType.REGULAR,
      ) as RfNodeRegularNode | undefined;
      if (!sourceNode) return;

      const siblingNodesInSameGroup = nodes.filter(
        (n) => n.parentId === sourceNode.parentId && n.type === CanvasElementType.REGULAR,
      ) as RfNodeRegularNode[];

      const positionChanges = shiftNodesRight({
        startingNode: sourceNode,
        nodes: siblingNodesInSameGroup,
        includeOwn: false,
      });

      const originalNodePositions: NodePositionTransformation[] = positionChanges
        .map((nodeTransformation) => {
          const node = getNode(nodeTransformation.id);

          if (node) {
            return {
              id: node.id,
              position: node.position,
            };
          }
        })
        .filter(Boolean) as NodePositionTransformation[];

      const [newNodeId, newEdgeId] = [uniqueId(), uniqueId()];

      transformedOriginalValuesToRestore.current = {
        ...transformedOriginalValuesToRestore.current,
        nodesToRemove: [newNodeId],
        edgesToRemove: [newEdgeId],
        nodePositions: originalNodePositions,
        edgeSourceAndTarget: [{ id: edge.id, source: edge.source, target: edge.target }],
      };

      withNodeRightShiftAnimation({
        originalNodePositions,
        nodeIds: positionChanges.map((node) => node.id),
        onComplete: () => {
          const newNodeGridPosition = convertXYPositionToGridIndex(sourceNode.position);
          newNodeGridPosition.column = newNodeGridPosition.column + 1;
          const newNode = createNewNode({
            newNodeId,
            position: convertGridPositionToXYPosition(newNodeGridPosition),
            isNb: Boolean(sourceNode.data.isNb),
            parentId: sourceNode.parentId,
            objectType: sourceNode.data.objectType,
            originStepName: sourceNode.data.name,
            sourceId: sourceNode.id,
            targetId: edge.target,
            origin: {
              type: 'edge',
              id: edge.id,
            },
          });
          const newEdge: RFEdgeFloatingEdge = {
            id: newEdgeId,
            source: newNode.id,
            target: edge.target,
            type: 'floating',
            markerEnd: { type: MarkerType.Arrow },
            data: {
              label: '',
              type: SweepCanvasReactFlowEdgeDataType.DASHED_CIRCLE,
              sourceColor: getObjectTypeColor(sourceNode.data.objectType).connection,
              targetColor: getObjectTypeColor(sourceNode.data.objectType).connection,
              gateColor: getObjectTypeColor(sourceNode.data.objectType).connection,
              connectionType: CanvasEdgeConnectionType.Bezier,
            },
            deletable: false,
          };

          setCanvasEdges((edges, edgeOperations) => {
            edgeOperations.setObject(edge.id, (edge) => ({
              ...edge,
              target: newNodeId,
            }));
            edgeOperations.addObject(newEdge);
            return edgeOperations.getObjects();
          });

          setCanvasNodes((_, nodeOperations) => {
            nodeOperations.addObject<RFNodeEditRegularNode>(newNode);
            updateGroupBoundingBox({
              groupNodeId: sourceNode.parentId as string,
              nodeOperations,
            });

            return nodeOperations.getObjects();
          });
          setTimeout(clearHighlight, 100);
        },
      });
    },
    [
      clearHighlight,
      createNewNode,
      getCanvasEdges,
      getCanvasNodes,
      setCanvasEdges,
      setCanvasNodes,
      transformedOriginalValuesToRestore,
      withNodeRightShiftAnimation,
    ],
  );

  const showButtonsOnHighlight = useGetShowButtonsOnHighlight();

  const sweepEdgeToReactFlowEdge = useCallback(
    (sweepEdge: SweepCanvasEdge): RFEdgeFloatingEdge => {
      const { id, data, source: _source, target: _target } = sweepEdge;
      let source = _source;
      let target = _target;
      let sourceNode = sweepNodesForEdgesByKey[source];
      let targetNode = sweepNodesForEdgesByKey[target];

      if (!sourceNode) {
        source = `label-${_source}`;
        sourceNode = sweepNodesForEdgesByKey[source];
        if (!sourceNode) {
          source = _source;
        }
      }
      if (!targetNode) {
        target = `label-${_target}`;
        targetNode = sweepNodesForEdgesByKey[target];
        if (!targetNode) {
          target = _target;
        }
      }

      const sourceParentId = (sourceNode as any)?.parentId;
      const targetParentId = (targetNode as any)?.parentId;

      const sourceColors = getObjectTypeColor(sourceNode?.objectType);
      const targetColors = getObjectTypeColor(targetNode?.objectType || sourceNode?.objectType);

      const draggingGroupNodeId = undefined; // TODO: Add this
      const isDraggingGroup =
        draggingGroupNodeId === sourceParentId || draggingGroupNodeId === targetParentId;

      const isDetourConnection =
        sweepEdge.data.connectionType === CanvasEdgeConnectionType.HorizontalDetour ||
        sweepEdge.data.connectionType === CanvasEdgeConnectionType.VerticalDetour;

      let _connectionType = sweepEdge.data.connectionType;

      if (
        isDraggingGroup &&
        sweepEdge.data.type === SweepCanvasReactFlowEdgeDataType.REMOVABLE &&
        isDetourConnection
      ) {
        _connectionType = CanvasEdgeConnectionType.Bezier;
      }

      const { isZoomLowerThan0_3 } = getZoomLevels(getZoom());

      const _data: RFEdgeData = {
        ...data,
        onEdgeAddBetweenBtnClick: onEdgeAddBetweenNodesClick,
        sourceColor: sourceColors.connection,
        targetColor: targetColors.connection,
        gateColor: sourceColors.step,
        onGateClick,
        readonly,
        connectionType: _connectionType || CanvasEdgeConnectionType.Bezier,
        noAddBetweenBtn: sourceNode.isNb || targetNode?.isNb,
        sourceParentId,
        targetParentId,
        onEdgeDeleteClick,
        canvasMode,
        showGates: visibilityMap[VisibilityLayers.GATES],
        hideNurturingEdges: !visibilityMap[VisibilityLayers.NURTURING_STEPS],
        highlightType: null,
        showButtonsOnHighlight,
        simpleHighlighted: simpleHighlightedEntities?.includes(id),
      };

      const hasSameParents = sourceParentId === targetParentId;

      const edge: RFEdgeFloatingEdge = {
        id,
        source,
        target,
        type: 'floating',
        markerEnd: { type: MarkerType.Arrow },
        data: _data,
        zIndex: 0,
        deletable: false,
        hidden: getEdgeHiddenProperty({
          sourceParentId,
          targetParentId,
          isZoomLowerThan0_3,
        }),
        className: hasSameParents
          ? 'floating-edge-connection-same-parents'
          : 'floating-edge-connection-different-parents',
      };
      return edge;
    },
    [
      canvasMode,
      getZoom,
      onEdgeAddBetweenNodesClick,
      onEdgeDeleteClick,
      onGateClick,
      readonly,
      showButtonsOnHighlight,
      simpleHighlightedEntities,
      sweepNodesForEdgesByKey,
      visibilityMap,
    ],
  );

  const calculateEdges = useCallback(() => {
    const edges = sweepEdges.map(sweepEdgeToReactFlowEdge);
    const edgesOperations = createObjectOperations(edges);
    const sweepGroupsOperations = createObjectOperations(sweepGroups);

    const edgesWithDetours = calculateAndAssignEdgeConnectionTypes({
      edgeOperations: edgesOperations,
      nodesPositions: sweepNodes.map((node) => {
        const parentGroupPosition = node.parentId
          ? sweepGroupsOperations.getObject(node.parentId)?.position
          : undefined;

        const parentGroupXYPosition = convertGridPositionToXYPosition(parentGroupPosition);
        const nodeXYPosition = convertGridPositionToXYPosition(node.position);
        const nodeXYPositionWithParentGroupOffset = addXYPositions(
          parentGroupXYPosition,
          nodeXYPosition,
        );

        return {
          id: node.id,
          positionAbsolute: nodeXYPositionWithParentGroupOffset,
          parentId: node.parentId,
        };
      }),
    });

    return edgesWithDetours;
  }, [sweepEdgeToReactFlowEdge, sweepEdges, sweepGroups, sweepNodes]);

  return { calculateEdges };
};
