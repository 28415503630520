import _get from 'lodash/get';

// Normalize to NFD form and remove diacritical marks using regex
const normalizeTextAndRemoveDiacriticalMarks = (txt: string) =>
  txt
    ?.toLocaleLowerCase()
    ?.normalize('NFD')
    ?.replace(/[\u0300-\u036f]/g, '');

type ItemSelector<T = any> = string | ((item: T) => string);

/* Filters Items on a list of type T. itemSelector accepts either 
   a string witch acts as a GET path, 
   or a function that returns the selected item.
*/

export function filterItemsBySearch<T = any>(
  items: T[],
  search: string,
  itemSelector: ItemSelector<T>,
): T[] {
  const searchFilter = (item: T) => {
    const searchWords = search.toLowerCase().split(/\b/);

    return (
      searchWords.filter((searchWord) => {
        const w = typeof itemSelector === 'string' ? _get(item, itemSelector) : itemSelector(item);
        return normalizeTextAndRemoveDiacriticalMarks(w)?.includes(
          normalizeTextAndRemoveDiacriticalMarks(searchWord),
        );
      }).length === searchWords.length
    );
  };

  if (!search) return items;

  return items.filter(searchFilter);
}
