import { useCallback } from 'react';
import { useReactFlow, FitViewOptions, Node as ReactFlowNode } from '@xyflow/react';
import { CanvasElementType } from './canvas-types';
export const useSweepCanvasFitView = () => {
  const reactFlowInstance = useReactFlow();

  const fitView = useCallback(
    (options?: FitViewOptions) => {
      const nodes = reactFlowInstance
        .getNodes()
        .filter((node) => node.type !== CanvasElementType.GROUP);

      reactFlowInstance.fitView({
        nodes,
        padding: 0.3,
        ...options,
      });
    },
    [reactFlowInstance],
  );

  const fitAroundNodes = useCallback(
    (nodeIds: string[], options?: FitViewOptions, firstChildNodeOnly?: boolean) => {
      const nodes = reactFlowInstance.getNodes();
      const filteredNodes: ReactFlowNode[] = nodes.filter((node) => nodeIds.includes(node.id));
      let filteredChildNodes: ReactFlowNode[] = [];
      if (firstChildNodeOnly) {
        const node = nodes.find((node) => nodeIds.includes(node.parentId || ''));
        if (node) {
          filteredChildNodes = [node];
        }
      } else {
        filteredChildNodes = nodes.filter((node) => nodeIds.includes(node.parentId || ''));
      }
      filteredNodes.push(...filteredChildNodes);

      let padding = 0.3;
      if (filteredNodes.find((node) => node.type === CanvasElementType.GHOST_NODE)) {
        padding = 1;
      }

      reactFlowInstance.fitView({
        nodes: filteredNodes,
        padding,
        duration: 800,
        ...options,
      });
    },
    [reactFlowInstance],
  );

  return { fitView, fitAroundNodes };
};
