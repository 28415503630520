import SweepStagesModel from '../../../../../models/stagesModel';

import { ListGroup } from '../../../../common/CollapseList';
import { colors } from '@sweep-io/sweep-design';
import { ThirdPartyCurrentStep } from './types';
import { isSalesforceLink, isThirdPartyLink, stageToConnectStepItem } from './helper';

export const getGroupsForThirdPartyStep = ({
  step,
  funnelsData,
  thirdPartyFunnelsData,
  showConnectFunnel,
}: {
  step: ThirdPartyCurrentStep;
  funnelsData: FunnelMap['funnelsData'];
  thirdPartyFunnelsData: FunnelMap['thirdPartyFunnelsData'];
  showConnectFunnel?: boolean;
}) => {
  if (!step.stageId) {
    console.error('No stageId provided for third party step');
    return [];
  }
  const tpFunnel = thirdPartyFunnelsData[step.funnelId];
  const tpStep = tpFunnel.steps.find((s) => s.id === step.stageId);
  if (!tpFunnel || !tpStep) {
    return [];
  }
  const tpStepConnectionsToSteps = tpStep.stepConnections.map(({ targetId }) => targetId);

  const currentFunnelGroup = {
    value: tpFunnel.id,
    parentName: tpFunnel.name,
    label: 'Third party integration',
    labelColor: colors.storm[200],
    objectName: tpFunnel.name,
    items: tpFunnel.steps
      .filter((s) => s.id !== tpStep.id && !tpStepConnectionsToSteps.includes(s.id))
      .map((s) => ({
        value: s.id,
        label: s.name,
      })),
  };

  const doesNotHaveSfStageFunnelLink = (funnelId: string) => (stage: SweepStage) => {
    return !Boolean(
      tpStep.funnelLinks
        ?.filter(isSalesforceLink)
        .find((link) => link.funnelId === funnelId && stage._stageId === link.stageId),
    );
  };

  const doesNotHaveThirdPartyStageFunnelLink = (funnelId: string) => (step: ThirdPartyStep) => {
    return !Boolean(
      tpStep.funnelLinks
        ?.filter(isThirdPartyLink)
        .find((link) => link.funnelId === funnelId && step.id === link.stageId),
    );
  };

  const hasStepToFunnelLink = (funnelId: string) => {
    return tpStep.funnelLinks?.some((link) => link.funnelId === funnelId && !link.stageId);
  };

  const allOtherSfFunnelGroups: ListGroup[] = Object.keys(funnelsData)
    .filter((funnelId) => funnelId !== tpFunnel.id)
    .map((funnelId) => ({
      value: funnelsData[funnelId].id,
      parentName: funnelsData[funnelId].name,
      objectName: funnelsData[funnelId].funnelDetails.leadingObject.objectName,
      label: funnelsData[funnelId].funnelDetails.leadingObject.objectName,
      selectable: showConnectFunnel && !hasStepToFunnelLink(funnelId),
      items: new SweepStagesModel(funnelsData[funnelId].funnelDetails.stages)
        .nonLostSteps()
        .filter(doesNotHaveSfStageFunnelLink(funnelId))
        .map(stageToConnectStepItem),
    }));

  const allOtherThirdPartyFunnelGroups: ListGroup[] = Object.keys(thirdPartyFunnelsData)
    .filter((funnelId) => funnelId !== tpFunnel.id)
    .map((funnelId) => ({
      value: thirdPartyFunnelsData[funnelId].id,
      parentName: thirdPartyFunnelsData[funnelId].name,
      objectName: '',
      label: '3rd party integration',
      labelColor: '#D9DBE7',
      selectable: showConnectFunnel && !hasStepToFunnelLink(funnelId),
      items: thirdPartyFunnelsData[funnelId].steps
        .filter(doesNotHaveThirdPartyStageFunnelLink(funnelId))
        .map((step) => ({
          value: step.id,
          label: step.name,
        })),
    }));

  return [currentFunnelGroup, ...allOtherSfFunnelGroups, ...allOtherThirdPartyFunnelGroups];
};

// Gets the groups and steps for the third party funnel
export const getGroupsForThirdPartyFunnel = ({
  step,
  funnelsData,
  thirdPartyFunnelsData,
  showConnectFunnel,
}: {
  step: ThirdPartyCurrentStep;
  funnelsData: FunnelMap['funnelsData'];
  thirdPartyFunnelsData: FunnelMap['thirdPartyFunnelsData'];
  showConnectFunnel?: boolean;
}) => {
  if (step.stageId !== undefined) {
    console.error(
      'Unexpected. Stage Id provided in getGroupsForThirdPartyFunnel. This isn\t a funnel.',
    );
    return [];
  }
  const tpFunnel = thirdPartyFunnelsData[step.funnelId];

  const doesNotHaveSfStageFunnelLink = (funnelId: string) => (stage: SweepStage) => {
    return !Boolean(
      tpFunnel.funnelLinks
        ?.filter(isSalesforceLink)
        .find((link) => link.funnelId === funnelId && stage._stageId === link.stageId),
    );
  };

  const hasFunnelToFunnelLink = (funnelId: string) => {
    return tpFunnel.funnelLinks?.some(
      (link) => link.funnelId === funnelId && link.stageId === undefined,
    );
  };

  const doesNotHaveThirdPartyStageFunnelLink = (funnelId: string) => (step: ThirdPartyStep) => {
    return !Boolean(
      tpFunnel.funnelLinks
        ?.filter(isThirdPartyLink)
        .find((link) => link.funnelId === funnelId && step.id === link.stageId),
    );
  };

  const allOtherSfFunnelGroups: ListGroup[] = Object.keys(funnelsData).map((funnelId) => ({
    value: funnelsData[funnelId].id,
    parentName: funnelsData[funnelId].name,
    objectName: funnelsData[funnelId].funnelDetails.leadingObject.objectName,
    label: funnelsData[funnelId].funnelDetails.leadingObject.objectName,
    selectable: showConnectFunnel && !hasFunnelToFunnelLink(funnelId),
    items: new SweepStagesModel(funnelsData[funnelId].funnelDetails.stages)
      .nonLostSteps()
      .filter(doesNotHaveSfStageFunnelLink(funnelId))
      .map(stageToConnectStepItem),
  }));

  const allOtherThirdPartyFunnelGroups: ListGroup[] = Object.keys(thirdPartyFunnelsData)
    .filter((funnelId) => funnelId !== tpFunnel.id)
    .map((funnelId) => ({
      value: thirdPartyFunnelsData[funnelId].id,
      parentName: thirdPartyFunnelsData[funnelId].name,
      objectName: '',
      label: '3rd party integration',
      labelColor: '#D9DBE7',
      selectable: showConnectFunnel && !hasFunnelToFunnelLink(funnelId),
      items: thirdPartyFunnelsData[funnelId].steps
        .filter(doesNotHaveThirdPartyStageFunnelLink(funnelId))
        .map((step) => ({
          value: step.id,
          label: step.name,
        })),
    }));

  return [...allOtherSfFunnelGroups, ...allOtherThirdPartyFunnelGroups];
};
