import { Box } from '@mui/material';
import { TemplateField } from '../templatesTypes';
import {
  Fields as FieldsIcon,
  ArrowRight as ArrowRightIcon,
} from '@sweep-io/sweep-design/dist/icons';
import { Switch, Tag, Typography, colors } from '@sweep-io/sweep-design';
import { useCallback, useState } from 'react';
import {
  CollapsiblePanel,
  CollapsiblePanelContent,
  CollapsiblePanelTitle,
} from './CollapsiblePanel';
import { useDispatch, useSelector } from 'react-redux';
import uniq from 'lodash/uniq';
import groupBy from 'lodash/groupBy';
import { DataTable__deprecated } from '../../../../common/table/DataTable__deprecated';
import { DataTableBaseColumn } from '../../../../common/table/TableTypes';
import {
  setFieldMappingEnabled,
  selectFieldMappingEnabled,
  setFieldMappingForTemplateField,
  selectTemplateApiNameMapping,
} from './templateFieldsMappingReducer';
import { NestedFieldsSelector } from '../../../../common/fieldsSelectors/NestedFieldsSelector';
import { parseSweepFieldPolymorphicID } from '../../../../common/fieldsSelectors/nestedFieldsSelectorHelper';
import { NestedSelectorPath } from '../../../../common/NestedSelector/NestedSelector';
import { FunnelFieldLabels } from '../../../../../constants/fieldsManagementConsts';
import { getDataFromSFFieldName } from '../../../rollups/rollupHelpers';
import { getObjectTypeColor } from '../../../../sweep-canvas/helpers/getObjectTypeColor';

interface NewTemplateFieldsMappingProps {
  templateFields: TemplateField[];
  templateId: string;
  crmOrgId: string;
}

export const NewTemplateFieldsMapping = ({
  templateFields,
  templateId,
  crmOrgId,
}: NewTemplateFieldsMappingProps) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(true);
  const fieldMappingEnabled = useSelector(selectFieldMappingEnabled(crmOrgId, templateId));
  const objectNames = uniq(templateFields.map((field) => field.objectName));
  const objectByObjectType = groupBy(templateFields, 'objectName');

  return (
    <CollapsiblePanel expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <CollapsiblePanelTitle>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box display="flex" alignItems="center" gap="12px">
            <FieldsIcon color={colors.grey[800]} />
            <Typography variant="body-bold" color={colors.grey[800]}>
              New Fields
            </Typography>
          </Box>
          <Box display="flex" gap={1} alignItems="center">
            <Typography variant="body" color={colors.grey[800]}>
              Enable field mapping
            </Typography>
            <Switch
              checked={Boolean(fieldMappingEnabled)}
              onChange={(e, checked) => {
                dispatch(setFieldMappingEnabled({ templateId, crmOrgId, enabled: checked }));
              }}
            />
          </Box>
        </Box>
      </CollapsiblePanelTitle>
      <CollapsiblePanelContent>
        <Box display="flex" flexDirection="column" gap={1} padding={1}>
          {objectNames.map((objectName) => (
            <Box display="flex" flexDirection="column" gap={1} key={objectName}>
              <Box>
                <Tag label={objectName} size="medium" color={getObjectTypeColor(objectName).step} />
              </Box>
              <TemplateFieldsTable
                templateFields={objectByObjectType[objectName]}
                crmOrgId={crmOrgId}
                templateId={templateId}
                enableFieldMapping={Boolean(fieldMappingEnabled)}
                objectType={objectName}
              />
            </Box>
          ))}
        </Box>
      </CollapsiblePanelContent>
    </CollapsiblePanel>
  );
};

interface TemplateFieldsTableProps {
  templateFields: TemplateField[];
  templateId: string;
  crmOrgId: string;
  enableFieldMapping: boolean;
  objectType: string;
}

const TemplateNestedFieldsSelector = ({
  objectType,
  crmOrgId,
  apiName,
  templateId,
  type,
}: {
  objectType: string;
  crmOrgId: string;
  apiName: string;
  type: string;
  templateId: string;
}) => {
  const dispatch = useDispatch();
  const { fieldIds, fieldLabels } = useSelector(
    selectTemplateApiNameMapping(crmOrgId, templateId, apiName),
  ) || {
    fieldIds: [],
    fieldLabels: [],
  };

  const nestedPath: NestedSelectorPath = fieldIds.map((fieldId, idx) => ({
    label: fieldLabels[idx + 1],
    value: fieldId,
  }));

  const onTemplateFieldChange = useCallback(
    ({ fieldIds, fieldLabels }: { fieldIds: string[]; fieldLabels: string[] }) => {
      dispatch(
        setFieldMappingForTemplateField({
          templateId,
          crmOrgId,
          fieldIds,
          fieldLabels,
          apiName,
        }),
      );
    },
    [apiName, crmOrgId, dispatch, templateId],
  );

  const filterBy = useCallback(
    (field: SweepField) => {
      const { isResolvable } = parseSweepFieldPolymorphicID(field.id || '');

      return isResolvable || field.fieldType === type;
    },
    [type],
  );

  return (
    <Box
      sx={{
        backgroundColor: colors.white,
        borderRadius: '4px',
        border: `1px solid ${colors.grey[300]}`,
        marginTop: '2px',
      }}
    >
      <NestedFieldsSelector
        nestedPath={nestedPath}
        objectType={objectType}
        crmOrgId={crmOrgId}
        onChange={onTemplateFieldChange}
        filterBy={filterBy}
        placeholder="Choose field"
        disableLookupItemsResolve
        hideActionButton
      />
    </Box>
  );
};

const TemplateFieldsTable = ({
  templateFields,
  enableFieldMapping,
  crmOrgId,
  objectType,
  templateId,
}: TemplateFieldsTableProps) => {
  const columns: DataTableBaseColumn[] = [
    {
      field: 'fieldLabel',
      headerName: 'Field Label',
    },
    {
      field: 'apiName',
      headerName: 'API Name',
    },
    {
      field: 'type',
      headerName: 'Type',
    },
    {
      field: 'mapToIcon',
      headerName: '',
      hidden: !enableFieldMapping,
      width: '30px',
    },
    {
      field: 'mapTo',
      headerName: 'Map To',
      hidden: !enableFieldMapping,
      width: '158px',
    },
  ];

  const rows = templateFields.map((templateField) => {
    const [, apiName] = getDataFromSFFieldName(templateField.sfFieldName);
    return {
      id: templateField.templateFieldId,
      fieldLabel: templateField.label,
      apiName: apiName,
      type: FunnelFieldLabels[templateField.fieldType as SweepFieldTypes],
      mapToIcon: <ArrowRightIcon />,
      mapTo: (
        <TemplateNestedFieldsSelector
          objectType={objectType}
          crmOrgId={crmOrgId}
          apiName={templateField.sfFieldName}
          templateId={templateId}
          type={templateField.fieldType}
        />
      ),
    };
  });
  return <DataTable__deprecated rowTypographyVariant="caption" columns={columns} rows={rows} />;
};
