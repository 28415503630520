import { ListItem, SxProps, Box } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';
import { TruncatedTextTooltip } from './TruncatedTextTooltip';

interface StyledListItemProps {
  id?: string;
  isActive?: boolean;
  onClick?: (event: any) => void;
  title: React.ReactNode;
  sx?: SxProps;
  ListItemTag?: React.ReactNode;
  StartIcon?: React.ReactNode;
  EndAdornment?: React.ReactNode;
}

export const StyledListItem = ({
  id,
  isActive,
  title,
  onClick,
  sx,
  ListItemTag,
  StartIcon,
  EndAdornment,
}: StyledListItemProps) => {
  return (
    <ListItem
      id={id}
      onClick={(event) => onClick && onClick(event)}
      sx={{
        cursor: isActive || !onClick ? 'default' : 'pointer',
        fontWeight: isActive ? 800 : '',
        backgroundColor: isActive ? colors.grey[100] : 'transparent',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        gap: 1,
        '&:hover': {
          backgroundColor: colors.grey[100],
        },
        '& svg path': {
          stroke: isActive && !ListItemTag ? colors.blue[500] : colors.grey[900],
        },
        padding: '10px',
        maxHeight: '40px',
        ...sx,
      }}
    >
      <Box
        sx={{ display: 'inline-flex', alignItems: 'center', gap: 1, width: '100%', minWidth: 0 }}
      >
        {StartIcon && StartIcon}

        <TruncatedTextTooltip
          variant={isActive ? 'body-bold' : 'body'}
          color={isActive ? colors.blue[500] : 'inherit'}
        >
          {title}
        </TruncatedTextTooltip>

        {ListItemTag}
      </Box>
      {EndAdornment}
    </ListItem>
  );
};
