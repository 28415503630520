import { ReactNode, useState } from 'react';
import { telemetry } from '../../../../telemetry';
import { SweepNotificationVariant } from '../../../../reducers/notificationsReducer';
import { AssignmentGroup, AssignmentGroupMember } from '../../../../reducers/assignmentGroupTypes';
import { ConfirmDeleteGroupDialog } from './ConfirmDeleteGroupDialog';
import useConfirm from '../../../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import { useSweepNotifications } from '../../../notifications/useSweepNotifications';
import { useAssignmentsApiWithReducer } from '../useAssignmentsApiWithReducer';
import { DialogLoader } from '../../../common/dialogs/DialogLoader';
import usePermission from '../../../common/permissions/usePermission';
import { Commands } from '../../../../types/enums/Common';
import { DataTableRow, SortCompareTypes } from '../../../common/table/TableTypes';
import { SortOrder } from '../../../common/types';
import { sortByDate } from '../../../../utils/sortByDate';
import { humanizeDate } from '../../../helpers/humanizeDate';
import { ActionsMenu, ActionsMenuAction } from '../../../common/actions-menu/ActionsMenu';
import { AvatarPicture } from '../../../avatar/AvatarPicture';
import { TruncatedTextTooltip } from '../../../common/TruncatedTextTooltip';
import { Box } from '@mui/material';
import { DataTable } from '../../../common/table/DataTable';
import { ClampedText } from '../../../common/ClampedText';
import { TableFilteredEmptyState } from '../../../common/TableFilteredEmptyState';
import { ACTION_BUTTONS_KEY } from '../../../common/table/utils';

interface GroupRow extends DataTableRow {
  name: ReactNode;
  description: ReactNode;
  activeMembers: ReactNode;
  updatedAt: ReactNode;
  rawData: AssignmentGroup;
  [ACTION_BUTTONS_KEY]: ReactNode;
}

const columns = [
  {
    field: 'name',
    headerName: 'Group name',
    showSortIcon: true,
    sortCompare: {
      type: SortCompareTypes.Custom,
      compareFunction: (groupA: GroupRow, groupB: GroupRow, order: SortOrder) => {
        const aVal = groupA.rawData.name;
        const bVal = groupB.rawData.name;
        return order === SortOrder.ASC ? aVal.localeCompare(bVal) : bVal.localeCompare(aVal);
      },
    },
    width: '20%',
  },
  {
    field: 'description',
    headerName: 'Description',
    showSortIcon: true,
    sortCompare: {
      type: SortCompareTypes.Custom,
      compareFunction: (groupA: GroupRow, groupB: GroupRow, order: SortOrder) => {
        const aVal = groupA.rawData.description;
        const bVal = groupB.rawData.description;
        return order === SortOrder.ASC ? aVal.localeCompare(bVal) : bVal.localeCompare(aVal);
      },
    },
    width: '30%',
  },
  {
    field: 'activeMembers',
    headerName: 'Active Members',
    showSortIcon: true,
    sortCompare: {
      type: SortCompareTypes.Number,
    },
    width: '20%',
  },
  {
    field: 'updatedAt',
    headerName: 'Last updated',
    showSortIcon: true,
    sortCompare: {
      type: SortCompareTypes.Custom,
      compareFunction: (groupA: GroupRow, groupB: GroupRow, order: SortOrder) =>
        sortByDate(groupA.rawData.updatedAt ?? '', groupB.rawData.updatedAt ?? '', order),
    },
    width: '20%',
  },
];

const countActiveMembers = (members: AssignmentGroupMember[]) =>
  members.filter((member) => member.membershipActive).length;

const GroupsTable = ({
  groups,
  onEdit,
  onClearFilters,
}: {
  groups: AssignmentGroup[];
  onEdit: (groupId: string) => void;
  onClearFilters: () => void;
}) => {
  const { openConfirm, onCancel, onConfirm } = useConfirm();
  const { addNotification } = useSweepNotifications();
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateAllowed, isEditAllowed, isDeleteAllowed] = usePermission([
    'create:assignment-groups',
    'edit:assignment-groups',
    'delete:assignment-groups',
  ]);

  const { getAssignmentsGroupList, duplicateAssignmentGroup, removeAssignmentGroup } =
    useAssignmentsApiWithReducer();

  const onDeleteClick = async (group: AssignmentGroup) => {
    const isConfirmed = await openConfirm(
      <ConfirmDeleteGroupDialog
        assignmentGroup={group}
        onConfirm={onConfirm}
        handleClose={onCancel}
      />,
    );
    if (isConfirmed) {
      try {
        await removeAssignmentGroup(group.id);
      } catch (e) {
        telemetry.captureError(e);
        addNotification({
          message: 'Error removing assignment group',
          variant: SweepNotificationVariant.Error,
        });
        getAssignmentsGroupList();
      }
    }
  };

  const menuActions: ActionsMenuAction[] = [
    {
      label: 'Edit',
      value: Commands.Edit,
      disabled: !isEditAllowed,
      disabledTooltip: 'To edit this assignment group, please contact your admin.',
    },
    {
      label: 'Duplicate',
      value: Commands.Duplicate,
      disabled: !isCreateAllowed,
      disabledTooltip: 'To create a new assignment group, please contact your admin.',
    },
    {
      label: 'Delete',
      value: Commands.Delete,
      disabled: !isDeleteAllowed,
      disabledTooltip: 'To delete this assignment group, please contact your admin.',
    },
  ];

  const onActionMenuClick = (group: AssignmentGroup) => async (actionName: string) => {
    switch (actionName) {
      case Commands.Delete:
        onDeleteClick(group);
        break;
      case Commands.Edit:
        onEdit(group.id);
        break;
      case Commands.Duplicate:
        try {
          setIsLoading(true);
          await duplicateAssignmentGroup(group);
        } catch (e) {
          telemetry.captureError(e);
          addNotification({
            message: 'Error duplicating assignment group',
            variant: SweepNotificationVariant.Error,
          });
        }
        setIsLoading(false);
        break;
    }
  };

  const rows = groups.map((group) => {
    return {
      id: group.id,
      rawData: group,
      name: (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', width: '100%' }}>
          <AvatarPicture dimension={24} avatar={group.avatar} isInactive={false} />
          <TruncatedTextTooltip variant="body">{group.name}</TruncatedTextTooltip>
        </Box>
      ),
      description: <ClampedText text={group.description || '-'} />,
      activeMembers: countActiveMembers(group.members) || 0,
      updatedAt: humanizeDate({
        dateOrTimestamp: group.updatedAt ?? '',
      }),
      [ACTION_BUTTONS_KEY]: (
        <ActionsMenu
          actions={menuActions}
          onClick={(action) => onActionMenuClick(group)(action.value)}
          iconVariant="flat"
        />
      ),
    };
  });

  return (
    <>
      <DialogLoader visible={isLoading} />
      <DataTable
        columns={columns}
        rows={rows}
        stickyHeader
        onRowClick={(e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, row: GroupRow) =>
          onEdit(row.rawData.id)
        }
        defaultSortState={{
          sortBy: 'name',
          sortOrder: SortOrder.ASC,
        }}
        tableEmptyStateJsx={
          <TableFilteredEmptyState clearFilters={onClearFilters} isFiltered={true} />
        }
      />
    </>
  );
};

export default GroupsTable;
