export enum ChatCompletionRequestMessageRole {
  SYSTEM = 'system',
  USER = 'user',
  ASSISTANT = 'assistant',
}
export type SalesforceConfigurationType = string;

export type AiChatMessageContent = string;

export interface ConfigurationDescription {
  data: string;
  error: string;
}

export interface ChatGptPromptMessage {
  role: ChatCompletionRequestMessageRole;
  content: AiChatMessageContent;
}

export enum AiChatContextType {
  AUTOMATION = 'automation',
  CONFIGURATION = 'configuration',
  FIELD = 'field',
}

export interface AiChatConfigurationContext {
  type: AiChatContextType.CONFIGURATION;
  filename: string;
}

export interface AiChatFieldContext {
  type: AiChatContextType.FIELD;
  fieldName: string;
  objectName: string;
}

export interface AiChatAutomationContext {
  type: AiChatContextType.AUTOMATION;
  // TODO this should be AiChatAutomationContextDto, which is using AutomationDetailsDto,
  //  but we can't use it as it's breaking the client, need to create an interface to use instead of the dto class
  automationProperties: any;
}

export type AiChatContextProperties =
  | AiChatConfigurationContext
  | AiChatFieldContext
  | AiChatAutomationContext;

export type AiChatMetadata = {
  id: string;
  name: string;
  isFavorite: boolean;
  agentId: string;
  agentType: AgentType;
  updatedAt: Date;
};

interface AiChatSystemMessage extends TimedChatGptPromptMessage {
  role: ChatCompletionRequestMessageRole.SYSTEM;
}

export interface AiChatUserMessage extends TimedChatGptPromptMessage {
  role: ChatCompletionRequestMessageRole.USER;
}

export interface AiChatAssistantMessage extends TimedChatGptPromptMessage {
  role: ChatCompletionRequestMessageRole.ASSISTANT;
  isError: boolean;
}

// TODO this is a temporary type, will be removed once prompts move to LangSmith
export type AiPromptMessage = AiChatSystemMessage | AiChatUserMessage | AiChatAssistantMessage;

export type AiChatMessage = AiChatUserMessage | AiChatAssistantMessage;

export interface AiChatDetails {
  messages: AiChatMessage[];
}

export interface TimedChatGptPromptMessage extends ChatGptPromptMessage {
  createdAt: Date;
}

export type NewChatResponse = {
  id: string;
  name: string;
  message: AiChatAssistantMessage;
};

export enum AgentType {
  Documentation = 'Documentation',
  ProcessOptimization = 'Process Optimization',
  Monitoring = 'Monitoring',
}

export enum AgentFileAllowedExtension {
  txt = 'txt',
  json = 'json',
  xml = 'xml',
  csv = 'csv',
  tsv = 'tsv',
  log = 'log',
  ini = 'ini',
  env = 'env',
  md = 'md',
  rst = 'rst',
  tex = 'tex',
  toml = 'toml',
  nacl = 'nacl',
  config = 'config',
  plist = 'plist',
}

export interface AgentFileUpdatableProperties {
  name: string;
  type: AgentFileAllowedExtension;
  content: string;
}

export interface AgentFileProperties {
  id: string;
  name: string;
  type: AgentFileAllowedExtension;
  createdById: string;
  createdAt: Date;
}

export interface ProcessOptimizationContextComponent {
  funnelId: string;
  stagesIds: string[];
}

export interface MonitoringContextComponent {
  objectType: string; // TODO change to objectName (data migration)
}

interface AiAgentPropertiesBase {
  id: string;
  name: string;
  type: AgentType;
  instructions?: string;
  files: AgentFileProperties[];
  crmOrgId: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface AiAgentPropertiesProcess extends AiAgentPropertiesBase {
  type: AgentType.ProcessOptimization;
  contextComponents: ProcessOptimizationContextComponent[];
}

export interface AiAgentPropertiesDocumentation extends AiAgentPropertiesBase {
  type: AgentType.Documentation;
  contextComponents: never;
}

export interface AiAgentPropertiesMonitoring extends AiAgentPropertiesBase {
  type: AgentType.Monitoring;
  contextComponents: MonitoringContextComponent;
}

export type AgentContextComponent = AiAgentProperties['contextComponents'];

export type AiAgentProperties =
  | AiAgentPropertiesDocumentation
  | AiAgentPropertiesProcess
  | AiAgentPropertiesMonitoring;

export function isAiAgentOfType<T extends AgentType | 'any'>(
  feature: AiAgentPropertiesBase,
  type: T,
): feature is {
  [AgentType.ProcessOptimization]: AiAgentPropertiesProcess;
  [AgentType.Documentation]: AiAgentPropertiesDocumentation;
  [AgentType.Monitoring]: AiAgentPropertiesMonitoring;
  any: AiAgentProperties;
}[T] {
  if (type === 'any') {
    return Object.values(AgentType).includes(feature.type);
  } else {
    return feature.type === type;
  }
}

export const AI_PROCESS_OPTIMIZATION_AGENT_RECOMMENDATIONS_USER_PROMPT =
  'Highlight process optimization opportunities';

export const AI_PROCESS_OPTIMIZATION_AGENT_PLAYBOOK_USER_PROMPT = 'Describe the process playbook';

export const AGENT_FILE_BYTES_MAX_SIZE = 102400; // max size of agent file is 100kb
