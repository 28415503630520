import { LogoDevIcon } from '../common/logo-dev-selector/LogoDevIcon';
import { Stack } from '@mui/material';
import { colors, Tooltip, Typography } from '@sweep-io/sweep-design';
import { LogoDevBrand } from '../../apis/facades/useLogoDevApiFacade';

export const SystemTag = ({
  system,
  tooltip,
  forceName,
}: {
  system?: LogoDevBrand;
  tooltip?: string;
  forceName?: string;
}) => {
  const name = forceName ?? system?.name ?? 'Third party integration';

  const renderTag = () => (
    <Stack
      direction="row"
      gap={0.5}
      alignItems="center"
      sx={{ backgroundColor: colors.grey[100], borderRadius: '2px', padding: '2px 4px' }}
    >
      {system && <LogoDevIcon brand={system} size={16} />}
      <Typography variant="caption">{name}</Typography>
    </Stack>
  );
  return tooltip ? <Tooltip title={tooltip}>{renderTag()}</Tooltip> : renderTag();
};
