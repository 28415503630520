import { Box, Stack } from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';

const PromptBubblesGrid = ({
  prompts,
  variant,
  onClickPrompt,
}: {
  variant: 'expanded' | 'collapsed';
  prompts: string[];
  onClickPrompt: (prompt: string) => void;
}) => {
  const promptsCount = prompts.length;

  const cardsInRowCountCollapsed = promptsCount >= 2 ? 2 : promptsCount;
  const cardsInRowCountExpanded = promptsCount >= 3 ? 3 : promptsCount;
  const cardsInRowCount =
    variant === 'collapsed' ? cardsInRowCountCollapsed : cardsInRowCountExpanded;
  return (
    <Stack
      sx={{
        flex: 1,
        display: 'grid',
        gap: '16px',
        gridTemplateColumns: `repeat(${cardsInRowCount}, 1fr)`,
      }}
    >
      {prompts.map((prompt) => (
        <PromptBubble key={prompt} onClick={() => onClickPrompt(prompt)} prompt={prompt} />
      ))}
    </Stack>
  );
};

const PromptBubble = ({ onClick, prompt }: { onClick: () => void; prompt: string }) => {
  return (
    <Box
      sx={{
        cursor: 'pointer',
        border: `1px solid ${colors.grey[300]}`,
        borderRadius: '8px',
        padding: '8px 16px',
        width: '100%',
        height: '100%',
        backgroundColor: colors.white,
        ':hover': {
          borderColor: colors.blue[500],
          '.MuiTypography-root': {
            color: colors.blue[500],
          },
        },
      }}
      onClick={onClick}
    >
      <Typography variant="body-medium" color={colors.grey[700]} textAlign="start">
        {prompt}
      </Typography>
    </Box>
  );
};

export default PromptBubblesGrid;
