import { Settings, Wrench, Files } from '@sweep-io/sweep-design/dist/icons';
import { ActionsMenu, ActionsMenuAction } from '../../common/actions-menu/ActionsMenu';
import { useCallback } from 'react';
import usePermission from '../../common/permissions/usePermission';
import { Permission } from '@server/permissions';
import { AiAgent } from '../aiAgentsConsts';
import useAgentSettings from './useAgentSettings';

const INSTRUCTIONS = 'Instructions';
const FILES = 'Files';

const INSTRUCTIONS_ACTION = {
  label: INSTRUCTIONS,
  value: INSTRUCTIONS,
  actionIcon: <Wrench />,
};

const FILES_ACTION = {
  label: FILES,
  value: FILES,
  actionIcon: <Files />,
};

const AgentSettingsMenu = ({ agent, withoutFiles }: { agent: AiAgent; withoutFiles: boolean }) => {
  const { openFilesDialog, openInstructionsDialog, dialogs } = useAgentSettings(agent);

  const permissions: Permission[] = ['edit:ai-agent'];
  const [isAllowedToEditAgent] = usePermission(permissions);

  const onClick = useCallback(
    (action: ActionsMenuAction) => {
      switch (action.value) {
        case INSTRUCTIONS:
          openInstructionsDialog();
          break;
        case FILES:
          openFilesDialog();
          break;
      }
    },
    [openFilesDialog, openInstructionsDialog],
  );

  if (!isAllowedToEditAgent) {
    return null;
  }

  const actions = withoutFiles ? [INSTRUCTIONS_ACTION] : [INSTRUCTIONS_ACTION, FILES_ACTION];

  return (
    <>
      <ActionsMenu
        actions={actions}
        onClick={onClick}
        MenuButtonIcon={<Settings />}
        iconSize="small"
      />
      {dialogs}
    </>
  );
};

export default AgentSettingsMenu;
