import { Box, Stack } from '@mui/material';
import { colors, IconButton, Typography } from '@sweep-io/sweep-design';
import { Close as CloseIcon } from '@sweep-io/sweep-design/dist/icons/Close';
import { ReactNode } from 'react';
import { HORIZONTAL_PADDING } from '../pages/configuration-canvas-panel/consts';
import { newSearchSx } from '../pages/canvas-pages/canvasPanelConsts';

const GroupsTerritoriesLayout = ({
  isDialogLayout,
  renderContent,
  renderDialogs,
  newButtonJsx,
  onClose,
  countText,
  searchJsx,
  title,
}: {
  isDialogLayout?: boolean;
  renderContent: () => ReactNode;
  renderDialogs: () => ReactNode;
  newButtonJsx: ReactNode;
  onClose?: () => void;
  countText: string;
  searchJsx: ReactNode;
  title: string;
}) => {
  return (
    <>
      <Stack
        height="100%"
        gap={2}
        sx={{
          padding: isDialogLayout ? undefined : `0 ${HORIZONTAL_PADDING}px`,
        }}
      >
        {isDialogLayout && (
          <Stack gap={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h1">{title}</Typography>
              <Box display="flex" gap={1} alignItems="center">
                {newButtonJsx}
                <IconButton variant="flat" onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={newSearchSx}>
              <Typography variant="body" color={colors.grey[700]}>
                {countText}
              </Typography>
              {searchJsx}
            </Box>
          </Stack>
        )}
        {!isDialogLayout && (
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={newSearchSx}>
            {searchJsx}
            {newButtonJsx}
          </Box>
        )}
        {renderContent()}
      </Stack>
      {renderDialogs()}
    </>
  );
};

export default GroupsTerritoriesLayout;
