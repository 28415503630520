import { SortOrder } from '../../../common/types';

export type SfChangeFeedListItem = {
  sfId: string;
  crmOrgId: string;
  sfUserName: string;
  actionText: string;
  section: string;
  timestamp: string;
  _animation?: string; //used only by client to trigger new rows animation
};

export enum SfChangeFeedFilterFieldNames {
  Environment = 'crmOrgs',
  User = 'sfUserName',
  Section = 'sections',
  Timestamp = 'timestamp',
}

export type SfChangeFeedSortAndFilter = SfChangeFeedFilters & {
  sortKey?: SortOrder;
  sortBy?: SfChangeFeedFilterFieldNames;
};

export type SfChangeFeedFilters = {
  crmOrgs?: string[];
  sfUserNames?: string[];
  sections?: string[];
  actionText?: string;
  startTime?: string; //kept in redux store, redux doesn't allow non serializable vars
  endTime?: string; //kept in redux store, redux doesn't allow non serializable vars
};

export type SfChangeFeedNotificationConditions = {
  criteria: SfChangeFeedNotificationCriterion[];
  criteriaLogic: string;
};
export type SfChangeFeedNotification = {
  id: string;
  name: string;
  crmOrgIds: string[];
  actions: SfChangeFeedNotificationAction[];
  isActive: boolean;
} & SfChangeFeedNotificationConditions;

export enum SfChangeFeedActionType {
  Email = 'email',
  Slack = 'slack',
}

export interface SfChangeFeedNotificationAction {
  type?: SfChangeFeedActionType;
  value: { recipients: string[] };
}

export interface SfChangeFeedNotificationCriterion {
  criterionId: string;
  fieldName: string;
  operator: string;
  value: string;
}
