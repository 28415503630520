import { useState } from 'react';
import { SectionBox } from '../helpers';
import { Typography } from '@sweep-io/sweep-design';
import { Box, Collapse, CircularProgress } from '@mui/material';
import { ExpandButtonSimple } from '../../../common/ExpandButtonSimple';
import { useRunOnce } from '../../../common/useRunOnce';
import { selectLatestDeployment } from '../../../../reducers/documentationReducer';
import { useSelector } from 'react-redux';
import { DevOpsCenterDeploymentStatusCode } from '@server/devops-center';
import { DeploymentStatusIcon } from '../../../pages/devops-center/StatusIcon';
import {
  SWEEP_AUTOMATION_TEMPLATE_TYPES,
  SWEEP_DEDUP_MATCHING_TEMPLATE_TYPES,
  ConfigurationType,
  ConfigurationTypesWithIcon,
} from '../types';
import { AutomationTemplate } from './templates/AutomationTemplate';
import { DedupAndMatchingTemplate } from './templates/DedupAndMatchingTemplate';
import { RollupTemplate } from './templates/RollupTemplate';
import { useDependencies } from '../hooks/useDependencies';
import { SweepElementTypes } from '../../types';

interface LatestDeployedSectionProps {
  parentElementId: string;
  crmOrgId: string;
  sweepElementType: SweepElementTypes;
}

export const LatestDeployedSection = ({
  crmOrgId,
  parentElementId,
  sweepElementType,
}: LatestDeployedSectionProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const latestDeployment = useSelector(selectLatestDeployment(parentElementId));

  const isAutomation = SWEEP_AUTOMATION_TEMPLATE_TYPES.includes(sweepElementType);
  const isDedupMatching = SWEEP_DEDUP_MATCHING_TEMPLATE_TYPES.includes(sweepElementType);
  const isRollup = sweepElementType === ConfigurationType.rollups;

  const LatestDeploymentComponent = (
    <>
      {isAutomation && (
        <AutomationTemplate
          elementType={sweepElementType as ConfigurationTypesWithIcon}
          readOnlyVersion={latestDeployment}
        />
      )}
      {isDedupMatching && (
        <DedupAndMatchingTemplate
          elementType={sweepElementType as ConfigurationTypesWithIcon}
          elementId={parentElementId}
        />
      )}
      {isRollup && <RollupTemplate elementId={parentElementId} crmOrgId={crmOrgId} />}
    </>
  );

  const { fetchLatestDeployedVersion } = useDependencies(crmOrgId);

  useRunOnce(async () => {
    if (!latestDeployment && !isLoading && !isError) {
      setIsLoading(true);
      setIsError(false);
      try {
        await fetchLatestDeployedVersion(sweepElementType, parentElementId, crmOrgId);
        setIsLoading(false);
      } catch (e) {
        setIsError(true);
        setIsLoading(false);
      }
    }
  });

  return (
    <SectionBox>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setIsExpanded(!isExpanded)}
        sx={{ cursor: 'pointer' }}
      >
        <Box display="flex" gap={1} alignItems="center">
          <DeploymentStatusIcon status={DevOpsCenterDeploymentStatusCode.FullySynced} />
          <Typography variant="body-medium">Latest deployed version</Typography>
        </Box>
        <ExpandButtonSimple isExpanded={isExpanded} />
      </Box>
      <Collapse in={isExpanded}>
        <Box pt={1}>
          {isLoading && <CircularProgress size={20} />}
          {!isLoading && isError && (
            <Typography variant="caption">Something went wrong, try again later</Typography>
          )}
          {!isLoading && !isError ? LatestDeploymentComponent : <></>}
        </Box>
      </Collapse>
    </SectionBox>
  );
};
