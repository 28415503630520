import { Box } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { StageDialogCollapsePanel } from './StageDialogCollapsePanel';
import { EnrichedFunnelLink } from '../../../funnel-links/types';

interface FunnelLinkCollapsePanelProps {
  enrichedFunnelLink: EnrichedFunnelLink;
  defaultIsCollapsed: boolean;
  fromStageName: string;
}

const FunnelLinkCollapsePanel = ({
  defaultIsCollapsed,
  fromStageName,
  enrichedFunnelLink,
}: FunnelLinkCollapsePanelProps) => {
  return (
    <StageDialogCollapsePanel
      defaultIsCollapsed={defaultIsCollapsed}
      from={fromStageName}
      funnelId=""
      enrichedFunnelLink={enrichedFunnelLink}
    >
      <Box
        sx={{ padding: 3, background: colors.grey[100], borderRadius: '16px', marginTop: '10px' }}
      >
        <Typography variant="caption" color={colors.grey[700]}>
          Adding gates to connections between funnels is not yet possible. But we will make it
          happen soon!
        </Typography>
      </Box>
    </StageDialogCollapsePanel>
  );
};

export { FunnelLinkCollapsePanel };
