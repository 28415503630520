import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { ParserResponse, RecordTypeData } from './ParserTypes';
import extractErrorMsg from '../helpers/extractErrorMsg';
import { selectTransientObjects, setTransientObjects } from '../../reducers/documentationReducer';
import {
  addParsedDataToDocumentation,
  selectEnvironments,
  selectParserInfo,
} from '../../reducers/global/globalReducer';
import { calcParsedFields, calcPills } from './parserUtils';
import { useCrmOrgsApiFacade } from '../../apis/facades/useCrmOrgsApiFacade';
import { GlobalDto } from '../../reducers/global/globalReducerTypes';
import { useConfigurationCanvas } from '../pages/configuration-canvas/public/useConfigurationCanvas';
import { useFetchGlobal } from '../../hooks/global-reducer/useFetchGlobal';
import useObjectTypesWithFetch from '../../hooks/useObjectTypesWithFetch';
import { telemetry } from '../../telemetry';

export enum GetParsedObjectStatus {
  addToConfigurationCanvas = 'addToConfigurationCanvas',
  fetchData = 'fetchData',
}

interface UseParserProps {
  parseObject: (objectName: string) => Promise<ParserResponse | undefined>;
  parseObjectOnDemand: (objectType: string) => void;
  getRecordType: ({
    crmOrgId,
    objectApiName,
    recordTypeName,
  }: {
    crmOrgId: string;
    objectApiName: string;
    recordTypeName: string;
  }) => Promise<RecordTypeData>;
  objectsBeingParsed: { [objectName: string]: boolean };
  toggleObjectVisibility: (objectType: string, visible: boolean, _fetchGlobal?: boolean) => void;
}

const useParser = ({ crmOrgId }: { crmOrgId: string }): UseParserProps => {
  const { canvasFunnelMap } = useConfigurationCanvas();
  const { fetchGlobal } = useFetchGlobal();

  const dispatch = useDispatch();

  const { post_crmOrgParse, get_objectTypeRecordType, patch_crmOrgParse } = useCrmOrgsApiFacade();

  const globalEnvironments = useSelector(selectEnvironments);
  const parserInfo = useSelector(selectParserInfo(crmOrgId));

  const parsedObjectNames = useMemo(
    () => parserInfo?.parsedObjectNames ?? [],
    [parserInfo?.parsedObjectNames],
  );

  const { objectTypes: crmOrgObjectTypes } = useObjectTypesWithFetch({
    crmOrgId,
  });
  const objectsBeingParsed = useSelector(selectTransientObjects(crmOrgId));

  const toggleObjectVisibility = useCallback(
    async (
      objectName: string,
      visible: boolean,
      _fetchGlobal?: boolean,
      skipLoadingObjectTypes?: boolean,
    ) => {
      const payload = { visible };
      try {
        await patch_crmOrgParse({ crmOrgId, objectName, payload });

        if (_fetchGlobal) {
          await fetchGlobal({ crmOrgId, skipLoadingObjectTypes });
        }
      } catch (error) {
        telemetry.captureError(error, { message: extractErrorMsg(error) });
      }
    },
    [crmOrgId, patch_crmOrgParse, fetchGlobal],
  );

  const toggleIsObjectBeingParsed = useCallback(
    ({ objectName, isBeingParsed }: { objectName: string; isBeingParsed: boolean }) => {
      dispatch(
        setTransientObjects({
          objectName: objectName,
          isObjectBeingParsed: isBeingParsed,
          crmOrgId,
        }),
      );
    },
    [crmOrgId, dispatch],
  );

  //this call saves object info in parser table
  const parseObject = useCallback(
    async (objectName: string) => {
      const newObject = crmOrgObjectTypes?.find((object) => object.objectType === objectName);
      const isObjectAlreadyParsed = parsedObjectNames.includes(objectName);

      if (newObject && crmOrgId) {
        const objectTypes = [{ label: newObject.label, name: newObject.objectType, visible: true }];

        toggleIsObjectBeingParsed({ objectName, isBeingParsed: true });

        try {
          let data = {} as ParserResponse;
          const payload = {
            objectTypes,
          };

          if (isObjectAlreadyParsed) {
            await toggleObjectVisibility(objectName, true);
          } else {
            data = await post_crmOrgParse({ crmOrgId, payload });
          }
          await fetchGlobal({ crmOrgId, skipLoadingObjectTypes: true });
          toggleIsObjectBeingParsed({ objectName, isBeingParsed: false });

          return data as ParserResponse;
        } catch (e) {
          toggleIsObjectBeingParsed({ objectName, isBeingParsed: false });
          telemetry.captureError(e, { message: extractErrorMsg(e) });
        }
      }
    },
    [
      crmOrgId,
      post_crmOrgParse,
      crmOrgObjectTypes,
      toggleObjectVisibility,
      parsedObjectNames,
      fetchGlobal,
      toggleIsObjectBeingParsed,
    ],
  );

  //this call doesn't save object info in parser table
  const parseObjectOnDemand = useCallback(
    async (objectApiName: string) => {
      const rollups = globalEnvironments?.[crmOrgId]?.data?.rollups;

      if (!parsedObjectNames.includes(objectApiName)) {
        const newObject = crmOrgObjectTypes?.find((object) => object.objectType === objectApiName);

        const payload = {
          objectTypes: [{ name: objectApiName, label: newObject?.label ?? '', visible: false }],
        };

        toggleIsObjectBeingParsed({ objectName: objectApiName, isBeingParsed: true });

        try {
          const response = await post_crmOrgParse({ crmOrgId, payload });
          const fieldsParsedOnDemand = calcParsedFields(response, rollups ?? []);

          //update store with new info
          const pills = calcPills({
            parser: response,
            funnels: canvasFunnelMap.funnelsData,
            recordTypes: {},
          } as GlobalDto);

          dispatch(
            addParsedDataToDocumentation({
              crmOrgId,
              objectName: objectApiName,
              response,
              fieldsParsedOnDemand,
              pills,
            }),
          );
          toggleIsObjectBeingParsed({ objectName: objectApiName, isBeingParsed: false });
          return response;
        } catch (error) {
          toggleIsObjectBeingParsed({ objectName: objectApiName, isBeingParsed: false });
          telemetry.captureError(error, { message: extractErrorMsg(error) });
        }
      }
    },
    [
      dispatch,
      post_crmOrgParse,
      toggleIsObjectBeingParsed,
      crmOrgId,
      globalEnvironments,
      parsedObjectNames,
      crmOrgObjectTypes,
      canvasFunnelMap,
    ],
  );

  const getRecordType = async ({
    crmOrgId,
    objectApiName,
    recordTypeName,
  }: {
    crmOrgId: string;
    objectApiName: string;
    recordTypeName: string;
  }) => {
    return await get_objectTypeRecordType({
      crmOrgId,
      objectApiName,
      recordTypeName,
    });
  };

  return {
    objectsBeingParsed,
    parseObject,
    parseObjectOnDemand,
    getRecordType,
    toggleObjectVisibility,
  };
};

export default useParser;
