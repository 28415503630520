import { SweepTypographyVariants } from '@sweep-io/sweep-design/dist/components/Typography/types';
import { DataTableVariant } from './TableTypes';
import { colors } from '@sweep-io/sweep-design';

type DataTableVariantProps = {
  [variant in DataTableVariant]: {
    fontVariant: SweepTypographyVariants;
    cellPadding: string;
    actionsPadding?: number; //TODO make not optional when FT removed
    minRowHeight: string;
    minRowWidth: string;
    headerFontVariant: SweepTypographyVariants;
    headerFontColor: string;
    fontColor: string;
  };
};

export const dataTableVariants: DataTableVariantProps = {
  [DataTableVariant.narrow]: {
    headerFontVariant: 'caption-medium',
    fontVariant: 'caption',
    headerFontColor: colors.grey[800],
    fontColor: colors.grey[800],
    minRowWidth: '',
    cellPadding: '10px 10px 10px 0',
    minRowHeight: '40px',
  },
  [DataTableVariant.default]: {
    headerFontVariant: 'body-medium',
    fontVariant: 'body',
    headerFontColor: colors.grey[800],
    fontColor: colors.grey[800],
    minRowWidth: '',
    cellPadding: '7px 10px 7px 0',
    minRowHeight: '60px',
  },
  //New table styles are below
  [DataTableVariant.medium]: {
    headerFontVariant: 'body',
    fontVariant: 'body',
    headerFontColor: colors.grey[800],
    fontColor: colors.grey[800],
    minRowWidth: '151px',
    cellPadding: '16px 0px 16px 24px',
    actionsPadding: 2,
    minRowHeight: '56px',
  },
  [DataTableVariant.small]: {
    headerFontVariant: 'caption',
    fontVariant: 'caption',
    fontColor: colors.grey[800],
    headerFontColor: colors.grey[800],
    minRowWidth: '96px',
    cellPadding: '8px 0px 8px 16px',
    actionsPadding: 0,
    minRowHeight: '32px',
  },
};
