import type { Dictionary } from 'lodash';
import { CanvasGridPosition } from '../helpers/gridPositioningUtils';
import { SweepCanvasReactFlowEdgeDataType } from '../edges/SweepCanvasReactFlowEdgeDataType';
import { LogoDevBrand } from '../../../apis/facades/useLogoDevApiFacade';
export enum NodeEntityTypes {
  Step = 'Step',
  StepPill = 'StepPill',
  Group = 'Group',
  GroupPill = 'GroupPill',
  GroupLabel = 'GroupLabel',
  GhostStep = 'GhostStep',
  GhostStepButton = 'GhostStepButton',
  GroupEditButton = 'GroupEditButton',
  GroupOverlay = 'GroupOverlay',
}

interface NodeEntity {
  type: NodeEntityTypes;
}

export interface StepEntityType extends NodeEntity {
  type: NodeEntityTypes.Step;
}
export interface StepPillEntityType extends NodeEntity {
  type: NodeEntityTypes.StepPill;
  canvasPillType: CanvasPillTypes;
}

export interface GroupPillEntityType extends NodeEntity {
  type: NodeEntityTypes.GroupPill;
  canvasPillType: CanvasPillTypes;
}

export interface GroupLabelEntityType extends NodeEntity {
  type: NodeEntityTypes.GroupLabel;
}
export interface GhostStepEntityType extends NodeEntity {
  type: NodeEntityTypes.GhostStep;
}

export interface GhostStepButtonEntityType extends NodeEntity {
  type: NodeEntityTypes.GhostStepButton;
}

export interface GroupEntityType extends NodeEntity {
  type: NodeEntityTypes.Group;
}

export interface GroupEditButtonEntityType extends NodeEntity {
  type: NodeEntityTypes.GroupEditButton;
}

export interface GroupOverlayEntityType extends NodeEntity {
  type: NodeEntityTypes.GroupOverlay;
}

export type SweepCanvasEntities =
  | StepEntityType
  | StepPillEntityType
  | GroupPillEntityType
  | GroupLabelEntityType
  | GhostStepEntityType
  | GhostStepButtonEntityType
  | GroupEntityType
  | GroupEditButtonEntityType
  | GroupOverlayEntityType;

export interface OnNodeClickProps {
  id: string;
  parentId?: string;
  entity: SweepCanvasEntities;
  event: React.MouseEvent;
}

export enum CanvasElementType {
  REGULAR = 'REGULAR',
  EDITABLE = 'EDITABLE',
  NURTURING_BUCKET = 'NURTURING_BUCKET',
  DROP_ZONE_NODE = 'DROP_ZONE_NODE',
  GROUP = 'GROUP',
  GROUP_LABEL = 'GROUP_LABEL',
  GROUP_OVERLAY = 'GROUP_OVERLAY',
  GHOST_NODE = 'GHOST_NODE',
}

export enum CanvasEdgeConnectionType {
  Bezier = 'Bezier',
  HorizontalDetour = 'HorizontalDetour',
  VerticalDetour = 'VerticalDetour',
}

export enum CanvasPillTypes {
  automation = 'automation',
  playbookAlert = 'playbookAlert',
  assignmentRule = 'assignmentRule',
  scheduledAssignment = 'scheduledAssignment',
  validationRule = 'validationRule',
  sfdcAutomation = 'sfdcAutomation',
  apexTrigger = 'apexTrigger',
  groupSfdcAutomation = 'groupSfdcAutomation',
  groupValidationRule = 'groupValidationRule',
  groupApex = 'groupApex',
  groupAutomation = 'groupAutomation',
  groupDeduplication = 'groupDeduplication',
  groupMatching = 'groupMatching',
  groupAssignmentRule = 'groupAssignmentRule',
  groupPlaybookAlert = 'groupPlaybookAlert',
  groupAggregated = 'groupAggregated',
  groupScheduledAssignment = 'groupScheduledAssignment',
  groupHubspotEnrollment = 'groupHubspotEnrollment',
}

export interface CanvasPill {
  type: CanvasPillTypes;
  amount: number;
  highlight?: boolean;
}

interface SweepCanvasNodeBase {
  nodeType: 'node' | 'group';
  id: string;
  name: string;
  position: CanvasGridPosition;
  objectType: ObjectTypeValues;
  pills?: CanvasPill[];
}

export interface SweepCanvasNode extends SweepCanvasNodeBase {
  nodeType: 'node';
  parentId?: string;
  type: CanvasElementType;
  originStepName?: string;
  probability?: number;
  forecastCategoryLabel?: string;
  hideNbButton?: boolean;
  showSparkleIcon?: boolean;
}
export interface SweepCanvasGroup<T = any> extends SweepCanvasNodeBase {
  nodeType: 'group';
  objectLabelLogoDevBrand?: LogoDevBrand;
  description?: string;
  objectLabel: string;
  metadata?: T;
  plugins?: PluginTypes[];
  showEditButton?: boolean;
  startingIcon?: 'hubspot';
}

export interface SweepCanvasEdgeData {
  type: SweepCanvasReactFlowEdgeDataType;
  label?: string;
  connectionType?: CanvasEdgeConnectionType;
  connectionIdx?: number;
  groupConnection?: boolean;
}

export interface SweepCanvasEdge {
  id: string;
  source: string;
  target: string;
  data: SweepCanvasEdgeData;
}

export interface NodeChangeMoveNode {
  parentId?: string;
  nodeId: string;
  newPosition: CanvasGridPosition;
}

export interface NodeChangeMoveNodeType {
  nodesToMove: NodeChangeMoveNode[];
}

export interface NodeChangeMoveGroupType {
  groupsToMove: NodeChangeMoveNode[];
}

export interface NodeChangeEscapeFromShadowStepType {
  nodeId: string;
  parentId: string;
}

export interface NodeChangeRemoveGroupType {
  groupId: string;
}

export type SweepCanvasEdgeTransformations = Dictionary<SweepCanvasEdge>;
export type SweepCanvasNodeTransformations = Dictionary<SweepCanvasNode>;
export type SweepCanvasGroupTransformations = Dictionary<SweepCanvasGroup>;

export enum CanvasMode {
  DEFAULT = 'DEFAULT',
  PREVIEW1 = 'PREVIEW1', //basic step view structure (eg. no funnel/step name displayed)
  PREVIEW2 = 'PREVIEW2', //detailed step information (eg. with step name displayed) with zoom controls
  PREVIEW3 = 'PREVIEW3', //detailed step information (eg. with step name displayed) without zoom controls
  FIT_NODES_PREVIEW = 'FIT_NODES_PREVIEW',
}

export const DEFAULT_CANVAS_ZOOM = 1;
export const DEFAULT_CANVAS_MIN_ZOOM = 0.1;
export const DEFAULT_PREVIEW_MIN_ZOOM = 0.01;

export enum ContextZoomType {
  DEFAULT = 'DEFAULT',
  LEVEL1 = 'LEVEL1',
  LEVEL1_5 = 'LEVEL1_5', // no actions on hover
  LEVEL2 = 'LEVEL2',
}
