import { XYPosition } from '@xyflow/react';
import { GroupNodeBoundaries, SweepCanvasRfNode } from './canvas-types/nodeTypesData';
import {
  convertGridPositionToXYPosition,
  convertXYPositionToGridIndex,
  convertXYPositionToGridPosition,
  CanvasGridPosition,
} from './helpers/gridPositioningUtils';
import { CanvasElementType } from './canvas-types';
import { GroupBoundingBox } from './canvas-types/nodeTypesData';
import { calculateGroupDimensions } from './calculateGroupDimensions';

interface NormalizedNodePosition {
  id: string;
  position: CanvasGridPosition;
  xYPosition: XYPosition;
}
/**
 * Normalizes positions of nodes within a group and calculates group boundaries.
 * Steps:
 * 1. When a node is moved outside current group boundaries, finds the leftmost/topmost node position
 * 2. Shifts all nodes so the leftmost/topmost node becomes (0,0)
 * 3. Updates the group position to compensate for the shift, preserving visual positions
 * 4. Recalculates group boundaries based on normalized node positions
 * 5. Updates all other nodes' positions relative to the new (0,0) reference point
 *
 * This ensures:
 * - There is always a node at (0,0) position
 * - All nodes maintain their relative positions to each other
 * - The group's visual position in the canvas remains unchanged
 * - Group boundaries are properly maintained around all nodes
 *
 * Example:
 *
 * Before move:
 * Group position: (10,10)
 *
 *     +----------------+
 *     |  Group        |
 *     |               |
 *     |   A(0,0)      |
 *     |         B(2,1)|
 *     |               |
 *     +----------------+
 *
 * After moving A to (-1,0):
 * Group position: (9,10)  <- Group shifted left by 1 to compensate
 *
 *     +----------------+
 *     |  Group        |
 *     |               |
 *     |   A(0,0)      | <- A normalized back to (0,0)
 *     |         B(3,1)| <- B position adjusted relative to A
 *     |               |
 *     +----------------+
 *
 */

export const getNormalizedGroupPositions = ({
  nodes,
  newNodePosition,
  parentNodeId,
  nodeId,
  getNode,
  isPreview,
}: {
  nodes: SweepCanvasRfNode[];
  newNodePosition: XYPosition;
  parentNodeId: string;
  nodeId: string;
  getNode: (id: string) => SweepCanvasRfNode | undefined;
  isPreview: boolean;
}): {
  normalizedStepPositions: NormalizedNodePosition[];
  normalizedGroupPosition: NormalizedNodePosition;
  groupBoundingBox: GroupBoundingBox;
  groupNodeBoundaries: GroupNodeBoundaries;
} => {
  const allSiblingsNodesPositions = nodes
    .filter((n) => n.parentId === parentNodeId)
    .filter((n) => n.type !== CanvasElementType.DROP_ZONE_NODE)
    .map((n) => {
      const position = n.id === nodeId ? newNodePosition : n.position;
      return {
        id: n.id,
        position: convertXYPositionToGridIndex(position),
      };
    });

  const parentGroupPositionXY = getNode(parentNodeId)?.position;
  if (!parentGroupPositionXY) {
    throw new Error('Parent group position not found');
  }

  const parentGroupIndexPosition = convertXYPositionToGridPosition(parentGroupPositionXY);

  const allSiblingNodesRowPos = allSiblingsNodesPositions.map(({ position }) => position.row);
  const allSiblingNodesColPos = allSiblingsNodesPositions.map(({ position }) => position.column);

  if (allSiblingNodesRowPos.length === 0) {
    allSiblingNodesRowPos.push(0);
  }
  if (allSiblingNodesColPos.length === 0) {
    allSiblingNodesColPos.push(0);
  }

  const minCol = Math.min(...allSiblingNodesColPos);
  const maxRow = Math.max(...allSiblingNodesRowPos) + 1;

  const normalizedNodePositions: NormalizedNodePosition[] = allSiblingsNodesPositions.map(
    (nodePos) => {
      const newIndexPosition = {
        row: nodePos.position.row - maxRow,
        column: nodePos.position.column - minCol,
      };
      return {
        ...nodePos,
        position: newIndexPosition,
        xYPosition: convertGridPositionToXYPosition(newIndexPosition),
      };
    },
  );

  const newGroupIndexPosition = {
    row: parentGroupIndexPosition.row + maxRow,
    column: parentGroupIndexPosition.column + minCol,
  };

  const { groupBoundingBox, groupNodeBoundaries } = calculateGroupDimensions({
    nodeWithCanvasPositions: nodes.map((node) => ({
      id: node.id,
      position: convertXYPositionToGridIndex(node.position),
      parentId: node.parentId,
    })),
    groupId: parentNodeId,
    isPreview,
  });

  return {
    normalizedStepPositions: normalizedNodePositions,
    normalizedGroupPosition: {
      id: parentNodeId,
      position: newGroupIndexPosition,
      xYPosition: convertGridPositionToXYPosition(newGroupIndexPosition),
    },
    groupBoundingBox,
    groupNodeBoundaries,
  };
};
