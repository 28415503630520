import { Box, Stack } from '@mui/material';
import { PotentialIssue } from '@server/homepage';
import { InfoDialog } from '../../../common/dialogs/InfoDialog';
import { colors, IconButton, Tag, Typography } from '@sweep-io/sweep-design';
import { ConfigurationTypeToPotentialIssueIcon } from './utils';
import { Close } from '@sweep-io/sweep-design/dist/icons';
import { ConfigurationToName } from '../../../documentation/dependencies/types';
import { MarkdownWrapper } from '../../../common/MarkdownWrapper';
import { ExploreWithAiButton } from './ExploreWithAiButton';
import { TruncatedTextTooltip } from '../../../common/TruncatedTextTooltip';

export const PotentialIssueDialog = ({
  handleClose,
  configurationForDialog,
  withExploreAiButton,
}: {
  handleClose: () => void;
  configurationForDialog?: PotentialIssue;
  withExploreAiButton?: boolean;
}) => {
  if (!configurationForDialog) {
    return;
  }
  return (
    <InfoDialog
      handleClose={() => handleClose()}
      open={!!configurationForDialog}
      dialogContentSx={{
        p: '0px !important',
        overflow: 'hidden',
        height: 'calc(100% - 80px)',
        maxHeight: 'calc(100% - 80px)',
        maxWidth: '983px',
        pt: '0 !important',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack height="100%" overflow="hidden" justifyContent="space-between">
        <Stack
          p="32px 40px"
          height={withExploreAiButton ? 'calc(100% - 92px)' : '100%'}
          gap={0.5}
          flex={1}
          overflow="hidden"
        >
          <Box sx={{ position: 'absolute', top: '15px', right: '15px' }}>
            <IconButton size="tiny" variant="flat" onClick={() => handleClose()}>
              <Close />
            </IconButton>
          </Box>
          <TruncatedTextTooltip variant="h1-bold">
            {configurationForDialog.configurationName}
          </TruncatedTextTooltip>
          <Typography variant="body" color={colors.grey[700]}>
            Object: {configurationForDialog.objectName}
          </Typography>
          <Stack direction="row" gap={2} alignItems="center" pt={0.5} pb={0.5}>
            <Box width="32px">
              {ConfigurationTypeToPotentialIssueIcon[configurationForDialog.configurationType]}
            </Box>
            <Tag
              label={'Type: ' + ConfigurationToName[configurationForDialog.configurationType]}
              color={colors.grey[100]}
            />
            <Tag label={'Impact: ' + configurationForDialog.severity} color={colors.grey[100]} />
          </Stack>
          <Stack
            sx={{
              backgroundColor: colors.grey[100],
              pr: 2,
              pl: 2,
              height: '100%',
              maxHeight: '600px',
              overflow: 'auto',
            }}
          >
            <MarkdownWrapper content={configurationForDialog.description} removeMargins={false} />
          </Stack>
        </Stack>
        {withExploreAiButton && (
          <Stack
            width="100%"
            justifyContent="flex-end"
            alignItems="flex-end"
            p="24px 40px"
            borderTop={`1px solid ${colors.grey[300]}`}
          >
            <ExploreWithAiButton
              buttonVariant="filled"
              buttonSize="large"
              issue={configurationForDialog}
            />
          </Stack>
        )}
      </Stack>
    </InfoDialog>
  );
};
