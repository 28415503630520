import * as React from "react";
const SvgRedWarning = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M7.1374 2.50025L1.6374 12.0002C1.54978 12.152 1.50357 12.3241 1.50342 12.4994C1.50326 12.6746 1.54916 12.8468 1.63651 12.9987C1.72386 13.1506 1.8496 13.2769 2.00113 13.365C2.15266 13.453 2.32466 13.4996 2.4999 13.5002H13.4999C13.6751 13.4996 13.8471 13.453 13.9987 13.365C14.1502 13.2769 14.2759 13.1506 14.3633 12.9987C14.4506 12.8468 14.4965 12.6746 14.4964 12.4994C14.4962 12.3241 14.45 12.152 14.3624 12.0002C12.2145 8.29027 11.0103 6.21023 8.8624 2.50025C8.77543 2.34829 8.64987 2.22201 8.49841 2.13418C8.34695 2.04635 8.17498 2.00009 7.9999 2.00009C7.82482 2.00009 7.65284 2.04635 7.50138 2.13418C7.34993 2.22201 7.22436 2.34829 7.1374 2.50025Z", fill: "#F3676B", style: {
  fill: "#F3676B",
  fill: "color(display-p3 0.9529 0.4039 0.4196)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M8 6.50024V9.00024", stroke: "white", style: {
  stroke: "white",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M8.70833 11.2502C8.70833 11.6414 8.3912 11.9586 8 11.9586C7.6088 11.9586 7.29167 11.6414 7.29167 11.2502C7.29167 10.859 7.6088 10.5419 8 10.5419C8.3912 10.5419 8.70833 10.859 8.70833 11.2502Z", fill: "white", stroke: "white", style: {
  fill: "white",
  fillOpacity: 1,
  stroke: "white",
  strokeOpacity: 1
}, strokeWidth: 0.0833333 }));
export default SvgRedWarning;
