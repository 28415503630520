import { Button, IconButton } from '@sweep-io/sweep-design';
import useAiAgentCenter from './useAiAgentCenter';
import RestrictedTooltip from '../common/permissions/RestrictedTooltip';
import usePermission from '../common/permissions/usePermission';
import { Plus } from '@sweep-io/sweep-design/dist/icons';
import useSendBiEvent from '../../hooks/useSendBiEvent';
import { AI_AGENTS_EVENTS } from '../../services/events';
import { useFeatureToggle } from '../common/useFeatureToggle';
import useIsAiAgentsEnabled from './useIsAiAgentsEnabled';

const AiProcessAgentCreateButton = ({ asIcon }: { asIcon?: boolean }) => {
  const permissionStr = 'create:ai-agent';
  const [isAllowedBtn] = usePermission([permissionStr]);
  const { setIsAgentCreateActive, toggleIsOpened, isOpened, isCreateAgentActive } =
    useAiAgentCenter();
  const sendBiEvent = useSendBiEvent();
  const isAiAgentsEnabled = useIsAiAgentsEnabled();
  const { aiAgentCenter: processAgentEnabled } = useFeatureToggle();

  const onClick = () => {
    if (isOpened) {
      toggleIsOpened();
    }
    if (!isCreateAgentActive) {
      sendBiEvent({
        name: AI_AGENTS_EVENTS.newAgentClick,
        props: { origin: 'button', app: 'canvas' },
      });
    }
    setIsAgentCreateActive(!isCreateAgentActive);
  };

  if (!isAiAgentsEnabled || !processAgentEnabled) {
    return null;
  }

  const button = asIcon ? (
    <IconButton
      disabled={!isAllowedBtn}
      variant="outlined"
      size="small"
      onClick={onClick}
      pressed={isCreateAgentActive}
    >
      <Plus />
    </IconButton>
  ) : (
    <Button
      disabled={!isAllowedBtn}
      variant="outlined"
      size="small"
      onClick={onClick}
      startIconName="Plus"
      pressed={isCreateAgentActive}
    >
      New Agent
    </Button>
  );

  return (
    <RestrictedTooltip
      to={[permissionStr]}
      notAllowedTitle={'To create an agent, please contact your admin.'}
      allowedTitle={asIcon ? 'Create new agent' : ''}
      placement="bottom"
    >
      {button}
    </RestrictedTooltip>
  );
};

export default AiProcessAgentCreateButton;
