import { fontFamily } from '@sweep-io/sweep-design/dist/theme/typography';
import { colors } from '@sweep-io/sweep-design';
import { ReactDiffViewerStylesOverride } from 'react-diff-viewer-continued';

export default {
  variables: {
    light: {
      diffViewerTitleBackground: colors.white,
      wordAddedBackground: colors.mint[300],
      addedBackground: colors.mint[100],
      wordRemovedBackground: colors.blush[300],
      removedBackground: colors.blush[100],
    },
  },
  marker: {
    background: colors.grey[100],
    pre: {
      color: `${colors.grey[700]} !important`,
      fontSize: '20px',
    },
  },
  titleBlock: {
    pre: {
      color: colors.grey[900],
      fontSize: '14px',
      fontWeight: 600,
    },
  },
  contentText: {
    fontFamily,
    fontSize: '14px',
    paddingLeft: '10px',
    letterSpacing: 0,
    '.diff-section-title': {
      fontWeight: 500,
      paddingTop: '8px',
      display: 'flex',
      flexDirection: 'row',
    },
    '.diff-section-content': {
      paddingLeft: '18px',
    },
    '.diff-section-picklist': {
      display: 'flex',
      borderBottom: `1px solid ${colors.grey[120]}`,
      padding: '0 20px',
      fontSize: '12px',
      fontWeight: '400',
      height: '28px',
      '& > div': {
        flex: '1 1 0',
        width: 0,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      '&.header-line': {
        height: '32px',
        fontWeight: '500',
        color: colors.grey[900],
      },
    },
  },
  wordAdded: {
    display: 'block',
  },
  wordRemoved: {
    display: 'block',
  },
} as ReactDiffViewerStylesOverride;
