import { TableProps, TableVirtuoso } from 'react-virtuoso';
import {
  DataTableSortableProps,
  DataTableRow,
  DataTableSortStateProps,
  DataTableVariant,
} from './TableTypes';
import { TableLoader } from './TableLoader';
import { StyledTableBody__deprecated, StyledTableHead__deprecated } from './StyledTableComponents';
import { Box, Table, TableRowProps, TableRow as MuiTableRow } from '@mui/material';
import { forwardRef, useCallback, useMemo, useState } from 'react';
import { SortOrder } from '../types';
import { ReachedLastRow } from './ReachedLastRow';
import { TableHeadRow__deprecated } from './TableHeadRow__deprecated';
import TableRow__deprecated from './TableRow__deprecated';

/**
 * Supports out of the box support of virtual scrolling for smooth big amounts of data rendering
 */
export function VirtualScrollDataTable__deprecated<TRow extends DataTableRow>({
  rows,
  columns: allColumns,
  onRowClick,
  containerRef,
  onSortChange,
  defaultSortState,
  tableEmptyStateJsx,
  sxRowFunction,
  actionableButtonsOnHover,
  isLoading,
  variant = DataTableVariant.default,
  reachedLastRow,
  isVirtualStaticRow,
}: Pick<
  DataTableSortableProps<TRow>,
  | 'onRowClick'
  | 'columns'
  | 'rows'
  | 'containerRef'
  | 'onSortChange'
  | 'defaultSortState'
  | 'tableEmptyStateJsx'
  | 'isLoading'
  | 'variant'
  | 'actionableButtonsOnHover'
  | 'sxRowFunction'
  | 'reachedLastRow'
  | 'isVirtualStaticRow'
>) {
  const [sortState, setSortState] = useState<DataTableSortStateProps | undefined>(defaultSortState);
  const columns = useMemo(() => allColumns.filter((column) => !column.hidden), [allColumns]);

  const _sort = useCallback(
    (columnName: string) => {
      const newState = {
        sortBy: columnName,
        sortOrder: sortState?.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC,
      };

      setSortState(newState);
      onSortChange && onSortChange(newState);
    },
    [onSortChange, sortState?.sortOrder],
  );

  const renderHeader = useCallback(() => {
    return (
      <TableHeadRow__deprecated
        columns={columns}
        stickyHeader
        variant={variant}
        sort={_sort}
        sortState={sortState}
      />
    );
  }, [columns, _sort, sortState, variant]);

  const components = useMemo(() => {
    return {
      Table: forwardRef<any, TableProps>(({ children, style }, ref) => {
        return (
          <Table
            ref={ref}
            style={
              {
                '--virtuosoPaddingTop': (style?.paddingTop ?? 0) + 'px',
                '--virtuosoPaddingBottom': (style?.paddingBottom ?? 0) + 'px',
              } as any
            }
          >
            {children}
          </Table>
        );
      }),
      TableHead: forwardRef<any, TableProps>(({ children }, ref) => {
        return <StyledTableHead__deprecated ref={ref}>{children}</StyledTableHead__deprecated>;
      }),
      TableBody: forwardRef<any, TableProps>((props, ref) => {
        return (
          <StyledTableBody__deprecated
            ref={ref}
            style={{ position: rows.length ? 'relative' : 'initial' }}
          >
            {!!rows.length ? (
              props.children
            ) : (
              <MuiTableRow
                sx={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  '&:hover': { backgroundColor: 'transparent !important' },
                }}
              >
                {tableEmptyStateJsx}
              </MuiTableRow>
            )}
          </StyledTableBody__deprecated>
        );
      }),
      TableRow: forwardRef<any, TableRowProps>((props: any, ref) => {
        const row = props.item;
        const idx = props['data-index'];

        return (
          <>
            <TableRow__deprecated
              isVirtualStaticRow={isVirtualStaticRow}
              data-index={idx}
              ref={ref}
              row={row}
              columns={columns}
              allowReorder={false}
              sxRowFunction={sxRowFunction}
              tableRowProps={{ id: `table_row_${row.id}` }}
              onClick={(e, column) => {
                onRowClick && onRowClick(e, row, column);
              }}
              actionableButtonsOnHover={actionableButtonsOnHover}
              variant={variant}
              {...props}
            />

            {reachedLastRow && idx === reachedLastRow?.rowsMaxCount - 1 && (
              <ReachedLastRow
                columnsCount={columns.length}
                reachedLastRowTxt={reachedLastRow.txt}
                scrollToTop={() => {
                  (containerRef?.current as any)?.scrollToIndex({
                    index: 0,
                    top: 0,
                    behavior: 'smooth',
                  });
                }}
              />
            )}
          </>
        );
      }),
    };
  }, [
    tableEmptyStateJsx,
    actionableButtonsOnHover,
    columns,
    onRowClick,
    rows.length,
    sxRowFunction,
    variant,
    containerRef,
    reachedLastRow,
    isVirtualStaticRow,
  ]);

  return (
    <Box height="100%" position="relative">
      {isLoading && <TableLoader />}

      <TableVirtuoso
        ref={containerRef as any}
        totalCount={rows.length}
        data={rows}
        components={components}
        fixedHeaderContent={renderHeader}
      />
    </Box>
  );
}
