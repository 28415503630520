import { colors } from '@sweep-io/sweep-design';
import { Sparkle } from '@sweep-io/sweep-design/dist/icons';
import { Box } from '@mui/material';
import { selectAgents } from '../../../aiAgentCenter/aiAgentsReducer';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import AiAgentsButtonAndMenu from '../../../aiAgentCenter/AiAgentsButtonAndMenu';
import { selectDefaultCreationCrmOrgId } from '../../../../reducers/userInfoReducer';
import { AiAgent } from '../../../aiAgentCenter/aiAgentsConsts';
import { useMemo } from 'react';

//The design is similar to "PluginButton"
const AgentsButton = styled(Box)`
  display: flex;
  border: 2px solid ${colors.white};
  border-radius: 4px;
  background-color: ${colors.blue[500]};
  width: 28px;
  height: 28px;
  justify-content: center;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    opacity: 0.8;
    border-color: ${colors.blue[500]};
  }
`;

const FunnelAiAgentsButton = ({ funnelId }: { funnelId: string }) => {
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  const aiAgents = useSelector(selectAgents(crmOrgId ?? undefined));

  const relevantAiAgents = useMemo(() => {
    return getAgentsPerFunnel(funnelId, aiAgents ?? []);
  }, [aiAgents, funnelId]);

  const hasAgents = relevantAiAgents.length > 0;

  if (!hasAgents) return null;

  return (
    <AiAgentsButtonAndMenu
      aiAgents={relevantAiAgents}
      button={
        <AgentsButton>
          <Sparkle color={colors.white} />
        </AgentsButton>
      }
    />
  );
};

const getAgentsPerFunnel = (funnelId: string, aiAgents: AiAgent[]) => {
  return aiAgents.filter((agent) => {
    if (Array.isArray(agent.contextComponents)) {
      return agent.contextComponents?.some((component) => component.funnelId === funnelId);
    } else {
      return false;
    }
  });
};

export default FunnelAiAgentsButton;
