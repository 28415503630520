import { createContext, useContext } from 'react';
import { OnPluginClickEvent, OnSweepNodesChange } from '../SweepCanvas';
import {
  SweepCanvasNode,
  SweepCanvasEdge,
  SweepCanvasGroup,
  OnNodeClickProps,
  CanvasMode,
} from '../canvas-types';
import { MoveCanvasGroups } from '../useMoveGroups';
import { SweepCanvasBackgroundProps } from '../canvas-background/CanvasBackground';
import { SweepCanvasControlsProps } from '../CanvasControls';
import { VisibilityMap } from '../../../types/VisibilityTypes';
import { DEFAULT_VISIBILITY_MAP } from '../../../constants/visibility';

export type OnConnectClick = (
  event: React.MouseEvent<HTMLButtonElement>,
  node: {
    stepId?: string;
    groupId: string;
  },
) => any;

export type OnEdgeDeleteClick = (params: {
  edgeId: string;
  sourceNodeId: string;
  targetNodeId: string;
  sourceNodeParentId?: string;
  targetNodeParentId?: string;
}) => any;

interface BaseSweepCanvasProps<GroupMetadata = any> {
  sweepNodes: SweepCanvasNode[];
  sweepEdges: SweepCanvasEdge[];
  sweepGroups: SweepCanvasGroup<GroupMetadata>[];
  onSweepNodesChange?: OnSweepNodesChange;
  onNodeClick?: (props: OnNodeClickProps) => any;
  onPillClick?: (props: OnNodeClickProps) => any;
  onLabelClick?: (props: OnNodeClickProps) => any;
  onGateClick?: (sourceNodeId: string, targetNodeId: string, sourceNodeParentId: string) => any;
  onEdgeDeleteClick?: OnEdgeDeleteClick;
  onConnectClick?: OnConnectClick;
  readonly?: boolean;
  fitViewTimeoutValue?: number;
  onNodeNameChange?: (name: string, nodeId: string) => { isValid: boolean; error?: string };
  moveGroups?: MoveCanvasGroups;
  holdNodeHighlighted?: boolean;
  hideGroupInfo?: boolean;
  disableGroups?: boolean;
  autoFitViewOnFirstNodes?: boolean;
  showControls?: boolean;
  isLoadingCursor?: boolean;
  showGroupOverlays?: boolean;
  onPluginClick?: OnPluginClickEvent;
  canvasMode?: CanvasMode;
  disableNodeHighlight?: boolean;
  simpleHighlightedEntities?: string[];
  visibilityMap: VisibilityMap;
}

export type SweepCanvasProps<GroupMetadata = any> = BaseSweepCanvasProps<GroupMetadata> &
  SweepCanvasBackgroundProps &
  SweepCanvasControlsProps;

const defaultSweepCanvasProps: SweepCanvasProps = {
  sweepNodes: [],
  sweepEdges: [],
  sweepGroups: [],
  visibilityMap: DEFAULT_VISIBILITY_MAP,
};

const SweepCanvasPropsCtx = createContext<SweepCanvasProps>(defaultSweepCanvasProps);

export const useSweepCanvasPropsCtx = () => {
  return useContext(SweepCanvasPropsCtx);
};

export const SweepCanvasPropsCtxProvider = ({
  children,
  value,
}: {
  children: React.ReactNode;
  value: SweepCanvasProps;
}) => {
  return <SweepCanvasPropsCtx.Provider value={value}>{children}</SweepCanvasPropsCtx.Provider>;
};
