import { Stack } from '@mui/material';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Typography, colors } from '@sweep-io/sweep-design';
import { CSSTransition } from 'react-transition-group';
import './loaderStyles.css';

const commonProps = {
  timeout: 300,
  classNames: 'loader-slide',
  mountOnEnter: true,
  unmountOnExit: true,
};

const DEFAULT_TEXTS = ['Thinking...', 'Analyzing...', 'Processing...'];

const GeneratingChatLoader = ({ avatar, texts }: { avatar: ReactNode; texts?: string[] }) => {
  const [index, setIndex] = useState(0);

  const _texts = useMemo(() => texts ?? DEFAULT_TEXTS, [texts]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prev) => {
        if (prev === _texts.length - 1) {
          return 0;
        } else {
          return prev + 1;
        }
      });
    }, 2000);

    return () => clearInterval(interval);
  }, [_texts]);

  return (
    <Stack direction="row" gap={0.5} width="300px">
      {avatar}
      <Stack padding="4px 10px" position="relative" flex={1}>
        <Stack position="absolute">
          <CSSTransition in={index === 0} {...commonProps}>
            <Typography variant="caption" color={colors.grey[700]}>
              {_texts[0]}
            </Typography>
          </CSSTransition>
        </Stack>

        <Stack position="absolute">
          <CSSTransition in={index === 1} {...commonProps}>
            <Typography variant="caption" color={colors.grey[700]}>
              {_texts[1]}
            </Typography>
          </CSSTransition>
        </Stack>

        <Stack position="absolute">
          <CSSTransition in={index === 2} {...commonProps}>
            <Typography variant="caption" color={colors.grey[700]}>
              {_texts[2]}
            </Typography>
          </CSSTransition>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default GeneratingChatLoader;
