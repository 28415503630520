import * as React from "react";
const SvgBlueTool = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M13.6814 4.43825C14.0094 5.20406 14.0897 6.05326 13.911 6.86696C13.7324 7.68067 13.3038 8.41814 12.6851 8.97612C12.0665 9.53411 11.2889 9.88468 10.4612 9.97876C9.63341 10.0728 8.79696 9.90569 8.06891 9.50075L4.56266 13.5633C4.28087 13.845 3.89868 14.0034 3.50016 14.0034C3.10165 14.0034 2.71945 13.845 2.43766 13.5633C2.15587 13.2815 1.99756 12.8993 1.99756 12.5008C1.99756 12.1022 2.15587 11.72 2.43766 11.4383L6.50016 7.932C6.09522 7.20396 5.92808 6.36751 6.02216 5.53975C6.11623 4.712 6.4668 3.93438 7.02479 3.31577C7.58278 2.69716 8.32024 2.26853 9.13395 2.08989C9.94765 1.91124 10.7969 1.99153 11.5627 2.3195L8.93766 4.93825L9.29391 6.707L11.0627 7.06325L13.6814 4.43825Z", fill: "#ACE6F9", stroke: "#1198FF", style: {
  fill: "#ACE6F9",
  fill: "color(display-p3 0.6745 0.9020 0.9765)",
  fillOpacity: 1,
  stroke: "#1198FF",
  stroke: "color(display-p3 0.0667 0.5961 1.0000)",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }));
export default SvgBlueTool;
