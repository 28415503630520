import { useConfigurationCanvasContext } from '../../../configuration-canvas/ConfigurationCanvasContext';
import useAiAgentCenter from '../../../../aiAgentCenter/useAiAgentCenter';

export const useHandleCommentsAndAiFirst = () => {
  const { setCommentsOn } = useConfigurationCanvasContext();
  const { toggleIsExpanded, isOpened, isExpanded } = useAiAgentCenter();

  const handleCommentsAndAiFirst = (cb: any, disableCollapseAi?: boolean) => {
    //When any panel is opened, the "AI center" should be collapsed
    //We use disableCollapseAi to identify the click on the "AI center" open button
    if (!disableCollapseAi && isOpened && isExpanded) {
      toggleIsExpanded();
    }
    setCommentsOn(false);
    cb && cb();
  };

  return handleCommentsAndAiFirst;
};
