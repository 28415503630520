import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import {
  addAsyncResponse,
  addSyncMessageToActiveChat,
  clearSearch,
  createGhostChat as createGhostChatAction,
  deleteChat as deleteChatAction,
  replaceTempWithChatIdAndName,
  resetAnimateName,
  selectActiveChatId,
  selectActiveIsEmptyChat,
  selectChatsDetails,
  selectChatsListData,
  selectChatsListIsLoading,
  selectSearchIsLoading,
  selectSearchResults,
  selectSearchText,
  setActiveChat,
  setChat,
  setChatContextForGhostChat,
  setChatIsFavorite,
  setChatName,
  setChatsList,
  setLoadingChatDetails,
  setLoadingChatListItem,
  setLoadingChatsList,
  setLoadingSearch,
  setSearchResult,
} from './aiChatsReducer';
import { sortByDate } from '../../utils/sortByDate';
import { SortOrder } from '../common/types';
import { useAiAgentApiFacade } from '../../apis/facades/useAiAgentApiFacade';
import { selectDefaultCreationCrmOrgId } from '../../reducers/userInfoReducer';
import { DateTime } from 'luxon';
import { AgentType, ChatCompletionRequestMessageRole } from '@server/ai';
import { telemetry } from '../../telemetry';
import { AiChatMessage } from '../common/ai-chat/aiChatTypes';
import { selectAgents } from './aiAgentsReducer';
import keyBy from 'lodash/keyBy';
import { AI_AGENTS_EVENTS } from '../../services/events';
import useSendBiEvent from '../../hooks/useSendBiEvent';
import { AiAgent } from './aiAgentsConsts';

const useAiAgentChats = () => {
  const {
    post_newChat,
    get_chats,
    patch_sendMessage,
    get_chat,
    rename_chat,
    delete_chat,
    set_isFavorite_chat,
    get_search,
    get_newRecommendations,
  } = useAiAgentApiFacade();
  const sendBiEvent = useSendBiEvent();
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  const _crmOrgId = crmOrgId ?? undefined;
  const chatsDetails = useSelector(selectChatsDetails(_crmOrgId));
  const activeChatId = useSelector(selectActiveChatId(_crmOrgId));
  const chatsList = useSelector(selectChatsListData(_crmOrgId));
  const chatsListIsLoading = useSelector(selectChatsListIsLoading(_crmOrgId));
  const isLoadingSearch = useSelector(selectSearchIsLoading(_crmOrgId));
  const searchResults = useSelector(selectSearchResults(_crmOrgId));
  const searchText = useSelector(selectSearchText(_crmOrgId));
  const agents = useSelector(selectAgents(_crmOrgId));
  const activeIsEmptyChat = useSelector(selectActiveIsEmptyChat(_crmOrgId));
  const activeChat = activeChatId ? chatsDetails?.[activeChatId] : undefined;

  const activeAgent = useMemo(
    () => agents?.find((agent) => agent.id === activeChat?.data?.agentId),
    [agents, activeChat?.data?.agentId],
  );

  const agentsMap = keyBy(agents, 'id');

  const dispatch = useDispatch();

  const fetchChats = useCallback(async () => {
    if (!crmOrgId) return;
    try {
      dispatch(setLoadingChatsList({ crmOrgId }));
      const chats = await get_chats();
      dispatch(setChatsList({ crmOrgId, chats }));
    } catch (e) {
      telemetry.captureError(e);
    }
  }, [crmOrgId, dispatch, get_chats]);

  const orderedChats = useMemo(
    () => chatsList?.toSorted((a, b) => sortByDate(a.updatedAt, b.updatedAt, SortOrder.DESC)) ?? [],
    [chatsList],
  );

  const onConfirmMessage = useCallback(
    async ({
      messageStr,
      agentId,
      agentType,
    }: {
      messageStr: string;
      agentId: string;
      agentType: AgentType;
    }) => {
      let newChatIdToUse: string | undefined = undefined;
      const createdAt = DateTime.now().toISO() ?? '';
      const newMessage = {
        role: ChatCompletionRequestMessageRole.USER,
        content: messageStr,
        createdAt,
      };

      dispatch(
        addSyncMessageToActiveChat({
          message: newMessage,
          crmOrgId,
          agentId,
          agentType,
        }),
      );

      //cant call API if there's no crmOrgId
      if (!crmOrgId) {
        return;
      }

      const isFirstMessage = activeChat
        ? activeChat.data?.aiChatDetails?.messages.length === 0
        : true;

      try {
        let responseMsg: AiChatMessage | undefined;

        if (isFirstMessage) {
          const chatContextComponents = activeChat?.data?.chatContextComponents;
          const res = await post_newChat(agentId, {
            message: newMessage,
            ...(chatContextComponents ? { potentialIssues: chatContextComponents } : {}),
          });
          newChatIdToUse = res.id;
          dispatch(
            replaceTempWithChatIdAndName({
              crmOrgId,
              chatId: newChatIdToUse,
              name: res.name,
              agentId,
              agentType,
            }),
          );

          setTimeout(() => {
            //allow the name animation to run only once, and then reset the property
            dispatch(
              resetAnimateName({
                crmOrgId,
                chatId: newChatIdToUse ?? '',
              }),
            );
          }, 1000);

          responseMsg = res.message;
        } else {
          // the "else" handles sending adding a message to an existing chat (patch)
          const res = await patch_sendMessage({
            chatId: activeChatId ?? '',
            payload: { message: newMessage },
          });
          responseMsg = res;
        }

        const answeredAt = DateTime.now().toISO() ?? '';

        dispatch(
          addAsyncResponse({
            chatId: newChatIdToUse ?? activeChatId,
            message: { ...responseMsg, createdAt: answeredAt },
            crmOrgId,
          }),
        );
      } catch (e) {
        const answeredAt = DateTime.now().toISO() ?? '';
        dispatch(
          addAsyncResponse({
            chatId: newChatIdToUse ?? activeChatId,
            message: {
              content: 'Something went wrong, please try again later',
              createdAt: answeredAt,
              role: ChatCompletionRequestMessageRole.ASSISTANT,
              isError: true,
            },
            crmOrgId,
          }),
        );
        telemetry.captureError(e);
      }
    },
    [activeChatId, activeChat, dispatch, crmOrgId, post_newChat, patch_sendMessage],
  );

  const setActiveChatId = useCallback(
    async (chatId?: string) => {
      dispatch(setActiveChat({ crmOrgId, chatId }));
      if (!chatId || chatsDetails?.[chatId]) {
        return;
      }
      if (crmOrgId) {
        try {
          dispatch(setLoadingChatDetails({ crmOrgId, chatId }));
          const chat = await get_chat(chatId);
          dispatch(
            setChat({
              crmOrgId,
              chat: { ...chat, agentType: agentsMap[chat.agentId].type, isLoading: false },
            }),
          );
        } catch (e) {
          telemetry.captureError(e);
        }
      }
    },
    [crmOrgId, agentsMap, dispatch, chatsDetails, get_chat],
  );

  //create just the infra - there's no messages yet
  const createGhostChat = useCallback(
    async ({
      agentId,
      agentType,
      isLoading,
    }: {
      agentId: string;
      agentType: AgentType;
      isLoading?: boolean;
    }) => {
      dispatch(createGhostChatAction({ crmOrgId, agentId, agentType, isLoading }));
    },
    [crmOrgId, dispatch],
  );

  const startNewChat = useCallback(
    async ({
      agentId,
      agentType,
      biEventProps,
    }: {
      agentId: string;
      agentType: AgentType;
      biEventProps?: Record<string, any>;
    }) => {
      if (biEventProps) {
        sendBiEvent({
          name: AI_AGENTS_EVENTS.createNewChat,
          props: { ...biEventProps, agentType },
        });
      }

      createGhostChat({
        agentId,
        agentType,
      });
    },
    [createGhostChat, sendBiEvent],
  );

  //This is called before the chat is created, so we're using the ghost chat
  const getChatContextForMonitoringAgent = useCallback(
    async ({ agent }: { agent: AiAgent }) => {
      if (crmOrgId) {
        try {
          createGhostChat({
            agentId: agent.id,
            agentType: agent.type,
            isLoading: true,
          });
          const potentialIssues = await get_newRecommendations({ agentId: agent.id });
          dispatch(
            setChatContextForGhostChat({
              crmOrgId,
              chatContextComponents: potentialIssues,
            }),
          );
        } catch (e) {
          telemetry.captureError(e);
        }
      }
    },
    [createGhostChat, crmOrgId, dispatch, get_newRecommendations],
  );

  const startChatWithAgent = useCallback(
    ({ agent, biEventProps }: { agent: AiAgent; biEventProps?: Record<string, any> }) => {
      switch (agent.type) {
        case AgentType.Monitoring:
          getChatContextForMonitoringAgent({
            agent,
          });
          return;
        default:
          startNewChat({
            agentId: agent.id,
            agentType: agent.type,
            biEventProps,
          });
      }
    },
    [getChatContextForMonitoringAgent, startNewChat],
  );

  const renameChat = useCallback(
    async ({ chatId, name }: { chatId: string; name: string }) => {
      if (crmOrgId) {
        try {
          dispatch(setLoadingChatListItem({ crmOrgId, chatId }));
          await rename_chat(chatId, { name });
          dispatch(setChatName({ crmOrgId, chatId, name }));
        } catch (e) {
          telemetry.captureError(e);
        }
      }
    },
    [crmOrgId, dispatch, rename_chat],
  );

  const deleteChat = useCallback(
    async (chatId: string) => {
      if (crmOrgId) {
        try {
          dispatch(setLoadingChatListItem({ crmOrgId, chatId }));
          await delete_chat(chatId);
          dispatch(deleteChatAction({ crmOrgId, chatId }));
        } catch (e) {
          telemetry.captureError(e);
        }
      }
    },
    [crmOrgId, delete_chat, dispatch],
  );

  const togglePin = useCallback(
    async (chatId: string) => {
      const relevantChat = chatsList?.find((chat) => chat.id === chatId);
      if (!!crmOrgId && !!relevantChat) {
        try {
          const isFavorite = !relevantChat.isFavorite;
          set_isFavorite_chat(chatId, { isFavorite });
          dispatch(setChatIsFavorite({ crmOrgId, chatId, isFavorite }));
        } catch (e) {
          telemetry.captureError(e);
        }
      }
    },
    [chatsList, crmOrgId, dispatch, set_isFavorite_chat],
  );

  const getSearchResults = useCallback(
    async (localSearchText: string) => {
      if (crmOrgId) {
        try {
          const trimmedSearch = localSearchText.trim();
          dispatch(setLoadingSearch({ crmOrgId, searchText: trimmedSearch }));
          const chats = await get_search(localSearchText);
          const chatsWithAgentType = chats?.map((chat) => ({
            ...chat,
            agentType: agentsMap[chat.agentId].type,
            isLoading: false,
          }));
          dispatch(setSearchResult({ crmOrgId, chats: chatsWithAgentType }));
        } catch (e) {
          telemetry.captureError(e);
        }
      }
    },
    [agentsMap, crmOrgId, dispatch, get_search],
  );

  const clearSearchResults = useCallback(() => {
    if (crmOrgId) {
      dispatch(clearSearch({ crmOrgId }));
    }
  }, [crmOrgId, dispatch]);

  const inputPlaceholder = useMemo(() => {
    switch (activeAgent?.type) {
      case AgentType.ProcessOptimization:
      case AgentType.Monitoring:
        return `Message ${activeAgent.name} agent`;

      case AgentType.Documentation:
      default:
        return 'Ask anything...';
    }
  }, [activeAgent]);

  return {
    chats: orderedChats,
    activeChat,
    chatsListIsLoading,
    fetchChats,
    onConfirmMessage,
    setActiveChatId,
    renameChat,
    deleteChat,
    togglePin,
    searchText,
    searchResults,
    isLoadingSearch,
    getSearchResults,
    clearSearchResults,
    activeChatId,
    inputPlaceholder,
    activeIsEmptyChat,
    startChatWithAgent,
  };
};

export default useAiAgentChats;
