import { useMemo } from 'react';
import { useSweepCanvasState } from './internal-context/CanvasStateContext';
import { ContextZoomType, CanvasMode } from './canvas-types/canvasTypes';
import { sweepNodeCanvasSx } from './nodes/GroupNode';
import { VisibilityLayers } from '../../types/enums/VisibilityLayers';
import { getGroupLabelStyleTransformations, groupLabelStyle } from './nodes/GroupLabelNode';
import {
  contextZoomTypeToClass,
  canvasModeToClass,
  visibilityMapClasses,
} from './nodes/contextZoomConsts';
import merge from 'lodash/merge';
import { useViewport } from '@xyflow/react';
import { getZoomLevels } from './effects/useContextZoomEffect';
import { useSweepCanvasPropsCtx } from './internal-context/SweepCanvasPropsCtx';
import { CONTEXT_ZOOM_LEVEL_1, CONTEXT_ZOOM_LEVEL_1_5, CONTEXT_ZOOM_LEVEL_2 } from './const';

const MAX_LABELS_COUNT = 9999999;

export const useCanvasZoomStyles = () => {
  const { canvasMode = CanvasMode.DEFAULT, sweepGroups, visibilityMap } = useSweepCanvasPropsCtx();
  const { reactFlowInstance } = useSweepCanvasState();
  const groupLabelsCount = sweepGroups.length;

  const zoom = reactFlowInstance?.getViewport().zoom;

  const contextZoomType = useMemo(() => {
    let _contextZoom = ContextZoomType.DEFAULT;
    if (!zoom) {
      return _contextZoom;
    }
    if (zoom <= CONTEXT_ZOOM_LEVEL_1) {
      _contextZoom = ContextZoomType.LEVEL1;
    }
    if (zoom <= CONTEXT_ZOOM_LEVEL_1_5 || canvasMode === CanvasMode.PREVIEW2) {
      _contextZoom = ContextZoomType.LEVEL1_5;
    }
    if (zoom <= CONTEXT_ZOOM_LEVEL_2) {
      _contextZoom = ContextZoomType.LEVEL2;
    }
    if (canvasMode === CanvasMode.PREVIEW2) {
      _contextZoom = ContextZoomType.LEVEL1_5;
    }

    return _contextZoom;
  }, [canvasMode, zoom]);

  const { zoom: zoomLevel } = useViewport();

  const contextZoomClass =
    contextZoomTypeToClass[(contextZoomType || ContextZoomType.DEFAULT) as string];
  const canvasModeClass = canvasModeToClass[canvasMode];

  const _visibilityMapClasses = Object.entries(visibilityMap).reduce((acc, [key, value]) => {
    if (value) {
      acc.push(visibilityMapClasses[key as VisibilityLayers]);
    }
    return acc;
  }, [] as string[]);

  const groupLabelStyleTransformationsStyle = useMemo(() => {
    return groupLabelsCount < MAX_LABELS_COUNT
      ? getGroupLabelStyleTransformations(zoomLevel)
      : undefined;
  }, [groupLabelsCount, zoomLevel]);

  const sx = useMemo(
    () => merge(sweepNodeCanvasSx, groupLabelStyle, groupLabelStyleTransformationsStyle),
    [groupLabelStyleTransformationsStyle],
  );

  const classes = useMemo(() => {
    const {
      isZoomLowerThan0_3,
      isZoomHigherThan0_3,
      isZoomLowerThan0_35,
      isZoomHigherThan0_35,
      isZoomLowerThan0_4,
      isZoomHigherThan0_4,
      isZoomLowerThan0_75,
      isZoomHigherThan0_75,
    } = getZoomLevels(zoomLevel);
    const zoomLevelClasses = [
      isZoomLowerThan0_3 && 'zoom-level-lower-than-0_3',
      isZoomHigherThan0_3 && 'zoom-level-higher-than-0_3',
      isZoomLowerThan0_35 && 'zoom-level-lower-than-0_35',
      isZoomHigherThan0_35 && 'zoom-level-higher-than-0_35',
      isZoomLowerThan0_4 && 'zoom-level-lower-than-0_4',
      isZoomHigherThan0_4 && 'zoom-level-higher-than-0_4',
      isZoomLowerThan0_75 && 'zoom-level-lower-than-0_75',
      isZoomHigherThan0_75 && 'zoom-level-higher-than-0_75',
    ].filter(Boolean) as string[];

    return [contextZoomClass, canvasModeClass, ..._visibilityMapClasses, ...zoomLevelClasses];
  }, [_visibilityMapClasses, canvasModeClass, contextZoomClass, zoomLevel]);

  return useMemo(() => ({ sx, classes }), [sx, classes]);
};
