import { Box, colors, Icon, Stack } from '@mui/material';
import { Button, IconButton, Typography } from '@sweep-io/sweep-design';
import { useDispatch, useSelector } from 'react-redux';
import { useIntegrations } from '../../../hooks/useIntegrations';
import {
  selectTeamsIntegrationStatus,
  setTeamsIntegrationStatus,
  TeamsConnectionStatus,
} from '../../../reducers/integrationsReducer';
import { useRunOnce } from '../../common/useRunOnce';
import { waitForWindowToClose } from '../../pages/helper';
import { randomId } from '../../../utils/teamsOAuthStateUtils';
import RestrictedTooltip from '../../common/permissions/RestrictedTooltip';
import usePermission from '../../common/permissions/usePermission';
import { ButtonSize, ButtonVariants } from '@sweep-io/sweep-design/dist/components/Button/types';
import usePopover from '../../../hooks/usePopover';
import { ActionsRequireMp } from '../../common/install-managed-package/getInstallManagedPackageText';
import InstallManagedPackagePopoverContent from '../../common/install-managed-package/InstallManagedPackagePopoverContent';
import IntegrationsButtonPopover from './IntegrationsButtonPopover';
import { ACTIONS_EVENTS } from '../../../services/events';
import useSendBiEvent from '../../../hooks/useSendBiEvent';
import { setTeamsStateToken } from '../../../utils/teamsOAuthStateUtils';
import { Info } from '@sweep-io/sweep-design/dist/icons';
import { useSweepNotifications } from '../../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../../reducers/notificationsReducer';
import { InfoDialog } from '../../common/dialogs/InfoDialog';
import { useState } from 'react';
import { MicrosoftTeamsLogo } from '../../common/connect-sweep-to/integrationIcons';

const SERVER_URL = import.meta.env.VITE_SERVER_URL;

const getTeamsRedirectToOAuthUrl = (state: string) =>
  `${SERVER_URL}/data-integrations/teams/redirect-to-oauth?data=${state}`;

interface TeamsConnectButtonProps {
  variant?: ButtonVariants;
  size?: ButtonSize;
  disabled?: boolean;
}

export const TeamsConnectButton = ({
  variant = 'filled',
  size = 'small',
  disabled,
}: TeamsConnectButtonProps) => {
  const sendBiEvent = useSendBiEvent();
  const teamsStatus = useSelector(selectTeamsIntegrationStatus);
  const dispatch = useDispatch();
  const integrations = useIntegrations();
  const managedPackagePopover = usePopover();
  const infoDialogPopover = usePopover();
  const { addNotification } = useSweepNotifications();
  const [isInfoDialogForcedOpen, setIsInfoDialogForcedOpen] = useState(false);

  const [isConnectTeamsAllowed, isDisconnectTeamsAllowed] = usePermission([
    'create:data-integrations:teams:oauth',
    'delete:data-integrations:teams',
  ]);

  useRunOnce(async () => {
    await integrations.getTeamsStatus();
    if (teamsStatus === TeamsConnectionStatus.AwaitingApproval && !isInfoDialogForcedOpen) {
      infoDialogPopover.openPopover();
      setIsInfoDialogForcedOpen(true);
    }
  });

  const connectToTeams = async () => {
    sendBiEvent({ name: ACTIONS_EVENTS.teamsConnect });
    const id = randomId(15);
    setTeamsStateToken(id);
    const _window = window.open(getTeamsRedirectToOAuthUrl(id), '_blank');

    if (_window) {
      dispatch(setTeamsIntegrationStatus(TeamsConnectionStatus.Initialization));
      await waitForWindowToClose(_window);
      const status = await integrations.getTeamsStatus();
      if (status === TeamsConnectionStatus.Error) {
        addNotification({
          keepOpen: true,
          variant: SweepNotificationVariant.Error,
          message:
            'Connection problem. Try reconnecting, check admin approval, or wait — Teams may take up to 24 hours to update.',
        });
      }
      if (status === TeamsConnectionStatus.AwaitingApproval) {
        if (isInfoDialogForcedOpen) {
          addNotification({
            keepOpen: true,
            variant: SweepNotificationVariant.Error,
            message: `Unable to connect, please contact your administrator`,
          });
        } else {
          infoDialogPopover.openPopover();
          setIsInfoDialogForcedOpen(true);
        }
      }
    } else {
      dispatch(setTeamsIntegrationStatus(TeamsConnectionStatus.Error));
    }
  };

  const disconnectTeams = async () => {
    await integrations.disconnectTeams();
    dispatch(setTeamsIntegrationStatus(TeamsConnectionStatus.NotInitialized));
  };

  const getTeamConnectButton = (text: string) => {
    return (
      <RestrictedTooltip
        to={['create:data-integrations:teams:oauth']}
        notAllowedTitle={'To adjust integration settings, please contact your admin.'}
      >
        <Button
          variant={variant}
          disabled={!isConnectTeamsAllowed || disabled}
          onClick={() => connectToTeams()}
          size={size}
          ref={managedPackagePopover.anchorRef}
        >
          {text}
        </Button>
      </RestrictedTooltip>
    );
  };

  return (
    <>
      <IntegrationsButtonPopover
        open={managedPackagePopover.isPopoverOpen}
        anchorEl={managedPackagePopover.anchorElement}
        onClose={() => managedPackagePopover.closePopover()}
      >
        <InstallManagedPackagePopoverContent action={ActionsRequireMp.TEAMS} />
      </IntegrationsButtonPopover>

      <Stack direction="row" gap="4px">
        {teamsStatus === TeamsConnectionStatus.Connected && (
          <RestrictedTooltip
            to={['delete:data-integrations:teams']}
            notAllowedTitle={'To adjust integration settings, please contact your admin.'}
          >
            <Button
              variant="outlined"
              onClick={disconnectTeams}
              disabled={!isDisconnectTeamsAllowed}
              size={size}
            >
              Disconnect
            </Button>
          </RestrictedTooltip>
        )}

        {teamsStatus === TeamsConnectionStatus.NotInitialized && getTeamConnectButton('Connect')}

        {teamsStatus === TeamsConnectionStatus.Initialization && (
          <Button variant={variant} size={size} loading={true}></Button>
        )}

        {teamsStatus === TeamsConnectionStatus.AwaitingApproval && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <IconButton onClick={infoDialogPopover.openPopover} size="tiny" variant="flat">
              <Info />
            </IconButton>
            {getTeamConnectButton('Complete')}
          </Box>
        )}

        {(teamsStatus === TeamsConnectionStatus.Error ||
          teamsStatus === TeamsConnectionStatus.InvalidCredentials) &&
          getTeamConnectButton('Reconnect')}
      </Stack>

      <InfoDialog
        handleClose={infoDialogPopover.closePopover}
        open={infoDialogPopover.isPopoverOpen}
        titleJsx={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Icon component={MicrosoftTeamsLogo} width={20} height={20} />
            <Typography variant="body-medium" color={colors.grey[700]}>
              Microsoft office Teams
            </Typography>
          </Box>
        }
        PaperPropsSx={{ width: '470px' }}
        dialogActions={
          <Button onClick={infoDialogPopover.closePopover} size="small">
            Got it
          </Button>
        }
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 3 }}>
          <Typography variant="h4">Pending Admin approval</Typography>
          <Typography variant="body" color={colors.grey[700]}>
            Teams require admin approval to set up this integration. Once approved, click "Complete"
            to finalize the process
          </Typography>
        </Box>
      </InfoDialog>
    </>
  );
};
