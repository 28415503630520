import { useSelector } from 'react-redux';
import { selectAgents } from '../../../../aiAgentCenter/aiAgentsReducer';
import AiAgentsButtonAndMenu from '../../../../aiAgentCenter/AiAgentsButtonAndMenu';
import { Sparkle } from '@sweep-io/sweep-design/dist/icons';
import { IconButton } from '@sweep-io/sweep-design';
import { selectDefaultCreationCrmOrgId } from '../../../../../reducers/userInfoReducer';
import { useMemo } from 'react';
import { AiAgent } from '../../../../aiAgentCenter/aiAgentsConsts';

const StageAiAgentsButton = ({ stageId, funnelId }: { stageId: string; funnelId: string }) => {
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  const aiAgents = useSelector(selectAgents(crmOrgId ?? undefined));

  const relevantAiAgents = useMemo(() => {
    return getAgentsPerStage(aiAgents ?? [], funnelId, stageId);
  }, [aiAgents, funnelId, stageId]);

  const hasAgents = relevantAiAgents.length > 0;

  if (!hasAgents) return null;

  return (
    <AiAgentsButtonAndMenu
      aiAgents={relevantAiAgents}
      button={
        <IconButton size="small">
          <Sparkle />
        </IconButton>
      }
    />
  );
};

const getAgentsPerStage = (aiAgents: AiAgent[], funnelId: string, stageId: string) => {
  return aiAgents?.filter((agent) => {
    if (Array.isArray(agent.contextComponents)) {
      return agent.contextComponents?.some(
        (component) => component.funnelId === funnelId && component.stagesIds?.includes(stageId),
      );
    } else {
      return false;
    }
  });
};

export default StageAiAgentsButton;
