import { ConfigurationType } from '../types';
import { possibleTypes } from './configurationChatConsts';
import { AiChatInput } from '../../../common/ai-chat/AiChatInput';
import { useState } from 'react';
import { useLimitMessageCount } from '../../../common/ai-chat/useLimitMessageCount';
import { Button, Typography } from '@sweep-io/sweep-design';
import { Stack } from '@mui/material';
import { scheduleCall } from '../../../dashboard/customerSupportHelper';
import { useSelector } from 'react-redux';
import { selectUserInfoData } from '../../../../reducers/userInfoReducer';
import useSendBiEvent from '../../../../hooks/useSendBiEvent';
import { HOMEPAGE_EVENTS } from '../../../../services/events';
import pluralize from 'pluralize';

const symbol = '$item';
const prompts: { [key: string]: string } = {
  ai1: 'Break down in steps how this $item works',
  ai2: 'List all fields that are updated via this $item',
};

const excludeAI2FromTypes = [ConfigurationType.validationRules];

interface ConfigurationChatInputWrapperProps {
  onConfirm: (message: string) => void;
  isLoading: boolean;
  configurationKey: ConfigurationType;
  itemType?: string;
  disableInput: boolean;
  messagesCount: number;
}

export const ConfigurationChatInputWrapper = ({
  configurationKey,
  onConfirm,
  isLoading,
  itemType,
  disableInput,
  messagesCount,
}: ConfigurationChatInputWrapperProps) => {
  const { keepPromptsOpen, messagesLeftCount, isFreeUser } = useLimitMessageCount();
  const [value, setValue] = useState('');

  const header =
    messagesLeftCount === 0 && isFreeUser ? (
      <NoQuestionsLeftHeader />
    ) : (
      `You have ${messagesLeftCount} AI ${pluralize('question', messagesLeftCount)} remaining`
    );

  const isField =
    configurationKey === ConfigurationType.fields || configurationKey === ConfigurationType.rollups;
  const isCpq = configurationKey === ConfigurationType.cpqData;

  if (!possibleTypes.includes(configurationKey)) {
    return <></>;
  }

  const noMessagesSent = messagesCount === 0;
  const isNoPromptsType = isField || isCpq;

  const predefinedPrompts = isNoPromptsType
    ? {}
    : Object.keys(prompts)
        .filter(
          (key) =>
            key !== 'ai2' || (key === 'ai2' && !excludeAI2FromTypes.includes(configurationKey)),
        )
        .reduce(
          (obj, key) => {
            obj[key] = prompts[key].replace(symbol, itemType?.toLowerCase() ?? 'element');
            return obj;
          },
          {} as { [promptKey: string]: string },
        );

  const renderNoPromptsButPromptsBox = keepPromptsOpen && messagesLeftCount === 0;
  const promptsToRender = renderNoPromptsButPromptsBox ? {} : predefinedPrompts;

  return (
    <AiChatInput
      value={value}
      setValue={setValue}
      textFieldVariant="primary"
      predefinedPrompts={
        noMessagesSent && !isNoPromptsType ? promptsToRender : keepPromptsOpen ? {} : undefined
      }
      onConfirm={onConfirm}
      isLoading={isLoading}
      isCollapsiblePrompts={true}
      disabled={disableInput || (keepPromptsOpen && messagesLeftCount === 0)}
      headerText={keepPromptsOpen ? header : undefined}
    />
  );
};

const NoQuestionsLeftHeader = () => {
  const sendBiEvent = useSendBiEvent();
  const user = useSelector(selectUserInfoData);

  return (
    <Stack
      direction="row"
      gap={0.5}
      alignItems="center"
      sx={{
        '& button': {
          fontSize: '16px',
        },
      }}
    >
      <Typography variant="h5">You have run out of questions.</Typography>
      <Button
        variant="link"
        onClick={() => {
          sendBiEvent({ name: HOMEPAGE_EVENTS.upgradeFreeQuestions });
          scheduleCall(user);
        }}
      >
        Talk to us
      </Button>
    </Stack>
  );
};
