import { IconButton } from '@sweep-io/sweep-design';
import { Close as CloseIcon } from '@sweep-io/sweep-design/dist/icons';

import {
  CreateEditDialogHeaderBase,
  CreateEditDialogHeaderBaseProps,
} from './CreateEditDialogHeaderBase';
import { Box } from '@mui/material';

export interface AutomationsCreateEditDialogHeaderProps extends CreateEditDialogHeaderBaseProps {
  onCancelClick?: () => any;
  showCancelButton?: boolean;
}

export const AutomationsCreateEditDialogHeader = ({
  showCancelButton,
  onCancelClick,
  rightSideContent,
  ...restProps
}: AutomationsCreateEditDialogHeaderProps) => {
  const _rightSideContent = (
    <>
      {rightSideContent}
      {showCancelButton && (
        <Box sx={{ ml: -1 }}>
          <IconButton variant="flat" onClick={onCancelClick} data-dd-action-name="close">
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </>
  );
  return <CreateEditDialogHeaderBase {...restProps} rightSideContent={_rightSideContent} />;
};
