import { Stack } from '@mui/material';
import { colors, Tooltip, Typography } from '@sweep-io/sweep-design';
import Box from '@mui/material/Box/Box';
import { Enter } from '@sweep-io/sweep-design/dist/icons';
import { SearchInput, SearchInputProps } from './SearchInput';

interface SearchInputWithTooltipProps extends SearchInputProps {
  displayTooltip: boolean;
}

const SearchInputWithTooltip = ({
  displayTooltip,
  ...searchProps
}: SearchInputWithTooltipProps) => {
  return (
    <Tooltip
      open={displayTooltip}
      title={
        <Stack alignItems="center" direction="row" gap={0.75}>
          <Typography variant="caption">Press Enter for results</Typography>
          <Box
            sx={{
              border: '0.5px solid white',
              borderRadius: '2px',
              height: '16px',
              width: '16px',
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
            }}
          >
            <Enter variant="tiny" color={colors.white} />
          </Box>
        </Stack>
      }
      placement="bottom-start"
    >
      <Box width="100%">
        <SearchInput {...searchProps} />
      </Box>
    </Tooltip>
  );
};

export default SearchInputWithTooltip;
