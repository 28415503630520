import { ChatCompletionRequestMessageRole } from '@server/ai';
import { Fragment, ReactNode, useCallback, useEffect } from 'react';
import { AiChatAssistantMessageBox } from './message-atoms/AiChatAssistantMessageBox';
import { AiChatUserMessageBox } from './message-atoms/AiChatUserMessageBox';
import { AiChatMessage } from './aiChatTypes';
import AiChatPaywallBlockMessage from './AiChatPaywallBlockMessage';
import { Stack } from '@mui/material';
import AiChatNoOrgConnectedMessage from './AiChatNoOrgConnectedMessage';
import AiChatInitialFetchMessage from './AiChatInitialFetchMessage';
import { highlightMatchInMarkdown } from '../../../lib/highlightMatchInMarkdown';

interface AiChatMessagesProps {
  containerElement?: HTMLElement;
  messages: AiChatMessage[];
  searchTermToHighlight?: string;
  assistantAvatar?: ReactNode;
  renderInitialFetchMessage: boolean;
  renderNoOrgConnectedMessage: boolean;
  renderChatPaywallBlockMessage: boolean;
  renderLoader: boolean;
  errorMsg?: string;
  activeChatId?: string;
}

export const AiChatMessages = ({
  containerElement,
  messages,
  searchTermToHighlight,
  assistantAvatar,
  renderInitialFetchMessage,
  renderNoOrgConnectedMessage,
  renderChatPaywallBlockMessage,
  renderLoader,
  errorMsg,
  activeChatId,
}: AiChatMessagesProps) => {
  const scrollToBottom = useCallback(
    (withScrollEffect?: boolean) => {
      const options: ScrollToOptions = { top: containerElement?.scrollHeight };
      if (withScrollEffect) {
        options.behavior = 'smooth';
      }
      containerElement?.scrollTo(options);
    },
    [containerElement],
  );

  const scrollToPrevMessage = useCallback(() => {
    if (!containerElement) return;
    const messageElements = containerElement.querySelectorAll('[data-message-type]');
    const targetElement = messageElements[messageElements.length - 2];

    if (targetElement) {
      const containerRect = containerElement.getBoundingClientRect();
      const targetRect = targetElement.getBoundingClientRect();
      const PADDING_FROM_TOP = 16;
      const scrollOffset =
        targetRect.top - containerRect.top + containerElement.scrollTop - PADDING_FROM_TOP;

      containerElement.scrollTo({
        top: scrollOffset > 0 ? scrollOffset : 0.5, //this 0.5 is needed for scroll listener in "useContainerWithScrollArrow" to be called
        behavior: 'smooth',
      });
    }
  }, [containerElement]);

  const smoothlyScrollToBottom = useCallback(() => {
    scrollToBottom(true);
  }, [scrollToBottom]);

  //When an existing chat is clicked, it needs to be scrolled to the bottom (no need to show the animation)
  useEffect(() => {
    if (activeChatId) {
      scrollToBottom();
    }
  }, [scrollToBottom, activeChatId]);

  return (
    <Stack gap={2}>
      {messages.map((message, idx) => {
        const content = searchTermToHighlight
          ? highlightMatchInMarkdown(message.content, searchTermToHighlight)
          : message.content;
        if (message.hidden) return null;
        switch (message.role) {
          case ChatCompletionRequestMessageRole.USER:
            return (
              <AiChatUserMessageBox
                key={`user_message_${idx}`}
                content={content}
                onMount={smoothlyScrollToBottom}
              />
            );
          case ChatCompletionRequestMessageRole.ASSISTANT:
            return (
              <AiChatAssistantMessageBox
                key={`assistant_message_${idx}`}
                content={content}
                onMount={() => scrollToPrevMessage()} // Scroll to the previous message (the user question)
                data-message-type="assistant"
                errorState={!!message.isError}
                avatar={assistantAvatar}
              />
            );

          default:
            return <Fragment key={`no_message_${idx}`} />;
        }
      })}

      {errorMsg && (
        <AiChatAssistantMessageBox
          key="error-assistant"
          content={errorMsg}
          onMount={smoothlyScrollToBottom}
          errorState
          avatar={assistantAvatar}
        />
      )}

      {renderLoader && (
        <AiChatAssistantMessageBox
          isLoading
          onMount={smoothlyScrollToBottom}
          content=""
          avatar={assistantAvatar}
        />
      )}

      {renderChatPaywallBlockMessage && (
        <AiChatAssistantMessageBox
          content={<AiChatPaywallBlockMessage />}
          onMount={smoothlyScrollToBottom}
          avatar={assistantAvatar}
        />
      )}

      {renderNoOrgConnectedMessage && (
        <AiChatAssistantMessageBox
          content={<AiChatNoOrgConnectedMessage />}
          onMount={smoothlyScrollToBottom}
          avatar={assistantAvatar}
        />
      )}
      {renderInitialFetchMessage && (
        <AiChatAssistantMessageBox
          content={<AiChatInitialFetchMessage />}
          onMount={smoothlyScrollToBottom}
          avatar={assistantAvatar}
        />
      )}
    </Stack>
  );
};
