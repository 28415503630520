import { useSelector } from 'react-redux';
import { selectDefaultCreationCrmOrgId } from '../reducers/userInfoReducer';
import { useEffect } from 'react';
import useUserInfo from './useUserInfo';
import { useTags } from '../components/documentation/dependencies/annotations/tags/useTags';
import useAiAgentChats from '../components/aiAgentCenter/useAiAgentChats';
import useAiAgents from '../components/aiAgentCenter/useAiAgents';

/**
 * Add an effect to any call that should be triggered whenever crmOrgId changes
 */

export const useFetchUponCrmOrgChange = () => {
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);

  const { fetchChats } = useAiAgentChats();
  const { fetchAiAgents } = useAiAgents();
  const { getUserPermissions } = useUserInfo();
  const { fetchTags } = useTags(crmOrgId ?? '');

  useEffect(() => {
    if (crmOrgId) {
      getUserPermissions(crmOrgId);
    }
  }, [crmOrgId, getUserPermissions]);

  useEffect(() => {
    if (crmOrgId) {
      fetchChats();
      fetchAiAgents();
    }
  }, [crmOrgId, fetchChats, fetchAiAgents]);

  useEffect(() => {
    if (crmOrgId) {
      fetchTags();
    }
  }, [crmOrgId, fetchTags]);
};
