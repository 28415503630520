import { Box, FormControl, Menu } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  allOperators,
  ApiSweepFieldOperator,
  apiSweepOperatorWithValueToSweepFieldOperator,
  SweepFieldOperator,
  SweepFieldOperatorsValues,
  sweepFieldOperatorToApiSweepOperatorWithValue,
  sweepFieldTypesOperatorsMapping,
} from './sweepFieldsTypesAndOperators';
import { SweepFieldTypes } from '../../types/enums/SweepFieldTypes';
import { FieldContext } from '../../types/enums/FieldContext';
import { ActionsMenuItem } from '../common/actions-menu/ActionsMenuItem';
import { ActionsMenuAction } from '../common/actions-menu/ActionsMenu';
import { colors, Typography } from '@sweep-io/sweep-design';
import { ChevronDown, ChevronUp } from '@sweep-io/sweep-design/dist/icons';
import {
  getExpendedOperatorDatesApart,
  getExpendedOperatorDistance,
} from './SweepFieldsExpendedOperators/ExpendedOperator';
import { DividerLighter } from '../Automations/helper';

export interface ActionsMenuActionGrouped extends ActionsMenuAction {
  groupIndex: number;
}

export const RELATIVE_DATE_OPERATORS = [
  ApiSweepFieldOperator.IN_LAST_X,
  ApiSweepFieldOperator.IN_NEXT_X,
  ApiSweepFieldOperator.GREATER_THAN_X,
  ApiSweepFieldOperator.GREATER_THAN_X_AGO,
];

export const TEXTFIELD_FOR_PICKLIST_OPERATORS = [
  ApiSweepFieldOperator.CONTAINS,
  ApiSweepFieldOperator.DOES_NOT_CONTAIN,
  ApiSweepFieldOperator.ENDS_WITH,
  ApiSweepFieldOperator.STARTS_WITH,
];

interface SweepFieldsOperatorSelectorProps {
  fieldType?: SweepFieldTypes;
  operator?: ApiSweepFieldOperator;
  value?: SweepFieldOperatorsValues;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (operator: ApiSweepFieldOperator, value?: SweepFieldOperatorsValues) => any;
  showAny?: boolean;
  removeBorders?: boolean;
  filterOperatorBy?: (
    operatorList: OperatorWithIndex[],
    fieldType?: SweepFieldTypes,
  ) => OperatorWithIndex[];
  fieldContext?: FieldContext;
  operatorValue?: OperatorOffset;
  onOperatorValueChange?: (operatorValue: OperatorOffset) => any;
}

const SweepFieldsOperatorSelector = ({
  fieldType,
  operator,
  disabled,
  onChange,
  value,
  showAny,
  removeBorders = true,
  placeholder = 'Operator',
  filterOperatorBy,
  fieldContext,
  operatorValue,
  onOperatorValueChange,
}: SweepFieldsOperatorSelectorProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!disabled) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [sweepFieldOperator, setSweepFieldOperator] = useState<SweepFieldOperator | undefined>(
    operator ? apiSweepOperatorWithValueToSweepFieldOperator(operator, value) : undefined,
  );

  useEffect(() => {
    setSweepFieldOperator(
      operator ? apiSweepOperatorWithValueToSweepFieldOperator(operator, value) : undefined,
    );
  }, [operator, value]);

  const onSelectChange = useCallback(
    (action: ActionsMenuAction) => {
      if (onChange) {
        const sweepFieldValue = action.value;
        if (showAny && 'Any' === (sweepFieldValue as any)) {
          return onChange(ApiSweepFieldOperator.ANY, 'true');
        }
        const { apiOperator, value } = sweepFieldOperatorToApiSweepOperatorWithValue(
          sweepFieldValue as SweepFieldOperator,
        );

        onChange(apiOperator, value);
      }
    },
    [onChange, showAny],
  );

  const alwaysFilterBy = (operatorList: OperatorWithIndex[]) => {
    let filtered = operatorList;
    const listOfOperatorsToRemove = [
      SweepFieldOperator.IS_CHANGED_FALSE,
      SweepFieldOperator.IS_CHANGED_TRUE,
    ];
    const removeHasChangedForTypes = [
      SweepFieldTypes.Summary,
      SweepFieldTypes.FormulaCheckbox,
      SweepFieldTypes.FormulaCurrency,
      SweepFieldTypes.FormulaDate,
      SweepFieldTypes.FormulaDateTime,
      SweepFieldTypes.FormulaNumber,
      SweepFieldTypes.FormulaPercent,
      SweepFieldTypes.FormulaText,
      SweepFieldTypes.FormulaTime,
    ];
    if (fieldType && removeHasChangedForTypes.includes(fieldType)) {
      filtered = operatorList.filter(({ operator }) => {
        return !listOfOperatorsToRemove.includes(operator);
      });
    }
    return filterOperatorBy ? filterOperatorBy(filtered, fieldType) : filtered;
  };

  let operatorsToShow = (fieldType && sweepFieldTypesOperatorsMapping[fieldType]) || allOperators;
  if (fieldContext === FieldContext.PRIOR_VALUE) {
    operatorsToShow = operatorsToShow.filter((operator) => {
      return ![
        SweepFieldOperator.IS_CHANGED_FALSE,
        SweepFieldOperator.IS_CHANGED_TRUE,
        SweepFieldOperator.HAS_INCREASED,
        SweepFieldOperator.HAS_DECREASED,
      ].includes(operator.operator);
    });
  }

  if (!showAny) {
    operatorsToShow = operatorsToShow.filter((operator) => {
      return operator.operator !== SweepFieldOperator.ANY;
    });
  }

  operatorsToShow = alwaysFilterBy(operatorsToShow);

  const expendedOperatorsToShow: ActionsMenuActionGrouped[] = operatorsToShow?.map((el) => {
    if (el.operator === SweepFieldOperator.DATES_APART) {
      return getExpendedOperatorDatesApart({
        element: el,
        selectedOperator: sweepFieldOperator,
        disabled,
        operatorValue,
        onOperatorValueChange,
      });
    }
    if (el.operator === SweepFieldOperator.DISTANCE) {
      return getExpendedOperatorDistance({
        element: el,
        selectedOperator: sweepFieldOperator,
        disabled,
        operatorValue,
        onOperatorValueChange,
      });
    }
    return {
      label: el.label,
      value: el.operator,
      isSelected: el.operator === sweepFieldOperator,
      groupIndex: el.groupIndex,
    };
  });

  const valueToShow = expendedOperatorsToShow.find((el) => el.value === sweepFieldOperator)?.label;

  const operatorsOrderedByIndex = expendedOperatorsToShow.sort(
    (a, b) => a.groupIndex - b.groupIndex,
  );

  const getOperatorsByGroups = useMemo(() => {
    let currentGroupIndex = operatorsOrderedByIndex[0]?.groupIndex ?? 0;
    const elemList = operatorsOrderedByIndex.map((option) => {
      const item = (
        <ActionsMenuItem
          action={option}
          handleClose={handleClose}
          onClick={onSelectChange}
          key={JSON.stringify(option.value)}
        />
      );
      if (option.groupIndex > currentGroupIndex) {
        currentGroupIndex = option.groupIndex;
        return [<DividerLighter sx={{ mt: 1, mb: 1 }} key={`divider${currentGroupIndex}`} />, item];
      } else {
        return item;
      }
    });

    return elemList;
  }, [onSelectChange, operatorsOrderedByIndex]);

  return (
    <FormControl fullWidth>
      <Box
        onClick={handleClick}
        sx={{
          display: 'flex',
          p: '9px 12px',
          cursor: 'pointer',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: '4px',
          border: removeBorders ? 'none' : `1px solid ${colors.grey[300]}`,
          gap: 1,
          height: '40px',
          backgroundColor: disabled ? colors.grey[100] : colors.white,
        }}
      >
        <Typography
          variant={'body'}
          color={!valueToShow || disabled ? colors.grey[500] : colors.grey[900]}
        >
          {valueToShow ?? placeholder}
        </Typography>
        {open ? <ChevronUp /> : <ChevronDown />}
      </Box>
      <Menu
        disableAutoFocusItem
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              width: '311px',
            },
          },
        }}
      >
        {getOperatorsByGroups}
      </Menu>
    </FormControl>
  );
};

export { SweepFieldsOperatorSelector };
