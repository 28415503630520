import { AutomationType } from '../../../../../types/enums/AutomationType';
import { AutomationTypes } from '../../../../../types/enums/AutomationTypes';
import { DeployStatusForTable } from '../../../../../types/enums/DeployStatusForTable';

export const newMarketingAttributionModel = {
  automationId: '',
  name: 'New Attribution model',
  objectName: '',
  type: AutomationType.MarketingAttributionModel,
  automationDetails: {
    automationParams: {},
    actions: [
      {
        _id: '',
        actionType: 'REVENUE_ATTRIBUTION',
        actionParams: {
          modelType: '' as any,
          campaignFilter: {
            criteria: [],
            criteriaLogic: '',
            isCriteria: false,
          },
        },
      },
    ] as MarketingAttributionModelAction[],
    triggerType: AutomationTypes.OnUpdate,
  },
  versionId: '',
  crmOrgId: '',
  accountId: '',
  status: DeployStatusForTable.Draft,
  isActive: true,
  priority: 0,
  updatedById: '',
  updatedAt: '',
  notifyAdmin: false,
};

export const columns = [
  {
    headerName: 'Name',
    field: 'name',
    width: '50%',
  },
  {
    field: 'weight',
    headerName: 'Weight distribution',
    width: '50%',
  },
];

export const translateWeightName = (name: string) => {
  switch (name) {
    case 'firstTouch':
      return 'First Touch';
    case 'lastTouch':
      return 'Last Touch';
    case 'rest':
      return 'Rest';
    case 'campaignA':
      return 'Campaign A';
    case 'campaignB':
      return 'Campaign B';
    case 'campaignC':
      return 'Campaign C';
    default:
      return name;
  }
};

export const getReadOnlyWeights = (modelType: string) => {
  switch (modelType) {
    case 'FIRST_TOUCH':
      return { firstTouch: 1, lastTouch: 0, rest: 0 };
    case 'LAST_TOUCH':
      return { firstTouch: 0, lastTouch: 1, rest: 0 };
    case 'EVEN_DISTRIBUTION':
      return { firstTouch: 0.2, campaignA: 0.2, campaignB: 0.2, campaignC: 0.2, lastTouch: 0.2 };
  }
};
