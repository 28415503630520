import { Box, Menu, Stack } from '@mui/material';
import { IconButton, Typography } from '@sweep-io/sweep-design';
import { ReactNode, useEffect, useState } from 'react';
import { MoreActionsVertical, Pin } from '@sweep-io/sweep-design/dist/icons';
import usePopover from '../../hooks/usePopover';
import ChatMenuActions from './ChatMenuActions';
import RenameChatDialog from './RenameChatDialog';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { ClampedText } from '../common/ClampedText';
import Typewriter from '../common/Typewriter';
import { ChatShort } from '../common/ai-chat/aiChatTypes';
import { AI_AGENTS_INFO } from './aiAgentsConsts';
import useAiAgentChats from './useAiAgentChats';
import { AgentType } from '@server/ai';
import AiAgentListItemLayout from './AiAgentListItemLayout';

const AiAgentChatListItem = ({
  isActive,
  title,
  subtitle,
  onClick,
  disableActions,
  chat,
}: {
  chat: ChatShort;
  isActive: boolean;
  title?: ReactNode;
  subtitle?: ReactNode;
  onClick: () => void;
  disableActions?: boolean;
}) => {
  const { renameChat, deleteChat, togglePin } = useAiAgentChats();
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const { closePopover, openPopover, isPopoverOpen, anchorRef, anchorElement } = usePopover();

  const { id, name, isLoading, isFavorite, isAnimateName } = chat;

  const menuIcon = (
    <IconButton ref={anchorRef} onClick={openPopover} variant="flat" size="tiny">
      <MoreActionsVertical />
    </IconButton>
  );

  const [isShowTypewriter, setIsShowTypewriter] = useState(false);
  const textToRenderWithTypewriter = name;
  const textToRender = title ?? name;

  useEffect(() => {
    setIsShowTypewriter(!!isAnimateName);
  }, [name, isAnimateName]);

  const showMenuIcon = !disableActions && (isHovered || isPopoverOpen);
  const iconToRender = showMenuIcon ? menuIcon : isFavorite ? <Pin /> : null;

  const onDelete = () => deleteChat(id);
  const onToggle = () => togglePin(id);
  const onRename = (name: string) => renameChat({ chatId: id, name });

  const { chatIcon } = AI_AGENTS_INFO[chat.agentType ?? AgentType.Documentation];

  return (
    <>
      <AiAgentListItemLayout isActive={isActive} onClick={onClick} setIsHovered={setIsHovered}>
        {isLoading ? (
          <CenteredCircularProgress circularProgressProps={{ size: '20px' }} />
        ) : (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Stack direction="row" gap={1} alignItems="center">
              <Box minWidth="18px" width="18px">
                {chatIcon}
              </Box>
              <Stack spacing={1}>
                {!isShowTypewriter && <ClampedText text={textToRender} typographyVariant="body" />}
                {isShowTypewriter && <Typewriter text={textToRenderWithTypewriter} delay={35} />}
                {subtitle && (
                  <Box
                    sx={{
                      overflow: 'hidden',
                      '& .MuiTypography-root': {
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        whiteSpace: 'break-spaces',
                        wordBreak: 'break-word',
                      },
                    }}
                  >
                    <Typography variant="caption">{subtitle}</Typography>
                  </Box>
                )}
              </Stack>
            </Stack>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                minHeight: '24px',
                flex: '0 0 24px',
              }}
            >
              {iconToRender}
            </Box>
          </Box>
        )}
      </AiAgentListItemLayout>
      <Menu
        anchorEl={anchorElement}
        open={isPopoverOpen}
        onClose={closePopover}
        slotProps={{
          paper: {
            sx: {
              width: '311px',
            },
          },
        }}
      >
        <ChatMenuActions
          closeMenu={closePopover}
          onRename={() => setIsRenameDialogOpen(true)}
          onDelete={onDelete}
          togglePin={onToggle}
          isPinned={isFavorite}
        />
      </Menu>

      <RenameChatDialog
        isOpen={isRenameDialogOpen}
        initialName={name}
        onChange={onRename}
        closeDialog={() => setIsRenameDialogOpen(false)}
      />
    </>
  );
};

export default AiAgentChatListItem;
