import { useCallback, useState } from 'react';
import { selectDefaultCreationCrmOrgId } from '../../../../../reducers/userInfoReducer';
import { useDispatch, useSelector } from 'react-redux';
import {
  addMarketingAttributionModel,
  deleteMarketingAttributionModel,
  requestRefreshGlobal,
  setMarketingAttributionModels,
  updateMarketingAttributionModel,
} from '../../../../../reducers/global/globalReducer';
import { DeployStatusForTable } from '../../../../../types/enums/DeployStatusForTable';
import { FailedAction } from '../../../../../types/enums/FailedAction';
import { telemetry } from '../../../../../telemetry';
import { SweepNotificationVariant } from '../../../../../reducers/notificationsReducer';
import { useSweepNotifications } from '../../../../notifications/useSweepNotifications';
import { clearSweepFieldsCache } from '../../../../../sweep-fields/useCachedSweepFields';
import { getEditOptimisticStatus } from '../../../helper';
import { getAttributionModelTemplates } from './getAttributionModelsTemplates';
import { useStageMetadataFacade } from '../../../../../apis/facades/useStageMetadataFacade';
import { StageMetadataType } from '../../../../../types/enums/StageMetadataTypes';
import { useAutomationsApiFacade } from '../../../../../apis/facades/useAutomationsApiFacade';
import { AutomationType } from '../../../../../types/enums/AutomationType';

export const useMarketingAttributionModels = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  const {
    update_automation,
    create_automation,
    delete_automation,
    deploy_automation,
    post_createMarketingAttributionTemplates: post_createMarketingAttributionModelTemplates,
  } = useAutomationsApiFacade();
  const { addNotification } = useSweepNotifications();
  const { get_allStagesStageMetadata } = useStageMetadataFacade();

  const getClosedWonStages = useCallback(async () => {
    const opportunityStageMetadata: StageMetadata[] = await get_allStagesStageMetadata(
      ['Opportunity.StageName'],
      crmOrgId ?? '',
    );
    const closedWonStages = opportunityStageMetadata?.filter(
      (stage) => stage.type === StageMetadataType.CLOSED_WON,
    );
    return closedWonStages?.map((stage) => ({
      _stageId: stage.stageName,
      stageName: stage.stageName,
    }));
  }, [crmOrgId, get_allStagesStageMetadata]);

  const addMarketingAttributionModelsTemplates = useCallback(async () => {
    setIsLoading(true);
    const stages = await getClosedWonStages();
    const _templates = getAttributionModelTemplates({
      stages,
    });
    const templates = await post_createMarketingAttributionModelTemplates(_templates);
    dispatch(
      setMarketingAttributionModels({
        crmOrgId: crmOrgId ?? '',
        marketingAttribution: templates,
      }),
    );
    setIsLoading(false);
  }, [dispatch, crmOrgId, post_createMarketingAttributionModelTemplates, getClosedWonStages]);

  const handleError = useCallback(
    (e: any, failedAction: FailedAction = FailedAction.deploy): ErrorInApi => {
      addNotification({
        message: `Failed to ${failedAction} due to technical issues`,
        keepOpen: true,
        details: e?.response?.data?.message,
        variant: SweepNotificationVariant.Error,
      });
      telemetry.captureError(e);
      return { isError: true };
    },
    [addNotification],
  );

  const deployOrRefresh = useCallback(
    ({
      deployToOrgIds,
      deployAutomationDto,
    }: {
      deployToOrgIds: string[];
      deployAutomationDto: AutomationStructureNew;
    }) => {
      if (deployToOrgIds.length > 0) {
        deploy_automation(deployAutomationDto.automationId, deployAutomationDto.versionId, {
          ...deployAutomationDto,
          deployToOrgIds,
        });
        //when deploy is finished, a web socket will be sent in order to trigger "requestRefresh"
      } else if (crmOrgId) {
        dispatch(requestRefreshGlobal({ crmOrgId }));
      }
    },
    [crmOrgId, deploy_automation, dispatch],
  );

  const onAddModel = useCallback(
    async ({
      deployToOrgIds,
      createAutomationDto,
    }: {
      deployToOrgIds: string[];
      createAutomationDto: AutomationStructureNew;
    }) => {
      try {
        const toSend = {
          ...createAutomationDto,
          deployToOrgIds,
        };
        const response = await create_automation(toSend);
        const shouldDeploy = deployToOrgIds && deployToOrgIds.length > 0;
        if (crmOrgId) {
          dispatch(
            addMarketingAttributionModel({
              crmOrgId,
              automation: response,
              optimisticStatus: shouldDeploy
                ? DeployStatusForTable.Deployed
                : DeployStatusForTable.Draft,
            }),
          );
        }
        deployOrRefresh({
          deployToOrgIds: deployToOrgIds ?? [],
          deployAutomationDto: response,
        });
        return response;
      } catch (e) {
        return handleError(e, FailedAction.create);
      }
    },
    [crmOrgId, create_automation, handleError, deployOrRefresh, dispatch],
  );

  const onEditModel = useCallback(
    async ({
      deployToOrgIds,
      updateAutomationDto,
      versionId,
    }: {
      updateAutomationDto: AutomationStructureNew;
      deployToOrgIds: string[];
      versionId: string;
    }) => {
      try {
        const toSend = {
          ...updateAutomationDto,
          deployToOrgIds,
        };
        const updatedAutomation = await update_automation(toSend);
        const shouldDeploy = deployToOrgIds.length > 0;
        if (crmOrgId) {
          dispatch(
            updateMarketingAttributionModel({
              crmOrgId,
              modelToUpdate: updatedAutomation,
              versionIdToUpdate: versionId,
              optimisticStatus: shouldDeploy
                ? DeployStatusForTable.Deployed
                : getEditOptimisticStatus(updatedAutomation.status),
            }),
          );
        }
        deployOrRefresh({
          deployToOrgIds,
          deployAutomationDto: updatedAutomation,
        });
        return updatedAutomation;
      } catch (e) {
        return handleError(e, FailedAction.save);
      }
    },
    [crmOrgId, update_automation, handleError, deployOrRefresh, dispatch],
  );

  const onDeployModel = useCallback(
    async ({
      automationId,
      versionId,
      deployToOrgIds,
      deployAutomationDto,
    }: {
      deployAutomationDto: AutomationStructureNew;
      automationId: string;
      versionId: string;
      deployToOrgIds: string[];
    }) => {
      try {
        const toSend = {
          ...deployAutomationDto,
          deployToOrgIds,
        };
        deploy_automation(automationId, versionId, toSend);
        clearSweepFieldsCache(10000);
        if (crmOrgId) {
          dispatch(
            updateMarketingAttributionModel({
              crmOrgId,
              modelToUpdate: deployAutomationDto,
              versionIdToUpdate: versionId,
              optimisticStatus: DeployStatusForTable.Deployed,
            }),
          );
        }
        //when deploy is finished, a web socket will be sent in order to trigger "requestRefresh"
      } catch (e) {
        return handleError(e, FailedAction.deploy);
      }
    },
    [crmOrgId, deploy_automation, dispatch, handleError],
  );

  const onDuplicateModel = useCallback(
    async (marketingAttribution: AutomationStructureNew) => {
      return await onAddModel({
        createAutomationDto: { ...marketingAttribution, automationId: '' },
        deployToOrgIds: [],
      });
    },
    [onAddModel],
  );

  const onDeleteModel = useCallback(
    async ({
      automationId,
      automationType,
    }: {
      automationId: string;
      automationType: AutomationType;
    }) => {
      try {
        await delete_automation(automationId, automationType);
        if (crmOrgId) {
          dispatch(
            deleteMarketingAttributionModel({
              crmOrgId: crmOrgId,
              automationIdToDelete: automationId,
            }),
          );
        }
      } catch (e) {
        return handleError(e, FailedAction.delete);
      }
    },
    [dispatch, delete_automation, handleError, crmOrgId],
  );

  return {
    isLoading,
    addMarketingAttributionModelsTemplates,
    onEditModel,
    onDeployModel,
    onDuplicateModel,
    onDeleteModel,
    onAddModel,
    getClosedWonStages,
  };
};
