import { useRef, useState } from 'react';
import { useReactFlow } from '@xyflow/react';
import { useRunOnce } from '../../../../common/useRunOnce';

import { getPositionRelativeToFunnel } from '../../../../comments/helper';

const DEFAULT_OFFSET_LEFT = 400;
const DEFAULT_OFFSET_TOP = 200;
const DEFAULT_PANEL_WIDTH = 820;
const DEFAULT_PANEL_HEIGHT = 630;
const DEFAULT_ANIMATION_DURATION = 800;

// This component is used to allocate space for the panel that opens.
// If the panel is opened and the anchorEl is outside of the viewport,
// it will positions the viewport so that the panel is visible.
export const MaybeAllocateSpaceForPanelInCanvas = ({
  children,
  anchorEl,
  offsetLeft = DEFAULT_OFFSET_LEFT,
  offsetTop = DEFAULT_OFFSET_TOP,
  panelWidth = DEFAULT_PANEL_WIDTH,
  panelHeight = DEFAULT_PANEL_HEIGHT,
}: {
  children: React.ReactNode;
  anchorEl: HTMLElement;
  offsetLeft?: number;
  offsetTop?: number;
  panelWidth?: number;
  panelHeight?: number;
}) => {
  const reactFlowInstance = useReactFlow();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const [render, setRender] = useState(false);

  useRunOnce(() => {
    const { x, y, zoom } = reactFlowInstance.getViewport();
    const anchorRect = anchorEl.getBoundingClientRect();
    const [windowWidth, windowHeight] = windowSize.current;

    if (anchorRect.x + panelWidth < windowWidth && anchorRect.y + panelHeight < windowHeight) {
      setRender(true);
      return;
    }

    const { positionX, positionY } = getPositionRelativeToFunnel(
      anchorRect.x + windowWidth / 2 - offsetLeft,
      anchorRect.y + windowHeight / 2 - offsetTop,
      x,
      y,
      zoom,
    );

    reactFlowInstance.setCenter(positionX, positionY, {
      duration: DEFAULT_ANIMATION_DURATION,
      zoom,
    });

    setTimeout(() => {
      setRender(true);
    }, DEFAULT_ANIMATION_DURATION + 50);
  });
  if (!render) {
    return null;
  }
  return children;
};
