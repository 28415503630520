import { ConfigurationType, OpenAIConfigurationKeys } from './parser';

export interface PotentialIssue {
  configurationName: string;
  objectName: string;
  configurationType: OpenAIConfigurationKeys; // TODO should be configurationKey
  apexClassParentId?: string;

  title: string;
  description: string;
  severity: PotentialIssueSeverity;
}

export const PotentialIssueSeverityValues = ['Low', 'Medium', 'High'] as const;
export type PotentialIssueSeverity = typeof PotentialIssueSeverityValues[number];

export enum ConfigurationWithCounters {
  customFields = 'customFields',
  apexClasses = ConfigurationType.apexClasses,
  apexTriggers = ConfigurationType.apexTriggers,
  recordTypes = ConfigurationType.recordTypes,
  flows = ConfigurationType.flows,
  validationRules = ConfigurationType.validationRules,
  approvalProcesses = ConfigurationType.approvalProcesses,
}

export type OrgConfigurationCounts = Record<ConfigurationWithCounters, number>;

export const AI_QUESTIONS_LIMIT = 5;

export type Homepage = {
  potentialIssues: PotentialIssue[];
  counters: OrgConfigurationCounts;
};
