import { VisibilityMap } from '../../../types/VisibilityTypes';
import { PillsMap } from '../../canvas-pills/canvasPillsReducer';
import {
  SweepCanvasGroup,
  SweepCanvasNode,
  SweepCanvasEdge,
  CanvasElementType,
} from '../../sweep-canvas/canvas-types';
import { HighlightEntities, TransientFunnel } from '../funnelMapCanvasTypes';
import * as pillsHelper from '../pillsHelper';
import { TRANSIENT_GROUP_ID } from '../useFunnelMapElements';
import { useMemo } from 'react';
import { Dictionary } from 'lodash';
import { usePlacingPluginContext } from '../../pages/funnel-map-flow/templates/placing-plugin/PlacingPluginContext';
import { getRecordTypeIdentifier } from '../../pages/configuration-canvas/utils/getRecordTypeIdentifier';
import { SweepCanvasReactFlowEdgeDataType } from '../../sweep-canvas/edges/SweepCanvasReactFlowEdgeDataType';
import { FunnelType } from '../../../types/enums/FunnelType';
import { StageType } from '../../../types/enums/StageType';

export const useCalculateSfFunnelsNodes = ({
  funnelMap,
  highlightEntities,
  renderZeroCountPills,
  pills,
  visibilityMap,
  transientFunnel,
  funnelStageMetadata,
  objectTypesByName,
  stagesWithSparkleIcon,
  mapForecastCategoryValueToLabel,
}: {
  funnelMap: FunnelMap;
  highlightEntities?: HighlightEntities[];
  renderZeroCountPills?: boolean;
  pills?: Partial<PillsMap>;
  visibilityMap: VisibilityMap;
  transientFunnel?: TransientFunnel;
  funnelStageMetadata?: StageMetadata[];
  mapForecastCategoryValueToLabel?: (value: string) => string;
  objectTypesByName: Dictionary<ObjectTypeName>;
  stagesWithSparkleIcon?: string[];
}) => {
  const { configuringPluginType } = usePlacingPluginContext();

  return useMemo(() => {
    const groups: SweepCanvasGroup<FunnelLeadingObject>[] = [];
    const nodes: SweepCanvasNode[] = [];
    const edges: SweepCanvasEdge[] = [];

    const { funnelsData = {}, funnels = {} } = funnelMap;
    const getLeadingObjectForFunnel = (funnelId: string) =>
      funnelsData[funnelId]?.funnelDetails.leadingObject;

    const sanitizedFunnelEntries = Object.entries(funnels).filter(
      ([funnelId]) => funnelsData[funnelId] && !funnels[funnelId].isHidden,
    );

    sanitizedFunnelEntries.forEach(([funnelId, { position }]) => {
      const funnelData: Funnel = funnelsData[funnelId];

      // Groups for the funnels
      const _plugins = funnelsData[funnelId]?.funnelDetails?.plugins || {};
      const plugins = Object.keys(_plugins) as PluginTypes[];

      if (configuringPluginType?.funnelId === funnelId) {
        plugins.push(configuringPluginType.pluginType);
      }

      const objectType = getLeadingObjectForFunnel(funnelId)?.objectName || '';
      const objectLabel = objectTypesByName[objectType]?.label ?? objectType;

      groups.push({
        nodeType: 'group',
        id: funnelId,
        name: funnelData.name,
        position: position || { column: 0, row: 0 },
        objectType,
        objectLabel,
        metadata: funnelData?.funnelDetails.leadingObject || {
          _leadingFieldId: '0',
          _leadingFieldLabels: [],
          fieldName: 'Status',
          objectName: 'Lead',
        },
        plugins,
        pills: pillsHelper.funnelPills({
          funnel: funnelData,
          renderZeroCountPills,
          highlightEntities,
          visibilityMap,
          pills,
          objectType,
          recordTypeId: getRecordTypeIdentifier(funnelData.recordType?.name, objectType),
        }),
      });

      const showStep = (stage: SweepStage) => {
        if (stage.stageType === StageType.LOST) {
          return false;
        }
        if (!visibilityMap.NURTURING && stage.stageType === StageType.NURTURING) {
          return false;
        }
        return true;
      };

      // Nodes for the funnel steps
      funnelData.funnelDetails.stages.filter(showStep).forEach((stage) => {
        const _pills = pillsHelper.stepPills({
          funnelId,
          stage,
          renderZeroCountPills,
          highlightEntities,
          visibilityMap,
          pills,
        });

        const funnelDetail = funnelsData[funnelId].funnelDetails;
        const leadingObject = funnelDetail.leadingObject;

        const metadata =
          funnelStageMetadata &&
          funnelStageMetadata.find(
            (metadata) =>
              metadata.stageName === stage.stageName &&
              metadata.leadingFieldId === leadingObject._leadingFieldId,
          );

        const node: SweepCanvasNode = {
          nodeType: 'node',
          id: stage._stageId,
          position: {
            column: stage._stageColumnIndex || 0,
            row: stage._branchIndex || 0,
          },
          name: stage.stageName,
          type:
            stage.stageType === 'nurturing'
              ? CanvasElementType.NURTURING_BUCKET
              : CanvasElementType.REGULAR,
          objectType: leadingObject.objectName as ObjectTypeValues,
          parentId: funnelId,
          probability: metadata?.probability,
          forecastCategoryLabel: mapForecastCategoryValueToLabel?.(metadata?.forecastCategory),

          pills: _pills,
          showSparkleIcon: stagesWithSparkleIcon?.includes(stage._stageId),
        };

        nodes.push(node);

        // Edges for the funnel steps
        stage.exitCriteria.forEach((exitCriteria) => {
          edges.push({
            id: exitCriteria._exitCriteriaId,
            source: stage._stageId,
            target: exitCriteria._nextStageId,
            data: {
              label: `${exitCriteria.criteria?.length}`,
              type: SweepCanvasReactFlowEdgeDataType.CIRCLE,
            },
          });
        });

        if (stage.funnelLinks?.length) {
          stage.funnelLinks.forEach((link) => {
            if (link.stageId) {
              edges.push({
                id: `${funnelId}-${stage._stageId}-${link.stageId}`,
                source: stage._stageId,
                target: link.stageId,
                data: { type: SweepCanvasReactFlowEdgeDataType.REMOVABLE, groupConnection: true },
              });
            } else {
              const target = `label-${link.funnelId}`;
              edges.push({
                id: `${funnelId}-${stage._stageId}-${target}`,
                source: stage._stageId,
                target: target,
                data: { type: SweepCanvasReactFlowEdgeDataType.REMOVABLE, groupConnection: true },
              });
            }
          });
        }
      });

      funnelData.funnelDetails.funnelLinks?.forEach((link) => {
        if (link.stageId) {
          edges.push({
            id: `${funnelId}-${link.stageId}`,
            source: `label-${funnelId}`,
            target: link.stageId,
            data: { type: SweepCanvasReactFlowEdgeDataType.REMOVABLE, groupConnection: true },
          });
        } else {
          const target = `label-${link.funnelId}`;
          edges.push({
            id: `${funnelId}-${target}`,
            source: `label-${funnelId}`,
            target: target,
            data: { type: SweepCanvasReactFlowEdgeDataType.REMOVABLE, groupConnection: true },
          });
        }
      });
    });

    // Transient funnel
    if (
      transientFunnel &&
      transientFunnel.type === FunnelType.SALESFORCE &&
      transientFunnel.importType === 'create'
    ) {
      const {
        name,
        data: {
          leadingObject: { _leadingFieldId, _leadingFieldLabels, fieldName, objectName },
        },
        position,
      } = transientFunnel;
      const objectLabel = objectTypesByName[objectName]?.label ?? objectName;

      groups.push({
        nodeType: 'group',
        id: TRANSIENT_GROUP_ID,
        name,
        objectType: objectName,
        objectLabel: objectLabel,
        position: position,
        metadata: {
          _leadingFieldId,
          _leadingFieldLabels,
          fieldName,
          objectName,
        },
        plugins: [],
      });

      if (transientFunnel.data.stage) {
        nodes.push({
          nodeType: 'node',
          id: transientFunnel.data.stage._stageId,
          position: { column: 0, row: 0 },
          name: transientFunnel.data.stage.stageName,
          type: CanvasElementType.REGULAR,
          objectType: objectName as ObjectTypeValues,
          parentId: TRANSIENT_GROUP_ID,
          showSparkleIcon: false,
        });
      }
    }

    return { nodes, edges, groups };
  }, [
    configuringPluginType?.funnelId,
    configuringPluginType?.pluginType,
    funnelMap,
    funnelStageMetadata,
    highlightEntities,
    mapForecastCategoryValueToLabel,
    objectTypesByName,
    pills,
    renderZeroCountPills,
    stagesWithSparkleIcon,
    transientFunnel,
    visibilityMap,
  ]);
};
