import { Box, Menu, PopoverOrigin } from '@mui/material';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { CustomDatePickerSection } from './CustomDatePickerSection';
import { DatePickerMenuList } from './DatePickerMenuList';
import { CustomDatePicker, DateKeys, DateBy } from './types';
import { getDateFromKey } from './getDateFromKey';
import { humanizeDate } from '../../helpers/humanizeDate';
import { FilterButton } from '../FilterButton';
import { DEFAULT_DATEPICKER_STATE } from '../../pages/salesforce-logs/login-history/utils';

const options = [
  { label: 'Today', value: DateKeys.Today },
  { label: 'Yesterday', value: DateKeys.Yesterday },
  { label: 'Last 7 days', value: DateKeys.Last7Days },
  { label: 'Last 28 days', value: DateKeys.Last28Days },
  { label: 'Last 90 days', value: DateKeys.Last90Days },
  { label: 'Last 12 months', value: DateKeys.Last12Months },
];

const customDateDefaultState = {
  [DateBy.From]: undefined,
  [DateBy.To]: undefined,
  key: DateKeys.Custom,
};

interface DatePickerWrapperProps {
  hideCustomDateButton?: boolean;
  onSelectDate: (value: CustomDatePicker) => void;
  defaultValue?: string;
  transformOrigin?: PopoverOrigin;
  anchorOrigin?: PopoverOrigin;
  customOptions?: {
    label: string;
    value: string;
  }[];
  placeholder?: string;
  disabled?: boolean;
  showClearButton?: boolean;
  preText?: React.ReactNode;
}

export type DatePickerRefProps = {
  clear: () => void;
};

export const DatePickerWrapper = forwardRef<DatePickerRefProps, DatePickerWrapperProps>(
  (
    {
      onSelectDate,
      defaultValue,
      anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'right',
      },
      transformOrigin = {
        vertical: 'top',
        horizontal: 'right',
      },
      hideCustomDateButton,
      customOptions = options,
      placeholder = 'Choose date',
      disabled,
      showClearButton = false,
      preText,
    },
    ref,
  ) => {
    const [value, setValue] = useState(defaultValue);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
    const [customDate, setCustomDate] = useState<CustomDatePicker>(customDateDefaultState);

    let chosenValue = options.find((option) => option.value === value);

    const fromStr = humanizeDate({
      dateOrTimestamp: customDate[DateBy.From] ? customDate[DateBy.From] : '',
    });

    const toStr = humanizeDate({
      dateOrTimestamp: customDate[DateBy.To] ? customDate[DateBy.To] : '',
    });

    const _showClearButton =
      !!(value || customDate[DateBy.From] || customDate[DateBy.To]) && showClearButton;

    if (!chosenValue && (fromStr || toStr)) {
      const withHyphen = fromStr && toStr;
      chosenValue = { label: fromStr + (withHyphen ? ' - ' : '') + toStr, value: DateKeys.Custom };
    }

    const clear = useCallback(() => {
      const dateFilter = getDateFromKey(DEFAULT_DATEPICKER_STATE);
      const dates = {
        [DateBy.From]: dateFilter[DateBy.From],
        [DateBy.To]: dateFilter[DateBy.To],
      };

      setValue(DEFAULT_DATEPICKER_STATE);
      setCustomDate({
        //should be same as customDateDefaultState
        //object is being compared by reference, therefore it's consider the same as initial if passed as variable
        //none of the copy/deep clone methods seems work here
        // TODO: investigate
        [DateBy.From]: undefined,
        [DateBy.To]: undefined,
        key: DateKeys.Custom,
      });
      onSelectDate({ ...dates, key: DEFAULT_DATEPICKER_STATE });
    }, [onSelectDate]);

    useImperativeHandle(ref, () => {
      return {
        clear,
      };
    }, [clear]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const onClose = useCallback(() => {
      if (value || customDate[DateBy.From] || customDate[DateBy.To]) {
        onSelectDate(value ? getDateFromKey(value as DateKeys) : customDate);
      }
      setAnchorEl(null);
    }, [value, customDate, onSelectDate]);

    const clearCustomDate = useCallback(() => {
      setShowCustomDatePicker(false);
      setCustomDate({
        ...customDate,
        [DateBy.From]: undefined,
        [DateBy.To]: undefined,
      });
    }, [customDate]);

    const onItemClick = useCallback(
      (value: DateKeys) => {
        setValue(value);
        onSelectDate(getDateFromKey(value as DateKeys));
        clearCustomDate();
        setAnchorEl(null);
      },
      [onSelectDate, clearCustomDate],
    );

    const onCustomDateChange = (date: string, direction: DateBy) => {
      customDate[direction] = date;
      setCustomDate({ ...customDate });
    };

    const onCustomButtonClick = () => {
      setValue(undefined);
      setShowCustomDatePicker(true);
    };

    return (
      <Box ref={ref}>
        <FilterButton
          handleClick={handleClick}
          isPressed={false}
          preText={preText}
          text={chosenValue?.label ?? placeholder}
          disabled={disabled}
          showClearBtn={_showClearButton}
          onClearBtn={clear}
        />

        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          MenuListProps={{
            sx: {
              display: 'flex',
              padding: '8px',
            },
          }}
        >
          {showCustomDatePicker && (
            <CustomDatePickerSection
              customDate={customDate}
              onCustomDateChange={onCustomDateChange}
            />
          )}

          <DatePickerMenuList
            value={value}
            customOptions={customOptions}
            hideCustomDateButton={hideCustomDateButton}
            onItemClick={onItemClick}
            showCustomDatePicker={showCustomDatePicker}
            onCustomButtonClick={onCustomButtonClick}
          />
        </Menu>
      </Box>
    );
  },
);
