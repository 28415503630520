import { Button, ExpandableTypography, Typography, colors } from '@sweep-io/sweep-design';
import { OrgDot } from '../../../common/OrgDot';
import { humanizeDateVariants, humanizeDate } from '../../../helpers/humanizeDate';
import {
  SfChangeFeedFilterFieldNames,
  SfChangeFeedListItem,
  SfChangeFeedNotification,
} from './types';
import { SortOrder } from '../../../common/types';
import { DateKeys } from '../../../common/datepicker/types';
import { Box, keyframes } from '@mui/material';
import { uniqueId } from '../../../../lib/uniqueId';
import { notificationStructure } from '../notifications-dialog/helpers';
import { dataTableVariants } from '../../../common/table/dataTableVariants';
import { DataTableVariant } from '../../../common/table/TableTypes';
import { ACTION_BUTTONS_KEY } from '../../../common/table/utils';

//all default states are by product definition
export const DEFAULT_SORT_KEY = SortOrder.DESC;
export const DEFAULT_SORT_BY = SfChangeFeedFilterFieldNames.Timestamp;
export const DEFAULT_DATEPICKER_STATE = DateKeys.Last28Days;

export const CHANNELS_ERROR_TXT = 'To continue, please add channel';
export const SLACK_RECIPIENTS_ERROR_TXT = 'To continue, please add recipients';
export const ENVIRONMENT_ERROR_TXT = 'To continue, please add environment';
export const RULE_BUILDER_ERROR_TXT = 'To continue, please add conditions';

export const columns = [
  {
    field: 'crmOrgId',
    headerName: 'Environment',
    width: '160px',
    showSortIcon: true,
  },
  { field: 'timestamp', headerName: 'Date', width: '160px', showSortIcon: true },
  { field: 'sfUserName', headerName: 'User', width: '160px', showSortIcon: true },
  { field: 'actionText', headerName: 'Action', width: '100%', minWidth: '250px' },
  { field: 'section', headerName: 'Section', width: '180px', showSortIcon: true },
];

export const parseRows = (
  rows: SfChangeFeedListItem[],
  crmOrgs: CrmOrg[],
  onTrackSimilarChangesClick: (criteria: SfChangeFeedNotification) => void,
) => {
  return (
    rows?.map((row) => {
      const org = crmOrgs.find((org) => row.crmOrgId === org.id);
      const actionTextParts = row?.actionText?.split(' from ');
      const [fromPart, toPart] = actionTextParts[1]?.split(' to ') ?? [];
      const withSplit = toPart && (toPart.match('}') || toPart.match('"'));

      return {
        ...row,
        timestamp:
          humanizeDate({
            dateOrTimestamp: row.timestamp,
            variant: humanizeDateVariants.TWO_DIGITS_WITH_TIME,
          }) ?? '',
        crmOrgId: (
          <Typography
            variant={dataTableVariants[DataTableVariant.default].fontVariant}
            whiteSpace="break-spaces"
            wordBreak="break-all"
          >
            {org?.name}
            {org?.isMain && <OrgDot styles={{ top: '-1px', right: '-3px' }} />}
          </Typography>
        ),
        sfUserName: (
          <Typography
            variant={dataTableVariants[DataTableVariant.default].fontVariant}
            whiteSpace="break-spaces"
          >
            {row.sfUserName ? row.sfUserName : 'System'}
          </Typography>
        ),
        section: (
          <Typography
            variant={dataTableVariants[DataTableVariant.default].fontVariant}
            whiteSpace="break-spaces"
          >
            {row.section ? row.section : 'General'}
          </Typography>
        ),
        actionText: (
          <Box
            sx={{
              '& .MuiTypography-root': {
                whiteSpace: 'break-spaces',
              },

              '& .grey-border, .green-border': {
                borderLeft: '1px solid ' + colors.grey[500],
                pl: 1.5,
              },
              '& .green-border': {
                borderColor: colors.mint[500],
              },
            }}
          >
            <ExpandableTypography
              rows={4}
              variant={dataTableVariants[DataTableVariant.default].fontVariant}
              wordBreak="break-all"
            >
              {withSplit ? actionTextParts[0] : row.actionText}
              {withSplit && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: `<div><div>From</div><div class='grey-border'>${fromPart}</div><div>To</div><div class='green-border'>${toPart}</div></div>`,
                  }}
                />
              )}
            </ExpandableTypography>
          </Box>
        ),
        id: row.sfId + row.crmOrgId,
        [ACTION_BUTTONS_KEY]: (
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              const hasSection = !!row.section;
              const newNotification = {
                ...notificationStructure,
                criteria: hasSection
                  ? [
                      {
                        criterionId: uniqueId(),
                        fieldName: 'Section',
                        operator: 'EQUALS',
                        value: row.section,
                      },
                    ]
                  : [],
                criteriaLogic: hasSection ? '1' : '',
              };

              onTrackSimilarChangesClick(newNotification);
            }}
          >
            Track similar changes
          </Button>
        ),
        trackChanges: (
          <Box
            className="section-box"
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'nowrap',
              minWidth: '150px',
              '& .MuiButton-root': {
                whiteSpace: 'nowrap',
                display: 'none',
              },
            }}
          >
            <Button
              variant="flat"
              onClick={() => {
                const hasSection = !!row.section;
                const newNotification = {
                  ...notificationStructure,
                  criteria: hasSection
                    ? [
                        {
                          criterionId: uniqueId(),
                          fieldName: 'Section',
                          operator: 'EQUALS',
                          value: row.section,
                        },
                      ]
                    : [],
                  criteriaLogic: hasSection ? '1' : '',
                };

                onTrackSimilarChangesClick(newNotification);
              }}
            >
              Track similar changes
            </Button>
          </Box>
        ),
      };
    }) ?? []
  );
};

export const fadeBackground = keyframes`
0% {
  background-color: ${colors.grey[120]};
}
100% {
  background-color: ${colors.white};
}
`;
