import { PotentialIssue } from '@server/homepage';
import React, { ReactNode, useState } from 'react';
import {
  ConfigurationTypeToPotentialIssueIcon,
  descriptionsMap,
  PotentialIssueSeverityIcon,
} from '../../pages/get-started/user-homepage/utils';
import { ConfigurationToName } from '../../documentation/dependencies/types';
import { PotentialIssueDialog } from '../../pages/get-started/user-homepage/PotentialIssueDialog';
import { DataTable } from '../table/DataTable';
import { DataTableSortStateProps, DataTableVariant, SortCompareTypes } from '../table/TableTypes';
import { SortOrder } from '../types';
import { colors, Typography } from '@sweep-io/sweep-design';
import { TruncatedTextTooltip } from '../TruncatedTextTooltip';
import { ACTION_BUTTONS_KEY } from '../table/utils';
import { ExploreWithAiButton } from '../../pages/get-started/user-homepage/ExploreWithAiButton';
import { dataTableVariants } from '../table/dataTableVariants';
import { Box, Stack } from '@mui/material';

const PRIORITY_MAP = { High: 3, Medium: 2, Low: 1 };
const severityCompareFunc = (
  itemA: PotentialIssuesTableRow,
  itemB: PotentialIssuesTableRow,
  order: SortOrder,
) => {
  const value1 = PRIORITY_MAP[itemA.issue.severity] || 0;
  const value2 = PRIORITY_MAP[itemB.issue.severity] || 0;

  if (order === SortOrder.ASC) {
    return value1 - value2;
  } else {
    return value2 - value1;
  }
};

const NameCompareFunc = (
  itemA: PotentialIssuesTableRow,
  itemB: PotentialIssuesTableRow,
  order: SortOrder,
) => {
  const value1 = itemA.issue.configurationName;
  const value2 = itemB.issue.configurationName;
  const multiplyBy = order === SortOrder.ASC ? 1 : -1;
  return value1.toLowerCase().localeCompare(value2.toLowerCase()) * multiplyBy;
};

type PotentialIssuesTableRow = {
  id: string;
  icon: ReactNode;
  configurationName: ReactNode;
  configurationType: string;
  description: string;
  severity: ReactNode;
  issue: PotentialIssue;
};

const columns = [
  {
    field: 'icon',
    headerName: '',
    showSortIcon: false,
    width: '32px',
    minWidth: '32px',
  },
  {
    field: 'configurationName',
    headerName: 'Name',
    showSortIcon: true,
    sortCompare: {
      type: SortCompareTypes.Custom,
      compareFunction: NameCompareFunc,
    },
    width: '200px',
  },
  {
    field: 'configurationType',
    headerName: 'Type',
    showSortIcon: true,
    sortCompare: {
      type: SortCompareTypes.String,
    },
    width: '120px',
    minWidth: '120px',
  },
  {
    field: 'description',
    headerName: 'Issue',
    showSortIcon: true,
    sortCompare: {
      type: SortCompareTypes.String,
    },
    width: '270px',
  },
  {
    field: 'severity',
    headerName: 'Impact',
    showSortIcon: true,
    sortCompare: {
      type: SortCompareTypes.Custom,
      compareFunction: severityCompareFunc,
    },
    width: '50px',
    minWidth: '50px',
  },
];

const columnsSmall = [
  {
    field: 'icon',
    headerName: '',
    showSortIcon: false,
    width: '20px',
    minWidth: '20px',
  },
  {
    field: 'configurationName',
    headerName: 'Name',
    showSortIcon: true,
    sortCompare: {
      type: SortCompareTypes.Custom,
      compareFunction: NameCompareFunc,
    },
    width: '100px',
    minWidth: '100px',
  },
  {
    field: 'configurationType',
    headerName: 'Type',
    showSortIcon: true,
    width: '100px',
    minWidth: '100px',
  },
  {
    field: 'description',
    headerName: 'Issue',
    showSortIcon: true,
    width: '250px',
  },
  {
    field: 'severity',
    headerName: 'Impact',
    showSortIcon: true,
    sortCompare: {
      type: SortCompareTypes.Custom,
      compareFunction: severityCompareFunc,
    },
    width: '50px',
    minWidth: '50px',
  },
];

const defaultSortState: DataTableSortStateProps = {
  sortBy: 'severity',
  sortOrder: SortOrder.DESC,
};

const PotentialIssuesTable = ({
  potentialIssues,
  tableMaxHeight,
  tableVariant = DataTableVariant.medium,
  withExploreAiButton,
}: {
  potentialIssues: PotentialIssue[];
  tableVariant?: DataTableVariant;
  withExploreAiButton?: boolean;
  tableMaxHeight?: string;
}) => {
  const [issueForDialog, setIssueForDialog] = useState<PotentialIssue>();
  const issueIconWidth = tableVariant === DataTableVariant.small ? '16px' : '24px';
  const severityIconVariant = tableVariant === DataTableVariant.small ? 'tiny' : 'default';

  const _rows: PotentialIssuesTableRow[] =
    potentialIssues?.map((issue) => ({
      id: issue.configurationName + issue.title,
      icon: React.cloneElement(ConfigurationTypeToPotentialIssueIcon[issue.configurationType], {
        width: issueIconWidth,
      }),
      configurationName: (
        <TruncatedTextTooltip
          variant={(dataTableVariants[tableVariant].fontVariant + '-medium') as any}
          color={colors.grey[900] + '!important'}
        >
          {issue.configurationName}
        </TruncatedTextTooltip>
      ),
      configurationType: ConfigurationToName[issue.configurationType],
      description:
        descriptionsMap[issue.title as keyof typeof descriptionsMap] ?? issue.description,
      severity: (
        <Stack direction="row" width="100px" alignItems="center" gap={0.5}>
          <Typography
            variant={dataTableVariants[tableVariant].fontVariant}
            color={colors.grey[700]}
          >
            {issue.severity}
          </Typography>
          {React.cloneElement(PotentialIssueSeverityIcon[issue.severity], {
            variant: severityIconVariant,
          })}
        </Stack>
      ),
      issue,
      [ACTION_BUTTONS_KEY]: withExploreAiButton ? <ExploreWithAiButton issue={issue} /> : undefined,
    })) ?? [];

  return (
    <Stack
      sx={{
        overflow: 'hidden', //to fix visibility of the corner radius
        '& td:first-of-type .MuiDivider-root, & th:first-of-type .MuiDivider-root': {
          display: 'none',
        },
        '.data-table-container': {
          '&::-webkit-scrollbar': {
            width: '4px',
            height: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: colors.grey[120] + '!important',
          },
        },
      }}
    >
      <PotentialIssueDialog
        withExploreAiButton={withExploreAiButton}
        configurationForDialog={issueForDialog}
        handleClose={() => setIssueForDialog(undefined)}
      />
      <DataTable
        columns={DataTableVariant.small === tableVariant ? columnsSmall : columns}
        rows={_rows}
        onRowClick={(
          e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
          row: PotentialIssuesTableRow,
        ) => setIssueForDialog(row.issue)}
        variant={tableVariant}
        allowReorder={false}
        defaultSortState={defaultSortState}
        hideChevronButton
        keepActionButtonsVisible
        stickyHeader
        tableMaxHeight={tableMaxHeight}
        tableEmptyStateJsx={
          <Box sx={{ marginBlock: 3, textAlign: 'center' }}>
            <Typography variant="caption" color={colors.grey[800]}>
              No issues found
            </Typography>
          </Box>
        }
      />
    </Stack>
  );
};

export default PotentialIssuesTable;
