import { funnelDetailModel } from '../../../../../models/funnelDetailModel';
import SweepStagesModel from '../../../../../models/stagesModel';
import { ListGroup } from '../../../../common/CollapseList';
import { stageToConnectStepItem, isSalesforceLink, isThirdPartyLink } from './helper';
import { colors } from '@sweep-io/sweep-design';
import { SfCurrentStep } from './types';

export const getGroupsForSalesforceStep = ({
  step,
  funnelsData,
  thirdPartyFunnelsData,
  showConnectFunnel,
}: {
  step: SfCurrentStep;
  funnelsData: FunnelMap['funnelsData'];
  thirdPartyFunnelsData: FunnelMap['thirdPartyFunnelsData'];
  showConnectFunnel?: boolean;
}) => {
  const sfFunnel = funnelsData[step.funnelId];
  if (!step.stageId) {
    console.error('No stageId provided for third party step');
    return [];
  }

  const sfStep = sfFunnel.funnelDetails.stages.find((s) => s._stageId === step.stageId);

  if (!sfFunnel || !sfStep) {
    return [];
  }
  const currentFunnelId = step.funnelId;

  const currentFunnelGroup = {
    value: funnelsData[currentFunnelId].id,
    parentName: funnelsData[currentFunnelId].name,
    label: funnelsData[currentFunnelId].funnelDetails.leadingObject.objectName,
    objectName: funnelsData[currentFunnelId].funnelDetails.leadingObject.objectName,
    items: funnelDetailModel(funnelsData[currentFunnelId].funnelDetails)
      .getAllStageValidConnections(step.stageId)
      .map(stageToConnectStepItem),
  };

  const stageLinks: FunnelLink[] | undefined = sfStep.funnelLinks;

  const doesNotHaveSfStageFunnelLink = (funnelId: string) => (stage: SweepStage) => {
    return !Boolean(
      stageLinks
        ?.filter(isSalesforceLink)
        .find((link) => link.funnelId === funnelId && stage._stageId === link.stageId),
    );
  };

  const doesNotHaveThirdPartyStageFunnelLink = (funnelId: string) => (step: ThirdPartyStep) => {
    return !Boolean(
      stageLinks
        ?.filter(isThirdPartyLink)
        .find((link) => link.funnelId === funnelId && step.id === link.stageId),
    );
  };
  const hasStepToFunnelLink = (funnelId: string) => {
    return Boolean(sfStep.funnelLinks?.some((link) => link.funnelId === funnelId && !link.stageId));
  };

  const allOtherSfFunnelGroups: ListGroup[] = Object.keys(funnelsData)
    .filter((funnelId) => funnelId !== currentFunnelId)
    .map((funnelId) => ({
      value: funnelsData[funnelId].id,
      parentName: funnelsData[funnelId].name,
      label: funnelsData[funnelId].funnelDetails.leadingObject.objectName,
      objectName: funnelsData[funnelId].funnelDetails.leadingObject.objectName,
      selectable: showConnectFunnel && !hasStepToFunnelLink(funnelId),
      items: new SweepStagesModel(funnelsData[funnelId].funnelDetails.stages)
        .nonLostSteps()
        .filter(doesNotHaveSfStageFunnelLink(funnelId))
        .map(stageToConnectStepItem),
    }));

  const allOtherThirdPartyFunnelGroups: ListGroup[] = Object.keys(thirdPartyFunnelsData)
    .filter((funnelId) => funnelId !== currentFunnelId)
    .map((funnelId) => ({
      value: thirdPartyFunnelsData[funnelId].id,
      parentName: thirdPartyFunnelsData[funnelId].name,
      label: '3rd party integration',
      objectName: '',
      labelColor: colors.storm[200],
      selectable: showConnectFunnel && !hasStepToFunnelLink(funnelId),
      showEmptyGroup: !hasStepToFunnelLink(funnelId),
      items: thirdPartyFunnelsData[funnelId].steps
        .filter(doesNotHaveThirdPartyStageFunnelLink(funnelId))
        .map((step) => ({
          value: step.id,
          label: step.name,
        })),
    }));

  return [currentFunnelGroup, ...allOtherSfFunnelGroups, ...allOtherThirdPartyFunnelGroups];
};

export const getGroupsForSalesforceFunnel = ({
  step,
  funnelsData,
  thirdPartyFunnelsData,
  showConnectFunnel,
}: {
  step: SfCurrentStep;
  funnelsData: FunnelMap['funnelsData'];
  thirdPartyFunnelsData: FunnelMap['thirdPartyFunnelsData'];
  showConnectFunnel?: boolean;
}) => {
  const sfFunnel = funnelsData[step.funnelId].funnelDetails;
  if (step.stageId !== undefined) {
    console.log(
      'Unexpected. Stage Id provided in getGroupsForThirdPartyFunnel. This isn\t a funnel.',
    );
    return [];
  }
  if (!sfFunnel) {
    return [];
  }
  const currentFunnelId = step.funnelId;

  const funnelLinks: FunnelLink[] | undefined = sfFunnel.funnelLinks;

  const doesNotHaveSfStageFunnelLink = (funnelId: string) => (stage: SweepStage) => {
    return !Boolean(
      sfFunnel.funnelLinks
        ?.filter(isSalesforceLink)
        .find((link) => link.funnelId === funnelId && stage._stageId === link.stageId),
    );
  };

  const doesNotHaveThirdPartyStageFunnelLink = (funnelId: string) => (step: ThirdPartyStep) => {
    return !Boolean(
      funnelLinks
        ?.filter(isThirdPartyLink)
        .find((link) => link.funnelId === funnelId && step.id === link.stageId),
    );
  };
  const hasStepToFunnelLink = (funnelId: string) => {
    return Boolean(
      sfFunnel.funnelLinks?.some((link) => link.funnelId === funnelId && !link.stageId),
    );
  };

  const allOtherSfFunnelGroups: ListGroup[] = Object.keys(funnelsData)
    .filter((funnelId) => funnelId !== currentFunnelId)
    .map((funnelId) => ({
      value: funnelsData[funnelId].id,
      parentName: funnelsData[funnelId].name,
      label: funnelsData[funnelId].funnelDetails.leadingObject.objectName,
      objectName: funnelsData[funnelId].funnelDetails.leadingObject.objectName,
      selectable: showConnectFunnel && !hasStepToFunnelLink(funnelId),
      items: new SweepStagesModel(funnelsData[funnelId].funnelDetails.stages)
        .nonLostSteps()
        .filter(doesNotHaveSfStageFunnelLink(funnelId))
        .map(stageToConnectStepItem),
    }));

  const allOtherThirdPartyFunnelGroups: ListGroup[] = Object.keys(thirdPartyFunnelsData)
    .filter((funnelId) => funnelId !== currentFunnelId)
    .map((funnelId) => ({
      value: thirdPartyFunnelsData[funnelId].id,
      parentName: thirdPartyFunnelsData[funnelId].name,
      label: '3rd party integration',
      objectName: '',
      labelColor: colors.storm[200],
      selectable: showConnectFunnel && !hasStepToFunnelLink(funnelId),
      items: thirdPartyFunnelsData[funnelId].steps
        .filter(doesNotHaveThirdPartyStageFunnelLink(funnelId))
        .map((step) => ({
          value: step.id,
          label: step.name,
        })),
    }));

  return [...allOtherSfFunnelGroups, ...allOtherThirdPartyFunnelGroups];
};
