import { useState, MouseEvent, useMemo } from 'react';
import { Box } from '@mui/material';
import { appRoutes } from '../../../../constants/appRoutes';
import useSweepNavigate from '../../../common/useSweepNavigate';
import { colors, IconButton, Tag } from '@sweep-io/sweep-design';
import FunnelMapCardMenu from './FunnelMapCardMenu';
import { useFunnelMaps } from '../useFunnelMaps';
import RenameFunnelMapDialog from './FunnelMapRenameDialog';
import usePermission from '../../../common/permissions/usePermission';
import { Pin, Unpin } from '@sweep-io/sweep-design/dist/icons';
import { TruncatedTextTooltip } from '../../../common/TruncatedTextTooltip';
import RestrictedTooltip from '../../../common/permissions/RestrictedTooltip';
import BoxWithDropShadow from '../../../common/BoxWithDropShadow';
import { selectRequestChangesByFunnelIds } from '../../../../reducers/requestChangesApiReducer';
import { useSelector } from 'react-redux';
import { selectUserInfoData } from '../../../../reducers/userInfoReducer';
import { Permission } from '@server/permissions';
import { telemetry } from '../../../../telemetry';
import useSendBiEvent from '../../../../hooks/useSendBiEvent';
import { UI_EVENTS } from '../../../../services/events';
import { useSweepRoleGroups } from '../../../common/useSweepRoleGroups';
import ConfirmDestructiveDialog from '../../../common/dialogs/ConfirmDestructiveDialog';
import { FunnelMapCardBase } from './FunnelMapCardBase';
import { CanvasMode } from '../../../sweep-canvas/canvas-types';

const permissionToPinStrs: Permission[] = ['edit:funnel-maps:pin'];

export const FunnelMapCard = ({
  funnelMap,
  isDeployment,
  snapshotId,
  deploymentId,
  crmOrgId,
  isPinned,
}: {
  funnelMap: FunnelMap;
  isDeployment?: boolean;
  snapshotId?: string;
  deploymentId?: string;
  crmOrgId?: string;
  isPinned: boolean;
}) => {
  const { funnelsData } = funnelMap;
  const funnelIds = Object.keys(funnelsData);
  const firstFunnelData =
    funnelIds[0] && funnelsData[funnelIds[0]] ? funnelsData[funnelIds[0]] : undefined;
  const selectOptions: any = [];
  const funnels = Object.values(funnelsData);

  funnels?.forEach((funnel) => {
    if (!funnel.funnelDetails?.leadingObject?.objectName) {
      telemetry.captureError('Funnel does not contain leadingObject objectName', {
        funnelId: funnel.id,
      });
    }

    selectOptions.push({
      id: funnel.id,
      name: funnel.name,
      funnel,
      objectName: funnel.funnelDetails?.leadingObject?.objectName || 'Lead',
    });
  });
  const requestedChanges = useSelector(selectRequestChangesByFunnelIds(funnelIds));
  const userInfo = useSelector(selectUserInfoData);
  const sendBiEvent = useSendBiEvent();

  const [isHovered, setIsHovered] = useState(false);
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const [isRemoveDialogOpen, setRemoveDialogOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { renameFunnelMap, deleteFunnelMap, updateIsPinned } = useFunnelMaps();
  const { navigate } = useSweepNavigate();
  const { isAdmin } = useSweepRoleGroups();

  const [canEditFunnelMaps] = usePermission(['edit:funnel-maps']);

  const [isAllowedPinToTop] = usePermission(permissionToPinStrs);
  const [isSubmittingPinToTop, setIsSubmittingPinToTop] = useState(false);

  const showRequestedChanges = useMemo(() => {
    return isAdmin(userInfo?.roleGroupId ?? '') && requestedChanges.length > 0;
  }, [isAdmin, userInfo?.roleGroupId, requestedChanges.length]);

  return (
    <Box sx={{ position: 'relative' }} data-testid="funnel-map-page-card-box">
      {isRenameDialogOpen && (
        <RenameFunnelMapDialog
          isOpen={isRenameDialogOpen}
          closeDialog={() => setIsRenameDialogOpen(false)}
          onConfirm={(data) => {
            if (data.name !== funnelMap.name) {
              renameFunnelMap({ funnelMap, newName: data.name });
            }
            setIsRenameDialogOpen(false);
          }}
          dialogTitle="Rename Funnel View"
          confirmText="Done"
          initialName={funnelMap.name}
          readOnly={!canEditFunnelMaps}
        />
      )}

      <ConfirmDestructiveDialog
        open={isRemoveDialogOpen}
        onConfirm={async () => {
          await deleteFunnelMap(funnelMap.id);
        }}
        onClose={() => setRemoveDialogOpen(false)}
        title="Remove Funnel View?"
        confirmText="Remove"
        cancelText="Cancel"
      >
        Are you sure you want to remove this funnel view?
      </ConfirmDestructiveDialog>

      <FunnelMapCardBase
        funnelMap={funnelMap}
        isPinned={isPinned}
        isActive={isMenuOpen}
        onHoverToggle={setIsHovered}
        hasRequestedChanges={showRequestedChanges}
        isDeployment={isDeployment}
        canvasMode={isPinned ? CanvasMode.PREVIEW3 : CanvasMode.PREVIEW1}
        onClick={() => {
          if (isDeployment) {
            navigate(
              `${appRoutes.canvasUnitedSnapshot.route}/${firstFunnelData?.id}?snapshotId=${snapshotId}&crmOrgId=${crmOrgId}&deploymentId=${deploymentId}`,
            );
          } else {
            sendBiEvent({ name: UI_EVENTS.funnelsOpen, props: { mode: 'edit' } });
            navigate(`${appRoutes.canvasUnited.route}/${funnelMap.id}`);
          }
        }}
      >
        {!isDeployment && (
          <FunnelMapCardMenu
            funnelMapId={funnelMap.id}
            funnelMapName={funnelMap.name}
            isMenuOpen={isMenuOpen}
            isHovered={isHovered}
            setIsMenuOpen={setIsMenuOpen}
            openRenameDialog={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation();
              setIsRenameDialogOpen(true);
            }}
            openRemoveDialogOpen={() => setRemoveDialogOpen(true)}
          />
        )}

        {!isDeployment && (
          <BoxWithDropShadow
            sx={{
              position: 'absolute',
              zIndex: 100, //should be above the card but beneath the "version history dialog"
              right: '9px',
              top: '40px',
              display: isHovered ? 'block' : 'none',
            }}
          >
            <RestrictedTooltip
              to={permissionToPinStrs}
              notAllowedTitle={'Only admins can pin/unpin'}
              forceDisallowTitle={isPinned ? 'Unpin' : 'Pin to top'}
              placement="bottom"
            >
              <IconButton
                variant="outlined"
                size="tiny"
                disabled={isSubmittingPinToTop || !isAllowedPinToTop}
                onClick={async (ev: MouseEvent<HTMLButtonElement>) => {
                  ev.stopPropagation(); //to avoid the funnel card from being clicked
                  setIsSubmittingPinToTop(true);
                  await updateIsPinned({
                    funnelMapId: funnelMap.id,
                    isPinned: !isPinned,
                  });
                  setIsSubmittingPinToTop(false);
                }}
              >
                <>
                  {isPinned && <Unpin />}
                  {!isPinned && <Pin />}
                </>
              </IconButton>
            </RestrictedTooltip>
          </BoxWithDropShadow>
        )}
      </FunnelMapCardBase>

      {isPinned && funnelMap.name && (
        <Box sx={{ position: 'absolute', top: '22px', left: '-14px' }}>
          <Ribbon text={funnelMap.name} hasRequestedChanges={showRequestedChanges} />
        </Box>
      )}
    </Box>
  );
};

const Ribbon = ({ text, hasRequestedChanges }: { text: string; hasRequestedChanges?: boolean }) => {
  return (
    <Box
      sx={{
        borderRadius: '4px',
        border: `1px solid ${colors.grey[300]}`,
        backgroundColor: colors.white,
        width: 'fit-content',
        maxWidth: '600px',
        '&:after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          bottom: '-6px',
          left: '3px',
          width: '10px',
          height: '6px',
          backgroundImage: `linear-gradient(to bottom left, ${colors.grey[300]} 50%, transparent 0)`,
        },
      }}
    >
      <Box
        sx={{
          padding: '14px 14px 14px 22px',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Pin />
        <Box maxWidth="95%">
          <TruncatedTextTooltip variant="h4">{text}</TruncatedTextTooltip>
        </Box>
        {hasRequestedChanges && (
          <Box>
            <Tag color={colors.blush[300]} label="Pending requests" />
          </Box>
        )}
      </Box>
    </Box>
  );
};
