import {
  UserDefinedRoleGroupsInterface,
  RoleGroupType,
  RoleLevel,
  Sections,
  RoleLevels,
  OrgRoleLevels,
} from '@server/role-group-interface';
import { SweepRoles } from '../../../hooks/useUserInfo';
import { isEqual } from 'lodash';
import { RoleGroupForMultipleOrgs } from './RoleManagerContext';

export const featureTranslations__deprecated: { [key in (typeof Sections)[number]]: string } = {
  automations: 'Automations',
  alerts: 'Alerts',
  dedupMatching: 'Dedupe & Matching',
  rollups: 'Rollups',
  funnels: 'Funnel Editor',
  assignments: 'Assignments',
  hubspot: 'HubSpot',
};

export const featureTranslations: { [key in (typeof Sections)[number]]: string } = {
  automations: 'Automations',
  alerts: 'Alerts',
  dedupMatching: 'Dedupe & Matching',
  rollups: 'Rollups',
  funnels: 'Funnel Editor',
  assignments: 'Assignments',
  hubspot: 'Marketing Attribution', // key needs to be first updated in the backend
};

export const roleTranslations: { [key in RoleLevel]: string } = {
  DEPLOY: 'Deploy',
  VIEWER: 'Viewer',
  REQUEST_TO_DEPLOY: 'Request to deploy',
};

export const sortRoles = (roles: UserDefinedRoleGroupsInterface[]) => {
  const systemOrder = [
    SweepRoles.Admin,
    SweepRoles.Collaborator,
    SweepRoles.Alert_Manager,
    SweepRoles.Viewer,
  ];

  return [...roles].sort((a, b) => {
    const aIsCustom = a.type === RoleGroupType.USER_CREATED;
    const bIsCustom = b.type === RoleGroupType.USER_CREATED;

    if (aIsCustom && bIsCustom) {
      return a.name.localeCompare(b.name);
    }

    if (aIsCustom && !bIsCustom) {
      return -1;
    }

    if (!aIsCustom && bIsCustom) {
      return 1;
    }

    if (!aIsCustom && !bIsCustom) {
      return systemOrder.indexOf(a.name as SweepRoles) - systemOrder.indexOf(b.name as SweepRoles);
    }

    return 0;
  });
};

export const createRoleGroupAccountBasedStructure = (
  crmOrgId: string,
): { crmOrgId: string; levels: RoleLevels } => ({
  crmOrgId,
  levels: {
    automations: RoleLevel.VIEWER,
    assignments: RoleLevel.VIEWER,
    rollups: RoleLevel.VIEWER,
    dedupMatching: RoleLevel.VIEWER,
    funnels: RoleLevel.VIEWER,
    alerts: RoleLevel.VIEWER,
    hubspot: RoleLevel.VIEWER,
  },
});

export const createRoleGroupOrgBasedStructure = (
  crmOrgId: string,
): { crmOrgId: string; levels: OrgRoleLevels[] } => ({
  crmOrgId,
  levels: [
    {
      crmOrgId,
      levels: {
        automations: RoleLevel.VIEWER,
        assignments: RoleLevel.VIEWER,
        rollups: RoleLevel.VIEWER,
        dedupMatching: RoleLevel.VIEWER,
        funnels: RoleLevel.VIEWER,
        alerts: RoleLevel.VIEWER,
        hubspot: RoleLevel.VIEWER,
      },
    },
  ],
});

// This will filter out the 'REQUEST_TO_DEPLOY' roleKey if the roleLevel is 'hubspot'
// until we add the request to deploy feature to HubSpot
export const temporarilyFilterHubspotRequestToDeploy = (roleLevel: string) => (roleKey: string) => {
  if (roleLevel === 'hubspot' && roleKey === 'REQUEST_TO_DEPLOY') {
    return false;
  }

  return true;
};

export const translateOrgBasedRoleLevelsIntoMultiOrgsStructure = (
  roleGroupLevels: OrgRoleLevels[],
) => {
  const multiOrgRoleGroups: RoleGroupForMultipleOrgs[] = [];
  roleGroupLevels.forEach((orgRole) => {
    const multiOrgIdx = multiOrgRoleGroups.findIndex((multiOrgRole) =>
      isEqual(multiOrgRole.levels, orgRole.levels),
    );

    if (multiOrgIdx !== -1) {
      multiOrgRoleGroups[multiOrgIdx].crmOrgIds.push(orgRole.crmOrgId);
    } else {
      const newMultiOrgGroup = { crmOrgIds: [orgRole.crmOrgId], levels: orgRole.levels };
      multiOrgRoleGroups.push(newMultiOrgGroup);
    }
  });
  return multiOrgRoleGroups;
};
