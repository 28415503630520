import Divider from '@mui/material/Divider';
import { colors } from '@sweep-io/sweep-design';

export const CellDivider = () => {
  return (
    <Divider
      sx={{ borderColor: colors.grey[120], alignSelf: 'center', height: '24px', paddingRight: 1 }}
      orientation="vertical"
      flexItem
    />
  );
};
