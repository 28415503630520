import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';

import CircleIcon from '@mui/icons-material/Circle';

interface StyledTextFieldProps {
  isWarning?: boolean;
}

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'isWarning',
})<StyledTextFieldProps>(({ isWarning }) => {
  const color = !!isWarning ? colors.blush[600] : colors.grey[900];

  return {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& input': {
        color,
      },
    },
  };
});

export const StyledTypography = styled(Typography)({
  color: colors.grey[800],
  display: 'flex',
  alignItems: 'center',
});

export const StyledCircleIcon = styled(CircleIcon)({
  color: colors.blush[600],
  fontSize: '5px',
  marginLeft: '3px',
});
