import { useSelector } from 'react-redux';
import { selectMarketingAttributionModels } from '../../../../../reducers/global/marketingAttributionReducer';
import { AutomationDialogContent } from '../../../../Automations/AutomationDialogContent';
import { automationsSortOptions } from '../../consts';
import useAutomationApiPerType from '../../../../Automations/useAutomationApiPerType';
import { AutomationFormVariant } from '../../../../../types/enums/AutomationFormVariant';
import { DeployStatusForTable } from '../../../../../types/enums/DeployStatusForTable';
import { selectDefaultCreationEnvironment } from '../../../environments/environmentsReducer';
import { Stack } from '@mui/material';

export const AttributionModelsTableWithFilters = () => {
  const marketingAttributionModels = useSelector(selectMarketingAttributionModels);
  const crmOrg = useSelector(selectDefaultCreationEnvironment);

  const { onSaveOrCreate, onDeleteAutomation, onDeployAutomation } = useAutomationApiPerType({
    automationVariant: AutomationFormVariant.MARKETING_ATTRIBUTIONS,
  });

  const hasAnyDeployedAutomations =
    marketingAttributionModels &&
    marketingAttributionModels?.filter(
      (attribution) => attribution.status === DeployStatusForTable.Deployed,
    )?.length > 0;

  const viewInSalesforceLink =
    hasAnyDeployedAutomations && crmOrg?.instanceUrl
      ? `${crmOrg?.instanceUrl}/lightning/o/Dashboard/home?queryScope=userFolders`
      : undefined;

  return (
    <Stack sx={{ height: '100%', overflow: 'hidden', pb: 4 }}>
      <AutomationDialogContent
        automations={marketingAttributionModels ?? []}
        onDeleteAutomation={onDeleteAutomation}
        crmOrgId={crmOrg?.id ?? ''}
        onSaveOrCreate={onSaveOrCreate}
        sortOptions={automationsSortOptions}
        disableCanvasTemplates={true}
        onDeployAutomation={onDeployAutomation}
        filtersForExpandedMode={{}}
        viewInSalesforceLink={viewInSalesforceLink}
      />
    </Stack>
  );
};
