import useSendBiEvent from './useSendBiEvent';
import { USER_EVENTS } from '../services/events';
import usePaywall from '../components/dashboard/paywall/usePaywall';
import { useLocation } from 'react-router';
import useHandleMessages from './useHandleMessages';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectUserRole } from '../reducers/userInfoReducer';
import useUserInfo from './useUserInfo';

const useSendMeetingBookedEvents = () => {
  const sendBiEvent = useSendBiEvent();
  const { updateHubspotContact } = useUserInfo();

  const { paywallState } = usePaywall();
  const { pathname } = useLocation();
  const mainPath = pathname.split('/')[1];
  const userRole = useSelector(selectUserRole);

  const handleMessage = useCallback(
    (event: MessageEvent) => {
      if (!event.data.action) {
        return;
      }
      const isMeetingBooked = event.data.action === 'booked';
      const isMeetingConfirmed = event.data.action === 'booking-confirmed';
      if (isMeetingBooked) {
        sendBiEvent({
          name: USER_EVENTS.meetingBooked,
          props: {
            source: mainPath,
            trialStatus: paywallState,
            userRole,
          },
        });
        updateHubspotContact({
          last_demo_click_source: mainPath,
          last_demo_click_date: Date.now(),
        });
      }
      if (isMeetingConfirmed) {
        sendBiEvent({
          name: USER_EVENTS.meetingConfirmed,
          props: {
            source: mainPath,
            trialStatus: paywallState,
            userRole,
          },
        });
      }
    },
    [mainPath, paywallState, sendBiEvent, userRole, updateHubspotContact],
  );

  useHandleMessages(['booked', 'booking-confirmed'], handleMessage);
};

export default useSendMeetingBookedEvents;
