import { MenuItem, ListItemIcon, Box, Stack } from '@mui/material';
import { Button, Tag, Typography, colors } from '@sweep-io/sweep-design';
import { CustomWebhookInterface, useWebhooksContext } from '../webhooks/WebhooksContext';
import { ActionsMenu } from '../../common/actions-menu/ActionsMenu';
import { Delete, Integrations } from '@sweep-io/sweep-design/dist/icons';
import { LogoDevIcon } from '../../common/logo-dev-selector/LogoDevIcon';
import { useIntegrations } from '../../../hooks/useIntegrations';
import useConfirm from '../../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import ConfirmDestructiveDialog from '../../common/dialogs/ConfirmDestructiveDialog';
import { useState } from 'react';
import { UsedIntegrationDialog } from './UsedIntegrationDialog';
import { ManageEnvDialog } from '../../pages/environments/connect-env/ManageEnvDialog';
import { useSelector } from 'react-redux';
import { selectDefaultCreationEnvironment } from '../../pages/environments/environmentsReducer';
import { ConfirmDialog } from '../../common/dialogs/ConfirmDialog';
import usePermission from '../../common/permissions/usePermission';
import RestrictedTooltip from '../../common/permissions/RestrictedTooltip';
import { useFeatureToggle } from '../../common/useFeatureToggle';
import { AuditDialog } from '../../common/audit-table/AuditDialog';
import { AuditReportType } from '../../common/audit-table/AuditTable';

interface CustomIntegrationOptionProps {
  integration: CustomWebhookInterface;
}
enum WebhookActionsEnum {
  DELETE = 'DELETE',
  ACTIVITY = 'ACTIVITY',
}

export const CustomIntegrationsOption = ({ integration }: CustomIntegrationOptionProps) => {
  const { webhookActivity } = useFeatureToggle();
  const { deleteCustomIntegration } = useIntegrations();
  const { setWebhooks, setEditWebhook } = useWebhooksContext();
  const { openConfirm, onCancel, onConfirm } = useConfirm();
  const [usedAutomationList, setUsedAutomationList] = useState([]);
  const [isUsedIntegrationOpen, setIsUsedIntegrationOpen] = useState(false);
  const [isManageEnvOpen, setIsManageEnvOpen] = useState(false);
  const [isActivityOpen, setIsActivityOpen] = useState(false);
  const selectedEnv = useSelector(selectDefaultCreationEnvironment);
  const [isAllowedBtnEdit, isAllowedBtnDelete] = usePermission([
    'edit:data-integration:custom-integrations',
    'delete:data-integration:custom-integrations',
  ]);

  const menuActions = [
    {
      label: 'Integration activity',
      value: WebhookActionsEnum.ACTIVITY,
      actionIcon: <Integrations />,
      disabled: !webhookActivity,
    },
    {
      label: 'Delete',
      value: WebhookActionsEnum.DELETE,
      actionIcon: <Delete />,
      disabled: !isAllowedBtnDelete,
      disabledTooltip: 'To adjust integration settings, please contact your admin.',
    },
  ];

  const handleDelete = async (isDryRun: boolean) => {
    if (selectedEnv?.isConnected) {
      try {
        await deleteCustomIntegration(integration?.id, isDryRun);
        //if ok show confirm dialog and resend with no dry-run
        if (isDryRun) {
          const isConfirmed = await openConfirm(
            <ConfirmDestructiveDialog
              open={true}
              onConfirm={onConfirm}
              onClose={onCancel}
              title={`Delete ${integration?.name} integration from Sweep?`}
              confirmText="Delete"
              cancelText="Cancel"
            ></ConfirmDestructiveDialog>,
          );
          if (isConfirmed) {
            handleDelete(false);
          }
        }
      } catch (error: any) {
        //show the used automations list
        const usedAutomationList = error?.response?.data?.automationIds;
        if (usedAutomationList?.length > 0) {
          setUsedAutomationList(usedAutomationList);
          setIsUsedIntegrationOpen(true);
        }
      }
    } else {
      const isConfirmed = await openConfirm(
        <ConfirmDialog
          title="Unable to delete integration"
          confirmText="Manage environment"
          children={
            <Typography variant="body">
              {`Your Salesforce environment ${selectedEnv?.name} is disconnected. Reconnect or remove the environment, then delete the integration.`}
            </Typography>
          }
          isOpen={true}
          handleClose={onCancel}
          onConfirm={onConfirm}
          PaperProps={{ sx: { width: '464px' } }}
          confirmButtonType="error"
        />,
      );
      if (isConfirmed) {
        setIsManageEnvOpen(true);
      }
    }
  };

  return (
    <>
      <MenuItem>
        <Stack width="100%">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2,
            }}
          >
            {integration?.logo ? (
              <LogoDevIcon brand={integration.logo} size={32} />
            ) : (
              <ListItemIcon>
                <Box
                  sx={{
                    background: colors.blue[100],
                    width: '32px',
                    height: '32px',
                    borderRadius: '50%',
                    textAlign: 'center',
                    lineHeight: '32px',
                  }}
                >
                  <Integrations variant={'tiny'} color={colors.blue[600]} />
                </Box>
              </ListItemIcon>
            )}

            <Box
              sx={{
                flex: '1',
                textWrap: 'wrap',
                gap: 1,
                display: 'flex',
              }}
            >
              <Typography variant="body-medium">{integration.name}</Typography>

              <Tag color={colors.blue[100]} label="Custom integration" textTheme="dark" />
            </Box>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <RestrictedTooltip
                to={['edit:data-integration:custom-integrations']}
                notAllowedTitle={'To adjust integration settings, please contact your admin.'}
              >
                <Button
                  disabled={!isAllowedBtnEdit}
                  onClick={() => {
                    setEditWebhook(integration);
                    setWebhooks(true);
                  }}
                  size="small"
                  startIconName=""
                  variant="outlined"
                >
                  Edit
                </Button>
              </RestrictedTooltip>
              <ActionsMenu
                actions={menuActions}
                onClick={(action) => {
                  if (action?.value === WebhookActionsEnum.DELETE) {
                    handleDelete(true);
                  }
                  if (action?.value === WebhookActionsEnum.ACTIVITY) {
                    setIsActivityOpen(true);
                  }
                }}
                iconVariant="outlined"
              />
            </Box>
          </Box>
        </Stack>
      </MenuItem>
      {isUsedIntegrationOpen && (
        <UsedIntegrationDialog
          usedAutomationList={usedAutomationList}
          integration={integration}
          isOpen={isUsedIntegrationOpen}
          onClose={() => {
            setIsUsedIntegrationOpen(false);
            setUsedAutomationList([]);
          }}
        />
      )}
      {isManageEnvOpen && (
        <ManageEnvDialog onClose={() => setIsManageEnvOpen(false)} allowDeleteLastEnv={false} />
      )}
      {isActivityOpen && (
        <AuditDialog
          onClose={() => setIsActivityOpen(false)}
          auditType={AuditReportType.INTEGRATION_WEBHOOKS}
          integrationId={integration?.id}
        />
      )}
    </>
  );
};
