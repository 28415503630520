import { useCallback, useRef } from 'react';
import { useReactFlow } from '@xyflow/react';
import { CanvasElementType, CanvasMode, SweepCanvasGroup, SweepCanvasNode } from '../canvas-types';
import { convertGridPositionToXYPosition } from '../helpers/gridPositioningUtils';
import { RfNodeGroup, RfNodeGroupLabel, RfNodeGroupOverlay } from '../canvas-types/nodeTypesData';
import { useCanvasInternalContext } from '../CanvasContext';
import { useSweepCanvasPropsCtx } from '../internal-context/SweepCanvasPropsCtx';
import { calculateGroupLabelPosition } from '../helpers/calculateGroupLabelPosition';
import { calculateGroupDimensions } from '../calculateGroupDimensions';
import { useGetShowButtonsOnHighlight } from '../effects/useContextZoomEffect';

export const useGroupNodesFactory = () => {
  const {
    hideGroupInfo,
    canvasMode = CanvasMode.DEFAULT,
    onPluginClick,
    showGroupOverlays,
    readonly,
    onNodeClick,
    onPillClick,
    onLabelClick,
    onConnectClick,
    simpleHighlightedEntities,
  } = useSweepCanvasPropsCtx();
  const { getOrCreateRefForPluginButton } = useCanvasInternalContext();
  const { getInternalNode } = useReactFlow();
  const labelDimensions = useRef<{
    [id: string]: {
      width: number;
      height: number;
    };
  }>({});

  const showButtonsOnHighlight = useGetShowButtonsOnHighlight();

  const calculateGroupNodes = useCallback(
    ({
      disableGroups,
      sweepNodes,
      sweepGroups,
    }: {
      disableGroups?: boolean;
      sweepNodes: SweepCanvasNode[];
      sweepGroups: SweepCanvasGroup[];
    }) => {
      if (disableGroups) {
        return {
          groupNodes: [],
          groupLabelNodes: [],
          groupOverlayNodes: [],
        };
      }

      const ghostGroups = new Set(
        sweepNodes
          .filter((node) => node.type === CanvasElementType.GHOST_NODE)
          .map((node) => node.parentId),
      );

      const groupNodes: RfNodeGroup[] = [];
      const groupLabelNodes: RfNodeGroupLabel[] = [];
      const groupOverlayNodes: RfNodeGroupOverlay[] = [];

      sweepGroups.forEach((sweepGroup) => {
        const { groupBoundingBox, groupNodeBoundaries } = calculateGroupDimensions({
          nodeWithCanvasPositions: sweepNodes.map((node) => ({
            id: node.id,
            position: node.position,
            parentId: node.parentId,
          })),
          groupId: sweepGroup.id,
          isPreview: canvasMode !== CanvasMode.DEFAULT,
        });

        const groupPosition = convertGridPositionToXYPosition(sweepGroup.position);

        const preview = canvasMode !== CanvasMode.DEFAULT;

        if (showGroupOverlays) {
          groupOverlayNodes.push({
            id: `overlay-${sweepGroup.id}`,
            data: {
              boundingBox: groupBoundingBox,
              nodeBoundaries: groupNodeBoundaries,
              onClick: onNodeClick,
              groupId: sweepGroup.id,
            },
            position: groupPosition,
            draggable: false,
            selectable: false,
            type: CanvasElementType.GROUP_OVERLAY,
            hidden: false,
            width: 1,
            height: 1,
            zIndex: 9999,
          });
        }

        const newGroupNode: RfNodeGroup = {
          id: sweepGroup.id,
          data: {
            nodeBoundaries: groupNodeBoundaries,
            boundingBox: groupBoundingBox,
            objectType: sweepGroup.objectType,
            hideGroupInfo,
            onClick: onNodeClick,
            isGhost: ghostGroups.has(sweepGroup.id),
            showEditButton: sweepGroup.showEditButton,
            groupId: sweepGroup.id,
            simpleHighlighted: simpleHighlightedEntities?.includes(sweepGroup.id),
          },
          position: groupPosition,
          draggable: true,
          selectable: false,
          type: CanvasElementType.GROUP,
          hidden: false,
          width: groupBoundingBox.width,
          height: groupBoundingBox.height,
        };

        groupNodes.push(newGroupNode);

        if (!preview) {
          const labelId = `label-${sweepGroup.id}`;

          const internalNode = getInternalNode(labelId);
          if (internalNode) {
            const width = internalNode.internals.handleBounds?.source?.find(
              (handle) => handle.id === 'sr',
            )?.x;

            const height = internalNode.internals.handleBounds?.source?.find(
              (handle) => handle.id === 'sb',
            )?.y;
            if (width && height) {
              labelDimensions.current[labelId] = { width, height };
            }
          }

          groupLabelNodes.push({
            id: labelId,
            data: {
              label: sweepGroup.name,
              boundingBox: groupBoundingBox,
              objectType: sweepGroup.objectType,
              objectLabel: sweepGroup.objectLabel,
              readonly,
              hideGroupInfo,
              groupId: sweepGroup.id,
              onLabelClick,
              onPillClick,
              labelPlugins:
                sweepGroup.plugins?.map((pluginType) => ({
                  type: pluginType,
                  onClick: (event) =>
                    onPluginClick?.({
                      parentId: sweepGroup.id,
                      pluginId: pluginType,
                      objectType: sweepGroup.objectType,
                      event,
                    }),
                  ref: getOrCreateRefForPluginButton(sweepGroup.id, pluginType),
                })) || [],

              pills: sweepGroup.pills,
              startingIcon: sweepGroup.startingIcon,
              description: sweepGroup.description,
              objectLabelLogoDevBrand: sweepGroup.objectLabelLogoDevBrand,
              onConnectClick,
              showButtonsOnHighlight,
              simpleHighlighted: simpleHighlightedEntities?.includes(sweepGroup.id),
            },
            position: calculateGroupLabelPosition(groupPosition, groupBoundingBox),
            draggable: true,
            selectable: false,
            type: CanvasElementType.GROUP_LABEL,
            zIndex: 5,
            height: labelDimensions.current[labelId]?.height || 1,
            width: labelDimensions.current[labelId]?.width || 1,
          });
        }
      });

      return {
        groupNodes,
        groupLabelNodes,
        groupOverlayNodes,
      };
    },
    [
      canvasMode,
      getInternalNode,
      getOrCreateRefForPluginButton,
      hideGroupInfo,
      onConnectClick,
      onLabelClick,
      onNodeClick,
      onPillClick,
      onPluginClick,
      readonly,
      showButtonsOnHighlight,
      showGroupOverlays,
      simpleHighlightedEntities,
    ],
  );

  return { calculateGroupNodes };
};
