import { Stack } from '@mui/material';
import { ReactNode } from 'react';
import useAiAgentChats from './useAiAgentChats';
import AgentsCardsExpanded from './AgentsCardsExpanded';
import AiAgentCenterSideMenu from './AiAgentCenterSideMenu';
import ChatWithAgentExpanded from './ChatWithAgentExpanded';
import { AgentType } from '@server/ai';

const HISTORY_PANEL_WIDTH = '300px';

const AiAgentCenterExpanded = ({
  closeButton,
  onNewAgentClick,
}: {
  closeButton: ReactNode;
  onNewAgentClick: (type: AgentType) => void;
}) => {
  const { activeChat } = useAiAgentChats();

  return (
    <Stack height="100%" direction="row" position="relative">
      <Stack
        sx={{
          flex: `0 0 ${HISTORY_PANEL_WIDTH}`,
        }}
      >
        <AiAgentCenterSideMenu />
      </Stack>
      <Stack
        sx={{
          flex: 1,
          alignItems: 'center',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        {!activeChat && (
          <AgentsCardsExpanded
            closeButton={closeButton}
            onNewAgentClick={onNewAgentClick}
          />
        )}
        {activeChat && <ChatWithAgentExpanded closeButton={closeButton} />}
      </Stack>
    </Stack>
  );
};

export default AiAgentCenterExpanded;
