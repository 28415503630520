import _isEqual from 'lodash/isEqual';
import { Box, Checkbox, List, ListItemText, MenuItem, Popover, PopoverOrigin } from '@mui/material';
import { FlexBox } from '../FlexBox';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Button, Typography, colors } from '@sweep-io/sweep-design';
import { TruncatedTextTooltip } from '../TruncatedTextTooltip';
import { SearchInput } from '../SearchInput';
import { filterItemsBySearch } from '../../../lib/filterItemsBySearch';
import {
  AdvancedFilterItem,
  AdvancedFilterItemValue,
  AdvancedFilterProps,
  itemToItemValue,
} from './AdvancedFilter';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';

const SHOW_ALL_FEATURES_FROM = 5;
const MIN_VIRTUALIZED_ITEMS = 50;
export type AdvancedFilterPopoverTexts = {
  title: string;
  selectAll: string;
  onlyButton: string;
};

export const defaultTexts: AdvancedFilterPopoverTexts = {
  title: 'Filter',
  selectAll: 'Select All',
  onlyButton: 'Only',
};

interface AdvancedFilterPopoverProps<DataT = any>
  extends Pick<
    AdvancedFilterProps<DataT>,
    'items' | 'selectedItems' | 'onSelectedItemsChange' | 'hideLabels'
  > {
  anchorEl: HTMLElement | null;
  texts?: Partial<AdvancedFilterPopoverTexts>;
  onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  enableVirtualScroll?: boolean;
}

const filterItemsNotInSet = (items: AdvancedFilterItem[], set: Set<string>) =>
  items.filter((item) => set.has(item.value)).map(itemToItemValue);

const FilterItem = ({
  item,
  hideLabels,
  toggleItem,
  selectedItems,
  selectOnlyItem,
  _texts,
}: {
  item: AdvancedFilterItem;
  hideLabels?: boolean;
  toggleItem: (item: AdvancedFilterItemValue) => void;
  selectedItems: string[];
  selectOnlyItem: (item: AdvancedFilterItemValue) => void;
  _texts: AdvancedFilterPopoverTexts;
}) => {
  if (item.isSectionItem) {
    return (
      <Box
        sx={{
          padding: '4px 4px 4px 12px',
          marginBottom: '4px',
        }}
      >
        <Typography variant={'caption-bold'} color={colors.grey[500]}>
          {item.label}
        </Typography>
      </Box>
    );
  }

  return (
    <MenuItem
      value={item.value}
      onClick={() => toggleItem(itemToItemValue(item))}
      sx={{
        padding: '4px 4px 4px 12px',
        marginBottom: '4px',
        '.button-only': {
          opacity: 0,
          padding: '5px 0',
          transition: 'opacity 500ms',
          '.MuiButtonBase-root': {
            justifyContent: 'center',
          },
        },
        '&:hover .button-only': {
          opacity: 1,
        },
      }}
    >
      <Checkbox checked={selectedItems.includes(item.value) || selectedItems.length == 0} />

      <ListItemText sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {!hideLabels && <TruncatedTextTooltip variant="body">{item.label}</TruncatedTextTooltip>}
        {item.labelDecoration}
      </ListItemText>
      <span className="button-only">
        <Button
          size="small"
          variant="flat"
          onClick={(e) => {
            e.stopPropagation();
            selectOnlyItem(itemToItemValue(item));
          }}
        >
          {_texts.onlyButton}
        </Button>
      </span>
    </MenuItem>
  );
};

export function AdvancedFilterPopover<DataT = any>({
  items,
  selectedItems,
  anchorEl,
  onSelectedItemsChange,
  texts,
  onClose,
  anchorOrigin,
  transformOrigin,
  hideLabels,
  enableVirtualScroll,
}: AdvancedFilterPopoverProps<DataT>) {
  const _texts = useMemo(() => ({ ...defaultTexts, ...texts }), [texts]);
  const isOpen = Boolean(anchorEl);
  const selectableItems = useMemo(() => items.filter((item) => !item.isSectionItem), [items]);

  const isShowAllFeatures = useMemo(
    () => selectableItems.length > SHOW_ALL_FEATURES_FROM,
    [selectableItems],
  );
  const [searchTxt, setSearchTxt] = useState('');

  const filteredItems = useMemo(
    () => filterItemsBySearch(items, searchTxt, 'label'),
    [items, searchTxt],
  );

  const toggleItem = useCallback(
    (item: AdvancedFilterItemValue) => {
      const selectedItemsSet = new Set(selectedItems);
      const itemsSet = new Set(selectableItems.map((item) => item.value));

      const wereAllSelected = selectedItemsSet.size == 0;

      if (wereAllSelected) {
        const newSelectedItems = selectableItems.filter(({ value }) => value !== item.value);
        onSelectedItemsChange(
          newSelectedItems.map((item) => item.value),
          newSelectedItems,
        );
        return;
      }

      if (selectedItemsSet.has(item.value)) {
        selectedItemsSet.delete(item.value);
      } else {
        selectedItemsSet.add(item.value);
      }

      const selectedItemsArray = Array.from(selectedItemsSet);
      const areAllSelected = _isEqual(itemsSet, selectedItemsSet);

      onSelectedItemsChange(
        areAllSelected ? [] : selectedItemsArray,
        areAllSelected ? [] : filterItemsNotInSet(selectableItems, selectedItemsSet),
      );
    },
    [selectedItems, onSelectedItemsChange, selectableItems],
  );

  const selectOnlyItem = useCallback(
    (item: AdvancedFilterItemValue) => {
      onSelectedItemsChange([item.value], [item]);
    },
    [onSelectedItemsChange],
  );

  const clearAll = useCallback(() => {
    onSelectedItemsChange([], []);
  }, [onSelectedItemsChange]);

  const areAllElementsSelected = useMemo(() => {
    if (selectedItems.length === 0) return true;
    const selectedItemsSet = new Set(selectedItems);
    const itemsSet = new Set(selectableItems.map((item) => item.value));
    return _isEqual(selectedItemsSet, itemsSet);
  }, [selectableItems, selectedItems]);

  const popoverOrigins = useMemo(
    () => ({
      anchorOrigin: anchorOrigin || {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: transformOrigin || {
        vertical: 'top',
        horizontal: 'left',
      },
    }),
    [anchorOrigin, transformOrigin],
  );
  const virtuosoHandle = useRef<VirtuosoHandle>(null);

  const [readyToRenderVirtuoso, setReadyToRenderVirtuoso] = useState(false);

  const shouldVirtualize = enableVirtualScroll && items.length > MIN_VIRTUALIZED_ITEMS;

  const renderItem = (item: AdvancedFilterItem) => {
    return (
      <FilterItem
        key={item.value}
        item={item}
        hideLabels={hideLabels}
        toggleItem={toggleItem}
        selectedItems={selectedItems}
        selectOnlyItem={selectOnlyItem}
        _texts={_texts}
      />
    );
  };

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      anchorOrigin={popoverOrigins.anchorOrigin}
      transformOrigin={popoverOrigins.transformOrigin}
      onClose={(event, reason) => {
        setReadyToRenderVirtuoso(false);
        onClose(event, reason);
      }}
      onAnimationEnd={() => {
        // Virtuoso needs to be rendered after the animation ends to avoid layout issues
        setTimeout(() => {
          setReadyToRenderVirtuoso(true);
        }, 100);
      }}
    >
      <Box width={'340px'}>
        {isShowAllFeatures && (
          <FlexBox
            justifyContent="space-between"
            padding="20px 20px 0px"
            alignItems="center"
            gap="12px"
          >
            <SearchInput
              withFixedMagnifyingGlassIcon
              TextFieldProps={{
                value: searchTxt,
                placeholder: 'Search',
                onChange: (e) => {
                  setSearchTxt(e.target.value);
                },
              }}
              onClearButtonClick={() => setSearchTxt('')}
            />

            <Button variant="flat" onClick={clearAll} disabled={areAllElementsSelected}>
              Select all
            </Button>
          </FlexBox>
        )}
        <FlexBox
          sx={{
            overflow: 'auto',
            maxHeight: '300px',
            height: shouldVirtualize ? '300px' : 'auto',
            padding: 0,
          }}
        >
          <List sx={{ width: '100%' }} dense>
            {!filteredItems.length && (
              <FlexBox justifyContent="center" py={2}>
                <Typography variant="body" color={colors.grey[500]}>
                  No results found
                </Typography>
              </FlexBox>
            )}
            {!shouldVirtualize && filteredItems.map((item) => renderItem(item))}
            {shouldVirtualize && readyToRenderVirtuoso && (
              <Virtuoso
                ref={virtuosoHandle}
                data={filteredItems}
                itemContent={(rowIndex, item) => renderItem(item)}
              />
            )}
          </List>
        </FlexBox>
      </Box>
    </Popover>
  );
}
