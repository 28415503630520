import { Box } from '@mui/material';
import { PluginDetail, VerticalFlexBox } from '../PluginDetail';
import { TemplatesPageHeader } from '../../dialog/TemplatesPageHeader';
import { CriteriaPluginSection } from './types';
import { DataTable__deprecated } from '../../../../../common/table/DataTable__deprecated';
import { DataTableBaseColumn } from '../../../../../common/table/TableTypes';
import { ConfigurationCanvasPanelContent } from '../../../../configuration-canvas-panel';

const columns: DataTableBaseColumn[] = [
  {
    field: 'fieldName',
    headerName: 'Name',
  },
  {
    field: 'apiName',
    headerName: 'API name',
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 120,
  },
];

export const BaseCriteriaPage = ({
  onBackClick,
  onAddPlugin,
  title,
  imageIcon,
  detailTitle,
  detailDescription,
  pluginData,
}: {
  onBackClick: () => void;
  onAddPlugin: () => void;
  title: string;
  imageIcon: React.ReactNode;
  detailTitle: string;
  detailDescription: React.ReactNode;
  pluginData: CriteriaPluginSection[];
}) => {
  const rows = pluginData
    .map((section) =>
      section.pluginFields.map(({ pluginFieldId, fieldName, apiName, type }) => ({
        id: pluginFieldId,
        fieldName,
        apiName,
        type,
      })),
    )
    .flat();
  return (
    <>
      <TemplatesPageHeader
        onBackClick={onBackClick}
        title={title}
        onButtonClick={onAddPlugin}
        buttonText="Add to Funnel"
        imageIcon={imageIcon}
      />
      <ConfigurationCanvasPanelContent>
        <VerticalFlexBox gap={2}>
          <PluginDetail gap={1} title={detailTitle} description={detailDescription} />
          <Box
            sx={{
              '.MuiTableBody-root .MuiTableCell-root': {
                borderBottom: 'none',
              },
            }}
          >
            <DataTable__deprecated columns={columns} rows={rows} rowTypographyVariant="caption" />
          </Box>
        </VerticalFlexBox>
      </ConfigurationCanvasPanelContent>
    </>
  );
};
