import { CanvasUnitedLeftMenu } from './left-menu/CanvasUnitedLeftMenu';
import { useConfigurationCanvas } from '../../configuration-canvas/public/useConfigurationCanvas';
import { useFunnelMapPage } from '../../funnel-map-flow/useFunnelMapPage';
import { CanvasUnitedRightMenu } from './CanvasUnitedRightMenu';
import { DashboardHeaderContainer } from './DashboardHeaderContainer';
import { useRunOnceWhenTruthy } from '../../../common/useRunOnceWhenTruthy';
import useAiAgentCenter from '../../../aiAgentCenter/useAiAgentCenter';

export const UnitedDashboardHeader = () => {
  const { appCrmOrgId, canvasFunnelMap } = useConfigurationCanvas();
  const { isFunnelMapEmpty, onAiAgentCenterClick } = useFunnelMapPage();
  const { isOpened } = useAiAgentCenter();

  //in case the agent center was opened in Dashboard, it should be opened in Canvas too
  useRunOnceWhenTruthy(() => {
    onAiAgentCenterClick();
  }, isOpened);

  return (
    <DashboardHeaderContainer>
      <CanvasUnitedLeftMenu crmOrgId={appCrmOrgId} funnelMapEmpty={isFunnelMapEmpty} />
      <CanvasUnitedRightMenu funnelMap={canvasFunnelMap} funnelMapEmpty={isFunnelMapEmpty} />
    </DashboardHeaderContainer>
  );
};
