import { Box } from '@mui/material';
import { MINIMIZED_FUNNEL_PREVIEW_HEIGHT } from '../../../../constants';
import { DEFAULT_VISIBILITY_MAP } from '../../../../constants/visibility';
import { colors } from '@sweep-io/sweep-design';
import { SweepCanvas } from '../../../sweep-canvas/SweepCanvas';
import { CanvasMode } from '../../../sweep-canvas/canvas-types';
import { useFunnelMapElements } from '../../../funnel-map-canvas/useFunnelMapElements';
import noop from 'lodash/noop';

export const FunnelMapPreview = ({
  funnelMap,
  sxProps,
  showPreviewBackground,
  fitViewTimeoutValue,
  canvasMode,
}: {
  funnelMap: FunnelMap;
  sxProps?: any;
  showPreviewBackground?: boolean;
  fitViewTimeoutValue?: number;
  canvasMode?: CanvasMode;
}) => {
  const { sweepNodes, sweepEdges, sweepGroups } = useFunnelMapElements({
    funnelMap,
    visibilityMap: DEFAULT_VISIBILITY_MAP,
    objectTypesByName: {},
  });

  return (
    <Box
      sx={{
        height: MINIMIZED_FUNNEL_PREVIEW_HEIGHT,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: `1px solid ${colors.grey[300]}`,
        ...sxProps,
      }}
      data-testid="funnel-preview"
    >
      {sweepNodes && sweepNodes.length > 0 && (
        <div style={{ width: '100%', height: '100%' }}>
          <SweepCanvas
            sweepGroups={sweepGroups}
            sweepNodes={sweepNodes}
            sweepEdges={sweepEdges}
            canvasMode={canvasMode ?? CanvasMode.PREVIEW1}
            showPreviewBackground={showPreviewBackground}
            fitViewTimeoutValue={fitViewTimeoutValue}
            visibilityMap={DEFAULT_VISIBILITY_MAP}
            onConnectClick={noop}
          />
        </div>
      )}
    </Box>
  );
};
