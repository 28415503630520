import { useContext } from 'react';
import { UnsavedPromptContext } from '../components/pages/funnel-map-flow/prompt/UnsavedPromptContext';
import { useBlocker } from 'react-router';

export function useUnsavedPrompt(when = true) {
  const { displayPrompt, setOnLeaveFunction, setOnCancelFunction } =
    useContext(UnsavedPromptContext);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && when,
  );

  if (blocker.state === 'blocked') {
    setOnCancelFunction(() => blocker.reset());
    setOnLeaveFunction(() => blocker.proceed());
    displayPrompt();
  }
}
