import { Stack } from '@mui/material';
import { AiAgentChatsSections } from './AiAgentChatsSections';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import AiAgentSearchResults from './AiAgentSearchResults';
import useAiAgentChats from './useAiAgentChats';

const AiAgentChatsHistory = () => {
  const {
    chatsListIsLoading,
    activeChat,
    setActiveChatId,
    chats,
    isLoadingSearch,
    searchResults,
    searchText,
  } = useAiAgentChats();

  const showSearchResults = isLoadingSearch || searchResults;

  return (
    <Stack
      sx={{
        height: '100%',
      }}
    >
      {chatsListIsLoading && <CenteredCircularProgress />}
      {!chatsListIsLoading && (
        <Stack
          sx={{
            height: '100%',
            gap: '16px',
          }}
        >
          {showSearchResults && (
            <AiAgentSearchResults
              isLoadingSearch={!!isLoadingSearch}
              searchResults={searchResults}
              searchText={searchText}
              activeChatId={activeChat?.data?.id}
              setActiveChatId={setActiveChatId}
            />
          )}
          {!showSearchResults && (
            <AiAgentChatsSections
              activeChatId={activeChat?.data?.id}
              setActiveChatId={setActiveChatId}
              chats={chats}
            />
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default AiAgentChatsHistory;
