import { Delete } from '@sweep-io/sweep-design/dist/icons';
import { ActionsMenu } from '../common/actions-menu/ActionsMenu';
import { Commands } from '../../types/enums/Common';
import usePermission from '../common/permissions/usePermission';
import { colors } from '@sweep-io/sweep-design';
import { AgentType } from '@server/ai';
import useConfirm from '../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import ConfirmDestructiveDialog from '../common/dialogs/ConfirmDestructiveDialog';
import useAiAgents from './useAiAgents';
import useSendBiEvent from '../../hooks/useSendBiEvent';
import { AI_AGENTS_EVENTS } from '../../services/events';

const AiAgentMenu = () => {
  const { openConfirm, onCancel, onConfirm } = useConfirm();
  const { deleteAgent, activeAgent } = useAiAgents();
  const [isAllowedDelete] = usePermission(['delete:ai-agent']);
  const sendBiEvent = useSendBiEvent();

  if (!activeAgent || activeAgent.type === AgentType.Documentation) {
    return null;
  }

  const actionsMenu = [
    {
      label: 'Delete agent',
      value: Commands.Delete,
      actionIcon: <Delete color={colors.blush[600]} />,
      actionSx: { color: colors.blush[600] },
      disabled: !isAllowedDelete,
      disabledTooltip: 'To delete this agent, please contact your admin',
    },
  ];

  const onActionMenuClick = async (actionName: string) => {
    switch (actionName) {
      case Commands.Delete:
        const isConfirmed = await openConfirm(
          <ConfirmDestructiveDialog
            onConfirm={onConfirm}
            onClose={onCancel}
            open={true}
            confirmText="Delete"
            title={`Are you sure you want to delete "${activeAgent.name}" agent?`}
          >
            By deleting "{activeAgent.name}" you’ll lose all your conversations with it.
          </ConfirmDestructiveDialog>,
        );
        if (isConfirmed) {
          sendBiEvent({ name: AI_AGENTS_EVENTS.deleteAgent });
          await deleteAgent(activeAgent.id);
        }
        break;
    }
  };

  return (
    <ActionsMenu
      actions={actionsMenu}
      onClick={(action) => onActionMenuClick(action.value)}
      iconSize="small"
    />
  );
};

export default AiAgentMenu;
