import { SxProps, TableRow as MuiTableRow, TableRowProps, Theme } from '@mui/material';
import {
  DataTableBaseColumn,
  DataTableDraggableColumn,
  DataTableRow,
  DataTableSortableColumn,
  DataTableVariant,
} from './TableTypes';
import { useMemo } from 'react';
import TableRowCells from './TableRowCells';
import { ACTION_BUTTONS_KEY, linearGradient } from './utils';
import { ActionCell } from './ActionCell';
import { colors } from '@sweep-io/sweep-design';
interface RowProps<TRow extends DataTableRow> {
  row: TRow;
  columns: DataTableSortableColumn<TRow>[] | DataTableBaseColumn[] | DataTableDraggableColumn[];
  sxRowFunction?: (isDragging: boolean) => SxProps<Theme>;
  tableRowProps?: TableRowProps;
  onClick?: (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    columnData: DataTableSortableColumn<TRow> | DataTableBaseColumn | DataTableDraggableColumn,
  ) => void;
  variant: DataTableVariant;
  hideChevronButton?: boolean;
  keepActionButtonsVisible?: boolean;
}

function TableRow<TRow extends DataTableRow = any>({
  row,
  columns,
  sxRowFunction,
  tableRowProps,
  onClick,
  variant,
  hideChevronButton,
  ...rowProps
}: RowProps<TRow>) {
  const sxRow = sxRowFunction ? sxRowFunction(Boolean(false)) : {};

  const _rowProps = useMemo(() => {
    const res = { ...rowProps };
    if ('onSortChange' in res) {
      delete res.onSortChange;
    }
    if ('defaultSortState' in res) {
      delete res.defaultSortState;
    }
    return res;
  }, [rowProps]);

  const actionButtons = row[ACTION_BUTTONS_KEY];
  return (
    <MuiTableRow
      data-testid="data-table-row"
      sx={{
        minHeight: '60px', //design team asked for it, if your design doesn't match please talk to them
        backgroundColor: row.isRowActive ? colors.grey[150] : 'transparent',
        '&:hover': {
          backgroundColor: colors.grey[150],
          '.action-buttons': {
            '&:before': {
              background: linearGradient(colors.grey[150]),
              visibility: 'visible',
            },
            '.custom-action-button': {
              visibility: 'visible',
            },
          },
        },
        '.action-buttons:before': {
          background: row.isRowActive ? linearGradient(colors.grey[150]) : undefined,
        },
        ...sxRow,
        animation: row._animation ? row._animation : '',
        cursor: row.isClickable ? 'pointer' : 'default',
        position: 'relative',
      }}
      className="SweepDataTableRow"
      {...tableRowProps}
      {..._rowProps}
    >
      <TableRowCells
        key={row.id}
        allowReorder={false}
        isRenderEmpty={false}
        row={row}
        columns={columns}
        onClick={
          !!onClick
            ? (
                e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
                column:
                  | DataTableSortableColumn<TRow>
                  | DataTableBaseColumn
                  | DataTableDraggableColumn,
              ) => onClick(e, column)
            : undefined
        }
        variant={variant}
      />
      {(!!onClick || actionButtons) && (
        <ActionCell
          row={row}
          variant={variant}
          columns={columns}
          sxRowFunction={sxRowFunction}
          tableRowProps={tableRowProps}
          onClick={onClick}
          hideChevronButton={hideChevronButton}
          {...rowProps}
        />
      )}
    </MuiTableRow>
  );
}

export default TableRow;
