import { styled } from '@mui/material/styles';
import { getObjectTypeColor } from '../../../helpers/getObjectTypeColor';
import StyledTooltip from '../../../../common/StyledTooltip';
import { colors } from '@sweep-io/sweep-design';
import * as Icons from '@sweep-io/sweep-design/dist/icons';
import { memo } from 'react';
import { Box } from '@mui/material';

export enum PillType {
  sweepAutomations = 'sweepAutomations',
  validationRule = 'validationRule',
  automation = 'automation',
  apex = 'apex',
  playbookAlerts = 'playbookAlerts',
  assignmentRules = 'assignmentRules',
  scheduledAssignments = 'scheduledAssignments',
  deduplication = 'deduplication',
  matching = 'matching',
  hubspotEnrollment = 'hubspotEnrollment',
  more = 'more',
}

interface NodePillData {
  type?: PillType;
  object?: string;
  children: React.ReactNode;
  tooltip: React.ReactNode;
  highlight?: boolean;
}

const Container = styled('div')(() => ({
  height: '24px',
  border: '2px solid transparent',
  borderRadius: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '5px',
}));

const pillsIconMap: {
  [key in PillType]: string;
} = {
  [PillType.sweepAutomations]: 'Automations',
  [PillType.validationRule]: 'ValidationRules',
  [PillType.automation]: 'SalesforceAutomations',
  [PillType.apex]: 'Apex',
  [PillType.playbookAlerts]: 'PlaybookAlert',
  [PillType.assignmentRules]: 'Assign',
  [PillType.scheduledAssignments]: 'Clock',
  [PillType.deduplication]: 'Copy',
  [PillType.matching]: 'Target',
  [PillType.more]: 'MoreActionsHorizontal',
  [PillType.hubspotEnrollment]: 'Enrollment',
};

const PillIcon = memo(({ type }: { type?: PillType }) => {
  if (!type) return null;

  const renderIcon = () => {
    const iconName = pillsIconMap[type];
    const PinIcon = (Icons as any)[iconName];

    return <PinIcon />;
  };
  //10px is not a size officially supported in the design system, this is a work-around
  return (
    <Box component="span" sx={{ svg: { width: '10px', height: '10px', display: 'flex' } }}>
      {renderIcon()}
    </Box>
  );
});

const NodePill = memo(({ type, children, object, tooltip, highlight }: NodePillData) => {
  const objectColor = getObjectTypeColor(object);
  let backgroundColor: string;

  switch (type) {
    case PillType.more:
      backgroundColor = colors.grey[120];
      break;
    default:
      backgroundColor = objectColor.light;
      break;
  }

  return (
    <StyledTooltip
      title={tooltip}
      placement="top"
      sx={{
        '& .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop': {
          // material ui has a different css selector with `margin-bottom: 14px` and it wins by specificity, therefore adding `!important` to override that bastard.
          marginBottom: '4px !important',
        },
      }}
    >
      <Container
        className={highlight ? 'highlight' : ''}
        sx={{
          backgroundColor,
          '&:hover, &.highlight': {
            borderColor: colors.blue[500],
          },
          cursor: 'pointer',
        }}
      >
        <PillIcon type={type} />
        {children}
      </Container>
    </StyledTooltip>
  );
});

const memoizedNodePill = memo(NodePill);
export default memoizedNodePill;
