import { PotentialIssue } from '@server/homepage';
import { Button } from '@sweep-io/sweep-design';
import { appRoutes } from '../../../../constants/appRoutes';
import buildURLQuery from '../../../../lib/buildURLQuery';
import { sendBiEvent } from '../../../../services/bi';
import { HOMEPAGE_EVENTS } from '../../../../services/events';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { selectDefaultCreationCrmOrgId } from '../../../../reducers/userInfoReducer';
import { ButtonSize, ButtonVariants } from '@sweep-io/sweep-design/dist/components/Button/types';

interface ExploreWithAiButtonProps {
  issue: PotentialIssue;
  buttonVariant?: ButtonVariants;
  buttonSize?: ButtonSize;
}

export const ExploreWithAiButton = ({
  issue,
  buttonVariant = 'flat',
  buttonSize = 'tiny',
}: ExploreWithAiButtonProps) => {
  const currentCrmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  const crmOrgId = currentCrmOrgId ?? '';
  const navigate = useNavigate();

  return (
    <Button
      variant={buttonVariant}
      size={buttonSize}
      onClick={(event) => {
        event.stopPropagation();
        const queryParams = buildURLQuery({
          configurationType: issue.configurationType,
          configurationName: issue.configurationName,
          objectName: issue.objectName,
          apexClassParentId: issue.apexClassParentId,
        });
        sendBiEvent({ name: HOMEPAGE_EVENTS.exploreWithAi });
        navigate(`${appRoutes.documentation.route}/${crmOrgId}?${queryParams}`);
      }}
    >
      Explore with AI
    </Button>
  );
};
