import { useReactFlow, XYPosition } from '@xyflow/react';
import { useCallback } from 'react';

export const useGetNodeAbsolutePosition = () => {
  const { getNode } = useReactFlow();

  const getAbsolutePosition = useCallback(
    (id: string): XYPosition | undefined => {
      const node = getNode(id);
      if (!node) return undefined;

      const position = { ...node.position };

      if (node.parentId) {
        const parentPosition = getAbsolutePosition(node.parentId);
        if (!parentPosition) return undefined;
        position.x += parentPosition.x;
        position.y += parentPosition.y;
      }

      return position;
    },
    [getNode],
  );

  return getAbsolutePosition;
};
