import Box from '@mui/material/Box';
import { IconButton, Typography, colors } from '@sweep-io/sweep-design';
import StyledTooltip from '../common/StyledTooltip';
import { Delete as DeleteListIcon } from '@sweep-io/sweep-design/dist/icons';
import { CustomHeader } from '../common/rule-builder/CustomHeader';
import { SxProps } from '@mui/material';
import { SweepFieldsRuleBuilder } from '../common/sweep-condition-rule-builder/SweepConditionRuleBuilder';
import { RefObject } from 'react';
import { SweepConditionRuleBuilderRef } from '../common/sweep-condition-rule-builder/sweep-condition-rule-builder-types';

export const labelBoxDesign = {
  width: 'fit-content',
  borderRadius: '4px',
  height: '24px',
  marginBottom: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: '0 8px',
};

export const ConditionsWrapper = ({
  removeConditionsToTrigger,
  customHeader,
  ruleBuilderProps,
  sx,
}: {
  removeConditionsToTrigger: () => void;
  customHeader: string;
  ruleBuilderProps: {
    showFieldContextMenu: boolean | undefined;
    ref: RefObject<SweepConditionRuleBuilderRef>;
    readOnly: boolean;
    crmOrgId: string;
    objectType: string;
    sweepCondition?: SweepCondition;
    onChange: (newSweepCondition: SweepCondition) => void;
  };
  sx?: SxProps;
}) => {
  return (
    <Box sx={{ marginBottom: '16px', ...(sx ?? {}) }}>
      <Box
        sx={{
          display: 'inline-flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          gap: '17px',
          height: '24px',
          mb: '8px',
          '&:hover .automationTriggerCriteria': {
            display: 'block',
          },
        }}
      >
        <Box
          sx={{
            ...labelBoxDesign,
            background: colors.lilac[500],
          }}
        >
          <Typography variant="caption-bold" textAlign="center" color={colors.white}>
            AND
          </Typography>
        </Box>
        <Box className="automationTriggerCriteria" sx={{ display: 'none' }}>
          <StyledTooltip title="Delete conditions" placement="top">
            <span>
              <IconButton variant="flat" size="tiny" onClick={removeConditionsToTrigger}>
                <DeleteListIcon />
              </IconButton>
            </span>
          </StyledTooltip>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: colors.grey[100],
          paddingTop: '12px',
          borderRadius: '6px',
        }}
      >
        <CustomHeader customHeader={customHeader} />
        <SweepFieldsRuleBuilder {...ruleBuilderProps} />
      </Box>
    </Box>
  );
};
