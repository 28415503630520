import SweepAvatar from './sweepAvatar.svg?react';
import styled from '@mui/material/styles/styled';
import { colors } from '@sweep-io/sweep-design';
import { ReactNode } from 'react';
import { Stack, Box } from '@mui/material';
import ChatLoader from './ChatLoader';
import { MarkdownWrapper } from '../../MarkdownWrapper';
import { useRunOnce } from '../../useRunOnce';

export const AiChatAssistantMessageBox = ({
  content,
  onMount,
  errorState = false,
  isLoading = false,
  avatar = <SweepAvatar />,
}: {
  content: string | ReactNode;
  onMount?: () => void;
  isLoading?: boolean;
  errorState?: boolean;
  avatar?: ReactNode;
}) => {
  useRunOnce(() => {
    onMount?.();
  });

  return (
    <Stack gap={1} data-message-type="assistant">
      <Box display="flex" gap={0.5} alignItems="flex-start">
        <Box display="flex" flex="0 0 24px">
          {avatar}
        </Box>
        {isLoading && (
          <Box alignSelf="center" pl={1.5}>
            <ChatLoader />
          </Box>
        )}
        {!isLoading && (
          <AssistantMessageBox
            sx={{
              pr: 1.5,
              pl: 1.5,
            }}
          >
            <MarkdownWrapper
              content={content}
              color={errorState ? colors.blush[600] : undefined}
              removeMargins={true}
            />
          </AssistantMessageBox>
        )}
      </Box>
    </Stack>
  );
};

const AssistantMessageBox = styled(Box)({
  maxWidth: '100%',
});
