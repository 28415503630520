import { useCallback, useContext, useMemo } from 'react';
import { OnNodeClickProps, NodeEntityTypes } from '../../sweep-canvas/canvas-types';
import { FunnelMapCanvasInternalCtx } from '../FunnelMapCanvasInternalCtx';
import { UseEventHandlerReturn } from './types';
import keyBy from 'lodash/keyBy';
import SweepStagesModel from '../../../models/stagesModel';
export const useSfFunnelsEventHandler = (): UseEventHandlerReturn => {
  const {
    onFunnelStepClick,
    onFunnelLabelClick,
    onFunnelOverlayClick,
    onFunnelLabelPillClick,
    onStageGateClick,
    funnelMap,
  } = useContext(FunnelMapCanvasInternalCtx);

  const funnelsData = useMemo(() => funnelMap.funnelsData, [funnelMap]);

  const funnelDetailsStages = useMemo(
    () =>
      Object.keys(funnelsData)
        .map((funnelId) => funnelsData[funnelId].funnelDetails.stages)
        .flat(),
    [funnelsData],
  );

  const sweepStagesByKey = useMemo(
    () => keyBy(funnelDetailsStages, '_stageId'),
    [funnelDetailsStages],
  );

  const findExitCriteriaByTarget = useCallback(
    (sourceStageId: string, targetStageId: string) =>
      sweepStagesByKey[sourceStageId].exitCriteria.find(
        (exitCriteria) => exitCriteria._nextStageId === targetStageId,
      ),
    [sweepStagesByKey],
  );

  const onNodeClick = useCallback(
    ({ id, parentId, entity, event }: OnNodeClickProps) => {
      switch (entity.type) {
        case NodeEntityTypes.Step:
        case NodeEntityTypes.StepPill: {
          if (!parentId) {
            throw new Error('parentId was not found');
          }
          onFunnelStepClick?.({ stepId: id, funnelId: parentId, entity, event });
          break;
        }
        case NodeEntityTypes.GroupLabel:
          onFunnelLabelClick?.({ funnelId: id, entity, event });
          break;
        case NodeEntityTypes.GroupOverlay:
          onFunnelOverlayClick?.(id);
          break;
      }
    },
    [onFunnelLabelClick, onFunnelOverlayClick, onFunnelStepClick],
  );

  const onPillClick = useCallback(
    ({ id, parentId, entity, event }: OnNodeClickProps) => {
      switch (entity.type) {
        case NodeEntityTypes.StepPill: {
          if (!parentId) {
            throw new Error('parentId was not found');
          }
          onFunnelStepClick?.({ stepId: id, funnelId: parentId, entity, event });
          break;
        }
        case NodeEntityTypes.GroupPill: {
          onFunnelLabelPillClick?.({ funnelId: id, entity, event });
          break;
        }
      }
    },
    [onFunnelLabelPillClick, onFunnelStepClick],
  );

  const onGateClick = useCallback(
    (sourceNodeId: string, targetNodeId: string, sourceNodeParentId: string) => {
      if (!onStageGateClick) return;
      const exitCriteria = findExitCriteriaByTarget(sourceNodeId, targetNodeId);
      const objectName = funnelsData[sourceNodeParentId]?.funnelDetails.leadingObject.objectName;
      const stagesModel = new SweepStagesModel(
        funnelsData[sourceNodeParentId]?.funnelDetails.stages || [],
      );
      const stageModel = stagesModel.stageByIdOrUndefined(sourceNodeId);

      if (!exitCriteria || !stageModel || !objectName) return;

      onStageGateClick(sourceNodeParentId, sourceNodeId, exitCriteria._exitCriteriaId);
    },
    [findExitCriteriaByTarget, funnelsData, onStageGateClick],
  );

  const onLabelClick = useCallback(
    ({ id, entity, event }: OnNodeClickProps) => {
      switch (entity.type) {
        case NodeEntityTypes.GroupLabel:
          onFunnelLabelClick?.({ funnelId: id, entity, event });
          break;
        default:
          break;
      }
    },
    [onFunnelLabelClick],
  );

  const onNodeNameChange = useCallback(
    (name: string, funnelId: string) => {
      const stagesModel = new SweepStagesModel(funnelsData[funnelId]?.funnelDetails.stages || []);
      return stagesModel.validateStageName(name.trim());
    },
    [funnelsData],
  );

  const returnFunctions = useMemo(
    () => ({ onNodeClick, onPillClick, onGateClick, onLabelClick, onNodeNameChange }),
    [onNodeClick, onPillClick, onGateClick, onLabelClick, onNodeNameChange],
  );

  return returnFunctions;
};
