import { useEffect } from 'react';
import { useCalculateLastUpdate } from './useCalculateLastUpdate';
import { setHasUnsavedChanges } from '../../../../reducers/multiFunnelFlowNoHistoryReducer';
import { useDispatch } from 'react-redux';
import { useAutoSaveContext } from './AutoSaveContext';
import { useFunnelMapAutoSave } from './useFunnelMapAutoSave';
import { useSfFunnelsAutoSave } from './useSfFunnelsAutoSave';
import { useThirdPartyFunnelAutoSave } from './useThirdPartyFunnelAutoSave';

export type FunnelMapSaveData = Pick<FunnelMap, 'id' | 'funnels' | 'name' | 'thirdPartyFunnels'>;

export interface AutoSaveProps {
  funnelMap: FunnelMap;
  // funnelsData: FunnelsData;
  // thirdPartyFunnelsData: ThirdPartyFunnelDataMap;
  readonly?: boolean;
  crmOrgId?: string | null;
  isButtonHidden?: boolean;
}

export const useAutoSave = ({
  funnelMap,
  // funnelsData,
  crmOrgId,
  // thirdPartyFunnelsData,
}: AutoSaveProps) => {
  const dispatch = useDispatch();
  const { dirtyObjectsExists, hasAnyErrors, isSaving } = useAutoSaveContext();
  const { funnelsData, thirdPartyFunnelsData } = funnelMap;

  const { lastUpdated, setLastUpdatedFor } = useCalculateLastUpdate({
    funnelsData,
    funnelMapLastUpdatedAt: funnelMap.updatedAt,
  });

  useEffect(() => {
    dispatch(setHasUnsavedChanges({ hasUnsavedChanges: dirtyObjectsExists }));
  }, [dirtyObjectsExists, dispatch]);

  useFunnelMapAutoSave({
    funnelMap: funnelMap,
    setLastUpdatedFor,
  });

  useSfFunnelsAutoSave({
    funnelsData,
    crmOrgId,
    setLastUpdatedFor,
  });

  useThirdPartyFunnelAutoSave({
    thirdPartyFunnelsData,
    setLastUpdatedFor,
  });

  return {
    isAutoSaving: isSaving,
    autoSavingError: hasAnyErrors,
    updatedAt: lastUpdated ? new Date(lastUpdated) : undefined,
  };
};
