import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setOrgAnalysis, setOrgAnalysisLoader } from '../../../../reducers/homepageReducer';
import { useHomepageApiFacade } from '../../../../apis/facades/useHomepageApiFacade';
import { sortByType } from './utils';
import { Homepage } from '@server/homepage';
import { telemetry } from '../../../../telemetry';

export const useOrgAnalysis = () => {
  const { get_homepageData } = useHomepageApiFacade();
  const dispatch = useDispatch();

  const fetchOrgAnalysis = useCallback(
    async (crmOrgId: string) => {
      dispatch(setOrgAnalysisLoader({ crmOrgId, isLoading: true }));
      try {
        const data = await get_homepageData();
        if (!data?.hasOwnProperty('status')) {
          const _data = data as Homepage;
          dispatch(
            setOrgAnalysis({
              crmOrgId,
              potentialIssues: _data.potentialIssues,
              isLoading: false,
              orgAnalysisCount: sortByType(_data.counters),
            }),
          );
        }
      } catch (e) {
        telemetry.captureError(e);
        dispatch(
          setOrgAnalysis({
            crmOrgId,
            isLoading: false,
            isError: true,
          }),
        );
      }
    },
    [dispatch, get_homepageData],
  );
  return { fetchOrgAnalysis };
};
