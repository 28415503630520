import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { AutomationDialogContent } from '../Automations/AutomationDialogContent';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { useSelector } from 'react-redux';
import { selectDefaultCreationEnvironment } from '../pages/environments/environmentsReducer';
import useAutomationApiPerType from '../Automations/useAutomationApiPerType';
import { selectAlerts } from '../../reducers/global/alertsReducers';
import { automationsSortOptions } from '../pages/canvas-pages/consts';
import { useGetAutomationFilters } from '../pages/canvas-pages/automationFilters';
import { AutomationAlertsContextProvider } from './AutomationAlertsContextProvider';
import { WithConfigurationCanvas } from '../pages/configuration-canvas/WithConfigurationCanvas';
import { VisibilityMap } from '../../types/VisibilityTypes';
import { VisibilityLayers } from '../../types/enums/VisibilityLayers';
import { VisibilityElementMap } from '../pages/configuration-canvas/types';
import useEntitiesToHighlight from '../pages/canvas-pages/useEntitiesToHighlight';
import useAutomationsCanvasInteractions from '../pages/canvas-pages/useAutomationsCanvasInteractions';

interface AlertsProps {
  recordTypesData: RecordTypesData;
  funnelsData: FunnelsData;
  disableCanvasTemplates?: boolean;
  setHoveredItem?: (automationId?: string | undefined) => void;
}

const alertsPageVisibilityMap: Partial<VisibilityMap> = {
  [VisibilityLayers.NURTURING_STEPS]: true,
  [VisibilityLayers.COMMENTS]: false,
  [VisibilityLayers.PLAYBOOK_ALERTS]: true,
  [VisibilityLayers.FUNNEL_PLAYBOOK_ALERTS]: true,
};
const visibilityElements: VisibilityElementMap = {
  type: 'map',
  visibilityMap: alertsPageVisibilityMap,
};

const Alerts = ({
  recordTypesData,
  funnelsData,
  disableCanvasTemplates,
  setHoveredItem,
}: AlertsProps) => {
  const alerts = useSelector(selectAlerts);
  const crmOrg = useSelector(selectDefaultCreationEnvironment);
  const { onSaveOrCreate, onToggleActivation, onDeleteAutomation, onDeployAutomation } =
    useAutomationApiPerType({ automationVariant: AutomationFormVariant.PLAYBOOK_ALERT });
  const filters = useGetAutomationFilters(alerts);

  if (!alerts) return <CenteredCircularProgress />;

  return (
    <>
      {crmOrg && (
        <AutomationDialogContent
          automations={alerts ?? []}
          onDeleteAutomation={onDeleteAutomation}
          crmOrgId={crmOrg.id}
          onSaveOrCreate={onSaveOrCreate}
          recordTypesData={recordTypesData}
          funnelsData={funnelsData}
          onToggleActivation={onToggleActivation}
          sortOptions={automationsSortOptions}
          disableCanvasTemplates={disableCanvasTemplates}
          setHoveredItem={setHoveredItem}
          onDeployAutomation={onDeployAutomation}
          filtersForExpandedMode={filters}
        />
      )}
    </>
  );
};

const AlertsWithContext = (alertsProps: AlertsProps) => {
  const { onEntityClick } = useAutomationsCanvasInteractions();
  const automationsVariant = AutomationFormVariant.PLAYBOOK_ALERT;
  const { entitiesToHighlight } = useEntitiesToHighlight(automationsVariant);
  return (
    <WithConfigurationCanvas
      visibilityElements={visibilityElements}
      onFunnelStepClick={onEntityClick}
      onRecordTypeStepClick={onEntityClick}
      onFunnelLabelPillClick={onEntityClick}
      onRecordTypeLabelPillClick={onEntityClick}
      highlightEntities={entitiesToHighlight}
    >
      <AutomationAlertsContextProvider>
        <Alerts {...alertsProps} />
      </AutomationAlertsContextProvider>
    </WithConfigurationCanvas>
  );
};

export default AlertsWithContext;
