import { ScheduledParametersOffsetType } from '../../types/enums/AutomationTypes';
import { useState } from 'react';
import { DateTime } from 'luxon';
import {
  Box,
  MenuItem,
  SelectChangeEvent,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';
import SweepSelect from '../common/SweepSelect';
import { TimeSelector } from '../common/rule-builder/selectors/TimeSelector';
import { TimezoneSelector } from '../common/timezone-selector/TimezoneSelector';
import { WeekDay } from '../../reducers/assignmentGroupTypes';
import { DatePickerElement } from '../common/datepicker/DatePickerElement';
import StartDatePicker from './StartDatePicker';

const AutomationScheduleRecurring = ({
  schedule,
  setSchedule,
  readonly,
  removeEnd,
  addMinutesHoursFrameTypes,
}: {
  schedule?: AutomationSchedule;
  setSchedule: (partialSchedule: Partial<AutomationSchedule>) => void;
  readonly?: boolean;
  removeEnd?: boolean;
  addMinutesHoursFrameTypes?: boolean;
}) => {
  const { offset, endDate, startDate, executeTime, timezone } = schedule ?? {};
  const isOffsetTypeYear = offset?.offsetType === ScheduledParametersOffsetType.YEAR;
  const [isShowEmptyEnd, setIsShowEmptyEnd] = useState(!startDate);
  const [isShowEnd, setIsShowEnd] = useState(!isShowEmptyEnd && !!endDate);

  const isEndDateError = () => {
    let isError = false;
    if (startDate && endDate) {
      if (DateTime.fromISO(startDate) >= DateTime.fromISO(endDate)) {
        isError = true;
      }
    }
    return isError;
  };

  const timeFrameTypes = [
    {
      key: ScheduledParametersOffsetType.DAY,
      value: ScheduledParametersOffsetType.DAY,
      listDisplay: 'Day',
      boxDisplay: 'Day(s)',
    },
    {
      key: ScheduledParametersOffsetType.WEEK,
      value: ScheduledParametersOffsetType.WEEK,
      listDisplay: 'Week',
      boxDisplay: 'Week(s)',
    },
    {
      key: ScheduledParametersOffsetType.MONTH,
      value: ScheduledParametersOffsetType.MONTH,
      listDisplay: 'Month',
      boxDisplay: 'Month(s)',
    },
    {
      key: ScheduledParametersOffsetType.YEAR,
      value: ScheduledParametersOffsetType.YEAR,
      listDisplay: 'Year',
      boxDisplay: 'Year(s)',
    },
  ];
  if (addMinutesHoursFrameTypes) {
    timeFrameTypes.unshift(
      {
        key: ScheduledParametersOffsetType.MINUTE,
        value: ScheduledParametersOffsetType.MINUTE,
        listDisplay: 'Minute',
        boxDisplay: 'Minutes(s)',
      },
      {
        key: ScheduledParametersOffsetType.HOUR,
        value: ScheduledParametersOffsetType.HOUR,
        listDisplay: 'Hour',
        boxDisplay: 'Hour(s)',
      },
    );
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 1 }}>
        <Typography variant="body" textAlign="center">
          Repeat every
        </Typography>

        <TextField
          error={offset?.offsetValue !== undefined && offset?.offsetValue < 1}
          type={'number'}
          value={offset?.offsetValue ?? ''}
          onChange={(event: { target: { value: any } }) => {
            const val = parseInt(event.target.value);
            const offset = { ...schedule?.offset, offsetValue: val };
            setSchedule({ offset: offset as ScheduleOffset });
          }}
          InputProps={{
            inputProps: { min: 1 },
            sx: {
              maxWidth: '75px',
            },
          }}
          placeholder={'Value'}
        />

        <SweepSelect
          FormControlProps={{
            sx: { minWidth: '120px' },
          }}
          menuMaxHeight={'360px'}
          SelectProps={{
            placeholder: 'Choose time frame',
            disabled: readonly,
            value: offset?.offsetType ?? '',
            onChange: (event: SelectChangeEvent<unknown>) => {
              const val = event.target.value;
              const offset = { ...schedule?.offset, offsetType: val };
              setSchedule({ offset: offset as ScheduleOffset });
            },
            renderValue: (val: any) => {
              if (val) {
                return timeFrameTypes.find((el) => el.value === val)?.boxDisplay;
              }
            },
          }}
        >
          {timeFrameTypes.map((Item) => {
            return (
              <MenuItem key={Item.key} value={Item.value}>
                {Item.listDisplay}
              </MenuItem>
            );
          })}
        </SweepSelect>

        {offset?.offsetType &&
          (offset?.offsetType === ScheduledParametersOffsetType.MINUTE ||
            offset?.offsetType === ScheduledParametersOffsetType.HOUR ||
            offset?.offsetType === ScheduledParametersOffsetType.MONTH ||
            offset?.offsetType === ScheduledParametersOffsetType.YEAR) && (
            <>
              <Typography variant="body" textAlign="center">
                from day
              </Typography>
              <StartDatePicker
                textFieldSx={{ maxWidth: '144px' }}
                startDate={startDate}
                callback={(value: string) => setSchedule({ startDate: value })}
              />
            </>
          )}

        <Typography variant="body" textAlign="center">
          at
        </Typography>
        <TimeSelector
          initialValue={executeTime}
          timePickerSx={{
            boxShadow:
              'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px',
            '& .MuiDialogActions-root': {
              padding: 1,
            },
            '& .MuiMultiSectionDigitalClock-root .Mui-selected.MuiMultiSectionDigitalClockSection-item':
              {
                paddingRight: 1,
                '&::after': {
                  content: 'none',
                },
              },
          }}
          textFieldSx={{ maxWidth: '144px' }}
          shouldBeDisabled={false}
          onChange={(value: string) => {
            setSchedule({ executeTime: value.slice(0, -4) });
          }}
        />
        <TimezoneSelector
          timezone={timezone}
          onChange={(timezone) => {
            setSchedule({ timezone: timezone });
          }}
        />
      </Box>
      {offset?.offsetType === ScheduledParametersOffsetType.WEEK && (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 1 }}>
          <Typography variant="body" textAlign="center">
            On days
          </Typography>

          <ToggleButtonGroup
            value={(schedule?.offset as ScheduleWeekOffset)?.weekDays}
            onChange={(event: React.MouseEvent<HTMLElement>, newDays: string[]) => {
              const offset = { ...(schedule?.offset as ScheduleWeekOffset), weekDays: newDays };
              setSchedule({ offset: offset as ScheduleOffset });
            }}
            sx={{
              padding: '2px 0',
              gap: 1,
              '& .MuiToggleButtonGroup-grouped.MuiToggleButtonGroup-groupedHorizontal': {
                border: `2px solid ${colors.white}`,
                background: colors.grey[120],
                color: colors.grey[800],
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                ml: 0,
                '&:hover': {
                  background: colors.grey[400],
                },
                '&.Mui-selected': {
                  color: colors.blue[500],
                  background: colors.blue[100],
                  ml: 0,
                },
              },
              '& .MuiToggleButtonGroup-grouped.Mui-selected+.MuiToggleButtonGroup-grouped.Mui-selected':
                { borderLeft: `2px solid ${colors.white}` },
            }}
          >
            <ToggleButton value={WeekDay.Sunday}>S</ToggleButton>
            <ToggleButton value={WeekDay.Monday}>M </ToggleButton>
            <ToggleButton value={WeekDay.Tuesday}>T</ToggleButton>
            <ToggleButton value={WeekDay.Wednesday}> W</ToggleButton>
            <ToggleButton value={WeekDay.Thursday}> T</ToggleButton>
            <ToggleButton value={WeekDay.Friday}> F</ToggleButton>
            <ToggleButton value={WeekDay.Saturday}> S</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}

      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        {(offset?.offsetType === ScheduledParametersOffsetType.DAY ||
          offset?.offsetType === ScheduledParametersOffsetType.WEEK) && (
          <>
            <Typography variant="body" textAlign="center">
              Starts on
            </Typography>
            <StartDatePicker
              startDate={startDate}
              callback={(value: string) => setSchedule({ startDate: value })}
            />
          </>
        )}
        {!removeEnd && (
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="body" textAlign="center">
              ends
            </Typography>

            <SweepSelect
              FormControlProps={{
                sx: { minWidth: '120px' },
              }}
              menuMaxHeight={'360px'}
              SelectProps={{
                placeholder: 'Choose value',
                disabled: readonly,
                value: isShowEmptyEnd ? '' : isShowEnd ? 'On' : 'Never',
                onChange: (event: SelectChangeEvent<unknown>) => {
                  setIsShowEmptyEnd(false);
                  const val = event.target.value;
                  if (val === 'Never') {
                    setIsShowEnd(false);
                    setSchedule({ endDate: undefined });
                  } else {
                    setIsShowEnd(true);
                  }
                },
                renderValue: (val: any) => {
                  if (val) {
                    return val === 'Never' ? 'Never' : `On ${isOffsetTypeYear ? 'year' : 'day'}`;
                  }
                },
              }}
            >
              <MenuItem key={'Never'} value={'Never'}>
                Never
              </MenuItem>
              <MenuItem key={'On'} value={'On'}>
                {`On ${isOffsetTypeYear ? 'year' : 'day'}`}
              </MenuItem>
            </SweepSelect>

            {isShowEnd && (
              <DatePickerElement
                isError={isEndDateError()}
                minDate={startDate ? DateTime.fromISO(startDate) : undefined}
                views={isOffsetTypeYear ? ['year'] : undefined}
                disablePast
                value={endDate ? DateTime.fromISO(endDate) : null}
                callback={(value: DateTime<boolean> | null) => {
                  let _date = value?.toISODate();
                  if (isOffsetTypeYear) {
                    _date = value?.year + '-12-31';
                  }
                  setSchedule({ endDate: _date as string });
                }}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AutomationScheduleRecurring;
