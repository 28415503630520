import { useCallback } from 'react';
import useObjectTypesWithFetch from '../../../hooks/useObjectTypesWithFetch';
import { TableWithHeader, TableWithHeaderProps } from './shared/TableWithHeader';
import { DependenciesContentViewType, NewDependencyProps } from '../dependencies/types';
import { ConfigurationType } from '@server/parser';
import pluralize from 'pluralize';
import { TableTypography } from './shared/helpers';
import { humanizeDate } from '../../helpers/humanizeDate';
import groupBy from 'lodash/groupBy';
import { DataTableRow } from '../../common/table/TableTypes';

interface CpqTablesItem {
  id: string;
  name: string;
  lastModified: string;
  label: string;
  type?: ConfigurationType;
  objectName?: string;
}

interface CpqTablesProps extends Pick<TableWithHeaderProps, 'headerVariant'> {
  items: CpqTablesItem[];
  crmOrgId: string;
  clearHistory: boolean;
  onItemClick: (props: NewDependencyProps) => void;
  activeItemId?: string;
}

const columns = [
  { field: 'label', headerName: 'Name', width: '215px' },
  { field: 'lastModified', headerName: 'Last Modified' },
];

const renderRows = ({ items, activeItemId }: { items: CpqTablesItem[]; activeItemId?: string }) => {
  return items.map((row) => ({
    id: row.id,
    name: row.name,
    objectName: row.objectName,
    label: <TableTypography>{row.label}</TableTypography>,
    lastModified: (
      <TableTypography>{humanizeDate({ dateOrTimestamp: row.lastModified })}</TableTypography>
    ),
    isRowActive: activeItemId === row.id || activeItemId === row.name,
    isClickable: true,
  }));
};

export const CpqTables = ({
  items,
  crmOrgId,
  clearHistory,
  onItemClick,
  activeItemId,
  ...props
}: CpqTablesProps) => {
  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId });
  const cpqMap = groupBy(items, 'objectName');

  const onRowClick = useCallback(
    (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, row: DataTableRow) => {
      onItemClick({
        id: row.id,
        name: row.name,
        parentType: ConfigurationType.cpqData,
        dependencyType: objectTypesByName[row.objectName]?.label ?? row.objectName,
        objectName: row.objectName,
        clearHistory,
        contentType: DependenciesContentViewType.default,
      });
    },
    [onItemClick, clearHistory, objectTypesByName],
  );

  return (
    <>
      {Object.keys(cpqMap).map((objectName) => {
        const tableHeader = objectTypesByName[objectName]?.label ?? objectName;
        const _items = renderRows({
          items: cpqMap?.[objectName] ?? [],
          activeItemId,
        });
        return (
          <TableWithHeader
            {...props}
            key={`tableHeader_${tableHeader}`}
            tableHeader={pluralize(tableHeader, _items.length)}
            columns={columns}
            rows={_items}
            onRowClick={onRowClick}
          />
        );
      })}
    </>
  );
};
