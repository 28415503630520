import { DateTime } from 'luxon';
import { useState, useCallback } from 'react';
import { AiChatContextType, ChatCompletionRequestMessageRole } from '@server/ai';
import { useAiChat } from '../../common/ai-chat/useAiChat';
import { telemetry } from '../../../telemetry';
import { uniqueId } from '../../../lib/uniqueId';
import { AiChatMessage, ImpactAnalysisChatPayload } from '../../common/ai-chat/aiChatTypes';

interface OnConfirmProps {
  message: string;
  automation: DeepPartial<AutomationStructureNew>;
}

export const useImpactAnalysisChat = () => {
  const { onSendImpactAnalysisMessage } = useAiChat();
  const [sessionId, setSessionId] = useState(uniqueId());
  const [messages, setMessages] = useState<AiChatMessage[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const onConfirm = useCallback(
    async ({ message, automation }: OnConfirmProps) => {
      error && setError('');
      const createdAt = DateTime.now().toISO() ?? '';
      const _messages: AiChatMessage[] = [
        ...messages,
        { role: ChatCompletionRequestMessageRole.USER, content: message, createdAt },
      ];
      setMessages(_messages);
      setIsLoading(true);
      const payload: ImpactAnalysisChatPayload = {
        message,
        sessionId,
        context: {
          automationProperties: automation,
          type: AiChatContextType.AUTOMATION,
        },
        isFirstMessage: messages.length === 0,
      };

      try {
        const response = await onSendImpactAnalysisMessage({
          payload,
        });
        setMessages([..._messages, response]);
      } catch (e) {
        setError('Something went wrong, please try again later');
        telemetry.captureError(e);
      }

      setIsLoading(false);
    },
    [messages, onSendImpactAnalysisMessage, sessionId, error],
  );

  const reset = useCallback(() => {
    setMessages([]);
    setError('');
    setSessionId(uniqueId());
    setIsLoading(true);
  }, []);

  return {
    isLoading,
    messages,
    error,
    onConfirm,
    reset,
  };
};
