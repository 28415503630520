import { Box, Paper, Stack } from '@mui/material';
import { colors, IconButton, Typography } from '@sweep-io/sweep-design';
import React, { useEffect, useState } from 'react';
import ImportFunnelImg from './img/import-funnel.svg?react';
import AddSweepTemplateImg from './img/add-sweep-template.svg?react';
import CreateFunnelImg from './img/create-funnel.svg?react';
import AddThirdPartyFunnelImg from './img/add-third-party.svg?react';
import { useHandleCommentsAndAiFirst } from '../left-menu/useHandleCommentsAndAiFirst';
import { usePanels } from '../../../../panels/usePanels';
import { importFunnelPanelType } from '../../../funnel-map-flow/dialogs/import-funnel/importFunnelPanelType';
import { useDispatch, useSelector } from 'react-redux';
import { openNewFunnelDialog } from '../../../../../reducers/newFunnelReducer';
import { useConfigurationCanvasContext } from '../../../configuration-canvas/ConfigurationCanvasContext';
import { setAddOrImportDialogOpen } from '../../../../third-party-funnels/thirdPartyFunnelsReducer';
import { templatesPanelType } from '../../../funnel-map-flow/templates/dialog/panelTypes';
import { Close } from '@sweep-io/sweep-design/dist/icons';
import { selectIsFunnelMapEmpty } from '../../../../../reducers/united-canvas/unitedCanvasReducer';

const ZeroStateItem = ({
  children,
  image,
  onClick,
}: {
  image: React.ReactNode;
  children: React.ReactNode | React.ReactNode[];
  onClick: () => void;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
        border: `1px solid ${colors.grey[300]}`,
        borderRadius: '4px',
        backgroundColor: colors.white,
        justifyContent: 'space-between',
        padding: 2,
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body-medium" color={colors.night[900]}>
          {children}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>{image}</Box>
    </Box>
  );
};

export const ZeroStateRightPanel = () => {
  const { appCrmOrgId } = useConfigurationCanvasContext();
  const dispatch = useDispatch();
  const handleCommentsAndAiFirst = useHandleCommentsAndAiFirst();
  const { maybeOpenPanel } = usePanels();
  const isFunnelMapEmpty = useSelector(selectIsFunnelMapEmpty);

  const [isOpen, setIsOpen] = useState(isFunnelMapEmpty);

  useEffect(() => {
    if (isFunnelMapEmpty) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [isFunnelMapEmpty]);

  if (!isOpen) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        right: '40px',
        top: 'calc(50% - 264px)',
        zIndex: 1,
      }}
    >
      <Paper
        sx={{
          width: '384px',
          border: `1px solid ${colors.grey[300]}`,
          backgroundColor: colors.grey[100],
          boxSizing: 'border-box',
          borderRadius: '4px',
          padding: 1,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            right: '0',
            top: '-34px',
          }}
        >
          <IconButton
            variant="outlined"
            size="tiny"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <Stack spacing={1}>
          <Box
            sx={{
              height: 36,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography variant="body-medium" color={colors.night[900]}>
              Get started with Sweep's canvas:
            </Typography>
          </Box>
          <ZeroStateItem
            image={<ImportFunnelImg />}
            onClick={() => {
              handleCommentsAndAiFirst(() => {
                maybeOpenPanel({ panelType: importFunnelPanelType });
              });
            }}
          >
            Import a funnel
            <br />
            from Salesforce
          </ZeroStateItem>
          <ZeroStateItem
            image={<CreateFunnelImg />}
            onClick={() => {
              dispatch(openNewFunnelDialog({ crmOrgId: appCrmOrgId }));
            }}
          >
            Create a funnel
            <br />
            from scratch
          </ZeroStateItem>
          <ZeroStateItem
            image={<AddThirdPartyFunnelImg />}
            onClick={() => {
              dispatch(setAddOrImportDialogOpen('import'));
            }}
          >
            Add a 3rd party funnel
          </ZeroStateItem>
          <ZeroStateItem
            image={<AddSweepTemplateImg />}
            onClick={() => {
              handleCommentsAndAiFirst(() => {
                maybeOpenPanel({ panelType: templatesPanelType });
              });
            }}
          >
            Use a Sweep template
          </ZeroStateItem>
        </Stack>
      </Paper>
    </Box>
  );
};
