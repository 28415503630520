import React, { useContext, useState } from 'react';
import { ReactFlowInstance } from '@xyflow/react';
import { VisibilityMap } from '../../../types/VisibilityTypes';
import { SweepCanvasRfNode } from '../canvas-types/nodeTypesData';
import { RFEdgeFloatingEdge } from '../edges';
import {
  SetStateActionWithObjectOperation,
  useStateWithGetter,
} from '../helpers/useStateWithGetter';
interface CanvasStateContextType {
  canvasNodes: SweepCanvasRfNode[];
  canvasEdges: RFEdgeFloatingEdge[];
  setCanvasNodes: React.Dispatch<SetStateActionWithObjectOperation<SweepCanvasRfNode>>;
  setCanvasEdges: React.Dispatch<SetStateActionWithObjectOperation<RFEdgeFloatingEdge>>;
  getCanvasNodes: () => SweepCanvasRfNode[];
  getCanvasEdges: () => RFEdgeFloatingEdge[];
  _isInitialized: boolean;
  reactFlowInstance?: ReactFlowInstance<SweepCanvasRfNode, RFEdgeFloatingEdge>;
  setReactFlowInstance: React.Dispatch<
    React.SetStateAction<ReactFlowInstance<SweepCanvasRfNode, RFEdgeFloatingEdge> | undefined>
  >;
}

const CanvasStateContext = React.createContext<CanvasStateContextType>({
  canvasNodes: [],
  canvasEdges: [],
  setCanvasNodes: () => {},
  setCanvasEdges: () => {},
  getCanvasNodes: () => [],
  getCanvasEdges: () => [],
  _isInitialized: false,
  setReactFlowInstance: () => {},
});

export const SweepCanvasInternalContextProvider = ({
  children,
}: {
  children: React.ReactNode;
  visibilityMap: VisibilityMap;
}) => {
  const [canvasNodes, setCanvasNodes, getCanvasNodes] = useStateWithGetter<SweepCanvasRfNode>([]);
  const [canvasEdges, setCanvasEdges, getCanvasEdges] = useStateWithGetter<RFEdgeFloatingEdge>([]);

  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance<SweepCanvasRfNode, RFEdgeFloatingEdge>>();

  return (
    <CanvasStateContext.Provider
      value={{
        canvasNodes,
        canvasEdges,
        setCanvasNodes,
        setCanvasEdges,
        _isInitialized: true,
        getCanvasNodes,
        getCanvasEdges,
        reactFlowInstance,
        setReactFlowInstance,
      }}
    >
      {children}
    </CanvasStateContext.Provider>
  );
};

export const useSweepCanvasState = () => {
  const ctx = useContext(CanvasStateContext);
  if (!ctx._isInitialized) {
    throw new Error('CanvasStateContext not initialized');
  }
  return ctx;
};
