import { memo, useCallback } from 'react';
import { Edge, EdgeProps, Position, useStore } from '@xyflow/react';
import { getConnectionPathAndArrow } from '../helpers/getConnectionPathAndArrow';
import {
  ActionButtonAdd,
  ActionButtonDelete,
  actionButtonDimensions,
} from '../nodes/components/CanvasButtons';
import FloatingEdgeConnection from './components/FloatingEdgeConnection';
import CanvasGate, { GATE_SIZE } from './components/CanvasGate';
import '../css/react-flow-styles.css';
import { CanvasEdgeConnectionType, CanvasMode } from '../canvas-types';
import * as path from 'svg-path-properties';
import {
  CANVAS_COLOR_HIGHLIGHT,
  DELETE_CONNECTION_DISTANCE_PERCENTAGE,
  GATE_DISTANCE_PERCENTAGE,
} from '../const';
import { SweepCanvasReactFlowEdgeDataType } from './SweepCanvasReactFlowEdgeDataType';
import { SweepCanvasProps } from '../internal-context/SweepCanvasPropsCtx';
import { getShortestPathHandles } from './handlePositionUtils';
import noop from 'lodash/noop';

export enum ArrowDirection {
  BottomTop = 'bt',
  TopBottom = 'tb',
  LeftRight = 'lr',
  RightLeft = 'rl',
}

export type RFEdgeData = {
  type: SweepCanvasReactFlowEdgeDataType;
  label?: string;
  highlightType?: 'source' | 'target' | 'simple' | null;
  connectedNodesDragging?: boolean;
  arrowDirection?: ArrowDirection;
  onEdgeAddBetweenBtnClick?: (id: string) => any;
  onEdgeDeleteClick?: SweepCanvasProps['onEdgeDeleteClick'];
  sourceColor: string;
  targetColor: string;
  gateColor: string;
  onGateClick?: (source: string, target: string, sourceParentId: string) => any;
  readonly?: boolean;
  connectionType: CanvasEdgeConnectionType;
  connectionIdx?: number;
  noAddBetweenBtn?: boolean;
  sourceParentId?: string;
  targetParentId?: string;
  canvasMode?: CanvasMode;
  showGates?: boolean;
  hideNurturingEdges?: boolean;
  showButtonsOnHighlight?: boolean;
  simpleHighlighted?: boolean;
};

export type RFEdgeFloatingEdge = Edge<RFEdgeData, 'floating'>;

const getButtonPositions = (connectionPath: string) => {
  const connectionPathProperties = new path.svgPathProperties(connectionPath);
  const length = connectionPathProperties.getTotalLength();

  const ptGate = connectionPathProperties.getPointAtLength(length * GATE_DISTANCE_PERCENTAGE);
  const ptSource = connectionPathProperties.getPointAtLength(length * 0.3 + 50);
  const ptDelete = connectionPathProperties.getPointAtLength(
    length * DELETE_CONNECTION_DISTANCE_PERCENTAGE,
  );

  return {
    gatePos: {
      x: Math.round(ptGate.x),
      y: Math.round(ptGate.y),
    },
    addBeforeBtnPos: {
      x: Math.round(ptSource.x),
      y: Math.round(ptSource.y),
    },
    deleteButtonPos: {
      x: Math.round(ptDelete.x),
      y: Math.round(ptDelete.y),
    },
  };
};

export const FloatingEdge = memo(({ id, source, target, data }: EdgeProps<RFEdgeFloatingEdge>) => {
  const {
    type,
    highlightType,
    sourceColor = '#DDDDDD',
    targetColor = '#CCCCCC',
    gateColor = '#eeeeee',
    onGateClick = noop,
    readonly,
    connectionIdx = 0,
    connectionType,
    noAddBetweenBtn,
    onEdgeDeleteClick,
    sourceParentId,
    targetParentId,
    canvasMode = CanvasMode.DEFAULT,
    hideNurturingEdges,
    showGates,
    connectedNodesDragging,
    onEdgeAddBetweenBtnClick,
    label,
    showButtonsOnHighlight,
    simpleHighlighted,
  } = data || {
    type: SweepCanvasReactFlowEdgeDataType.SIMPLE,
    sourceColor: '#DDDDDD',
    targetColor: '#CCCCCC',
    gateColor: '#eeeeee',
    onGateClick: () => {},
    connectionIdx: 0,
    connectionType: CanvasEdgeConnectionType.Bezier,
    onEdgeDeleteClick: noop,
  };

  const [sourceNode, targetNode, sourceParentNode, targetParentNode] = useStore(
    useCallback(
      (store) => [
        store.nodeLookup.get(source),
        store.nodeLookup.get(target),
        sourceParentId ? store.nodeLookup.get(sourceParentId) : undefined,
        targetParentId ? store.nodeLookup.get(targetParentId) : undefined,
      ],
      [source, target, sourceParentId, targetParentId],
    ),
  );

  if (!sourceNode || !targetNode) {
    console.error('sourceNode or targetNode is undefined');
    return null;
  }

  const isConnectionBetweenGroups = sourceParentId !== targetParentId;
  const areParentGroupsDragging = sourceParentNode?.dragging || targetParentNode?.dragging;

  const isHighlighted = Boolean(highlightType && !connectedNodesDragging);

  const _sourceEdgeColor =
    isHighlighted || simpleHighlighted ? CANVAS_COLOR_HIGHLIGHT : sourceColor;
  const _targetEdgeColor =
    isHighlighted || simpleHighlighted ? CANVAS_COLOR_HIGHLIGHT : targetColor;

  const {
    sourceHandle,
    targetHandle,
    arrowDirection,
    sourceHandleAbsolutePosition,
    targetHandleAbsolutePosition,
  } = getShortestPathHandles(sourceNode, targetNode);

  const { x: sourceX, y: sourceY } = sourceHandleAbsolutePosition;
  const { x: targetX, y: targetY } = targetHandleAbsolutePosition;
  const sourcePos = sourceHandle?.position || Position.Right;
  const targetPos = targetHandle?.position || Position.Left;

  const { connectionPath, arrowPath, arrowCirclePath, newTargetX, newTargetY } =
    getConnectionPathAndArrow({
      sourceX,
      sourceY,
      sourcePos,
      targetPos,
      targetX,
      targetY,
      arrowDirection,
      connectionIdx,
      connectionType:
        areParentGroupsDragging && isConnectionBetweenGroups
          ? CanvasEdgeConnectionType.Bezier
          : connectionType,
    });

  const { gatePos, addBeforeBtnPos, deleteButtonPos } = getButtonPositions(connectionPath);

  const renderGate = () => {
    switch (type) {
      case SweepCanvasReactFlowEdgeDataType.DASHED_CIRCLE:
        return (
          <CanvasGate
            dashed
            color={gateColor}
            label={label || ''}
            highlighted={isHighlighted}
            onClick={() => onGateClick(source, target, sourceParentId || '')}
            readonly={readonly}
          />
        );
      case SweepCanvasReactFlowEdgeDataType.CIRCLE:
        return (
          <CanvasGate
            color={gateColor}
            label={label || ''}
            highlighted={isHighlighted || simpleHighlighted}
            onClick={() => onGateClick(source, target, sourceParentId || '')}
            readonly={readonly}
          />
        );
      case SweepCanvasReactFlowEdgeDataType.REMOVABLE:
      case SweepCanvasReactFlowEdgeDataType.SIMPLE:
        return null;
    }
  };

  const renderAddButton = (x: number, y: number) => (
    <foreignObject
      width={actionButtonDimensions.width}
      height={actionButtonDimensions.height}
      x={x - actionButtonDimensions.width / 2}
      y={y - actionButtonDimensions.height / 2}
      className="floating-edge-foreign-object-button"
      requiredExtensions="http://www.w3.org/1999/xhtml"
    >
      <ActionButtonAdd onClick={() => onEdgeAddBetweenBtnClick && onEdgeAddBetweenBtnClick(id)} />
    </foreignObject>
  );

  const renderDeleteButton = (x: number, y: number) => (
    <foreignObject
      width={actionButtonDimensions.width}
      height={actionButtonDimensions.height}
      x={x - actionButtonDimensions.width / 2}
      y={y - actionButtonDimensions.height / 2}
      className="floating-edge-foreign-object-button"
      requiredExtensions="http://www.w3.org/1999/xhtml"
    >
      <ActionButtonDelete
        onClick={() =>
          onEdgeDeleteClick?.({
            edgeId: id,
            sourceNodeId: source.replace('label-', ''),
            targetNodeId: target.replace('label-', ''),
            sourceNodeParentId: sourceParentId,
            targetNodeParentId: targetParentId,
          })
        }
        className="inverted"
      />
    </foreignObject>
  );

  const showButtons = showButtonsOnHighlight && highlightType;

  const hasConnector = type !== SweepCanvasReactFlowEdgeDataType.SIMPLE;
  const shouldRenderDelete = showButtons && type === SweepCanvasReactFlowEdgeDataType.REMOVABLE;

  const shouldRenderAddButton =
    type !== SweepCanvasReactFlowEdgeDataType.REMOVABLE &&
    showButtons &&
    highlightType === 'source' &&
    !noAddBetweenBtn;

  if (hideNurturingEdges && (targetNode.data.isNb || sourceNode.data.isNb)) {
    return null;
  }

  return (
    <>
      <FloatingEdgeConnection
        id={id}
        connectionPath={connectionPath}
        arrowPath={arrowPath}
        arrowCirclePath={arrowCirclePath}
        sourceX={sourceX}
        sourceY={sourceY}
        targetX={newTargetX}
        targetY={newTargetY}
        sourceEdgeColor={_sourceEdgeColor}
        targetEdgeColor={_targetEdgeColor}
        strokeWidth={canvasMode === CanvasMode.PREVIEW1 ? 10 : 2}
      />

      {hasConnector && showGates && (
        <foreignObject
          width={GATE_SIZE}
          height={GATE_SIZE}
          x={gatePos.x - GATE_SIZE / 2}
          y={gatePos.y - GATE_SIZE / 2}
          className="edgebutton-foreignobject"
        >
          {renderGate()}
        </foreignObject>
      )}
      {shouldRenderAddButton && renderAddButton(addBeforeBtnPos.x, addBeforeBtnPos.y)}
      {shouldRenderDelete && renderDeleteButton(deleteButtonPos.x, deleteButtonPos.y)}
    </>
  );
});
