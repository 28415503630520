import { IconButton, Tooltip } from '@sweep-io/sweep-design';
import { Expand, Minimize } from '@sweep-io/sweep-design/dist/icons';
import { useCallback } from 'react';
import { AI_AGENTS_EVENTS } from '../../services/events';
import useAiAgentCenter from './useAiAgentCenter';
import useSendBiEvent from '../../hooks/useSendBiEvent';

const ToggleExpandButton = () => {
  const { isExpanded, toggleIsExpanded } = useAiAgentCenter();
  const sendBiEvent = useSendBiEvent();

  const handleToggleClick = useCallback(() => {
    if (!isExpanded) {
      sendBiEvent({ name: AI_AGENTS_EVENTS.expand });
    }
    toggleIsExpanded();
  }, [isExpanded, sendBiEvent, toggleIsExpanded]);

  return (
    <Tooltip placement="top" title={isExpanded ? 'Collapse' : 'Expand'}>
      <IconButton variant="flat" onClick={handleToggleClick} size="small">
        {isExpanded ? <Minimize /> : <Expand />}
      </IconButton>
    </Tooltip>
  );
};

export default ToggleExpandButton;
