import { ConfirmDialog } from '../common/dialogs/ConfirmDialog';
import { useState } from 'react';
import { Box, TextField, Collapse, Stack } from '@mui/material';
import { colors, IconButton, Typography } from '@sweep-io/sweep-design';
import { ChevronDown, ChevronUp } from '@sweep-io/sweep-design/dist/icons';
import AgentInstructionsContent from './agentSettings/AgentInstructionsContent';

const ProcessAgentCreateDialog = ({
  onConfirm,
  closeDialog,
  agentNamePlaceholder,
  instructionsPlaceholder,
}: {
  onConfirm: (name: string, instructions?: string) => void;
  closeDialog: () => void;
  agentNamePlaceholder: string;
  instructionsPlaceholder: string;
}) => {
  const [localName, setLocalName] = useState(agentNamePlaceholder);
  const [localInstructions, setLocalInstructions] = useState<string | undefined>();
  const [isSectionExpanded, setIsSectionExpanded] = useState(false);

  const _onConfirm = () => {
    onConfirm(localName, localInstructions);
    closeDialog();
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      _onConfirm();
    }
  };

  return (
    <ConfirmDialog
      title="Create your process optimization agent"
      confirmText="Create Agent"
      confirmButtonStartIconName="Sparkle"
      onConfirm={_onConfirm}
      isOpen={true}
      handleClose={closeDialog}
      PaperProps={{
        sx: {
          width: 670,
          //content should have 24px padding from title and actions
          '.MuiDialogContent-root': {
            pb: 0,
            pt: 2,
          },
        },
      }}
      disableConfirmButton={!localName}
      enableKeyListener={true}
    >
      <Stack gap={3}>
        <TextField
          fullWidth
          autoFocus
          value={localName}
          onChange={(event) => {
            setLocalName(event.target.value);
          }}
          onKeyDown={handleKeyDown}
        />
        <Stack gap={2}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            <Typography color={colors.grey[800]} variant="body">
              Personalize AI agent with instructions
            </Typography>
            <IconButton
              size="small"
              variant="flat"
              onClick={() => setIsSectionExpanded((prev) => !prev)}
            >
              {isSectionExpanded ? <ChevronDown /> : <ChevronUp />}
            </IconButton>
          </Box>
          <Collapse in={isSectionExpanded}>
            <AgentInstructionsContent
              value={localInstructions ?? ''}
              onChange={(value) => setLocalInstructions(value)}
              placeholder={instructionsPlaceholder}
            />
          </Collapse>
        </Stack>
      </Stack>
    </ConfirmDialog>
  );
};

export default ProcessAgentCreateDialog;
