import { MarketingAttributionMultiTouchModel } from '@server/marketing-attribution';
import {
  apiSweepOperatorWithValueToSweepFieldOperator,
  ApiSweepFieldOperator,
  SweepFieldOperatorsValues,
  sweepFieldOperatorToLabel,
  operatorHasAutomaticValue,
} from '../../SweepFieldsOperatorSelector/sweepFieldsTypesAndOperators';

export const getMarketingAttributionObjectList = (
  automationJson: DeepPartial<AutomationStructureNew>,
) => {
  const objects: string[] = [];

  if (!automationJson.automationDetails?.when?.isCriteria) {
    objects.push('Opportunity');
  }

  if (
    !(automationJson.automationDetails?.actions as MarketingAttributionModelAction[])?.[0]
      ?.actionParams?.campaignFilter?.isCriteria
  ) {
    objects.push('Campaign');
  }
  return objects;
};

export const getMarketingAttributionAction = (
  automationJson: DeepPartial<AutomationStructureNew>,
) => {
  const action = automationJson.automationDetails?.actions as MarketingAttributionAction[];
  return action?.[0];
};

export const getMarketingAttributionActionParams = (
  automationJson: DeepPartial<AutomationStructureNew>,
) => {
  const action = getMarketingAttributionAction(automationJson);
  return action?.actionParams;
};

export const getMarketingAttributionWeights = (
  automationJson: DeepPartial<AutomationStructureNew>,
) => {
  const actionParams = getMarketingAttributionActionParams(automationJson);
  return (actionParams as MarketingAttributionMultiTouchModel)?.weights;
};

export const getMarketingAttributionModelType = (
  automationJson: DeepPartial<AutomationStructureNew>,
) => {
  const actionParams = getMarketingAttributionActionParams(automationJson);
  return actionParams?.modelType;
};

export const getMarketingAttributionCampaignFilter = (
  automationJson: DeepPartial<AutomationStructureNew>,
) => {
  const actionParams = getMarketingAttributionActionParams(automationJson);
  return actionParams?.campaignFilter;
};

export const doesStageExist = (automationJson: DeepPartial<AutomationStructureNew>) => {
  return automationJson.automationDetails?.automationParams?.stageEnter?.toStage?._stageId;
};

export const translateModelType = (modelType: string) => {
  switch (modelType) {
    case 'FIRST_TOUCH':
      return 'First Touch';
    case 'LAST_TOUCH':
      return 'Last Touch';
    case 'EVEN_DISTRIBUTION':
      return 'Even Distribution';
    case 'MULTI_TOUCH':
      return 'Multi Touch';
  }
};

const translateOperator = (operator: string, value?: string) => {
  const sweepFieldOperator = apiSweepOperatorWithValueToSweepFieldOperator(
    operator as ApiSweepFieldOperator,
    value as SweepFieldOperatorsValues,
  );
  return sweepFieldOperatorToLabel(sweepFieldOperator);
};

const translateValue = (value: string, operator: ApiSweepFieldOperator) => {
  const isAutomatic = operatorHasAutomaticValue(operator);
  if (isAutomatic) {
    return '';
  }
  return value;
};

export const translateConditionsToString = (
  criteria: any[],
  getLabelsFromIds: (ids: string[]) => string[],
): string[] => {
  return criteria.map(
    (item) =>
      `${getLabelsFromIds(item._fieldIds)?.join(' > ')} ${translateOperator(item.operator, item.value)} ${translateValue(item.value, item.operator)}`,
  );
};
