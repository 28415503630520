import { colors, Typography } from '@sweep-io/sweep-design';
import { InfoDialog } from '../../../../common/dialogs/InfoDialog';
import { Card, Stack, styled } from '@mui/material';
import chatWithUs from './images/chatWithUs.png';
import scheduleMeeting from './images/scheduleMeeting.png';
import { openIntercom, scheduleCall } from '../../../customerSupportHelper';
import { useSelector } from 'react-redux';
import { selectUserInfoData } from '../../../../../reducers/userInfoReducer';
import usePaywall from '../../usePaywall';
import { PaywallSource } from '../../paywallTypes';

export const CtaDialog = ({
  name,
  source,
  onClose,
}: {
  name: string;
  source: PaywallSource;
  onClose: () => void;
}) => {
  const userInfo = useSelector(selectUserInfoData);
  const { scheduleMeetingCallback, chatWithUsCallback } = usePaywall();
  return (
    <InfoDialog open handleClose={onClose} showCloseButton PaperPropsSx={{ width: '760px' }}>
      <Typography variant="h2" textAlign="center">
        How do you want to explore {name} further?
      </Typography>
      <Stack direction="row" gap={6} pt={5} pb={6} alignItems="center" justifyContent="center">
        <StyledCard
          onClick={() => {
            scheduleMeetingCallback(source);
            scheduleCall(userInfo);
            onClose();
          }}
        >
          <img src={scheduleMeeting} width="248px" height="176px" />
          <Typography variant="body" color={colors.blue[500]} textAlign="center">
            Schedule a meeting
          </Typography>
        </StyledCard>

        <StyledCard
          onClick={() => {
            chatWithUsCallback(source);
            openIntercom();
            onClose();
          }}
        >
          <img src={chatWithUs} width="248px" height="176px" />
          <Typography variant="body" color={colors.blue[500]} textAlign="center">
            Chat with us
          </Typography>
        </StyledCard>
      </Stack>
    </InfoDialog>
  );
};

const StyledCard = styled(Card)({
  boxShadow: 'none',
  padding: '16px',
  gap: '8px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
});
