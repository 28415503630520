export enum AutomationFormVariant {
  PLAYBOOK_ALERT = 'PLAYBOOK_ALERT',
  ASSIGNMENT_RULES = 'ASSIGNMENT_RULES',
  AUTOMATIONS = 'AUTOMATIONS',
  DEDUP_MATCHING = 'DEDUP_MATCHING',
  SCHEDULED_ASSIGNMENTS = 'SCHEDULED_ASSIGNMENTS',
  SCHEDULED_REPORTS = 'SCHEDULED_REPORTS',
  SCHEDULED_JOBS = 'SCHEDULED_JOBS',
  MARKETING_ATTRIBUTIONS = 'MARKETING_ATTRIBUTIONS',
}
