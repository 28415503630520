import { TableContainer, Box } from '@mui/material';
import {
  StyledTable__deprecated,
  StyledTableBody__deprecated,
  StyledTableHead__deprecated,
} from './StyledTableComponents';
import { useState, useCallback, useMemo } from 'react';
import {
  DataTableDraggableProps,
  DataTableRow,
  DataTableSortableProps,
  DataTableVariant,
  SortCompareTypes,
} from './TableTypes';
import { keyBy } from 'lodash';
import { SortOrder } from '../types';
import { TableHeadRow__deprecated } from './TableHeadRow__deprecated';
import { TableLoader } from './TableLoader';
import { TableBodyRows__deprecated } from './TableBodyRows__deprecated';
import { TableEmptyStateComponentWrapper } from './TableEmptyStateComponentWrapper';
import { DraggableTableBodyRows__deprecated } from './DraggableTableBodyRows__deprecated';

/** Current limitation: "renderRow" is applicable only for non-draggable tables (when allowReorder is false) **/

export function DataTable__deprecated<TRow extends DataTableRow = any>({
  rows: allRows,
  columns: allColumns,
  sx,
  isLoading,
  tableMaxHeight,
  tableEmptyStateJsx,
  TableFooter,
  actionableButtonsOnHover = false,
  variant = DataTableVariant.default,
  containerRef,
  stickyHeader,
  ...dataTableProps
}: DataTableDraggableProps<TRow> | DataTableSortableProps<TRow>) {
  const isDraggable = 'onOrderChange' in dataTableProps;

  const columnsByField = keyBy(allColumns, 'field');
  const [sortState, setSortState] = useState(
    'defaultSortState' in dataTableProps ? dataTableProps.defaultSortState : undefined,
  );

  const columns = allColumns.filter((column) => !column.hidden);

  const rows = useMemo(() => {
    if (!sortState) {
      return allRows;
    }
    const columnToSortBy = columnsByField[sortState.sortBy];
    if (!columnToSortBy) {
      return allRows;
    }
    if (typeof columnToSortBy === 'object' && 'sortCompare' in columnToSortBy) {
      const sortCompare = columnToSortBy.sortCompare;
      const orderModifier = sortState.sortOrder === SortOrder.ASC ? 1 : -1;
      switch (sortCompare?.type) {
        case SortCompareTypes.String:
          return allRows.sort((a, b) => {
            const aVal = a[sortState.sortBy];
            const bVal = b[sortState.sortBy];
            return orderModifier * aVal.localeCompare(bVal);
          });

        case SortCompareTypes.Number:
          return allRows.sort((a, b) => {
            const aVal = a[sortState.sortBy];
            const bVal = b[sortState.sortBy];
            return orderModifier * (aVal - bVal);
          });

        case SortCompareTypes.Custom:
          if (sortCompare.compareFunction) {
            return allRows.sort((a, b) => {
              const aRow = a;
              const bRow = b;
              return sortCompare.compareFunction(aRow, bRow, sortState.sortOrder);
            });
          }
          break;

        default:
          break;
      }
    }
    return allRows;
  }, [allRows, columnsByField, sortState]);

  const sort = useCallback(
    (field: string) => {
      setSortState((prevState) => {
        const order = prevState?.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
        if ('onSortChange' in dataTableProps) {
          dataTableProps.onSortChange?.({
            sortBy: field,
            sortOrder: order,
          });
        }
        return {
          sortBy: field,
          sortOrder: order,
        };
      });
    },
    [dataTableProps],
  );

  return (
    <Box
      sx={{
        maxHeight: tableMaxHeight ?? '100%',
        height: '100%',
        overflow: isLoading ? 'hidden' : 'auto',
        position: 'relative',
      }}
      data-testid="data-table"
      ref={containerRef}
    >
      <TableContainer sx={{ ...sx, overflow: 'unset', height: '100%' }}>
        <StyledTable__deprecated>
          <StyledTableHead__deprecated data-testid="data-table-head">
            <TableHeadRow__deprecated
              stickyHeader={stickyHeader}
              columns={columns}
              sort={sort}
              sortState={sortState}
              variant={variant}
            />
          </StyledTableHead__deprecated>
          <StyledTableBody__deprecated
            sx={{
              visibility: isLoading ? 'hidden' : 'visible',
              position: 'relative',
            }}
          >
            {!isDraggable && rows.length > 0 && (
              <TableBodyRows__deprecated
                rows={rows}
                columns={columns}
                containerRef={containerRef}
                variant={variant}
                actionableButtonsOnHover={actionableButtonsOnHover}
                {...dataTableProps}
              />
            )}
            {isDraggable && rows.length > 0 && (
              <DraggableTableBodyRows__deprecated
                rows={rows}
                columns={columns}
                variant={variant}
                actionableButtonsOnHover={actionableButtonsOnHover}
                {...dataTableProps}
              />
            )}
            {!rows.length && (
              <TableEmptyStateComponentWrapper
                TableEmptyStateJsx={tableEmptyStateJsx}
                columnsCount={columns.length}
              />
            )}
          </StyledTableBody__deprecated>
          {TableFooter}
        </StyledTable__deprecated>
      </TableContainer>

      {isLoading && <TableLoader />}
    </Box>
  );
}
