export enum AutomationActionType {
  CreateRecord = 'CREATE_RECORD',
  UpdateRecords = 'UPDATE_RECORDS',
  SendEmail = 'SEND_EMAIL',
  Assignment = 'ASSIGNMENT',
  AssignmentRules = 'ASSIGNMENT_RULES',
  ConvertLead = 'CONVERT_LEAD',
  Slack = 'SEND_SLACK_MESSAGE',
  SlackDealRoom = 'CREATE_SLACK_CHANNEL',
  SlackAddMember = 'ADD_MEMBERS_TO_SLACK_CHANNEL',
  SendReportToSlack = 'SEND_REPORT_TO_SLACK',
  VerifyURL = 'VERIFY_URL',
  SlackRenameDealroom = 'RENAME_SLACK_CHANNEL',
  SlackArchiveDealroom = 'ARCHIVE_SLACK_CHANNEL',
  PrioritizedActions = 'PRIORITIZED_ACTIONS',
  Webhook = 'WEBHOOK',
  RunApex = 'RUN_APEX',
  SendTeamsMessage = 'SEND_TEAMS_MESSAGE',
  PushNotification = 'PUSH_NOTIFICATION', // TODO: REMOVE 'PushNotification' with ft lgDemo
  CloneRecords = 'CLONE_RECORDS', // TODO: REMOVE 'PushNotification' with ft lgDemo
  MarketingAttributionModel = 'REVENUE_ATTRIBUTION',
}
