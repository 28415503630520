import { Box, SxProps, Theme } from '@mui/material';
import { useSweepFields } from '../../../sweep-fields/useCachedSweepFields';
import {
  NestedSelector,
  NestedItem,
  NestedSelectorPath,
  NestedSelectorPathFragment,
  NestedSelectorRef,
} from '../NestedSelector/NestedSelector';
import { useLoadingItem } from './useLoadingItem';
import AddFieldButton from './AddFieldButton';
import FieldSelectorEmptyState from './FieldSelectorEmptyState';
import { SFDCObjectType } from '../../../types/enums/SFDCObjectType';
import { useCallback, useRef, useState } from 'react';
import {
  CHECKBOX_FIELD_TYPES,
  DATETIME_FIELD_TYPES,
  DATE_FIELD_TYPES,
  GEO_FIELD_TYPES,
  NUMBER_FIELD_TYPES,
  TEXT_FIELD_TYPES,
  TIME_FIELD_TYPES,
} from '../../../types/ReferencedValuesTypes';
import {
  injectPolymorphicFields,
  parseSweepFieldPolymorphicID,
} from './nestedFieldsSelectorHelper';
import { EditMode } from '../../../constants/fieldsManagementConsts';
import { SweepNotificationVariant } from '../../../reducers/notificationsReducer';
import { useSweepNotifications } from '../../notifications/useSweepNotifications';
import CreateEditFieldDialog from '../../pages/funnel-map-flow/dialogs/fields-management/create-field-dialog/CreateEditFieldDialog';
import { useCreateFieldContext } from '../../pages/funnel-map-flow/dialogs/fields-management/create-field-dialog/CreateFieldContext';
import { SweepFieldTypes } from '../../../types/enums/SweepFieldTypes';
import { Button, colors } from '@sweep-io/sweep-design';
import { GenericMenu, useGenericMenu } from '../GenericMenu';
import { FieldContext } from '../../../types/enums/FieldContext';
import { telemetry } from '../../../telemetry';

export interface NestedFieldsField {
  sweepField: SweepField;
  fieldIds: string[];
  fieldType: string;
  fieldLabels: string[];
  fullPath: NestedSelectorPath;
  fieldContext?: FieldContext;
  valueContext?: FieldContext;
}

export interface NestedFieldsSelectorProps {
  nestedPath: NestedSelectorPath; // Controlled
  objectType: string;
  onChange?: (field: NestedFieldsField) => any;
  additionalPopoverStyles?: SxProps<Theme>;
  crmOrgId: string;
  readonly?: boolean;
  startIcon?: React.ReactNode;
  customButtonText?: string;
  customButtonSx?: SxProps;
  useCustomButton?: boolean;
  displayLeadingObjectName?: boolean;
  parentFieldType?: SweepFieldTypes;
  isReferencedValue?: boolean;
  customButtonStartIcon?: React.ReactNode;
  customButtonEndIcon?: React.ReactNode;
  exclusiveParentFieldType?: SweepFieldTypes;
  CustomButtonComponent?: React.FunctionComponent<{ onClick: (i: any) => void; disabled: boolean }>;
  placeholder?: string;
  filterBy?: (field: SweepField) => boolean;
  hideActionButton?: boolean;
  nestedSelectorButtonStyle?: SxProps<Theme>;
  dropDownWidth?: number;
  showApiNameTooltip?: boolean;
  restrictReferencesToObject?: string;
  fieldContext?: FieldContext;
  displayFieldContextMenu?: boolean;
  valueContext?: FieldContext;
  displayValueContextMenu?: boolean;

  // If a field is polymorphic, it will be resolved to multiple fields.
  // One selectable, and one drill in field per object.
  // This flag will disable that behavior.
  disableResolvePolymorphic?: boolean;

  // If a field is a lookup, it will be resolve into at least two fields.
  // One selectable, and one drill in field.
  // This flag will disable that behavior.
  disableLookupItemsResolve?: boolean;
}

const sweepFieldsInterfaceToNodes = (
  fields: SweepField[],
  disableLookupItemsResolve?: boolean,
  showApiNameTooltip?: boolean,
): NestedItem[] => {
  const newFields: NestedItem[] = [];
  fields.forEach((field) => {
    const { isResolvable } = parseSweepFieldPolymorphicID(field.id || '');
    if (isResolvable && disableLookupItemsResolve) {
      return;
    }
    newFields.push({
      label: field.sweepFieldName || field.sfFieldName || '',
      value: field.id || '',
      shouldResolveSubItems: disableLookupItemsResolve ? false : isResolvable,
      data: field,
      tooltip: showApiNameTooltip ? (
        <>
          <div>{field.sfFieldName}</div>
        </>
      ) : undefined,
    });
  });
  return newFields;
};

const replaceEmptyLabelsByApiName = (path: NestedSelectorPath) => {
  try {
    return path.map((path) => ({
      ...path,
      label: path.label || path.value,
    }));
  } catch (e) {
    telemetry.captureError(e);
    return [];
  }
};

const removeLookupFromPathValue = (path: NestedSelectorPath) =>
  path.map((fragment) => {
    const { id } = parseSweepFieldPolymorphicID(fragment.value);
    return {
      ...fragment,
      value: id,
    };
  });

const fieldContextMenuEntries = [
  { label: 'Triggering record', value: FieldContext.CURRENT_VALUE },
  {
    label: 'Prior value of Triggering record',
    value: FieldContext.PRIOR_VALUE,
  },
];

export const NestedFieldsSelector = ({
  nestedPath,
  objectType,
  onChange,
  additionalPopoverStyles,
  crmOrgId,
  readonly,
  startIcon,
  customButtonText,
  customButtonSx,
  useCustomButton,
  displayLeadingObjectName,
  parentFieldType,
  isReferencedValue,
  disableLookupItemsResolve: _disableLookupItemsResolve,
  customButtonStartIcon,
  customButtonEndIcon,
  exclusiveParentFieldType,
  CustomButtonComponent,
  placeholder,
  filterBy,
  hideActionButton,
  nestedSelectorButtonStyle,
  dropDownWidth,
  showApiNameTooltip = true,
  restrictReferencesToObject,
  fieldContext,
  displayFieldContextMenu,
  disableResolvePolymorphic,
  valueContext,
  displayValueContextMenu,
}: NestedFieldsSelectorProps) => {
  const { hideCreateFieldDialog } = useCreateFieldContext();

  const { searchSweepFields } = useSweepFields();
  const { calculateAndSetLoadingState, loadingItem } = useLoadingItem();
  const { addNotification } = useSweepNotifications();
  const [isCreateFieldDialogOpened, setIsCreateFieldDialogOpened] = useState(false);

  const [localFieldContext, setLocalFieldContext] = useState<FieldContext | undefined>(
    displayFieldContextMenu ? fieldContext : undefined,
  );
  const [localValueContext, setLocalValueContext] = useState<FieldContext | undefined>(
    displayValueContextMenu ? valueContext : undefined,
  );

  const disableLookupItemsResolve =
    Boolean(localFieldContext === FieldContext.PRIOR_VALUE) ||
    Boolean(localValueContext === FieldContext.PRIOR_VALUE) ||
    _disableLookupItemsResolve;

  const nestedSelectorRef = useRef<NestedSelectorRef>(null);

  const getParentFieldRelatedTypes = useCallback(() => {
    if (exclusiveParentFieldType) {
      return [exclusiveParentFieldType];
    }
    if (!parentFieldType) return [];

    if (TEXT_FIELD_TYPES.includes(parentFieldType)) {
      return TEXT_FIELD_TYPES;
    }

    if (NUMBER_FIELD_TYPES.includes(parentFieldType)) {
      return NUMBER_FIELD_TYPES;
    }

    if (CHECKBOX_FIELD_TYPES.includes(parentFieldType)) {
      return CHECKBOX_FIELD_TYPES;
    }

    if (DATE_FIELD_TYPES.includes(parentFieldType)) {
      return DATE_FIELD_TYPES;
    }

    if (DATETIME_FIELD_TYPES.includes(parentFieldType)) {
      return DATETIME_FIELD_TYPES;
    }

    if (TIME_FIELD_TYPES.includes(parentFieldType)) {
      return TIME_FIELD_TYPES;
    }
    if (GEO_FIELD_TYPES.includes(parentFieldType)) {
      return GEO_FIELD_TYPES;
    }
    return [];
  }, [parentFieldType, exclusiveParentFieldType]);

  const searchOnlyReferencedValues = useCallback(
    (sweepFields: SweepField[]) => {
      const parentFieldRelatedTypes: SweepFieldTypes[] = [
        ...getParentFieldRelatedTypes(),
        SweepFieldTypes.Lookup,
        SweepFieldTypes.Id,
        SweepFieldTypes.Hierarchy,
        SweepFieldTypes.MasterDetail,
      ];

      return sweepFields.filter((item) => {
        const fieldType = item.fieldType as SweepFieldTypes;
        const currentItem = parentFieldRelatedTypes.includes(fieldType);

        if (item.fieldType === 'Lookup' && restrictReferencesToObject) {
          return Boolean(item.objectNames?.includes(restrictReferencesToObject));
        }

        return currentItem;
      });
    },
    [getParentFieldRelatedTypes, restrictReferencesToObject],
  );

  const searchBySiblings = useCallback(
    async (path: NestedSelectorPath) => {
      const { sweepFields, complete, isFirstLoad } = await searchSweepFields({
        fieldIds: removeLookupFromPathValue(path).map((v) => v.value),
        crmOrgId,
      });
      injectPolymorphicFields(sweepFields, disableResolvePolymorphic);

      calculateAndSetLoadingState(complete, isFirstLoad);

      return path.map(({ value }, idx) => {
        const objectTypeFromFieldId = value.split('.')[0];
        let fields = objectTypeFromFieldId ? sweepFields[objectTypeFromFieldId].fields : [];

        if (isReferencedValue) {
          fields = searchOnlyReferencedValues(fields);
        }

        if (filterBy) {
          fields = fields.filter(filterBy);
        }

        const items = objectTypeFromFieldId
          ? sweepFieldsInterfaceToNodes(fields, disableLookupItemsResolve, showApiNameTooltip)
          : [];
        const itemBasePath = path.slice(0, idx);

        return {
          path: itemBasePath,
          items: items,
        };
      });
    },
    [
      calculateAndSetLoadingState,
      crmOrgId,
      disableLookupItemsResolve,
      disableResolvePolymorphic,
      filterBy,
      isReferencedValue,
      searchOnlyReferencedValues,
      searchSweepFields,
      showApiNameTooltip,
    ],
  );

  const searchByObjectType = useCallback(
    async (_objectType: string) => {
      const { sweepFields, complete, isFirstLoad } = await searchSweepFields({
        objectType: [_objectType],
        crmOrgId,
      });
      injectPolymorphicFields(sweepFields, disableResolvePolymorphic);

      calculateAndSetLoadingState(complete, isFirstLoad);

      let fields = sweepFields[_objectType].fields;

      if (isReferencedValue) {
        fields = searchOnlyReferencedValues(fields);
      }

      if (filterBy) {
        fields = fields.filter(filterBy);
      }

      const items = sweepFieldsInterfaceToNodes(
        fields,
        disableLookupItemsResolve,
        showApiNameTooltip,
      );

      const path: NestedSelectorPathFragment[] = [];

      return [
        {
          path,
          items,
        },
      ];
    },
    [
      calculateAndSetLoadingState,
      crmOrgId,
      disableLookupItemsResolve,
      filterBy,
      isReferencedValue,
      disableResolvePolymorphic,
      searchOnlyReferencedValues,
      searchSweepFields,
      showApiNameTooltip,
    ],
  );

  const resolveOpen = useCallback(
    async (sweepSelectorValue: NestedSelectorPath) => {
      const hasPath = Boolean(sweepSelectorValue.length > 0);
      if (!(hasPath || objectType)) {
        return [];
      }

      if (hasPath) {
        const items = await searchBySiblings(removeLookupFromPathValue(sweepSelectorValue));
        return items;
      } else {
        const items = await searchByObjectType(objectType as string);
        return items;
      }
    },
    [objectType, searchByObjectType, searchBySiblings],
  );

  const resolveChildren = useCallback(
    async (item: NestedItem<SweepField>) => {
      if (!item.data) {
        return [];
      }
      const { fieldType, objectName, objectNames } = item.data;
      const shouldResolveChildren =
        (fieldType === 'Lookup' && objectNames) ||
        (fieldType === 'Hierarchy' && objectName) ||
        (fieldType === 'MasterDetail' && objectName);

      if (!shouldResolveChildren) {
        return [];
      }
      const _objectNames = (
        fieldType === 'Lookup' || fieldType === 'MasterDetail' ? objectNames : [objectName]
      ) as string[];

      const { sweepFields } = await searchSweepFields({
        objectType: _objectNames,
        crmOrgId: crmOrgId,
      });
      injectPolymorphicFields(sweepFields, disableResolvePolymorphic);

      let fields = _objectNames.length ? sweepFields[_objectNames[0]].fields : [];

      if (isReferencedValue) {
        fields = searchOnlyReferencedValues(fields);
      }

      if (filterBy) {
        fields = fields.filter(filterBy);
      }

      //related to SWEEP-2989
      fields = fields.filter((field) => field.sweepFieldName !== item.data?.sweepFieldName);

      return sweepFieldsInterfaceToNodes(fields, disableLookupItemsResolve);
    },
    [
      crmOrgId,
      disableLookupItemsResolve,
      filterBy,
      isReferencedValue,
      disableResolvePolymorphic,
      searchOnlyReferencedValues,
      searchSweepFields,
    ],
  );

  //This function is called after creation of a new field
  const onChangeWithNewField = useCallback(
    (field: SweepField) => {
      const item = {
        sweepField: field,
        fieldIds: [field.id ?? ''],
        fieldType: field.fieldType,
        fieldLabels: [field.objectName ?? '', field.sweepFieldName ?? ''],
        fullPath: [
          {
            label: field.sweepFieldName ?? '',
            value: field.id ?? '',
          },
        ],
      };
      onChange?.(item);
    },
    [onChange],
  );

  const { handleClickOpen, handleClose, isOpen, anchorEl } = useGenericMenu();

  const priorValueSelectorHeaderComponent = () => {
    const currentContext = displayFieldContextMenu ? localFieldContext : localValueContext;
    const currentSetContext = displayFieldContextMenu ? setLocalFieldContext : setLocalValueContext;
    return (
      <>
        <Box
          sx={{
            margin: 2,
            marginBottom: 0,
            '& > .MuiButtonBase-root': {
              display: 'flex',
              justifyContent: 'space-between !important',
              width: '100%',
              color: colors.grey[900],
              svg: {
                path: {
                  stroke: colors.grey[900],
                },
              },
            },
          }}
        >
          <Button variant="flat" endIconName="ChevronRight" onClick={handleClickOpen}>
            <span>
              {currentContext === FieldContext.PRIOR_VALUE
                ? fieldContextMenuEntries[1].label
                : fieldContextMenuEntries[0].label}
            </span>
          </Button>
          <GenericMenu
            entries={fieldContextMenuEntries}
            onClose={handleClose}
            open={isOpen}
            anchorEl={anchorEl}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            selectedValue={currentContext}
            onClick={(event, itemValue) => {
              currentSetContext(itemValue as FieldContext);
              handleClose();
              setTimeout(() => {
                nestedSelectorRef.current?.reset();
              }, 0);
            }}
          />
        </Box>
      </>
    );
  };

  const _ActionButton =
    !hideCreateFieldDialog && !hideActionButton
      ? ({ closeSelector }: { closeSelector: () => void }) => (
          <AddFieldButton
            onAddFieldClick={() => {
              setIsCreateFieldDialogOpened(true);
              closeSelector();
            }}
          />
        )
      : undefined;

  return (
    <>
      <NestedSelector
        ref={nestedSelectorRef}
        objectType={objectType}
        placeholder={placeholder || 'Field'}
        displayLeadingObjectName={displayLeadingObjectName}
        readonly={readonly}
        startIcon={startIcon}
        loadingItem={loadingItem}
        customButtonText={customButtonText}
        customButtonSx={customButtonSx}
        useCustomButton={useCustomButton}
        customButtonStartIcon={customButtonStartIcon}
        customButtonEndIcon={customButtonEndIcon}
        CustomButtonComponent={CustomButtonComponent}
        onChange={(item, fullPath) => {
          if (onChange) {
            const _fullPath = removeLookupFromPathValue(fullPath);
            const fieldLabels = [objectType, ..._fullPath.map((path) => path.label || '')];
            const fieldIds = _fullPath.map((path) => path.value);
            const sweepField = item.data as SweepField;
            const { fieldType } = sweepField;

            onChange({
              sweepField,
              fieldIds,
              fieldLabels,
              fieldType,
              fullPath: _fullPath,
              fieldContext: displayFieldContextMenu ? localFieldContext : undefined,
              valueContext: displayValueContextMenu ? localValueContext : undefined,
            });
          }
        }}
        onClose={() => {
          setLocalFieldContext(fieldContext);
          setLocalValueContext(valueContext);
        }}
        nestedPath={replaceEmptyLabelsByApiName(nestedPath)}
        resolveOpen={resolveOpen}
        resolveChildren={resolveChildren}
        additionalPopoverStyles={additionalPopoverStyles}
        ActionButton={_ActionButton}
        EmptyState={({ closeSelector }: { closeSelector: () => void }) => (
          <FieldSelectorEmptyState
            onAddFieldClick={
              !hideCreateFieldDialog && !hideActionButton
                ? () => {
                    setIsCreateFieldDialogOpened(true);
                    closeSelector();
                  }
                : undefined
            }
          />
        )}
        nestedSelectorButtonStyle={nestedSelectorButtonStyle}
        dropDownWidth={dropDownWidth}
        HeaderComponent={
          Boolean(displayFieldContextMenu || displayValueContextMenu)
            ? priorValueSelectorHeaderComponent()
            : undefined
        }
        labelPretext={
          fieldContext === FieldContext.PRIOR_VALUE || valueContext === FieldContext.PRIOR_VALUE
            ? 'Prior value of '
            : undefined
        }
      />
      {!hideCreateFieldDialog && (
        <CreateEditFieldDialog
          crmOrgId={crmOrgId}
          onCreateFieldCb={(field) => {
            const isChangedObjectType = objectType !== field.objectName;
            if (isChangedObjectType) {
              addNotification({
                message: `Field was created but isn’t currently visible`,
                variant: SweepNotificationVariant.Success,
              });
            } else {
              onChangeWithNewField(field);
            }
          }}
          isOpened={isCreateFieldDialogOpened}
          closeDialog={() => setIsCreateFieldDialogOpened(false)}
          initialValues={{ objectName: objectType as SFDCObjectType }}
          mode={EditMode.CREATE}
        />
      )}
    </>
  );
};
