import { DialogContent, Stack } from '@mui/material';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import useConfirm from '../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import DialogOnCanvas from '../pages/canvas-pages/DialogOnCanvas';
import { DedupMatchingForm } from './DedupMatchingForm';
import { ConfirmLeaveWithoutSaveDialog } from '../common/dialogs/ConfirmLeaveWithoutSave/ConfirmLeaveWithoutSaveDialog';
import useSendOpenAutomationEvent from '../Automations/useSendOpenAutomationEvent';
import { useAutomationsContext } from '../Automations/AutomationsContext';
import { DedupMatchingType } from '../../types/enums/DedupMatchingType';
import { AutomationDialogActions } from '../Automations/AutomationDialogActions';
import ImpactAnalysisChat from '../Automations/impact-analysis-agent/ImpactAnalysisChat';

export type SingleDedupMatchingDialogRef = {
  isPristine: () => boolean;
};

export interface SingleDedupMatchingDialogProps {
  newDedupMatchingType?: DedupMatchingType;
  onSaveOrCreate: (automation: AutomationStructureNew, crmOrgIds: string[]) => any;
  onCancel: () => any;
  isOpen: boolean;
  readonly?: boolean;
  crmOrgId: string;
  objectName: ObjectTypeValues;
  funnelData?: { id: string; name: string; objectName: string };
  forceWideScreen?: boolean;
}

export const SingleDedupMatchingDialog = forwardRef<
  SingleDedupMatchingDialogRef,
  SingleDedupMatchingDialogProps
>(
  (
    {
      isOpen,
      onSaveOrCreate,
      onCancel: closeDialog,
      readonly,
      crmOrgId,
      objectName,
      funnelData,
      newDedupMatchingType,
      forceWideScreen,
    },
    ref,
  ) => {
    const [isImpactAnalysisOpen, setIsImpactAnalysisOpen] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const { automationJson } = useAutomationsContext();

    const [isPristine, setIsPristine] = useState(true);
    const { openConfirm } = useConfirm();

    const _onCancel = useCallback(async () => {
      if (isPristine) {
        closeDialog();
      } else {
        const isConfirmed = await openConfirm(<ConfirmLeaveWithoutSaveDialog />);
        if (isConfirmed) {
          closeDialog();
        }
      }
    }, [isPristine, openConfirm, closeDialog]);

    useImperativeHandle(ref, () => {
      return {
        isPristine() {
          return isPristine;
        },
      };
    }, [isPristine]);

    useSendOpenAutomationEvent();

    useEffect(() => {
      if (!isOpen) {
        setIsImpactAnalysisOpen(false);
      }
    }, [isOpen]);

    return (
      <DialogOnCanvas
        open={isOpen}
        onClose={_onCancel}
        blockFully={!isPristine}
        forceWideScreen={forceWideScreen || isImpactAnalysisOpen}
      >
        <Stack flex={1} direction="row" position="relative" overflow="hidden">
          <Stack flex={1} sx={{ overflowX: 'auto' }}>
            <DialogContent>
              <DedupMatchingForm
                newDedupMatchingType={newDedupMatchingType}
                key={`${automationJson?.automationId}${automationJson?.versionId}`}
                funnelData={funnelData}
                objectName={objectName}
                readonly={readonly}
                crmOrgId={crmOrgId}
                onBackButtonClick={_onCancel}
                onPristineChange={setIsPristine}
                updateIsFormValid={setIsFormValid}
              />
            </DialogContent>
            <AutomationDialogActions
              isAutomationsFormValid={isFormValid}
              readonly={readonly}
              onSaveOrCreate={onSaveOrCreate}
              crmOrgId={crmOrgId}
              toggleImpactAnalysis={() => setIsImpactAnalysisOpen((v) => !v)}
              isImpactAnalysisOpen={isImpactAnalysisOpen}
            />
          </Stack>
          <ImpactAnalysisChat
            isOpen={isImpactAnalysisOpen}
            close={() => setIsImpactAnalysisOpen(false)}
            automation={automationJson}
          />
        </Stack>
      </DialogOnCanvas>
    );
  },
);
