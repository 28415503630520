import { Box } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';
import { CenteredCircularProgress } from '../CenteredCircularProgress';

export const TableLoader = ({ topMargin }: { topMargin?: string }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        background: colors.white,
        height: '100%',
        zIndex: 1,
      }}
    >
      <Box sx={{ marginTop: topMargin ?? '15%' }}>
        <CenteredCircularProgress />
      </Box>
    </Box>
  );
};
