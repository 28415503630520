import { ReadOnlyAutomationAction } from '@server/read-only-elements.types';
import { ConditionsWrapper } from './ConditionsByLogicString';
import { HeaderWithIconOrNumber } from './atoms/HeaderWithIconOrNumber';
import { configurationItemTypeToIcon, propertiesToIcon } from './helpers/icons';
import Box from '@mui/material/Box/Box';
import { NOTIFICATION_TYPES } from '../consts';
import { ElementsUsedByClient } from '@server/read-only-elements.consts';
import { Notification } from './Notification';
import { ConfigurationTypesWithIcon } from '../../../types';
import { Subtext } from './atoms/Subtext';
import { styled } from '@mui/material/styles';

export interface StartAdornmentMap {
  [rowNo: number]: 'number' | 'icon' | 'none';
}

interface ReadOnlyAutomationActionWrapperProps {
  readOnlyAutomationAction: ReadOnlyAutomationAction;
  elementType: ConfigurationTypesWithIcon;
  number: number;
  subsectionLvl: number;
  noConditionExternalBorder?: boolean;
  renderStartAdornmentsByMap?: StartAdornmentMap;
}

export const ReadOnlyAutomationActionWrapper = ({
  readOnlyAutomationAction,
  elementType,
  number,
  subsectionLvl,
  noConditionExternalBorder,
  renderStartAdornmentsByMap,
}: ReadOnlyAutomationActionWrapperProps) => {
  if (!readOnlyAutomationAction) {
    return <></>;
  }

  const { conditionsLogicString, title, value, details } = readOnlyAutomationAction;
  const displayConditions = !!conditionsLogicString;
  const displayNotification = NOTIFICATION_TYPES.includes(title as ElementsUsedByClient);
  const displayDefault = !displayConditions && !displayNotification;

  const startAdornment = renderStartAdornmentsByMap?.[subsectionLvl];

  return (
    <Box position="relative">
      {displayConditions && (
        <BoxWithPadding pt={0.5}>
          <ConditionsWrapper
            noConditionExternalBorder={noConditionExternalBorder}
            readOnlyAutomationAction={readOnlyAutomationAction}
          />
        </BoxWithPadding>
      )}

      {displayNotification && (
        <BoxWithPadding pt={0.5}>
          <Notification action={readOnlyAutomationAction} />
        </BoxWithPadding>
      )}

      {displayDefault && (
        <>
          {title && (
            <Box pt={1} pb={1}>
              <HeaderWithIconOrNumber
                HeaderIcon={
                  startAdornment === 'icon'
                    ? (propertiesToIcon[title] ?? configurationItemTypeToIcon[elementType])
                    : propertiesToIcon[title]
                }
                headerText={title}
                number={startAdornment === 'number' ? number : undefined}
              />
            </Box>
          )}

          {value && (
            <BoxWithPadding pl={title ? 3 : 0}>
              <Subtext text={value} />
            </BoxWithPadding>
          )}

          {details.map((detail, idx) => {
            if (typeof detail === 'string') {
              return (
                <BoxWithPadding pl={title ? 3 : 0} key={'nested-subtext-' + idx}>
                  <Subtext text={detail} />
                </BoxWithPadding>
              );
            }

            return (
              <BoxWithPadding pl={3} key={'nested-read-only-' + idx}>
                <ReadOnlyAutomationActionWrapper
                  readOnlyAutomationAction={detail as ReadOnlyAutomationAction}
                  elementType={elementType}
                  number={idx + 1}
                  subsectionLvl={subsectionLvl + 1}
                  noConditionExternalBorder={noConditionExternalBorder}
                  renderStartAdornmentsByMap={renderStartAdornmentsByMap}
                />
              </BoxWithPadding>
            );
          })}
        </>
      )}
    </Box>
  );
};

const BoxWithPadding = styled(Box)({
  p: 1,
  pl: 0,
  position: 'relative',
});
