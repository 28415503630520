import { Box, Stack } from '@mui/material';
import AgentInfo from './AgentInfo';
import AiAgentChat from './AiAgentChat';
import { AI_AGENTS_INFO, CHAT_AREA_MAX_WIDTH } from './aiAgentsConsts';
import { ReactNode } from 'react';
import useAiAgentChats from './useAiAgentChats';
import AiAgentMenu from './AiAgentMenu';
import useAiAgents from './useAiAgents';
import { Button } from '@sweep-io/sweep-design';
import useAgentSettings from './agentSettings/useAgentSettings';
import { Permission } from '@server/permissions';
import usePermission from '../common/permissions/usePermission';
import ToggleExpandButton from './ToggleExpandButton';

const ChatWithAgentExpanded = ({ closeButton }: { closeButton: ReactNode }) => {
  const { searchText } = useAiAgentChats();
  const { renameAgent, activeAgent } = useAiAgents();
  const { openInstructionsDialog, openFilesDialog, dialogs } = useAgentSettings(activeAgent);
  const permissions: Permission[] = ['edit:ai-agent'];
  const [isAllowedToEditAgent] = usePermission(permissions);

  if (!activeAgent) {
    return null;
  }

  const { agentIcon, persona, addingFiles } = AI_AGENTS_INFO[activeAgent.type];

  return (
    <>
      {isAllowedToEditAgent && dialogs}
      <Stack
        flex={1}
        width="100%"
        sx={{
          overflow: 'hidden',
        }}
      >
        <Stack direction="row" justifyContent="space-between" width="100%" p={2}>
          <AgentInfo
            icon={agentIcon}
            persona={persona}
            name={activeAgent.name}
            onEditName={(name) => renameAgent(activeAgent.id, name)}
            iconSize="40px"
          />
          <Box display="flex" gap={1} alignItems="center">
            {isAllowedToEditAgent && addingFiles && addingFiles.isAllowed && (
              <Button
                variant="outlined"
                size="small"
                startIconName="Files"
                onClick={openFilesDialog}
              >
                Files
              </Button>
            )}
            {isAllowedToEditAgent && (
              <Button
                variant="outlined"
                size="small"
                startIconName="Wrench"
                onClick={openInstructionsDialog}
              >
                Instructions
              </Button>
            )}
            <AiAgentMenu />
            <ToggleExpandButton />
            {closeButton}
          </Box>
        </Stack>

        <AiAgentChat
          noteAlignment="center"
          maxWidth={CHAT_AREA_MAX_WIDTH}
          searchTermToHighlight={searchText}
        />
      </Stack>
    </>
  );
};

export default ChatWithAgentExpanded;
