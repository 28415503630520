import * as React from "react";
const SvgBlackFlag = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("g", { id: "IconC" }, /* @__PURE__ */ React.createElement("path", { id: "Vector", d: "M2.5 10.501C6.5 7.50097 9.5 13.501 13.5 10.501V3.00097C9.5 6.00097 6.5 0.000974417 2.5 3.00097", fill: "#1D1F20", style: {
  fill: "#1D1F20",
  fill: "color(display-p3 0.1146 0.1208 0.1239)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { id: "Vector_2", d: "M2.5 13.501V3.00104", stroke: "#A0A7AB", style: {
  stroke: "#A0A7AB",
  stroke: "color(display-p3 0.6275 0.6549 0.6706)",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { id: "Vector 13764", d: "M7.29297 5.77618L8.70718 8.22567M9.22482 6.29382L6.77533 7.70804", stroke: "white", style: {
  stroke: "white",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" })));
export default SvgBlackFlag;
