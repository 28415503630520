import { Box } from '@mui/material';
import { EditableLabel } from '../../../../common/EditableLabel';
import SweepStagesModel from '../../../../../models/stagesModel';
import { __SWEEP_DESIGN_TYPOGRAPHY_STYLES___, colors } from '@sweep-io/sweep-design';
import { ConfigurationCanvasPanelHeader } from '../../../configuration-canvas-panel';
import StageAiAgentsButton from './StageAiAgentsButton';

interface StageDialogHeaderProps {
  stageName: string;
  stageId: string;
  funnelId: string;
  readonly?: boolean;
  funnelDetails: FunnelDetails;
  onStageNameConfirm: (newName: string) => void;
  closeDialog: () => void;
}

export const StageDialogHeader = ({
  stageName,
  stageId,
  funnelId,
  readonly,
  funnelDetails,
  onStageNameConfirm,
  closeDialog,
}: StageDialogHeaderProps) => {
  return (
    <ConfigurationCanvasPanelHeader onClose={closeDialog}>
      <Box sx={{ display: 'flex', ml: '-10px', gap: 1, alignItems: 'center' }}>
        <EditableLabel
          readonly={readonly}
          value={stageName ?? ''}
          onValueConfirm={onStageNameConfirm}
          maxCharLength={40}
          height={36}
          padding={'8px'}
          inputSx={{
            ...__SWEEP_DESIGN_TYPOGRAPHY_STYLES___.h3,
            lineHeight: '36px',
            borderRadius: '6px',
            color: colors.grey[900],
          }}
          errorSx={{
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '15px',
          }}
          
          validateClbk={(value) => {
            const stagesModel = new SweepStagesModel(funnelDetails?.stages ?? []);
            const validationResult = stagesModel.validateStageName(value.trim());
            if (!validationResult.isValid) {
              return {
                isValid: false,
                error: validationResult.error,
              };
            }
            return {
              isValid: true,
            };
          }}
        />
        <StageAiAgentsButton stageId={stageId} funnelId={funnelId} />
      </Box>
    </ConfigurationCanvasPanelHeader>
  );
};
