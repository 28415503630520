import { Box, MenuItem, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { isEqual } from 'lodash';
import teamsPng from './images/teamsIcon.png';
import sfPng from './images/sfIcon.png';
import channelPng from './images/channelIcon.png';
import CloseIcon from '@mui/icons-material/Close';
import { colors } from '@sweep-io/sweep-design';
import { ChevronDown, Close } from '@sweep-io/sweep-design/dist/icons';
import { useCallback, useEffect, useState } from 'react';
import { SlackConstant } from './slack/utils';
import { StyledChip } from '../common/StyledChip';
import { parseSweepFieldPolymorphicID } from '../common/fieldsSelectors/nestedFieldsSelectorHelper';
import { SweepFieldTypes } from '../../types/enums/SweepFieldTypes';
import { ListboxComponent } from './slack/VirtualList';
import { NestedFieldsSelector } from '../common/fieldsSelectors/NestedFieldsSelector';
import { useSweepApi } from '../../apis/sweep';
import { useRunOnce } from '../common/useRunOnce';

interface TeamsRecipientsSelectProps {
  onChange: (event: any, newValues: SlackRecipientStruct[]) => void;
  handleDeleteChip: (chipToRemove: SlackRecipientStruct) => void;
  readonly?: boolean;
  recipients: SlackRecipientStruct[];
  error?: boolean;
  hideDisclaimer?: boolean;
  isLoading?: boolean;
  notConnected?: boolean;
  crmOrgId?: string;
  objectName?: string;
  customButtonText?: string;
  isAddTextFieldFromSf?: boolean;
  hideRecipientsForLgDemo?: boolean; // TODO: REMOVE with ft lgDemo
}

export const TeamsRecipientsSelect = ({
  onChange,
  handleDeleteChip,
  readonly,
  recipients,
  error,
  hideDisclaimer,
  isLoading,
  notConnected,
  crmOrgId,
  objectName,
  customButtonText,
  isAddTextFieldFromSf,
  hideRecipientsForLgDemo,
}: TeamsRecipientsSelectProps) => {
  const [teamsData, setTeamsData] = useState<SlackRecipient[]>([]);
  const [teamsListToDisplay, setTeamsListToDisplay] = useState<SlackRecipientStruct[]>([]);
  const [recipientsWithLabels, setRecipientsWithLabels] = useState<SlackRecipientStruct[]>([]);

  const sweepApi = useSweepApi();

  const getTeamsData = async () => {
    const response = await sweepApi.get(`/data-integrations/teams/recipients`);
    return response.data as SlackRecipient[];
  };
  const setDataForDisplay = (data: SlackRecipient[]) => {
    const list = data?.map((el) => {
      return {
        type: 'Teams' as SlackRecipientType,
        value: el.id,
        _label: el.type === 'user' ? `${el.label} [User]` : el.label,
      };
    });
    setTeamsListToDisplay(list);
  };

  const setRecipientsWithLabelsFromGiven = useCallback(() => {
    const list = recipients?.map((_el) => {
      const el = teamsData?.find((el) => el.id === _el?.value);
      if (el) {
        return {
          type: 'Teams' as SlackRecipientType,
          value: el.id,
          _label: el.type === 'user' ? `${el.label} [User]` : el.label,
        };
      }
    });
    setRecipientsWithLabels(list.filter((el) => el !== undefined));
  }, [recipients, teamsData]);

  useRunOnce(async () => {
    if (!hideRecipientsForLgDemo) {
      const data = await getTeamsData();
      setTeamsData(data);
      setDataForDisplay(data);
    }
  });

  useEffect(() => {
    if (teamsData.length) {
      setRecipientsWithLabelsFromGiven();
    }
  }, [recipients, setRecipientsWithLabelsFromGiven, teamsData.length]);

  const returnChipWithProps = useCallback(
    (item: SlackRecipientStruct, isDelete: boolean) => {
      let ChipProps: any = {
        key: JSON.stringify(item.value),
        label: item._label,
        title: item._label,
        size: 'small',
        icon:
          item.type === 'Teams' ? (
            <img src={teamsPng} alt={'teams icon'} style={{ width: '12px', marginLeft: '8px' }} />
          ) : item.type === SlackConstant.Sweep_Created_Slack_Channel ? (
            <img
              src={channelPng}
              alt={'channel icon'}
              style={{ width: '12px', marginLeft: '8px' }}
            />
          ) : (
            <img src={sfPng} alt={'salesforce icon'} style={{ width: '12px', marginLeft: '8px' }} />
          ),
      };
      if (isDelete) {
        ChipProps = {
          ...ChipProps,
          onDelete: () => handleDeleteChip(item),
          deleteIcon: <CloseIcon sx={{ color: '#000', fontSize: 12 }} />,
        };
      }
      return <StyledChip {...ChipProps} sx={{ textTransform: 'capitalize' }} key={ChipProps.key} />;
    },
    [handleDeleteChip],
  );

  const handleSlackRecipientsChips = useCallback(() => {
    const labels = recipients
      ? recipientsWithLabels?.map((chip) => {
          return returnChipWithProps(chip, true);
        })
      : [];
    return labels;
  }, [recipients, recipientsWithLabels, returnChipWithProps]);

  const filterByEndSelectionTextOrUser = (field: SweepField) => {
    const { isResolvable } = parseSweepFieldPolymorphicID(field.id || '');
    if (isResolvable) {
      return true;
    }
    if (isAddTextFieldFromSf && field.fieldType === SweepFieldTypes.Text) {
      return true;
    }
    if (field.fieldType === SweepFieldTypes.Lookup) {
      return Boolean(field.objectNames?.includes('User'));
    }
    return false;
  };

  return (
    <Box
      component="span"
      sx={{
        position: 'relative',
        ' .MuiAutocomplete-paper': {
          width: '100%',
          ...(!hideDisclaimer && {
            '.MuiAutocomplete-listbox::after': {
              content: '"To message a private channel, add the Sweep app as a member"',
              display: 'block',
              height: '39px',
              paddingLeft: '28px',
              lineHeight: '39px',
              fontSize: '14px',
              color: colors.grey[700],
            },
          }),
        },
        '& .MuiAutocomplete-option[aria-selected="true"]': {
          background: `${colors.grey[100]} !important`,
        },
        '& .MuiAutocomplete-root .MuiAutocomplete-inputRoot': {
          rowGap: '8px',
          paddingTop: '8px',
          paddingBottom: '8px',
          '& .MuiAutocomplete-input': {
            paddingTop: 0,
            paddingBottom: 0,
          },
          '& .MuiAutocomplete-endAdornment': {
            '& .MuiAutocomplete-clearIndicator': {
              marginRight: '4px',
            },
            ' & .MuiAutocomplete-popupIndicator': {
              marginRight: 0,
            },
          },
        },
      }}
    >
      <Autocomplete
        ListboxComponent={ListboxComponent}
        loading={isLoading}
        popupIcon={<ChevronDown />}
        clearIcon={<Close />}
        disabled={readonly || notConnected}
        multiple
        id="tags-slack"
        options={teamsListToDisplay}
        getOptionLabel={(option: SlackRecipientStruct) => option._label ?? ''}
        renderTags={handleSlackRecipientsChips}
        isOptionEqualToValue={(option: SlackRecipientStruct, savedItem: SlackRecipientStruct) => {
          if (option.type === SlackConstant.Salesforce) {
            if (
              savedItem.type === SlackConstant.Salesforce ||
              savedItem.type === SlackConstant.Salesforce_Created_Slack_Channel
            ) {
              return isEqual(
                (option.value as SlackFieldsStruct).fieldIds,
                (savedItem.value as SlackFieldsStruct).fieldIds,
              );
            }
          } else if (savedItem?.type === 'Teams') {
            return option.value === savedItem?.value;
          }

          return false;
        }}
        disablePortal
        value={notConnected ? [] : (recipientsWithLabels ?? [])}
        renderOption={(props, option: SlackRecipientStruct) => {
          return (
            <MenuItem {...props} sx={{ minWidth: '100%', width: '100%' }} key={props.key}>
              {returnChipWithProps(option, false)}
            </MenuItem>
          );
        }}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            placeholder={
              notConnected
                ? 'To add recipients, connect Teams to your production environment'
                : 'Select recipients'
            }
          />
        )}
      />

      {objectName && (
        <Box>
          <NestedFieldsSelector
            filterBy={filterByEndSelectionTextOrUser}
            readonly={readonly}
            exclusiveParentFieldType={SweepFieldTypes.Email}
            crmOrgId={crmOrgId ?? ''}
            objectType={objectName ?? ''}
            nestedPath={[]}
            customButtonEndIcon={<ChevronDown color={colors.blue[500]} />}
            useCustomButton
            customButtonText={customButtonText}
            customButtonSx={{
              marginTop: '8px',
              height: '20px',
              color: colors.blue[500],
              border: 'none',
              textTransform: 'unset',
              p: '4px 0px',
              '& span.MuiTypography-root': {
                fontSize: '14px',
                fontWeight: 500,
              },
              '&:hover': {
                background: 'transparent',
                color: colors.blue[600],
              },
            }}
            onChange={(_sweepField) => {
              const elem: SlackRecipientStruct = {
                type:
                  _sweepField?.fieldType === SweepFieldTypes.Text
                    ? SlackConstant.Salesforce_Created_Slack_Channel
                    : SlackConstant.Salesforce,
                value: {
                  fieldIds: _sweepField?.fieldIds,
                },
                _label: _sweepField?.fieldLabels?.join('.'),
              };
              onChange(null, [...(recipients ?? []), elem]);
            }}
          />
        </Box>
      )}
    </Box>
  );
};
