import { useConfigurationCanvas } from '../configuration-canvas/public/useConfigurationCanvas';
import useAutomationsCanvasInteractions from './useAutomationsCanvasInteractions';
import { colors, Tabs } from '@sweep-io/sweep-design';
import { useState } from 'react';
import {
  ConfigurationCanvasLeftPanel,
  ConfigurationCanvasPanelContent,
  ConfigurationCanvasPanelHeader,
} from '../configuration-canvas-panel';
import { MultipleRuleBuildersProvider } from '../../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';
import { Stack, Box } from '@mui/material';
import { HORIZONTAL_PADDING } from '../configuration-canvas-panel/consts';
import { useFeatureToggle } from '../../common/useFeatureToggle';
import Automations from '../../Automations/Automations';
import ScheduledJobs from '../../Automations/ScheduledJobs';

enum AutomationsDialogTabTypes {
  AUTOMATIONS = 'Automations',
  SCHEDULED_JOBS = 'Scheduled jobs',
}

export const CanvasAutomationPage = () => {
  const [activeTab, setActiveTab] = useState(AutomationsDialogTabTypes.AUTOMATIONS);
  const { canvasFunnelMap } = useConfigurationCanvas();
  const { funnelsData, recordTypesData } = canvasFunnelMap;
  const { runApex } = useFeatureToggle();
  const isTabsShown = runApex;
  const { setHoveredItem } = useAutomationsCanvasInteractions();

  return (
    <ConfigurationCanvasLeftPanel
      withExpandedModeButton={true}
      sx={{
        gap: isTabsShown ? 1 : undefined,
      }}
    >
      <ConfigurationCanvasPanelHeader withExpandedModeButton={true}>
        Automations
      </ConfigurationCanvasPanelHeader>
      <MultipleRuleBuildersProvider>
        <ConfigurationCanvasPanelContent
          sx={{
            overflow: 'hidden',
            height: '100%',
            padding: 0,
            display: 'flex',
          }}
        >
          {/* by product definition we should display tabs only when more than one tab is visible */}
          <Stack
            sx={{
              flex: 1,
              overflow: 'hidden',
              gap: 2,
              '& .MuiTabs-root': {
                display: isTabsShown ? 'flex' : 'none',
              },
              '.MuiTabs-root': {
                borderBottom: `1px solid ${colors.grey[120]}`,
              },
            }}
          >
            <Box
              sx={{
                padding: `0 ${HORIZONTAL_PADDING}px`,
              }}
            >
              <Tabs
                value={activeTab}
                tabs={[
                  {
                    label: AutomationsDialogTabTypes.AUTOMATIONS,
                    value: AutomationsDialogTabTypes.AUTOMATIONS,
                  },
                  {
                    label: AutomationsDialogTabTypes.SCHEDULED_JOBS,
                    value: AutomationsDialogTabTypes.SCHEDULED_JOBS,
                  },
                ]}
                onChange={(event, tab) => {
                  setActiveTab(tab as AutomationsDialogTabTypes);
                }}
              />
            </Box>
            {activeTab === AutomationsDialogTabTypes.AUTOMATIONS && (
              <Automations setHoveredItem={setHoveredItem} />
            )}
            {activeTab === AutomationsDialogTabTypes.SCHEDULED_JOBS && (
              <ScheduledJobs
                funnelsData={funnelsData}
                recordTypesData={recordTypesData}
                setHoveredItem={setHoveredItem}
              />
            )}
          </Stack>
        </ConfigurationCanvasPanelContent>
      </MultipleRuleBuildersProvider>
    </ConfigurationCanvasLeftPanel>
  );
};
