import AgentDocumentationAvatar from './svg/agentDocumentationAvatar.svg?react';
import AgentDocumentationIcon from './svg/agentDocumentationIcon.svg?react';
import AgentProcessAvatar from './svg/agentProcessAvatar.svg?react';
import AgentMonitoringAvatar from './svg/agentMonitoringAvatar.svg?react';
import AgentProcessIcon from './svg/agentProcessIcon.svg?react';
import AgentMonitoringIcon from './svg/agentMonitoringIcon.svg?react';
import ChatDocumentationIcon from './svg/chatDocumentationIcon.svg?react';
import ChatProcessIcon from './svg/chatProcessIcon.svg?react';
import ChatMonitoringIcon from './svg/chatMonitoringIcon.svg?react';
import {
  AgentFileProperties,
  AgentType,
  AI_PROCESS_OPTIMIZATION_AGENT_PLAYBOOK_USER_PROMPT,
  AI_PROCESS_OPTIMIZATION_AGENT_RECOMMENDATIONS_USER_PROMPT,
  AiAgentProperties,
  MonitoringContextComponent,
  ProcessOptimizationContextComponent,
} from '@server/ai';
import { ReactNode } from 'react';
import NewProcessAgentIcon from './svg/newProcessAgentIcon.svg?react';
import NewMonitoringAgentIcon from './svg/newMonitoringAgentIcon.svg?react';
import PotentialIssuesTableWithFilters from './PotentialIssuesTableWithFilters';
import { PotentialIssue } from '@server/homepage';

export const CHAT_AREA_MAX_BY_DESIGN = 924;
export const CHAT_AREA_SIDE_PADDING = 12;
export const CHAT_AREA_MAX_WIDTH = CHAT_AREA_MAX_BY_DESIGN + 2 * CHAT_AREA_SIDE_PADDING + 'px';

export interface AgentFile extends Omit<AgentFileProperties, 'createdAt'> {
  createdAt: string; //in the BE it is "Date" so can't reuse the type
}

export interface AiAgent extends Omit<AiAgentProperties, 'updatedAt' | 'createdAt' | 'files'> {
  updatedAt: string; //in the BE it is "Date" so can't reuse the type
  files: AgentFile[];
}

const PROCESS_AGENT_PROMPTS = [
  AI_PROCESS_OPTIMIZATION_AGENT_RECOMMENDATIONS_USER_PROMPT,
  AI_PROCESS_OPTIMIZATION_AGENT_PLAYBOOK_USER_PROMPT,
];

const MONITORING_AGENT_PROMPTS = [
  'Show urgent risks for this object',
  'Generate a technical quality score',
  'Suggest the top optimization for this object',
];

export enum PromptsLocation {
  WHOLE_CHAT_AREA = 'WHOLE_CHAT_AREA',
  BELOW_CONTEXT = 'BELOW_CONTEXT',
}

type BaseAgentProps = {
  nameForComingSoon: string;
  persona: string;
  description: string;
  tags: string[];
  agentIcon: ReactNode;
  addingFiles?: {
    isAllowed: boolean;
    placeholder: string;
  };
  create: {
    isAllowed: boolean;
    nameInCard?: string;
    nameInMenu?: string;
    description?: string;
    icon?: ReactNode;
  };
  assistantAvatar: ReactNode;
  chatIcon: ReactNode;
  introMessage?: {
    text: string;
  };
  prompts?: {
    timing: PromptsLocation;
    texts: string[];
  };
  paywallNumberOfAllowedUserMessages: number;
  paywallNumberOfAllowedAgents?: number; //undefined = no limit
};

type MonitoringAgentProps = BaseAgentProps & {
  RenderContext?: React.FC<{
    chatContextComponents: PotentialIssue[];
    agentContext: MonitoringContextComponent;
  }>;
};

type ProcessAgentProps = BaseAgentProps & {
  RenderContext?: React.FC<{
    agentContext: ProcessOptimizationContextComponent[];
  }>;
};

type DocumentationAgentProps = BaseAgentProps & {
  RenderContext?: undefined;
};

const DOCUMENTATION_FIRST_MSG = `Document your Salesforce configuration, automatically. Ask anything about your metadata to understand how your org is built and connected.`;

export const AI_AGENTS_INFO: {
  [AgentType.Documentation]: DocumentationAgentProps;
  [AgentType.Monitoring]: MonitoringAgentProps;
  [AgentType.ProcessOptimization]: ProcessAgentProps;
} = {
  [AgentType.Documentation]: {
    nameForComingSoon: 'Documentation Agent',
    persona: 'Salesforce Architect',
    description: 'Generates real-time documentation of Salesforce configurations and dependencies',
    tags: ['Map dependencies', 'Troubleshoot errors'],
    agentIcon: <AgentDocumentationIcon />,
    create: {
      isAllowed: false,
    },
    assistantAvatar: <AgentDocumentationAvatar />,
    chatIcon: <ChatDocumentationIcon />,
    introMessage: {
      text: DOCUMENTATION_FIRST_MSG,
    },
    paywallNumberOfAllowedUserMessages: 1,
  },
  [AgentType.ProcessOptimization]: {
    nameForComingSoon: 'Process Optimization Agent',
    persona: 'Business Analyst',
    description: 'Detects bottlenecks and recommends improvements to optimize your process',
    tags: ['Analyze business process', 'Optimize processes'],
    agentIcon: <AgentProcessIcon />,
    addingFiles: {
      isAllowed: true,
      placeholder: `e.g., "Focus on conversion of MQL --> Demo Booked. Keep answers short an focused. Elaborate on the issues your recommendations address."`,
    },
    create: {
      isAllowed: true,
      nameInCard: 'Create a new process optimization agent',
      nameInMenu: 'Process Optimization',
      description: 'Detect bottlenecks and opportunities in your process',
      icon: <NewProcessAgentIcon />,
    },
    assistantAvatar: <AgentProcessAvatar />,
    chatIcon: <ChatProcessIcon />,
    prompts: {
      texts: PROCESS_AGENT_PROMPTS,
      timing: PromptsLocation.WHOLE_CHAT_AREA,
    },
    paywallNumberOfAllowedUserMessages: 2,
  },
  [AgentType.Monitoring]: {
    nameForComingSoon: 'Monitoring Agent',
    persona: 'Salesforce Architect',
    description: 'Identifies potential issues and risks in your Salesforce configuration',
    tags: ['Diagnose Errors', 'Find Inefficiencies '],
    agentIcon: <AgentMonitoringIcon />,
    addingFiles: {
      isAllowed: true,
      placeholder: `e.g., "Focus on issues in Apex code. Keep answers short and focused. Highlight key violations and recommended fixes."`,
    },
    create: {
      isAllowed: true,
      nameInCard: 'Create a new monitoring agent',
      nameInMenu: 'Monitoring',
      description: 'Identify potential issues in your Salesforce configuration',
      icon: <NewMonitoringAgentIcon />,
    },
    assistantAvatar: <AgentMonitoringAvatar />,
    chatIcon: <ChatMonitoringIcon />,
    prompts: {
      texts: MONITORING_AGENT_PROMPTS,
      timing: PromptsLocation.BELOW_CONTEXT,
    },
    paywallNumberOfAllowedUserMessages: 1,
    paywallNumberOfAllowedAgents: 1,
    RenderContext: PotentialIssuesTableWithFilters,
  },
};

export const SUPPORTED_TYPES_FOR_CREATION = Object.entries(AI_AGENTS_INFO)
  .filter(([, props]) => props.create.isAllowed)
  .map(([type]) => type as AgentType);

export const TOP_BAR_COMMON_SX = {
  display: 'flex',
  alignItems: 'center',
  height: '56px',
  padding: '12px',
  gap: '8px',
};

export type NewAgentFile = Omit<AgentFile, 'id'>;

export type AgentFileInvalid = {
  id: string;
  name: string;
  message: string;
};
