import * as React from "react";
const SvgChatMonitoringIcon = (props) => /* @__PURE__ */ React.createElement("svg", { width: 18, height: 20, viewBox: "0 0 18 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("circle", { cx: 6, cy: 7, r: 6, fill: "#ACE6F9", style: {
  fill: "#ACE6F9",
  fill: "color(display-p3 0.6745 0.9020 0.9765)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M2.27798 4.58129C1.99281 3.92838 2.64284 3.25821 3.30415 3.52332L3.43256 3.5748C3.63631 3.65648 3.86369 3.65648 4.06744 3.5748L5.55347 2.97906C5.84007 2.86416 6.15993 2.86416 6.44653 2.97906L7.93256 3.5748C8.13631 3.65648 8.36369 3.65648 8.56744 3.5748L8.69585 3.52332C9.35716 3.25821 10.0072 3.92838 9.72202 4.58129C9.63326 4.78453 9.63326 5.01557 9.72202 5.21881L10.2902 6.51975C10.424 6.82598 10.424 7.17412 10.2902 7.48035L9.72202 8.78129C9.63326 8.98453 9.63326 9.21557 9.72202 9.41881C10.0072 10.0717 9.35716 10.7419 8.69585 10.4768L8.56744 10.4253C8.36369 10.3436 8.13631 10.3436 7.93256 10.4253L6.44653 11.021C6.15993 11.1359 5.84007 11.1359 5.55347 11.021L4.06744 10.4253C3.86369 10.3436 3.63631 10.3436 3.43256 10.4253L3.30415 10.4768C2.64284 10.7419 1.99281 10.0717 2.27798 9.41881C2.36674 9.21557 2.36674 8.98453 2.27798 8.78129L1.70978 7.48035C1.57603 7.17412 1.57603 6.82598 1.70978 6.51975L2.27798 5.21881C2.36674 5.01557 2.36674 4.78453 2.27798 4.58129Z", fill: "#DEFBFF", style: {
  fill: "#DEFBFF",
  fill: "color(display-p3 0.8706 0.9843 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 4.5, y: 6.5, width: 13, height: 13, rx: 3.5, fill: "#CBD5DB", style: {
  fill: "#CBD5DB",
  fill: "color(display-p3 0.7961 0.8351 0.8577)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 4.5, y: 6.5, width: 13, height: 13, rx: 3.5, stroke: "white", style: {
  stroke: "white",
  strokeOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M11.0001 16.5999C11.7201 16.5999 12.6801 16.1199 12.6801 16.1199L14.2988 16.5999C14.3385 16.6004 14.3779 16.593 14.4147 16.578C14.4515 16.5631 14.4849 16.5409 14.513 16.5128C14.5411 16.4847 14.5633 16.4513 14.5782 16.4145C14.5932 16.3777 14.6006 16.3383 14.6001 16.2986L14.1201 15.1599C14.5782 14.3775 14.6001 12.9999 14.6001 12.9999C14.6001 12.0451 14.2208 11.1294 13.5457 10.4543C12.8705 9.77919 11.9549 9.3999 11.0001 9.3999C10.5273 9.3999 10.0592 9.49302 9.62243 9.67393C9.18566 9.85485 8.7888 10.12 8.45451 10.4543C8.12022 10.7886 7.85505 11.1855 7.67413 11.6222C7.49321 12.059 7.4001 12.5271 7.4001 12.9999C7.4001 13.4726 7.49321 13.9408 7.67413 14.3775C7.85505 14.8143 8.12022 15.2112 8.45451 15.5455C8.7888 15.8798 9.18566 16.1449 9.62243 16.3258C10.0592 16.5068 10.2801 16.5999 11.0001 16.5999Z", stroke: "#214B64", style: {
  stroke: "#214B64",
  stroke: "color(display-p3 0.1299 0.2942 0.3928)",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M9.5 12L13 12", stroke: "#214B64", style: {
  stroke: "#214B64",
  stroke: "color(display-p3 0.1299 0.2942 0.3928)",
  strokeOpacity: 1
}, strokeWidth: 0.75, strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M9.5 14L12 14", stroke: "#214B64", style: {
  stroke: "#214B64",
  stroke: "color(display-p3 0.1299 0.2942 0.3928)",
  strokeOpacity: 1
}, strokeWidth: 0.75, strokeLinejoin: "round" }));
export default SvgChatMonitoringIcon;
