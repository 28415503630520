import { Box, Menu, MenuItem, TextField, InputAdornment, IconButton, Divider } from '@mui/material';
import { Button, colors, Typography } from '@sweep-io/sweep-design';
import { ChevronRight, Close, Check, ArrowLeft } from '@sweep-io/sweep-design/dist/icons';
import { useCallback, useState, useMemo } from 'react';
import { TeamsRecipientInfo } from './TeamsMessageAction';
import { useIntegrations } from '../../hooks/useIntegrations';
import teamsPng from './images/teamsIcon.png';

interface TeamsRecipientsNestedSelectorProps {
  onChange: (recipient: TeamsRecipientInfo) => void;
  readonly?: boolean;
  customButtonText?: string;
  selectedRecipients?: TeamsRecipientInfo[];
}

export const TeamsRecipientsNestedSelector = ({
  onChange,
  readonly,
  customButtonText = 'Add Recipient',
  selectedRecipients = [],
}: TeamsRecipientsNestedSelectorProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedTeam, setSelectedTeam] = useState<{ id: string; label: string } | null>(null);
  const [menuItems, setMenuItems] = useState<TeamsRecipientInfo[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const { getTeamsRecipients, getTeamsChannels } = useIntegrations();

  const filteredMenuItems = useMemo(() => {
    if (!searchQuery) return menuItems;
    return menuItems.filter((item) => item.label.toLowerCase().includes(searchQuery.toLowerCase()));
  }, [menuItems, searchQuery]);

  const isItemSelected = useCallback(
    (recipient: TeamsRecipientInfo) => {
      return selectedRecipients.some((selected) => selected.id === recipient.id);
    },
    [selectedRecipients],
  );

  const handleClick = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      if (readonly) return;
      setAnchorEl(event.currentTarget);
      setSelectedTeam(null);
      setIsLoading(true);
      try {
        const recipients = await getTeamsRecipients();
        setMenuItems(recipients);
      } finally {
        setIsLoading(false);
      }
    },
    [readonly, getTeamsRecipients],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setSelectedTeam(null);
    setSearchQuery('');
  }, []);

  const handleMenuItemClick = useCallback(
    async (recipient: TeamsRecipientInfo) => {
      if (recipient.type === 'team') {
        setIsLoading(true);
        try {
          const channels = await getTeamsChannels(recipient.id);
          setSelectedTeam({ id: recipient.id, label: recipient.label });
          setMenuItems(channels);
        } finally {
          setIsLoading(false);
        }
      } else {
        onChange(recipient);
        handleClose();
      }
    },
    [getTeamsChannels, onChange, handleClose],
  );

  const handleBack = useCallback(async () => {
    setIsLoading(true);
    try {
      const recipients = await getTeamsRecipients();
      setSelectedTeam(null);
      setMenuItems(recipients);
    } finally {
      setIsLoading(false);
    }
  }, [getTeamsRecipients]);

  const handleClearSearch = useCallback(() => {
    setSearchQuery('');
  }, []);

  return (
    <Box>
      <Button
        onClick={handleClick}
        startIconName="Plus"
        variant="flat"
        disabled={readonly || isLoading}
      >
        {customButtonText}
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ p: 1 }}
        PaperProps={{
          sx: {
            maxHeight: 300,
            minWidth: '310px',
            mt: 1,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <Box
          sx={{
            p: 1,
            backgroundColor: colors.white,
            position: 'sticky',
            top: 0,
            zIndex: 1,
          }}
        >
          <TextField
            fullWidth
            size="small"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              endAdornment: searchQuery ? (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={handleClearSearch}
                    sx={{ color: colors.grey[500] }}
                  >
                    <Close />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                height: '32px',
                fontSize: '14px',
              },
            }}
          />
        </Box>

        <Box sx={{ overflow: 'auto' }}>
          {selectedTeam && (
            <>
              <MenuItem onClick={handleBack}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <ArrowLeft />
                  <Typography variant="body-bold">Back to {selectedTeam.label}</Typography>
                </Box>
              </MenuItem>
              <Divider />
            </>
          )}
          {filteredMenuItems.length > 0 ? (
            filteredMenuItems.map((recipient) => {
              const isSelected = isItemSelected(recipient);
              return (
                <MenuItem
                  key={recipient.id}
                  onClick={() => (isSelected ? undefined : handleMenuItemClick(recipient))}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    justifyContent: 'space-between',
                    backgroundColor: isSelected ? colors.grey[100] : 'transparent',
                    '&:hover': {
                      backgroundColor: isSelected ? colors.grey[200] : colors.grey[100],
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <img src={teamsPng} style={{ width: '16px', height: '16px' }} />
                    <Typography variant={'body'}>{recipient.label}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {isSelected && <Check color={colors.blue[500]} />}
                    {recipient.type === 'team' && <ChevronRight color={colors.grey[500]} />}
                  </Box>
                </MenuItem>
              );
            })
          ) : !isLoading ? (
            <MenuItem disabled>
              <Typography variant={'body'} color={colors.grey[600]}>
                No results found
              </Typography>
            </MenuItem>
          ) : null}
          {isLoading && (
            <MenuItem disabled>
              <Typography variant={'body'}>Loading...</Typography>
            </MenuItem>
          )}
        </Box>
      </Menu>
    </Box>
  );
};
