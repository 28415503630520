import { Tabs, colors } from '@sweep-io/sweep-design';
import {
  ConfigurationCanvasLeftPanel,
  ConfigurationCanvasPanelHeader,
  ConfigurationCanvasPanelContent,
} from '../../configuration-canvas-panel';
import { PanelCampaignsTab } from './PanelCampaignsTab';
import { useLoadHubspotFieldsAndWorkflows as useLoadHubspotFieldsAndWorkflows__deprecated } from './HubspotCanvasPanel';
import { useLoadHubspotFieldsAndWorkflows } from './campaign-dialog/new/HubspotCanvasPanel';
import { useState } from 'react';
import { Stack } from '@mui/material';
import { useExpandedMode } from '../../configuration-canvas/panels-reserve-space/ReserveSpaceForPanelsCtx';
import { AttributionModelsPanel } from './marketing-attribution/AttributionModelsPanel';
import { useFeatureToggle } from '../../../common/useFeatureToggle';
import { HubspotOrgBlockedViews } from './unhappy-hubspot-pages/HubspotOrgBlockedViews';
import { CenteredCircularProgress } from '../../../common/CenteredCircularProgress';
import { useHubspotUnhappyStates } from './useHubspotUnhappyStates';
import { useRunOnce } from '../../../common/useRunOnce';

enum MarketingAttributionTabs {
  CAMPAIGN_ENROLLMENTS = 'CAMPAIGN_ENROLLMENTS',
  MODELS = 'MODELS',
}

export const MarketingAttributionPanel = () => {
  const { hubspotV2Ft3 } = useFeatureToggle();
  const { setIsExpandedMode } = useExpandedMode();
  const [activeTab, setActiveTab] = useState<MarketingAttributionTabs>(
    MarketingAttributionTabs.CAMPAIGN_ENROLLMENTS,
  );
  const { connectedHubspotOrg: connectedHubspotOrg__deprecated, crmOrgId: crmOrgId__deprecated } =
    useLoadHubspotFieldsAndWorkflows__deprecated();
  const { connectedHubspotOrg, crmOrgId } = useLoadHubspotFieldsAndWorkflows();
  const _crmOrgId = hubspotV2Ft3 ? crmOrgId : crmOrgId__deprecated;
  const _connectedHubspotOrg = hubspotV2Ft3 ? connectedHubspotOrg : connectedHubspotOrg__deprecated;
  const { isRenderHubspotConnectPage, isRenderHubspotNotInProductionPage } =
    useHubspotUnhappyStates();

  const _isExpandedMode = (_activeTab: MarketingAttributionTabs) =>
    _activeTab === MarketingAttributionTabs.MODELS ||
    (_activeTab === MarketingAttributionTabs.CAMPAIGN_ENROLLMENTS &&
      (isRenderHubspotConnectPage || isRenderHubspotNotInProductionPage));

  useRunOnce(() => {
    setIsExpandedMode(_isExpandedMode(activeTab));
  });

  return (
    <>
      <ConfigurationCanvasLeftPanel
        expandedModeWithoutButton={_isExpandedMode(activeTab)}
        sx={{
          '.configuration-canvas-panel-content': {
            overflow: 'hidden',
          },
        }}
      >
        <ConfigurationCanvasPanelHeader>Marketing Attribution</ConfigurationCanvasPanelHeader>
        <ConfigurationCanvasPanelContent sx={{ padding: 0 }}>
          <Stack
            sx={{
              padding: `0 10px 0 16px`,
              '.MuiTabs-flexContainer': {
                gap: 2,
              },
              '.MuiTabs-root': {
                borderBottom: `1px solid ${colors.grey[200]}`,
              },
            }}
          >
            <Tabs
              value={activeTab}
              tabs={[
                {
                  label: 'Campaign Enrollment',
                  value: MarketingAttributionTabs.CAMPAIGN_ENROLLMENTS,
                },
                {
                  label: 'Campaign Attribution Model',
                  value: MarketingAttributionTabs.MODELS,
                },
              ]}
              onChange={(event, tab) => {
                setActiveTab(tab as MarketingAttributionTabs);
                //By product definition - models tab only exists in expanded mode
                setIsExpandedMode(_isExpandedMode(tab as MarketingAttributionTabs));
              }}
            />
          </Stack>

          <Stack sx={{ height: '100%', pt: 2, overflow: 'hidden' }}>
            {activeTab === MarketingAttributionTabs.CAMPAIGN_ENROLLMENTS ? (
              <HubspotOrgBlockedViews>
                {!_connectedHubspotOrg?.id || !_crmOrgId ? (
                  <CenteredCircularProgress />
                ) : (
                  <PanelCampaignsTab crmOrgId={_crmOrgId} hsOrgId={_connectedHubspotOrg?.id} />
                )}
              </HubspotOrgBlockedViews>
            ) : null}

            {activeTab === MarketingAttributionTabs.MODELS && <AttributionModelsPanel />}
          </Stack>
        </ConfigurationCanvasPanelContent>
      </ConfigurationCanvasLeftPanel>
    </>
  );
};
