import { Stack } from '@mui/material';
import { BookADemoButton } from './BookADemoButton';
import { ContentWithImage } from './ContentWithImage';
import { HeaderWithPaywallCta } from './HeaderWithPaywallCta';
import { PageTitle } from './PageTitle';
import { getPageData } from './utils';
import { HeaderWithConnectToSfButtons } from './HeaderWithConnectToSfButtons';
import usePaywall from '../../usePaywall';
import { useRunOnceWhenTruthy } from '../../../../common/useRunOnceWhenTruthy';
import { useRef } from 'react';

export const PageLayout = ({
  pathname,
  withConnectToSfButtons,
}: {
  pathname: string;
  withConnectToSfButtons?: boolean;
}) => {
  const data = getPageData(pathname);
  const { sendEncounterPaywallPageEvent } = usePaywall();
  const isFirstEntry = useRef(false);

  useRunOnceWhenTruthy(() => {
    if (!isFirstEntry.current && data?.headerData.source) {
      isFirstEntry.current = true;
      sendEncounterPaywallPageEvent({ source: data.headerData.source });
    }
  }, !!data?.headerData.source);

  if (!data) {
    return;
  }

  const { headerData, pageTitle, section1, section2, section3 } = data;

  return (
    <Stack alignItems="center">
      {withConnectToSfButtons && (
        <HeaderWithConnectToSfButtons {...headerData} name={headerData.name ?? ''} />
      )}
      {!withConnectToSfButtons && (
        <HeaderWithPaywallCta {...headerData} name={headerData.name ?? ''} />
      )}
      <PageTitle pageTitle={pageTitle} />
      <ContentWithImage {...section1} imgPosition="left" pt="0" />
      <ContentWithImage {...section2} imgPosition="right" />
      <ContentWithImage {...section3} imgPosition="left" />
      <BookADemoButton source={headerData.source} />
    </Stack>
  );
};
