import Box from '@mui/material/Box';
import {
  AutomationVersionHistoryFilterOptions,
  AutomationVersionHistoryTableFilters,
} from './AutomationVersionHistoryDialog';
import { colors, Typography } from '@sweep-io/sweep-design';
import { CustomDatePicker, DateBy, DateKeys } from '../common/datepicker/types';
import { AdvancedFilter } from '../common/advanced-filter/AdvancedFilter';
import { DatePickerWrapper } from '../common/datepicker/DatePickerWrapper';
export const DEFAULT_DATEPICKER_STATE = DateKeys.Last28Days;

interface AutomationVersionHistoryFiltersProps {
  setFilter: (newFilter: Partial<AutomationVersionHistoryTableFilters>) => void;
  disableFilters: boolean;
  sortAndFilter: AutomationVersionHistoryTableFilters;
  filterOptions: AutomationVersionHistoryFilterOptions;
}

export const AutomationVersionHistoryFilters = ({
  setFilter,
  disableFilters,
  filterOptions,
  sortAndFilter,
}: AutomationVersionHistoryFiltersProps) => {
  const statusItemsText = filterOptions?.status?.map((item) => {
    return {
      label: item,
      value: item,
    };
  });

  const deployedByNameItemsText = filterOptions?.deployedByName?.map((item) => {
    return {
      label: item,
      value: item,
    };
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
        alignItems: 'center',
      }}
    >
      <AdvancedFilter
        texts={{ title: 'Deployed by' }}
        selectedItems={sortAndFilter.deployedByName ?? []}
        items={deployedByNameItemsText ?? []}
        onSelectedItemsChange={(items: string[]) => {
          setFilter({
            deployedByName: items.length ? items : filterOptions?.deployedByName,
          });
        }}
        disabled={disableFilters}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <Typography variant="body" color={colors.grey[900]}>
          Deployed at:
        </Typography>

        <DatePickerWrapper
          onSelectDate={(value: CustomDatePicker) => {
            setFilter({
              deployedAtStartTime: value[DateBy.From],
              deployedAtEndTime: value[DateBy.To],
            });
          }}
          disabled={disableFilters}
          defaultValue={DEFAULT_DATEPICKER_STATE}
        />
      </Box>
      <AdvancedFilter
        texts={{ title: 'Status' }}
        selectedItems={sortAndFilter.status ?? []}
        items={statusItemsText ?? []}
        onSelectedItemsChange={(items: string[]) =>
          setFilter({
            status: items.length ? items : filterOptions?.status,
          })
        }
        disabled={disableFilters}
      />
    </Box>
  );
};
