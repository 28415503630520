import { useCallback, useEffect, useState } from 'react';
import { useDiffApiFacade } from '../../../../../apis/facades/useDiffApiFacade';
import { Box, Grid } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { ArrowRight } from '@sweep-io/sweep-design/dist/icons';
import React from 'react';
import { CenteredCircularProgress } from '../../../../common/CenteredCircularProgress';
import { TextWithMentions } from '../../../../common/comments/TextWithMentions';

type DiffItem = { message: string; before: string; after: string };

export const DeploymentDiff = ({
  snapshotId,
  funnelId,
  crmOrgId,
}: {
  snapshotId?: string;
  funnelId?: string;
  crmOrgId?: string;
}) => {
  const { get_diffBySnapshotId, get_diffByFunnelId } = useDiffApiFacade();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [diffResults, setDiffResults] = useState<DiffItem[]>([]);

  const fetchDiff = useCallback(() => {
    if (snapshotId && crmOrgId) {
      return get_diffBySnapshotId({ snapshotId, crmOrgId });
    }
    if (funnelId && crmOrgId) {
      return get_diffByFunnelId({ funnelId, crmOrgId });
    }
  }, [crmOrgId, funnelId, get_diffByFunnelId, get_diffBySnapshotId, snapshotId]);

  const fetchSuccess = (res: DiffItem[]) => {
    setIsLoading(false);
    setDiffResults(res);
  };
  const fetchError = () => {
    setIsLoading(false);
    setIsError(true);
  };

  useEffect(() => {
    fetchDiff()?.then(fetchSuccess, fetchError);
  }, [fetchDiff]);

  if (isLoading) {
    return (
      <Box m={2}>
        <CenteredCircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box mt={1}>
        <Typography variant="caption-bold" color={colors.blush[500]}>
          Sorry, we could not calculate the difference between current and deployed version
        </Typography>
      </Box>
    );
  }

  if (diffResults.length === 0) {
    return (
      <Box mt={1}>
        <Typography variant="caption-bold" color={colors.grey[800]}>
          No changes were identified
        </Typography>
      </Box>
    );
  }

  return (
    <Grid
      container
      sx={{
        border: '1px solid',
        borderColor: colors.grey[300],
        borderRadius: '4px',
      }}
    >
      <Grid item xs={1} p={1.5}>
        <Typography variant="caption-bold" color={colors.grey[500]}>
          #
        </Typography>
      </Grid>
      <Grid item xs={4} p={1.5}>
        <Typography variant="caption-bold" color={colors.grey[500]}>
          Change
        </Typography>
      </Grid>
      <Grid item xs={3} p={1.5}>
        <Typography variant="caption-bold" color={colors.grey[500]}>
          Current configuration
        </Typography>
      </Grid>
      <Grid item xs={1} p={1.5}></Grid>
      <Grid item xs={3} p={1.5}>
        <Typography variant="caption-bold" color={colors.grey[500]}>
          Requested changes
        </Typography>
      </Grid>
      {diffResults.map(({ message, before, after }, idx) => {
        return (
          <React.Fragment key={idx}>
            <Grid item xs={1} p={1.5} display="flex" alignItems="center">
              <Typography variant="caption-bold">{idx + 1}</Typography>
            </Grid>
            <Grid item xs={4} p={1.5} display="flex" alignItems="center">
              <Typography variant="body">
                <TextWithMentions text={message} highlightBold highlightColor={colors.grey[900]} />
              </Typography>
            </Grid>
            <Grid item xs={3} p={1.5} display="flex" alignItems="center">
              <Typography variant="body">
                <TextWithMentions text={before} highlightBold highlightColor={colors.grey[900]} />
              </Typography>
            </Grid>
            <Grid item xs={1} p={1.5} display="flex" alignItems="center">
              {before && after && <ArrowRight />}
            </Grid>
            <Grid item xs={3} p={1.5} display="flex" alignItems="center">
              <Typography variant="body">
                <TextWithMentions text={after} highlightBold highlightColor={colors.grey[900]} />
              </Typography>
            </Grid>
          </React.Fragment>
        );
      })}
    </Grid>
  );
};
