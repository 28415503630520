export const automationsSortOptions = [
  {
    label: 'Last updated',
    value: 'LAST_UPDATED',
    dataAccessor: 'updatedAt',
  },
  {
    label: 'Automation name',
    value: 'AUTOMATION_NAME',
    dataAccessor: 'name',
  },

  {
    label: 'Action type',
    value: 'ACTION_TYPE',
    dataAccessor: 'automationDetails.actions[0].actionType',
  },
  {
    label: 'Active first',
    value: 'ACTIVE_FIRST',
    dataAccessor: 'isActive',
    getManualValue: (automation: AutomationStructureNew) => {
      return automation?.isActive ? '0' : '1';
    },
  },
];

export const assignmentsSortOptions = [
  {
    label: 'Last updated',
    value: 'LAST_UPDATED',
    dataAccessor: 'updatedAt',
  },
  {
    label: 'Name',
    value: 'ASSIGNMENT_NAME',
    dataAccessor: 'name',
  },

  {
    label: 'Object',
    value: 'OBJECT',
    dataAccessor: 'objectName',
  },
];

export const dedupMatchingSortOptions = [
  {
    label: 'Last updated',
    value: 'LAST_UPDATED',
    dataAccessor: 'updatedAt',
  },
  {
    label: 'Order of execution',
    value: 'ORDER',
    dataAccessor: 'objectName',
  },
  {
    label: 'Name',
    value: 'DEDUP_MATCHING_NAME',
    dataAccessor: 'name',
  },
];

export const scheduledAssignmentsSortOptions = [
  ...assignmentsSortOptions,
  {
    label: 'End date',
    value: 'END_DATE',
    dataAccessor: 'automationDetails.automationParams.schedule.endDate',
  },
  {
    label: 'Start date',
    value: 'START_DATE',
    dataAccessor: 'automationDetails.automationParams.schedule.startDate',
    getManualValue: (automation: AutomationStructureNew) => {
      const startDate = automation?.automationDetails?.automationParams?.schedule?.startDate ?? '';
      const startTime =
        automation?.automationDetails?.automationParams?.schedule?.executeTime ?? '';
      return new Date(startDate + 'T' + startTime).toISOString();
    },
  },
];

export const scheduledJobsSortOptions = [
  {
    label: 'Last updated',
    value: 'LAST_UPDATED',
    dataAccessor: 'updatedAt',
  },
  {
    label: 'Name',
    value: 'ASSIGNMENT_NAME',
    dataAccessor: 'name',
  },
];
