import { ReactNode, memo } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';
import _isNil from 'lodash/isNil';
import StepDragIcon from '../svg/step-drag.svg?react';
import StyledTooltip from '../../common/StyledTooltip';
import { NODE_WIDTH, NODE_HEIGHT } from '../const';
import {
  actionButtonDimensions,
  ActionButtonAdd,
  ActionButtonNurturingStep,
  ActionButtonConnectStep,
} from './components/CanvasButtons';
import { NodeComponent } from './components/SweepNodeComponent';
import { SweepNodeHandles } from './SweepNodeHandles';
import { DropInvalidReason } from '../useCalculateDragAndDrop';
import { colors, Typography } from '@sweep-io/sweep-design';
import { Sparkle } from '@sweep-io/sweep-design/dist/icons';
import { TruncatedTextTooltip } from '../../common/TruncatedTextTooltip';
import { NodeEntityTypes } from '../canvas-types';
import { Pills } from './components/pills/Pills';
import { RfNodeRegularNode } from '../canvas-types/nodeTypesData';
import { NodeProps } from '@xyflow/react';

const invalidReasonTooltipTextMap = {
  [DropInvalidReason.OVERLAP]: 'Positions cannot overlap',
};

const MetadataChip = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      borderRadius: '2px',
      display: 'flex',
      padding: '3px 8px',
    }}
  >
    <Typography variant="caption">{children}</Typography>
  </Box>
);

export const RegularStep = memo(
  ({
    id,
    selected,
    dragging,
    data: {
      name,
      objectType,
      onAddNodeClick,
      onNodeClick,
      onPillClick,
      onConnectClick,
      readonly,
      dropInvalidReason,
      isHighlighted: _isHighlighted,
      hasSourceButton,
      hasTargetButton,
      showButtonsOnHighlight,
      probability,
      forecastCategoryLabel,
      parentId,
      pills,
      disableStepClick,
      hideNbButton,
      showSparkleIcon,
      isNb,
      nodeVisibility,
      simpleHighlighted,
    },
  }: NodeProps<RfNodeRegularNode>) => {
    const isHighlighted = !dragging && _isHighlighted;

    const nodeHorizontalCenter = NODE_WIDTH / 2 - actionButtonDimensions.width / 2;
    const nodeVerticalCenter = NODE_HEIGHT / 2 - actionButtonDimensions.height / 2;

    const renderAddBranch = () => {
      return (
        <Box
          sx={{
            position: 'absolute',
            left: nodeHorizontalCenter,
            top: -45,
            cursor: 'default',
          }}
        >
          <ActionButtonAdd
            onClick={() =>
              onAddNodeClick({
                buttonPosition: 'top',
                sourceNodeId: id,
              })
            }
          />
        </Box>
      );
    };

    const renderAddSource = () => {
      return (
        <Box
          sx={{
            position: 'absolute',
            left: -45,
            top: nodeVerticalCenter,
            cursor: 'default',
          }}
        >
          <ActionButtonAdd
            onClick={() =>
              onAddNodeClick({
                buttonPosition: 'left',
                sourceNodeId: id,
              })
            }
          />
        </Box>
      );
    };

    const renderAddTarget = () => {
      return (
        <Box
          sx={{
            position: 'absolute',
            right: -45,
            top: nodeVerticalCenter,
            cursor: 'default',
          }}
        >
          <ActionButtonAdd
            onClick={() =>
              onAddNodeClick({
                buttonPosition: 'right',
                sourceNodeId: id,
              })
            }
          />
        </Box>
      );
    };

    const renderAddNurturingBucket = () => {
      if (hideNbButton) return null;
      return (
        <Box
          sx={{
            position: 'absolute',
            left: nodeHorizontalCenter,
            cursor: 'default',
            top: pills?.length ? NODE_HEIGHT + 35 : NODE_HEIGHT + 14,
          }}
        >
          <ActionButtonNurturingStep
            onClick={() =>
              onAddNodeClick({
                buttonPosition: 'bottom',
                sourceNodeId: id,
              })
            }
          />
        </Box>
      );
    };

    const renderConnectStepButton = () => (
      <Box
        sx={{
          position: 'absolute',
          right: 0,
          top: -45,
          cursor: 'default',
        }}
      >
        <ActionButtonConnectStep
          onClick={
            onConnectClick
              ? (event) =>
                  onConnectClick(event, {
                    stepId: id,
                    groupId: parentId,
                  })
              : undefined
          }
        />
      </Box>
    );

    const renderSparkleIcon = () => (
      <Box
        sx={{
          backgroundColor: colors.blue[500],
          height: '20px',
          width: '20px',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          zIndex: 1,
          right: '-5px',
          top: '-5px',
        }}
      >
        <Sparkle variant="tiny" color={colors.white} />
      </Box>
    );

    const isInvalidPosition = !!dropInvalidReason;
    const dropInvalidTooltip = dropInvalidReason && invalidReasonTooltipTextMap[dropInvalidReason];

    const showButtons = isHighlighted && showButtonsOnHighlight;
    const showProbability = !_isNil(probability);

    const nodeVisibilityIsOnlyStep = nodeVisibility === 'onlyStep';
    const nodeVisibilityIsDoNotShowMetadata = nodeVisibility === 'doNotShowMetadata';
    const nodeVisibilityIsShowAll = nodeVisibility === 'showAll';

    const showPills = nodeVisibilityIsShowAll && pills;
    const showInnerContent = nodeVisibilityIsShowAll || nodeVisibilityIsDoNotShowMetadata;
    const showMetadata = nodeVisibilityIsShowAll && (showProbability || forecastCategoryLabel);

    return (
      <SweepNodeHandles>
        {showSparkleIcon && !nodeVisibilityIsOnlyStep && renderSparkleIcon()}
        {showButtons && (
          <>
            {hasSourceButton && renderAddSource()}
            {hasTargetButton && renderAddTarget()}
            {!isNb && (
              <>
                {renderAddBranch()}
                {renderAddNurturingBucket()}
              </>
            )}
            {renderConnectStepButton()}
          </>
        )}
        <StyledTooltip
          title={dropInvalidTooltip}
          open={isInvalidPosition}
          placement="top"
          sx={{
            maxWidth: 'inherit',
            zIndex: '5 !important', //force overwrite to avoid errors appearing over dialogs
          }}
        >
          <NodeComponent
            data-testid="sweep-canvas-regular-node"
            className={classNames({
              highlighted:
                !nodeVisibilityIsOnlyStep && (isHighlighted || selected || simpleHighlighted),
              'invalid-position': isInvalidPosition,
              'nb-node': isNb,
              shadow: !nodeVisibilityIsOnlyStep,
              [`node-${objectType}`]: true,
              'has-opacity': nodeVisibilityIsOnlyStep,
              'disable-step-click': disableStepClick,
            })}
          >
            <Box
              onClick={
                onNodeClick
                  ? (event) =>
                      onNodeClick({
                        id,
                        parentId,
                        event,
                        entity: {
                          type: NodeEntityTypes.Step,
                        },
                      })
                  : undefined
              }
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
                padding: '0 27px 0 12px',
                gap: '6px',
              }}
            >
              {showInnerContent && (
                <>
                  <TruncatedTextTooltip
                    variant={nodeVisibilityIsDoNotShowMetadata ? 'body-medium' : 'h4'}
                  >
                    {name}
                  </TruncatedTextTooltip>
                  {showMetadata && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        gap: '4px',
                      }}
                      className="metadata-data"
                    >
                      {showProbability && <MetadataChip>{probability + ''}</MetadataChip>}
                      {forecastCategoryLabel && (
                        <MetadataChip>{forecastCategoryLabel}</MetadataChip>
                      )}
                    </Box>
                  )}
                </>
              )}

              {(dragging || isHighlighted) && !readonly && !nodeVisibilityIsOnlyStep && (
                <Box
                  className="drag-handle"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: 40,
                    height: '100%',
                    cursor: 'grab',
                    '& svg': {
                      fill: colors.blue[500],
                    },

                    '&:active': {
                      cursor: 'grabbing',
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      minHeight: '100%',
                      paddingLeft: '20px',
                      svg: {
                        width: '10px',
                        height: '20px',
                      },
                    }}
                  >
                    <StepDragIcon />
                  </Box>
                </Box>
              )}
            </Box>
            {showPills && (
              <Box
                sx={{
                  marginTop: '12px', // 10px padding + 2px of shadow
                }}
              >
                <Pills
                  nodeId={id}
                  nodeLabel={name}
                  parentNodeId={parentId}
                  objectType={objectType}
                  onPillClick={onPillClick}
                  pills={pills}
                  entityType={NodeEntityTypes.StepPill}
                  pillsMax={4}
                />
              </Box>
            )}
          </NodeComponent>
        </StyledTooltip>
      </SweepNodeHandles>
    );
  },
);
