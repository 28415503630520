import { AppBar, Box, Toolbar } from '@mui/material';
import { IconButton, Tooltip, colors } from '@sweep-io/sweep-design';
import { MenuCollapse, MenuExpand } from '@sweep-io/sweep-design/dist/icons';
import { DASHBOARD_HEADER_HEIGHT } from '../../../constants';
import { SweepLogoWhiteWandIcon } from '../../../icons/generalIcons';
import UserMenu from './UserMenu';
import { DashboardHeaderEnvSelector } from '../../pages/environments/connect-env/DashboardHeaderEnvSelector';
import DashboardHeaderFetchProgressBar from '../fetch-progress-bar/DashboardHeaderFetchProgressBar';
import PaywallDashboardHeader from './PaywallDashboardHeader';
import useSweepNavigate from '../../common/useSweepNavigate';
import { appRoutes, DEFAULT_ROUTE } from '../../../constants/appRoutes';
import useAiAgentCenter from '../../aiAgentCenter/useAiAgentCenter';
import AiAgentCenterButton from '../../aiAgentCenter/AiAgentCenterButton';
import { AI_AGENTS_EVENTS } from '../../../services/events';
import { AccountType } from '@server/account-type';
import { useSelector } from 'react-redux';
import { selectUserInfoData } from '../../../reducers/userInfoReducer';

const DashboardHeader = ({
  sidebarMenuOpen,
  toggle,
}: {
  sidebarMenuOpen: boolean;
  toggle: () => void;
}) => {
  const { navigate } = useSweepNavigate();
  const path = window.location.pathname;
  const user = useSelector(selectUserInfoData);
  const hideProgressBar =
    user?.account?.type === AccountType.Free && path === appRoutes.getStarted.route;

  return (
    <AppBar sx={{ backgroundColor: colors.night[900], zIndex: 1300 }} position="sticky">
      <Toolbar
        variant="dense"
        sx={{
          justifyContent: 'space-between',
          minHeight: DASHBOARD_HEADER_HEIGHT,
          '&': {
            paddingLeft: '10px',
          },
        }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <Tooltip title={sidebarMenuOpen ? 'Collapse menu' : 'Expand menu'}>
            <IconButton variant="dark" size="small" onClick={toggle}>
              {sidebarMenuOpen ? <MenuCollapse /> : <MenuExpand />}
            </IconButton>
          </Tooltip>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '12px',
              height: '100%',
            }}
          >
            <Box onClick={() => navigate(DEFAULT_ROUTE)} sx={{ cursor: 'pointer' }}>
              <SweepLogoWhiteWandIcon />
            </Box>
            <DashboardHeaderEnvSelector />
            {!hideProgressBar && <DashboardHeaderFetchProgressBar />}
          </Box>
        </Box>
        <Box display="flex" gap={2} alignItems="center">
          <AiAgentCenterDashboardButton />
          <PaywallDashboardHeader />
          <UserMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const AiAgentCenterDashboardButton = () => {
  const { toggleIsOpened, isOpened } = useAiAgentCenter();

  return (
    <AiAgentCenterButton
      onClick={() => {
        if (!isOpened) {
          toggleIsOpened();
        }
      }}
      variant="primary"
      biEvent={
        isOpened
          ? undefined
          : { name: AI_AGENTS_EVENTS.openAgentCenter, props: { app: 'dashboard' } }
      }
    />
  );
};

export default DashboardHeader;
