import '@xyflow/react/dist/base.css';
import { useCallback, useContext, useMemo } from 'react';
import { ReactFlow, NodeTypes, ReactFlowInstance } from '@xyflow/react';
import { FloatingEdge, RFEdgeFloatingEdge } from './edges/FloatingEdge';
import { EditableNode, RegularStep, VirtualDropNode } from './nodes';
import { CanvasBackground } from './canvas-background/CanvasBackground';
import { CanvasControls } from './CanvasControls';

import {
  CanvasElementType,
  SweepNodesChangeEvent,
  CanvasMode,
  DEFAULT_PREVIEW_MIN_ZOOM,
  DEFAULT_CANVAS_MIN_ZOOM,
} from './canvas-types';
import { GroupNode } from './nodes/GroupNode';
import { Box } from '@mui/material';
import GroupLabelNode from './nodes/GroupLabelNode';
import {
  getFitViewOptions,
  useCenterOnFirstNodeEffect,
} from './effects/useCenterOnFirstNodeEffect';
import { useMoveGroups } from './useMoveGroups';
import {
  CanvasWrapperResizeObserverContext,
  CanvasWrapperResizeObserverProvider,
} from './internal-context/CanvasWrapperDimensionsContext';
import { CanvasContextProvider } from './CanvasContext';
import { PluginTypes } from '../../types/enums/PluginTypes';
import { GroupOverlay } from './nodes/GroupOverlay';
import React from 'react';
import {
  SweepCanvasInternalContextProvider,
  useSweepCanvasState,
} from './internal-context/CanvasStateContext';
import { VisibilityMap } from '../../types/VisibilityTypes';
import { useCanvasZoomStyles } from './useCanvasZoomStyles';
import { useOnNodeChange } from './node-changes-event-handlers/useOnNodeChange';
import { GhostNode } from './nodes/GhostNode';
import {
  SweepCanvasProps,
  SweepCanvasPropsCtxProvider,
  useSweepCanvasPropsCtx,
} from './internal-context/SweepCanvasPropsCtx';
import { SweepCanvasRfNode } from './canvas-types/nodeTypesData';

import { HighlightEntityProvider } from './highlight/HighlightEntityContext';
import { useHighlightNodesAndEdges } from './highlight/useHighlightNodesAndEdges';
import { TemporaryTransformationsCtxProvider } from './factories/TemporaryTransformationsCtx';
import { useContextZoomEffect } from './effects/useContextZoomEffect';
import { useNewNodeOnEmptyGroupEffect } from './effects/useNewNodeOnEmptyGroupEffect';
import { useFitEditingNodeToViewEffect } from './effects/useFitElementsToViewEffect';
import { useLoadNodesAndEdges } from './useLoadNodesAndEdges';
import { useFitViewIfPreviewOnResizeEffect } from './effects/useFitViewIfPreviewOnResizeEffect';

const edgeTypes = {
  floating: FloatingEdge,
};

export type OnSweepNodesChange = (changes: SweepNodesChangeEvent[]) => any;

export type OnPluginClickEvent = (props: {
  parentId: string;
  pluginId: PluginTypes;
  objectType: string;
  event: React.MouseEvent;
}) => void;

const proOptions = {
  account: 'paid-pro',
  hideAttribution: true,
};

const nodeTypes: NodeTypes = {
  [CanvasElementType.REGULAR]: RegularStep,
  [CanvasElementType.EDITABLE]: EditableNode,
  [CanvasElementType.DROP_ZONE_NODE]: VirtualDropNode,
  [CanvasElementType.GROUP]: GroupNode,
  [CanvasElementType.GROUP_LABEL]: GroupLabelNode,
  [CanvasElementType.GROUP_OVERLAY]: GroupOverlay,
  [CanvasElementType.GHOST_NODE]: GhostNode,
};

const PreviewOverlay = () => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(0,0,0,0)',
      zIndex: 12,
    }}
  />
);

const disableOnlyRenderVisibleElements =
  new URLSearchParams(window.location.search).get('disableOnlyRenderVisibleElements') === 'true';

const SweepCanvas = () => {
  const {
    moveGroups,
    showControls,
    isLoadingCursor,
    canvasMode = CanvasMode.DEFAULT,
  } = useSweepCanvasPropsCtx();

  const { setReactFlowInstance } = useSweepCanvasState();

  const isInGroupMouseMoveMode = Boolean(moveGroups);

  const preview = canvasMode !== CanvasMode.DEFAULT;

  const { canvasWrapperRef } = useContext(CanvasWrapperResizeObserverContext);

  const { startHighlightNodeAndConnectedEdges, startHighlightEdge } = useHighlightNodesAndEdges();

  const { handleOnGroupsDropClick, onMoveGroupsMouseMoveHandler: onMoveGroupsMouseMove } =
    useMoveGroups();

  const { nodes, edges, regularNodes } = useLoadNodesAndEdges();

  const showZoomControls =
    showControls ?? [CanvasMode.DEFAULT, CanvasMode.PREVIEW2].includes(canvasMode);

  useFitViewIfPreviewOnResizeEffect();

  const { onNodesChange, onEdgesChange } = useOnNodeChange();

  useContextZoomEffect();
  useNewNodeOnEmptyGroupEffect();
  useFitEditingNodeToViewEffect();
  useCenterOnFirstNodeEffect();

  const { sx, classes } = useCanvasZoomStyles();

  const fitViewOptions = useMemo(() => {
    return {
      nodes: regularNodes,
      ...getFitViewOptions(canvasMode),
    };
  }, [canvasMode, regularNodes]);

  const onInit = useCallback(
    (reactFlowInstance: ReactFlowInstance<SweepCanvasRfNode, RFEdgeFloatingEdge>) => {
      setReactFlowInstance(reactFlowInstance);
    },
    [setReactFlowInstance],
  );

  const onNodeMouseMove = useCallback(
    (event: any, node: SweepCanvasRfNode) => {
      if (node.type === CanvasElementType.GROUP_LABEL || node.type === CanvasElementType.REGULAR) {
        startHighlightNodeAndConnectedEdges(node.id);
      }
    },
    [startHighlightNodeAndConnectedEdges],
  );

  const onEdgeMouseMove = useCallback(
    (event: any, edge: RFEdgeFloatingEdge) => {
      startHighlightEdge(edge.id);
    },
    [startHighlightEdge],
  );

  // Enforce the order of the nodes to be groups first
  const sortedNodes = useMemo(
    () =>
      nodes.sort((a, b) => {
        if (a.type === CanvasElementType.GROUP && b.type !== CanvasElementType.GROUP) {
          return -1;
        }
        if (a.type !== CanvasElementType.GROUP && b.type === CanvasElementType.GROUP) {
          return 1;
        }
        return 0;
      }),
    [nodes],
  );

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        backgroundColor: '#fff',
        '& .react-flow__pane': {
          cursor: isLoadingCursor ? 'wait' : undefined,
        },
        ...sx,
      }}
      ref={canvasWrapperRef}
      data-testid="sweep-canvas"
      className={classes.join(' ')}
      id="sweep-canvas"
    >
      <ReactFlow
        proOptions={proOptions}
        nodes={sortedNodes}
        edges={edges}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        onEdgesChange={onEdgesChange}
        onNodesChange={onNodesChange}
        onNodeMouseMove={isInGroupMouseMoveMode ? undefined : onNodeMouseMove}
        onEdgeMouseMove={isInGroupMouseMoveMode ? undefined : onEdgeMouseMove}
        onMouseMove={onMoveGroupsMouseMove}
        onClick={handleOnGroupsDropClick}
        onInit={onInit}
        minZoom={preview ? DEFAULT_PREVIEW_MIN_ZOOM : DEFAULT_CANVAS_MIN_ZOOM}
        fitView
        fitViewOptions={fitViewOptions}
        zoomOnScroll={!preview}
        panOnScroll
        onlyRenderVisibleElements={!disableOnlyRenderVisibleElements}
      >
        <CanvasBackground />
        {showZoomControls ? <CanvasControls /> : <PreviewOverlay />}
      </ReactFlow>
    </Box>
  );
};

const SweepCanvasWithAllInternalProviders = (
  props: SweepCanvasProps & { visibilityMap: VisibilityMap },
) => {
  const { visibilityMap } = props;
  return (
    <TemporaryTransformationsCtxProvider>
      <SweepCanvasInternalContextProvider visibilityMap={visibilityMap}>
        <CanvasWrapperResizeObserverProvider>
          <HighlightEntityProvider>
            <SweepCanvasPropsCtxProvider value={props}>
              <SweepCanvas />
            </SweepCanvasPropsCtxProvider>
          </HighlightEntityProvider>
        </CanvasWrapperResizeObserverProvider>
      </SweepCanvasInternalContextProvider>
    </TemporaryTransformationsCtxProvider>
  );
};

const SweepMultiCanvasWithReactFlowContext = (
  props: SweepCanvasProps & { visibilityMap: VisibilityMap },
) => (
  <CanvasContextProvider>
    <SweepCanvasWithAllInternalProviders {...props} />
  </CanvasContextProvider>
);

export { SweepMultiCanvasWithReactFlowContext as SweepCanvas };
export { SweepCanvasWithAllInternalProviders as SweepCanvasInternal };
