import { Suspense, useMemo } from 'react';
import { documentationPanelType } from '../../../../documentation/types';
import { PanelConsumer } from '../../../../panels/PanelsContext';
import { useConfigurationCanvas } from '../../../configuration-canvas/public/useConfigurationCanvas';
import DocumentationDialog from '../../../../documentation/DocumentationDialog';
import { ConnectedNewSfFunnelDialog } from '../../../funnel-map-flow/new-sf-funnel-dialog/NewSfFunnelDialog';
import { FunnelMapPluginPanels } from '../../../funnel-map-flow/templates/funnel-map-plugins/FunnelMapPluginPanels';
import { CanvasUnitedTemplatesDialog } from './CanvasUnitedTemplatesDialog';
import { automationsPanelType, playbookAlertsPanelType } from '../../../../Automations/panelTypes';
import { ConfigurationCanvasAutomationsPanel } from '../../CanvasAutomationsPanel';
import { useSelector } from 'react-redux';
import { selectCrmOrgAlerts } from '../../../../../reducers/global/alertsReducers';
import { selectCrmOrgAutomations } from '../../../../../reducers/global/automationReducers';
import { importFunnelPanelType } from '../../../funnel-map-flow/dialogs/import-funnel/importFunnelPanelType';
import { ImportFunnelWrapper } from '../../../funnel-map-flow/dialogs/import-funnel/ImportFunnelWrapper';
import {
  selectStageDialogStage,
  selectStageDialogFunnelId,
} from '../../../../../reducers/funnel-flow-page/stageDialogReducer';
import { stagePanelType } from '../../../funnel-map-flow/dialogs/stage/panelType';
import { StageDialog } from '../../../funnel-map-flow/dialogs/stage/StageDialog';
import ConnectedFunnelDialog from '../../../funnel-map-flow/dialogs/funnel-panel/ConnectedFunnelDialog';
import { funnelPanelType } from '../../../funnel-map-flow/dialogs/funnel-panel/funnelPanelTypes';
import { ConnectedGateDialog } from '../../../funnel-map-flow/dialogs/gates/ConnectedGateDialog';
import { gatePanelType } from '../../../funnel-map-flow/dialogs/gates/gatePanelType';
import FieldsManagementDialog from '../../../funnel-map-flow/dialogs/fields-management/FieldsManagementDialog';
import { selectIsFieldsDialogOpen } from '../../../../../reducers/funnel-flow-page/fieldsReducer';
import { automationsInFunnelMapScope } from '../../../../Automations/automationsInFunnelDataScope';
import { ConfigurationCanvasLeftPanel } from '../../../configuration-canvas-panel';
import { WithEnvConnectionErrorPanel } from './error-panel/EnvConnectionErrorPanel';
import { getFunnelsStepNames } from '../../../../documentation/helpers';
import { selectSingleObject } from '../../../../../reducers/documentationReducer';
import { getRecordTypeNames } from '../../../../documentation/selected-object/utils';
import { AutomationAlertsContextProvider } from '../../../../alerts/AutomationAlertsContextProvider';
import { AutomationAutomationsContextProvider } from '../../../../alerts/AutomationAutomationsContextProvider';
import { aiAgentCenterPanelType } from '../../../../dashboard/aiAgents/aiAgentCenterPanelType';
import { DocumentationContextProvider } from '../../../../documentation/context/DocumentationContextProvider';
import { useCanvasFilters } from '../../../configuration-canvas-filters/useCanvasFilters';
import { useDocumentationCanvasInteractions } from '../../../../documentation/useCanvasDocumentationInteractions';
import useAutomationsCanvasInteractions from '../../useAutomationsCanvasInteractions';
import { CenteredCircularProgress } from '../../../../common/CenteredCircularProgress';
import useAiAgentCenter from '../../../../aiAgentCenter/useAiAgentCenter';
import AiAgentCenterPanel from '../../../../aiAgentCenter/AiAgentCenterPanel';

export const CanvasUnitedFlow = () => {
  const { canvasFunnelMap, canvasCrmOrgId } = useConfigurationCanvas();
  const { filterValues, setFilterValues, focusOnCanvasElements, clearAllValues } =
    useCanvasFilters();

  const { onEntityClick } = useAutomationsCanvasInteractions();

  const automations = useSelector(selectCrmOrgAutomations(canvasCrmOrgId));
  const alerts = useSelector(selectCrmOrgAlerts(canvasCrmOrgId));
  const selectedStage = useSelector(selectStageDialogStage);
  const selectedStageDialogFunnelId = useSelector(selectStageDialogFunnelId);
  const isFieldsManagementDialogOpen = useSelector(selectIsFieldsDialogOpen);
  const activeTab = useSelector(selectSingleObject);

  const filteredAutomations = useMemo(
    () =>
      automationsInFunnelMapScope({
        automations,
        funnelsData: canvasFunnelMap.funnelsData,
      }),
    [automations, canvasFunnelMap.funnelsData],
  );
  const filteredAlerts = useMemo(
    () =>
      automationsInFunnelMapScope({
        automations: alerts,
        funnelsData: canvasFunnelMap.funnelsData,
      }),
    [alerts, canvasFunnelMap.funnelsData],
  );

  const stepNames = useMemo(() => {
    return (
      getFunnelsStepNames({
        funnelsData: canvasFunnelMap.funnelsData,
        recordTypesData: canvasFunnelMap.recordTypesData,
      })[activeTab?.objectType ?? ''] ?? []
    );
  }, [activeTab?.objectType, canvasFunnelMap.funnelsData, canvasFunnelMap.recordTypesData]);

  const currentRecordTypesByObjectName = useMemo(() => {
    return getRecordTypeNames({
      funnelsData: canvasFunnelMap.funnelsData,
      recordTypesData: canvasFunnelMap.recordTypesData,
    });
  }, [canvasFunnelMap.funnelsData, canvasFunnelMap.recordTypesData]);

  const objectNamesUsedInCanvas = Object.keys(currentRecordTypesByObjectName);
  const { dispatchSingleObjectNameAndFocusOnElement } = useDocumentationCanvasInteractions({
    focusOnCanvasElements,
    setFilterValues,
    onSweepElementClick: onEntityClick,
    crmOrgId: canvasCrmOrgId,
    clearAllValues,
  });

  const { toggleIsOpened: toggleIsOpenedAgentCenter, isOpened: isOpenedAgentCenter } =
    useAiAgentCenter();

  return (
    <>
      <PanelConsumer panelType={documentationPanelType}>
        {({ isOpen, maybeClosePanel }) => (
          <>
            {isOpen && (
              <ConfigurationCanvasLeftPanel withExpandedModeButton>
                <WithEnvConnectionErrorPanel shouldWaitForInitialFetch={true}>
                  <DocumentationContextProvider
                    value={{
                      objectNamesUsedInCanvas,
                      recordTypesUsedInCanvas: currentRecordTypesByObjectName,
                      stepNamesUsedInCanvas: stepNames,
                      funnelsData: canvasFunnelMap.funnelsData,
                      recordTypesData: canvasFunnelMap.recordTypesData,
                      filterValues,
                    }}
                  >
                    <Suspense fallback={<CenteredCircularProgress />}>
                      <DocumentationDialog
                        crmOrgId={canvasCrmOrgId}
                        isOpen
                        keepState
                        onCloseDialog={() => maybeClosePanel()}
                        hideAddObjectButton
                        dispatchSingleObjectNameAndFocusOnElement={
                          dispatchSingleObjectNameAndFocusOnElement
                        }
                      />
                    </Suspense>
                  </DocumentationContextProvider>
                </WithEnvConnectionErrorPanel>
              </ConfigurationCanvasLeftPanel>
            )}
          </>
        )}
      </PanelConsumer>
      <CanvasUnitedTemplatesDialog />
      <FunnelMapPluginPanels isReadOnly={false} />
      <ConnectedNewSfFunnelDialog hideUseFieldsFrom />

      <PanelConsumer panelType={automationsPanelType}>
        {({ isOpen, maybeClosePanel }) => (
          <>
            {isOpen && (
              <AutomationAutomationsContextProvider>
                <ConfigurationCanvasAutomationsPanel
                  automations={filteredAutomations}
                  onClose={maybeClosePanel}
                  disableCanvasTemplates
                  excludeAutomationsNotInFunnelMapObjects
                />
              </AutomationAutomationsContextProvider>
            )}
          </>
        )}
      </PanelConsumer>
      <PanelConsumer panelType={playbookAlertsPanelType}>
        {({ isOpen, maybeClosePanel }) => (
          <>
            {isOpen && (
              <AutomationAlertsContextProvider>
                <ConfigurationCanvasAutomationsPanel
                  automations={filteredAlerts}
                  onClose={maybeClosePanel}
                  disableCanvasTemplates
                />
              </AutomationAlertsContextProvider>
            )}
          </>
        )}
      </PanelConsumer>
      <PanelConsumer panelType={importFunnelPanelType}>
        {({ isOpen, maybeClosePanel }) =>
          isOpen && (
            <ImportFunnelWrapper onClose={() => maybeClosePanel()} crmOrgId={canvasCrmOrgId} />
          )
        }
      </PanelConsumer>
      {selectedStage && selectedStageDialogFunnelId && (
        <PanelConsumer panelType={stagePanelType} instanceId={selectedStage._stageId}>
          {({ maybeClosePanel, isOpen, setIsDirty }) => (
            <>
              {isOpen && (
                <StageDialog
                  readonly={false}
                  stage={selectedStage}
                  funnelId={selectedStageDialogFunnelId}
                  crmOrgId={canvasCrmOrgId}
                  closeDialog={() => maybeClosePanel()}
                  setIsDirty={setIsDirty}
                  funnelMap={canvasFunnelMap}
                />
              )}
            </>
          )}
        </PanelConsumer>
      )}
      <PanelConsumer panelType={funnelPanelType}>
        {({ maybeClosePanel, isOpen, data }) => (
          <>
            {data && (
              <ConnectedFunnelDialog
                closeDialog={() => maybeClosePanel()}
                isOpen={isOpen}
                readonly={false}
                data={data}
                crmOrgId={canvasCrmOrgId}
                funnelMapId={canvasFunnelMap.id}
              />
            )}
          </>
        )}
      </PanelConsumer>
      <PanelConsumer panelType={gatePanelType}>
        {({ maybeClosePanel, isOpen, setIsDirty, data }) => (
          <>
            {data && (
              <ConnectedGateDialog
                crmOrgId={canvasCrmOrgId}
                readonly={false}
                closeDialog={() => maybeClosePanel()}
                setIsDirty={setIsDirty}
                isOpen={isOpen}
                data={data}
              />
            )}
          </>
        )}
      </PanelConsumer>
      <PanelConsumer panelType={aiAgentCenterPanelType}>
        {({ maybeClosePanel, isOpen }) => (
          <AiAgentCenterPanel
            closePanel={() => {
              maybeClosePanel();
              toggleIsOpenedAgentCenter();
            }}
            isOpened={isOpen && isOpenedAgentCenter}
          />
        )}
      </PanelConsumer>
      {isFieldsManagementDialogOpen && (
        <FieldsManagementDialog crmOrgId={canvasCrmOrgId} funnelMapId={canvasFunnelMap.id} />
      )}
    </>
  );
};
