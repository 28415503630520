import { useMemo } from 'react';
import { AutomationActionType } from '../../../types/enums/AutomationActionType';
import { getDeployedVersions } from '../../documentation/selected-object/filters/utils';
import {
  CanvasFilterTypes,
  MultiGenericFilterItem,
  SweepCanvasFiltersMap,
} from '../configuration-canvas-filters/filterTypes';
import { AutomationCanvasFilter } from './canvasAutomationsTypes';
import { useShouldRequestToDeployFilter } from '../../common/deploy-button/useShouldRequestToDeploy';
import { AutomationActionDescriptionByType } from '../../Automations/helper';

export const activeFilterItems = [
  { value: 'true', label: 'Active' },
  { value: 'false', label: 'Inactive' },
];

const getActionFilterItems = (automations: AutomationStructureNew[]) => {
  const items: MultiGenericFilterItem[] = [];

  const actionsInUse = new Set(
    automations
      .map(({ automationDetails: { actions } }) =>
        actions.map(({ actionType }) => actionType as AutomationActionType),
      )
      .flat(),
  );

  actionsInUse?.forEach((action: AutomationActionType) => {
    if (AutomationActionDescriptionByType[action]) {
      items.push({ value: action, label: AutomationActionDescriptionByType[action] });
    }
  });

  return items;
};

export const useGetAssignmentFilters = (automations: AutomationStructureNew[] = []) => {
  const requestToDeployFilter = useShouldRequestToDeployFilter(automations, 3);
  const filters = useMemo(() => {
    let assignmentRuleFilters: SweepCanvasFiltersMap = {
      [AutomationCanvasFilter.OBJECTS]: { type: CanvasFilterTypes.OBJECT, position: 0 },
      [AutomationCanvasFilter.RECORD_TYPES]: {
        type: CanvasFilterTypes.RECORD_TYPE,
        position: 1,
        objectsFilter: {
          type: 'reference',
          filterRef: AutomationCanvasFilter.OBJECTS,
        },
      },
      [AutomationCanvasFilter.STEPS]: {
        type: CanvasFilterTypes.STEP,
        recordTypeFilterRef: AutomationCanvasFilter.RECORD_TYPES,
        position: 2,
      },
      [AutomationCanvasFilter.ACTIVE]: {
        type: CanvasFilterTypes.MULTI_GENERIC,
        position: 3,
        label: 'Status',
        items: activeFilterItems,
      },
    };
    if (requestToDeployFilter) {
      assignmentRuleFilters = {
        ...assignmentRuleFilters,
        ...requestToDeployFilter,
      };
    }
    return assignmentRuleFilters;
  }, [requestToDeployFilter]);
  return filters;
};

export const useGetScheduledAssignmentFilters = (automations: AutomationStructureNew[] = []) => {
  const requestToDeployFilter = useShouldRequestToDeployFilter(automations, 1);
  const filters = useMemo(() => {
    let assignmentRuleFilters: SweepCanvasFiltersMap = {
      [AutomationCanvasFilter.OBJECTS]: { type: CanvasFilterTypes.OBJECT, position: 0 },
      [AutomationCanvasFilter.ACTIVE]: {
        type: CanvasFilterTypes.MULTI_GENERIC,
        position: 2,
        label: 'Status',
        items: activeFilterItems,
      },
    };

    if (requestToDeployFilter) {
      assignmentRuleFilters = {
        ...assignmentRuleFilters,
        ...requestToDeployFilter,
      };
    }
    return assignmentRuleFilters;
  }, [requestToDeployFilter]);
  return filters;
};

export const useGetAutomationFilters = (automations: AutomationStructureNew[] = []) => {
  const requestToDeployFilter = useShouldRequestToDeployFilter(automations, 4);
  const automationsFilters = useMemo((): SweepCanvasFiltersMap => {
    let regularFilters: SweepCanvasFiltersMap = {
      [AutomationCanvasFilter.OBJECTS]: { type: CanvasFilterTypes.OBJECT, position: 0 },
      [AutomationCanvasFilter.RECORD_TYPES]: {
        type: CanvasFilterTypes.RECORD_TYPE,
        position: 1,
        objectsFilter: {
          type: 'reference',
          filterRef: AutomationCanvasFilter.OBJECTS,
        },
      },
      [AutomationCanvasFilter.STEPS]: {
        type: CanvasFilterTypes.STEP,
        recordTypeFilterRef: AutomationCanvasFilter.RECORD_TYPES,
        position: 2,
      },
      [AutomationCanvasFilter.ACTIONS]: {
        type: CanvasFilterTypes.MULTI_GENERIC,
        position: 3,
        label: 'Action',
        items: getActionFilterItems(automations),
      },
      [AutomationCanvasFilter.ACTIVE]: {
        type: CanvasFilterTypes.MULTI_GENERIC,
        position: 4,
        label: 'Status',
        items: activeFilterItems,
      },
    };
    if (requestToDeployFilter) {
      regularFilters = {
        ...regularFilters,
        ...requestToDeployFilter,
      };
    }

    return regularFilters;
  }, [automations, requestToDeployFilter]);

  return automationsFilters;
};

export const getDocumentationAutomationFilters = (
  automations: AutomationStructureNew[],
  objectFilter = '',
) => ({
  [AutomationCanvasFilter.RECORD_TYPES]: {
    type: CanvasFilterTypes.RECORD_TYPE,
    position: 1,
    objectsFilter: {
      type: 'values',
      values: [objectFilter],
    },
  },
  [AutomationCanvasFilter.STEPS]: {
    type: CanvasFilterTypes.STEP,
    recordTypeFilterRef: AutomationCanvasFilter.RECORD_TYPES,
    position: 2,
  },
  [AutomationCanvasFilter.ACTIONS]: {
    type: CanvasFilterTypes.MULTI_GENERIC,
    position: 3,
    label: 'Action',
    items: getActionFilterItems(getDeployedVersions(automations) ?? []),
  },
});
