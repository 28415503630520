import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { AutomationDialogContent } from './AutomationDialogContent';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { useSelector } from 'react-redux';
import { selectDefaultCreationEnvironment } from '../pages/environments/environmentsReducer';
import useAutomationApiPerType from './useAutomationApiPerType';
import { scheduledJobsSortOptions } from '../pages/canvas-pages/consts';
import { AutomationScheduledJobsContextProvider } from './AutomationScheduledJobsContextProvider';
import { WithConfigurationCanvas } from '../pages/configuration-canvas/WithConfigurationCanvas';
import useAutomationsCanvasInteractions from '../pages/canvas-pages/useAutomationsCanvasInteractions';
import useEntitiesToHighlight from '../pages/canvas-pages/useEntitiesToHighlight';
import { VisibilityMap } from '../../types/VisibilityTypes';
import { VisibilityElementMap } from '../pages/configuration-canvas/types';
import { VisibilityLayers } from '../../types/enums/VisibilityLayers';
import { selectScheduledJobs } from '../../reducers/global/scheduledJobReducers';

const alertsPageVisibilityMap: Partial<VisibilityMap> = {
  [VisibilityLayers.NURTURING_STEPS]: true,
  [VisibilityLayers.COMMENTS]: false,
};
const visibilityElements: VisibilityElementMap = {
  type: 'map',
  visibilityMap: alertsPageVisibilityMap,
};

const ScheduledJobs = ({
  recordTypesData,
  funnelsData,
  setHoveredItem,
}: {
  recordTypesData: RecordTypesData;
  funnelsData: FunnelsData;
  setHoveredItem?: (automationId?: string | undefined) => void;
}) => {
  const automationsVariant = AutomationFormVariant.SCHEDULED_JOBS;
  const scheduledJobs = useSelector(selectScheduledJobs);
  const crmOrg = useSelector(selectDefaultCreationEnvironment);
  const { onSaveOrCreate, onToggleActivation, onDeleteAutomation, onDeployAutomation } =
    useAutomationApiPerType({ automationVariant: automationsVariant });
  const { onEntityClick } = useAutomationsCanvasInteractions();
  const { entitiesToHighlight } = useEntitiesToHighlight(automationsVariant);
  if (!scheduledJobs) return <CenteredCircularProgress />;

  return (
    <>
      {crmOrg && (
        <WithConfigurationCanvas
          visibilityElements={visibilityElements}
          onFunnelStepClick={onEntityClick}
          onRecordTypeStepClick={onEntityClick}
          onFunnelLabelPillClick={onEntityClick}
          onRecordTypeLabelPillClick={onEntityClick}
          highlightEntities={entitiesToHighlight}
        >
          <AutomationScheduledJobsContextProvider>
            <AutomationDialogContent
              automations={scheduledJobs ?? []}
              onDeleteAutomation={onDeleteAutomation}
              crmOrgId={crmOrg.id}
              onSaveOrCreate={onSaveOrCreate}
              recordTypesData={recordTypesData}
              funnelsData={funnelsData}
              onToggleActivation={onToggleActivation}
              sortOptions={scheduledJobsSortOptions}
              disableCanvasTemplates={true}
              setHoveredItem={setHoveredItem}
              onDeployAutomation={onDeployAutomation}
              filtersForExpandedMode={{}}
            />
          </AutomationScheduledJobsContextProvider>
        </WithConfigurationCanvas>
      )}
    </>
  );
};

export default ScheduledJobs;
