import { Box, Collapse } from '@mui/material';
import { useState } from 'react';
import { ChevronRight, ChevronUp } from '@sweep-io/sweep-design/dist/icons';
import { Button, IconButton, colors } from '@sweep-io/sweep-design';
import { IconForPluginType } from '../../../templates/plugins/icons/IconForPluginType';
import { useSweepCanvas } from '../../../../../sweep-canvas/useSweepCanvas';
import {
  SfLinkFunnelLinkDescription,
  ThirdPartyLinkFunnelLinkDescription,
} from '../../../FunnelLinkDescription';
import { EnrichedFunnelLink } from '../../../funnel-links/types';
import { FunnelType } from '../../../../../../types/enums/FunnelType';

interface StageDialogCollapsePanelProps {
  children: React.ReactElement;
  defaultIsCollapsed: boolean;
  from: string;
  totalConditions?: number;
  isPluginType?: PluginTypes;
  funnelId: string;
  enrichedFunnelLink: EnrichedFunnelLink;
}

const StageDialogCollapsePanel = ({
  defaultIsCollapsed,
  children,
  from,
  totalConditions,
  isPluginType,
  funnelId,
  enrichedFunnelLink,
}: StageDialogCollapsePanelProps) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultIsCollapsed);
  const showConditions = totalConditions !== undefined;
  const { clickPluginButton } = useSweepCanvas();

  const maybeRenderFunnelLinkDescription = () => {
    if (enrichedFunnelLink._type === FunnelType.SALESFORCE) {
      return (
        <SfLinkFunnelLinkDescription
          from={from}
          to={enrichedFunnelLink.stageName}
          conditions={totalConditions}
          showConditions={showConditions && !isCollapsed}
          targetObjectName={enrichedFunnelLink.objectName}
          targetFunnelName={enrichedFunnelLink.funnelName}
        />
      );
    }
  };

  const maybeRenderThirdPartyFunnelLinkDescription = () => {
    if (enrichedFunnelLink._type === FunnelType.THIRD_PARTY) {
      return (
        <ThirdPartyLinkFunnelLinkDescription
          from={from}
          to={enrichedFunnelLink.stageName}
          system={enrichedFunnelLink.system}
          funnelName={enrichedFunnelLink.funnelName}
        />
      );
    }
  };

  return (
    <Box
      sx={{
        background: colors.white,
        borderBottom: `1px solid ${colors.grey[300]}`,
        position: 'relative',
        cursor: !isCollapsed ? 'pointer' : 'default',
        display: 'flex',
        flexDirection: 'column',
        pt: 3,
        pb: 3,
      }}
      onClick={() => !isCollapsed && setIsCollapsed((prevState) => !prevState)}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', justifyItems: 'center' }}>
        {maybeRenderFunnelLinkDescription()}
        {maybeRenderThirdPartyFunnelLinkDescription()}
        <Box sx={{ display: 'flex', gap: 2 }}>
          {isPluginType && (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                variant="flat"
                startIconName="SeeIn"
                onClick={(e) => {
                  e.stopPropagation();
                  clickPluginButton(isPluginType, funnelId);
                }}
              >
                Edit in Plugin
              </Button>
              <IconForPluginType pluginType={isPluginType} size={24} />
            </Box>
          )}
          <IconButton
            variant="flat"
            size="tiny"
            onClick={() => isCollapsed && setIsCollapsed((prevState) => !prevState)}
          >
            {isCollapsed ? <ChevronUp /> : <ChevronRight />}
          </IconButton>
        </Box>
      </Box>
      <Collapse in={isCollapsed}>{children}</Collapse>
    </Box>
  );
};

export { StageDialogCollapsePanel };
