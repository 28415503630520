import React from 'react';
import usePermission from './usePermission';
import { Permission } from '@server/permissions';
import { Tooltip } from '@sweep-io/sweep-design';

type RestrictedTooltipProps = {
  to: Permission[];
  notAllowedTitle?: string;
  allowedTitle?: string;
  children?: React.ReactElement;
  component?: (isAllowed: boolean, allowedPermissions: boolean[]) => React.ReactElement;
  forceDisallowTitle?: string; // if present it shows this message even if the permissions are allowed
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';
};

const RestrictedTooltip = React.forwardRef(
  (
    {
      to,
      notAllowedTitle,
      allowedTitle,
      children,
      component,
      forceDisallowTitle,
      placement,
    }: RestrictedTooltipProps,
    ref,
  ) => {
    const permissions = usePermission(to);

    // If any of the permissions is false it is not allowed
    const allowed = permissions.filter((permission) => !permission).length === 0;
    const _children = children || (component && component(allowed, permissions));

    if (!_children) {
      throw new Error('Restricted tooltip must contain either children or a component');
    }

    if (allowed && !forceDisallowTitle && !allowedTitle) {
      return <>{_children}</>;
    }

    let _title = allowed ? allowedTitle : notAllowedTitle;
    if (forceDisallowTitle && !_title) {
      _title = forceDisallowTitle;
    }

    return (
      <Tooltip title={_title} placement={placement ?? 'top'} ref={ref}>
        <span>{_children}</span>
      </Tooltip>
    );
  },
);

export default RestrictedTooltip;
