import { useLocation } from 'react-router';
import PaywallHorizontalLayout from './PaywallHorizontalLayout';
import usePaywall from '../usePaywall';
import { useEffect } from 'react';
import { getAppRouteByPath } from '../../../../constants/appRoutes';
import { useFeatureToggle } from '../../../common/useFeatureToggle';
import { PageLayout } from './page/PageLayout';

const PaywallPageBlock = () => {
  const { newEmptyStates } = useFeatureToggle();
  const { pathname } = useLocation();
  const path = pathname.split('/')[1];
  const appRoutePath = '/' + path;
  const paywallSource = getAppRouteByPath(appRoutePath)?.paywallBlockSource;
  const { sendEncounterPaywallEvent } = usePaywall();

  useEffect(() => {
    if (paywallSource) {
      sendEncounterPaywallEvent({
        source: paywallSource,
      });
    }
  }, [paywallSource, sendEncounterPaywallEvent]);

  if (newEmptyStates) {
    return <PageLayout pathname={appRoutePath} />;
  }

  return <PaywallHorizontalLayout paywallSource={paywallSource} />;
};

export default PaywallPageBlock;
