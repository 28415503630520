export enum ElementsUsedByClient {
  EMAIL_TEMPLATE = 'Email template:',
  RECIPIENTS = 'Recipients:',
  MEMBERS = 'Members:',
  SLACK_MESSAGE_FIELDS = 'Message Fields:',
  SLACK_MESSAGE_BUTTONS = 'Message buttons:',
  SLACK_MESSAGE = 'Message:',
  CHANNEL_NAME = 'Name:',
  CHANNEL_TYPE = 'Type:',
  CHANNEL_ID_FIELD = 'Channel ID Field:',
  DEAL_ROOM_SELECTION = 'Selected Deal Room:',
  REPORT_NAME = 'Report Name',
  CHANNEL_WELCOME_MESSAGE = 'Welcome Message:',
  MET_CONDITIONS = 'When these conditions are met',
  ASSIGNMENT_DO_NOT_NOTIFY = 'Do not notify',
  ASSIGNMENT_ASSIGN_TO = 'Assign to',
  ASSIGNMENT_ASSIGN_TO_FALLBACK = 'Fallback assign to',

  SEND_EMAIL = 'Send email',
  CREATE_RECORD = 'Create',
  UPDATE_RECORDS = 'Update',
  CONVERT_LEAD = 'Convert Lead',
  SEND_SLACK_MESSAGE = 'Post to Slack',
  WEBHOOK = 'Send Webhook',
  WEBHOOK_PATH = 'Webhook path',
  WEBHOOK_HEADERS = 'Webhook headers',
  WEBHOOK_QUERY_PARAMS = 'Webhook query parameters',
  WEBHOOK_BODY = 'Webhook body',
  ASSIGNMENT_RULES = 'Run assignment rules',
  CREATE_SLACK_CHANNEL = 'Create Slack channel',
  ADD_MEMBERS_TO_SLACK_CHANNEL = 'Add members to Slack channel',
  RENAME_SLACK_CHANNEL = 'Rename Slack Channel',
  ARCHIVE_SLACK_CHANNEL = 'Archive Slack Channel',
  SEND_REPORT_TO_SLACK = 'Send a Salesforce report to Slack',

  ROLLUP_CALCULATION = 'Calculate only records that meet these criteria:',
}
