import Stack from '@mui/material/Stack';
import { Button, Typography } from '@sweep-io/sweep-design';
import { scheduleCall } from '../../../customerSupportHelper';
import { useSelector } from 'react-redux';
import { selectUserInfoData } from '../../../../../reducers/userInfoReducer';
import usePaywall from '../../usePaywall';
import { PaywallSource } from '../../paywallTypes';

export const BookADemoButton = ({ source }: { source: PaywallSource }) => {
  const userInfo = useSelector(selectUserInfoData);
  const { ctaClickedCallback } = usePaywall();

  return (
    <Stack pt={'102px'} pb={11} width="358px">
      <Stack gap={3} alignItems="center">
        <Typography variant="h1-bold" textAlign="center">
          See how Sweep transforms your business
        </Typography>
        <Button
          onClick={() => {
            ctaClickedCallback(source);
            scheduleCall(userInfo);
          }}
        >
          Book a demo
        </Button>
      </Stack>
    </Stack>
  );
};
