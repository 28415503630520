import { Box, styled } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';
import { ReactNode } from 'react';
import { MarkdownWrapper } from '../../MarkdownWrapper';
import { useRunOnce } from '../../useRunOnce';

export const AiChatUserMessageBox = ({
  content,
  onMount,
}: {
  content: string | ReactNode;
  onMount?: () => void;
}) => {
  useRunOnce(() => {
    onMount?.();
  });

  return (
    <UserMessageBox data-message-type="user">
      <MarkdownWrapper content={content} removeMargins={false} />
    </UserMessageBox>
  );
};

const UserMessageBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '0 16px',
  borderRadius: '8px 8px 2px 8px',
  background: colors.blue[100],
  maxWidth: `max(70%, 356px)`,
  alignSelf: 'flex-end',
});
