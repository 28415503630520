import { Box, SxProps } from '@mui/material';
import { ActionsMenu, ActionsMenuAction } from './actions-menu/ActionsMenu';
import { Typography, colors } from '@sweep-io/sweep-design';
import { Permission } from '@server/permissions';
import { ClampedText } from './ClampedText';
import { IconButtonSize } from '@sweep-io/sweep-design/dist/components/IconButton/types';
import { useCallback } from 'react';

interface ListItemActionCardProps<ItemValueT = any> {
  showToggle?: boolean;
  actionsMenu: ActionsMenuAction<ItemValueT>[];
  title: string;
  headerRightContent?: React.ReactNode;
  content: React.ReactNode;
  toggleButton?: {};
  permissionString?: Permission;
  notAllowedTitle?: string;
  onLineClick: () => any;
  isAllowedBtn: boolean;
  onActionMenuClick: (action: ItemValueT) => any;
  onToggleChange?: (action: ItemValueT) => any;
  isToggleChecked?: boolean;
  isSelected?: boolean;
  titleSX?: SxProps;
  horizontalMargin?: string | number;
  actionsMenuIconSize?: IconButtonSize;
}

function ListItemActionCard<ItemValueT = any>({
  actionsMenu,
  content,
  title,
  headerRightContent,
  notAllowedTitle,
  onLineClick,
  isAllowedBtn,
  onActionMenuClick,
  isSelected,
  titleSX,
  horizontalMargin = 2,
  actionsMenuIconSize,
}: ListItemActionCardProps<ItemValueT>) {
  const className = 'list-item-action-card';

  const _onActionMenuClick = useCallback(
    (action: ActionsMenuAction<ItemValueT>) => {
      onActionMenuClick(action.value as ItemValueT);
    },
    [onActionMenuClick],
  );

  return (
    <Box
      sx={{
        background: isSelected ? colors.grey[100] : colors.white,
        '&:hover': {
          cursor: 'pointer',
          background: colors.grey[100],
        },
      }}
      className={className}
    >
      <Box
        sx={{
          borderBottom: '1px solid',
          borderColor: colors.grey[120],
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          letterSpacing: '0',
          ml: horizontalMargin,
          mr: horizontalMargin,
        }}
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          //verify that the click is on the item (and not on the menu popover backdrop)
          if (e.target instanceof Element && e.target?.matches(`.${className} *`)) {
            onLineClick();
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            gap: '8px',
            borderRadius: '8px 8px 0 0',
            justifyContent: 'space-between',
            pt: 2,
          }}
        >
          <Box
            sx={{
              lineHeight: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '18px',
              flex: 1,
              flexBasis: '100px',
              ...titleSX,
            }}
          >
            <ClampedText text={title} typographyVariant="body" />
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                overflow: 'hidden',
                textWrap: 'nowrap',
              }}
            >
              {headerRightContent}
            </Box>
            <Box sx={{ marginLeft: 1 }}>
              <ActionsMenu
                actions={actionsMenu}
                onClick={_onActionMenuClick}
                iconTooltip={!isAllowedBtn ? notAllowedTitle : undefined}
                iconSize={actionsMenuIconSize}
              />
            </Box>
          </Box>
        </Box>

        {content && (
          <Box
            onClick={onLineClick}
            sx={{
              width: '100%',
              pb: 2,
            }}
          >
            <Typography variant="body">{content}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ListItemActionCard;
