import { useCallback, useContext } from 'react';
import { CanvasElementType } from '../canvas-types';
import { useSweepCanvasState } from '../internal-context/CanvasStateContext';
import { SweepCanvasRfNode } from '../canvas-types/nodeTypesData';
import { HighlightEntityContext } from './HighlightEntityContext';

const DEFAULT_TIMEOUT = 3000;

export const useHighlightNodesAndEdges = () => {
  const { setCanvasNodes, setCanvasEdges, getCanvasEdges } = useSweepCanvasState();

  const { timeoutRef } = useContext(HighlightEntityContext);

  const clearHighlight = useCallback(() => {
    clearTimeout(timeoutRef.current?.timeout);
    timeoutRef.current = null;

    setCanvasNodes((prevNodes) =>
      prevNodes.map((node) => {
        if (
          (node.type === CanvasElementType.REGULAR ||
            node.type === CanvasElementType.GROUP_LABEL) &&
          Boolean(node.data?.isHighlighted)
        ) {
          return {
            ...node,
            data: {
              ...node.data,
              isHighlighted: false,
            },
            zIndex: 1,
          } as SweepCanvasRfNode;
        }
        return node;
      }),
    );
    setCanvasEdges((prevEdges) =>
      prevEdges.map((edge) => {
        if (edge.data?.highlightType) {
          edge.data.highlightType = null;
          edge.zIndex = 0;
        }
        return edge;
      }),
    );
  }, [setCanvasEdges, setCanvasNodes, timeoutRef]);

  const startHighlightNodeAndConnectedEdges = useCallback(
    (nodeId: string) => {
      // Clear any existing timeout
      clearTimeout(timeoutRef.current?.timeout);
      if (timeoutRef.current?.entityId === nodeId) {
        timeoutRef.current.timeout = setTimeout(clearHighlight, DEFAULT_TIMEOUT);
      } else {
        timeoutRef.current = {
          timeout: setTimeout(clearHighlight, DEFAULT_TIMEOUT),
          entityId: nodeId,
        };

        setCanvasNodes((prevNodes) =>
          prevNodes.map((node) => {
            const isHighlighted = node.id === nodeId;

            if (
              node.type === CanvasElementType.GROUP_LABEL ||
              (node.type === CanvasElementType.REGULAR &&
                Boolean(node.data?.isHighlighted) !== isHighlighted)
            ) {
              return {
                ...node,
                data: {
                  ...node.data,
                  isHighlighted,
                },
                zIndex: isHighlighted ? 3 : 1,
              } as SweepCanvasRfNode;
            }

            return node;
          }),
        );

        setCanvasEdges((prevEdges) =>
          prevEdges.map((edge) => {
            if (edge.data) {
              if (edge.source === nodeId) {
                return {
                  ...edge,
                  data: {
                    ...edge.data,
                    highlightType: 'source',
                  },
                  zIndex: 2,
                };
              } else if (edge.target === nodeId) {
                return {
                  ...edge,
                  data: {
                    ...edge.data,
                    highlightType: 'target',
                  },
                  zIndex: 2,
                };
              } else if (edge.data.highlightType) {
                return {
                  ...edge,
                  data: {
                    ...edge.data,
                    highlightType: null,
                  },
                  zIndex: 0,
                };
              }
            }
            return edge;
          }),
        );
      }
    },
    [clearHighlight, setCanvasEdges, setCanvasNodes, timeoutRef],
  );

  const startHighlightEdge = useCallback(
    (edgeId: string) => {
      clearTimeout(timeoutRef.current?.timeout);
      if (timeoutRef.current?.entityId === edgeId) {
        timeoutRef.current.timeout = setTimeout(clearHighlight, DEFAULT_TIMEOUT);
      } else {
        timeoutRef.current = {
          timeout: setTimeout(clearHighlight, DEFAULT_TIMEOUT),
          entityId: edgeId,
        };

        const edge = getCanvasEdges().find((edge) => edge.id === edgeId);
        if (edge?.data?.highlightType) {
          return;
        }

        setCanvasNodes((prevNodes) =>
          prevNodes.map((node) => {
            if (
              node.type === CanvasElementType.GROUP_LABEL ||
              node.type === CanvasElementType.REGULAR
            ) {
              if (node.data.isHighlighted) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    isHighlighted: false,
                  },
                } as SweepCanvasRfNode;
              }
            }
            return node;
          }),
        );

        setCanvasEdges((prevEdges) =>
          prevEdges.map((edge) => {
            const isHighlighted = edge.id === edgeId;
            if (edge.data) {
              if (isHighlighted && !edge.data.highlightType) {
                return { ...edge, data: { ...edge.data, highlightType: 'simple' } };
              } else {
                if (edge.data.highlightType) {
                  return { ...edge, data: { ...edge.data, highlightType: null } };
                }
              }
            }
            return edge;
          }),
        );
      }
    },
    [clearHighlight, getCanvasEdges, setCanvasEdges, setCanvasNodes, timeoutRef],
  );

  return { startHighlightNodeAndConnectedEdges, startHighlightEdge, clearHighlight };
};
