import { useEffect, useMemo, useState } from 'react';
import useObjectTypes from './useObjectTypes';
import { useSelector } from 'react-redux';
import { selectIsLoadingObjectTypes } from '../components/pages/environments/environmentsReducer';
import keyBy from 'lodash/keyBy';

interface UseObjectTypesWithFetchProps {
  crmOrgId?: string;
}

export interface ObjectTypePropertyFilterType {
  triggerable?: boolean;
  updateable?: boolean;
  createable?: boolean;
  queryable?: boolean;
  includeBig5?: boolean;
}

const isBig5 = (objectType: ObjectTypeName) => {
  return ['Lead', 'Account', 'Contact', 'Opportunity', 'Case'].includes(objectType.objectType);
};

export const objectTypesPropertyFilter = ({
  objectTypes,
  filters,
}: {
  objectTypes?: ObjectTypeName[];
  filters: ObjectTypePropertyFilterType;
}) => {
  if (!objectTypes) return undefined;
  const { triggerable, updateable, createable, queryable } = filters;

  return objectTypes.filter((objectType) => {
    if (filters.includeBig5 && isBig5(objectType)) {
      return true;
    }
    return (
      (triggerable === undefined || objectType.triggerable === triggerable) &&
      (updateable === undefined || objectType.updateable === updateable) &&
      (createable === undefined || objectType.createable === createable) &&
      (queryable === undefined || objectType.queryable === queryable)
    );
  });
};

const useObjectTypesWithFetch = ({ crmOrgId = '' }: UseObjectTypesWithFetchProps) => {
  const { objectTypes, fetchCrmOrgObjectTypes } = useObjectTypes({ crmOrgId });
  const [errorFetching, setErrorFetching] = useState(false);
  const isLoading = useSelector(selectIsLoadingObjectTypes(crmOrgId));

  useEffect(() => {
    if (!crmOrgId || errorFetching) return;

    /*
        Verifies that objectTypes are not already fetched and that the fetch is not in progress
    */
    const shouldFetch = !objectTypes && !isLoading;

    if (shouldFetch) {
      fetchCrmOrgObjectTypes({
        crmOrgId,
      }).then(({ error }) => {
        setErrorFetching(Boolean(error));
      });
    }
  }, [fetchCrmOrgObjectTypes, objectTypes, isLoading, crmOrgId, errorFetching]);

  const objectTypesByName = useMemo(() => keyBy(objectTypes, 'objectType'), [objectTypes]);

  return {
    objectTypes,
    isLoading,
    objectTypesByName,
  };
};

export default useObjectTypesWithFetch;
