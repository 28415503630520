import { Box, CircularProgress } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { useCallback, useState } from 'react';

export type DialogLoaderVariant = 'light' | 'dark';

export const DialogLoader = ({
  visible,
  variant = 'dark',
}: {
  visible?: boolean;
  variant?: DialogLoaderVariant;
}) => {
  if (!visible) {
    return null;
  }
  return (
    <Box sx={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }}>
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: variant === 'light' ? colors.white : colors.grey[900],
          zIndex: 999,
          opacity: variant === 'light' ? 0.8 : 0.4, //not 0.5 to differentiate from the normal dialog drop-shadow, so they don't blend (in case of a dialog on top of another dialog)
          top: 0,
          left: 0,
        }}
      />

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100%"
        flexDirection="column"
        gap={3}
        position="relative"
        zIndex={999}
      >
        <CircularProgress />
        {variant === 'light' && (
          <Typography color={colors.grey[900]} variant="body-bold">
            Loading...
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export const useDialogLoader = (initiallyVisible: boolean = false) => {
  const [isLoaderVisible, setLoaderVisibility] = useState(initiallyVisible);
  const hideLoader = useCallback(() => setLoaderVisibility(false), []);
  const showLoader = useCallback(() => setLoaderVisibility(true), []);

  const Loader = <DialogLoader visible={isLoaderVisible} />;

  return {
    setLoaderVisible: setLoaderVisibility,
    loaderVisible: isLoaderVisible,
    hideLoader,
    showLoader,
    DialogLoader: Loader,
  };
};
