import { Box, FormControl, MenuItem, SelectChangeEvent } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { useSweepApi } from '../../apis/sweep';
import { useRunOnce } from '../common/useRunOnce';
import { useState } from 'react';
import SweepSelect from '../common/SweepSelect';

interface AutomationNotifyEmailProps {
  readonly?: boolean;
  action: RunApexAutomationAction;
  onChange: (emails: RunApexAutomationAction) => any;
  crmOrgId: string;
}

interface ApexClassesResponse {
  className: string;
  methods: string[];
}

export const RunApexActionWrapper = ({
  readonly,
  action,
  onChange,
  crmOrgId,
}: AutomationNotifyEmailProps) => {
  const [apexData, setApexData] = useState<ApexClassesResponse[]>();
  const [isLoading, setIsLoading] = useState(true);
  const sweepApi = useSweepApi();

  const getApexData = async () => {
    const response = await sweepApi.get(`/crm-orgs/org/${crmOrgId}/apex-classes`);
    return response.data as ApexClassesResponse[];
  };

  useRunOnce(async () => {
    const data = await getApexData();
    setApexData(data);
    setIsLoading(false);
  });
  return (
    <>
      <Box
        sx={{
          background: colors.grey[100],
          padding: '24px 24px 19px',
          borderRadius: '8px',
          margin: '16px 0',
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography variant="body-medium">Invoke custom Apex</Typography>
        </Box>

        <FormControl
          sx={{
            minWidth: '120px',
            margin: '0 22px 16px 0',
          }}
        >
          <Box sx={{ display: 'flex', marginBottom: '8px', alignItems: 'center', gap: 1 }}>
            <Box sx={{ width: '65px' }}>
              <Typography variant="caption" color={colors.grey[800]}>
                Apex class:
              </Typography>
            </Box>

            <SweepSelect
              SelectProps={{
                disabled: readonly,
                placeholder: 'Choose class',
                value: action?.actionParams?.className ?? '',
                onChange: (event: SelectChangeEvent<unknown>) => {
                  const val = event.target.value as string;
                  onChange({
                    ...action,
                    actionParams: {
                      ...action.actionParams,
                      className: val,
                    },
                  });
                },
                renderValue: (val: any) => val,
              }}
              isLoading={isLoading}
            >
              {apexData
                ?.map((el) => el.className)
                .map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              {apexData?.length === 0 && <MenuItem disabled>No apex classes available</MenuItem>}
            </SweepSelect>
          </Box>
        </FormControl>
        <FormControl
          sx={{
            minWidth: '120px',
            margin: '0 22px 0px 0',
            display: 'flex',
            alignContent: 'center',
          }}
        >
          <Box sx={{ display: 'flex', marginBottom: '8px', alignItems: 'center', gap: 1 }}>
            <Box sx={{ width: '65px' }}>
              <Typography variant="caption" color={colors.grey[800]}>
                Method:
              </Typography>
            </Box>

            <SweepSelect
              SelectProps={{
                disabled: readonly || !action?.actionParams?.className,
                placeholder: 'Choose static method',
                value: action?.actionParams?.methodName ?? '',
                onChange: (event: SelectChangeEvent<unknown>) => {
                  const val = event.target.value as string;
                  onChange({
                    ...action,
                    actionParams: {
                      ...action.actionParams,
                      methodName: val,
                    },
                  });
                },
                renderValue: (val: any) => val,
              }}
              isLoading={isLoading}
            >
              {apexData
                ?.find((el) => el.className === action?.actionParams?.className)
                ?.methods?.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              {(!apexData?.find((el) => el.className === action?.actionParams?.className)
                ?.methods ||
                apexData?.find((el) => el.className === action?.actionParams?.className)?.methods
                  ?.length === 0) && <MenuItem disabled>No apex methods available</MenuItem>}
            </SweepSelect>
          </Box>
        </FormControl>
      </Box>
    </>
  );
};
