import { Box } from '@mui/material';
import { useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { AuditTableFilters, FilterOptions } from './AuditTable';
import { SearchInput } from '../SearchInput';
import { SearchInputContext } from '../../pages/salesforce-logs/change-feed/SearchInputContext';
import { newSearchSx } from '../../pages/canvas-pages/canvasPanelConsts';
import { FiltersPanel } from './FiltersPanel';

interface AuditFiltersProps {
  setFilter: (newFilter: Partial<AuditTableFilters>) => void;
  sortAndFilter: AuditTableFilters;
  disableFilters: boolean;
  filterOptions: FilterOptions;
  searchPlaceholder?: string;
}

const AuditFilters = ({
  setFilter,
  disableFilters,
  sortAndFilter,
  filterOptions,
  searchPlaceholder = 'Search record ID',
}: AuditFiltersProps) => {
  const { searchInputRef } = useContext(SearchInputContext);
  const debounced = useRef(debounce((searchTxt) => setSaveSearchVal(searchTxt), 500));
  const [searchVal, setSearchVal] = useState(sortAndFilter.search);
  const [saveSearchVal, setSaveSearchVal] = useState<string>();

  useImperativeHandle(searchInputRef, () => {
    return {
      clear: () => {
        setSearchVal('');
      },
    };
  }, []);

  /**
   * passing sortAndFilter directly to debounce function is not updating its state
   * so using saveSearchVal to make it work
   */
  useEffect(() => {
    setFilter({ ...sortAndFilter, search: saveSearchVal });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveSearchVal]);

  const onChange = useCallback((searchTxt: string) => {
    setSearchVal(searchTxt);
    debounced.current(searchTxt);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        gap: 3,
        justifyContent: 'space-between',
        ...newSearchSx,
      }}
    >
      <FiltersPanel
        setFilter={setFilter}
        disableFilters={disableFilters}
        sortAndFilter={sortAndFilter}
        filterOptions={filterOptions}
      />
      <Box>
        <SearchInput
          withFixedMagnifyingGlassIcon
          placeholder={searchPlaceholder}
          TextFieldProps={{
            sx: { width: '300px' },
            value: searchVal,
            onChange: (event) => onChange(event.target.value),
            disabled: disableFilters,
          }}
          onClearButtonClick={() => onChange('')}
          variant={'small'}
        />
      </Box>
    </Box>
  );
};

export { AuditFilters };
