import { DialogContent, Stack } from '@mui/material';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { AutomationsForm } from './AutomationsForm';
import useConfirm from '../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import { useCrmOrgsApiFacade } from '../../apis/facades/useCrmOrgsApiFacade';
import DialogOnCanvas from '../pages/canvas-pages/DialogOnCanvas';
import { ConfirmLeaveWithoutSaveDialog } from '../common/dialogs/ConfirmLeaveWithoutSave/ConfirmLeaveWithoutSaveDialog';
import { telemetry } from '../../telemetry';
import useSendOpenAutomationEvent from './useSendOpenAutomationEvent';
import { useAutomationsContext } from './AutomationsContext';
import { AutomationDialogActions } from './AutomationDialogActions';
import ImpactAnalysisChat from './impact-analysis-agent/ImpactAnalysisChat';

export type SingleAutomationDialogRef = {
  isPristine: () => boolean;
};

export interface SingleAutomationDialogProps {
  onSaveOrCreate: (
    automation: AutomationStructureNew,
    crmOrgIds: string[],
  ) => Promise<{ versionId?: string; automationId?: string; isError?: string }>;
  onCancel: () => any;
  isOpen: boolean;
  readonly?: boolean;
  crmOrgId: string;
  objectName: ObjectTypeValues;
  filterFunnelIds?: string[];
  isShowWideScreenPopup?: boolean;
}

export const SingleAutomationDialog = forwardRef<
  SingleAutomationDialogRef,
  SingleAutomationDialogProps
>(
  (
    {
      isOpen,
      onSaveOrCreate,
      onCancel: closeDialog,
      readonly,
      crmOrgId,
      objectName,
      filterFunnelIds,
      isShowWideScreenPopup,
    },
    ref,
  ) => {
    const [isImpactAnalysisOpen, setIsImpactAnalysisOpen] = useState(false);
    const { automationJson, isObjectless } = useAutomationsContext();
    const [isPristine, setIsPristine] = useState(true);
    const [isFormValid, setIsFormValid] = useState(false);
    const { openConfirm } = useConfirm();
    const [isDoneLoadingFunnelsDataForObject, setIsDoneLoadingFunnelsDataForObject] =
      useState(false);

    const [recordTypesAndFunnelsDataForObject, setRecordTypesAndFunnelsDataForObject] =
      useState<ParsedRecordTypesAndFunnelsObjectData>();

    const { getObjectFunnelsAndRecordTypes } = useCrmOrgsApiFacade();

    useEffect(() => {
      const setData = async () => {
        if (isObjectless) {
          setIsDoneLoadingFunnelsDataForObject(true);
        } else if (objectName) {
          try {
            const parsedDataForObject = await getObjectFunnelsAndRecordTypes({
              crmOrgId,
              objectName,
            });

            setRecordTypesAndFunnelsDataForObject(
              parsedDataForObject ?? {
                name: objectName,
                label: objectName,
                recordTypes: [],
                funnels: [],
              },
            );
            setIsDoneLoadingFunnelsDataForObject(true);
          } catch (e) {
            setIsDoneLoadingFunnelsDataForObject(false);
            telemetry.captureError(e);
          }
        }
      };
      setData();
    }, [crmOrgId, getObjectFunnelsAndRecordTypes, isObjectless, objectName]);

    const _onCancel = useCallback(async () => {
      if (isPristine) {
        closeDialog();
      } else {
        const isConfirmed = await openConfirm(<ConfirmLeaveWithoutSaveDialog />);
        if (isConfirmed) {
          closeDialog();
        }
      }
    }, [isPristine, openConfirm, closeDialog]);

    useImperativeHandle(ref, () => {
      return {
        isPristine() {
          return isPristine;
        },
      };
    }, [isPristine]);

    useSendOpenAutomationEvent();

    useEffect(() => {
      if (!isOpen) {
        setIsImpactAnalysisOpen(false);
      }
    }, [isOpen]);

    return (
      <DialogOnCanvas
        open={isOpen}
        onClose={_onCancel}
        blockFully={!isPristine && !isShowWideScreenPopup}
        forceWideScreen={isShowWideScreenPopup || isImpactAnalysisOpen}
      >
        <Stack flex={1} direction="row" position="relative" overflow="hidden">
          <Stack flex={1} sx={{ overflowX: 'auto' }}>
            <DialogContent sx={{ paddingTop: 0 }}>
              <AutomationsForm
                key={`${automationJson?.automationId}${automationJson?.versionId}`}
                objectName={objectName}
                readonly={readonly}
                crmOrgId={crmOrgId}
                onBackButtonClick={_onCancel}
                onPristineChange={setIsPristine}
                updateIsFormValid={setIsFormValid}
                recordTypesAndFunnelsDataForObject={recordTypesAndFunnelsDataForObject}
                filterFunnelIds={filterFunnelIds}
                isDoneLoading={isDoneLoadingFunnelsDataForObject}
              />
            </DialogContent>
            <AutomationDialogActions
              isAutomationsFormValid={isFormValid}
              readonly={readonly}
              onSaveOrCreate={onSaveOrCreate}
              crmOrgId={crmOrgId}
              toggleImpactAnalysis={() => setIsImpactAnalysisOpen((v) => !v)}
              isImpactAnalysisOpen={isImpactAnalysisOpen}
            />
          </Stack>
          <ImpactAnalysisChat
            isOpen={isImpactAnalysisOpen}
            close={() => setIsImpactAnalysisOpen(false)}
            automation={automationJson}
          />
        </Stack>
      </DialogOnCanvas>
    );
  },
);
