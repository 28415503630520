import { MenuList, Box, Divider } from '@mui/material';
import { Calendar, Edit } from '@sweep-io/sweep-design/dist/icons';
import { StyledListItem } from '../StyledListItem';

export const DatePickerMenuList = ({
  value,
  customOptions,
  hideCustomDateButton,
  onItemClick,
  showCustomDatePicker,
  onCustomButtonClick,
}: {
  showCustomDatePicker: boolean;
  value: any;
  customOptions: any;
  onItemClick: any;
  hideCustomDateButton?: boolean;
  onCustomButtonClick?: any;
}) => {
  return (
    <MenuList
      sx={{
        padding: 0,
        minWidth: '244px',
      }}
    >
      {customOptions.map((option: any) => (
        <StyledListItem
          key={option.value}
          title={option.label}
          onClick={() => onItemClick(option.value)}
          isActive={value === option.value}
          StartIcon={<Calendar />}
        />
      ))}

      {!hideCustomDateButton && (
        <Box component="span">
          <Divider />
          <StyledListItem
            title={'Custom'}
            onClick={onCustomButtonClick}
            isActive={showCustomDatePicker}
            StartIcon={<Edit />}
            sx={{ marginBottom: 0, marginTop: '8px' }}
          />
        </Box>
      )}
    </MenuList>
  );
};
