import { colors, Tag } from '@sweep-io/sweep-design';
import Box from '@mui/material/Box';

interface InactiveTagProps {
  isActive: boolean;
}

export const InactiveTag = ({ isActive }: InactiveTagProps) => {
  if (isActive) {
    return <></>;
  }

  return (
    <Box
      pr={0.5}
      pl={0.5}
      display="flex"
      alignItems="center"
      sx={{
        '& .MuiChip-root': {
          display: 'flex',
        },
      }}
    >
      <Tag color={colors.grey[120]} size="tiny" label="Inactive" />
    </Box>
  );
};
