import pluralize from 'pluralize';
import { TableWithHeader, TableWithHeaderProps } from './shared/TableWithHeader';
import { TableTypography } from './shared/helpers';
import { ObjectTypeLabelAndKeyTag } from '../../common/ObjectTypeLabelAndKey';
import useObjectTypesWithFetch from '../../../hooks/useObjectTypesWithFetch';
import { Dictionary } from 'lodash';
import Stack from '@mui/material/Stack';

interface RelatedListTableProps
  extends Pick<TableWithHeaderProps, 'headerVariant' | 'tableHeader'> {
  items: RelatedListTableItem[];
  shortListViewCounter?: number;
  crmOrgId: string;
}

interface RelatedListTableItem {
  name: string;
  label: string;
  objectName: string;
}

const columns = [
  { field: 'label', headerName: 'Name', width: '160px' },
  { field: 'objectName', headerName: 'Object' },
];

const renderRows = ({
  items,
  objectTypesByName,
}: {
  items: RelatedListTableItem[];
  objectTypesByName: Dictionary<ObjectTypeName>;
}) => {
  return items.map((row) => ({
    id: row.name,
    label: <TableTypography>{row.label}</TableTypography>,
    objectName: (
      <Stack minWidth={0} maxWidth="200px">
        <ObjectTypeLabelAndKeyTag
          label={objectTypesByName?.[row.objectName]?.label ?? row.objectName}
          objectType={row.objectName}
        />
      </Stack>
    ),
  }));
};

export const RelatedListTable = ({
  items,
  tableHeader,
  crmOrgId,
  ...props
}: RelatedListTableProps) => {
  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId });
  const rows = renderRows({ items, objectTypesByName });
  return (
    <TableWithHeader
      {...props}
      key={`tableHeader_${tableHeader}`}
      tableHeader={pluralize(tableHeader, rows.length)}
      columns={columns}
      rows={rows}
    />
  );
};
