import { useCallback, useEffect, useRef, useState } from 'react';

const useContainerWithScrollArrow = () => {
  const containerRef = useRef<HTMLDivElement>();
  const containerElement = containerRef.current;

  const [showScrollButton, setShowScrollButton] = useState(false);

  const handleScroll = useCallback(() => {
    if (!containerElement) return;

    const isAtBottom =
      containerElement.scrollHeight - containerElement.scrollTop <=
      containerElement.clientHeight + 100; // 100px threshold (don't show the arrow if there's less than 100px to scroll)
    setShowScrollButton(!isAtBottom);
  }, [containerElement]);

  const scrollToBottom = useCallback(() => {
    containerElement?.scrollTo({ top: containerElement.scrollHeight, behavior: 'smooth' });
  }, [containerElement]);

  useEffect(() => {
    containerElement?.addEventListener('scroll', handleScroll);
    return () => containerElement?.removeEventListener('scroll', handleScroll);
  }, [containerElement, handleScroll]);

  return { showScrollButton, scrollToBottom, containerRef };
};

export default useContainerWithScrollArrow;
