import { MarketingAttributionMultiTouchModel } from '@server/marketing-attribution';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { colors, Tag, Typography } from '@sweep-io/sweep-design';
import {
  getReadOnlyWeights,
  translateWeightName,
} from '../../pages/canvas-pages/canvas-hubspot-page/marketing-attribution/utils';
import { IntegerPicker } from '../../pages/assignments/group-members/IntegerPicker';
import { Percentage } from '../../common/Percentage';
import { DataTable } from '../../common/table/DataTable';

const columns = [
  {
    headerName: 'Name',
    field: 'name',
    width: '50%',
  },
  {
    field: 'weight',
    headerName: 'Weight distribution',
    width: '50%',
  },
];

const headerText = (modelType: string) => {
  switch (modelType) {
    case 'EVEN_DISTRIBUTION':
      return 'Even weights';
    case 'MULTI_TOUCH':
      return 'Set custom weights';
    default:
      return 'Custom weights';
  }
};

interface WeightsProps {
  modelType: string;
  weights?: MarketingAttributionMultiTouchModel['weights'];
  readonly?: boolean;
  onChange?: (weights: MarketingAttributionMultiTouchModel['weights']) => void;
}

export const Weights = ({ modelType, weights, readonly, onChange }: WeightsProps) => {
  const isMultiTouch = modelType == 'MULTI_TOUCH';
  const _weights = isMultiTouch
    ? (weights ?? { firstTouch: 0.33, lastTouch: 0.33, rest: 0.34 })
    : getReadOnlyWeights(modelType);
  const totalWeights = Object.values(_weights ?? {}).reduce((acc, curr) => acc + curr, 0);
  const showExampleBadge = modelType === 'EVEN_DISTRIBUTION';

  const rows = Object.entries(_weights ?? {}).map(([key, value]) => ({
    id: key,
    name: translateWeightName(key),
    weight: (
      <Box display="flex" alignItems="center" gap={2} width="100%">
        <Box className="show-on-hover">
          <IntegerPicker
            useImmediateUpdate
            disabled={readonly ? readonly : !isMultiTouch}
            value={value * 100}
            onChange={(weight) => {
              onChange?.({ ...weights, [key]: weight ? weight / 100 : 0 } as any);
            }}
            min={1}
          />
        </Box>

        <Box width="112px">
          <Percentage numerator={value} active />
        </Box>
      </Box>
    ),
  }));

  return (
    <Stack
      sx={{
        pt: 2,
        gap: 2,
        '& .show-on-hover': {
          button: { opacity: 0 },
          '.MuiOutlinedInput-root fieldset': { borderColor: 'transparent' },
          '.MuiDivider-root': { opacity: 0 },
        },
        '&:hover .hide-on-hover': {
          display: 'none',
        },
      }}
    >
      <Typography variant="h2">{headerText(modelType)}</Typography>
      {showExampleBadge && (
        <Typography variant="body" color={colors.grey[800]}>
          In even distribution, all weight will be distributed evenly between campaigns.
        </Typography>
      )}

      <Stack
        height="100%"
        position="relative"
        sx={{
          ...(showExampleBadge && {
            mt: '10px',
            p: '24px 16px 16px',
            border: '1px solid',
            borderColor: colors.grey[120],
            borderRadius: '8px',
          }),
        }}
        gap={2}
      >
        {showExampleBadge && (
          <Box sx={{ position: 'absolute', top: '-10px', left: '10px', zIndex: 1 }}>
            <Tag color={colors.lilac[100]} size="medium" label="Example" />
          </Box>
        )}
        <DataTable columns={columns} rows={rows} />
      </Stack>

      {(totalWeights > 1 || totalWeights < 1) && (
        <Box sx={{ left: '50%', position: 'relative', width: '310px' }}>
          <Typography variant="body-medium" color={colors.blush[500]}>
            Fix weight distribution to a total of 100 percent
          </Typography>
        </Box>
      )}
    </Stack>
  );
};
