import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { SxProps, TableRow as MuiTableRow, Theme } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';
import { Reorder } from '@sweep-io/sweep-design/dist/icons';
import TableRowCells from './TableRowCells';
import { DataTableDraggableColumn, DataTableRow, DataTableVariant } from './TableTypes';
import { dataTableVariants } from './dataTableVariants';
import { ACTION_BUTTONS_KEY, linearGradient } from './utils';
import { ActionCell } from './ActionCell';

interface RowProps<TRow extends DataTableRow> {
  row: TRow;
  columns: DataTableDraggableColumn[];
  sxRowFunction?: (isDragging: boolean) => SxProps<Theme>;
  allowReorder?: boolean;
  isBeingDragged: boolean;
  isOverlay: boolean;
  onClick?: (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    columnData: DataTableDraggableColumn,
  ) => void;
  variant: DataTableVariant;
}

export function DraggableTableRow<TRow extends DataTableRow = any>({
  isOverlay,
  row,
  allowReorder,
  sxRowFunction,
  variant,
  onClick,
  isBeingDragged,
  ...rowProps
}: RowProps<TRow>) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: row.id,
    disabled: row.disabled,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition: transition || undefined,
  };

  const sxRow = sxRowFunction ? sxRowFunction(Boolean(isBeingDragged)) : {};

  const isRenderEmpty = isBeingDragged && !isOverlay;
  const actionButtons = row[ACTION_BUTTONS_KEY];

  return (
    <MuiTableRow
      ref={setNodeRef}
      data-testid="data-table-row"
      style={style}
      {...attributes}
      sx={{
        minHeight: dataTableVariants[variant].minRowHeight,
        backgroundColor: row.isRowActive ? colors.grey[150] : 'transparent',
        '&:hover .action-buttons': {
          '&:before': {
            background: linearGradient(colors.grey[150]),
            visibility: 'visible',
          },
          '.custom-action-button': {
            visibility: 'visible',
          },
        },
        '.action-buttons:before': {
          background: row.isRowActive ? linearGradient(colors.grey[150]) : undefined,
        },
        ...sxRow,
        animation: row._animation ? row._animation : '',
        cursor: row.isClickable ? 'pointer' : 'default',
      }}
      className="SweepDataTableRow"
    >
      <TableRowCells
        isRenderEmpty={isRenderEmpty}
        row={row}
        allowReorder={!!allowReorder}
        dragHandle={<Reorder {...listeners} color={colors.grey[800]} />}
        variant={variant}
        onClick={
          !!onClick
            ? (
                e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
                column: DataTableDraggableColumn,
              ) => onClick(e, column)
            : undefined
        }
        {...rowProps}
      />
      {(!!onClick || actionButtons) && (
        <ActionCell
          row={row}
          variant={variant}
          sxRowFunction={sxRowFunction}
          onClick={onClick}
          {...rowProps}
        />
      )}
    </MuiTableRow>
  );
}
