import { Box, Stack } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import {
  InlineAutocompleteItem,
  InlineAutocompleteVariants,
  InlineAutocomplete,
} from '../../../../common/InlineAutocomplete/InlineAutocomplete';

interface TagsLineProps {
  title: string;
  selectedItems: string[];
  tags: InlineAutocompleteItem[];
  onSelectItem: (item: InlineAutocompleteItem) => void;
  onCreate?: (label: string) => void;
  onDeleteItem: (item: InlineAutocompleteItem) => void;
  onClick?: (item: InlineAutocompleteItem) => void;
  inlineComponentVariant?: InlineAutocompleteVariants;
  placeholder?: string;
  noBottomBorder?: boolean;
  headerTxt?: string;
  closeOnItemSelection?: boolean;
}

export const TagsLine = ({
  title,
  selectedItems,
  tags,
  onSelectItem,
  onCreate,
  onDeleteItem,
  onClick,
  inlineComponentVariant = 'tags',
  placeholder,
  noBottomBorder,
  headerTxt = 'Search tag',
  closeOnItemSelection,
}: TagsLineProps) => {
  return (
    <Stack
      sx={{
        borderBottom: noBottomBorder ? '' : `1px solid ${colors.grey[120]}`,
        flexDirection: 'row',
      }}
    >
      <Box
        minWidth="120px"
        borderRight={`1px solid ${colors.grey[120]}`}
        minHeight="40px"
        display="flex"
        alignItems="start"
        pt="13px"
      >
        <Typography color={colors.grey[800]} variant="caption">
          {title}
        </Typography>
      </Box>

      <Box width="300px">
        <InlineAutocomplete
          labelTooltip="Click to see all the configurations"
          deleteTooltip="Remove tag"
          inputTypographyVariant="caption"
          placeholder={placeholder}
          items={tags}
          selectedItemValues={selectedItems}
          headerTxt={headerTxt}
          onSelectItem={onSelectItem}
          onCreate={onCreate}
          onDeleteItem={onDeleteItem}
          onClick={onClick}
          variant={inlineComponentVariant}
          closeOnItemSelection={closeOnItemSelection}
          clearSearchTextOnSelection
          maxHeight={250}
        />
      </Box>
    </Stack>
  );
};
