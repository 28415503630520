import { useMemo } from 'react';
import { UI_EVENTS } from '../../../../../services/events';
import { AutomationFormVariant } from '../../../../../types/enums/AutomationFormVariant';
import { AutomationType } from '../../../../../types/enums/AutomationType';
import {
  AutomationsContextProvider,
  AutomationsContextType,
} from '../../../../Automations/AutomationsContext';

export const MarketingAttributionAutomationContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const contextValue: AutomationsContextType = useMemo(() => {
    return {
      automationType: AutomationType.MarketingAttributionModel,
      permissions: {
        create: 'create:marketing-attributions',
        edit: 'edit:marketing-attributions',
        deploy: 'deploy:marketing-attributions',
        delete: 'delete:marketing-attributions',
        notifyAdmin: 'edit:automations:notify-admin', //NOT SUPPORTED
      },
      emptyStateTitle: '',
      emptyStateImage: <></>,
      defaultName: 'New Attribution model',
      placeholder: 'New Attribution model',
      nameInMessage: 'marketing attribution models',
      nameInMessageSingular: 'marketing attribution model',
      openEvent: UI_EVENTS.marketingAttributionModelsOpen,
      automationVariant: AutomationFormVariant.MARKETING_ATTRIBUTIONS,
      templateSupport: false,
      requestDeployNotifyAdmin: () => Promise.resolve(), //NOT SUPPORTED
      impactAnalysisAiSupport: false,
      isObjectless: true,
      initialAutomationJson: {},
    };
  }, []);
  return <AutomationsContextProvider value={contextValue}>{children}</AutomationsContextProvider>;
};
