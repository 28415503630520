import { useFeatureToggle } from '../common/useFeatureToggle';
import { useSelector } from 'react-redux';
import { selectUserInfoData } from '../../reducers/userInfoReducer';

const useIsAiAgentsEnabled = () => {
  const {
    aiAgentCenter: processAiAgent,
    monitoringAiAgent,
    documentationAiAgent,
  } = useFeatureToggle();
  const user = useSelector(selectUserInfoData);
  const disableAi = !!user?.account?.disableAi;
  const atLeastOneAgentEnabled = processAiAgent || monitoringAiAgent || documentationAiAgent;

  return !disableAi && atLeastOneAgentEnabled;
};

export default useIsAiAgentsEnabled;
