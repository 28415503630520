import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSweepApi } from '../apis/sweep';
import {
  selectSnapshotsList,
  setSnapshotName,
  setSnapshotsList,
} from '../reducers/snapshotsReducer';
import { telemetry } from '../telemetry';

const useSnapshots = () => {
  const sweepApi = useSweepApi();
  const dispatch = useDispatch();

  const snapshotList = useSelector(selectSnapshotsList);
  const getSnapshotById = useCallback(
    (id: string) => snapshotList?.find((snapshot) => snapshot.id === id),
    [snapshotList],
  );

  const getSnapshots = useCallback(
    async (funnelId: string) => {
      try {
        const response = await sweepApi.get(`/funnels/${funnelId}/snapshots`);
        const snapshotsList = response.data;
        dispatch(setSnapshotsList({ snapshotsList }));
      } catch (e) {
        telemetry.captureError(e);
        dispatch(setSnapshotsList({ snapshotsList: [] }));
      }
    },
    [dispatch, sweepApi],
  );
  const editSnapshot = async (name: string, funnelId: string, snapshotId: string) => {
    const oldName = getSnapshotById(snapshotId)?.name;
    dispatch(setSnapshotName({ id: snapshotId, name }));
    try {
      await sweepApi.patch(`/funnels/${funnelId}/snapshots/${snapshotId}`, { name });
    } catch (e) {
      dispatch(setSnapshotName({ id: snapshotId, name: oldName ?? '' }));
      throw e;
    }
  };

  const restoreSnapshot = async (
    funnelId: string,
    sourceSnapshotId: string,
    preserveCurrent: boolean,
  ) => {
    return sweepApi.put(`/funnels/${funnelId}`, {
      sourceSnapshotId,
      preserveCurrent,
    });
  };

  return {
    getSnapshots,
    editSnapshot,
    restoreSnapshot,
  };
};

export { useSnapshots };
