import { Box, Card, Stack } from '@mui/material';
import { AI_AGENTS_INFO } from './aiAgentsConsts';
import AgentInfo from './AgentInfo';
import { colors, Tag, Typography } from '@sweep-io/sweep-design';
import classNames from 'classnames';
import { AgentType } from '@server/ai';

const AgentCard = ({
  agentName,
  agentType,
  onClick,
  opacity = 1,
  isRenderComingSoon,
}: {
  agentName: string;
  agentType: AgentType;
  onClick?: () => void;
  opacity?: number;
  isRenderComingSoon?: boolean;
}) => {
  const { agentIcon, persona, tags, description } = AI_AGENTS_INFO[agentType];

  return (
    <Card
      onClick={isRenderComingSoon ? undefined : onClick}
      sx={{ borderColor: '#E4F2F8', overflow: 'visible', opacity }}
      elevation={1}
      className={classNames({
        disabled: isRenderComingSoon,
      })}
    >
      <Stack p={2} gap={2}>
        <Stack direction="row" sx={{ justifyContent: 'center' }}>
          <AgentInfo icon={agentIcon} name={agentName} persona={persona} iconSize="40px" />
          {isRenderComingSoon && <Tag label="Coming soon" />}
        </Stack>
        <Typography variant="body">{description}</Typography>
        <Stack direction="row" gap={1}>
          {tags.map((tag) => (
            <Box
              key={tag}
              sx={{
                padding: '0 4px',
                height: '24px',
                border: `1px solid ${colors.grey[300]}`,
                borderRadius: '2px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant="caption" ellipsis>
                {tag}
              </Typography>
            </Box>
          ))}
        </Stack>
      </Stack>
    </Card>
  );
};

export default AgentCard;
