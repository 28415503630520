export enum WEBSOCKET_MESSAGES {
  DEPLOYMENT_COMPLETED = 'deployments/deploymentCompleted',
  AUTOMATIONS_DEPLOY_COMPLETED = 'automations/deployCompleted',
  AUTOMATIONS_TOGGLE_ACTIVATION_COMPLETED = 'automations/toggleActivationCompleted',
  AUTOMATIONS_ADMIN_NOTIFICATION_COMPLETED = 'automations/adminNotificationCompleted',
  AUTOMATIONS_DELETE_COMPLETED = 'automations/deleteCompleted',
  ROLLUPS_DEPLOY_COMPLETED = 'rollups/deployCompleted',
  ROLLUPS_ADMIN_NOTIFICATION_COMPLETED = 'rollups/adminNotificationCompleted',
  ROLLUPS_DELETE_COMPLETED = 'rollups/deleteCompleted',
  DEVOPS_CENTER_DEPLOY_ELEMENTS_COMPLETED = 'devOpsCenter/deployElementsCompleted',
  FETCH_COMPLETED = 'environments/fetchCompleted',
  REPORTING_ACTIVATION_COMPLETED = 'reports/activationCompleted',
  REPORTING_DEACTIVATION_COMPLETED = 'reports/deactivationCompleted',
  DATA_INTEGRATION_SLACK_CONNECTION_COMPLETED = 'integrations/slackConnectionCompleted',
  DATA_INTEGRATION_SLACK_DISCONNECTION_COMPLETED = 'integrations/slackDisconnectionCompleted',
  CREATE_COMMENT_THREAD = 'comments/createCommentThread',
  UPDATE_COMMENT_THREAD = 'comments/updateCommentThread',
  DELETE_COMMENT_THREAD = 'comments/deleteCommentThread',
  CREATE_COMMENT_REPLY = 'comments/createCommentReply',
  UPDATE_COMMENT_REPLY = 'comments/editCommentReply',
  DELETE_COMMENT_REPLY = 'comments/deleteCommentReply',
  NOTIFICATIONS_REFETCH = 'notifications/refetch',
  NEW_CHANGE_FEED_LIST_ITEMS = 'salesforceChangeFeed/newListItemsAdded',
  PARSE_COMPLETED = 'parser/parseCompleted',
  HOMEPAGE_REFRESH = 'homepage/refresh',
  DATA_INTEGRATION_TEAMS_CONNECTION_COMPLETED = 'integrations/teamsConnectionCompleted',
  DATA_INTEGRATION_TEAMS_DISCONNECTION_COMPLETED = 'integrations/teamsDisconnectionCompleted',
}
