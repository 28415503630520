import { Box } from '@mui/material';
import { funnelDetailModel } from '../../../../../models/funnelDetailModel';
import { RuleBuilderCollapsePanel } from './collapse-panels/RuleBuilderCollapsePanel';
import { Fragment, useEffect, useState } from 'react';
import { colors, Typography } from '@sweep-io/sweep-design';
import { FunnelLinkCollapsePanel } from './collapse-panels/FunnelLinkCollapsePanel';
import range from 'lodash/range';
import { EnrichedFunnelLink } from '../../funnel-links/types';
import { useGetEnrichedFunnelLinks } from '../../funnel-links/useGetEnrichedFunnelLinks';
import { telemetry } from '../../../../../telemetry';

const NoGates = () => (
  <Box
    sx={{
      backgroundColor: colors.grey[100],
      borderRadius: 1,
      p: 2,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <Typography variant="body" color={colors.grey[700]}>
      This step is not connected to other steps or funnels
    </Typography>
  </Box>
);

interface StageGatesTabProps {
  funnelStage: SweepStage;
  funnelDetails: FunnelDetails;
  defaultIsCollapsed: boolean;
  readonly?: boolean;
  crmOrgId: string;
  onGateSave: (updatedExitCriteria: SweepExitCriteria) => void;
  onGateDelete: (exitCriteriaId: string) => void;
  setGateIsDirty: ({
    isDirty,
    exitCriteriaId,
  }: {
    isDirty: boolean;
    exitCriteriaId: string;
  }) => void;
  funnelId: string;
  funnelName: string;
  funnelMap: FunnelMap;
}

const StageGatesTab = ({
  funnelStage,
  funnelDetails,
  defaultIsCollapsed,
  readonly,
  crmOrgId,
  onGateSave,
  onGateDelete,
  setGateIsDirty,
  funnelId,
  funnelName,
  funnelMap,
}: StageGatesTabProps) => {
  const { leadingObject } = funnelDetails;
  const { exitCriteria, stageName, funnelLinks: funnelLinksFromStage } = funnelStage;

  const getEnrichedFunnelLinks = useGetEnrichedFunnelLinks();
  const [enrichedFunnelLinks, setEnrichedFunnelLinks] = useState<EnrichedFunnelLink[]>([]);
  useEffect(() => {
    getEnrichedFunnelLinks(funnelLinksFromStage || [], funnelMap).then(
      ({ enrichedFunnelLinks, notFoundFunnelLinksIds }) => {
        setEnrichedFunnelLinks(enrichedFunnelLinks);
        console.error('Error loading enriched funnel links', notFoundFunnelLinksIds);
        telemetry.addBreadcrumb({
          message: 'Error loading enriched funnel links',
          data: {
            notFoundFunnelLinksIds,
          },
        });
      },
    );
  }, [getEnrichedFunnelLinks, funnelLinksFromStage, funnelMap]);

  const renderGates = () => {
    const hasFunnelLinks = !!funnelLinksFromStage?.length;
    if (exitCriteria.length === 0 && !hasFunnelLinks) {
      return <NoGates />;
    }
    return (
      <>
        {exitCriteria.map((exitCriteria) => {
          const toStageName = funnelDetailModel(funnelDetails)?.findNextStageName(
            exitCriteria._nextStageId,
          );
          const exitCriteriaId = exitCriteria._exitCriteriaId;
          const criteriaPlugins = funnelDetailModel(funnelDetails).criteriaPluginsForStage(
            funnelStage._stageId,
          );

          return (
            <Fragment key={exitCriteriaId}>
              {criteriaPlugins.map(({ criterion, pluginType }) => {
                if (!criterion?.length) return null;

                const pluginExitCriteria: SweepExitCriteria = {
                  ...exitCriteria,
                  _exitCriteriaId: `plugin-${pluginType}`,
                  criteria: criterion.map((criterion) => criterion.criterion),
                  criteriaLogic: range(1, criterion.length + 1)
                    .map((number) => `${number}`)
                    .join(' AND '),
                };
                return (
                  <RuleBuilderCollapsePanel
                    key={pluginType}
                    exitCriteria={pluginExitCriteria}
                    fromStageName={stageName}
                    toStageName={toStageName}
                    readonly
                    defaultIsCollapsed={defaultIsCollapsed}
                    stageObjectName={leadingObject.objectName}
                    crmOrgId={crmOrgId}
                    onGateSave={() => {}}
                    onGateDelete={() => {}}
                    setGateDirtyState={() => {}}
                    isPluginType={pluginType}
                    funnelId={funnelId}
                    funnelName={funnelName}
                  />
                );
              })}
              <RuleBuilderCollapsePanel
                key={exitCriteriaId}
                exitCriteria={exitCriteria}
                fromStageName={stageName}
                toStageName={toStageName}
                readonly={readonly}
                defaultIsCollapsed={defaultIsCollapsed}
                stageObjectName={leadingObject.objectName}
                crmOrgId={crmOrgId}
                onGateSave={onGateSave}
                onGateDelete={() => onGateDelete(exitCriteriaId)}
                setGateDirtyState={(isDirty) => setGateIsDirty({ exitCriteriaId, isDirty })}
                funnelId={funnelId}
                funnelName={funnelName}
              />
            </Fragment>
          );
        })}
        {enrichedFunnelLinks?.map((enrichedFunnelLink) => (
          <FunnelLinkCollapsePanel
            key={enrichedFunnelLink.stageId}
            defaultIsCollapsed={defaultIsCollapsed}
            fromStageName={stageName}
            enrichedFunnelLink={enrichedFunnelLink}
          />
        ))}
      </>
    );
  };

  return <Box mb={8}>{renderGates()}</Box>;
};
export { StageGatesTab };
