import { Box, FormControl, FormControlLabel, RadioGroup, Stack, TextField } from '@mui/material';
import { Section } from './atoms/Section';
import { colors, Radio, Typography } from '@sweep-io/sweep-design';
import {
  UserDefinedRoleGroupsInterface,
  RoleGroupType,
  RoleLevels,
  RoleGroupLevelType,
} from '@server/role-group-interface';
import { useRoleManagerContext } from './RoleManagerContext';
import { AccountBasedRoleLevels } from './role/AccountBasedRoleLevels';
import { OrgBasedRoleLevelsGroup } from './role/OrgBasedRoleLevelsGroup';
import { Info } from '@sweep-io/sweep-design/dist/icons';
import { useSelector } from 'react-redux';
import { selectCrmOrgs } from '../../pages/environments/environmentsReducer';

interface SingleRoleDetailsProps {
  role: UserDefinedRoleGroupsInterface;
}

export const SingleRoleDetails = ({ role }: SingleRoleDetailsProps) => {
  const {
    updateRole,
    accountBasedRoleLevels,
    orgBasedMultiGroupLevels,
    setAccountBasedRoleLevels,
    updateRoleLevelGroupType,
  } = useRoleManagerContext();
  const crmOrgsCount = useSelector(selectCrmOrgs)?.length ?? 0;
  const isEditable = role.type !== RoleGroupType.SYSTEM;

  const roleType = role.roleGroupLevels?.type;

  const renderGroupLevelsOrgBased = () => {
    return (
      <Box display="flex" flexDirection="column" pt={0.5} height="calc(100vh - 460px)">
        <Box display="flex" gap={0.5} pl={3} pb={2} alignItems="center">
          <Info variant="tiny" color={colors.grey[700]} />
          <Typography variant="caption" color={colors.grey[700]}>
            In unselected environments, default permissions are set to 'Viewer'
          </Typography>
        </Box>
        <OrgBasedRoleLevelsGroup
          roleGroupLevels={orgBasedMultiGroupLevels ?? []}
          isEditable={isEditable}
        />
      </Box>
    );
  };

  const renderGroupLevelAccountBased = () => {
    return (
      <Box pt={role.type === RoleGroupType.SYSTEM || crmOrgsCount < 2 ? 0 : 2} overflow="auto">
        <AccountBasedRoleLevels
          roleLevels={accountBasedRoleLevels as RoleLevels}
          isEditable={isEditable}
          onPermissionChange={(key, value) => setAccountBasedRoleLevels(value as RoleLevels)}
        />
      </Box>
    );
  };

  return (
    <Stack gap={2} height="100%">
      <Section headerTxt="Description">
        {!isEditable && (
          <Typography variant="body" color={colors.grey[800]}>
            {role.description}
          </Typography>
        )}

        {isEditable && (
          <TextField
            multiline
            rows={1}
            value={role.description ?? ''}
            onChange={(e) => updateRole('description', e.target.value)}
            placeholder="What is this role used for?"
          />
        )}
      </Section>

      <Section headerTxt="Permissions" withBorderBottom sx={{ height: '100%' }}>
        <Box height="100%">
          {role.type !== RoleGroupType.SYSTEM && crmOrgsCount > 1 && (
            <FormControl sx={{ '& .MuiFormGroup-root': { gap: 1 } }}>
              <RadioGroup
                value={roleType ?? RoleGroupLevelType.AccountBased}
                onChange={(event) =>
                  updateRoleLevelGroupType(event.target.value as RoleGroupLevelType)
                }
              >
                <FormControlLabel
                  value={RoleGroupLevelType.AccountBased}
                  control={<Radio />}
                  sx={{ '& .MuiButtonBase-root': { p: '4px 9px 4px 0' } }}
                  label={
                    <Typography variant="body">
                      Apply role's permissions to <Typography variant="body-bold">all</Typography>{' '}
                      connected environments
                    </Typography>
                  }
                />

                <FormControlLabel
                  value={RoleGroupLevelType.OrgBased}
                  control={<Radio />}
                  sx={{ '& .MuiButtonBase-root': { p: '4px 9px 4px 0' } }}
                  label={
                    <Typography variant="body">
                      Apply role's permissions{' '}
                      <Typography variant="body-bold">only in selected</Typography> environments
                    </Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          )}

          {roleType === RoleGroupLevelType.OrgBased && renderGroupLevelsOrgBased()}
          {roleType === RoleGroupLevelType.AccountBased && renderGroupLevelAccountBased()}
        </Box>
      </Section>
    </Stack>
  );
};
