import { Box } from '@mui/material';
import { colors, Tag, Typography } from '@sweep-io/sweep-design';

export type NoteAlignment = 'left' | 'center';

const ChatNote = ({ alignment }: { alignment: NoteAlignment }) => (
  <Box
    display="flex"
    gap={1}
    pl={alignment === 'left' ? 1.5 : 0}
    alignItems="center"
    justifyContent={alignment === 'left' ? 'start' : 'center'}
  >
    <Tag label="Beta" color={colors.storm[70]} />
    <Typography variant="caption" color={colors.grey[700]}>
      AI bots can make mistakes. Check important info.
    </Typography>
  </Box>
);

export default ChatNote;
