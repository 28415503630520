import { GroupNodeBoundaries, GroupBoundingBox } from './canvas-types/nodeTypesData';
import { CanvasGridPosition } from './helpers/gridPositioningUtils';
import { calculateGroupSizeAndMargins } from './nodes/helpers';

interface NodeWithCanvasPosition {
  id: string;
  position: CanvasGridPosition;
  parentId?: string;
}

export const calculateGroupDimensions = ({
  nodeWithCanvasPositions,
  isPreview,
  groupId,
}: {
  groupId: string;
  nodeWithCanvasPositions: NodeWithCanvasPosition[];
  isPreview?: boolean;
}): {
  groupNodeBoundaries: GroupNodeBoundaries;
  groupBoundingBox: GroupBoundingBox;
} => {
  const filteredNodes = nodeWithCanvasPositions.filter((node) => node.parentId === groupId);
  const rowsPositions = filteredNodes.map((node) => node.position.row);
  const colPositions = filteredNodes.map((node) => node.position.column);

  if (rowsPositions.length === 0) {
    rowsPositions.push(0);
  }
  if (colPositions.length === 0) {
    colPositions.push(0);
  }

  const groupNodeBoundaries = {
    minRow: Math.min(...rowsPositions),
    maxRow: Math.max(...rowsPositions) + (isPreview ? 0 : 1), // +1 You need to accommodate for the label
    minCol: Math.min(...colPositions),
    maxCol: Math.max(...colPositions),
  };

  const groupBoundingBox: GroupBoundingBox = calculateGroupSizeAndMargins({
    ...groupNodeBoundaries,
    discountFirstRow: !isPreview,
  });

  return {
    groupNodeBoundaries,
    groupBoundingBox,
  };
};
