import { Box, Grid } from '@mui/material';
import { Badge, colors, ExpandableTypography, Typography } from '@sweep-io/sweep-design';
import { UnreadComment } from '@sweep-io/sweep-design/dist/icons';
import uniqBy from 'lodash/uniqBy';
import keyBy from 'lodash/keyBy';
import { useSelector } from 'react-redux';
import { DIMENSION_AVATAR_SMALL } from '../../constants';
import { selectAccountUsers } from '../../reducers/accountUsersReducer';
import { CommentThreadProps } from './types';
import { AvatarPicture } from '../avatar/AvatarPicture';
import { getReplyText, humanizedTimeAgo, isAllowedToResolve } from './helper';
import { selectUserInfoData } from '../../reducers/userInfoReducer';
import useComments from './useComments';
import ResolveButton from './ResolveButton';
import { TextWithMentions } from '../common/comments/TextWithMentions';
import UnreadButton from './UnreadButton';
import { getIsUserInactive } from '../../lib/getIsUserActive';

interface CommentsPanelBoxProps {
  commentThread: CommentThreadProps;
  onClick: (commentThread: CommentThreadProps) => void;
}

const NewTag = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      backgroundColor: colors.blue[500],
      padding: 0.5,
      borderRadius: '2px',
      height: '20px',
    }}
  >
    <Typography variant="caption" color={colors.white}>
      New
    </Typography>
  </Box>
);

export const CommentsPanelBoxEmpty = ({ text }: { text: string }) => {
  return (
    <Grid container px={3} py={3} gap={2}>
      <Grid item xs={1} pt={0.5}>
        <UnreadComment variant="large" color={colors.grey[800]} />
      </Grid>
      <Grid item xs>
        <Typography variant="body">{text}</Typography>
      </Grid>
    </Grid>
  );
};

export const CommentsPanelBox = ({ commentThread, onClick }: CommentsPanelBoxProps) => {
  const { updateThread, updateThreadIsRead } = useComments();

  const firstReply = commentThread.commentReplies[0];
  const currentUser = useSelector(selectUserInfoData);
  const accountUsers = keyBy(useSelector(selectAccountUsers), 'id');
  const author = accountUsers[firstReply.createdById];
  const participants = uniqBy(
    commentThread.commentReplies.map((reply) => accountUsers[reply.createdById]),
    'id',
  );
  const participantsToRender = participants.slice(0, 2);
  const createdAgo = humanizedTimeAgo(firstReply.createdAt ?? '');
  const amountOfParticipantsReplies = commentThread.commentReplies.length - 1;
  const repliesText = getReplyText(amountOfParticipantsReplies);
  const { isResolved, isRead } = commentThread;
  const allowedToResolve = isAllowedToResolve(commentThread.commentReplies, currentUser?.id);
  const typographyColor = isResolved ? colors.grey[700] : colors.grey[900];

  const updateResolveCommentThread = (isResolved: boolean) => {
    updateThread({
      commentThreadId: commentThread.id,
      funnelMapId: commentThread.funnelMapId,
      isResolved,
    });
  };

  const markAsUnread = () => {
    updateThreadIsRead({
      commentThreadId: commentThread.id,
      funnelMapId: commentThread.funnelMapId,
      isRead: false,
    });
  };

  return (
    <Box
      p={2}
      mt={0.5}
      onClick={() => onClick(commentThread)}
      sx={{
        cursor: 'pointer',
        '.buttons': {
          visibility: 'hidden',
        },
        '&:hover': {
          backgroundColor: colors.grey[100],
          '.buttons': {
            visibility: 'visible',
          },
        },
      }}
    >
      <Box mb={1} display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" gap={1} alignItems="center">
          {participantsToRender.map((user) => (
            <AvatarPicture
              key={user?.id}
              avatar={{ emoji: user?.emoji, imageUrl: user?.imageUrl }}
              dimension={DIMENSION_AVATAR_SMALL}
              clickable={false}
              isInactive={getIsUserInactive(user?.status)}
              greyscale={isResolved}
            />
          ))}
          {participants.length > 2 && <Badge label={participants.length} />}
          {!isRead && <NewTag />}
        </Box>
        <Box className="buttons" display="flex" gap={1}>
          {!isResolved && <UnreadButton isRead={Boolean(isRead)} markAsUnread={markAsUnread} />}
          {allowedToResolve && (
            <ResolveButton
              isResolved={isResolved}
              updateResolveCommentThread={updateResolveCommentThread}
            />
          )}
        </Box>
      </Box>
      <Box display="flex" gap={1}>
        <Box>
          <Typography variant="body-bold" color={typographyColor}>
            {author?.name}
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" color={colors.grey[700]}>
            {createdAgo}
          </Typography>
        </Box>
      </Box>
      <ExpandableTypography variant="body" rows={3} color={typographyColor}>
        <TextWithMentions text={commentThread.commentReplies[0].commentBody} />
      </ExpandableTypography>
      {amountOfParticipantsReplies > 0 && (
        <Box mt={2}>
          <Typography variant="caption" color={colors.grey[700]}>
            {repliesText}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
