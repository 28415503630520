import _memoize from 'lodash/memoize';
import { Bezier } from 'bezier-js';
import { Position } from '@xyflow/react';
import { getControlWithCurvature } from '../../../third-party/reactFlowInternalFunctions';
import { SWEEP_GRID_SIZE, NODE_WIDTH, NODE_HEIGHT, EDGE_EXIT_ENTRY_GAP } from '../const';
import { ArrowDirection } from '../edges/FloatingEdge';
import { calculateArrowPath } from './calculateArrowPath';
import { CanvasEdgeConnectionType } from '../canvas-types';

const _getBezier = ({
  sourcePos,
  targetPos,
  sourceX,
  sourceY,
  targetX,
  targetY,
}: {
  sourcePos: Position;
  targetPos: Position;
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
}) => {
  const [sourceControlX, sourceControlY] = getControlWithCurvature({
    pos: sourcePos,
    x1: sourceX,
    y1: sourceY,
    x2: targetX,
    y2: targetY,
    c: 0.25,
  });
  const [targetControlX, targetControlY] = getControlWithCurvature({
    pos: targetPos,
    x1: targetX,
    y1: targetY,
    x2: sourceX,
    y2: sourceY,
    c: 0.25,
  });

  const b = new Bezier(
    sourceX,
    sourceY,
    sourceControlX,
    sourceControlY,
    targetControlX,
    targetControlY,
    targetX,
    targetY,
  );

  return b;
};

const _getHorizontalDetourPath = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  connectionIdx,
  arrowDirection,
}: {
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  connectionIdx: number;
  arrowDirection: ArrowDirection;
}) => {
  const stepGapWidth = SWEEP_GRID_SIZE.width - NODE_WIDTH;
  const horizontalDetourStartingPosition = NODE_HEIGHT / 2 + 35;
  const horizontalDetourIndexGap = 10;
  const arrowDirectionTransformation =
    ArrowDirection.RightLeft === arrowDirection ? 0 : -EDGE_EXIT_ENTRY_GAP;

  const horizontalDetourLineYPosition =
    horizontalDetourStartingPosition +
    horizontalDetourIndexGap * connectionIdx +
    arrowDirectionTransformation;

  let path = '';
  if (targetX > sourceX) {
    const _firstBezier = _getBezier({
      sourcePos: Position.Right,
      targetPos: Position.Left,
      sourceX,
      sourceY,
      targetX: sourceX + stepGapWidth,
      targetY: sourceY + horizontalDetourLineYPosition,
    });
    path = _firstBezier.toSVG();

    path += `L ${targetX - stepGapWidth} ${targetY + horizontalDetourLineYPosition}`;

    const _secondBezier = _getBezier({
      sourcePos: Position.Right,
      targetPos: Position.Left,
      sourceX: targetX - stepGapWidth,
      sourceY: targetY + horizontalDetourLineYPosition,
      targetX,
      targetY,
    });
    path += _secondBezier.toSVG();
  } else {
    const _firstBezier = _getBezier({
      sourcePos: Position.Left,
      targetPos: Position.Right,
      sourceX,
      sourceY,
      targetX: sourceX - stepGapWidth,
      targetY: sourceY + horizontalDetourLineYPosition,
    });
    path = _firstBezier.toSVG();

    path += `L ${targetX + stepGapWidth} ${targetY + horizontalDetourLineYPosition}`;

    const _secondBezier = _getBezier({
      sourcePos: Position.Left,
      targetPos: Position.Right,
      sourceX: targetX + stepGapWidth,
      sourceY: targetY + horizontalDetourLineYPosition,
      targetX,
      targetY,
    });
    path += _secondBezier.toSVG();
  }
  return path;
};

const _getVerticalDetourPath = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  connectionIdx,
  arrowDirection,
}: {
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  connectionIdx: number;
  arrowDirection: ArrowDirection;
}) => {
  const stepGapHeight = SWEEP_GRID_SIZE.height - NODE_HEIGHT;
  const verticalDetourStartingPosition = NODE_WIDTH / 2 + 60;
  const verticalDetourIndexGap = 20;
  const arrowDirectionTransformation =
    ArrowDirection.TopBottom === arrowDirection ? 0 : -EDGE_EXIT_ENTRY_GAP;

  const verticalDetourLineXPosition =
    verticalDetourStartingPosition +
    verticalDetourIndexGap * connectionIdx +
    arrowDirectionTransformation;

  let path = '';
  if (targetY > sourceY) {
    const _firstBezier = _getBezier({
      sourcePos: Position.Bottom,
      targetPos: Position.Top,
      sourceX,
      sourceY,
      targetX: sourceX - verticalDetourLineXPosition,
      targetY: sourceY + stepGapHeight,
    });
    path = _firstBezier.toSVG();
    path += `L ${targetX - verticalDetourLineXPosition} ${targetY - stepGapHeight}`;
    const _secondBezier = _getBezier({
      sourcePos: Position.Bottom,
      targetPos: Position.Top,
      sourceX: targetX - verticalDetourLineXPosition,
      sourceY: targetY - stepGapHeight,
      targetX,
      targetY,
    });
    path += _secondBezier.toSVG();
  } else {
    const _firstBezier = _getBezier({
      sourcePos: Position.Top,
      targetPos: Position.Bottom,
      sourceX,
      sourceY,
      targetX: targetX - verticalDetourLineXPosition,
      targetY: sourceY - verticalDetourStartingPosition,
    });
    path = _firstBezier.toSVG();

    path += `L ${targetX - verticalDetourLineXPosition} ${targetY + stepGapHeight}`;

    const _secondBezier = _getBezier({
      sourcePos: Position.Top,
      targetPos: Position.Bottom,
      sourceX: targetX - verticalDetourLineXPosition,
      sourceY: targetY + stepGapHeight,
      targetX,
      targetY,
    });
    path += _secondBezier.toSVG();
  }
  return path;
};

const getArrowCirclePath = (sourceX: number, sourceY: number, sourcePos: Position) => {
  const gap = 7;
  let _sx = sourceX;
  const r = 4.7;
  let _sy = sourceY;

  if (sourcePos === Position.Left) _sx -= gap;
  if (sourcePos === Position.Right) _sx += gap;
  if (sourcePos === Position.Top) _sy -= gap;
  if (sourcePos === Position.Bottom) _sy += gap;

  const arrowCirclePath = `M ${_sx - r} , ${_sy}  a ${r},${r} 0 1,0 ${
    r * 2
  },0  a ${r},${r} 0 1,0 -${r * 2},0`;

  return { arrowCirclePath, _sx: _sx, _sy: _sy };
};

const getArrowPath = (targetX: number, targetY: number, arrowDirection: ArrowDirection) => {
  let _tx = targetX;
  let _ty = targetY;
  switch (arrowDirection) {
    case ArrowDirection.LeftRight:
      _tx -= 8;
      break;
    case ArrowDirection.TopBottom:
      _ty -= 8;
      break;
    case ArrowDirection.BottomTop:
      _ty += 8;
      break;
    case ArrowDirection.RightLeft:
      _tx += 8;
      break;
  }
  const arrowPath = calculateArrowPath(_tx, _ty, arrowDirection);
  return { arrowPath, _tx, _ty };
};

const _getConnectionPathAndArrow = ({
  sourcePos,
  targetPos,
  sourceX,
  sourceY,
  targetX,
  targetY,
  connectionIdx = 0,
  arrowDirection = ArrowDirection.LeftRight,
  connectionType,
}: {
  sourcePos: Position;
  targetPos: Position;
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  connectionIdx?: number;
  arrowDirection?: ArrowDirection;
  connectionType: CanvasEdgeConnectionType;
}) => {
  const { arrowPath, _tx, _ty } = getArrowPath(targetX, targetY, arrowDirection);

  const { arrowCirclePath, _sx, _sy } = getArrowCirclePath(sourceX, sourceY, sourcePos);

  let connectionPath: string;

  switch (connectionType) {
    case CanvasEdgeConnectionType.VerticalDetour:
      connectionPath = _getVerticalDetourPath({
        sourceX: _sx,
        sourceY: _sy,
        targetX: _tx,
        targetY: _ty,
        connectionIdx,
        arrowDirection,
      });
      break;

    case CanvasEdgeConnectionType.HorizontalDetour:
      connectionPath = _getHorizontalDetourPath({
        sourceX: _sx,
        sourceY: _sy,
        targetX: _tx,
        targetY: _ty,
        connectionIdx,
        arrowDirection,
      });
      break;

    default:
      connectionPath = _getBezier({
        sourcePos,
        targetPos,
        sourceX: _sx,
        sourceY: _sy,
        targetX: _tx,
        targetY: _ty,
      }).toSVG();
      break;
  }

  return {
    connectionPath,
    arrowPath,
    arrowCirclePath,
    newTargetX: _tx,
    newTargetY: _ty,
    newSourceX: _sx,
    newSourceY: _sy,
  };
};

export const getConnectionPathAndArrow = _memoize(_getConnectionPathAndArrow);
