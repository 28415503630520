import { createContext, MutableRefObject, useContext, useRef } from 'react';
import { NodePositionTransformation } from '../helpers/nodePositionManagementHelper';

interface TransformedOriginalValuesToRestore {
  nodePositions: NodePositionTransformation[];
  edgeSourceAndTarget: { id: string; source: string; target: string }[];
  edgesToRemove: string[];
  nodesToRemove: string[];
}

interface TemporaryTransformationsCtxState {
  transformedOriginalValuesToRestore: MutableRefObject<TransformedOriginalValuesToRestore | null>;
}

const TemporaryTransformationsCtx = createContext<TemporaryTransformationsCtxState>({
  transformedOriginalValuesToRestore: { current: null },
});

export const TemporaryTransformationsCtxProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <TemporaryTransformationsCtx.Provider
      value={{ transformedOriginalValuesToRestore: useRef(null) }}
    >
      {children}
    </TemporaryTransformationsCtx.Provider>
  );
};

export const useTemporaryTransformationsCtx = () => {
  const { transformedOriginalValuesToRestore } = useContext(TemporaryTransformationsCtx);
  return transformedOriginalValuesToRestore;
};
