import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { AuditStatus, AuditTableFilters, FilterOptions, getSlackActionText } from './AuditTable';
import { selectCrmOrgs } from '../../pages/environments/environmentsReducer';
import { getAdvanceFilterItemStructure } from '../../pages/salesforce-logs/utils';
import { OrgDot } from '../OrgDot';
import { AdvancedFilter } from '../advanced-filter/AdvancedFilter';
import { DatePickerWrapper } from '../datepicker/DatePickerWrapper';
import { CustomDatePicker, DateBy, DateKeys } from '../datepicker/types';
import { useCallback } from 'react';
import { selectCustomIntegrations } from '../../../reducers/integrationsReducer';
export const DEFAULT_DATEPICKER_STATE = DateKeys.Last28Days;

interface FiltersPanelProps {
  setFilter: (newFilter: Partial<AuditTableFilters>) => void;
  disableFilters: boolean;
  sortAndFilter: AuditTableFilters;
  filterOptions: FilterOptions;
}

export const FiltersPanel = ({
  setFilter,
  disableFilters,
  filterOptions,
  sortAndFilter,
}: FiltersPanelProps) => {
  const _crmOrgs = useSelector(selectCrmOrgs);
  const customIntegrations = useSelector(selectCustomIntegrations);

  const crmOrgs = getAdvanceFilterItemStructure({
    items: _crmOrgs ?? [],
    labelKey: 'name',
    valueKey: 'id',
    decorationFunc: (item) => item.isMain,
  });

  const getIntegrationName = useCallback(
    (integrationId: string) => {
      const integration = customIntegrations.find((d) => d.id === integrationId);
      return integration?.name ?? '';
    },
    [customIntegrations],
  );
  const itemsWithDecoration = crmOrgs?.map((org) => {
    const withDecoration = org.labelDecoration;
    return { ...org, labelDecoration: withDecoration ? <OrgDot /> : null };
  });

  const actionItemsText = filterOptions?.actionType?.map((item) => {
    return {
      label: getSlackActionText(item),
      value: item,
    };
  });

  const statusItemsText = Object.values(AuditStatus).map((item) => {
    return {
      label: item.charAt(0) + item.slice(1).toLowerCase(),
      value: item,
    };
  });

  const integrationItemsText = filterOptions?.customIntegrationId?.map((item) => {
    return {
      label: getIntegrationName(item),
      value: item,
    };
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
        alignItems: 'center',
      }}
    >
      <DatePickerWrapper
        onSelectDate={(value: CustomDatePicker) => {
          setFilter({
            startTime: value[DateBy.From],
            endTime: value[DateBy.To],
          });
        }}
        disabled={disableFilters}
        defaultValue={DEFAULT_DATEPICKER_STATE}
      />
      {filterOptions.showEnvironments && !!(crmOrgs?.length || crmOrgs?.length) && (
        <AdvancedFilter
          texts={{ title: 'Environments' }}
          selectedItems={sortAndFilter.crmOrgId ?? []}
          items={itemsWithDecoration}
          onSelectedItemsChange={(items: string[]) =>
            setFilter({ crmOrgId: items.length ? items : crmOrgs.map((org) => org.value) })
          }
          disabled={disableFilters}
        />
      )}

      {!!filterOptions.showIntegrations && !!filterOptions?.customIntegrationId?.length && (
        <AdvancedFilter
          texts={{ title: 'Integration' }}
          selectedItems={sortAndFilter.customIntegrationId ?? []}
          items={integrationItemsText ?? []}
          onSelectedItemsChange={(items: string[]) =>
            setFilter({
              customIntegrationId: items.length
                ? items
                : filterOptions?.customIntegrationId?.map((item: any) => item.value),
            })
          }
          disabled={disableFilters}
        />
      )}

      {!!filterOptions.showActions && !!filterOptions?.actionType?.length && (
        <AdvancedFilter
          texts={{ title: 'Action' }}
          selectedItems={sortAndFilter.actionType ?? []}
          items={actionItemsText ?? []}
          onSelectedItemsChange={(items: string[]) =>
            setFilter({
              actionType: items.length
                ? items
                : filterOptions?.actionType?.map((item: any) => item.value),
            })
          }
          disabled={disableFilters}
        />
      )}
      <AdvancedFilter
        texts={{ title: 'Status' }}
        selectedItems={sortAndFilter.status ?? []}
        items={statusItemsText}
        onSelectedItemsChange={(items: string[]) =>
          setFilter({
            status: items.length ? items : filterOptions?.status?.map((item: any) => item.value),
          })
        }
        disabled={disableFilters}
      />
    </Box>
  );
};
