import { createContext, useRef } from 'react';

interface HighlightEntityContextType {
  timeoutRef: React.MutableRefObject<{
    timeout: NodeJS.Timeout;
    entityId: string;
  } | null>;
}

export const HighlightEntityContext = createContext<HighlightEntityContextType>({
  timeoutRef: { current: null },
});

export const HighlightEntityProvider = ({ children }: { children: React.ReactNode }) => {
  const timeoutRef = useRef<{ timeout: NodeJS.Timeout; entityId: string } | null>(null);
  return (
    <HighlightEntityContext.Provider
      value={{
        timeoutRef,
      }}
    >
      {children}
    </HighlightEntityContext.Provider>
  );
};
