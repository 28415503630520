import { Box, ButtonBase, Divider, TooltipProps } from '@mui/material';
import { colors, IconButton, Tooltip } from '@sweep-io/sweep-design';
import { useDispatch, useSelector } from 'react-redux';
import { SparklingWandColorIcon } from '../../../../../icons/generalIcons';
import * as Icons from '@sweep-io/sweep-design/dist/icons';
import React, { useCallback } from 'react';
import { selectIsFieldsDialogOpen } from '../../../../../reducers/funnel-flow-page/fieldsReducer';
import { usePanels } from '../../../../panels/usePanels';
import { selectAutomations } from '../../../../../reducers/global/automationReducers';
import { selectAlerts } from '../../../../../reducers/global/alertsReducers';
import { documentationPanelType } from '../../../../documentation/types';
import { automationsPanelType, playbookAlertsPanelType } from '../../../../Automations/panelTypes';
import { automationsInFunnelMapScope } from '../../../../Automations/automationsInFunnelDataScope';
import { CrmOrgUsageNotification } from '../../../funnel-map-flow/left-menu/CrmOrgUsageNotification';
import { useFunnelMapPage } from '../../../funnel-map-flow/useFunnelMapPage';
import { SettingsButton } from '../../../funnel-map-flow/dialogs/funnel-settings-dialog/CanvasUnitedSettingsButton';
import { useConfigurationCanvas } from '../../../configuration-canvas/public/useConfigurationCanvas';
import { useConfigurationCanvasContext } from '../../../configuration-canvas/ConfigurationCanvasContext';
import { enableLayerFor, DEFAULT_LAYERS_ID } from '../../../../../reducers/canvasLayersReducer';
import { VisibilityLayers } from '../../../../../types/enums/VisibilityLayers';
import { useHandleCommentsAndAiFirst } from './useHandleCommentsAndAiFirst';
import { FunnelMapNameEdit } from '../../../funnel-map-flow/left-menu/FunnelMapNameEdit';
import useSendBiEvent from '../../../../../hooks/useSendBiEvent';
import { UI_EVENTS } from '../../../../../services/events';
import { AddFunnelMenu } from './AddFunnelMenu';
import useAiAgentCenter from '../../../../aiAgentCenter/useAiAgentCenter';
import useUnitedCanvasMediaQueries from '../useUnitedCanvasMediaQueries';

interface CanvasUnitedLeftMenuProps {
  funnelMapEmpty?: boolean;
  crmOrgId: string;
}

const TooltipWithDisabledTitle = React.forwardRef(
  (props: TooltipProps & { disabledTitle: string; showDisabledTitle?: boolean }, ref) => {
    const { title, showDisabledTitle, disabledTitle, ...otherProps } = props;
    const tooltipTitle = showDisabledTitle ? disabledTitle : title;
    return <Tooltip title={tooltipTitle} {...otherProps} ref={ref} />;
  },
);

export const CanvasUnitedLeftMenu = ({ funnelMapEmpty, crmOrgId }: CanvasUnitedLeftMenuProps) => {
  const dispatch = useDispatch();
  const { canvasFunnelMap } = useConfigurationCanvas();
  const { setCommentsOn, commentsOn } = useConfigurationCanvasContext();
  const { isPanelOpen } = usePanels();
  const sendBiEvent = useSendBiEvent();
  const { isCreateAgentActive } = useAiAgentCenter();
  const isAlertsPressed = !commentsOn && isPanelOpen(playbookAlertsPanelType);
  const isAutomationsPressed = !commentsOn && isPanelOpen(automationsPanelType);

  const isFieldsDialogOpen = useSelector(selectIsFieldsDialogOpen);
  const isDocumentationPressed = !commentsOn && isPanelOpen(documentationPanelType);
  const automationsList = useSelector(selectAutomations);
  const alertsList = useSelector(selectAlerts);
  const isFieldsPressed = isFieldsDialogOpen && !commentsOn;

  const toggleComments = useCallback(() => {
    const _commentsOn = !commentsOn;
    setCommentsOn(_commentsOn);
    if (_commentsOn) {
      sendBiEvent({ name: UI_EVENTS.canvasCommentMode });
      dispatch(enableLayerFor({ layersId: DEFAULT_LAYERS_ID, layer: VisibilityLayers.COMMENTS }));
    }
  }, [commentsOn, dispatch, sendBiEvent, setCommentsOn]);

  const { onBackCallback, onAutomationsClick, onAlertsClick, onFieldsClick, onDocumentationClick } =
    useFunnelMapPage();

  const handleCommentsAndAiFirst = useHandleCommentsAndAiFirst();

  const { isMediumScreen } = useUnitedCanvasMediaQueries();

  const gap = '16px';

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap,
          padding: `0 ${gap}`,
          backgroundColor: colors.white,
          borderRadius: '6px',
          position: 'relative',
        }}
        data-testid="funnel-flow-left-menu"
      >
        <Tooltip title="Go back">
          <ButtonBase
            onClick={() => handleCommentsAndAiFirst(() => onBackCallback && onBackCallback())}
            sx={{
              padding: '14px',
              margin: '-14px',
              ':hover svg .hover-color': {
                fill: colors.blue[500],
              },
            }}
          >
            <SparklingWandColorIcon />
          </ButtonBase>
        </Tooltip>

        <Divider orientation="vertical" variant="middle" flexItem />
        <FunnelMapNameEdit />
        {isMediumScreen && (
          <SettingsButton
            funnelMap={canvasFunnelMap}
            crmOrgId={crmOrgId}
            disabled={isCreateAgentActive}
          />
        )}
        <TooltipWithDisabledTitle
          title="Documentation"
          disabledTitle={'Please add a funnel to enable Documentation'}
          showDisabledTitle={funnelMapEmpty}
        >
          <span>
            <IconButton
              variant="flat"
              onClick={() => handleCommentsAndAiFirst(onDocumentationClick)}
              pressed={isDocumentationPressed}
              disabled={funnelMapEmpty || isCreateAgentActive}
            >
              <Icons.Binoculars variant="large" />
            </IconButton>
          </span>
        </TooltipWithDisabledTitle>
        <TooltipWithDisabledTitle
          title="Automations"
          disabledTitle={'Please add a funnel to enable Automations'}
          showDisabledTitle={funnelMapEmpty}
        >
          <span>
            <IconButton
              variant="flat"
              badgeLabel={
                automationsInFunnelMapScope({
                  automations: automationsList ?? [],
                  funnelsData: canvasFunnelMap?.funnelsData ?? {},
                })?.length
              }
              onClick={() => handleCommentsAndAiFirst(onAutomationsClick)}
              pressed={isAutomationsPressed}
              disabled={funnelMapEmpty || isCreateAgentActive}
            >
              <Icons.Automations variant="large" />
            </IconButton>
          </span>
        </TooltipWithDisabledTitle>
        <TooltipWithDisabledTitle
          title="Alerts"
          disabledTitle={'Please add a funnel to enable Alerts'}
          showDisabledTitle={funnelMapEmpty}
        >
          <span>
            <IconButton
              variant="flat"
              badgeLabel={
                automationsInFunnelMapScope({
                  automations: alertsList ?? [],
                  funnelsData: canvasFunnelMap?.funnelsData ?? {},
                })?.length
              }
              onClick={() => handleCommentsAndAiFirst(onAlertsClick)}
              pressed={isAlertsPressed}
              disabled={funnelMapEmpty || isCreateAgentActive}
            >
              <Icons.PlaybookAlert variant="large" />
            </IconButton>
          </span>
        </TooltipWithDisabledTitle>

        <TooltipWithDisabledTitle
          title="Fields Management"
          disabledTitle={'Please add a funnel to enable Fields Management'}
          showDisabledTitle={funnelMapEmpty}
        >
          <span>
            <IconButton
              variant="flat"
              onClick={() => handleCommentsAndAiFirst(onFieldsClick)}
              pressed={!!isFieldsPressed}
              disabled={funnelMapEmpty || isCreateAgentActive}
            >
              <Icons.Fields variant="large" />
            </IconButton>
          </span>
        </TooltipWithDisabledTitle>

        {isMediumScreen && (
          <Tooltip title="Comments">
            <span>
              <IconButton
                variant="flat"
                onClick={toggleComments}
                badgeVariant="dot"
                pressed={commentsOn}
                disabled={isCreateAgentActive}
              >
                <Icons.UnreadComment variant="large" />
              </IconButton>
            </span>
          </Tooltip>
        )}
        <AddFunnelMenu disabled={isCreateAgentActive} />
      </Box>

      <CrmOrgUsageNotification crmOrgId={crmOrgId} />
    </>
  );
};
