// FileName: gridPositioningUtils.ts - Utility functions for converting between grid and XY coordinate systems

import { XYPosition } from '@xyflow/react';
import { SWEEP_GRID_SIZE } from '../const';

export interface CanvasGridPosition {
  row: number;
  column: number;
}

export const convertXYPositionToGridPosition = (position: XYPosition): CanvasGridPosition => ({
  column: position.x / SWEEP_GRID_SIZE.width,
  row: -(position.y / SWEEP_GRID_SIZE.height),
});

export const snapPositionToGrid = (position: XYPosition): XYPosition => ({
  x: Math.round(position.x / SWEEP_GRID_SIZE.width) * SWEEP_GRID_SIZE.width,
  y: Math.round(position.y / SWEEP_GRID_SIZE.height) * SWEEP_GRID_SIZE.height,
});

export const convertXYPositionToGridIndex = (
  position: XYPosition = { x: 0, y: 0 },
): CanvasGridPosition => ({
  column: Math.round(position.x / SWEEP_GRID_SIZE.width),
  row: Math.round(-(position.y / SWEEP_GRID_SIZE.height)),
});

export const convertGridPositionToXYPosition = (
  position: CanvasGridPosition = { column: 0, row: 0 },
): XYPosition => ({
  x: position.column * SWEEP_GRID_SIZE.width,
  y: -(position.row * SWEEP_GRID_SIZE.height),
});
