import { useEffect, useRef } from 'react';

function usePrevious<T = string>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    if (ref) {
      ref.current = value;
    }
  });

  return ref.current;
}

export default usePrevious;
