import { Stack, SxProps, TableCell, TableRowProps, Theme } from '@mui/material';
import { ACTION_BUTTONS_KEY, linearGradient } from './utils';
import { dataTableVariants } from './dataTableVariants';
import {
  DataTableBaseColumn,
  DataTableDraggableColumn,
  DataTableRow,
  DataTableSortableColumn,
  DataTableVariant,
} from './TableTypes';
import { colors, IconButton } from '@sweep-io/sweep-design';
import { ChevronRight } from '@sweep-io/sweep-design/dist/icons';

interface ActionCellProps<TRow extends DataTableRow> {
  row: TRow;
  columns: DataTableSortableColumn<TRow>[] | DataTableBaseColumn[] | DataTableDraggableColumn[];
  sxRowFunction?: (isDragging: boolean) => SxProps<Theme>;
  tableRowProps?: TableRowProps;
  onClick?: (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    columnData: DataTableSortableColumn<TRow> | DataTableBaseColumn | DataTableDraggableColumn,
  ) => void;
  variant: DataTableVariant;
  hideChevronButton?: boolean;
  keepActionButtonsVisible?: boolean;
}
export const ActionCell = <TRow extends DataTableRow = any>({
  row,
  onClick,
  variant,
  hideChevronButton,
  keepActionButtonsVisible,
}: ActionCellProps<TRow>) => {
  const actionButtons = row[ACTION_BUTTONS_KEY];
  return (
    <TableCell
      key={`actions-${row.id}`}
      className="action-buttons"
      sx={{
        minWidth: '32px',
        position: 'sticky',
        p: dataTableVariants[variant].cellPadding,
        pr: variant === DataTableVariant.medium ? '24px' : '16px',
        pl: 1.5,
        right: 0,

        '&::before': {
          content: '""',
          position: 'absolute',
          inset: 0,
          background: linearGradient(colors.white),
          visibility: onClick ? 'visible' : actionButtons ? 'hidden' : 'hidden',
        },
      }}
      onClick={(e) => onClick && onClick(e, { field: ACTION_BUTTONS_KEY, headerName: '' })}
    >
      <Stack direction="row" gap={1} alignItems="center" justifyContent="flex-end">
        {actionButtons && (
          <Stack
            className="custom-action-button"
            sx={{
              '& button': { whiteSpace: 'nowrap' },
              visibility: keepActionButtonsVisible ? 'visible' : 'hidden',
            }}
          >
            {actionButtons}
          </Stack>
        )}
        {!!onClick && !hideChevronButton && (
          <Stack
            sx={{
              '& button': {
                border: 'none',

                '&:hover': {
                  border: 'none',
                  background: colors.grey[120],
                },
              },
              '& svg path': {
                stroke: colors.blue[500],
              },
            }}
          >
            <IconButton variant="outlined" size="tiny">
              <ChevronRight />
            </IconButton>
          </Stack>
        )}
      </Stack>
    </TableCell>
  );
};
