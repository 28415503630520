import { useLocation } from 'react-router';
import { useCrmOrgs } from './useCrmOrgs';
import { useRunOnce } from '../../common/useRunOnce';
import {
  CrmOrgConnectingError,
  getCrmOrgTransientData,
  setCrmOrgTransientResult,
} from './connectCrmSessionHelper';
import LoadingSweep from '../../common/LoadingSweep';
import { SweepError } from './env-connection-error-page/envConnectionErrorTypes';
import axios from 'axios';
import { telemetry } from '../../../telemetry';

const wait = (ms: number = 2000) => new Promise((resolve) => setTimeout(resolve, ms));

const SalesforceOAuth2Redirect = () => {
  const { search } = useLocation();
  const { setSalesforceOauthCode } = useCrmOrgs();

  /**
   * NOTE: need to make sure all the known scenarios are calling "setCrmOrgTransientResult"
   * So if there's no data, it means the user MANUALLY closed the browser's tab
   **/

  useRunOnce(async () => {
    const urlSearchParams = new URLSearchParams(search);
    const code = urlSearchParams.get('code');
    const error = urlSearchParams.get('error');
    const errorDescription = urlSearchParams.get('error_description');
    const crmOrgIdToken = getCrmOrgTransientData();
    if (!crmOrgIdToken) {
      return <LoadingSweep />;
    }

    if (error === 'access_denied' && errorDescription === 'end-user denied authorization') {
      setCrmOrgTransientResult(
        {
          success: false,
          error: CrmOrgConnectingError.USER_DENIED_AUTHORIZATION,
        },
        crmOrgIdToken.crmOrgId,
      );
      await wait(); // wait for the error to be set in local storage
      window.close();
      return;
    }

    if (crmOrgIdToken && code) {
      try {
        await setSalesforceOauthCode({
          authCode: code,
          crmOrgId: crmOrgIdToken.crmOrgId,
          isSandbox: crmOrgIdToken.isSandbox,
          isOnboardingFastFetch: crmOrgIdToken.isOnboardingFastFetch,
        });
        setCrmOrgTransientResult(
          {
            success: true,
          },
          crmOrgIdToken.crmOrgId,
        );
      } catch (error) {
        if (
          axios.isAxiosError(error) &&
          error.response?.status === 401 &&
          error.response.data.sweepError === SweepError.CrmOrgInvalidGrant
        ) {
          setCrmOrgTransientResult(
            {
              success: false,
              error: CrmOrgConnectingError.NOT_ENOUGH_PERMISSIONS,
            },
            crmOrgIdToken.crmOrgId,
          );
        } else {
          setCrmOrgTransientResult(
            {
              success: false,
              error: CrmOrgConnectingError.ERROR_CONNECTING,
            },
            crmOrgIdToken.crmOrgId,
          );
          telemetry.captureError(error);
        }
      }
    }
    await wait(); // wait for the error to be set in local storage
    window.close();
  });

  return <LoadingSweep />;
};

export { SalesforceOAuth2Redirect };
