import { Box, Collapse, Grid } from '@mui/material';
import { useState } from 'react';
import { ChevronDown, ChevronUp, Target } from '@sweep-io/sweep-design/dist/icons';
import { Radio, Tag, Typography, colors } from '@sweep-io/sweep-design';
import { StyledRadioGroup } from '../../common/StyledComponents';
import { CustomHeader } from '../../common/rule-builder/CustomHeader';
import { SweepBinaryRuleBuilder } from '../../common/sweep-condition-rule-builder/SweepConditionRuleBuilder';
import { useRefForMultipleRuleBuilders } from '../../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';
import { StyledFormControlLabel, greyLayout, settingsPanelLayout } from './helpers';
import { SweepFieldOperator } from '../../SweepFieldsOperatorSelector/sweepFieldsTypesAndOperators';
import { SweepFieldTypes } from '../../../types/enums/SweepFieldTypes';
import FieldLabel from '../../common/FieldLabel';
import { SFDCObjectType } from '../../../types/enums/SFDCObjectType';

interface MatchingLogicFilterPanelProps {
  matchingLogic?: SweepCondition;
  readonly?: boolean;
  crmOrgId: string;
  objectType: string;
  onChange: (filter?: SweepCondition) => any;
  referenceObjectType?: string;
  showOnlyRuleBuilder?: boolean;
  title?: string;
  subTitle?: string;
  infoTooltipTitle?: string;
  ruleBuilderTitle?: string;
  ruleBuilderHeaderLabel?: string;
}

enum SweepFilterType {
  HAS = 'HAS',
  EMPTY = 'EMPTY',
}

const MatchingLogicFilterPanel = ({
  readonly,
  crmOrgId,
  objectType,
  onChange,
  referenceObjectType,
  matchingLogic,
  showOnlyRuleBuilder,
  title,
  subTitle,
  infoTooltipTitle,
  ruleBuilderTitle,
  ruleBuilderHeaderLabel,
}: MatchingLogicFilterPanelProps) => {
  const [isPanelExpanded, setIsPanelExpanded] = useState(!!matchingLogic);
  const [selectFilterType, setSelectFilterType] = useState<SweepFilterType>(
    matchingLogic ? SweepFilterType.HAS : SweepFilterType.EMPTY,
  );
  const ruleBuilderRefInclude = useRefForMultipleRuleBuilders();

  const filterOperatorBy = (operatorList: OperatorWithIndex[], fieldType?: SweepFieldTypes) => {
    const newOperators = [{ label: 'Exact', operator: SweepFieldOperator.EXACT, groupIndex: 0 }];

    if (fieldType && fieldType === SweepFieldTypes.Url) {
      newOperators.push({ label: 'Fuzzy', operator: SweepFieldOperator.FUZZY, groupIndex: 0 });
    }

    if (
      fieldType &&
      (fieldType === SweepFieldTypes.Date || fieldType === SweepFieldTypes.DateTime)
    ) {
      newOperators.push({
        label: 'Within X days of',
        operator: SweepFieldOperator.DATES_APART,
        groupIndex: 0,
      });
    }
    if (fieldType && fieldType === SweepFieldTypes.Geolocation) {
      newOperators.push({
        label: 'Within X miles of',
        operator: SweepFieldOperator.DISTANCE,
        groupIndex: 0,
      });
    }
    return [...newOperators];
  };

  const headerRowComponent = (
    <Box sx={{ display: 'flex', marginLeft: '36px', mb: '16px', pr: '36px' }}>
      <Grid container flexWrap="nowrap">
        <Grid item xs={4}>
          <Tag label={ruleBuilderHeaderLabel ?? 'Potential duplicate'} color={colors.grey[120]} />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={5}>
          <Tag label={`Triggering ${objectType?.toLowerCase()}`} color={colors.grey[120]} />
        </Grid>
      </Grid>
    </Box>
  );

  const preFillOperators = (sweepCondition: SweepCondition) => {
    const conditionsWithOperator = sweepCondition?.criteria?.map((el) => {
      if (
        el?.fieldType &&
        !el?.operator &&
        el?.fieldType !== SweepFieldTypes.Url &&
        el?.fieldType !== SweepFieldTypes.DateTime &&
        el?.fieldType !== SweepFieldTypes.Date &&
        el?.fieldType !== SweepFieldTypes.Geolocation
      ) {
        el.operator = SweepFieldOperator.EXACT;
        return el;
      } else return el;
    });
    return { criteriaLogic: sweepCondition.criteriaLogic, criteria: conditionsWithOperator };
  };

  const ruleBuilderSection = (
    <Box sx={{ ...greyLayout }}>
      <>
        {!showOnlyRuleBuilder && (
          <CustomHeader
            customHeader={ruleBuilderTitle ?? 'Set your custom logic to find duplicates'}
          />
        )}
        <SweepBinaryRuleBuilder
          showCompoundGeolocation={
            objectType === SFDCObjectType.Opportunity &&
            referenceObjectType === SFDCObjectType.Opportunity
          }
          filterOperatorBy={filterOperatorBy}
          headerRowComponent={headerRowComponent}
          referenceObjectType={objectType}
          readOnly={readonly}
          crmOrgId={crmOrgId}
          objectType={referenceObjectType ?? ''}
          sweepCondition={matchingLogic}
          ref={ruleBuilderRefInclude}
          nestedSelectorFilterBy={(field) => field.fieldType !== SweepFieldTypes.LongTextArea}
          onChange={(sweepCondition) => {
            const tempCondition = preFillOperators(sweepCondition);
            onChange(tempCondition);
          }}
          disableResolvePolymorphic
          onOperatorValueChange={(operatorValue: OperatorOffset, lineNumber: number) => {
            let tempLine = matchingLogic?.criteria?.[lineNumber - 1];
            if (tempLine) {
              const _fieldType = tempLine.fieldType;
              const _operator =
                _fieldType === SweepFieldTypes.Geolocation
                  ? SweepFieldOperator.DISTANCE
                  : SweepFieldOperator.DATES_APART;
              tempLine = { ...tempLine, operator: _operator, operatorValue };
            }
            const objToSend = [...(matchingLogic?.criteria ?? [])];
            objToSend[lineNumber - 1] = tempLine as SweepCriterion;
            onChange({ criteriaLogic: matchingLogic?.criteriaLogic ?? '', criteria: objToSend });
          }}
        />
      </>
    </Box>
  );

  return (
    <Box sx={{ ...settingsPanelLayout }}>
      <Collapse in={isPanelExpanded} collapsedSize={24}>
        <Box className="panelHeader" onClick={() => setIsPanelExpanded(!isPanelExpanded)}>
          <Box sx={{ display: 'flex', gap: '16px' }}>
            <Target variant="large" />
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '2px' }}>
              <Typography variant="body-bold">{title ?? 'Duplicates logic'}</Typography>
              <Typography variant="body">
                {subTitle ?? 'Set the logic how to identify a potential duplicate'}
              </Typography>
            </Box>
          </Box>
          <Box>
            {isPanelExpanded ? <ChevronUp variant="large" /> : <ChevronDown variant="large" />}
          </Box>
        </Box>
        <Box sx={{ marginTop: '16px', marginLeft: '44px' }}>
          {showOnlyRuleBuilder ? (
            ruleBuilderSection
          ) : (
            <StyledRadioGroup
              sx={{ marginTop: '12px' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = (event.target as HTMLInputElement).value as SweepFilterType;
                setSelectFilterType(value);
                if (value === SweepFilterType.EMPTY) {
                  onChange(undefined);
                }
              }}
              value={selectFilterType}
            >
              <StyledFormControlLabel
                disabled={readonly}
                value={SweepFilterType.EMPTY}
                control={<Radio />}
                label={
                  <FieldLabel
                    label={'Default Sweep logic'}
                    showTooltip
                    infoTooltipTitle={
                      infoTooltipTitle ??
                      'We leverage a combination of exact and fuzzy logic on standard fields like email, name, company, phone, and more to ensure an accurate detection of duplicates.'
                    }
                  />
                }
              />
              <StyledFormControlLabel
                disabled={readonly}
                value={SweepFilterType.HAS}
                control={<Radio />}
                label={<Typography variant="body">Custom logic</Typography>}
              />
              {selectFilterType === SweepFilterType.HAS && ruleBuilderSection}
            </StyledRadioGroup>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

export default MatchingLogicFilterPanel;
