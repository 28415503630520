import { Handle, Position } from '@xyflow/react';
import { memo } from 'react';

export enum SweepNodeHandlesEnum {
  SourceTop = 'st',
  SourceRight = 'sr',
  SourceBottom = 'sb',
  SourceLeft = 'sl',
  TargetTop = 'tt',
  TargetRight = 'tr',
  TargetBottom = 'tb',
  TargetLeft = 'tl',
}
const SweepNodeHandles = ({ children }: { children: any }) => {
  return (
    <div data-testid="sweep-node">
      <Handle
        type="target"
        position={Position.Right}
        isConnectable={false}
        id={SweepNodeHandlesEnum.TargetRight}
      />
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={false}
        id={SweepNodeHandlesEnum.SourceRight}
      />
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={false}
        id={SweepNodeHandlesEnum.TargetTop}
      />
      <Handle
        type="source"
        position={Position.Top}
        isConnectable={false}
        id={SweepNodeHandlesEnum.SourceTop}
      />
      {children}
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={false}
        id={SweepNodeHandlesEnum.TargetLeft}
      />
      <Handle
        type="source"
        position={Position.Left}
        isConnectable={false}
        id={SweepNodeHandlesEnum.SourceLeft}
      />
      <Handle
        type="target"
        position={Position.Bottom}
        isConnectable={false}
        id={SweepNodeHandlesEnum.TargetBottom}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={false}
        id={SweepNodeHandlesEnum.SourceBottom}
      />
    </div>
  );
};

const _memoSweepNode = memo(SweepNodeHandles);

export { _memoSweepNode as SweepNodeHandles };
