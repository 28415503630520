import { useReactFlow, XYPosition } from '@xyflow/react';
import { useCallback } from 'react';
import { convertXYPositionToGridIndex, CanvasGridPosition } from './helpers/gridPositioningUtils';
import { CanvasElementType } from './canvas-types';

export enum DropInvalidReason {
  OVERLAP = 'OVERLAP',
}

export const useCalculateDragAndDrop = () => {
  const { getNodes } = useReactFlow();
  const getDropInvalidReason = useCallback(
    (newPotentialNodPos: XYPosition, id: string): DropInvalidReason | null => {
      const nodes = getNodes().filter((node) => node.type !== CanvasElementType.DROP_ZONE_NODE);
      const node = nodes.find((node) => node.id === id);
      if (node) {
        const parentId = node?.parentId;

        const newCanvasPos = convertXYPositionToGridIndex({
          x: newPotentialNodPos.x,
          y: newPotentialNodPos.y,
        });

        const isOverlappingAnotherStep = Boolean(
          nodes.find((node) => {
            if (node.id === id || node.parentId !== parentId) {
              return false;
            }

            const { row, column } = convertXYPositionToGridIndex({
              x: node.position.x,
              y: node.position.y,
            });

            const nodePos: CanvasGridPosition = {
              row,
              column,
            };

            return (
              nodePos.column === newCanvasPos.column &&
              nodePos.row === newCanvasPos.row &&
              node.id !== id
            );
          }),
        );

        if (isOverlappingAnotherStep) {
          return DropInvalidReason.OVERLAP;
        }
      }
      return null;
    },
    [getNodes],
  );

  return {
    getDropInvalidReason,
  };
};
