import * as React from "react";
const SvgSadRedFace = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("circle", { cx: 8, cy: 7.99829, r: 6, fill: "#F3676B", style: {
  fill: "#F3676B",
  fill: "color(display-p3 0.9529 0.4039 0.4196)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M6 6.50049V7.50049M10 6.50049V7.50049", stroke: "white", style: {
  stroke: "white",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M6 10.5005V10.5005C7.25903 9.87097 8.74097 9.87097 10 10.5005V10.5005", stroke: "white", style: {
  stroke: "white",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }));
export default SvgSadRedFace;
