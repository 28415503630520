import { VisibilityMap } from '../../types/VisibilityTypes';
import { VisibilityLayers } from '../../types/enums/VisibilityLayers';
import { PillsMap } from '../canvas-pills/canvasPillsReducer';
import { CanvasPill, CanvasPillTypes } from '../sweep-canvas/canvas-types';
import { HighlightEntities } from './funnelMapCanvasTypes';
import { FULL_NAME_SEPARATOR } from './helper';

const isPillHighlighted = ({
  highlightEntities,
  funnelOrRecordTypeId,
  stepId,
  pillType,
}: {
  funnelOrRecordTypeId?: string;
  stepId?: string;
  pillType: CanvasPillTypes;
  highlightEntities?: HighlightEntities[];
}) => {
  return (
    highlightEntities?.some(
      (highlightEntity) =>
        highlightEntity.type === 'pill' &&
        highlightEntity.funnelOrRecordTypeID === funnelOrRecordTypeId &&
        (highlightEntity.stepId === undefined || highlightEntity.stepId === stepId) &&
        highlightEntity.pillType === pillType,
    ) ?? false
  );
};

export const stepPills = ({
  stage,
  renderZeroCountPills,
  highlightEntities,
  funnelId,
  visibilityMap,
  pills: pillsMap,
}: {
  stage: SweepStage;
  renderZeroCountPills?: boolean;
  highlightEntities?: HighlightEntities[];
  funnelId: string;
  visibilityMap: VisibilityMap;
  pills?: Partial<PillsMap>;
}) => {
  const {
    alerts: alertsPills,
    assignments: assignmentsPills,
    sweepAutomations: sweepAutomationPills,
    scheduledAssignments: scheduledAssignmentsPills,
  } = pillsMap || {};

  let pills: CanvasPill[] = [];

  if (visibilityMap[VisibilityLayers.SFDC_AUTOMATIONS]) {
    const amount = pillsMap?.sdfcAutomations?.steps[stage._stageId] || 0;

    pills.push({
      type: CanvasPillTypes.sfdcAutomation,
      amount: amount,
      highlight: isPillHighlighted({
        funnelOrRecordTypeId: funnelId,
        stepId: stage._stageId,
        pillType: CanvasPillTypes.sfdcAutomation,
        highlightEntities,
      }),
    });
  }

  if (visibilityMap[VisibilityLayers.VALIDATION_RULES]) {
    const amount = pillsMap?.validationRules?.steps[stage._stageId] || 0;

    pills.push({
      type: CanvasPillTypes.validationRule,
      amount: amount,
      highlight: isPillHighlighted({
        funnelOrRecordTypeId: funnelId,
        stepId: stage._stageId,
        pillType: CanvasPillTypes.validationRule,
        highlightEntities,
      }),
    });
  }

  if (visibilityMap[VisibilityLayers.APEX]) {
    const amount = pillsMap?.apex?.steps[stage._stageId] || 0;

    pills.push({
      type: CanvasPillTypes.apexTrigger,
      amount: amount,
      highlight: isPillHighlighted({
        funnelOrRecordTypeId: funnelId,
        stepId: stage._stageId,
        pillType: CanvasPillTypes.apexTrigger,
        highlightEntities,
      }),
    });
  }

  if (visibilityMap[VisibilityLayers.SWEEP_AUTOMATIONS]) {
    const amount = sweepAutomationPills?.steps[stage._stageId] || 0;

    pills.push({
      type: CanvasPillTypes.automation,
      amount: amount,
      highlight: isPillHighlighted({
        funnelOrRecordTypeId: funnelId,
        stepId: stage._stageId,
        pillType: CanvasPillTypes.automation,
        highlightEntities,
      }),
    });
  }
  if (visibilityMap[VisibilityLayers.ASSIGNMENT_RULES]) {
    const amount = assignmentsPills?.steps[stage._stageId] || 0;

    pills.push({
      type: CanvasPillTypes.assignmentRule,
      amount: amount,
      highlight: isPillHighlighted({
        funnelOrRecordTypeId: funnelId,
        stepId: stage._stageId,
        pillType: CanvasPillTypes.assignmentRule,
        highlightEntities,
      }),
    });
  }

  if (visibilityMap[VisibilityLayers.SCHEDULED_ASSIGNMENTS]) {
    const amount = scheduledAssignmentsPills?.steps[stage._stageId] || 0;

    pills.push({
      type: CanvasPillTypes.scheduledAssignment,
      amount: amount,
      highlight: isPillHighlighted({
        funnelOrRecordTypeId: funnelId,
        stepId: stage._stageId,
        pillType: CanvasPillTypes.scheduledAssignment,
        highlightEntities,
      }),
    });
  }

  if (visibilityMap[VisibilityLayers.PLAYBOOK_ALERTS]) {
    const amount = alertsPills?.steps[stage._stageId] || 0;

    pills.push({
      type: CanvasPillTypes.playbookAlert,
      amount: amount,
      highlight: isPillHighlighted({
        funnelOrRecordTypeId: funnelId,
        stepId: stage._stageId,
        pillType: CanvasPillTypes.playbookAlert,
        highlightEntities,
      }),
    });
  }

  if (!renderZeroCountPills) {
    pills = pills.filter((pill) => pill.amount > 0);
  }

  return pills;
};

export const funnelPills = ({
  renderZeroCountPills,
  highlightEntities,
  funnel,
  visibilityMap,
  pills: pillsMap,
  objectType,
  recordTypeId,
}: {
  renderZeroCountPills?: boolean;
  highlightEntities?: HighlightEntities[];
  funnel?: Funnel;
  visibilityMap: VisibilityMap;
  pills?: Partial<PillsMap>;
  objectType: string;
  recordTypeId: string;
}) => {
  if (!funnel) {
    return [];
  }
  const {
    sweepAutomations: sweepAutomationPills,
    alerts: alertsPills,
    assignments: assignmentsPills,
    dedupe: dedupePills,
    matching: matchingPills,
    scheduledAssignments: scheduledAssignmentsPills,
  } = pillsMap || {};
  const funnelId = funnel.id;

  let pills: CanvasPill[] = [];

  if (visibilityMap[VisibilityLayers.FUNNEL_SFDC_AUTOMATIONS]) {
    pills.push({
      type: CanvasPillTypes.groupSfdcAutomation,
      amount: pillsMap?.funnelSfcAutomations?.funnels[funnelId] || 0,
      highlight: isPillHighlighted({
        funnelOrRecordTypeId: funnelId,
        pillType: CanvasPillTypes.groupSfdcAutomation,
        highlightEntities,
      }),
    });
  }

  if (visibilityMap[VisibilityLayers.FUNNEL_VALIDATION_RULES]) {
    pills.push({
      type: CanvasPillTypes.groupValidationRule,
      amount: pillsMap?.funnelValidationRules?.funnels[funnelId] || 0,
      highlight: isPillHighlighted({
        funnelOrRecordTypeId: funnelId,
        pillType: CanvasPillTypes.groupValidationRule,
        highlightEntities,
      }),
    });
  }

  if (visibilityMap[VisibilityLayers.FUNNEL_APEX]) {
    pills.push({
      type: CanvasPillTypes.groupApex,
      amount: pillsMap?.funnelApex?.funnels[funnelId] || 0,
      highlight: isPillHighlighted({
        funnelOrRecordTypeId: funnelId,
        pillType: CanvasPillTypes.groupApex,
        highlightEntities,
      }),
    });
  }

  if (visibilityMap[VisibilityLayers.FUNNEL_SWEEP_AUTOMATIONS]) {
    pills.push({
      type: CanvasPillTypes.groupAutomation,
      amount:
        (sweepAutomationPills?.recordType[recordTypeId] || 0) +
        (sweepAutomationPills?.funnels[funnelId] || 0) +
        (sweepAutomationPills?.objectType[objectType] || 0),
      highlight: isPillHighlighted({
        funnelOrRecordTypeId: funnelId,
        pillType: CanvasPillTypes.groupAutomation,
        highlightEntities,
      }),
    });
  }
  if (visibilityMap[VisibilityLayers.FUNNEL_ASSIGNMENT_RULES]) {
    pills.push({
      type: CanvasPillTypes.groupAssignmentRule,
      amount:
        (assignmentsPills?.recordType[recordTypeId] || 0) +
        (assignmentsPills?.funnels[funnelId] || 0) +
        (assignmentsPills?.objectType[objectType] || 0),
      highlight: isPillHighlighted({
        funnelOrRecordTypeId: funnelId,
        pillType: CanvasPillTypes.groupAssignmentRule,
        highlightEntities,
      }),
    });
  }

  if (visibilityMap[VisibilityLayers.SCHEDULED_ASSIGNMENTS]) {
    const amount =
      (scheduledAssignmentsPills?.recordType[recordTypeId] || 0) +
      (scheduledAssignmentsPills?.funnels[funnelId] || 0) +
      (scheduledAssignmentsPills?.objectType[objectType] || 0);

    pills.push({
      type: CanvasPillTypes.groupScheduledAssignment,
      amount: amount,
      highlight: isPillHighlighted({
        funnelOrRecordTypeId: funnelId,
        pillType: CanvasPillTypes.groupScheduledAssignment,
        highlightEntities,
      }),
    });
  }

  if (visibilityMap[VisibilityLayers.FUNNEL_DEDUPLICATION]) {
    pills.push({
      type: CanvasPillTypes.groupDeduplication,
      amount: dedupePills?.objectType[objectType] || 0,
      highlight: isPillHighlighted({
        funnelOrRecordTypeId: funnelId,
        pillType: CanvasPillTypes.groupDeduplication,
        highlightEntities,
      }),
    });
  }
  if (visibilityMap[VisibilityLayers.FUNNEL_MATCHING]) {
    pills.push({
      type: CanvasPillTypes.groupMatching,
      amount: matchingPills?.objectType[objectType] || 0,
      highlight: isPillHighlighted({
        funnelOrRecordTypeId: funnelId,
        pillType: CanvasPillTypes.groupMatching,
        highlightEntities,
      }),
    });
  }
  if (visibilityMap[VisibilityLayers.FUNNEL_PLAYBOOK_ALERTS]) {
    pills.push({
      type: CanvasPillTypes.groupPlaybookAlert,
      amount:
        (alertsPills?.recordType[recordTypeId] || 0) +
        (alertsPills?.funnels[funnelId] || 0) +
        (alertsPills?.objectType[objectType] || 0),
      highlight: isPillHighlighted({
        funnelOrRecordTypeId: funnelId,
        pillType: CanvasPillTypes.groupPlaybookAlert,
        highlightEntities,
      }),
    });
  }

  if (!renderZeroCountPills) {
    pills = pills.filter((pill) => pill.amount > 0);
  }

  return pills;
};

export const recordTypePills = ({
  renderZeroCountPills,
  highlightEntities,
  recordType,
  recordTypeId,
  visibilityMap,
  pills: pillsMap,
}: {
  renderZeroCountPills?: boolean;
  highlightEntities?: HighlightEntities[];
  recordType: SingleRecordType;
  recordTypeId: string;
  visibilityMap: VisibilityMap;
  pills?: Partial<PillsMap>;
}) => {
  const {
    sweepAutomations: sweepAutomationPills,
    alerts: alertsPills,
    assignments: assignmentsPills,
    dedupe: dedupePills,
    matching: matchingPills,
    scheduledAssignments: scheduledAssignmentsPills,
  } = pillsMap || {};

  let pills: CanvasPill[] = [];

  if (visibilityMap[VisibilityLayers.FUNNEL_SFDC_AUTOMATIONS]) {
    pills.push({
      type: CanvasPillTypes.groupSfdcAutomation,
      amount: pillsMap?.funnelSfcAutomations?.recordType[recordTypeId] || 0,
      highlight: isPillHighlighted({
        funnelOrRecordTypeId: recordTypeId,
        pillType: CanvasPillTypes.groupSfdcAutomation,
        highlightEntities,
      }),
    });
  }
  if (visibilityMap[VisibilityLayers.FUNNEL_VALIDATION_RULES]) {
    pills.push({
      type: CanvasPillTypes.groupValidationRule,
      amount: pillsMap?.funnelValidationRules?.recordType[recordTypeId] || 0,
      highlight: isPillHighlighted({
        funnelOrRecordTypeId: recordTypeId,
        pillType: CanvasPillTypes.groupValidationRule,
        highlightEntities,
      }),
    });
  }

  if (visibilityMap[VisibilityLayers.FUNNEL_APEX]) {
    pills.push({
      type: CanvasPillTypes.groupApex,
      amount: pillsMap?.funnelApex?.recordType[recordTypeId] || 0,
      highlight: isPillHighlighted({
        funnelOrRecordTypeId: recordTypeId,
        pillType: CanvasPillTypes.groupApex,
        highlightEntities,
      }),
    });
  }
  if (visibilityMap[VisibilityLayers.FUNNEL_SWEEP_AUTOMATIONS]) {
    pills.push({
      type: CanvasPillTypes.groupAutomation,
      amount:
        (sweepAutomationPills?.recordType[recordTypeId] || 0) +
        (sweepAutomationPills?.objectType[recordType?.objectName] || 0),
      highlight: isPillHighlighted({
        funnelOrRecordTypeId: recordTypeId,
        pillType: CanvasPillTypes.groupAutomation,
        highlightEntities,
      }),
    });
  }

  if (visibilityMap[VisibilityLayers.FUNNEL_ASSIGNMENT_RULES]) {
    pills.push({
      type: CanvasPillTypes.groupAssignmentRule,
      amount:
        (assignmentsPills?.recordType[recordTypeId] || 0) +
        (assignmentsPills?.objectType[recordType?.objectName] || 0),
      highlight: isPillHighlighted({
        funnelOrRecordTypeId: recordTypeId,
        pillType: CanvasPillTypes.groupAssignmentRule,
        highlightEntities,
      }),
    });
  }

  if (visibilityMap[VisibilityLayers.SCHEDULED_ASSIGNMENTS]) {
    pills.push({
      type: CanvasPillTypes.groupScheduledAssignment,
      amount:
        (scheduledAssignmentsPills?.recordType[recordTypeId] || 0) +
        (scheduledAssignmentsPills?.objectType[recordType?.objectName] || 0),
      highlight: isPillHighlighted({
        funnelOrRecordTypeId: recordTypeId,
        pillType: CanvasPillTypes.groupScheduledAssignment,
        highlightEntities,
      }),
    });
  }

  if (visibilityMap[VisibilityLayers.FUNNEL_DEDUPLICATION]) {
    pills.push({
      type: CanvasPillTypes.groupDeduplication,
      amount: dedupePills?.objectType[recordType?.objectName] || 0,
      highlight: isPillHighlighted({
        funnelOrRecordTypeId: recordTypeId,
        pillType: CanvasPillTypes.groupDeduplication,
        highlightEntities,
      }),
    });
  }
  if (visibilityMap[VisibilityLayers.FUNNEL_MATCHING]) {
    pills.push({
      type: CanvasPillTypes.groupMatching,
      amount: matchingPills?.objectType[recordType?.objectName] || 0,
      highlight: isPillHighlighted({
        funnelOrRecordTypeId: recordTypeId,
        pillType: CanvasPillTypes.groupMatching,
        highlightEntities,
      }),
    });
  }
  if (visibilityMap[VisibilityLayers.FUNNEL_PLAYBOOK_ALERTS]) {
    pills.push({
      type: CanvasPillTypes.groupPlaybookAlert,
      amount:
        (alertsPills?.recordType[recordTypeId] || 0) +
        (alertsPills?.objectType[recordType?.objectName] || 0),
      highlight: isPillHighlighted({
        funnelOrRecordTypeId: recordTypeId,
        pillType: CanvasPillTypes.groupPlaybookAlert,
        highlightEntities,
      }),
    });
  }

  if (!renderZeroCountPills) {
    pills = pills.filter((pill) => pill.amount > 0);
  }

  return pills;
};

export const recordTypeStepPills = ({
  renderZeroCountPills,
  highlightEntities,
  recordTypeId,
  recordTypeDataValue,
  visibilityMap,
  pills: pillsMap,
}: {
  renderZeroCountPills?: boolean;
  highlightEntities?: HighlightEntities[];
  recordType: SingleRecordType;
  recordTypeId: string;
  recordTypeDataValue: PicklistValue;
  visibilityMap: VisibilityMap;
  pills?: Partial<PillsMap>;
}) => {
  let pills: CanvasPill[] = [];
  const stepId = `${recordTypeId}.${recordTypeDataValue.fullName}`;

  if (visibilityMap[VisibilityLayers.SFDC_AUTOMATIONS]) {
    pills.push({
      type: CanvasPillTypes.sfdcAutomation,
      amount: pillsMap?.sdfcAutomations?.steps[stepId] || 0,
      highlight: isPillHighlighted({
        stepId: recordTypeId + FULL_NAME_SEPARATOR + recordTypeDataValue.fullName,
        pillType: CanvasPillTypes.sfdcAutomation,
        highlightEntities,
      }),
    });
  }

  if (visibilityMap[VisibilityLayers.VALIDATION_RULES]) {
    pills.push({
      type: CanvasPillTypes.validationRule,
      amount: pillsMap?.validationRules?.steps[stepId] || 0,
      highlight: isPillHighlighted({
        stepId: recordTypeId + FULL_NAME_SEPARATOR + recordTypeDataValue.fullName,
        pillType: CanvasPillTypes.validationRule,
        highlightEntities,
      }),
    });
  }
  if (visibilityMap[VisibilityLayers.APEX]) {
    pills.push({
      type: CanvasPillTypes.apexTrigger,
      amount: pillsMap?.apex?.steps[stepId] || 0,
      highlight: isPillHighlighted({
        stepId: recordTypeId + FULL_NAME_SEPARATOR + recordTypeDataValue.fullName,
        pillType: CanvasPillTypes.apexTrigger,
        highlightEntities,
      }),
    });
  }

  if (!renderZeroCountPills) {
    pills = pills.filter((pill) => pill.amount > 0);
  }
  return pills;
};

export const hubspotFunnelPills = ({
  visibilityMap,
  pills: pillsMap,
  renderZeroCountPills,
}: {
  visibilityMap: VisibilityMap;
  renderZeroCountPills?: boolean;
  pills?: Partial<PillsMap>;
}) => {
  if (!visibilityMap[VisibilityLayers.FUNNEL_HUBSPOT_ENROLLMENTS]) {
    return [];
  }
  let pills: CanvasPill[] = [];

  if (pillsMap?.hubspotEnrollments) {
    pills.push({
      type: CanvasPillTypes.groupHubspotEnrollment,
      amount: pillsMap.hubspotEnrollments.funnels['hubspot'] || 0,
    });
  }

  if (!renderZeroCountPills) {
    pills = pills.filter((pill) => pill.amount > 0);
  }
  return pills;
};
