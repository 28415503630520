import { useEffect, useRef } from 'react';
import { useSweepCanvasState } from '../internal-context/CanvasStateContext';
import { CanvasElementType } from '../canvas-types';
import { useReactFlow } from '@xyflow/react';
import { useGetNodeAbsolutePosition } from '../helpers/useGetNodeAbsolutePosition';

export const useFitEditingNodeToViewEffect = () => {
  const { canvasNodes: nodes } = useSweepCanvasState();
  const { setCenter } = useReactFlow();

  const editingNodeId = nodes.find((node) => node.type === CanvasElementType.EDITABLE)?.id;
  const getAbsolutePosition = useGetNodeAbsolutePosition();

  const nodeHasFocused = useRef(false);
  useEffect(() => {
    if (editingNodeId && !nodeHasFocused.current) {
      nodeHasFocused.current = true;

      const absolutePosition = getAbsolutePosition(editingNodeId);
      if (absolutePosition) {
        setCenter(absolutePosition.x, absolutePosition.y, {
          zoom: 0.7,
          duration: 300,
        });
      }
    }
    if (!editingNodeId) {
      nodeHasFocused.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingNodeId]);
};
