import { useSelector } from 'react-redux';
import { EmptyState } from './EmptyState';
import { CenteredCircularProgress } from '../../../../common/CenteredCircularProgress';
import { useMarketingAttributionModels } from './useMarketingAttributionModels';
import { AttributionModelsTableWithFilters } from './AttributionModelsTableWithFilters';
import { selectMarketingAttributionModels } from '../../../../../reducers/global/marketingAttributionReducer';
import { MarketingAttributionAutomationContextProvider } from './MarketingAttributionAutomationContextProvider';
import { MultipleRuleBuildersProvider } from '../../../../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';

export const AttributionModelsPanel = () => {
  const marketingAttributionModels = useSelector(selectMarketingAttributionModels);
  const { isLoading } = useMarketingAttributionModels();

  return (
    <MarketingAttributionAutomationContextProvider>
      <MultipleRuleBuildersProvider>
        {isLoading && <CenteredCircularProgress />}
        {!marketingAttributionModels?.length && <EmptyState />}
        {marketingAttributionModels?.length && <AttributionModelsTableWithFilters />}
      </MultipleRuleBuildersProvider>
    </MarketingAttributionAutomationContextProvider>
  );
};
