import useAiAgents from '../../../aiAgentCenter/useAiAgents';
import useAiAgentCenter from '../../../aiAgentCenter/useAiAgentCenter';
import { useSelector } from 'react-redux';
import { selectDefaultCreationCrmOrgId } from '../../../../reducers/userInfoReducer';
import { selectAgents } from '../../../aiAgentCenter/aiAgentsReducer';
import { useMemo } from 'react';

const useStagesWithSparkleIcon = (funnelMap: FunnelMap) => {
  const { activeAgent } = useAiAgents();
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  const _crmOrgId = crmOrgId ?? undefined;
  const agents = useSelector(selectAgents(_crmOrgId));
  const { isOpened: isOpenedAgentCenter } = useAiAgentCenter();

  const stagesWithActiveAgent = useMemo(() => {
    if (!crmOrgId || !agents) {
      return [];
    }
    const stages = [];
    const agent = agents.find((agent) => agent.id === activeAgent?.id);

    if (!agent || !agent?.contextComponents || !Array.isArray(agent.contextComponents)) {
      return [];
    }

    for (const component of agent.contextComponents) {
      const { funnelId, stagesIds } = component;
      if (funnelMap.funnelsData[funnelId]) {
        for (const stage of funnelMap.funnelsData[funnelId].funnelDetails.stages) {
          if (stagesIds.includes(stage._stageId)) {
            stages.push(stage._stageId);
          }
        }
      }

      if (funnelMap.thirdPartyFunnelsData[funnelId]) {
        for (const step of funnelMap.thirdPartyFunnelsData[funnelId].steps) {
          if (stagesIds.includes(step.id)) {
            stages.push(step.id);
          }
        }
      }
    }

    return stages;
  }, [activeAgent?.id, agents, crmOrgId, funnelMap.funnelsData, funnelMap.thirdPartyFunnelsData]);

  const stagesWithSparkleIcon = useMemo(() => {
    return isOpenedAgentCenter ? stagesWithActiveAgent : [];
  }, [stagesWithActiveAgent, isOpenedAgentCenter]);

  return stagesWithSparkleIcon;
};

export default useStagesWithSparkleIcon;
