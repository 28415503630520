import { DataTableBaseColumn } from '../table/TableTypes';
import { AuditReportType } from './AuditTable';

export const getColumns = (
  auditType: AuditReportType,
  isMultipleIntegrationActions?: boolean,
  isMultipleSlackActions?: boolean,
) => {
  const webhooksColumns: DataTableBaseColumn[] = [
    {
      field: 'createdAt',
      headerName: 'Time & date',
      width: '180px',
    },
    {
      field: 'environment',
      headerName: 'Environment',
      width: '220px',
    },
    {
      field: 'automationName',
      headerName: 'Automation name',
      width: '240px',
    },
    {
      field: 'recordUrl',
      headerName: 'Triggering record',
      width: '240px',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: '140px',
    },
  ];
  const automationWebhookColumns: DataTableBaseColumn[] = [
    {
      field: 'createdAt',
      headerName: 'Time & date',
      width: isMultipleIntegrationActions ? '25%' : '33%',
    },
    {
      field: 'integrationName',
      headerName: 'Integration name',
      width: isMultipleIntegrationActions ? '25%' : '33%',
      hidden: !isMultipleIntegrationActions,
    },
    {
      field: 'recordUrl',
      headerName: 'Triggering record',
      width: isMultipleIntegrationActions ? '25%' : '33%',
    },
    {
      field: 'status',
      headerName: 'Status',
    },
  ];
  const slackColumns: DataTableBaseColumn[] = [
    {
      field: 'createdAt',
      headerName: 'Time & date',
      width: isMultipleSlackActions ? '160px' : '200px',
    },
    {
      field: 'action',
      headerName: 'Action',
      width: '200px',
      hidden: !isMultipleSlackActions,
    },
    {
      field: 'recordUrl',
      headerName: 'Triggering record',
      width: isMultipleSlackActions ? '210px' : '240px',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: isMultipleSlackActions ? '160px' : '200px',
    },
    {
      field: 'details',
      headerName: 'Details',
    },
  ];

  switch (auditType) {
    case AuditReportType.INTEGRATION_WEBHOOKS:
      return webhooksColumns;
    case AuditReportType.AUTOMATION_WEBHOOK:
      return automationWebhookColumns;
    case AuditReportType.SLACK:
      return slackColumns;
  }
};
