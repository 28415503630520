import { Box, Stack } from '@mui/material';
import { AI_AGENTS_INFO, AiAgent } from './aiAgentsConsts';
import { colors, Typography } from '@sweep-io/sweep-design';
import useAiAgentChats from './useAiAgentChats';
import { ReactNode, useCallback } from 'react';
import PromptBubblesGrid from './PromptBubblesGrid';

const StartChatWithPrompts = ({
  agent,
  variant,
  prompts,
}: {
  agent: AiAgent;
  variant: 'expanded' | 'collapsed';
  prompts: string[];
}) => {
  const { agentIcon, persona, description } = AI_AGENTS_INFO[agent.type];
  const { onConfirmMessage } = useAiAgentChats();

  const onConfirm = useCallback(
    (prompt: string) =>
      onConfirmMessage({
        messageStr: prompt,
        agentId: agent.id,
        agentType: agent.type,
      }),
    [onConfirmMessage, agent],
  );

  return (
    <Stack padding="0 22px" maxWidth="568px" alignSelf="center">
      <Stack padding="0 30px">
        <AgentInfoCentered
          variant={variant}
          icon={agentIcon}
          persona={persona}
          name={agent.name}
          description={description}
        />
      </Stack>
      <PromptBubblesGrid prompts={prompts} variant={variant} onClickPrompt={onConfirm} />
    </Stack>
  );
};

const AgentInfoCentered = ({
  icon,
  name,
  persona,
  description,
  variant,
}: {
  icon: ReactNode;
  name: string;
  persona: string;
  description: string;
  variant: 'expanded' | 'collapsed';
}) => {
  const isExpanded = variant === 'expanded';
  return (
    <Stack alignItems="center" pb={isExpanded ? 5 : 4}>
      <Stack maxWidth="568px" alignItems="center" gap={1}>
        <Box
          sx={{
            width: isExpanded ? '64px' : '52px',
            height: isExpanded ? '64px' : '52px',
          }}
        >
          {icon}
        </Box>
        <Stack gap={1} alignItems="center" textAlign="center">
          <Stack gap={0.5} alignItems="center" textAlign="center">
            <Typography variant={isExpanded ? 'h1' : 'h4'} color={colors.grey[900]}>
              {name}
            </Typography>
            <Typography variant="caption" color={colors.grey[700]}>
              {persona}
            </Typography>
          </Stack>
          <Typography variant="body" color={colors.grey[900]} textAlign="center">
            {description}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default StartChatWithPrompts;
