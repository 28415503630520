import { useCallback } from 'react';
import { calculateAndAssignEdgeConnectionTypes } from '../assignConnectionTypeToEdges';
import {
  SweepNodesChangeEscapeFromShadowStep,
  SweepNodesChangeType,
  CanvasElementType,
  SweepCanvasNode,
  NodeChangeMoveNode,
  SweepNodesChangeAddNodeEvent,
} from '../canvas-types';
import {
  CanvasNodeOrigin,
  EditableNodeDataMetadata,
  RFNodeEditRegularNode,
} from '../canvas-types/nodeTypesData';
import { SweepCanvasReactFlowEdgeDataType } from '../edges/SweepCanvasReactFlowEdgeDataType';
import { convertXYPositionToGridPosition } from '../helpers/gridPositioningUtils';
import { useSweepCanvasState } from '../internal-context/CanvasStateContext';
import { createObjectOperations } from '../node-changes-event-handlers/objectOperations';
import { updateGroupBoundingBox } from '../node-changes-event-handlers/updateGroupBoundingBox';
import { useTemporaryTransformationsCtx } from './TemporaryTransformationsCtx';
import { XYPosition } from '@xyflow/react';
import { useSweepCanvasPropsCtx } from '../internal-context/SweepCanvasPropsCtx';
import { addXYPositions } from '../helpers/xYPositionOperation';
import { uniqueId } from '../../../lib/uniqueId';

export const useNewEditableNodeFactory = () => {
  const { onSweepNodesChange, onNodeNameChange } = useSweepCanvasPropsCtx();

  const { setCanvasNodes, setCanvasEdges, getCanvasNodes } = useSweepCanvasState();

  const transformedOriginalValuesToRestore = useTemporaryTransformationsCtx();

  const restoreOriginalNodesAndEdgesState = useCallback(
    (groupId: string) => {
      const restoredValues = {
        nodesToRemove: [],
        nodePositions: [],
        edgesToRemove: [],
        edgeSourceAndTarget: [],
        ...transformedOriginalValuesToRestore.current,
      };
      transformedOriginalValuesToRestore.current = null;
      setCanvasNodes((_, nodeOperations) => {
        const {
          removeObject: removeNode,
          setObject: setNode,
          getObjects: getNodes,
        } = nodeOperations;

        restoredValues.nodesToRemove.forEach((nodeId) => {
          removeNode(nodeId);
        });
        restoredValues.nodePositions.forEach((nodePosition) => {
          setNode(nodePosition.id, (node) => ({
            ...node,
            position: nodePosition.position,
          }));
        });
        const groupHasNodes = Boolean(
          getNodes().find(
            (node) => node.parentId === groupId && node.type === CanvasElementType.REGULAR,
          ),
        );

        if (groupHasNodes) {
          updateGroupBoundingBox({
            groupNodeId: groupId,
            nodeOperations,
          });
        } else {
          // If the group has no nodes, remove the group
          removeNode(groupId);
        }

        return getNodes();
      });

      const nodeOperations = createObjectOperations(getCanvasNodes());

      setCanvasEdges((_, edgeOperations) => {
        const { removeObject } = edgeOperations;

        restoredValues.edgesToRemove.forEach((edgeId) => {
          removeObject(edgeId);
        });
        restoredValues.edgeSourceAndTarget.forEach((edgeTransform) => {
          edgeOperations.setObject(edgeTransform.id, (edge) => ({
            ...edge,
            source: edgeTransform.source,
            target: edgeTransform.target,
          }));
        });

        return calculateAndAssignEdgeConnectionTypes({
          edgeOperations,
          nodesPositions: nodeOperations.getObjects().map((node) => {
            const parentGroupPosition = node.parentId
              ? (nodeOperations.getObject(node.parentId)?.position ?? { x: 0, y: 0 })
              : { x: 0, y: 0 };

            return {
              id: node.id,
              positionAbsolute: addXYPositions(parentGroupPosition, node.position),
              parentId: node.parentId,
            };
          }),
        });
      });
    },
    [getCanvasNodes, setCanvasEdges, setCanvasNodes, transformedOriginalValuesToRestore],
  );

  const onEditLabelCancel = useCallback(
    (
      nodeId: string,
      parentId: string,
      metadata: EditableNodeDataMetadata,
      wasValidatedOnBlur?: boolean,
    ) => {
      if (wasValidatedOnBlur) return;
      const change: SweepNodesChangeEscapeFromShadowStep = {
        type: SweepNodesChangeType.EscapeFromShadowStep,
        change: { nodeId, parentId },
      };
      onSweepNodesChange?.([change]);
      restoreOriginalNodesAndEdgesState(parentId);
    },

    [restoreOriginalNodesAndEdgesState, onSweepNodesChange],
  );

  const editNameValidateClbk = useCallback(
    (parentId: string) => (value: string) => {
      if (onNodeNameChange) {
        const res = onNodeNameChange(value, parentId as string);
        if (!res.isValid) {
          return {
            isValid: false,
            error: res.error,
          };
        }
      }
      return {
        isValid: true,
      };
    },
    // TODO: Fix this dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onNewEditNameConfirm: (
    id: string,
    nodeName: string,
    metadata: EditableNodeDataMetadata,
  ) => Promise<void> = useCallback(
    async (id: string, nodeName: string, metadata: EditableNodeDataMetadata) => {
      if (nodeName.length === 0) {
        onEditLabelCancel(id, '', metadata, true);
        return;
      }
      const { parentId, isNb } = metadata;

      const nodeType = isNb ? CanvasElementType.NURTURING_BUCKET : CanvasElementType.REGULAR;
      const newCanvasNode: SweepCanvasNode = {
        nodeType: 'node',
        id,
        parentId,
        type: nodeType,
        name: nodeName,
        objectType: metadata.objectType,
        position: {
          column: metadata.position.column,
          row: metadata.position.row,
        },
        originStepName: metadata.originStepName,
      };
      const nodeOperations = createObjectOperations(getCanvasNodes());

      const nodesToMove =
        (transformedOriginalValuesToRestore.current?.nodePositions
          .map(({ id }) => {
            const nodePosition = nodeOperations.getObject(id)?.position;
            if (!nodePosition) return null;
            const nodeChangeMoveNode: NodeChangeMoveNode = {
              nodeId: id,
              newPosition: convertXYPositionToGridPosition(nodePosition),
            };
            return nodeChangeMoveNode;
          })
          .filter(Boolean) as NodeChangeMoveNode[]) || [];

      const parentNode = getCanvasNodes().find((node) => node.id === parentId);
      if (!parentNode) return;

      const change: SweepNodesChangeAddNodeEvent = {
        type: SweepNodesChangeType.AddNode,
        change: {
          newNode: newCanvasNode,
          nodesToMove,
          edgeTransformations: {},
          parentCanvasNodePosition: convertXYPositionToGridPosition(parentNode.position),
        },
      };

      switch (metadata.origin.type) {
        case 'node':
          change.change.newEdge = {
            id: uniqueId(),
            source: metadata.sourceId,
            target: id,
            data: {
              type: SweepCanvasReactFlowEdgeDataType.CIRCLE,
            },
          };
          break;

        case 'edge':
          if (metadata.targetId) {
            change.change.newEdge = {
              id: uniqueId(),
              source: id,
              target: metadata.targetId,
              data: {
                type: SweepCanvasReactFlowEdgeDataType.CIRCLE,
              },
            };
            change.change.edgeTransformations[metadata.origin.id] = {
              id: metadata.origin.id,
              source: metadata.sourceId,
              target: id,
              data: {
                type: SweepCanvasReactFlowEdgeDataType.CIRCLE,
              },
            };
          }
          break;
        case 'none':
        default:
          break;
      }

      onSweepNodesChange?.([change]);
      transformedOriginalValuesToRestore.current = null;
    },
    [getCanvasNodes, onEditLabelCancel, onSweepNodesChange, transformedOriginalValuesToRestore],
  );

  const createNewNode = useCallback(
    ({
      newNodeId,
      position,
      isNb,
      parentId,
      objectType,
      originStepName,
      sourceId,
      targetId,
      isInGroupMouseMoveMode,
      origin,
    }: {
      newNodeId: string;
      position: XYPosition;
      isNb: boolean;
      parentId?: string;
      objectType: ObjectTypeValues;
      originStepName: string;
      sourceId: string;
      targetId?: string;
      isInGroupMouseMoveMode?: boolean;
      origin: CanvasNodeOrigin;
    }) => {
      const newNode: RFNodeEditRegularNode = {
        id: newNodeId,
        type: CanvasElementType.EDITABLE,
        parentId,
        position,
        data: {
          onEditNameCancel: onEditLabelCancel,
          editNameValidateClbk: editNameValidateClbk,
          onEditNameConfirm: onNewEditNameConfirm,
          metadata: {
            objectType,
            parentId: parentId || '',
            isNb,
            originStepName,
            position: convertXYPositionToGridPosition(position),
            isInGroupMouseMoveMode: Boolean(isInGroupMouseMoveMode),
            sourceId,
            targetId,
            origin,
          },
        },
      };
      return newNode;
    },
    [editNameValidateClbk, onEditLabelCancel, onNewEditNameConfirm],
  );
  return { createNewNode };
};
