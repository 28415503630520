import { noop } from 'lodash';
import { Ref, createContext, useCallback, useRef } from 'react';

interface SearchInputContextType {
  searchInputRef: Ref<any>;
  clearSearchInput: () => void;
}

export const SearchInputContext = createContext<SearchInputContextType>({
  clearSearchInput: noop,
  searchInputRef: null,
});

export const SearchInputProvider = ({ children }: any) => {
  const searchInputRef = useRef<any>(null);

  const clearSearchInput = useCallback(() => {
    searchInputRef?.current?.clear();
  }, []);

  return (
    <SearchInputContext.Provider
      value={{
        clearSearchInput,
        searchInputRef,
      }}
    >
      {children}
    </SearchInputContext.Provider>
  );
};
