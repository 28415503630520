import { Stack } from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';

export const PageTitle = ({ pageTitle }: { pageTitle: string }) => {
  return (
    <Stack pt={8} pb={8}>
      <Typography variant="h2" textAlign="center" color={colors.night[900]}>
        {pageTitle}
      </Typography>
    </Stack>
  );
};
