import { useCallback, useState } from 'react';
import AgentInstructionsDialog from './AgentInstructionsDialog';
import AgentFilesDialog from './AgentFilesDialog';
import useAiAgents from '../useAiAgents';
import { AI_AGENTS_INFO, AiAgent, NewAgentFile } from '../aiAgentsConsts';
import useSendBiEvent from '../../../hooks/useSendBiEvent';
import { AI_AGENTS_EVENTS } from '../../../services/events';

const useAgentSettings = (agent?: AiAgent) => {
  const [isOpenedInstructionsDialog, setIsOpenedInstructionsDialog] = useState(false);
  const [isOpenedFilesDialog, setIsOpenedFilesDialog] = useState(false);
  const { editAgentInstructions, addAgentFiles, deleteFile } = useAiAgents();
  const sendBiEvent = useSendBiEvent();
  const agentTypeInfo = agent ? AI_AGENTS_INFO[agent?.type] : { addingFiles: undefined };

  const openInstructionsDialog = useCallback(() => {
    setIsOpenedInstructionsDialog(true);
  }, []);

  const openFilesDialog = useCallback(() => {
    setIsOpenedFilesDialog(true);
  }, []);

  const onInstructionsConfirm = async (instructions: string) => {
    if (agent) {
      sendBiEvent({ name: AI_AGENTS_EVENTS.editInstructions });
      await editAgentInstructions(agent.id, instructions);
    }
  };

  const onFilesAdd = async (files: NewAgentFile[]) => {
    if (agent) {
      sendBiEvent({ name: AI_AGENTS_EVENTS.addFiles });
      await addAgentFiles(agent.id, files);
    }
  };

  const onFileRemove = async (fileId: string) => {
    if (agent) {
      await deleteFile(agent.id, fileId);
    }
  };

  const dialogs = (
    <>
      {isOpenedInstructionsDialog && (
        <AgentInstructionsDialog
          closeDialog={() => setIsOpenedInstructionsDialog(false)}
          onConfirm={onInstructionsConfirm}
          initialValue={agent?.instructions ?? ''}
          placeholder={agentTypeInfo.addingFiles ? agentTypeInfo.addingFiles.placeholder : ''}
        />
      )}
      {isOpenedFilesDialog && (
        <AgentFilesDialog
          closeDialog={() => setIsOpenedFilesDialog(false)}
          addFiles={onFilesAdd}
          removeFile={onFileRemove}
          files={agent?.files ?? []}
          agentName={agent?.name ?? ''}
        />
      )}
    </>
  );

  return { openInstructionsDialog, openFilesDialog, dialogs };
};

export default useAgentSettings;
