import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCallback, useMemo, useState } from 'react';
import { useIsPristine } from '../../hooks/useIsPristine';
import { uniqueId } from '../../lib/uniqueId';
import { AutomationActionType } from '../../types/enums/AutomationActionType';
import { CreateEditDialog } from '../common/create-edit-dialog/CreateEditDialog';
import { Button, colors, Typography } from '@sweep-io/sweep-design';
import usePermission from '../common/permissions/usePermission';
import { CustomHeader } from '../common/rule-builder/CustomHeader';
import { SweepFieldsRuleBuilder } from '../common/sweep-condition-rule-builder/SweepConditionRuleBuilder';
import {
  MultipleRuleBuildersProvider,
  useRefForMultipleRuleBuilders,
  useValidateAllAndReturnIsValid,
} from '../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';
import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';
import { selectSlackIntegrationStatus } from '../../reducers/integrationsReducer';
import { useIntegrations } from '../../hooks/useIntegrations';
import { useRunOnce } from '../common/useRunOnce';
import useConfirm from '../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import { ConfirmLeaveWithoutSaveDialog } from '../common/dialogs/ConfirmLeaveWithoutSave/ConfirmLeaveWithoutSaveDialog';
import { useAutomationsContext } from './AutomationsContext';
import { getOperatorsWithoutRecordsComparison, validateAnAction } from './helper';
import { AutomationActionPopupMenu } from './AutomationActionPopupMenu';
import { AutomationsActionsWrapper } from './AutomationsActionsWrapper';
import { labelBoxDesign } from './AutomationsForm';
import { isEmptySweepCriterion } from '../../models/AutomationsConditionalActionModelDeprecated';
import { MainSelectors } from '../SweepFieldsMainSelector/SweepFieldsMainSelector';
import { SelectorValueTypes } from '../../types/enums/SelectorValueTypes';

interface ConditionalActionDialogProps {
  initialConditionalAction?: AutomationConditionalAction;
  onCreateEdit: (conditionalAction: AutomationConditionalAction) => any;
  crmOrgId: string;
  objectType: string;
  onCancel: () => any;
  editMode?: boolean;
  objectTypes?: ObjectTypeName[];
  isShowWebhookOption?: boolean;
}

const SectionBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

interface FormType {
  _id: string;
  when?: OptionalCondition;
  actions: Partial<AutomationAction>[];
  _name: string;
}

const automationHasOnlyOneCriteriaAndItIsEmpty = (action: FormType) => {
  if (action.when?.criteria?.criteria) {
    if (
      action.when.criteria.criteria.length === 1 &&
      isEmptySweepCriterion(action.when.criteria.criteria[0])
    ) {
      return true;
    }
  }
  return false;
};

const hasActions = (conditionalAction: FormType) => {
  if (conditionalAction?.actions && conditionalAction?.actions?.length > 0) {
    return true;
  }
  return false;
};

const sanitizeConditionalAction = (action: FormType) => {
  const cloned = cloneDeep(action);
  if (
    automationHasOnlyOneCriteriaAndItIsEmpty(action) ||
    action.when?.criteria?.criteria.length === 0
  ) {
    delete cloned.when;
  }
  return cloned as AutomationConditionalAction;
};

const ConditionalActionDialog = ({
  initialConditionalAction,
  onCreateEdit,
  crmOrgId,
  objectType,
  onCancel,
  editMode,
  objectTypes,
  isShowWebhookOption,
}: ConditionalActionDialogProps) => {
  const slackStatus = useSelector(selectSlackIntegrationStatus);
  const integrations = useIntegrations();

  useRunOnce(async () => {
    await integrations.getSlackStatus();
  });

  const { permissions } = useAutomationsContext();
  const [isAllowedBtn] = usePermission([permissions.create]);

  const formVarsFromInitialConditionalAction = (): FormType => {
    const { criteria = [], criteriaLogic = '' } = initialConditionalAction?.when?.criteria || {};

    return {
      _id: initialConditionalAction?._id || uniqueId(),
      _name: initialConditionalAction?._name || 'New branch',
      when: {
        criteria: {
          criteria,
          criteriaLogic,
        },
      },
      actions: initialConditionalAction?.actions ?? [],
    };
  };

  const [formVars, setFormVars] = useState<FormType>(formVarsFromInitialConditionalAction);

  const isPristine = useIsPristine(formVars);
  const { openConfirm } = useConfirm();

  const isFormValid = useMemo(() => {
    const isNameValid = formVars._name.length > 0;
    const areActionsValid = formVars.actions.every((action) =>
      validateAnAction(action, slackStatus, objectType),
    );

    return isNameValid && areActionsValid && hasActions(formVars);
  }, [formVars, objectType, slackStatus]);

  const ruleBuilderRef = useRefForMultipleRuleBuilders();
  const validateAllAndReturnIsValid = useValidateAllAndReturnIsValid();

  const applyCurrentAssignment = useCallback(() => {
    const isValid =
      hasActions(formVars) &&
      (automationHasOnlyOneCriteriaAndItIsEmpty(formVars) || validateAllAndReturnIsValid());

    if (isValid) {
      const sanitizedRule = sanitizeConditionalAction(formVars);
      onCreateEdit(sanitizedRule);
    }
  }, [formVars, validateAllAndReturnIsValid, onCreateEdit]);

  const clearAction = useCallback(
    (actionIndx: number) => {
      if (formVars.actions.length > 1) {
        const updatedActions = [...formVars.actions];
        updatedActions.splice(actionIndx, 1);
        setFormVars((prev) => ({ ...prev, actions: updatedActions }));
      } else {
        setFormVars((prev) => ({ ...prev, actions: [] }));
      }
    },
    [formVars.actions],
  );

  const addAction = useCallback(
    (emptyAction: Partial<AutomationAction>, isBefore: boolean, idx: number) => {
      const updatedActions = [...(formVars.actions || [])];
      if (isBefore) {
        updatedActions?.splice(idx, 0, emptyAction as AutomationAction);
      } else {
        updatedActions?.splice(idx + 1, 0, emptyAction as AutomationAction);
      }
      setFormVars((prev) => ({ ...prev, actions: updatedActions }));
    },
    [formVars.actions],
  );

  const closeOrConfirmClose = async () => {
    if (!isPristine) {
      const isConfirmed = await openConfirm(<ConfirmLeaveWithoutSaveDialog />);
      if (!isConfirmed) {
        return;
      }
    }
    onCancel();
  };

  const _actionsToExclude = useMemo(() => {
    return isShowWebhookOption
      ? [
          AutomationActionType.PrioritizedActions,
          AutomationActionType.Webhook,
          AutomationActionType.VerifyURL,
        ]
      : [AutomationActionType.PrioritizedActions];
  }, [isShowWebhookOption]);

  const _onChange = useCallback(
    async (action: Partial<AutomationAction>, idx?: number) => {
      const updatedActions = [...(formVars.actions || [])];
      if (updatedActions) {
        if (idx !== undefined) {
          updatedActions[idx] = action;
        } else {
          updatedActions.push(action);
        }

        setFormVars((prev) => ({ ...prev, actions: updatedActions }));
      }
    },
    [formVars.actions],
  );

  const _onCriteriaChange = useCallback(
    async (newSweepCondition: SweepCondition) => {
      setFormVars({
        ...formVars,
        when: {
          ...formVars.when,
          criteria: newSweepCondition,
        },
      });
    },
    [formVars],
  );

  const _getSelectorKey = useMemo(() => {
    return isShowWebhookOption ? MainSelectors.TextField : undefined;
  }, [isShowWebhookOption]);

  const excludeValueType = useCallback(
    (type: SelectorValueTypes) => {
      if (
        isShowWebhookOption &&
        (type === SelectorValueTypes.FROM_ANOTHER_OBJECT || type === SelectorValueTypes.PRIOR)
      ) {
        return true;
      }
      return false;
    },
    [isShowWebhookOption],
  );

  return (
    <CreateEditDialog
      open
      onClose={closeOrConfirmClose}
      HeaderProps={{
        hideBackButton: true,
        showCancelButton: true,
        buttonContent: editMode ? 'Save Changes' : 'Add branch',
        buttonStartIcon: 'Automations',
        onCancelClick: closeOrConfirmClose,
        onChange: (name: string) => {
          setFormVars({
            ...formVars,
            _name: name,
          });
        },
        onButtonClick: () => {
          applyCurrentAssignment();
        },
        placeholder: 'Add branch name',
        value: formVars._name,
        buttonDisabled: !isFormValid || !isAllowedBtn,
        editDisabled: !isAllowedBtn,
        noCreateEditButtons: true,
      }}
      actionsSection={
        <Button
          onClick={applyCurrentAssignment}
          disabled={!isFormValid || !isAllowedBtn}
          startIconName={'Automations'}
        >
          {editMode ? 'Save Changes' : 'Add branch'}
        </Button>
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, paddingBottom: 3 }}>
        <SectionBox>
          <Box
            sx={{
              ...labelBoxDesign,
              background: colors.gum[500],
            }}
          >
            <Typography variant="caption-bold" textAlign="center" color={colors.white}>
              WHEN
            </Typography>
          </Box>
          <Box>
            <Box
              sx={{
                backgroundColor: colors.grey[100],
                paddingTop: '22px',
                borderRadius: '4px',
                '.rbHeader': {
                  padding: 0,
                  margin: 0,
                },
                '.rule-builder-logic-preview': {
                  marginRight: '22px',
                },
              }}
            >
              <CustomHeader customHeader="Those conditions are met" />
              <SweepFieldsRuleBuilder
                filterOperatorBy={
                  isShowWebhookOption ? getOperatorsWithoutRecordsComparison : undefined
                }
                ref={ruleBuilderRef}
                readOnly={!isAllowedBtn}
                sweepCondition={formVars.when?.criteria}
                onChange={_onCriteriaChange}
                crmOrgId={crmOrgId}
                objectType={objectType}
                showFieldContextMenu={true}
                mainSelectorKey={_getSelectorKey}
                hideSelectValuesFromRecord={isShowWebhookOption ? true : undefined}
              />
            </Box>
          </Box>
        </SectionBox>

        <SectionBox>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Box
              sx={{
                ...labelBoxDesign,
                background: colors.sun[500],
              }}
            >
              <Typography variant="caption-bold" textAlign="center" color={colors.white}>
                THEN
              </Typography>
            </Box>
            <AutomationActionPopupMenu
              isClearable={true}
              action={formVars?.actions?.[0]}
              onClearAction={() => clearAction(0)}
              onAddAction={(emptyAction, isBefore) => addAction(emptyAction, isBefore, 0)}
            />
          </Box>

          <AutomationsActionsWrapper
            excludeValueType={excludeValueType}
            isShowWebhookOption={isShowWebhookOption}
            actionsToExclude={_actionsToExclude}
            isHideActionSelector={false}
            onAddAction={addAction}
            onClearAction={clearAction}
            onChange={_onChange}
            objectName={objectType}
            actionList={formVars.actions}
            objectTypes={objectTypes}
            crmOrgId={crmOrgId}
            isActionEnabled={true}
          />
        </SectionBox>
      </Box>
    </CreateEditDialog>
  );
};

const ConditionalActionDialogWithProviders = (props: ConditionalActionDialogProps) => (
  <MultipleRuleBuildersProvider>
    <ConditionalActionDialog {...props} />
  </MultipleRuleBuildersProvider>
);

export { ConditionalActionDialogWithProviders as ConditionalActionDialog };
