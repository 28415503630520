import { DateTime } from 'luxon';
import { useState, useCallback } from 'react';
import {
  AiChatConfigurationPayload,
  AiChatFieldPayload,
} from '../../../../apis/facades/useCrmOrgsApiFacade';
import { AiChatContextType, ChatCompletionRequestMessageRole } from '@server/ai';
import { AiChatMessage } from '../../../common/ai-chat/aiChatTypes';
import usePaywall from '../../../dashboard/paywall/usePaywall';
import { telemetry } from '../../../../telemetry';
import { useAiChat } from '../../../common/ai-chat/useAiChat';
import { uniqueId } from '../../../../lib/uniqueId';
import { useLimitMessageCount } from '../../../common/ai-chat/useLimitMessageCount';

interface OnConfirmBaseProps {
  message: string;
  crmOrgId: string;
}

interface OnConfirmForConfigurationProps extends OnConfirmBaseProps {
  filename: string;
}

interface OnConfirmForFieldsProps extends OnConfirmBaseProps {
  objectName: string;
  fieldName: string;
}

export const useConfigurationChat = () => {
  const { onSendConfigurationMessage, onSendFieldMessage } = useAiChat();
  const { updateMessageCount, hasMoreMessagesLeft, isFreeUser } = useLimitMessageCount();

  const [sessionId] = useState(uniqueId());
  const [messages, setMessages] = useState<AiChatMessage[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const { isPaywallActive } = usePaywall();
  const disableInput = isPaywallActive && messages.length > 0 && !hasMoreMessagesLeft;

  const onConfirmOrgChatForConfiguration = useCallback(
    async ({ message, crmOrgId, filename }: OnConfirmForConfigurationProps) => {
      error && setError('');

      const payload: AiChatConfigurationPayload = {
        message,
        sessionId,
        isFirstMessage: messages.length === 0,
        crmOrgId,
        context: {
          filename,
          type: AiChatContextType.CONFIGURATION,
        },
      };

      const createdAt = DateTime.now().toISO() ?? '';
      const _messages: AiChatMessage[] = [
        ...messages,
        { role: ChatCompletionRequestMessageRole.USER, content: message, createdAt },
      ];
      setMessages(_messages);

      if (isFreeUser && !hasMoreMessagesLeft) {
        setMessages([
          ..._messages,
          {
            role: ChatCompletionRequestMessageRole.ASSISTANT,
            content: 'No questions allowed',
            createdAt,
          },
        ]);
        return;
      }

      setIsLoading(true);

      try {
        const _response = await onSendConfigurationMessage({
          payload,
        });

        if (_response.error) {
          setError(_response.error);
          setIsLoading(false);
          return;
        }

        updateMessageCount();

        const lastResponse = _response.data.pop();
        if (lastResponse) {
          setMessages([..._messages, lastResponse]);
        }
      } catch (e) {
        setError('Something went wrong, please try again later');
        telemetry.captureError(e);
      }

      setIsLoading(false);
    },
    [
      error,
      messages,
      onSendConfigurationMessage,
      isFreeUser,
      hasMoreMessagesLeft,
      updateMessageCount,
      sessionId,
    ],
  );

  const onConfirmOrgChatForFields = useCallback(
    async ({ message, crmOrgId, objectName, fieldName }: OnConfirmForFieldsProps) => {
      error && setError('');
      const createdAt = DateTime.now().toISO() ?? '';
      const _messages: AiChatMessage[] = [
        ...messages,
        { role: ChatCompletionRequestMessageRole.USER, content: message, createdAt },
      ];
      setMessages(_messages);

      if (isFreeUser && !hasMoreMessagesLeft) {
        setMessages([
          ..._messages,
          {
            role: ChatCompletionRequestMessageRole.ASSISTANT,
            content: 'No questions allowed',
            createdAt,
          },
        ]);
        return;
      }

      setIsLoading(true);
      const payload: AiChatFieldPayload = {
        message,
        sessionId,
        context: {
          type: AiChatContextType.FIELD,
          objectName,
          fieldName,
        },
        isFirstMessage: messages.length === 0,
        crmOrgId,
      };

      try {
        const _response = await onSendFieldMessage({
          payload,
        });

        if (_response.error) {
          setError(_response.error);
          setIsLoading(false);
          return;
        }

        updateMessageCount();
        const lastResponse = _response.data.pop();
        if (lastResponse) {
          setMessages([..._messages, lastResponse]);
        }
      } catch (e) {
        setError('Something went wrong, please try again later');
        telemetry.captureError(e);
      }

      setIsLoading(false);
    },
    [
      messages,
      onSendFieldMessage,
      updateMessageCount,
      hasMoreMessagesLeft,
      isFreeUser,
      sessionId,
      error,
    ],
  );

  return {
    isLoading,
    messages,
    error,
    onConfirmOrgChatForFields,
    onConfirmOrgChatForConfiguration,
    disableInput,
  };
};
