import { PayloadAction } from '@reduxjs/toolkit';
import { GlobalState } from './globalReducerTypes';
import { RootState } from '../index';
import { selectDefaultCreationEnvironment } from '../../components/pages/environments/environmentsReducer';
import { DeployStatusForTable } from '../../types/enums/DeployStatusForTable';

type MarketingAttributionModelReducerProps = {
  setMarketingAttributionModels: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      marketingAttribution: AutomationStructureNew[];
    }>,
  ) => any;
  addMarketingAttributionModel: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      automation: AutomationStructureNew;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
  updateMarketingAttributionModel: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      modelToUpdate: AutomationStructureNew;
      versionIdToUpdate: string;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
  toggleMarketingAttributionModel: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      modelToUpdate: AutomationStructureNew;
      versionIdToUpdate: string;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
  deleteMarketingAttributionModel: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      automationIdToDelete: string;
    }>,
  ) => any;
  updateMarketingAttributionModelDeployStatus: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      automationId: string;
      versionId: string;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
};
export const marketingAttributionReducer: MarketingAttributionModelReducerProps = {
  setMarketingAttributionModels: (state, action) => {
    //TEMPORARY function until BE is ready
    const { crmOrgId, marketingAttribution } = action.payload;
    if (!state.environments[crmOrgId]?.data) {
      return;
    }
    state.environments[crmOrgId].data.marketingAttribution = marketingAttribution;
  },
  addMarketingAttributionModel: (state, action) => {
    const { crmOrgId, automation, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data) {
      return;
    }
    if (!state.environments[crmOrgId].data.marketingAttribution) {
      state.environments[crmOrgId].data.marketingAttribution = [];
    }
    state.environments[crmOrgId].data.marketingAttribution?.push({
      ...automation,
      status: optimisticStatus,
    });
  },
  updateMarketingAttributionModel: (state, action) => {
    const { crmOrgId, modelToUpdate, versionIdToUpdate, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data?.marketingAttribution) {
      return;
    }
    const indexToUpdate = state.environments[crmOrgId].data.marketingAttribution?.findIndex(
      (r) => r.automationId === modelToUpdate.automationId && r.versionId === versionIdToUpdate,
    );
    if (indexToUpdate !== undefined) {
      // @ts-ignore
      state.environments[crmOrgId].data.marketingAttribution[indexToUpdate] = {
        ...modelToUpdate,
        status: optimisticStatus,
      };
    }
  },
  deleteMarketingAttributionModel: (state, action) => {
    const { crmOrgId, automationIdToDelete } = action.payload;
    if (!state.environments[crmOrgId]?.data?.marketingAttribution) {
      return;
    }
    const indexToDelete = state.environments[crmOrgId].data.marketingAttribution?.findIndex(
      (r) => r.automationId === automationIdToDelete,
    );
    if (indexToDelete !== undefined) {
      state.environments[crmOrgId].data.marketingAttribution?.splice(indexToDelete, 1);
    }
  },
  toggleMarketingAttributionModel: (state, action) => {
    const { crmOrgId, modelToUpdate, versionIdToUpdate, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data?.marketingAttribution) {
      return;
    }
    const indexToUpdate = state.environments[crmOrgId].data.marketingAttribution?.findIndex(
      (r) => r.automationId === modelToUpdate.automationId && r.versionId === versionIdToUpdate,
    );
    if (indexToUpdate !== undefined) {
      // @ts-ignore
      state.environments[crmOrgId].data.marketingAttribution[indexToUpdate] = {
        ...modelToUpdate,
        status: optimisticStatus,
      };
    }
  },
  updateMarketingAttributionModelDeployStatus: (state, action) => {
    const { crmOrgId, automationId, versionId, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data?.marketingAttribution) {
      return;
    }
    const indexToUpdate = state.environments[crmOrgId].data.marketingAttribution?.findIndex(
      (r) => r.automationId === automationId && r.versionId === versionId,
    );
    if (indexToUpdate !== undefined) {
      // @ts-ignore
      state.environments[crmOrgId].data.marketingAttribution[indexToUpdate].status =
        optimisticStatus;
    }
  },
};

export const selectCrmOrgMarketingAttributionModels = (crmOrgId: string) => (state: RootState) => {
  return state.global.environments[crmOrgId]?.data?.marketingAttribution;
};

export const selectMarketingAttributionModels = (state: RootState) => {
  const crmOrg = selectDefaultCreationEnvironment(state);
  if (crmOrg?.id) {
    return selectCrmOrgMarketingAttributionModels(crmOrg.id)(state);
  }
};
