import { Box, FormControl } from '@mui/material';
import { useCallback } from 'react';
import { isEqual } from 'lodash';
import { colors } from '@sweep-io/sweep-design';
import { SlackConstant } from './slack/utils';
import FieldLabel from '../common/FieldLabel';
import { SlackNewMessageEditor } from './slack/SlackNewMessageEditor';
import { TeamsRecipientsSelect } from './TeamsRecipientsSelect';

interface PushNotificationActionProps {
  readonly?: boolean;
  action: PushNotificationAutomationAction;
  crmOrgId: string;
  onChange: (slackAutomationAction: PushNotificationAutomationAction) => any;
  objectName: string;
}

export const PushNotificationAction = ({
  readonly,
  action,
  crmOrgId,
  objectName,
  onChange,
}: PushNotificationActionProps) => {
  const updateOnChange = useCallback(
    (updateProps: Partial<AutomationPushNotificationActionParams>) => {
      onChange({
        ...action,
        actionParams: {
          ...action.actionParams,
          ...updateProps,
        },
      });
    },
    [action, onChange],
  );

  const handleDeleteChip = useCallback(
    (chipToRemove: SlackRecipientStruct) => {
      const newRecipients = action.actionParams.recipients?.filter((item) => {
        if (
          chipToRemove.type === SlackConstant.Salesforce &&
          item.type === SlackConstant.Salesforce
        ) {
          return !isEqual(
            (item.value as SlackFieldsStruct).fieldIds,
            (chipToRemove.value as SlackFieldsStruct).fieldIds,
          );
        }
        return true;
      });
      updateOnChange({ recipients: newRecipients });
    },
    [action, updateOnChange],
  );

  const _onChange = useCallback(
    (event: any, newValues: SlackRecipientStruct[]) => {
      const newItems = newValues.map((el: SlackRecipientStruct) => {
        switch (el.type) {
          case SlackConstant.Salesforce:
            return {
              ...el,
              type: SlackConstant.Salesforce,
              value: {
                fieldIds: (el?.value as SlackFieldsStruct)?.fieldIds,
              },
            };

          case SlackConstant.Salesforce_Created_Slack_Channel:
            return {
              ...el,
              type: SlackConstant.Salesforce_Created_Slack_Channel,
            };
        }
      });
      updateOnChange({ recipients: newItems as SlackRecipientStruct[] });
    },
    [updateOnChange],
  );

  return (
    <>
      <Box
        sx={{
          background: colors.grey[100],
          padding: '24px 24px 19px',
          borderRadius: '8px',
          margin: '16px 0',
        }}
      >
        <FormControl
          sx={{
            width: '100%',
            marginBottom: '20px',
            maxWidth: '1148px',
          }}
        >
          <Box
            sx={{
              marginBottom: '3px',
              '& div': {
                color: colors.grey[800],
                fontSize: '12px',
                fontWeight: '400',
              },
            }}
          >
            <FieldLabel label={'Recipients'} markLabelAsRequired={true} />
          </Box>

          <TeamsRecipientsSelect
            isAddTextFieldFromSf={true}
            customButtonText={'Add Dynamic Recipient'}
            objectName={objectName}
            crmOrgId={crmOrgId}
            onChange={_onChange}
            readonly={!!readonly}
            recipients={action.actionParams?.recipients ?? []}
            handleDeleteChip={handleDeleteChip}
            notConnected={false}
            hideRecipientsForLgDemo={true}
          />
        </FormControl>

        <Box sx={{ position: 'relative' }}>
          <SlackNewMessageEditor
            isRequired={true}
            label="Message"
            readonly={readonly}
            messageStruct={action.actionParams?.message}
            crmOrgId={crmOrgId}
            onChange={(newMsg) => {
              updateOnChange({ message: newMsg });
            }}
            objectName={objectName}
          />
        </Box>
      </Box>
    </>
  );
};
