import { useReactFlow } from '@xyflow/react';
import { addPlacingTemplate } from '../../../../../reducers/multiFunnelFlowNoHistoryReducer';
import { PanelConsumer } from '../../../../panels/PanelsContext';
import { TemplatesDialog } from '../../../funnel-map-flow/templates/dialog/TemplatesDialog';
import { templatesPanelType } from '../../../funnel-map-flow/templates/dialog/panelTypes';
import { useDispatch } from 'react-redux';
import { TEMPLATES_DIALOG_WIDTH } from '../../../funnel-map-flow/templates/dialog/consts';
import { ConfigurationCanvasLeftPanel } from '../../../configuration-canvas-panel';
import { useConfigurationCanvas } from '../../../configuration-canvas/public/useConfigurationCanvas';

export const CanvasUnitedTemplatesDialog = () => {
  const { canvasCrmOrgId: crmOrgId } = useConfigurationCanvas();
  const dispatch = useDispatch();
  const { getZoom, zoomTo } = useReactFlow();

  return (
    <PanelConsumer panelType={templatesPanelType}>
      {({ maybeClosePanel, isOpen }) => (
        <>
          {isOpen && (
            <ConfigurationCanvasLeftPanel width={TEMPLATES_DIALOG_WIDTH}>
              <TemplatesDialog
                isOpen={isOpen}
                crmOrgId={crmOrgId}
                onAddTemplate={(template, useFieldMapping) => {
                  const zoom = getZoom();
                  if (zoom > 0.3) {
                    zoomTo(0.3, {
                      duration: 1000,
                    });
                  }
                  dispatch(addPlacingTemplate({ template, useFieldMapping }));
                }}
                closeDialog={() => maybeClosePanel()}
              />
            </ConfigurationCanvasLeftPanel>
          )}
        </>
      )}
    </PanelConsumer>
  );
};
