import { Box, TextField } from '@mui/material';
import classNames from 'classnames';
import { useState, useEffect, useCallback } from 'react';

const textValueFromInteger = (value: number | null, zeroAsEmpty?: boolean) =>
  value === null || (zeroAsEmpty && value === 0) ? null : value;

export const IntegerPicker = ({
  value,
  onChange,
  useImmediateUpdate,
  disabled,
  min = Number.NEGATIVE_INFINITY,
  max = Number.POSITIVE_INFINITY,
  placeholder,
  zeroAsEmpty,
  onBlur,
  onFocus,
}: {
  value: number | null;
  onChange: (newInteger: number | null) => any;
  disabled?: boolean;
  min?: number;
  max?: number;
  placeholder?: string;
  zeroAsEmpty?: boolean;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  useImmediateUpdate?: boolean;
}) => {
  const [localValue, setLocalValue] = useState<number | null>(() =>
    textValueFromInteger(value, zeroAsEmpty),
  );

  useEffect(() => {
    setLocalValue(() => textValueFromInteger(value, zeroAsEmpty));
  }, [value, zeroAsEmpty]);

  const affectChanges = useCallback(
    (localValue: number | null) => {
      if (localValue === null) {
        onChange(null);
      } else {
        const newInteger = Math.max(min, Math.min(localValue, max)); // clamp to min/max
        setLocalValue(textValueFromInteger(newInteger, zeroAsEmpty));
        onChange(newInteger);
      }
    },
    [onChange, min, max, zeroAsEmpty],
  );

  return (
    <Box
      className={classNames({ disabled })}
      sx={{ display: 'flex', gap: '12px', alignContent: 'center', alignItems: 'center' }}
    >
      <TextField
        disabled={disabled}
        value={localValue === null ? '' : localValue}
        type="number"
        InputProps={{
          inputProps: {
            min,
            max,
          },
          sx: {
            '&.Mui-disabled': {
              background: 'initial',
            },
            width: 84,
          },
          placeholder,
        }}
        onChange={(e) => {
          const newValue = parseInt(e.target.value, 10);
          if (useImmediateUpdate) {
            affectChanges(newValue);
          } else {
            setLocalValue(isNaN(newValue) ? null : newValue);
          }
        }}
        onBlur={(e) => {
          affectChanges(localValue);
          onBlur?.(e);
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            affectChanges(localValue);
          }
        }}
        onFocus={onFocus}
      />
    </Box>
  );
};
