import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { OrgConfigurationCounts, PotentialIssue } from '@server/homepage';

export interface HomepageState {
  [crmOrgId: string]: {
    isLoading: boolean;
    isError: boolean;
    potentialIssues?: PotentialIssue[];
    orgAnalysisCount?: OrgConfigurationCounts;
  };
}

const initialState: HomepageState = {};

export const homepageSlice = createSlice({
  name: 'homepage',
  initialState,
  reducers: {
    setOrgAnalysisLoader: (
      state,
      action: PayloadAction<{
        isLoading: boolean;
        crmOrgId: string;
      }>,
    ) => {
      const { crmOrgId, isLoading } = action.payload;
      state[crmOrgId] = { potentialIssues: undefined, isLoading, isError: false };
    },
    setOrgAnalysis: (
      state,
      action: PayloadAction<{
        isLoading: boolean;
        crmOrgId: string;
        potentialIssues?: PotentialIssue[];
        orgAnalysisCount?: OrgConfigurationCounts;
        isError?: boolean;
      }>,
    ) => {
      const { crmOrgId, isLoading, potentialIssues, orgAnalysisCount, isError } = action.payload;
      state[crmOrgId] = { potentialIssues, isLoading, orgAnalysisCount, isError: !!isError };
    },
  },
});

export const { setOrgAnalysisLoader, setOrgAnalysis } = homepageSlice.actions;

export const selectPotentialIssuesInCrmOrg = (crmOrgId: string) => (state: RootState) =>
  state.homepage[crmOrgId]?.potentialIssues;

export const selectIsOrgAnalysisLoading = (crmOrgId: string) => (state: RootState) =>
  state.homepage[crmOrgId]?.isLoading;

export const selectIsOrgAnalysisError = (crmOrgId: string) => (state: RootState) =>
  state.homepage[crmOrgId]?.isError;

export const selectOrgAnalysisCounts = (crmOrgId: string) => (state: RootState) =>
  state.homepage[crmOrgId]?.orgAnalysisCount;

export default homepageSlice.reducer;
