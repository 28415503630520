import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useEventListener from '../common/useEventListener';
import useAiAgentCenter from './useAiAgentCenter';
import ProcessAgentCreateDialog from './ProcessAgentCreateDialog';
import { Node as ReactFlowNode } from '@xyflow/react';
import {
  AgentType,
  ProcessOptimizationContextComponent,
} from '@server/ai';
import { useSweepCanvas } from '../sweep-canvas/useSweepCanvas';
import BoxSelectionLayer from '../common/boxSelection/BoxSelectionLayer';
import _groupBy from 'lodash/groupBy';
import { CanvasElementType } from '../sweep-canvas/canvas-types';
import {
  BoxSelectionProvider,
  useBoxSelectionContext,
} from '../common/boxSelection/BoxSelectionContext';
import { useSelector } from 'react-redux';
import { selectFunnelsData } from '../../reducers/united-canvas/sfFunnelReducer';
import useSendBiEvent from '../../hooks/useSendBiEvent';
import { AI_AGENTS_EVENTS } from '../../services/events';
import { Button } from '@sweep-io/sweep-design';
import useCreateAgent from './useCreateAgent';
import { AI_AGENTS_INFO } from './aiAgentsConsts';

const SUPPORTED_NODES_TYPES = [CanvasElementType.REGULAR, CanvasElementType.NURTURING_BUCKET];

const getContextFromNodes = (nodes: ReactFlowNode[]) => {
  const relevantNodes = nodes.filter((node) =>
    SUPPORTED_NODES_TYPES.includes(node.type as CanvasElementType),
  );

  const groupedByFunnelId = _groupBy(relevantNodes, (node) => node.parentId);
  const contextComponents: ProcessOptimizationContextComponent[] = [];
  for (const [funnelId, nodes] of Object.entries(groupedByFunnelId)) {
    contextComponents.push({
      funnelId,
      stagesIds: nodes.map((node) => node.id),
    });
  }
  return contextComponents;
};

const ProcessAgentCreation = () => {
  const [selectedContext, setSelectedContext] = useState<ProcessOptimizationContextComponent[]>([]);
  const [isCreateDialogOpened, setIsCreateDialogOpened] = useState(false);
  const { isCreateAgentActive, setIsAgentCreateActive, toggleIsOpened, isOpened } =
    useAiAgentCenter();

  const { getIntersectingNodes } = useSweepCanvas();
  const funnelsData = useSelector(selectFunnelsData);
  const sendBiEvent = useSendBiEvent();
  const buttonRef = useRef<HTMLButtonElement>();

  const funnelsNamesFromContext = useMemo(() => {
    return selectedContext
      .map((context) => context.funnelId)
      .map((funnelId) => funnelsData[funnelId]?.name)
      .filter((v) => !!v)
      .join(', ');
  }, [selectedContext, funnelsData]);

  const agentNamePlaceholder = `${funnelsNamesFromContext} Optimization`;

  const { selection, resetSelectionBox } = useBoxSelectionContext();

  const selectedNodes = selection ? getIntersectingNodes(selection) : [];

  const onFinishSelection = useCallback(() => {
    sendBiEvent({ name: AI_AGENTS_EVENTS.drawPolygon });
  }, [sendBiEvent]);

  const activateHandler = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    resetSelectionBox?.();

    const context: ProcessOptimizationContextComponent[] = getContextFromNodes(selectedNodes);

    setIsCreateDialogOpened(true);
    setSelectedContext(context);

    sendBiEvent({ name: AI_AGENTS_EVENTS.activateAgent });
  };

  const finish = useCallback(() => {
    setIsAgentCreateActive(false);
    setSelectedContext([]);
  }, [setIsAgentCreateActive]);

  const onConfirmCreate = useCreateAgent(finish);

  useEffect(() => {
    //upon "create agent" activation, close agents panel
    if (isCreateAgentActive) {
      if (isOpened) {
        toggleIsOpened();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateAgentActive]);

  const onEscapeHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (selection && resetSelectionBox) {
          resetSelectionBox();
          return;
        }
        finish();
      }
    },
    [finish, selection, resetSelectionBox],
  );
  useEventListener('keydown', onEscapeHandler);

  const showCursorTooltip = !selection && !isCreateDialogOpened && selectedContext.length === 0;

  return (
    <>
      {isCreateAgentActive && (
        <BoxSelectionLayer
          showCursorTooltip={showCursorTooltip}
          selectedNodesCount={selectedNodes.length}
          onFinishSelection={onFinishSelection}
          buttonElement={buttonRef.current}
          buttonJsx={
            <Button
              ref={buttonRef}
              startIconName="Sparkle"
              endIconName="ArrowRight"
              size="small"
              onClick={activateHandler}
            >
              Activate Agent
            </Button>
          }
        />
      )}

      {isCreateDialogOpened && (
        <ProcessAgentCreateDialog
          onConfirm={(name, instructions) =>
            onConfirmCreate({
              type: AgentType.ProcessOptimization,
              name,
              instructions,
              selectedContext,
              shouldOpenCanvasPanel: true,
            })
          }
          agentNamePlaceholder={agentNamePlaceholder}
          closeDialog={() => {
            setIsCreateDialogOpened(false);
            setSelectedContext([]);
          }}
          instructionsPlaceholder={
            AI_AGENTS_INFO[AgentType.ProcessOptimization].addingFiles?.placeholder ?? ''
          }
        />
      )}
    </>
  );
};

const AiAgentCreationWithProviders = () => (
  <BoxSelectionProvider>
    <ProcessAgentCreation />
  </BoxSelectionProvider>
);

export default AiAgentCreationWithProviders;
