import { Typography } from '@sweep-io/sweep-design';
import { AdvancedFilter } from '../common/advanced-filter/AdvancedFilter';
import { Box } from '@mui/material';
import { PotentialIssue } from '@server/homepage';
import { ReactNode, useMemo } from 'react';
import _uniq from 'lodash/uniq';
import { ConfigurationToName } from '../documentation/dependencies/types';
import { newSearchSx } from '../pages/canvas-pages/canvasPanelConsts';

export type PotentialsIssuesFilters = {
  selectedConfigurationTypes: string[];
  selectedIssues: string[];
  selectedSeverity: string[];
};

const PotentialIssuesFilters = ({
  filters,
  setFilter,
  potentialIssues,
  searchJsx,
}: {
  filters: PotentialsIssuesFilters;
  setFilter: (filter: Partial<PotentialsIssuesFilters>) => void;
  potentialIssues: PotentialIssue[];
  searchJsx: ReactNode;
}) => {
  const { configurationTypesItems, issuesItems, severityItems } = useMemo(() => {
    const uniqueTypes = _uniq(potentialIssues.map((issue) => issue.configurationType));
    const configurationTypesItems = uniqueTypes.map((type) => ({
      value: type,
      label: ConfigurationToName[type],
    }));

    const uniqueIssues = _uniq(potentialIssues.map((issue) => issue.title));
    const issuesItems = uniqueIssues.map((issue) => ({
      value: issue,
      label: issue,
    }));

    const uniqueSeverity = _uniq(potentialIssues.map((issue) => issue.severity));
    const severityItems = uniqueSeverity.map((severity) => ({
      value: severity,
      label: severity,
    }));

    return {
      configurationTypesItems,
      issuesItems,
      severityItems,
    };
  }, [potentialIssues]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...newSearchSx,
      }}
    >
      <Box
        sx={{
          padding: '8px 16px 8px 8px',
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <Box display="flex" gap={0.5} alignItems="center">
          <Typography variant="body">Configuration type</Typography>
          <AdvancedFilter
            items={configurationTypesItems}
            selectedItems={filters.selectedConfigurationTypes}
            onSelectedItemsChange={(items) => setFilter({ selectedConfigurationTypes: items })}
          />
        </Box>
        <Box display="flex" gap={0.5}>
          <Typography variant="body">Issue type</Typography>
          <AdvancedFilter
            items={issuesItems}
            selectedItems={filters.selectedIssues}
            onSelectedItemsChange={(items) => setFilter({ selectedIssues: items })}
          />
        </Box>
        <Box display="flex" gap={0.5}>
          <Typography variant="body">Impact</Typography>
          <AdvancedFilter
            items={severityItems}
            selectedItems={filters.selectedSeverity}
            onSelectedItemsChange={(items) => setFilter({ selectedSeverity: items })}
          />
        </Box>
      </Box>
      <Box width="200px">{searchJsx}</Box>
    </Box>
  );
};

export default PotentialIssuesFilters;
