import { XYPosition } from '@xyflow/react';
import range from 'lodash/range';
import { CanvasEdgeConnectionType } from './canvas-types';
import { RFEdgeFloatingEdge } from './edges';
import { convertXYPositionToGridIndex, CanvasGridPosition } from './helpers/gridPositioningUtils';
import {
  ObjectOperations,
  createObjectOperations,
} from './node-changes-event-handlers/objectOperations';

/**
 * Checks if there are any nodes between two positions in the same row.
 * Used to determine if a connection between nodes needs to detour around other nodes.
 *
 * @param nodesPositions - Array of nodes with their positions
 * @param edgeOperations - ObjectOperations for edges. Passed by reference.
 * @returns edges with updated connectionType
 */

export const calculateAndAssignEdgeConnectionTypes = ({
  nodesPositions,
  edgeOperations,
}: {
  nodesPositions: { id: string; positionAbsolute: XYPosition; parentId?: string }[];
  edgeOperations: ObjectOperations<RFEdgeFloatingEdge>;
}) => {
  const sweepNodesPositionsMap = nodesPositions.reduce(
    (acc, nodePosition) => {
      const { column, row } = convertXYPositionToGridIndex(nodePosition.positionAbsolute);
      acc[`${nodePosition.parentId}|${row}|${column}`] = nodePosition.id;
      return acc;
    },
    {} as { [pos: string]: string },
  );

  const hasHorizontalNodesBetween = (
    position1: CanvasGridPosition,
    position2: CanvasGridPosition,
    nodeId: string,
    parentId?: string,
  ) => {
    const positionsNotOnTheSameRow = position1.row !== position2.row;

    if (positionsNotOnTheSameRow) {
      return false;
    }

    const columnDistance = Math.abs(position1.column - position2.column);
    const areSiblings = columnDistance < 2;

    if (areSiblings) {
      return false;
    }
    const cols = range(
      Math.min(position1.column, position2.column) + 1,
      Math.max(position1.column, position2.column),
    );

    let ret = false;
    cols.forEach((col) => {
      const _id = sweepNodesPositionsMap[`${parentId}|${position1.row}|${col}`];
      if (_id && _id !== nodeId) {
        ret = true;
      }
    });
    return ret;
  };

  const hasVerticalNodesBetween = (
    position1: CanvasGridPosition,
    position2: CanvasGridPosition,
    nodeId: string,
    parentId?: string,
  ) => {
    const positionsNotOnTheSameColumn = position1.column !== position2.column;

    if (positionsNotOnTheSameColumn) {
      return false;
    }

    const rowsDistance = Math.abs(position1.row - position2.row);
    const areSiblings = rowsDistance < 2;

    if (areSiblings) {
      return false;
    }
    const possibleBetweenPositions = range(
      Math.min(position1.row, position2.row) + 1,
      Math.max(position1.row, position2.row),
    );

    const hasNodesInRows = possibleBetweenPositions.find((row) => {
      const _id = sweepNodesPositionsMap[`${parentId}|${row}|${position1.column}`];
      return _id && _id !== nodeId;
    });
    return hasNodesInRows;
  };

  const horizontalDetourIndexMap: { [groupRow: string]: number } = {};
  const verticalDetourIndexMap: { [groupCol: string]: number } = {};

  const { getObject: getNodePosition } = createObjectOperations(nodesPositions);

  for (const edge of edgeOperations.getObjects()) {
    if (!edge.data) {
      continue;
    }
    const reactFlowSourceNode = getNodePosition(edge.source);
    const reactFlowTargetNode = getNodePosition(edge.target);
    if (!reactFlowSourceNode || !reactFlowTargetNode) {
      continue;
    }

    const sourceNodeGridAbsolutePosition = convertXYPositionToGridIndex(
      reactFlowSourceNode.positionAbsolute,
    );
    const targetNodeGridAbsolutePosition = convertXYPositionToGridIndex(
      reactFlowTargetNode.positionAbsolute,
    );

    const _hasHorizontalNodesBetween = hasHorizontalNodesBetween(
      sourceNodeGridAbsolutePosition,
      targetNodeGridAbsolutePosition,
      reactFlowSourceNode.id,
      reactFlowSourceNode.parentId,
    );

    if (_hasHorizontalNodesBetween) {
      const groupRow = `${reactFlowSourceNode.parentId}|${sourceNodeGridAbsolutePosition.row}`;
      const idx =
        horizontalDetourIndexMap[groupRow] === undefined ? -1 : horizontalDetourIndexMap[groupRow];

      horizontalDetourIndexMap[groupRow] = idx + 1;

      edgeOperations.setObject(edge.id, {
        ...edge,
        data: {
          ...edge.data,
          connectionType: CanvasEdgeConnectionType.HorizontalDetour,
          connectionIdx: horizontalDetourIndexMap[groupRow],
        },
      });
    } else {
      const _hasVerticalNodesBetween = hasVerticalNodesBetween(
        sourceNodeGridAbsolutePosition,
        targetNodeGridAbsolutePosition,
        reactFlowSourceNode.id,
        reactFlowSourceNode.parentId,
      );

      if (_hasVerticalNodesBetween) {
        const groupCol = `${reactFlowSourceNode.parentId}|${sourceNodeGridAbsolutePosition.column}`;
        const idx =
          verticalDetourIndexMap[groupCol] === undefined ? -1 : verticalDetourIndexMap[groupCol];

        verticalDetourIndexMap[groupCol] = idx + 1;
        edgeOperations.setObject(edge.id, {
          ...edge,
          data: {
            ...edge.data,
            connectionType: CanvasEdgeConnectionType.VerticalDetour,
            connectionIdx: verticalDetourIndexMap[groupCol],
          },
        });
      } else {
        edgeOperations.setObject(edge.id, {
          ...edge,
          data: {
            ...edge.data,
            connectionType: CanvasEdgeConnectionType.Bezier,
          },
        });
      }
    }
  }

  return edgeOperations.getObjects();
};
