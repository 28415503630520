import { ReactNode } from 'react';
import { ConnectToSfAndHubspot } from './ConnectToSfAndHubspot';
import { HubspotSwitchToProduction } from './HubspotSwitchToProduction';
import { useHubspotUnhappyStates } from '../useHubspotUnhappyStates';

export const HubspotOrgBlockedViews = ({ children }: { children: ReactNode }) => {
  const { isRenderHubspotConnectPage, isRenderHubspotNotInProductionPage } =
    useHubspotUnhappyStates();

  if (isRenderHubspotConnectPage) {
    return <ConnectToSfAndHubspot />;
  }

  if (isRenderHubspotNotInProductionPage) {
    return <HubspotSwitchToProduction />;
  }

  return children;
};
