import { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { StyledCircleIcon, StyledTypography, StyledTextField } from './styledComponents';
import {
  StageMetadataForecastCategory,
  StageMetadataType,
} from '../../../../../types/enums/StageMetadataTypes';
import { checkIsProbabilityValid } from '../../../../../lib/checkIsProbabilityValid';
import deferCallback from '../../../../../lib/deferCallback';

const OpportunityStageFields = ({
  readonly,
  onStageMetadataChange,
  leadingFieldMetadata,
  opportunityTypes,
}: {
  readonly: boolean;
  onStageMetadataChange: (leadingFieldMetadata: StageMetadata) => Promise<boolean>;
  leadingFieldMetadata?: StageMetadata;
  opportunityTypes?: OpportunityType[];
}) => {
  const probability = leadingFieldMetadata?.probability;
  const [localProbability, setLocalProbability] = useState(leadingFieldMetadata?.probability);
  const isProbabilityValid = checkIsProbabilityValid(localProbability);

  //apply locally external changes (e.g. applying metadata from another stage, after rename to an existing stage name)
  useEffect(() => {
    setLocalProbability(probability);
  }, [probability]);

  const forecastCategory = leadingFieldMetadata?.forecastCategory;
  const type = leadingFieldMetadata?.type;
  const chosenType = opportunityTypes?.find((oppType) => oppType.value === type);
  const filteredForecastCategories = chosenType?.forecastCategories;
  const isDisabled = leadingFieldMetadata?.type === StageMetadataType.CLOSED_LOST;

  const onTypeChange = async (event: SelectChangeEvent<HTMLSelectElement>) => {
    const target = event.target;
    const chosenType = target.value as StageMetadataType;
    if (leadingFieldMetadata) {
      switch (chosenType) {
        case StageMetadataType.CLOSED_LOST:
          setLocalProbability(0);
          const isChangeConfirmed = await onStageMetadataChange({
            ...leadingFieldMetadata,
            type: chosenType,
            forecastCategory: StageMetadataForecastCategory.OMITTED,
            probability: 0,
          });
          if (!isChangeConfirmed) {
            setLocalProbability(probability); //revert UI to the original value
          }
          break;

        case StageMetadataType.OPEN:
          onStageMetadataChange({
            ...leadingFieldMetadata,
            type: chosenType,
            forecastCategory: StageMetadataForecastCategory.PIPELINE,
          });
          break;

        case StageMetadataType.CLOSED_WON:
          onStageMetadataChange({
            ...leadingFieldMetadata,
            type: chosenType,
            forecastCategory: StageMetadataForecastCategory.CLOSED,
          });
          break;

        default:
          break;
      }
    }
  };

  if (!opportunityTypes || readonly) {
    return <></>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={4} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
        <StyledTypography variant="body-medium">
          Type <StyledCircleIcon />
        </StyledTypography>

        <FormControl fullWidth>
          <Select onChange={onTypeChange} value={type ?? ''} required>
            {opportunityTypes.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid
        item
        xs={4}
        sx={{
          position: 'relative',
          gap: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <StyledTypography variant="body-medium">
          Probability <StyledCircleIcon />
        </StyledTypography>

        <StyledTextField
          onChange={(event: React.SyntheticEvent) => {
            const target = event.target as HTMLInputElement;
            const value = Number(target.value);
            setLocalProbability(value);
          }}
          // using "deferCallback" to allow executing the "onClick" that triggered the "onBlur"
          // (e.g. click on other tab or on the dialog's "x")
          onBlur={deferCallback(async (event: React.SyntheticEvent) => {
            const target = event.target as HTMLInputElement;
            const value = Number(target.value);
            setLocalProbability(value);
            const isValid = checkIsProbabilityValid(value);
            if (isValid && leadingFieldMetadata) {
              // Adding timeout to allow executing the "onClick" that triggered the "onBlur"
              // (e.g. click on other tab or on the dialog's "x")
              const isChangeConfirmed = await onStageMetadataChange({
                ...leadingFieldMetadata,
                probability: value,
              });
              if (!isChangeConfirmed) {
                setLocalProbability(probability); //revert UI to the original value
              }
            } else {
              setLocalProbability(probability); //revert to the current value
            }
          })}
          type="number"
          value={localProbability ?? 0}
          isWarning={!isProbabilityValid}
          required
        />

        {!isProbabilityValid && (
          <Box
            sx={{
              position: 'absolute',
              top: '90px',
              padding: '16px',
              borderRadius: '4px',
              boxShadow: 6,
              zIndex: 1,
              backgroundColor: 'white',
            }}
          >
            <Typography fontSize="10px">Enter a number between 0 and 100</Typography>
          </Box>
        )}
      </Grid>

      <Grid item xs={4}>
        <FormControl sx={{ width: '100%', gap: 1, display: 'flex', flexDirection: 'column' }}>
          <StyledTypography variant="body-medium">
            Forecast Category <StyledCircleIcon />
          </StyledTypography>

          <Select
            onChange={(event) => {
              const target = event.target as HTMLSelectElement;
              if (leadingFieldMetadata) {
                onStageMetadataChange({
                  ...leadingFieldMetadata,
                  forecastCategory: target.value as StageMetadataForecastCategory,
                });
              }
            }}
            value={forecastCategory ?? ''}
            required
            disabled={isDisabled}
          >
            {filteredForecastCategories?.map((forecastCategory) => (
              <MenuItem key={forecastCategory.value} value={forecastCategory.value}>
                {forecastCategory.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export { OpportunityStageFields };
