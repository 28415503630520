import { Skeleton, Box } from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';

const PADDING = 2;

const FetchProgressBar = ({
  totalWidth,
  progressPercentage,
  withAnnotation,
}: {
  progressPercentage: number;
  totalWidth: number;
  withAnnotation: boolean;
}) => {
  const progressWidth = Math.round(progressPercentage * 0.01 * (totalWidth - 2 * PADDING));
  const annotationLeft = `max(calc(${progressWidth}px - 15px), 1px)`; //"max" is so that the annotation won't be outside the container

  return (
    <Box>
      {withAnnotation && (
        <Box sx={{ position: 'relative', left: annotationLeft, width: 'fit-content' }}>
          <Typography variant="caption" color={colors.grey[700]}>
            {progressPercentage}%
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          position: 'relative',
          height: '10px',
          width: totalWidth + 'px',
          borderRadius: '24px',
          backgroundColor: colors.grey[120],
        }}
      >
        <Skeleton
          variant="rounded"
          width={progressWidth}
          height={6}
          animation="wave"
          sx={{
            position: 'absolute',
            top: PADDING,
            left: PADDING,
            backgroundColor: colors.blue[300],
            border: '1px solid rgba(0, 155, 255, 0.30)',
            ':after': {
              background:
                'linear-gradient(90deg, transparent, rgba(255,255,255, 0.5), transparent)',
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default FetchProgressBar;
