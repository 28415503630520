import { useMediaQuery } from '@mui/material';

const useUnitedCanvasMediaQueries = () => {
  const isBigScreen = useMediaQuery('(min-width:1300px)');
  const isMediumScreen = useMediaQuery('(min-width:1150px)');
  const isSmallScreen = useMediaQuery('(max-width:900px)');

  return {
    isBigScreen,
    isMediumScreen,
    isSmallScreen,
  };
};

export default useUnitedCanvasMediaQueries;
