import { Box, Tooltip } from '@mui/material';
import { ReactNode, useRef, useState } from 'react';
import { useBoxSelection } from './useBoxSelection';
import { useBoxSelectionContext } from './BoxSelectionContext';
import useEventListener from '../useEventListener';

const BOX_BORDER_WIDTH = 1;
const DISABLE_SELECTION_KEY = ' ';

const BoxSelectionLayer = ({
  selectedNodesCount,
  showCursorTooltip,
  buttonElement,
  buttonJsx,
  onFinishSelection,
}: {
  showCursorTooltip: boolean;
  selectedNodesCount: number;
  buttonElement?: HTMLElement;
  buttonJsx: ReactNode;
  onFinishSelection: () => void;
}) => {
  const [disableSelection, setDisableSelection] = useState(false);
  const buttonContainerRef = useRef<HTMLDivElement>();

  const selectContainerRef = useRef<HTMLElement | null>(null);

  useBoxSelection({
    container: selectContainerRef,
    disableListenerCb: (e) => {
      return buttonContainerRef.current?.contains(e.target as Element) ?? false;
    },
    onFinishSelection,
  });

  const { selection, mouseDown } = useBoxSelectionContext();

  const buttonBoundingBox = buttonElement?.getBoundingClientRect() ?? {
    height: 0,
    width: 0,
  };

  const buttonTop = selection
    ? selection.y + selection.height - 2 * buttonBoundingBox.height - 2 * BOX_BORDER_WIDTH
    : 0;
  const buttonLeft = selection
    ? selection.x + selection.width / 2 - buttonBoundingBox.width / 2
    : 0;
  const showButton = selectedNodesCount > 0 && !mouseDown;

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === DISABLE_SELECTION_KEY) {
      setDisableSelection(true);
    }
  };
  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === DISABLE_SELECTION_KEY) {
      setDisableSelection(false);
    }
  };

  useEventListener('keydown', handleKeyDown);
  useEventListener('keyup', handleKeyUp);

  return (
    <Tooltip
      title={
        showCursorTooltip ? (
          <>
            Click and drag to select stages to optimize. <br />
            Press and hold Space to pan/zoom.
          </>
        ) : undefined
      }
      followCursor={true}
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: 'unset',
            width: 'fit-content',
          },
        },
      }}
    >
      <Box
        ref={selectContainerRef}
        sx={{
          cursor: 'crosshair',
          width: '100vw',
          height: '100vh',
          top: 0,
          left: 0,
          position: 'absolute',
          zIndex: 1,
          pointerEvents: disableSelection ? 'none' : 'auto',
        }}
      >
        <Box
          sx={{
            visibility: showButton ? 'visible' : 'hidden',
            position: 'absolute',
            zIndex: 1,
            top: `${buttonTop}px`,
            left: `${buttonLeft}px`,
          }}
          ref={buttonContainerRef}
        >
          {buttonJsx}
        </Box>
      </Box>
    </Tooltip>
  );
};

export default BoxSelectionLayer;
