import { Stack, TextField } from '@mui/material';
import { Typography } from '@sweep-io/sweep-design';

const AgentInstructionsContent = ({
  onChange,
  value,
  placeholder,
}: {
  value: string;
  placeholder: string;
  onChange: (instructions: string) => void;
}) => {
  return (
    <Stack gap={2}>
      <Typography variant="body">
        Add instruction to your AI agent to give it more context about your configuration, ask it to
        focus on certain topics, or to set tone or format for responses.
      </Typography>
      <TextField
        multiline
        fullWidth
        sx={{ textarea: { minHeight: '124px' } }}
        value={value}
        placeholder={placeholder}
        onChange={(event) => onChange(event.target.value)}
      />
    </Stack>
  );
};

export default AgentInstructionsContent;
