import { ReactNode } from 'react';
import ChatWithAgentCollapsed from './ChatWithAgentCollapsed';
import AgentsCardsCollapsed from './AgentsCardsCollapsed';
import useAiAgents from './useAiAgents';
import { AgentType } from '@server/ai';

const AiAgentCenterCollapsed = ({
  closeButton,
  onNewAgentClick,
}: {
  closeButton: ReactNode;
  onNewAgentClick: (type: AgentType) => void;
}) => {
  const { activeAgent } = useAiAgents();

  return (
    <>
      {activeAgent && <ChatWithAgentCollapsed closeButton={closeButton} />}
      {!activeAgent && (
        <AgentsCardsCollapsed closeButton={closeButton} onNewAgentClick={onNewAgentClick} />
      )}
    </>
  );
};

export default AiAgentCenterCollapsed;
