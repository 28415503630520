import { styled } from '@mui/material/styles';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip placement="bottom-start" {...props} classes={{ popper: className }} />
))({
  '.MuiTooltip-popper': {
    backgroundColor: 'transparent',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.grey[900],
    color: colors.white,
    boxShadow: 6,
    fontSize: '12px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    lineHeight: 1.2,
    padding: '8px',
    borderRadius: '4px',
    overflow: 'hidden',
  },
});

export default StyledTooltip;
