import { useFlags } from 'launchdarkly-react-client-sdk';
import useQueryParams from '../../hooks/useQueryParams';

export const useFeatureToggle = () => {
  const query = useQueryParams();

  const debugTools = query.has('debugTools');

  // Please remove feature toggles no longer in use when merging an experiment
  // This list should have all currently used feature toggle flags.
  const {
    reportsComingSoonTag,
    orgFactoryReset,
    sweepSupportChat,
    priorValueSlackMessages,
    salesActivatedTrial,
    assignmentGroupRoles,
    webhooks,
    hubspotV2Ft2,
    hubspotV2Ft3,
    webhookActivity,
    runApex,
    sfLoginHistory,
    postToMsTeamsAction,
    lgDemo,
    thirdPartyFunnelConnections,
    newEmptyStates,
    homepageForTrialUsers,
    marketingAttributionModels,
    impactAnalysisAiAgent,
    aiAgentCenter,
    monitoringAiAgent,
    documentationAiAgent,
    automationsVersionHistory,
  } = useFlags();

  return {
    debugTools,
    reportsComingSoonTag,
    orgFactoryReset,
    sweepSupportChat,
    priorValueSlackMessages,
    salesActivatedTrial, //AKA "paywallV2"
    assignmentGroupRoles,
    webhooks,
    hubspotV2Ft2,
    hubspotV2Ft3,
    webhookActivity,
    runApex,
    sfLoginHistory,
    postToMsTeamsAction,
    lgDemo,
    thirdPartyFunnelConnections,
    newEmptyStates,
    homepageForTrialUsers,
    impactAnalysisAiAgent,
    aiAgentCenter,
    monitoringAiAgent,
    marketingAttributionModels,
    documentationAiAgent,
    automationsVersionHistory,
  };
};
