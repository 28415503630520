import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { AutomationDialogContent } from '../Automations/AutomationDialogContent';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { useSelector } from 'react-redux';
import { selectDefaultCreationEnvironment } from '../pages/environments/environmentsReducer';
import useAutomationApiPerType from '../Automations/useAutomationApiPerType';
import { automationsSortOptions } from '../pages/canvas-pages/consts';
import { selectScheduledReports } from '../../reducers/global/scheduledReportReducers';
import { AutomationScheduledReportsContextProvider } from './AutomationScheduledReportsContextProvider';
import { WithConfigurationCanvas } from '../pages/configuration-canvas/WithConfigurationCanvas';
import useAutomationsCanvasInteractions from '../pages/canvas-pages/useAutomationsCanvasInteractions';
import useEntitiesToHighlight from '../pages/canvas-pages/useEntitiesToHighlight';
import { VisibilityMap } from '../../types/VisibilityTypes';
import { VisibilityElementMap } from '../pages/configuration-canvas/types';
import { VisibilityLayers } from '../../types/enums/VisibilityLayers';

const alertsPageVisibilityMap: Partial<VisibilityMap> = {
  [VisibilityLayers.NURTURING_STEPS]: true,
  [VisibilityLayers.COMMENTS]: false,
};
const visibilityElements: VisibilityElementMap = {
  type: 'map',
  visibilityMap: alertsPageVisibilityMap,
};

const ScheduledReports = ({
  recordTypesData,
  funnelsData,
  disableCanvasTemplates,
  setHoveredItem,
}: {
  recordTypesData: RecordTypesData;
  funnelsData: FunnelsData;
  disableCanvasTemplates?: boolean;
  setHoveredItem?: (automationId?: string | undefined) => void;
}) => {
  const automationsVariant = AutomationFormVariant.SCHEDULED_REPORTS;

  const scheduledReports = useSelector(selectScheduledReports);
  const crmOrg = useSelector(selectDefaultCreationEnvironment);
  const { onSaveOrCreate, onToggleActivation, onDeleteAutomation, onDeployAutomation } =
    useAutomationApiPerType({ automationVariant: automationsVariant });
  const { onEntityClick } = useAutomationsCanvasInteractions();
  const { entitiesToHighlight } = useEntitiesToHighlight(automationsVariant);
  if (!scheduledReports) return <CenteredCircularProgress />;

  return (
    <>
      {crmOrg && (
        <WithConfigurationCanvas
          visibilityElements={visibilityElements}
          onFunnelStepClick={onEntityClick}
          onRecordTypeStepClick={onEntityClick}
          onFunnelLabelPillClick={onEntityClick}
          onRecordTypeLabelPillClick={onEntityClick}
          highlightEntities={entitiesToHighlight}
        >
          <AutomationScheduledReportsContextProvider>
            <AutomationDialogContent
              automations={scheduledReports ?? []}
              onDeleteAutomation={onDeleteAutomation}
              crmOrgId={crmOrg.id}
              onSaveOrCreate={onSaveOrCreate}
              recordTypesData={recordTypesData}
              funnelsData={funnelsData}
              onToggleActivation={onToggleActivation}
              sortOptions={automationsSortOptions}
              disableCanvasTemplates={disableCanvasTemplates}
              setHoveredItem={setHoveredItem}
              onDeployAutomation={onDeployAutomation}
              filtersForExpandedMode={{}} //filters don't exist also in "canvas view"
            />
          </AutomationScheduledReportsContextProvider>
        </WithConfigurationCanvas>
      )}
    </>
  );
};

export default ScheduledReports;
