import { useCallback } from 'react';
import { useSweepApi } from '../sweep';
import { Homepage } from '@server/homepage';

export const useHomepageApiFacade = () => {
  const sweepApi = useSweepApi();

  return {
    get_homepageData: useCallback(async () => {
      const { data } = await sweepApi.get<Homepage | { status: 'pending' }>(`/homepage`);
      return data;
    }, [sweepApi]),
  };
};
