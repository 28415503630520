import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import {
  AutomationsContextProvider,
  AutomationsContextType,
} from '../Automations/AutomationsContext';
import { AutomationType } from '../../types/enums/AutomationType';
import { UI_EVENTS } from '../../services/events';
import DrawerEmptyImage from '../common/empty-state/svgs/drawerEmpty.svg?react';
import { useAutomationsApiFacade } from '../../apis/facades/useAutomationsApiFacade';
import { useMemo } from 'react';

export const AutomationScheduledReportsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { patch_alerts_admin_notification } = useAutomationsApiFacade();
  const contextValue: AutomationsContextType = useMemo(() => {
    return {
      automationType: AutomationType.ScheduledReport,
      permissions: {
        create: 'create:alerts',
        edit: 'edit:alerts',
        deploy: 'deploy:alerts',
        delete: 'delete:alerts',
        notifyAdmin: 'edit:automations:notify-admin',
      },
      emptyStateTitle: 'Create your first Scheduled report',
      emptyStateImage: <DrawerEmptyImage />,
      defaultName: 'New Scheduled Report',
      placeholder: 'New Scheduled Report',
      nameInMessage: 'scheduled reports',
      nameInMessageSingular: 'scheduled report',
      openEvent: UI_EVENTS.scheduledReportsOpen,
      automationVariant: AutomationFormVariant.SCHEDULED_REPORTS,
      templateSupport: false,
      requestDeployNotifyAdmin: patch_alerts_admin_notification,
      impactAnalysisAiSupport: false,
    };
  }, [patch_alerts_admin_notification]);

  return <AutomationsContextProvider value={contextValue}>{children}</AutomationsContextProvider>;
};
