import { AutomationType } from '../../../../../types/enums/AutomationType';
import { AutomationTypes } from '../../../../../types/enums/AutomationTypes';
import { MarketingAttributionModelType } from '../../../../../types/enums/MarketingAttributionModelType';
import { DeployStatusForTable } from '../../../../../types/enums/DeployStatusForTable';
import { uniqueId } from '../../../../../lib/uniqueId';

export const getAttributionModelTemplates = ({
  stages,
}: {
  stages: { _stageId: string; stageName: string }[];
}): Partial<AutomationStructureNew>[] => {
  const stage = stages[0];
  return [
    {
      name: 'First Touch Attribution Model',
      objectName: 'Opportunity',
      type: AutomationType.MarketingAttributionModel,
      automationDetails: {
        automationParams: {
          stageEnter: {
            toStage: stage,
          },
        },
        actions: [
          {
            _id: uniqueId(),
            actionType: 'REVENUE_ATTRIBUTION',
            actionParams: {
              modelType: MarketingAttributionModelType.FIRST_TOUCH,
              campaignFilter: {
                criteria: [],
                criteriaLogic: '',
                isCriteria: false,
              },
            },
          },
        ],
        triggerType: AutomationTypes.StageEnter,
      },
      status: DeployStatusForTable.Draft,
      isActive: true,
      priority: 0,
      notifyAdmin: false,
    },
    {
      name: 'Last Touch Attribution Model',
      objectName: 'Opportunity',
      type: AutomationType.MarketingAttributionModel,
      automationDetails: {
        automationParams: {
          stageEnter: {
            toStage: stage,
          },
        },
        actions: [
          {
            _id: uniqueId(),
            actionType: 'REVENUE_ATTRIBUTION',
            actionParams: {
              modelType: MarketingAttributionModelType.LAST_TOUCH,
              campaignFilter: {
                criteria: [],
                criteriaLogic: 'AND',
                isCriteria: false,
              },
            },
          },
        ],
        triggerType: AutomationTypes.StageEnter,
      },
      status: DeployStatusForTable.Draft,
      isActive: true,
      priority: 0,
      notifyAdmin: false,
    },
    {
      name: 'Multi Touch Attribution Model',
      objectName: 'Opportunity',
      type: AutomationType.MarketingAttributionModel,
      automationDetails: {
        automationParams: {
          stageEnter: {
            toStage: stage,
          },
        },
        actions: [
          {
            _id: uniqueId(),
            actionType: 'REVENUE_ATTRIBUTION',
            actionParams: {
              modelType: MarketingAttributionModelType.MULTI_TOUCH,
              campaignFilter: {
                criteria: [],
                criteriaLogic: 'AND',
                isCriteria: false,
              },
              weights: {
                firstTouch: 0.33,
                lastTouch: 0.33,
                rest: 0.34,
              },
            },
          },
        ],
        triggerType: AutomationTypes.StageEnter,
      },
      status: DeployStatusForTable.Draft,
      isActive: true,
      priority: 0,
      notifyAdmin: false,
    },
    {
      name: 'Even Distribution Attribution Model',
      objectName: 'Opportunity',
      type: AutomationType.MarketingAttributionModel,
      automationDetails: {
        automationParams: {
          stageEnter: {
            toStage: stage,
          },
        },
        actions: [
          {
            _id: uniqueId(),
            actionType: 'REVENUE_ATTRIBUTION',
            actionParams: {
              modelType: MarketingAttributionModelType.EVEN_DISTRIBUTION,
              campaignFilter: {
                criteria: [],
                criteriaLogic: 'AND',
                isCriteria: false,
              },
            },
          },
        ],
        triggerType: AutomationTypes.StageEnter,
      },
      status: DeployStatusForTable.Draft,
      isActive: true,
      priority: 0,
      notifyAdmin: false,
    },
  ];
};
