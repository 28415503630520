import { Box, ButtonBase, Grid } from '@mui/material';
import { NestedFieldsSelector } from '../../common/fieldsSelectors/NestedFieldsSelector';
import { SelectorValueTypes } from '../../../types/enums/SelectorValueTypes';
import { colors, Typography } from '@sweep-io/sweep-design';
import { Edit } from '@sweep-io/sweep-design/dist/icons';
import FormulaEditor from '../FormulaEditor';
import { useFormulaEditorState } from '../FormulaEditorStateContext';
import { SweepFieldTypes } from '../../../types/enums/SweepFieldTypes';
import { parseSweepFieldPolymorphicID } from '../../common/fieldsSelectors/nestedFieldsSelectorHelper';
import { TEXT_FIELD_TYPES } from '../../../types/ReferencedValuesTypes';
import { useSweepFieldsLabels } from '../../../sweep-fields/useSweepFieldsLabels';
import { AutomationActionRowProps } from './types';
import { NestedBox } from './NestedBox';
import { newEmptyField } from './utils/getNewEmptyField';
import { VerifyURLSelect } from './selectors/VerifyUrlSelect';
import { RelativeSelect } from './selectors/RelativeSelect';
import { LiteralSelect } from './selectors/LiteralSelect';
import { selectFirstValueTypeOption } from './utils/selectFirstValueTypeOption';
import { TypeSelect } from './selectors/TypeSelect';
import { MandatoryStar } from './MandatoryStar';
import { AutomationActionRowButtons } from './AutomationActionRowButtons';
import { useValueFromAnotherObjectEditorState } from '../ValueFromAnotherObjectEditorStateContext';
import ValueFromAnotherObjectEditor from '../ValueFromAnotherObjectEditor';

export const AutomationActionRow = ({
  initialObjectName,
  objectName,
  field,
  onChange,
  onDelete,
  onAdd,
  crmOrgId,
  readonly,
  hideRecordTypeIdField,
  disableLookupItems,
  showCreatableFields,
  excludeValueType,
  showVerifiedUrlOption,
  isShowWebhookOption,
}: AutomationActionRowProps) => {
  const { isValueFromAnotherObjectEditorOpenedPerKey, setValueFromAnotherObjectEditorStatePerKey } =
    useValueFromAnotherObjectEditorState();

  const { setFormulaEditorStatePerKey, isFormulaEditorOpenedPerKey } = useFormulaEditorState();
  const _field = field || newEmptyField();

  const isFormulaEditorOpened =
    isFormulaEditorOpenedPerKey && isFormulaEditorOpenedPerKey(_field._id);

  const isValueFromAnotherObjectEditorOpen =
    isValueFromAnotherObjectEditorOpenedPerKey &&
    isValueFromAnotherObjectEditorOpenedPerKey(_field._id);

  //when the formula editor or valueFromAnotherObject is opened, don't show row action buttons
  const showActionButtons =
    _field.valueType === SelectorValueTypes.FORMULA
      ? !isFormulaEditorOpened
      : _field.valueType === SelectorValueTypes.FROM_ANOTHER_OBJECT
        ? !isValueFromAnotherObjectEditorOpen
        : true;

  const filterRecordTypeIdFields = (field: SweepField) =>
    hideRecordTypeIdField ? field.sweepFieldName !== 'Record Type ID' : true;

  const filterNonUpdatableFields = (field: SweepField) => {
    // if field is resolvable return it
    const { isResolvable } = parseSweepFieldPolymorphicID(field.id || '');

    // TODO: SWEEP-2091| Remove isException
    const isException =
      field.properties.apiName?.endsWith('.OwnerId') ||
      field.properties.apiName?.endsWith('.RecordTypeId');

    if (showCreatableFields)
      return (
        isException ||
        field.properties?.updateable !== false ||
        field.properties?.createable === true
      );
    else return isResolvable || isException || field.properties?.updateable !== false;
  }; //typo comes from the original record

  const filterBy = (field: SweepField) =>
    filterRecordTypeIdFields(field) && filterNonUpdatableFields(field) && !field.rollup; //Rollup value is automatically calculated so can't be set here

  // show lookup types as selectable values only for text type fields.
  const filterByEndSelectionRestrictLookupIfNotText = (field: SweepField) => {
    const { isResolvable } = parseSweepFieldPolymorphicID(field.id || '');
    const originalFieldType = _field.fieldType;
    const givenFieldType = field.fieldType;
    if (
      !isResolvable &&
      !TEXT_FIELD_TYPES.includes(originalFieldType as SweepFieldTypes) &&
      originalFieldType !== SweepFieldTypes.Lookup &&
      originalFieldType !== SweepFieldTypes.Id &&
      originalFieldType !== SweepFieldTypes.Hierarchy &&
      originalFieldType !== SweepFieldTypes.MasterDetail &&
      (givenFieldType === SweepFieldTypes.Lookup ||
        givenFieldType === SweepFieldTypes.Id ||
        givenFieldType === SweepFieldTypes.Hierarchy ||
        givenFieldType === SweepFieldTypes.MasterDetail)
    )
      return false;
    return true;
  };

  const { getEnrichedNestedPath } = useSweepFieldsLabels();

  const renderSelector = () => {
    switch (_field.valueType) {
      case SelectorValueTypes.RELATIVE:
        return <RelativeSelect _field={_field} onChange={onChange} readonly={readonly} />;

      case SelectorValueTypes.VERIFY_URL:
        return (
          <VerifyURLSelect
            _field={_field}
            onChange={onChange}
            readonly={readonly}
            showVerifiedUrlOption={showVerifiedUrlOption}
          />
        );

      case SelectorValueTypes.REFERENCE:
      case SelectorValueTypes.PRIOR:
        return (
          <>
            <Box
              sx={{
                border: `1px solid ${colors.grey[300]}`,
                borderRadius: '4px',
                backgroundColor: colors.white,
                '& .MuiButtonBase-root.MuiButton-root': {
                  justifyContent: 'normal',
                  '& span.MuiButton-endIcon': {
                    marginLeft: 'auto',
                  },
                },
              }}
            >
              <NestedBox>
                <NestedFieldsSelector
                  readonly={readonly}
                  crmOrgId={crmOrgId}
                  objectType={initialObjectName || ''}
                  nestedPath={_field.value ? (_field.value as any) : []}
                  startIcon={
                    <Box
                      sx={{
                        fontSize: '14px !important',
                        marginRight: '-6px',
                      }}
                    >
                      [{initialObjectName}].
                    </Box>
                  }
                  onChange={(_sweepField) => {
                    onChange({
                      ..._field,
                      value: _sweepField.fullPath as any,
                    });
                  }}
                  isReferencedValue
                  disableLookupItemsResolve={_field.valueType === SelectorValueTypes.PRIOR}
                  parentFieldType={_field.fieldType as SweepFieldTypes}
                  filterBy={filterByEndSelectionRestrictLookupIfNotText}
                />
              </NestedBox>
            </Box>
          </>
        );

      case SelectorValueTypes.LITERAL:
      case SelectorValueTypes.WEBHOOK_RESULT:
      case undefined:
        return (
          <LiteralSelect
            crmOrgId={crmOrgId}
            _field={_field}
            onChange={onChange}
            readonly={readonly}
            _valueType={_field.valueType ?? SelectorValueTypes.LITERAL}
          />
        );

      case SelectorValueTypes.FORMULA:
        if (!isFormulaEditorOpened) {
          return (
            <ButtonBase
              sx={{ width: '100%' }}
              onClick={() => {
                setFormulaEditorStatePerKey &&
                  setFormulaEditorStatePerKey({ key: _field._id, value: true });
              }}
            >
              <Box
                display="flex"
                gap={1.5}
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: '100%',
                  border: `1px solid ${colors.grey[300]}`,
                  borderRadius: '4px',
                  padding: '9px 12px',
                  backgroundColor: colors.white,
                }}
              >
                <Typography ellipsis variant="body">
                  {_field.value}
                </Typography>
                <Box display="flex" alignItems="center">
                  <Edit />
                </Box>
              </Box>
            </ButtonBase>
          );
        }

        return <></>;
      case SelectorValueTypes.FROM_ANOTHER_OBJECT:
        if (!isValueFromAnotherObjectEditorOpen) {
          return (
            <ButtonBase
              sx={{ width: '100%' }}
              onClick={() => {
                setValueFromAnotherObjectEditorStatePerKey &&
                  setValueFromAnotherObjectEditorStatePerKey({ key: _field._id, value: true });
              }}
            >
              <Box
                display="flex"
                gap={1.5}
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: '100%',
                  border: `1px solid ${colors.grey[300]}`,
                  borderRadius: '4px',
                  padding: '9px 12px',
                  backgroundColor: colors.white,
                }}
              >
                <Typography ellipsis variant="body">
                  {`Value from ${(_field?.value as ValueFromAnotherObject)?.objectName?.label ?? 'another object'} record`}
                </Typography>
                <Box display="flex" alignItems="center">
                  <Edit />
                </Box>
              </Box>
            </ButtonBase>
          );
        }

        return <></>;
    }
  };

  const renderFormulaEditor = () => {
    return (
      <FormulaEditor
        crmOrgId={crmOrgId}
        objectName={initialObjectName ?? ''}
        initialValue={_field.value as string}
        closeEditor={(value?: string) => {
          if (!value && !_field.value) {
            selectFirstValueTypeOption({ onChange, _field });
          }
          setFormulaEditorStatePerKey &&
            setFormulaEditorStatePerKey({ key: _field._id, value: false });
        }}
        updateFormula={({ value, valueFieldIdsArray }) => {
          onChange({
            ..._field,
            value: value || '',
            valueFieldIdsArray,
          });
        }}
      />
    );
  };

  const renderValueFromAnotherObjectEditor = () => {
    return (
      <ValueFromAnotherObjectEditor
        initialFieldType={_field.fieldType as SweepFieldTypes}
        crmOrgId={crmOrgId}
        initialObjectName={initialObjectName ?? ''}
        initialValue={_field.value as ValueFromAnotherObject}
        closeEditor={(value?: ValueFromAnotherObject) => {
          if (
            !value &&
            (!(_field?.value as ValueFromAnotherObject)?.objectName ||
              !(_field?.value as ValueFromAnotherObject)?.fieldIds)
          ) {
            selectFirstValueTypeOption({ onChange, _field });
          }
          setValueFromAnotherObjectEditorStatePerKey &&
            setValueFromAnotherObjectEditorStatePerKey({ key: _field._id, value: false });
        }}
        onChange={(val: ValueFromAnotherObject) => {
          onChange({
            ..._field,
            value: val,
          });
        }}
      />
    );
  };

  return (
    <Box sx={{ position: 'relative' }}>
      {_field._isMandatory && <MandatoryStar />}

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Grid
          container
          flexWrap="nowrap"
          sx={{
            alignItems: 'center',
            gap: '8px',
            mr: '12px',
          }}
        >
          <Grid item xs={4} zeroMinWidth>
            <Box
              sx={{
                border: `1px solid ${colors.grey[300]}`,
                borderRadius: '4px',
                background: colors.white,
              }}
            >
              <NestedBox>
                <NestedFieldsSelector
                  filterBy={filterBy}
                  disableLookupItemsResolve={disableLookupItems}
                  crmOrgId={crmOrgId}
                  objectType={objectName || ''}
                  nestedPath={getEnrichedNestedPath(_field._fieldIds)}
                  readonly={_field._isMandatory || readonly}
                  onChange={(_sweepField) => {
                    onChange({
                      _fieldInfo: _sweepField.sweepField,
                      _fieldIds: _sweepField.fieldIds,
                      fieldType: _sweepField.fieldType,
                      _id: _field._id,
                      valueType: SelectorValueTypes.LITERAL,
                      value: '',
                    });
                  }}
                />
              </NestedBox>
            </Box>
          </Grid>

          <Grid item xs={3} zeroMinWidth>
            <TypeSelect
              _field={_field}
              onChange={onChange}
              crmOrgId={crmOrgId}
              setFormulaEditorStatePerKey={setFormulaEditorStatePerKey}
              setValueFromAnotherObjectEditorStatePerKey={
                setValueFromAnotherObjectEditorStatePerKey
              }
              excludeValueType={excludeValueType}
              readonly={readonly}
              showVerifiedUrlOption={showVerifiedUrlOption}
              isShowWebhookOption={isShowWebhookOption}
            />
          </Grid>

          <Grid item xs={5} zeroMinWidth>
            {renderSelector()}
          </Grid>
        </Grid>

        {!readonly && showActionButtons && (
          <AutomationActionRowButtons
            isFieldUndefined={field === undefined}
            _field={_field}
            onAdd={onAdd}
            onDelete={onDelete}
          />
        )}
      </Box>

      {_field.valueType === SelectorValueTypes.FORMULA && isFormulaEditorOpened && (
        <Box mt={1}>{renderFormulaEditor()}</Box>
      )}
      {_field.valueType === SelectorValueTypes.FROM_ANOTHER_OBJECT &&
        isValueFromAnotherObjectEditorOpen && (
          <Box mt={1}>{renderValueFromAnotherObjectEditor()}</Box>
        )}
    </Box>
  );
};
