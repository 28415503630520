import useSendBiEvent from '../../hooks/useSendBiEvent';
import { useAutomationsContext } from './AutomationsContext';
import { useRunOnce } from '../common/useRunOnce';

const useSendOpenAutomationEvent = () => {
  const sendBiEvent = useSendBiEvent();
  const dataByVariant = useAutomationsContext();
  const { automationJson } = dataByVariant;

  const isEdit = !!automationJson.automationId;
  const isTemplate = !isEdit && !!automationJson.automationDetails?.triggerType;
  const eventName = dataByVariant.openEvent;
  const props = {
    type: isEdit ? 'edit' : isTemplate ? 'new_template' : 'new_blank',
  };

  useRunOnce(() => {
    sendBiEvent({ name: eventName, props });
  });
};

export default useSendOpenAutomationEvent;
