import { useLocation } from 'react-router';
import { useSweepApi } from '../../../apis/sweep';
import { useRunOnce } from '../../common/useRunOnce';
import { getSlackStateToken } from '../../../utils/slackOAuthStateUtils';
import { telemetry } from '../../../telemetry';

const SlackOAuth2Redirect = () => {
  const { search } = useLocation();
  const sweepApi = useSweepApi();

  const setSlackOauthCode = async (authCode: string) => {
    await sweepApi.post(`/data-integrations/slack/oauth2-code`, {
      authCode,
    });
  };

  useRunOnce(async () => {
    const urlSearchParams = new URLSearchParams(search);
    const state = urlSearchParams.get('state');
    const code = urlSearchParams.get('code');
    const storedState = getSlackStateToken();

    if (state && storedState === state && code) {
      try {
        await setSlackOauthCode(code);
      } catch (error) {
        telemetry.captureError(error);
      }
    }
    window.close();
  });

  return <></>;
};

export { SlackOAuth2Redirect };
