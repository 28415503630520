import { Box, MenuItem } from '@mui/material';
import {
  Shuffle as ShuffleIcon,
  Warning as WarningIcon,
  Info as InfoIcon,
} from '@sweep-io/sweep-design/dist/icons';
import { BasePluginPanelProps, BasePluginPanel } from '../../panel/BasePluginPanel';
import { CriteriaPluginSection, PluginField } from './types';

import {
  CollapsiblePanel,
  CollapsiblePanelContent,
  CollapsiblePanelTitle,
} from '../../templates-tab/CollapsiblePanel';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DataTable__deprecated } from '../../../../../common/table/DataTable__deprecated';
import SweepSelect from '../../../../../common/SweepSelect';
import { Tooltip, Typography, colors } from '@sweep-io/sweep-design';
import { isEqual, keyBy } from 'lodash';
import { useSweepFieldsLabels } from '../../../../../../sweep-fields/useSweepFieldsLabels';
import { useRunOnce } from '../../../../../common/useRunOnce';
import { CenteredCircularProgress } from '../../../../../common/CenteredCircularProgress';
import { uniqueId } from '../../../../../../lib/uniqueId';
import { useSweepFields } from '../../../../../../sweep-fields/useCachedSweepFields';
import { ANNOTATION_DEFAULTS } from './fieldCreationDefaults';
import { useErrorHandling } from '../../../../../../hooks/useErrorHandling';
import { PluginFieldsMapTable } from './PluginFieldsMapTable';
import { DataTableVariant } from '../../../../../common/table/TableTypes';
import { setSfFieldName } from '../../../../rollups/rollupHelpers';

type PluginFieldWithSweepField = PluginField & { sweepFieldId: string };

interface BaseCriteriaPluginPanelProps
  extends Omit<BasePluginPanelProps, 'children' | 'showButton' | 'onConfirm'> {
  plugin: CriteriaPlugin;
  onChange: (plugin: CriteriaPlugin) => void;
  pluginTemplateSections: CriteriaPluginSection[];
  stepList: { id: string; name: string }[];
  objectType: string;
  crmOrgId: string;
  setIsDirty: (isDirty: boolean) => void;
}

const CollapsibleSection = ({
  title,
  children,
  icon,
  onTransitionEnd,
  defaultIsOpen = false,
}: {
  title: string;
  children: React.ReactNode;
  icon: React.ReactNode;
  onTransitionEnd?: React.TransitionEventHandler<HTMLElement>;
  defaultIsOpen?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  return (
    <CollapsiblePanel
      expanded={isOpen}
      onChange={(expanded) => {
        setIsOpen(!expanded);
      }}
      onTransitionEnd={onTransitionEnd}
      enableHeaderClick
    >
      <CollapsiblePanelTitle>
        <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
          {icon}
          <Typography variant="body-bold" color={colors.grey[800]}>
            {title}
          </Typography>
        </Box>
      </CollapsiblePanelTitle>
      <CollapsiblePanelContent>{children}</CollapsiblePanelContent>
    </CollapsiblePanel>
  );
};

const columns = [
  {
    field: 'fieldName',
    headerName: 'Name',
  },
  {
    field: 'apiName',
    headerName: 'API name',
  },
  {
    field: 'type',
    headerName: 'Type',
  },
  {
    field: 'addToStep',
    headerName: 'Add to step',
  },
];

export const BaseCriteriaPluginPanel = ({
  plugin,
  pluginTemplateSections,
  stepList,
  onChange,
  crmOrgId,
  objectType,
  setIsDirty,
  ...basePluginProps
}: BaseCriteriaPluginPanelProps) => {
  const { getLabelInfoFromId } = useSweepFieldsLabels();
  const [loading, setLoading] = useState(true);
  const [pluginFieldsWithErrors, setPluginFieldsWithErrors] = useState<PluginFieldWithSweepField[]>(
    [],
  );

  const pluginFields = pluginTemplateSections.map((section) => section.pluginFields).flat();

  const { createField, getSweepFields, getSweepFieldIdsByName } = useSweepFields();

  const [pluginFieldToStageIdMap, setPluginFieldToStageIdMap] = useState<
    Record<string, string | undefined>
  >(() =>
    Object.fromEntries(
      pluginFields.map(({ pluginFieldId }) => [
        pluginFieldId,
        plugin.criterionMap?.find(({ id }) => pluginFieldId === id)?.stageId,
      ]),
    ),
  );

  const [initialPluginFieldToStageIdMap] = useState(pluginFieldToStageIdMap);
  const [fieldsMap, setFieldsMap] = useState<CriterionPluginFieldMap[]>(plugin.fieldMapping);
  const [initialFieldsMap, setInitialFieldsMap] = useState(fieldsMap);

  useRunOnce(async () => {
    // 1: Find empty template apiNames not mapped to plugin fieldsMap
    // 2: Retrieve fieldIds from apiNames
    // 3: Add to plugin fieldsMap

    const getFieldByApiName = async (apiName: string) => {
      try {
        // TODO: We need a bulk function for this
        const ret = await getSweepFieldIdsByName({
          crmOrgId,
          fieldNames: [apiName],
        });

        return ret.fieldIds.flat().at(0);
      } catch (e) {
        return undefined;
      }
    };

    const emptyFieldMaps = pluginFields.filter(({ pluginFieldId: id }) => {
      return !fieldsMap.find(({ fromTemplateFieldId }) => fromTemplateFieldId === id);
    });

    const exitingFieldIds = await Promise.all(
      emptyFieldMaps.map(async (pluginField) => {
        const sweepFieldId = await getFieldByApiName(`${objectType}.${pluginField.apiName}`);
        if (!sweepFieldId) {
          return;
        }
        return {
          ...pluginField,
          sweepFieldId,
        } as PluginFieldWithSweepField;
      }),
    );

    const filteredExitingFields = exitingFieldIds.filter(
      (field) => field !== undefined,
    ) as PluginFieldWithSweepField[];

    const sweepFields = await getSweepFields({
      crmOrgId,
      fieldIds: filteredExitingFields.map(({ sweepFieldId: fieldId }) => fieldId),
      includeSiblings: false,
    });

    // Maps existing fields and filters by the same type
    const exitingFieldIdsByFieldId = keyBy(exitingFieldIds, 'sweepFieldId');

    const sweepFieldsFlatMap = sweepFields.sweepFields.map(({ fields }) => fields).flat();

    const _sweepFields = sweepFieldsFlatMap.filter(
      ({ fieldType, id = '' }) => exitingFieldIdsByFieldId[id]?.type === fieldType,
    ); // Product question:  What happens if an field already exists but it is not of the same type?

    // Finds sweepFields with the same name but different type
    // These fields are invalid and the user needs to map a different field

    const _sweepFieldsErrors = sweepFieldsFlatMap
      .filter(
        ({ fieldType, id = '' }) =>
          exitingFieldIdsByFieldId[id] && exitingFieldIdsByFieldId[id]?.type !== fieldType,
      )
      .map(({ id = '' }) => exitingFieldIdsByFieldId[id]) as PluginFieldWithSweepField[];

    setPluginFieldsWithErrors(_sweepFieldsErrors);

    // Finds existing fields and assigns them to the field mapping
    setFieldsMap((prev) => {
      const newFieldsMap = [...prev];
      _sweepFields.forEach(({ id }) => {
        if (id && exitingFieldIdsByFieldId[id]) {
          newFieldsMap.push({
            fromTemplateFieldId: exitingFieldIdsByFieldId[id]?.pluginFieldId as string, // We now it exists
            toSweepFieldId: id,
          });
        }
      });

      setInitialFieldsMap(newFieldsMap);
      return newFieldsMap;
    });

    setLoading(false);
  });

  useEffect(() => {
    if (
      isEqual(fieldsMap, initialFieldsMap) &&
      isEqual(pluginFieldToStageIdMap, initialPluginFieldToStageIdMap)
    ) {
      setIsDirty(false);
    } else {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fieldsMap,
    initialFieldsMap,
    initialPluginFieldToStageIdMap /*, setIsDirty */,
    pluginFieldToStageIdMap,
  ]);

  const fieldsMapById = useMemo(() => keyBy(fieldsMap, 'fromTemplateFieldId'), [fieldsMap]);

  const setStepMapping = (id: string, stepId: string) => {
    setPluginFieldToStageIdMap((prev) => ({ ...prev, [id]: stepId }));
  };

  const { errorHandlingBuilder } = useErrorHandling();

  const _onDone = useCallback(async () => {
    const newPlugin: CriteriaPlugin = { ...plugin, fieldMapping: fieldsMap };

    const stepMaps = pluginTemplateSections
      .map(({ pluginFields: stepMaps }) => {
        return stepMaps.map(
          ({
            pluginFieldId: id,
            type,
            apiName,
            description,
            fieldName,
            lookupFieldProps,
            picklistValueSet,
          }) => {
            return {
              id,
              stageId: pluginFieldToStageIdMap[id] || '',
              type,
              apiName,
              description,
              fieldName,
              lookupFieldProps,
              picklistValueSet,
            };
          },
        );
      })
      .flat();

    // Find not mapped fields
    const notMappedFields = stepMaps.filter(({ id }) => {
      return fieldsMapById[id] === undefined;
    });

    // Create new fields that were not mapped
    const createdFields = await Promise.all(
      notMappedFields.map(
        async ({
          id,
          type,
          apiName,
          description,
          lookupFieldProps,
          picklistValueSet,
          fieldName,
        }) => {
          const lookupProperties = lookupFieldProps
            ? {
                referenceTo: [
                  {
                    elemID: {
                      adapter: 'salesforce',
                      typeName: lookupFieldProps.lookupObjectName,
                      idType: 'type',
                      nameParts: [],
                      fullName: `salesforce.${lookupFieldProps.lookupObjectName}`,
                    },
                  },
                ],
                relationshipName: lookupFieldProps.lookupRelationshipName,
              }
            : undefined;

          const valueSet = picklistValueSet ? { valueSet: picklistValueSet } : undefined;

          const field = await createField({
            crmOrgId,
            draft: true,
            field: {
              fieldType: type,
              description,
              objectName: objectType,
              isRequired: false,
              sfFieldName: setSfFieldName(apiName, objectType),
              sweepFieldName: fieldName,
              properties: { ...ANNOTATION_DEFAULTS[type], ...lookupProperties, ...valueSet },
            },
          });
          return { id, field };
        },
      ),
    );

    createdFields.forEach(({ id, field }) => {
      if (field?.id) {
        fieldsMapById[id] = {
          fromTemplateFieldId: id,
          toSweepFieldId: field.id,
        };
      }
    });

    newPlugin.criterionMap = stepMaps.map(({ id, stageId, type }) => {
      return {
        id,
        stageId,
        criterion: {
          _fieldIds: [fieldsMapById[id]?.toSweepFieldId || ''],
          criterionId: uniqueId(),
          fieldType: type,
          operator: 'IS_NULL',
          value: 'false',
          valueType: 'Literal',
        },
      };
    });

    onChange(newPlugin);
  }, [
    createField,
    crmOrgId,
    fieldsMap,
    fieldsMapById,
    objectType,
    onChange,
    plugin,
    pluginTemplateSections,
    pluginFieldToStageIdMap,
  ]);

  const pluginFieldError = useCallback(
    (pluginField: PluginField) => {
      const fieldHasError = pluginFieldsWithErrors.find(
        ({ pluginFieldId }) => pluginFieldId === pluginField.pluginFieldId,
      );

      if (fieldHasError && fieldsMapById[fieldHasError.pluginFieldId]?.toSweepFieldId === undefined)
        return fieldHasError;
    },
    [fieldsMapById, pluginFieldsWithErrors],
  );

  const isValid = useMemo(() => {
    const hasSomeMissingStepIds = Object.values(pluginFieldToStageIdMap).some(
      (stepId) => stepId === undefined,
    );

    if (hasSomeMissingStepIds) {
      return false;
    }
    const pluginFieldsHasErrors = pluginFields.some((pluginField) => pluginFieldError(pluginField));

    return !pluginFieldsHasErrors;
  }, [pluginFieldError, pluginFieldToStageIdMap, pluginFields]);

  const getRows = (section: CriteriaPluginSection) => {
    return section.pluginFields.map((pluginField) => {
      let { fieldName, apiName } = pluginField;

      if (fieldsMapById[pluginField.pluginFieldId]) {
        const labelInfo = getLabelInfoFromId(
          fieldsMapById[pluginField.pluginFieldId].toSweepFieldId,
        );
        fieldName = labelInfo?.sweepFieldName || fieldName;
        apiName = labelInfo?.sfFieldName || apiName;
        apiName = apiName.replace(/^.+\./, '');
      }

      let error;
      if (pluginFieldError(pluginField)) {
        error = (
          <Box sx={{ display: 'flex', gap: '5px' }}>
            <Typography variant="caption">{fieldName}</Typography>
            <Tooltip title="You already have a field with the same name but a conflicting type. Please ensure that you remap the field to a different one with the correct type.">
              <WarningIcon color={colors.blush[600]} />
            </Tooltip>
          </Box>
        );
      }

      let fieldNameWithInfo;
      if (
        fieldsMap.some(
          ({ fromTemplateFieldId }) => fromTemplateFieldId === pluginField.pluginFieldId,
        )
      ) {
        fieldNameWithInfo = (
          <Box sx={{ display: 'flex', gap: '5px' }}>
            <Typography variant="caption">{fieldName}</Typography>
            <Tooltip title="We are utilizing an existing field from your organization with the same name.">
              <InfoIcon color={colors.grey[800]} />
            </Tooltip>
          </Box>
        );
      }

      return {
        id: pluginField.pluginFieldId,
        fieldName: error || fieldNameWithInfo || fieldName,
        apiName,
        type: pluginField.type,
        addToStep: (
          <SweepSelect
            key={pluginField.pluginFieldId}
            FormControlProps={{ fullWidth: true }}
            SelectProps={{
              onChange: (e) => {
                setStepMapping(pluginField.pluginFieldId, e.target.value as string);
              },
              value: pluginFieldToStageIdMap[pluginField.pluginFieldId] || '',
              placeholder: 'Choose step',
            }}
          >
            {stepList.map((step) => {
              return (
                <MenuItem key={step.id} value={step.id}>
                  {step.name}
                </MenuItem>
              );
            })}
          </SweepSelect>
        ),
      };
    });
  };

  const [, forceUpdate] = useState({});

  return (
    <BasePluginPanel
      {...basePluginProps}
      headerButton={{
        onConfirm: () => {
          setLoading(true);
          errorHandlingBuilder()
            .withErrorNotification('Error setting plugin')
            .withFinally(() => setLoading(false))
            .execute(_onDone);
        },
        disabled: !isValid,
      }}
    >
      {loading && (
        <Box
          sx={{
            display: 'flex',
            minHeight: '300px',
            width: '760px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CenteredCircularProgress />
        </Box>
      )}
      {!loading && (
        <Box
          sx={{
            marginTop: '12px',
            width: '760px',
            display: 'flex',
            gap: '12px',
            flexDirection: 'column',
          }}
        >
          {pluginTemplateSections.map((section, idx) => {
            return (
              <CollapsibleSection
                title={section.title}
                icon={section.icon}
                key={section.title}
                onTransitionEnd={() => {
                  forceUpdate({});
                }}
                defaultIsOpen={idx === 0}
              >
                <Box
                  sx={{
                    '.MuiTableBody-root .MuiTableCell-root': {
                      borderBottom: 'none',
                    },
                  }}
                >
                  <DataTable__deprecated
                    columns={columns}
                    rows={getRows(section)}
                    variant={DataTableVariant.narrow}
                    rowTypographyVariant="caption"
                  />
                </Box>
              </CollapsibleSection>
            );
          })}
          <Box marginTop="4px">
            <Typography variant="body-bold">Advanced Settings</Typography>
          </Box>
          <CollapsibleSection title={'field mapping'} icon={<ShuffleIcon />}>
            <PluginFieldsMapTable
              crmOrgId={crmOrgId}
              objectType={objectType}
              fieldsMap={fieldsMap}
              stepMaps={pluginTemplateSections.map(({ pluginFields: stepMaps }) => stepMaps).flat()}
              onChange={(fieldsMap) => {
                setFieldsMap(fieldsMap);
              }}
            />
          </CollapsibleSection>
        </Box>
      )}
    </BasePluginPanel>
  );
};
