import { AutomationDialog } from '../../Automations/AutomationDialog';
import useAutomationApiPerType from '../../Automations/useAutomationApiPerType';
import { MultipleRuleBuildersProvider } from '../../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';
import { useConfigurationCanvas } from '../configuration-canvas/public/useConfigurationCanvas';
import { useAutomationsContext } from '../../Automations/AutomationsContext';

export const ConfigurationCanvasAutomationsPanel = ({
  automations,
  onClose,
  disableCanvasTemplates,
  excludeAutomationsNotInFunnelMapObjects,
}: {
  automations?: AutomationStructureNew[];
  onClose?: () => void;
  disableCanvasTemplates?: boolean;
  excludeAutomationsNotInFunnelMapObjects?: boolean;
}) => {
  const { automationVariant } = useAutomationsContext();
  const { canvasFunnelMap, canvasCrmOrgId } = useConfigurationCanvas();
  const { onSaveOrCreate, onToggleActivation, onDeleteAutomation, onDeployAutomation } =
    useAutomationApiPerType({ automationVariant });

  return (
    <MultipleRuleBuildersProvider>
      <AutomationDialog
        isLoading={!automations}
        onSaveOrCreate={onSaveOrCreate}
        onToggleActivation={onToggleActivation}
        automations={automations ?? []}
        crmOrgId={canvasCrmOrgId}
        funnelMap={canvasFunnelMap}
        onDeleteAutomation={onDeleteAutomation}
        automationVariant={automationVariant}
        onDeployAutomation={onDeployAutomation}
        onClose={onClose}
        disableCanvasTemplates={disableCanvasTemplates}
        excludeAutomationsNotInFunnelMapObjects={excludeAutomationsNotInFunnelMapObjects}
      />
    </MultipleRuleBuildersProvider>
  );
};
