import Stack from '@mui/material/Stack';
import { colors, Typography } from '@sweep-io/sweep-design';

interface ContentWithImageProps {
  imgSrc: string;
  imgAlt: string;
  title: string;
  content: string;
  imgPosition: 'right' | 'left';
  pt?: string;
}

export const ContentWithImage = ({
  imgAlt,
  imgSrc,
  title,
  content,
  imgPosition,
  pt,
}: ContentWithImageProps) => {
  const Img = <img src={imgSrc} alt={imgAlt} style={{ height: '100%', width: '436px' }} />;
  return (
    <Stack pt={pt ?? '64px'} direction="row" width="1080px" justifyContent="space-between">
      {imgPosition === 'left' && Img}
      <Stack pl={imgPosition === 'right' ? 0 : '104px'} pr="116px" gap={3} justifyContent="center">
        <Typography variant="h1-bold" color={colors.night[900]}>
          {title}
        </Typography>
        <Typography variant="body" color={colors.night[900]}>
          {content}
        </Typography>
      </Stack>
      {imgPosition === 'right' && Img}
    </Stack>
  );
};
