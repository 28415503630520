import userInfoReducer from './userInfoReducer';
import notificationsReducer from './notificationsReducer';
import deploymentsReducer from './deploymentsReducer';
import snapshotsReducer from './snapshotsReducer';
import canvasLayersReducer, { canvasLayers } from './canvasLayersReducer';
import reportsReducer from './reportsReducer';
import assignmentGroupsPageReducer from './assignmentGroupsPageReducer';
import integrationsReducer from './integrationsReducer';
import multiFunnelFlowPageReducer from './multiFunnelFlowNoHistoryReducer';
import unitedCanvasReducer from './united-canvas/unitedCanvasReducer';
import commentsReducer from './commentsReducer';
import accountUsersReducer from './accountUsersReducer';
import stageDialogReducer from './funnel-flow-page/stageDialogReducer';
import newFunnelDialogReducer from './newFunnelReducer';
import { undoable } from './undoable';
import funnelMapsReducer from './funnelMapsReducer';
import { deploymentChangesUi } from './deploymentChangesUiReducer';
import { requestChangesApi } from './requestChangesApiReducer';
import { notificationsCenter } from './notificationsCenterReducers';
import sfChangeFeedReducer from './sfChangeFeedReducer';
import { crmOrgUsersSlice } from './crmOrgUsersReducer';
import documentationReducer from './documentationReducer';
import sweepFieldsReducer, {
  sweepFieldsLabelsSlice,
} from '../sweep-fields/sweepFieldsLabelsReducer';
import { importFunnelSlice } from './importFunnelReducer';
import { hubspotSlice } from './hubspotReducer';
import { templatesDialogSlice } from '../components/pages/funnel-map-flow/templates/dialog/templatesDialogReducer';
import { templateFieldsMappingSlice } from '../components/pages/funnel-map-flow/templates/templates-tab/templateFieldsMappingReducer';
import { devOpsCenterSlice } from './devOpsCenterReducer';
import { globalSlice } from './global/globalReducer';
import { canvasPillsSlice } from '../components/canvas-pills/canvasPillsReducer';
import { leftPanelSlice } from './leftPanelReducer';
import { funnelSettingsDialogSlice } from '../components/pages/funnel-map-flow/dialogs/funnel-settings-dialog/funnelSettingsReducer';
import { rollupsPageSlice } from './rollupsPageReducer';
import { slackSlice } from './slackReducer';
import { roleGroupsSlice } from './roleGroupsReducer';
import { tagsSlice } from './tagsReducer';
import { combineReducers } from 'redux';
import { aiAgentCenterSlice } from '../components/aiAgentCenter/aiAgentCenterReducer';
import { aiAgentsSlice } from '../components/aiAgentCenter/aiAgentsReducer';
import { aiChatsSlice } from '../components/aiAgentCenter/aiChatsReducer';
import { canvasFiltersSlice } from '../components/pages/configuration-canvas-filters/canvasFiltersReducer';
import environmentsReducer from '../components/pages/environments/environmentsReducer';
import { thirdPartyFunnelsSlice } from '../components/third-party-funnels/thirdPartyFunnelsReducer';
import { connectOrgSlice } from './connectOrgReducer';
import debugInfoReducer from './debugInfoReducer';
import fieldsReducer from './funnel-flow-page/fieldsReducer';
import { homepageSlice } from './homepageReducer';
import { sfLoginHistorySlice } from './loginHistoryReducer';
import territoriesReducer from './territoriesReducer';

const rootReducer = combineReducers({
  [canvasLayers.name]: canvasLayersReducer,
  environments: environmentsReducer,
  debugInfo: debugInfoReducer,
  userInfo: userInfoReducer,
  notifications: notificationsReducer,
  deployments: deploymentsReducer,
  snapshots: snapshotsReducer,
  reports: reportsReducer,
  assignmentGroupsPage: assignmentGroupsPageReducer,
  integrations: integrationsReducer,
  multiFunnelFlowPage: multiFunnelFlowPageReducer,
  unitedCanvas: unitedCanvasReducer,
  comments: undoable(commentsReducer),
  accountUsers: accountUsersReducer,
  stageDialog: stageDialogReducer,
  fieldsManagement: fieldsReducer,
  newFunnelDialog: newFunnelDialogReducer,
  funnelMaps: funnelMapsReducer,
  territories: territoriesReducer,
  sfChangeFeed: sfChangeFeedReducer,
  documentation: documentationReducer,
  [requestChangesApi.name]: requestChangesApi.reducer,
  [deploymentChangesUi.name]: deploymentChangesUi.reducer,
  [notificationsCenter.name]: notificationsCenter.reducer,
  [crmOrgUsersSlice.name]: crmOrgUsersSlice.reducer,
  [templatesDialogSlice.name]: templatesDialogSlice.reducer,
  [templateFieldsMappingSlice.name]: templateFieldsMappingSlice.reducer,
  [sweepFieldsLabelsSlice.name]: sweepFieldsReducer,
  [importFunnelSlice.name]: importFunnelSlice.reducer,
  [hubspotSlice.name]: hubspotSlice.reducer,
  [devOpsCenterSlice.name]: devOpsCenterSlice.reducer,
  [globalSlice.name]: globalSlice.reducer,
  [canvasPillsSlice.name]: canvasPillsSlice.reducer,
  [canvasFiltersSlice.name]: canvasFiltersSlice.reducer,
  [leftPanelSlice.name]: leftPanelSlice.reducer,
  [funnelSettingsDialogSlice.name]: funnelSettingsDialogSlice.reducer,
  [rollupsPageSlice.name]: rollupsPageSlice.reducer,
  [slackSlice.name]: slackSlice.reducer,
  [roleGroupsSlice.name]: roleGroupsSlice.reducer,
  [tagsSlice.name]: tagsSlice.reducer,
  [thirdPartyFunnelsSlice.name]: thirdPartyFunnelsSlice.reducer,
  [aiChatsSlice.name]: aiChatsSlice.reducer,
  [aiAgentsSlice.name]: aiAgentsSlice.reducer,
  [aiAgentCenterSlice.name]: aiAgentCenterSlice.reducer,
  [connectOrgSlice.name]: connectOrgSlice.reducer,
  [homepageSlice.name]: homepageSlice.reducer,
  [sfLoginHistorySlice.name]: sfLoginHistorySlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
