import { XYPosition } from '@xyflow/react';
import { GroupBoundingBox } from '../canvas-types/nodeTypesData';

export const calculateGroupLabelPosition = (
  groupPosition: XYPosition,
  groupBoundingBox: GroupBoundingBox,
): XYPosition => {
  const position = groupPosition;
  return {
    x: position.x + groupBoundingBox.marginLeft,
    y: position.y + groupBoundingBox.marginTop,
  };
};
