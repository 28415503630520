import { useConfigurationCanvas } from '../pages/configuration-canvas/public/useConfigurationCanvas';
import { RoutingTabs, useRoutingTabs } from './useRoutingTabs';
import { useRef } from 'react';
import AssignmentsTemplatesDialog from './AssignmentsTemplatesDialog';
import {
  SingleAutomationDialog,
  SingleAutomationDialogRef,
} from '../Automations/SingleAutomationDialog';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import useAutomationApiPerType from '../Automations/useAutomationApiPerType';
import { RoutingTabsInfo } from './routingTabsInfo';
import { useSelector } from 'react-redux';
import ScheduledAssignmentsEmptyStateDialog from './ScheduledAssignmentsEmptyStateDialog';
import { useAutomationsContext } from '../Automations/AutomationsContext';

const useIsEmptyState = (routingTab?: RoutingTabs) => {
  const routeInfo = routingTab ? RoutingTabsInfo[routingTab] : undefined;
  const { selector } = routeInfo ?? {};
  const items = useSelector(selector ?? (() => undefined));
  return items?.length === 0;
};

interface RoutingAutomationsContextWrapperProps {
  goToRoutingHome: () => void;
  isExpanded: boolean;
}

export const RoutingAutomationsContextWrapper = ({
  goToRoutingHome,
  isExpanded,
}: RoutingAutomationsContextWrapperProps) => {
  const { appCrmOrgId } = useConfigurationCanvas();
  const { routingTab } = useRoutingTabs();
  const { automationJson, setAutomationJson, automationVariant } = useAutomationsContext();
  const isAutomationInEdit = Object.keys(automationJson)?.length > 0;
  const SingleAutomationDialogRef = useRef<SingleAutomationDialogRef>(null);
  const isEmptyState = useIsEmptyState(routingTab);
  const disableOpenAnimation = !!routingTab;
  const transitionDuration = disableOpenAnimation ? 0 : undefined;
  const showEmptyStateDialog = isEmptyState && !isAutomationInEdit;

  const { onSaveOrCreate } = useAutomationApiPerType({
    automationVariant,
  });

  const closeSingleAutomationDialog = () => {
    setAutomationJson({});
  };

  const renderSingleAutomationDialog = () => {
    return (
      <SingleAutomationDialog
        ref={SingleAutomationDialogRef}
        isOpen={isAutomationInEdit}
        onSaveOrCreate={async (automationJson, crmOrgIds) => {
          const res = await onSaveOrCreate(automationJson, crmOrgIds, false);
          if (res?.isError) {
            return { isError: true };
          }
          closeSingleAutomationDialog();
          return res;
        }}
        onCancel={closeSingleAutomationDialog}
        crmOrgId={appCrmOrgId}
        objectName={automationJson?.objectName ?? ''}
        isShowWideScreenPopup={isExpanded}
      />
    );
  };

  const assignmentsChildren = (
    <>
      {showEmptyStateDialog && !isExpanded && (
        <AssignmentsTemplatesDialog
          title={`Create your first Assignment`}
          closeDialog={goToRoutingHome}
          setAutomationInEdit={(automation: Partial<AutomationStructureNew>) => {
            setAutomationJson(automation);
          }}
          crmOrgId={appCrmOrgId}
          transitionDuration={transitionDuration}
        />
      )}
      {isAutomationInEdit && renderSingleAutomationDialog()}
    </>
  );

  const scheduledAssignmentsChildren = (
    <>
      {showEmptyStateDialog && !isExpanded && (
        <ScheduledAssignmentsEmptyStateDialog
          closeDialog={goToRoutingHome}
          transitionDuration={transitionDuration}
          onCreate={(object: string) => setAutomationJson({ objectName: object })}
          crmOrgId={appCrmOrgId}
        />
      )}
      {isAutomationInEdit && renderSingleAutomationDialog()}
    </>
  );

  return (
    <>
      {automationVariant === AutomationFormVariant.ASSIGNMENT_RULES
        ? assignmentsChildren
        : scheduledAssignmentsChildren}
    </>
  );
};
