// FileName: nodePositionManagementHelper.ts - Helper functions for manipulating node positions and transformations in the sweep canvas
import { RfNodeRegularNode } from '../canvas-types/nodeTypesData';
import { XYPosition } from '@xyflow/react';
import { convertXYPositionToGridPosition } from './gridPositioningUtils';
import { SWEEP_GRID_SIZE } from '../const';

interface StepsRightFuncProps {
  startingNode: RfNodeRegularNode;
  nodes: RfNodeRegularNode[];
  includeOwn?: boolean;
}

export type NodePositionTransformation = {
  id: string;
  position: XYPosition;
};

const getNodesOnTheRightOf = ({
  startingNode,
  nodes,
  includeOwn,
}: StepsRightFuncProps): NodePositionTransformation[] => {
  const startingNodeGridPosition = convertXYPositionToGridPosition(startingNode.position);

  const relativeColumn = includeOwn ? 0 : 1;
  const startingColumn = startingNodeGridPosition.column + relativeColumn;

  const startingNodeHasImmediatelyRightNode = () =>
    nodes.find(
      (_node) =>
        convertXYPositionToGridPosition(_node.position).column ===
          startingNodeGridPosition.column + 1 && _node.parentId === startingNode.parentId,
    );

  const nodesToMove =
    includeOwn || startingNodeHasImmediatelyRightNode()
      ? nodes.filter((_node) => {
          const { row, column } = convertXYPositionToGridPosition(_node.position);
          return (
            row === startingNodeGridPosition.row &&
            column >= startingColumn &&
            _node.parentId === startingNode.parentId
          );
        })
      : [];

  const nodePositionTransformations: NodePositionTransformation[] = nodesToMove.map((nt) => ({
    id: nt.id,
    position: nt.position,
  }));

  return nodePositionTransformations;
};

export const shiftNodesRight = (props: StepsRightFuncProps): NodePositionTransformation[] => {
  const nodePositionTransformations = getNodesOnTheRightOf(props).map((nt) => ({
    id: nt.id,
    position: {
      x: nt.position.x + SWEEP_GRID_SIZE.width,
      y: nt.position.y,
    },
  }));

  return nodePositionTransformations;
};
