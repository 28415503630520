import { Box, Divider, FormControl, IconButton, Popper } from '@mui/material';
import { useRef, useState } from 'react';
import {
  NestedFieldsSelector,
  NestedFieldsField,
} from '../../common/fieldsSelectors/NestedFieldsSelector';
import FieldLabel from '../../common/FieldLabel';
import { Plus as PlusIcon } from '@sweep-io/sweep-design/dist/icons';
import { SmileyIcon } from '../../../icons/generalIcons';
import data from '@emoji-mart/data';
import { init } from 'emoji-mart';
import Picker from '@emoji-mart/react';
import { colors } from '@sweep-io/sweep-design';
import { useSweepFieldsLabels } from '../../../sweep-fields/useSweepFieldsLabels';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import React from 'react';
import { FieldContext } from '../../../types/enums/FieldContext';
import { useFeatureToggle } from '../../common/useFeatureToggle';

interface SlackNewMessageEditorProps {
  readonly?: boolean;
  messageStruct: SlackMessageStruct;
  crmOrgId: string;
  onChange: (messageStruct: SlackMessageStruct) => any;
  objectName: string;
  label: string;
  isRequired?: boolean;
  hideEmoji?: boolean;
  infoTooltipTitle?: string;
  customOnKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => any;
  placeholder?: string;
  isHideDynamicField?: boolean;
}

const modules = {
  toolbar: false,
};

const stripEmojis = (str: string) =>
  str
    .replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      '',
    )
    .replace(/\s+/g, ' ');

const convertHtmlToValidString = (content: string) => {
  const parser = new DOMParser();
  const document = parser.parseFromString(content, 'text/html');

  let constructedString = '';

  const loopThroughHtmlBuildString = (node: ChildNode, index: number) => {
    if (node.nodeName === '#text') {
      const data = node.nodeValue ?? '';
      return stripEmojis(data);
    }
    if (node.nodeName === 'EM-EMOJI') {
      return (node as Element).getAttribute('shortcodes');
    }
    if (node.nodeName === 'SPAN' && (node as Element).className === 'sfField') {
      return `{{{${(node as Element).getAttribute('chipId')}}}}`;
    }
    if (node.nodeName === 'P' && index !== 0) {
      return '\n';
    }
    return '';
  };

  const getData = (el: ChildNode, index: number) => {
    constructedString += loopThroughHtmlBuildString(el, index);
    if (el.hasChildNodes() && (el as Element).className !== 'sfField') {
      el.childNodes.forEach((innerEl) => {
        getData(innerEl, index);
      });
    }
  };
  document.body.childNodes.forEach((el, index) => {
    getData(el, index);
  });
  return constructedString;
};

const Embed = ReactQuill.Quill.import('blots/embed');
class EmojiBlot extends Embed {
  static create(emojis: any) {
    const node = super.create();
    node.setAttribute('shortcodes', emojis);
    return node;
  }

  static formats(node: any) {
    return node.getAttribute('shortcodes');
  }

  static value(node: any) {
    return node.getAttribute('shortcodes');
  }
}
EmojiBlot.blotName = 'emoji';
EmojiBlot.tagName = 'em-emoji';
ReactQuill.Quill.register(EmojiBlot, true);

const convertStringToValidHtml = (outerContent: string) => {
  if (!outerContent) return '';

  const getResults = (content: string) => {
    let tempString = '<p>';

    const splitArray = content
      .split(/({{{\d}}}|:[^\s:]+(?:::skin-tone-[2-6])?:)/g)
      .filter((x) => x.trim().length !== 0);

    splitArray.forEach((innerNode: string) => {
      if (innerNode.startsWith(':') && innerNode.endsWith(':')) {
        tempString += `<em-emoji shortcodes=${innerNode}></em-emoji>`;
      } else if (innerNode.startsWith('{{{')) {
        const tagNum = innerNode.match(/\d+/)?.[0];
        const elem = `<span class="sfField" chipId=${tagNum}></span>`;
        tempString += elem;
      } else {
        tempString += innerNode;
      }
    });
    return (tempString += ' </p>');
  };

  let multilineStr = '';
  const firstSplit = outerContent.split(/\n/g);
  firstSplit.forEach((el) => {
    const splitResults = getResults(el);
    return (multilineStr += splitResults);
  });

  return multilineStr;
};

export const SlackNewMessageEditor = ({
  readonly,
  messageStruct,
  crmOrgId,
  onChange,
  objectName,
  label,
  isRequired,
  hideEmoji,
  infoTooltipTitle,
  customOnKeyDown,
  placeholder,
  isHideDynamicField,
}: SlackNewMessageEditorProps) => {
  const { priorValueSlackMessages: priorValueSlackMessagesFt } = useFeatureToggle();
  const { getLabelFromIdsWithObjectType } = useSweepFieldsLabels();
  const [actionMessage, setActionMessage] = useState<SlackMessageStruct>(messageStruct);
  const [value, setValue] = useState(convertStringToValidHtml(messageStruct?.template));
  const quillRef = useRef() as React.MutableRefObject<ReactQuill>;
  const [blockApiChangeEvents, setBlockApiChangeEvents] = useState(true);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isEmojiVisible, setEmojiVisible] = useState(false);
  init({ data });

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    customOnKeyDown && customOnKeyDown(event);
  };

  const onOuterBlur = () => {
    const content = quillRef.current?.unprivilegedEditor?.getHTML();
    handleBlur(content ?? '');
  };

  const handleBlur = (content: string) => {
    if (quillRef.current) {
      // Extract the fields used and update the indexes:
      const currentFields = [...(actionMessage?.variables || [])];
      const newFields: SlackFieldsStruct[] = [];
      const validContent = convertHtmlToValidString(content);
      const usedIndexFields = validContent.split(/({{{\d}}})/g);
      const alignIndexesString = usedIndexFields.map((str) => {
        if (str.startsWith('{{{')) {
          const stringNum = str.match(/\d+/)?.[0];
          if (stringNum) {
            const newIndex = newFields.length;
            newFields[newIndex] = currentFields[parseInt(stringNum)];
            return `{{{${newIndex}}}}`;
          }
        } else {
          return str;
        }
      });
      const joinedContent = alignIndexesString.join('');

      const newMessage = {
        ...actionMessage,
        template: joinedContent || '',
        variables: newFields,
      };
      onChange(newMessage);
    }
  };

  const handleChange = (content: string, delta: any, source: string) => {
    // block first api change when value is initialized
    if (source === 'api' && blockApiChangeEvents) {
      setBlockApiChangeEvents(false);
    } else {
      if (quillRef.current) {
        setValue(content);
      }
    }
  };

  class FieldTagBlot extends Embed {
    static create(createData: any) {
      const node = super.create();
      node.setAttribute('chipId', createData.fieldNum);
      const innerDiv = document.createElement('span');
      innerDiv.setAttribute('contenteditable', 'false');
      innerDiv.classList.add('disablePointerEvents');
      const taglineCss = `
        text-transform: capitalize;
        border-radius: 4px;
        background: #F4F6FF;
        padding: 0 4px;
        font-size: 12px;
        color: #4B4B4B;
        display: inline-block;
      `;
      innerDiv.setAttribute('style', taglineCss);

      let textLabel: string = createData.fieldTitle;
      if (!textLabel) {
        const fieldStruct = actionMessage?.variables[createData.fieldNum];
        if (fieldStruct) {
          const fieldLabels = getLabelFromIdsWithObjectType(fieldStruct.fieldIds).join('.');
          textLabel =
            fieldStruct.fieldContext === FieldContext.PRIOR_VALUE
              ? `Prior value of ${fieldLabels}`
              : fieldLabels;
        } else {
          textLabel = '';
        }
      }

      const exitNode = document.createElement('span');
      exitNode.setAttribute('contenteditable', 'false');
      exitNode.classList.add('tagDeleteClass');
      const deleteTagCss = `
      font-size: 10px;
      display: inline-block;
      padding: 0 6px;
      cursor: pointer;
      position: relative;
      `;
      exitNode.setAttribute('style', deleteTagCss);
      exitNode.innerText = 'X';

      exitNode.addEventListener('click', function () {
        const quillEditor = quillRef.current.getEditor();
        const range = quillEditor.getSelection(true);
        quillEditor.removeFormat(range.index, 1, 'user');
      });

      innerDiv.innerText = textLabel;
      innerDiv.append(exitNode);
      node.appendChild(innerDiv);
      return node;
    }

    static formats(node: any) {
      const _num = node.getAttribute('chipId');
      return {
        fieldNum: _num,
      };
    }

    static value(node: any) {
      const _num = node.getAttribute('chipId');
      return {
        fieldNum: _num,
      };
    }
  }

  FieldTagBlot.blotName = 'sfField';
  FieldTagBlot.tagName = 'span';
  FieldTagBlot.className = 'sfField';
  ReactQuill.Quill.register(FieldTagBlot, true);

  const [localFieldContext, setLocalFieldContext] = useState<FieldContext>(
    FieldContext.CURRENT_VALUE,
  );

  return (
    <>
      <FormControl
        sx={{
          width: '100%',
          marginBottom: '20px',
          maxWidth: '1148px',
        }}
      >
        <Box
          sx={{
            marginBottom: '3px',
            '& div': {
              color: colors.grey[800],
              fontSize: '12px',
              fontWeight: '400',
            },
          }}
        >
          <FieldLabel
            label={label}
            markLabelAsRequired={isRequired}
            infoTooltipTitle={infoTooltipTitle}
            showTooltip={!!infoTooltipTitle}
          />
        </Box>
        <Box
          sx={{
            background: '#FFFFFF',
            border: `1px solid ${colors.grey[300]}`,
            borderRadius: '4px',
            fontWeight: '400',
          }}
        >
          <Box
            sx={{
              background: '#FFFFFF',
              borderRadius: '4px',
              fontWeight: '400',
              '& .ql-container': {
                fontFamily: 'Inter',
                fontSize: '12px',
                '&  .emoji-mart-emoji > span': {
                  fontFamily: 'Inter !important',
                },
                '& .ql-editor ul>li:before': {
                  content: '"\\2022"',
                },
              },
              '& .ql-editor.ql-blank::before': {
                fontStyle: 'normal',
                fontSize: '14px',
              },
            }}
            onBlur={onOuterBlur}
          >
            <ReactQuill
              readOnly={readonly}
              placeholder={placeholder ?? 'e.g. great job for closing the deal! 🌈✨🐠'}
              theme="bubble"
              value={value || ''}
              onChange={handleChange}
              modules={modules}
              ref={quillRef}
              onKeyDown={onKeyDown}
            />
          </Box>
          <Box
            sx={{
              background: '#FFFFFF',
              borderRadius: '0 0 4px 4px',
              height: '40px',
              display: 'flex',
              flexDirection: 'row-reverse',
              position: 'relative',
              alignItems: 'center',
            }}
          >
            {!isHideDynamicField && (
              <Box>
                <NestedFieldsSelector
                  readonly={readonly}
                  nestedPath={[]}
                  customButtonStartIcon={<PlusIcon color={colors.grey[800]} />}
                  crmOrgId={crmOrgId}
                  objectType={objectName}
                  useCustomButton
                  customButtonText="Insert Dynamic field"
                  customButtonSx={{
                    borderRadius: '50px',
                    height: '32px',
                    fontSize: '14px',
                    background: 'transparent',
                    color: colors.grey[800],
                    border: 'none',
                    textTransform: 'unset',
                    marginRight: '8px',
                    p: '4px 8px',
                    '& span': {
                      fontWeight: 500,
                      fontSize: '14px',
                    },
                    '&:hover': {
                      background: colors.white,
                      color: colors.grey[900],
                    },
                  }}
                  onChange={({ fieldIds, fieldContext, fieldLabels }: NestedFieldsField) => {
                    const newVar = [...(actionMessage?.variables || [])];
                    const _fieldStruct: SlackFieldsStruct = {
                      fieldIds: fieldIds,
                    };
                    if (fieldContext) {
                      _fieldStruct.fieldContext = fieldContext;
                    }
                    newVar.push(_fieldStruct);

                    const newMessage = {
                      ...(actionMessage || {}),
                      variables: newVar,
                    };
                    setActionMessage(newMessage);
                    setLocalFieldContext(FieldContext.CURRENT_VALUE);
                    const isPriorValue = fieldContext === FieldContext.PRIOR_VALUE;
                    const fieldText = fieldLabels.join('.');
                    const fieldTitle = isPriorValue ? `Prior value of ${fieldText}` : fieldText;

                    setTimeout(() => {
                      const quillEditor = quillRef.current.getEditor();
                      const range = quillEditor.getSelection(true);
                      quillEditor.insertEmbed(
                        range.index,
                        'sfField',
                        {
                          fieldNum: newVar.length - 1,
                          fieldTitle: fieldTitle,
                        },
                        'silent',
                      );
                      quillEditor.setSelection(range.index + 1, 0, 'user');
                    }, 200);
                  }}
                  fieldContext={localFieldContext}
                  displayFieldContextMenu={priorValueSlackMessagesFt}
                />
              </Box>
            )}
            {!(hideEmoji || isHideDynamicField) && (
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ margin: '12px 8px' }}
              />
            )}

            {!hideEmoji && (
              <IconButton
                disabled={readonly}
                aria-label="add emoji"
                onClick={(event) => {
                  if (!anchorEl) {
                    setAnchorEl(event.currentTarget);
                  }
                  setEmojiVisible(!isEmojiVisible);
                }}
                className={'openPicker'}
              >
                <SmileyIcon className={'openPicker'} />
              </IconButton>
            )}
            <Popper
              sx={{ zIndex: 1600 }}
              open={isEmojiVisible}
              anchorEl={anchorEl}
              placement="left-end"
            >
              <Box
                sx={{
                  border: '1px solid #D9D9D9',
                  borderRadius: '10px',
                }}
              >
                <Picker
                  data={data}
                  disabled={readonly}
                  skinTonePosition={'none'}
                  previewPosition={'none'}
                  maxFrequentRows={2}
                  onClickOutside={(e: any) => {
                    const btnClasses = e.target.closest('button');
                    if (btnClasses && btnClasses.classList.contains('openPicker')) {
                      return;
                    }
                    setEmojiVisible(false);
                  }}
                  onEmojiSelect={(data: any) => {
                    const quillEditor = quillRef.current.getEditor();
                    const range = quillEditor.getSelection(true);
                    quillEditor.insertEmbed(range.index, 'emoji', data.shortcodes, 'silent');
                    quillEditor.setSelection(range.index + 1, 0, 'user');
                  }}
                />
              </Box>
            </Popper>
          </Box>
        </Box>
      </FormControl>
    </>
  );
};
