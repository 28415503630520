import { PanelType } from '../panels/types';

export const automationsPanelType: PanelType = {
  name: 'AUTOMATIONS_PANEL',
  location: 'left'
};

export const playbookAlertsPanelType: PanelType = {
  name: 'PLAYBOOK_ALERTS_PANEL',
  location: 'left'
};
