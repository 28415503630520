import { Menu, MenuItem } from '@mui/material';
import { AiAgent } from './aiAgentsConsts';
import { TruncatedTextTooltip } from '../common/TruncatedTextTooltip';

const AiAgentsMenu = ({
  isOpen,
  aiAgents,
  anchorElement,
  closeMenu,
  onAgentClick,
}: {
  isOpen: boolean;
  aiAgents: AiAgent[];
  anchorElement: HTMLButtonElement;
  closeMenu: () => void;
  onAgentClick: (agent: AiAgent) => void;
}) => {
  return (
    <Menu
      open={isOpen}
      anchorEl={anchorElement}
      onClose={closeMenu}
      slotProps={{
        paper: {
          sx: {
            width: '330px',
          },
        },
      }}
    >
      {aiAgents.map((agent) => (
        <MenuItem
          key={agent.id}
          onClick={(e) => {
            e.stopPropagation();
            onAgentClick(agent);
          }}
        >
          <TruncatedTextTooltip>{agent.name}</TruncatedTextTooltip>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default AiAgentsMenu;
