import { useCallback, useEffect, useMemo } from 'react';
import { DedupMatchingType } from '../../types/enums/DedupMatchingType';
import { useIsPristine } from '../../hooks/useIsPristine';
import usePermission from '../common/permissions/usePermission';
import { Box, Divider } from '@mui/material';
import { AutomationsCreateEditDialogHeader } from '../common/create-edit-dialog/AutomationsCreateEditDialogHeader';
import { ObjectTypeLabelAndKeyTag } from '../common/ObjectTypeLabelAndKey';
import { colors } from '@sweep-io/sweep-design';
import { MultipleRuleBuildersProvider } from '../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';
import { FormulaEditorStateContextProvider } from '../Automations/FormulaEditorStateContext';
import { SFDCObjectType } from '../../types/enums/SFDCObjectType';
import { DuplicateSameObject } from './dedupMatching/DuplicateSameObject';
import { AutomationType } from '../../types/enums/AutomationType';
import { AutomationActionType } from '../../types/enums/AutomationActionType';
import { MatchingLeadAccountPage } from './dedupMatching/MatchingLeadAccountPage';
import { AssignmentToType } from '../../types/enums/AssignmentToType';
import { MatchingLeadLeadPage } from './dedupMatching/MatchingLeadLeadPage';
import {
  DedupMergeActionType,
  getAssignAction,
  getNotifyAction,
  getWebhooksFollowUpActions,
  validateWebhookActions,
} from './dedupMatching/helpers';
import { useAutomationsContext } from '../Automations/AutomationsContext';
import { hasOnlyOneCriteriaAndItIsEmpty } from '../common/rule-builder/validateSweepCriteria';
import { ValueFromAnotherObjectEditorStateContextProvider } from '../Automations/ValueFromAnotherObjectEditorStateContext';
import { AUTOMATIONS_LAYOUT_MAX_WIDTH } from '../Automations/helper';
import AlertPermission from '../common/AlertPermission';
import { useSelector } from 'react-redux';
import { selectCrmOrg } from '../pages/environments/environmentsReducer';

interface DedupMatchingFormProps {
  onBackButtonClick: () => any;
  crmOrgId: string;
  onPristineChange: (pristine: boolean) => any;
  updateIsFormValid: (isValid: boolean) => any;
  readonly?: boolean;
  objectName: ObjectTypeValues;
  funnelData?: { id: string; name: string; objectName: string };
  recordTypesAndFunnelsDataForObject?: ParsedRecordTypesAndFunnelsObjectData;
  newDedupMatchingType?: DedupMatchingType;
}

const DedupMatchingForm = ({
  onBackButtonClick,
  onPristineChange,
  readonly,
  objectName,
  recordTypesAndFunnelsDataForObject,
  crmOrgId,
  newDedupMatchingType,
  updateIsFormValid,
}: DedupMatchingFormProps) => {
  const dataByVariant = useAutomationsContext();

  const [isAllowedBtn] = usePermission([
    dataByVariant.permissions.edit,
    dataByVariant.permissions.deploy,
  ]);
  if (!isAllowedBtn) {
    readonly = true;
  }

  const { automationJson, setAutomationJson } = dataByVariant;

  const isPristine = useIsPristine(automationJson);
  const crmOrg = useSelector(selectCrmOrg(crmOrgId));

  useEffect(() => {
    onPristineChange(isPristine);
  }, [isPristine, onPristineChange]);

  const setProps = useCallback(
    (_name: string, _value: AutomationDetailsDtoNew[keyof AutomationDetailsDtoNew]) => {
      setAutomationJson({
        ...automationJson,
        automationDetails: {
          ...automationJson.automationDetails,
          [_name]: _value,
        },
      });
    },
    [automationJson, setAutomationJson],
  );

  const renderByType = () => {
    switch (newDedupMatchingType) {
      case DedupMatchingType.ACCOUNT_TO_ACCOUNT_DEDUP:
        return (
          <DuplicateSameObject
            readonly={readonly}
            objectName={SFDCObjectType.Account}
            dedupObject={automationJson}
            crmOrgId={crmOrgId}
            onChangeProperty={setProps}
          />
        );
      case DedupMatchingType.CONTACT_TO_CONTACT_DEDUP:
        return (
          <DuplicateSameObject
            readonly={readonly}
            objectName={SFDCObjectType.Contact}
            dedupObject={automationJson}
            crmOrgId={crmOrgId}
            onChangeProperty={setProps}
          />
        );
      case DedupMatchingType.LEAD_TO_ACCOUNT_MATCHING:
        return (
          <MatchingLeadAccountPage
            readonly={readonly}
            crmOrgId={crmOrgId}
            matchingObject={automationJson}
            onChangeProperty={setProps}
          />
        );
      case DedupMatchingType.LEAD_TO_CONTACT_DEDUP:
        return (
          <DuplicateSameObject
            readonly={readonly}
            objectName={SFDCObjectType.Lead}
            targetObjectName={SFDCObjectType.Contact}
            dedupObject={automationJson}
            crmOrgId={crmOrgId}
            onChangeProperty={setProps}
          />
        );
      case DedupMatchingType.LEAD_TO_LEAD_DEDUP:
        return (
          <DuplicateSameObject
            readonly={readonly}
            objectName={SFDCObjectType.Lead}
            dedupObject={automationJson}
            crmOrgId={crmOrgId}
            onChangeProperty={setProps}
          />
        );
      case DedupMatchingType.LEAD_TO_LEAD_MATCHING:
        return (
          <MatchingLeadLeadPage
            readonly={readonly}
            crmOrgId={crmOrgId}
            matchingObject={automationJson}
            onChangeProperty={setProps}
          />
        );
      case DedupMatchingType.CASE_TO_CASE_DEDUP:
        return (
          <DuplicateSameObject
            readonly={readonly}
            objectName={SFDCObjectType.Case}
            dedupObject={automationJson}
            crmOrgId={crmOrgId}
            onChangeProperty={setProps}
          />
        );
      case DedupMatchingType.OPP_TO_OPP_DEDUP:
        return (
          <DuplicateSameObject
            readonly={readonly}
            objectName={SFDCObjectType.Opportunity}
            dedupObject={automationJson}
            crmOrgId={crmOrgId}
            onChangeProperty={setProps}
          />
        );
    }
  };

  const isAutomationsFormValid = useMemo(() => {
    const validateDedupData = () => {
      let isValid = true;
      if (automationJson.automationDetails?.delay) {
        if (!automationJson.automationDetails?.delay.offsetValue) {
          isValid = false;
        }
      }
      const dedupAction = automationJson?.automationDetails
        ?.actions?.[0] as DeduplicationAutomationAction;
      if (dedupAction?.actionParams.mergeActionType === DedupMergeActionType.OVERRIDE_BY_CONVERT) {
        if (!dedupAction?.actionParams.convertedStatus) {
          isValid = false;
        }
      }
      if (dedupAction?.actionParams?.dmType === DedupMatchingType.CASE_TO_CASE_DEDUP) {
        if (
          hasOnlyOneCriteriaAndItIsEmpty(
            dedupAction?.actionParams?.matchingLogic?.criteria ?? [],
          ) ||
          !dedupAction?.actionParams?.matchingLogic
        ) {
          isValid = false;
        }
      }

      const notifyAction = getNotifyAction(automationJson);
      if (notifyAction) {
        if (notifyAction.actionType === AutomationActionType.SendEmail) {
          if (
            !notifyAction.actionParams?.emailTemplate ||
            !notifyAction.actionParams?.toEmailRecipients ||
            !(notifyAction.actionParams.toEmailRecipients?.length > 0)
          )
            isValid = false;
        } else {
          if (
            !notifyAction.actionParams?.recipients ||
            !(notifyAction.actionParams?.recipients.length > 0)
          ) {
            isValid = false;
          }
        }
      }
      const _webhooks = getWebhooksFollowUpActions(automationJson);
      if (_webhooks?.length > 0) {
        const isWebhooksValid = validateWebhookActions(_webhooks);
        if (!isWebhooksValid) {
          isValid = false;
        }
      }

      return isValid;
    };

    const validateMatchingData = () => {
      const validateFieldMapping = (fieldMapping: FieldMappingStruct) => {
        if (
          !fieldMapping.fromField?.fieldIds ||
          !fieldMapping.toField?.fieldIds ||
          fieldMapping.fromField?.fieldIds.length === 0 ||
          fieldMapping.toField?.fieldIds.length === 0
        ) {
          return false;
        }
        return true;
      };

      let isValid = true;

      const notifyAction = getNotifyAction(automationJson);
      if (notifyAction) {
        if (notifyAction.actionType === AutomationActionType.SendEmail) {
          if (
            !notifyAction.actionParams?.emailTemplate ||
            !notifyAction.actionParams?.toEmailRecipients ||
            !(notifyAction.actionParams.toEmailRecipients?.length > 0)
          )
            isValid = false;
        } else {
          if (
            !notifyAction.actionParams?.recipients ||
            !(notifyAction.actionParams?.recipients.length > 0)
          ) {
            isValid = false;
          }
        }
      }
      const assignAction = getAssignAction(automationJson);
      if (assignAction) {
        const fieldMapping = assignAction.actionParams.assignedTo.fieldMapping;
        if (fieldMapping) {
          const isValidFieldMapping = validateFieldMapping(fieldMapping);
          if (!isValidFieldMapping) {
            isValid = false;
          }
        }
        if (assignAction?.actionParams?.assignedTo.type === AssignmentToType.Field) {
          if (!fieldMapping) {
            isValid = false;
          }
        }
      }
      const matchingAction = automationJson?.automationDetails
        ?.actions?.[0] as MatchingAutomationAction;
      if (matchingAction.actionParams.fieldMapping.length > 0) {
        matchingAction.actionParams.fieldMapping.forEach((el) => {
          if (!validateFieldMapping(el)) {
            isValid = false;
          }
        });
      }
      if (matchingAction.actionParams.advancedLogic?.isActive) {
        if (
          !matchingAction.actionParams.advancedLogic?.endField?.fieldIds ||
          matchingAction.actionParams.advancedLogic?.endField?.fieldIds.length === 0 ||
          !matchingAction.actionParams.advancedLogic?.startField?.fieldIds ||
          matchingAction.actionParams.advancedLogic?.startField?.fieldIds.length === 0
        ) {
          isValid = false;
        }
      }
      const _webhooks = getWebhooksFollowUpActions(automationJson);
      if (_webhooks?.length > 0) {
        const isWebhooksValid = validateWebhookActions(_webhooks);
        if (!isWebhooksValid) {
          isValid = false;
        }
      }

      return isValid;
    };

    if (automationJson.type === AutomationType.Dedupe) {
      return validateDedupData();
    } else {
      return validateMatchingData();
    }
  }, [automationJson]);

  useEffect(() => {
    updateIsFormValid(!!isAutomationsFormValid);
  }, [isAutomationsFormValid, isPristine, onPristineChange, updateIsFormValid]);

  return (
    <Box>
      <AutomationsCreateEditDialogHeader
        showCancelButton
        onCancelClick={onBackButtonClick}
        rightSideContent={
          <>
            <ObjectTypeLabelAndKeyTag
              tagVariant="medium"
              label={recordTypesAndFunnelsDataForObject?.label ?? objectName}
              objectType={objectName}
            />
            <Divider orientation="vertical" variant="fullWidth" flexItem />
          </>
        }
        isStickyHeader
        sx={{ background: colors.white, paddingTop: '20px', paddingBottom: '20px', gap: '16px' }}
        hideBackButton
        onChange={(value) => {
          setAutomationJson({
            ...automationJson,
            name: value,
          });
        }}
        placeholder={dataByVariant.placeholder}
        value={automationJson.name || ''}
        editDisabled={!isAllowedBtn || readonly}
      />
      <Box
        sx={{
          maxHeight: 'calc(100% - 95px)',
          overflowY: 'auto',
          maxWidth: AUTOMATIONS_LAYOUT_MAX_WIDTH,
        }}
      >
        {!isAllowedBtn && (
          <AlertPermission
            alertMessage={`You don't have permission to edit dedupe and matching in environment ${crmOrg?.name}`}
            addedStyle={{
              width: '470px',
              position: 'absolute',
              right: '40px',
              top: '98px',
              zIndex: '1000',
            }}
          />
        )}
      </Box>
      {renderByType()}
    </Box>
  );
};

const DedupMatchingFormWithProviders = (props: DedupMatchingFormProps) => (
  <MultipleRuleBuildersProvider>
    <ValueFromAnotherObjectEditorStateContextProvider>
      <FormulaEditorStateContextProvider>
        <DedupMatchingForm {...props} />
      </FormulaEditorStateContextProvider>
    </ValueFromAnotherObjectEditorStateContextProvider>
  </MultipleRuleBuildersProvider>
);

export { DedupMatchingFormWithProviders as DedupMatchingForm };
