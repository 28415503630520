import { useDispatch } from 'react-redux';
import {
  setSfChangeFeedFilter,
  setSfChangeFeedList,
  setSfChangeFeedNotifications,
  removeNotification,
  saveNotification,
  selectSfChangeFeedFilters,
  selectSfChangeFeedList,
  setTemporaryRows,
  openNotificationDialog,
  setFormInitialSkeleton,
  setFiltersOptions,
} from '../../../../reducers/sfChangeFeedReducer';
import {
  SfChangeFeedFilterFieldNames,
  SfChangeFeedFilters,
  SfChangeFeedNotification,
  SfChangeFeedSortAndFilter,
} from './types';
import { useCallback, useState } from 'react';
import { useSfChangeFeedApiFacade } from '../../../../apis/facades/useSfChangeFeedApiFacade';
import { useSelector } from 'react-redux';
import differenceBy from 'lodash/differenceBy';
import { useLocation } from 'react-router';
import { appRoutes } from '../../../../constants/appRoutes';
import { ACTIONS_EVENTS } from '../../../../services/events';
import useSendBiEvent from '../../../../hooks/useSendBiEvent';
import { telemetry } from '../../../../telemetry';
import { useSweepNotifications } from '../../../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../../../reducers/notificationsReducer';
import { prepareURLQuery } from '../utils';
import isEqual from 'lodash/isEqual';
import { getDateFromKey } from '../../../common/datepicker/getDateFromKey';
import { DateBy } from '../../../common/datepicker/types';
import { DataTableOnSortChangeClbk } from '../../../common/table/TableTypes';
import { DEFAULT_DATEPICKER_STATE, DEFAULT_SORT_KEY, DEFAULT_SORT_BY } from './helpers';
import { selectCrmOrgs } from '../../environments/environmentsReducer';

export const useSfChangeFeed = ({ clearSearchInput }: { clearSearchInput?: () => void }) => {
  const [isTableLoading, setIsTableLoading] = useState(false);
  const sendBiEvent = useSendBiEvent();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentFilters = useSelector(selectSfChangeFeedFilters);
  const currentList = useSelector(selectSfChangeFeedList);
  const sortAndFilter = useSelector(selectSfChangeFeedFilters);
  const crmOrgs = useSelector(selectCrmOrgs);

  const {
    get_sfChangeFeed,
    get_sfChangeFeedNotifications,
    post_sfChangeFeedNotification,
    put_sfChangeFeedNotification,
    delete_sfChangeFeedNotification,
  } = useSfChangeFeedApiFacade();
  const { addNotification } = useSweepNotifications();

  const compareOldFeedWithNewData = useCallback(async () => {
    const { pathname } = location;

    if (pathname.startsWith(appRoutes.sfChangeFeed.route)) {
      const queryString = currentFilters ? prepareURLQuery(currentFilters) : '';
      const response = (await get_sfChangeFeed(queryString))?.changeFeedList ?? [];
      const difference = differenceBy(response, currentList, 'sfId');

      if (difference.length > 0) {
        dispatch(
          setTemporaryRows({
            temporaryListItems: difference,
          }),
        );
        return true;
      }
    }

    return false;
  }, [location, dispatch, currentFilters, currentList, get_sfChangeFeed]);

  const fetchSfChangeFeed = useCallback(
    async (selectedFilters: SfChangeFeedFilters, withFilters = false) => {
      const queryString = selectedFilters
        ? prepareURLQuery({ ...selectedFilters, withFilters })
        : '';

      try {
        const response = await get_sfChangeFeed(queryString);
        dispatch(
          setSfChangeFeedList({
            response,
            selectedFilters,
            withFilters,
          }),
        );
      } catch (error) {
        telemetry.captureError(error);
      }

      setIsTableLoading(false);
    },
    [dispatch, get_sfChangeFeed],
  );

  const getFiltersOptions = useCallback(
    async (selectedFilters: SfChangeFeedFilters) => {
      const queryString = selectedFilters
        ? prepareURLQuery({ ...selectedFilters, withFilters: true })
        : '';
      const response = await get_sfChangeFeed(queryString);
      dispatch(
        setFiltersOptions({
          filters: response.filters,
        }),
      );
    },
    [dispatch, get_sfChangeFeed],
  );

  const setFilter = useCallback(
    (newFilter: Partial<SfChangeFeedFilters>, clear?: boolean) => {
      const _newFilter = clear
        ? { ...newFilter, actionText: '', sections: [], sfUserNames: [] }
        : newFilter;
      dispatch(setSfChangeFeedFilter({ newFilter: _newFilter, clear }));
      fetchSfChangeFeed(_newFilter);
    },
    [dispatch, fetchSfChangeFeed],
  );

  const fetchNotifications = useCallback(async () => {
    const notifications = await get_sfChangeFeedNotifications();
    dispatch(setSfChangeFeedNotifications({ notifications }));
  }, [dispatch, get_sfChangeFeedNotifications]);

  const _add = useCallback(
    async (notification: Omit<SfChangeFeedNotification, 'id'>) => {
      try {
        return await post_sfChangeFeedNotification(notification);
      } catch (e) {
        telemetry.captureError(e, {
          message: 'Error during sf change feed notification creation',
        });
      }
    },
    [post_sfChangeFeedNotification],
  );

  const _save = useCallback(
    (notification: SfChangeFeedNotification) => {
      try {
        put_sfChangeFeedNotification(notification);
      } catch (e) {
        telemetry.captureError(e, { message: 'Error during sf change feed notification save' });
      }
    },
    [put_sfChangeFeedNotification],
  );

  const onSaveNotification = useCallback(
    async (notification: SfChangeFeedNotification) => {
      const { id, ...newNotification } = notification;
      const isNewNotification = !id;
      let _notification: SfChangeFeedNotification | undefined = notification;

      if (isNewNotification) {
        sendBiEvent({ name: ACTIONS_EVENTS.changeFeedAddNotification });
        _notification = await _add(newNotification);
      } else {
        _save(_notification);
      }

      if (_notification) {
        dispatch(saveNotification({ notification: _notification }));
      }
    },
    [sendBiEvent, _add, _save, dispatch],
  );

  const onDuplicateNotification = useCallback(
    (oldNotification: SfChangeFeedNotification) => {
      const newItem = {
        ...oldNotification,
        id: '',
        name: 'Copy of ' + oldNotification.name,
      };

      onSaveNotification(newItem);
    },
    [onSaveNotification],
  );

  const displayNotification = useCallback(
    (message: string, variant: SweepNotificationVariant) => {
      return addNotification({
        message: message,
        variant: variant,
      });
    },
    [addNotification],
  );

  const onDeleteNotification = useCallback(
    async (notificationId: string) => {
      try {
        await delete_sfChangeFeedNotification(notificationId);
        displayNotification('Notification successfully deleted', SweepNotificationVariant.Success);
      } catch (e) {
        displayNotification(
          'Failed to delete notification due to technical issues',
          SweepNotificationVariant.Error,
        );
        telemetry.captureError(e, { message: 'Error during sf change feed notification delete' });
      }
      dispatch(removeNotification({ notificationId }));
    },
    [dispatch, delete_sfChangeFeedNotification, displayNotification],
  );

  const onToggleNotification = useCallback(
    async (notification: SfChangeFeedNotification) => {
      const oldState = !notification.isActive;
      dispatch(saveNotification({ notification }));

      try {
        await onSaveNotification(notification);
        dispatch(saveNotification({ notification }));
        displayNotification('Notification successfully updated', SweepNotificationVariant.Success);
      } catch (error) {
        displayNotification(
          'Failed to updated notification due to technical issues',
          SweepNotificationVariant.Error,
        );
        dispatch(saveNotification({ notification: { ...notification, isActive: oldState } }));
        telemetry.captureError(error);
      }
    },
    [dispatch, onSaveNotification, displayNotification],
  );

  const onNotificationDialogToggle = useCallback(
    (isOpen: boolean) => {
      dispatch(openNotificationDialog({ toggleOpen: isOpen }));
    },
    [dispatch],
  );

  const onSetFormElement = useCallback(
    (element?: SfChangeFeedNotification) => {
      dispatch(setFormInitialSkeleton({ element }));
    },
    [dispatch],
  );

  const openNotificationDialogInCreationMode = useCallback(
    (element: SfChangeFeedNotification) => {
      onNotificationDialogToggle(true);
      onSetFormElement(element);
    },
    [onNotificationDialogToggle, onSetFormElement],
  );

  const onCrmOrgOrDateChange = useCallback(
    async (newFilter: Partial<SfChangeFeedSortAndFilter>, clear?: boolean) => {
      const filter = { ...sortAndFilter, ...newFilter };
      setFilter(
        {
          ...filter,
          sections: [],
          sfUserNames: [],
          actionText: '',
        },
        clear,
      );

      const { startTime, endTime } = filter;
      const filterOptions = {
        crmOrgs: filter.crmOrgs,
        startTime,
        endTime,
      };

      getFiltersOptions(filterOptions);
    },
    [getFiltersOptions, sortAndFilter, setFilter],
  );

  const handleFilterChange = useCallback(
    async (newFilter: Partial<SfChangeFeedSortAndFilter>, clear?: boolean) => {
      if (!isEqual(newFilter, sortAndFilter)) {
        const { startTime, endTime, crmOrgs } = sortAndFilter;
        const isCrmOrgDifferent = newFilter.crmOrgs && !isEqual(newFilter.crmOrgs, crmOrgs);
        const isDateDifferent =
          (newFilter.startTime && !isEqual(newFilter.startTime, startTime)) ||
          (newFilter.endTime && !isEqual(newFilter.endTime, endTime));

        if (isCrmOrgDifferent || isDateDifferent) {
          onCrmOrgOrDateChange(newFilter, clear);
          setIsTableLoading(true); //to start loading before call
          return;
        }

        setFilter({ ...sortAndFilter, ...newFilter }, clear);
        setIsTableLoading(true); //to start loading before call
      }
    },
    [sortAndFilter, setFilter, onCrmOrgOrDateChange],
  );

  const clearFilters = useCallback(() => {
    const dateFilter = getDateFromKey(DEFAULT_DATEPICKER_STATE);

    handleFilterChange(
      {
        crmOrgs: crmOrgs.map((org) => org.id),
        startTime: dateFilter[DateBy.From],
        endTime: dateFilter[DateBy.To],
        sortKey: DEFAULT_SORT_KEY,
        sortBy: DEFAULT_SORT_BY,
      },
      true,
    );
    clearSearchInput && clearSearchInput();
  }, [crmOrgs, clearSearchInput, handleFilterChange]);

  const onSortChange: DataTableOnSortChangeClbk = useCallback(
    ({ sortBy, sortOrder }) => {
      handleFilterChange({ sortBy: sortBy as SfChangeFeedFilterFieldNames, sortKey: sortOrder });
    },
    [handleFilterChange],
  );

  return {
    fetchSfChangeFeed,
    fetchNotifications,
    onSaveNotification,
    onDuplicateNotification,
    onDeleteNotification,
    onToggleNotification,
    compareOldFeedWithNewData,
    onNotificationDialogToggle,
    onSetFormElement,
    openNotificationDialogInCreationMode,
    handleFilterChange,
    clearFilters,
    onSortChange,
    isTableLoading,
  };
};
