import { useContext, useCallback } from 'react';
import { CanvasContext } from './CanvasContext';
import { Rect, useReactFlow, useViewport } from '@xyflow/react';

/* Public Api to be exposed */

export const useSweepCanvas = () => {
  const { referencesById, setReferencesMap } = useContext(CanvasContext);
  const rf = useReactFlow();
  const viewport = useViewport();
  const { zoom } = viewport;

  const getRefForPluginButton = useCallback(
    (groupId: string, pluginType: PluginTypes) => {
      const ref = referencesById[`${pluginType}-${groupId}`];
      return ref;
    },
    [referencesById],
  );

  const pluginButtonExists = useCallback(
    (groupId: string, pluginType: PluginTypes) => {
      return !!getRefForPluginButton(groupId, pluginType)?.current;
    },
    [getRefForPluginButton],
  );

  const clickPluginButton = useCallback((pluginType: PluginTypes, groupId: string) => {
    // TODO: Fix this. Not sure why this is not working
    // const ref = getRefForPluginButton(groupId, pluginType);
    // if (!ref) {
    //   console.warn(
    //     `Plugin button for plugin type ${pluginType} and group id ${groupId} not found`,
    //   );
    //   return;
    // }
    // ref.current?.click();

    // Workaround:
    const ref = window.document.getElementById(
      `plugin-button-${pluginType}-${groupId}`,
    ) as HTMLButtonElement | null;
    if (!ref) {
      console.warn(
        `Plugin button for plugin type ${pluginType} and group id ${groupId} not found`,
      );
      return;
    }
    ref.click();
  }, []);

  const removeRefId = useCallback(
    (id: string) => {
      setReferencesMap(id, undefined);
    },
    [setReferencesMap],
  );

  const removePluginButton = useCallback(
    (groupId: string, pluginType: PluginTypes) => {
      const ref = getRefForPluginButton(groupId, pluginType);
      if (!ref) {
        console.warn(
          `Plugin button for plugin type ${pluginType} and group id ${groupId} not found`,
        );
        return;
      }
      removeRefId(`${pluginType}-${groupId}`);
    },
    [getRefForPluginButton, removeRefId],
  );

  const getIntersectingNodes = useCallback(
    (rect: Rect) => {
      const flowPosition = rf.screenToFlowPosition({
        x: rect.x,
        y: rect.y,
      });

      return rf.getIntersectingNodes(
        {
          ...flowPosition,
          width: rect.width / zoom,
          height: rect.height / zoom,
        },
        true,
      );
    },
    [rf, zoom],
  );

  return {
    clickPluginButton,
    pluginButtonExists,
    removePluginButton,
    getIntersectingNodes,
  };
};
