import Box from '@mui/material/Box';
import { __SWEEP_DESIGN_TYPOGRAPHY_STYLES___, colors } from '@sweep-io/sweep-design';
import { ReactNode } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css'; // `rehype-katex` does not import the CSS automatically

import { dataTableVariants } from './table/dataTableVariants';
import { DataTableVariant } from './table/TableTypes';

export const MarkdownWrapper = ({
  content,
  component = 'div',
  color,
  removeMargins,
}: {
  content: ReactNode;
  component?: 'div' | 'span';
  color?: string;
  removeMargins: boolean; //margins are not needed in case there's no background color
}) => {
  const dataTableVariant = dataTableVariants[DataTableVariant.small];
  return (
    <Box
      component={component}
      sx={{
        color: color ?? colors.grey[900],
        fontFamily: "'Inter', sans-serif",
        '&>*:first-of-type': {
          marginTop: removeMargins ? 0 : '12px',
        },
        '&>*:last-child': {
          marginBottom: removeMargins ? 0 : '12px',
        },
        '& code': {
          wordBreak: 'break-word',
          whiteSpace: 'break-spaces',
          background: colors.grey[120],
        },
        '& p, & a ': {
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontSize,
          lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.lineHeight,
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontWeight,
          wordBreak: 'break-word',
        },
        '& em, & hr, & pre,  & li, & ol, & ul': {
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontSize,
          wordBreak: 'break-word',
        },
        '& strong': {
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['body-bold']?.fontWeight,
        },
        '& h1': {
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['h1-bold']?.fontSize,
          lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['h1-bold']?.lineHeight,
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['h1-bold']?.fontWeight,
        },
        '& h2': {
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['h2-bold']?.fontSize,
          lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['h2-bold']?.lineHeight,
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['h2-bold']?.fontWeight,
        },
        '& h3': {
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.h4?.fontSize,
          lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.h4?.lineHeight,
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.h4?.fontWeight,
        },
        '& h4': {
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.h4?.fontSize,
          lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.h4?.lineHeight,
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.h4?.fontWeight,
        },
        '& h5': {
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.h4?.fontSize,
          lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.h4?.lineHeight,
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.h4?.fontWeight,
        },
        '& h6': {
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['caption-bold']?.fontSize,
          lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['caption-bold']?.lineHeight,
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['caption-bold']?.fontWeight,
        },
        '& table': {
          borderSpacing: 0,
          overflow: 'hidden',
          width: '100%',
          borderRadius: '8px',
          border: `1px solid ${colors.grey[120]}`,
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___[dataTableVariant.fontVariant]?.fontSize,

          '& th': {
            backgroundColor: colors.grey[100],
            color: dataTableVariant.headerFontColor,
            fontWeight:
              __SWEEP_DESIGN_TYPOGRAPHY_STYLES___[dataTableVariant.fontVariant]?.fontWeight,
            textAlign: 'left',
            padding: dataTableVariant.cellPadding,
            minWidth: dataTableVariant.minRowWidth,
            wordBreak: 'break-word',
            lineHeight:
              __SWEEP_DESIGN_TYPOGRAPHY_STYLES___[dataTableVariant.fontVariant]?.lineHeight,
          },

          '& tr': {
            '& td': {
              whiteSpace: 'break-spaces',
              wordBreak: 'break-word',
              borderBottom: `1px solid ${colors.grey[120]}`,
              color: dataTableVariant.fontColor,
              fontWeight:
                __SWEEP_DESIGN_TYPOGRAPHY_STYLES___[dataTableVariant.fontVariant]?.fontWeight,
              lineHeight:
                __SWEEP_DESIGN_TYPOGRAPHY_STYLES___[dataTableVariant.fontVariant]?.lineHeight,
              textAlign: 'left',
              padding: dataTableVariant.cellPadding,
            },
            '&:last-child': {
              '& td': {
                borderBottom: 'none',
              },
            },
          },
        },
      }}
    >
      {typeof content === 'string' && (
        <Markdown
          remarkPlugins={[remarkGfm, remarkMath]}
          rehypePlugins={[rehypeKatex]}
          children={content}
        />
      )}
      {typeof content !== 'string' && <>{content}</>}
    </Box>
  );
};
