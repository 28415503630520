import { Box, Collapse, Grid } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { useState } from 'react';
import AdditionalFields from '../../../../dialogs/stage/AdditionalFields';
import pluralize from 'pluralize';
import { CriterionComponent } from './CriterionComponent';
import { ArrowRight, ChevronDown, ChevronUp } from '@sweep-io/sweep-design/dist/icons';
import { TruncatedTextTooltip } from '../../../../../../common/TruncatedTextTooltip';
import RichTextEditor from '../../../../../../common/RichTextEditor/RichTextEditor';

interface DynamicPathContentProps {
  onDescriptionChange: (newDescription: string) => void;
  onAdditionalFieldsChange: (additionalFields: AdditionalField[]) => void;
  onGateGuidanceChange: (guidance: string) => void;
  objectName: string;
  crmOrgId: string;
  pluginStage: DynamicPathStage;
  stageExitCriteria: SweepExitCriteria[];
  selectedExitCriteriaId: string;
  mappedIdToStageName: { [stageId: string]: string };
  selectedStageId: string;
  isReadOnly: boolean;
}

export const DynamicPathStageContent = ({
  pluginStage,
  stageExitCriteria,
  selectedExitCriteriaId,
  selectedStageId,
  onDescriptionChange,
  onAdditionalFieldsChange,
  onGateGuidanceChange,
  objectName,
  crmOrgId,
  mappedIdToStageName,
  isReadOnly,
}: DynamicPathContentProps) => {
  const { exitCriteria: pluginExitCriteria, description, additionalFields } = pluginStage;

  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [_description, setDescription] = useState(description);
  const [_guidance, setGuidance] = useState(
    pluginExitCriteria.find((ec) => ec.exitCriteriaId === selectedExitCriteriaId)?.guidance,
  );

  const exitCriteria = stageExitCriteria.find(
    (ec) => ec._exitCriteriaId === selectedExitCriteriaId,
  );
  const hasCriteria = Boolean(exitCriteria?.criteria.length);

  return (
    <Box
      sx={{
        background: colors.grey[100],
        padding: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        overflow: 'auto',
      }}
    >
      {exitCriteria && (
        <Box
          sx={{
            background: colors.grey[120],
            padding: '3px 4px',
            display: 'flex',
            width: 'fit-content',
            alignItems: 'center',
            gap: 0.5,
          }}
        >
          <Typography color={colors.night[900]} variant="caption">
            {mappedIdToStageName[selectedStageId]}
          </Typography>
          <ArrowRight color={colors.night[900]} />
          <Typography color={colors.night[900]} variant="caption">
            {mappedIdToStageName[exitCriteria?._nextStageId]}
          </Typography>
        </Box>
      )}
      <Box sx={{ display: 'flex', gap: '12px', flexDirection: 'column' }}>
        <Box>
          <Typography variant="caption" color={colors.grey[800]}>
            Step Description
          </Typography>
          <RichTextEditor
            value={_description}
            onChange={(value: string) => {
              setDescription(value);
            }}
            onBlur={(value: string) => {
              onDescriptionChange(value);
            }}
            isReadOnly={isReadOnly}
            placeholder={`Describe this step to the Salesforce end users. (For example: outline the goals of this step and the customer journey)`}
          />
        </Box>

        {hasCriteria && (
          <Box
            key={selectedExitCriteriaId}
            sx={{
              borderRadius: 1,
              background: colors.white,
              padding: 2,
              border: `1px solid ${colors.grey[300]}`,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => setIsCollapseOpen((collapsed) => !collapsed)}
            >
              <Typography variant="body-bold" color={colors.grey[800]}>
                {pluralize('Condition', exitCriteria?.criteria.length ?? 0, true)}
              </Typography>

              {isCollapseOpen ? <ChevronUp /> : <ChevronDown />}
            </Box>

            <Collapse in={isCollapseOpen}>
              <Box sx={{ mt: 1 }}>
                <Box>
                  <Typography variant="caption">Gate Guidance</Typography>
                  <RichTextEditor
                    value={_guidance || ''}
                    onChange={(value: string) => {
                      setGuidance(value);
                    }}
                    onBlur={(value: string) => {
                      onGateGuidanceChange(value);
                    }}
                    isReadOnly={isReadOnly}
                    placeholder={`Add guidance for the gate, focus on what should be completed in order to move to the next step and why (optional)`}
                  />
                </Box>

                <Box mt={'12px'}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mb: 1,
                    }}
                  >
                    <Typography variant="caption">Required Gates conditions</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="caption-bold" color={colors.grey[700]}>
                        Logic Summary:
                      </Typography>

                      <Box
                        sx={{
                          ml: '6px',
                          mr: '6px',
                          maxWidth: '300px',
                        }}
                      >
                        <TruncatedTextTooltip variant="caption" color={colors.grey[900]}>
                          {exitCriteria?.criteriaLogic}
                        </TruncatedTextTooltip>
                      </Box>
                    </Box>
                  </Box>
                  <Grid container spacing={'12px'} rowSpacing={'12px'}>
                    {exitCriteria?.criteria.map((criterion, idx) => (
                      <Grid item xs={6} key={idx}>
                        <CriterionComponent
                          key={criterion.criterionId}
                          criterion={criterion}
                          idx={idx}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </Collapse>
          </Box>
        )}

        {!hasCriteria && (
          <Box>
            <Typography variant="body" color={colors.grey[700]}>
              No conditions defined
            </Typography>
          </Box>
        )}
      </Box>

      <Box>
        <Typography variant="body-bold" color={colors.grey[800]}>
          Additional fields
        </Typography>

        <AdditionalFields
          readonly={isReadOnly}
          additionalFields={additionalFields}
          onChange={onAdditionalFieldsChange}
          crmOrgId={crmOrgId}
          objectType={objectName}
        />
      </Box>
    </Box>
  );
};
