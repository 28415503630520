import { AI_AGENTS_INFO } from './aiAgentsConsts';
import { AgentType, ChatCompletionRequestMessageRole } from '@server/ai';
import isNil from 'lodash/isNil';
import { AiChatMessage } from '../common/ai-chat/aiChatTypes';

export const isMessagesPaywallLimitReached = ({
  messages,
  agentType,
}: {
  messages?: AiChatMessage[];
  agentType?: AgentType;
}) => {
  const { paywallNumberOfAllowedUserMessages } =
    AI_AGENTS_INFO[agentType ?? AgentType.Documentation];

  const userMessagesCount = messages?.filter(
    (msg) => msg.role === ChatCompletionRequestMessageRole.USER,
  ).length;

  return !isNil(userMessagesCount) && !isNil(paywallNumberOfAllowedUserMessages)
    ? userMessagesCount >= paywallNumberOfAllowedUserMessages
    : false;
};
