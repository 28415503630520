import { AI_AGENTS_EVENTS } from '../../services/events';
import { AgentContextComponent, AgentType } from '@server/ai';
import { aiAgentCenterPanelType } from '../dashboard/aiAgents/aiAgentCenterPanelType';
import { sendBiEvent } from '../../services/bi';
import { useCallback } from 'react';
import useAiAgentChats from './useAiAgentChats';
import useAiAgents from './useAiAgents';
import { usePanels } from '../panels/usePanels';
import useAiAgentCenter from './useAiAgentCenter';

const useCreateAgent = (onFinishCb?: () => void) => {
  const { startChatWithAgent } = useAiAgentChats();
  const { createAgent } = useAiAgents();
  const { maybeOpenPanel } = usePanels();
  const { toggleIsOpened, isOpened } = useAiAgentCenter();

  return useCallback(
    async ({
      type,
      name,
      selectedContext,
      instructions,
      shouldOpenCanvasPanel,
    }: {
      type: AgentType;
      name: string;
      selectedContext: AgentContextComponent;
      shouldOpenCanvasPanel?: boolean;
      instructions?: string;
    }) => {
      if (selectedContext) {
        sendBiEvent({ name: AI_AGENTS_EVENTS.createAgent });
        const newAgent = await createAgent({
          name,
          contextComponents: selectedContext,
          type,
          instructions,
        });
        if (!newAgent) {
          return;
        }

        startChatWithAgent({
          agent: newAgent,
        });

        if (shouldOpenCanvasPanel) {
          maybeOpenPanel({
            panelType: aiAgentCenterPanelType,
            onOpen: () => {
              //should mark as "opened" in the aiAgentsCenter reducer too
              if (!isOpened) {
                toggleIsOpened();
              }
            },
          });
        } else {
          if (!isOpened) {
            toggleIsOpened();
          }
        }
        onFinishCb?.();
      }
    },
    [createAgent, startChatWithAgent, isOpened, maybeOpenPanel, onFinishCb, toggleIsOpened],
  );
};

export default useCreateAgent;
