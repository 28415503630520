import noop from 'lodash/noop';
import { createContext, useState } from 'react';
import { MaybeClosePanelFunc, PanelLocation, PanelType } from './types';

interface IContextPanel<DataT = unknown> {
  panelType: PanelType<DataT>;
  isDirty?: boolean;
  data?: DataT;
  onClose?: () => void;
}

type PanelConfirmOpen = ({ action }: { action: () => void }) => void;

export interface IPanelsContext<DataT = unknown> {
  currentPanelRight?: IContextPanel<DataT>;
  setCurrentPanelRight: (panel: IContextPanel<DataT> | undefined) => void;
  currentPanelLeft?: IContextPanel<DataT>;
  setCurrentPanelLeft: (panel: IContextPanel<DataT> | undefined) => void;
  confirmOpen: PanelConfirmOpen;
  isInitialized: boolean;
}

export const PanelsContext = createContext<IPanelsContext>({
  setCurrentPanelRight: noop,
  setCurrentPanelLeft: noop,
  confirmOpen: noop,
  isInitialized: false,
});

export const PanelsProvider = ({
  children,
  confirmOpen,
  forceConfirmOpen,
}: {
  children: React.ReactNode;
  confirmOpen?: PanelConfirmOpen;
  forceConfirmOpen?: boolean;
}) => {
  const [currentPanelRight, setCurrentPanelRight] = useState<IContextPanel | undefined>(undefined);
  const [currentPanelLeft, setCurrentPanelLeft] = useState<IContextPanel | undefined>(undefined);
  if (Boolean(confirmOpen) === Boolean(forceConfirmOpen)) {
    // XOR
    throw new Error(
      'PanelsProvider requires one of confirmOpen or forceConfirmOpen to be passed, but not both',
    );
  }
  const _confirmOpen: PanelConfirmOpen =
    confirmOpen ||
    (({ action }) => {
      action();
    });

  return (
    <PanelsContext.Provider
      value={{
        currentPanelRight,
        setCurrentPanelRight,
        currentPanelLeft,
        setCurrentPanelLeft,
        confirmOpen: _confirmOpen,
        isInitialized: true,
      }}
    >
      {children}
    </PanelsContext.Provider>
  );
};

interface PanelConsumerArgs<DataT = unknown> {
  isOpen: boolean;
  maybeClosePanel: MaybeClosePanelFunc;
  setIsDirty: (isDirty: boolean) => void;
  data?: DataT; // Does not passes data if isOpen is false
  onClose?: () => void;
}

export function PanelConsumer<DataT = unknown>({
  panelType,
  instanceId,
  children,
  defaultIsDirty,
}: {
  panelType: PanelType<DataT>;
  instanceId?: string;
  children: (args: PanelConsumerArgs<DataT>) => React.ReactNode;
  defaultIsDirty?: boolean;
}) {
  const nameAndInstance = instanceId ? `${panelType.name}-${instanceId}` : panelType.name;
  const { location } = panelType;
  return (
    <PanelsContext.Consumer
      children={({
        currentPanelRight,
        setCurrentPanelRight,
        currentPanelLeft,
        setCurrentPanelLeft,
        confirmOpen,
      }) => {
        const isOpenRight =
          location === 'right' && currentPanelRight?.panelType.name === nameAndInstance;
        const isOpenLeft =
          location === 'left' && currentPanelLeft?.panelType.name === nameAndInstance;

        const maybeClosePanel: MaybeClosePanelFunc = (params) => {
          const closeAction = () => {
            if (location === 'right') {
              setCurrentPanelRight(undefined);
              if (currentPanelRight?.onClose) {
                currentPanelRight?.onClose();
              }
              if (params?.onCloseConfirm) {
                params.onCloseConfirm();
              }
            } else {
              setCurrentPanelLeft(undefined);
              if (currentPanelLeft?.onClose) {
                currentPanelLeft?.onClose();
              }
            }

            if (params?.onCloseConfirm) {
              params.onCloseConfirm();
            }
          };

          const isDirty =
            (location === 'right' && currentPanelRight?.isDirty) ||
            (location === 'left' && currentPanelLeft?.isDirty);

          if (isDirty && !params?.forceClose) {
            confirmOpen({
              action: closeAction,
            });
          } else {
            closeAction();
          }
        };

        const setIsDirty = (location: PanelLocation) => (isDirty: boolean) => {
          if (location === 'right' && currentPanelRight) {
            setCurrentPanelRight({ ...currentPanelRight, isDirty });
          }
          if (location === 'left' && currentPanelLeft) {
            setCurrentPanelLeft({ ...currentPanelLeft, isDirty });
          }
        };

        if (defaultIsDirty) {
          setIsDirty(location)(true);
        }

        if (isOpenRight) {
          return children({
            isOpen: isOpenRight,
            setIsDirty: setIsDirty(location),
            data: currentPanelRight?.data as DataT,
            maybeClosePanel,
          });
        }
        if (isOpenLeft) {
          return children({
            isOpen: isOpenLeft,
            setIsDirty: setIsDirty(location),
            data: currentPanelLeft?.data as DataT,
            maybeClosePanel,
          });
        }
        return children({
          isOpen: location === 'right' ? isOpenRight : isOpenLeft,
          setIsDirty: noop,
          maybeClosePanel: noop,
        }); // Panel is closed so pass the noop functions
      }}
    />
  );
}
