import { useCallback, useMemo } from 'react';
import { useSweepCanvasFitView } from '../../sweep-canvas/useSweepCanvasFitView';
import { calculateElementsToFit } from './useFitAroundElements';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCanvasFilterSelectedValues,
  selectCanvasFiltersFunnelMap,
  selectItemsDataByValue,
  setMultipleCanvasFilterSelectedValues,
  setCanvasFiltersFunnelMap,
} from './canvasFiltersReducer';
import get from 'lodash/get';

export const useCanvasFilters = () => {
  const dispatch = useDispatch();
  const canvasFiltersValues = useSelector(selectCanvasFilterSelectedValues);
  const funnelMap = useSelector(selectCanvasFiltersFunnelMap);

  const { fitAroundNodes } = useSweepCanvasFitView();

  const focusOnCanvasElements = useCallback(
    (elementIds: string[], options = {}, fitAroundFirstStepOnly?: boolean) => {
      if (!elementIds.length || !funnelMap?.funnelsData) return;
      const nodeIds = calculateElementsToFit({
        funnelsData: funnelMap.funnelsData,
        recordTypesData: funnelMap.recordTypesData,
        selectedValues: elementIds,
      });
      fitAroundNodes(nodeIds, options, fitAroundFirstStepOnly);
    },
    [fitAroundNodes, funnelMap?.funnelsData, funnelMap?.recordTypesData],
  );

  const _setCanvasFiltersFunnelMap = useCallback(
    (_funnelMap: FunnelMap) => {
      dispatch(setCanvasFiltersFunnelMap({ funnelMap: _funnelMap }));
    },
    [dispatch],
  );

  const setFilterValues = useCallback(
    (_filterValues: { [filterKey: string]: string[] }, focusOnFilter?: string) => {
      dispatch(setMultipleCanvasFilterSelectedValues(_filterValues));
      if (focusOnFilter) {
        focusOnCanvasElements(_filterValues[focusOnFilter]);
      }
    },

    [dispatch, focusOnCanvasElements],
  );

  const clearAllValues = useCallback(() => {
    setFilterValues(
      Object.keys(canvasFiltersValues).reduce((acc, key) => ({ ...acc, [key]: [] }), {}),
    );
  }, [canvasFiltersValues, setFilterValues]);

  const itemsDataByValue = useSelector(selectItemsDataByValue);

  const filterValues = useMemo(() => {
    return Object.fromEntries(
      Object.entries(canvasFiltersValues).map(([filterKey, selectedValues]) => {
        return [
          filterKey,
          {
            selectedValues,
            selectedValuesData: selectedValues.map((value) => ({
              label: value,
              value: value,
              ...get(itemsDataByValue, [filterKey, value], {}),
            })),
          },
        ];
      }),
    );
  }, [canvasFiltersValues, itemsDataByValue]);

  return {
    filterValues,
    setFilterValues,
    focusOnCanvasElements,
    clearAllValues,
    _setCanvasFiltersFunnelMap,
  };
};
