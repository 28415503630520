import { Box, IconButton, PopperPlacementType } from '@mui/material';
import { CloseIconButton } from './CloseIconButton';
import { PopperWithArrow } from './PopperWithArrow';
import { Typography, colors } from '@sweep-io/sweep-design';
import { Warning, ChevronDown, ChevronUp } from '@sweep-io/sweep-design/dist/icons';

export const ErrorPopover = ({
  isOpen,
  errorText,
  displayChevrons,
  findNextIdx,
  currentIdx,
  totalItemsCount,
  criterionRef,
  onClose,
  placement = 'top',
}: {
  isOpen: boolean;
  errorText: string;
  displayChevrons?: boolean;
  findNextIdx?: Function;
  currentIdx?: number;
  totalItemsCount?: number;
  criterionRef?: any;
  onClose: () => void;
  placement?: PopperPlacementType;
}) => {
  if (!isOpen) {
    return <></>;
  }

  return (
    <PopperWithArrow
      placement={placement}
      PopperProps={{
        open: isOpen && Boolean(criterionRef),
        anchorEl: criterionRef,
        sx: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          background: colors.blush[500],
          color: colors.white,
          zIndex: 1300,
        },
      }}
      arrowColor={colors.blush[500]}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          '&:after': {
            content: '""',
            display: 'block',
            width: currentIdx ? '1px' : '0px',
            height: '19px',
            background: colors.blush[600],
          },
        }}
        gap="8px"
      >
        <Warning variant="large" />

        <Typography variant="caption-bold" color={colors.grey[900]}>
          {errorText}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {currentIdx !== -1 && totalItemsCount && (
          <Box sx={{ margin: '0 5px 0 15px' }}>
            <Typography variant="body" color={colors.grey[900]}>
              {currentIdx} of {totalItemsCount}
            </Typography>
          </Box>
        )}

        {displayChevrons && (
          <Box sx={{ color: colors.grey[900], display: 'flex', gap: '4px' }}>
            <IconButton
              onClick={() => findNextIdx && findNextIdx({ moveBy: 1 })}
              disabled={currentIdx === totalItemsCount}
            >
              <ChevronDown variant="tiny" />
            </IconButton>

            <IconButton
              onClick={() => findNextIdx && findNextIdx({ moveBy: -1 })}
              disabled={currentIdx === 1}
            >
              <ChevronUp variant="tiny" />
            </IconButton>

            <CloseIconButton
              sx={{
                background: colors.blush[600],
                fontSize: '12px',
              }}
              handleClose={onClose}
            />
          </Box>
        )}
      </Box>
    </PopperWithArrow>
  );
};
