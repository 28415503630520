import { ConfirmDialog } from '../../common/dialogs/ConfirmDialog';
import { useState } from 'react';
import AgentInstructionsContent from './AgentInstructionsContent';

const AgentInstructionsDialog = ({
  closeDialog,
  onConfirm,
  initialValue,
  placeholder,
}: {
  closeDialog: () => void;
  onConfirm: (instructions: string) => Promise<void>;
  initialValue: string;
  placeholder: string;
}) => {
  const [text, setText] = useState(initialValue);

  return (
    <ConfirmDialog
      isOpen={true}
      onCancel={closeDialog}
      handleClose={closeDialog}
      title="Instructions"
      titleVariant="h3"
      dialogContentSx={{ pt: 1, pb: 0 }}
      PaperProps={{
        sx: {
          maxWidth: '950px',
        },
      }}
      onConfirm={async () => {
        await onConfirm(text);
      }}
    >
      <AgentInstructionsContent
        value={text}
        onChange={(value) => setText(value)}
        placeholder={placeholder}
      />
    </ConfirmDialog>
  );
};

export default AgentInstructionsDialog;
