import noop from 'lodash/noop';
import { useMemo } from 'react';
import useAutomationsCanvasInteractions from '../pages/canvas-pages/useAutomationsCanvasInteractions';
import { useConfigurationCanvas } from '../pages/configuration-canvas/public/useConfigurationCanvas';
import { RoutingTabs, useRoutingTabs } from './useRoutingTabs';
import { Breadcrumbs } from '../documentation/selected-object/Breadcrumbs';
import {
  ConfigurationCanvasPanelContent,
  ConfigurationCanvasPanelHeader,
} from '../pages/configuration-canvas-panel';
import { RoutingTabsInfo } from './routingTabsInfo';
import { AssignmentsProps } from './Assignments';
import { useExpandedMode } from '../pages/configuration-canvas/panels-reserve-space/ReserveSpaceForPanelsCtx';
import { colors, Tabs } from '@sweep-io/sweep-design';
import { Stack, Box } from '@mui/material';
import useRoutingSectionElements from './useRoutingSectionElements';
import { HORIZONTAL_PADDING } from '../pages/configuration-canvas-panel/consts';
import { useRunOnceWhenTruthy } from '../common/useRunOnceWhenTruthy';

export const RoutingTabContent = ({
  goToRoutingHome,
  goToTab,
}: {
  goToRoutingHome: () => void;
  goToTab: (tab: RoutingTabs) => void;
}) => {
  const { routingTab } = useRoutingTabs();
  const { canvasFunnelMap } = useConfigurationCanvas();
  const { funnelsData, recordTypesData } = canvasFunnelMap;
  const { setHoveredItem } = useAutomationsCanvasInteractions();
  const { isExpandedMode } = useExpandedMode();

  //same props are used for "assignment rules" and "scheduled assignments"
  //if in the future we'll have Content component that require different pros - might need to change this logic
  const contentProps: AssignmentsProps = useMemo(
    () => ({
      funnelsData,
      recordTypesData,
      setHoveredItem,
      goToRoutingHome,
    }),
    [funnelsData, goToRoutingHome, recordTypesData, setHoveredItem],
  );

  const ContentComponent = routingTab && RoutingTabsInfo[routingTab].ContentComponent;
  const canDrillIn = routingTab && RoutingTabsInfo[routingTab].drillable;
  const breadcrumbList = [
    {
      label: 'Routing',
      value: 'Routing',
      onClick: () => {
        goToRoutingHome();
      },
    },
    {
      label: canDrillIn ? RoutingTabsInfo[routingTab].label : '',
      value: canDrillIn ? RoutingTabsInfo[routingTab].label : '',
      onClick: noop,
    },
  ];

  const { rulesElements, setupElements } = useRoutingSectionElements();

  const routingListItems = useMemo(
    () => [...rulesElements, ...setupElements],
    [rulesElements, setupElements],
  );

  const tabList = useMemo(
    () =>
      routingListItems.map((routingListItem) => ({
        ...routingListItem,
        badgeLabel: (routingListItem.elements.length ?? 0) + '',
      })),
    [routingListItems],
  );

  useRunOnceWhenTruthy(() => {
    goToTab(RoutingTabs.assignment);
  }, !routingTab);

  return (
    <>
      <ConfigurationCanvasPanelHeader withExpandedModeButton={true}>
        {isExpandedMode ? 'Routing' : <Breadcrumbs breadcrumbList={breadcrumbList} />}
      </ConfigurationCanvasPanelHeader>
      <ConfigurationCanvasPanelContent
        sx={{
          overflow: 'hidden',
          height: '100%',
          padding: 0, //needed in order to override the existing value (I dont know who needs it)
        }}
      >
        <Stack
          sx={{
            gap: 2,
            height: '100%',
            '.MuiTabs-root': {
              borderBottom: `1px solid ${colors.grey[120]}`,
            },
          }}
        >
          {isExpandedMode && (
            <Box
              sx={{
                padding: `0 ${HORIZONTAL_PADDING}px`,
              }}
            >
              <Tabs
                tabs={tabList}
                value={routingTab}
                onChange={(e, value) => goToTab(value as RoutingTabs)}
              />
            </Box>
          )}
          <Box mt={isExpandedMode ? 1 : 0} height="100%" overflow="hidden" pb={2}>
            {!!ContentComponent && <ContentComponent {...contentProps} />}
          </Box>
        </Stack>
      </ConfigurationCanvasPanelContent>
    </>
  );
};
