import { ReactNode } from 'react';
import { Box } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';

const CommentIcon = ({
  isRead,
  expandableClass,
  flexDirection = 'row',
  isResolved,
  children,
}: {
  isRead?: boolean;
  expandableClass?: string;
  isResolved?: boolean;
  flexDirection?: 'row' | 'column';
  children?: ReactNode;
}) => {
  const expandableSx = expandableClass
    ? {
        ['.' + expandableClass]: {
          display: 'none',
        },
        '&:hover ': {
          width: 'auto',
          minWidth: 240,
          padding: '12px',
          ['.' + expandableClass]: {
            display: 'flex',
            flexDirection: 'column',
          },
        },
      }
    : {};

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection,
        alignItems: 'flex-start',
        fontSize: 12,
        padding: '3px',
        height: children ? 'fit-content' : '100%',
        borderRadius: '16px 16px 16px 4px',
        backgroundColor: isRead ? colors.grey[900] : colors.blue[500],
        gap: 1,
        userSelect: 'none',
        transition: 'padding 0.1s linear',
        opacity: isResolved ? 0.3 : 1,
        ...expandableSx,
      }}
    >
      {children}
    </Box>
  );
};

export default CommentIcon;
