import { PayloadAction } from '@reduxjs/toolkit';
import { GlobalState } from './globalReducerTypes';
import { RootState } from '../index';
import { selectDefaultCreationEnvironment } from '../../components/pages/environments/environmentsReducer';
import { DeployStatusForTable } from '../../types/enums/DeployStatusForTable';

type ScheduledReportReducersProps = {
  addScheduledReport: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      automation: AutomationStructureNew;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
  updateScheduledReport: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      automationToUpdate: AutomationStructureNew;
      versionIdToUpdate: string;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
  toggleScheduledReport: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      automationToUpdate: AutomationStructureNew;
      versionIdToUpdate: string;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
  deleteScheduledReport: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      automationIdToDelete: string;
    }>,
  ) => any;
  updateScheduledReportDeployStatus: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      automationId: string;
      versionId: string;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
};
export const scheduledReportReducers: ScheduledReportReducersProps = {
  addScheduledReport: (state, action) => {
    const { crmOrgId, automation, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data) {
      return;
    }
    if (!state.environments[crmOrgId].data.scheduledReport) {
      state.environments[crmOrgId].data.scheduledReport = [];
    }
    state.environments[crmOrgId].data.scheduledReport?.push({
      ...automation,
      status: optimisticStatus,
    });
  },
  updateScheduledReport: (state, action) => {
    const { crmOrgId, automationToUpdate, versionIdToUpdate, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data?.scheduledReport) {
      return;
    }
    const indexToUpdate = state.environments[crmOrgId].data.scheduledReport?.findIndex(
      (r) =>
        r.automationId === automationToUpdate.automationId && r.versionId === versionIdToUpdate,
    );
    if (indexToUpdate !== undefined) {
      // @ts-ignore
      state.environments[crmOrgId].data.scheduledReport[indexToUpdate] = {
        ...automationToUpdate,
        status: optimisticStatus,
      };
    }
  },
  deleteScheduledReport: (state, action) => {
    const { crmOrgId, automationIdToDelete } = action.payload;
    if (!state.environments[crmOrgId]?.data?.scheduledReport) {
      return;
    }
    const indexToDelete = state.environments[crmOrgId].data.scheduledReport?.findIndex(
      (r) => r.automationId === automationIdToDelete,
    );
    if (indexToDelete !== undefined) {
      state.environments[crmOrgId].data.scheduledReport?.splice(indexToDelete, 1);
    }
  },
  toggleScheduledReport: (state, action) => {
    const { crmOrgId, automationToUpdate, versionIdToUpdate, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data?.scheduledReport) {
      return;
    }
    const indexToUpdate = state.environments[crmOrgId].data.scheduledReport?.findIndex(
      (r) =>
        r.automationId === automationToUpdate.automationId && r.versionId === versionIdToUpdate,
    );
    if (indexToUpdate !== undefined) {
      // @ts-ignore
      state.environments[crmOrgId].data.scheduledReport[indexToUpdate] = {
        ...automationToUpdate,
        status: optimisticStatus,
      };
    }
  },
  updateScheduledReportDeployStatus: (state, action) => {
    const { crmOrgId, automationId, versionId, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data?.scheduledReport) {
      return;
    }
    const indexToUpdate = state.environments[crmOrgId].data.scheduledReport?.findIndex(
      (r) => r.automationId === automationId && r.versionId === versionId,
    );
    if (indexToUpdate !== undefined) {
      // @ts-ignore
      state.environments[crmOrgId].data.scheduledReport[indexToUpdate].status = optimisticStatus;
    }
  },
};

export const selectCrmOrgScheduledReports = (crmOrgId: string) => (state: RootState) => {
  return state.global.environments[crmOrgId]?.data?.scheduledReport;
};

export const selectScheduledReports = (state: RootState) => {
  const crmOrg = selectDefaultCreationEnvironment(state);
  if (crmOrg?.id) {
    return selectCrmOrgScheduledReports(crmOrg.id)(state);
  }
};
