import { MenuItem, Popover, Stack } from '@mui/material';
import { Typography } from '@mui/material';
import {
  MarketingAttributionModelType,
  MarketingAttributionMultiTouchModel,
} from '@server/marketing-attribution';
import SweepSelect from '../../common/SweepSelect';
import { IconButton } from '@sweep-io/sweep-design';
import { Plus } from '@sweep-io/sweep-design/dist/icons';
import usePopover from '../../../hooks/usePopover';
import { useMarketingAttributionModels } from '../../pages/canvas-pages/canvas-hubspot-page/marketing-attribution/useMarketingAttributionModels';
import { useMemo, useState } from 'react';
import { useRunOnce } from '../../common/useRunOnce';
import { ConditionsWrapper } from '../ConditionsWrapper';
import { AutomationFormVariant } from '../../../types/enums/AutomationFormVariant';
import { Weights } from './Weights';
import {
  getMarketingAttributionCampaignFilter,
  getMarketingAttributionObjectList,
  getMarketingAttributionAction,
  getMarketingAttributionModelType,
  doesStageExist,
  getMarketingAttributionWeights,
} from './helpers';

interface MarketingAttributionFormProps {
  onChange: (data: DeepPartial<AutomationStructureNew>) => void;
  readonly: boolean;
  model: DeepPartial<AutomationStructureNew>;
  setDisplayObject: (object: string) => void;
  removeConditionsToTrigger: () => void;
  removeMarketingAttributionConditions: () => void;
  helperShowFieldContextMenu: (
    automationVariant: AutomationFormVariant,
    automationJson: DeepPartial<AutomationStructureNew>,
  ) => boolean | undefined;
  setAutomationJson: (data: DeepPartial<AutomationStructureNew>) => void;
  crmOrgId: string;
  automationVariant: AutomationFormVariant;
  ruleBuilderRef: React.RefObject<any>;
}

export const MarketingAttributionForm = ({
  onChange,
  readonly,
  model,
  setDisplayObject,
  removeConditionsToTrigger,
  removeMarketingAttributionConditions,
  helperShowFieldContextMenu,
  setAutomationJson,
  crmOrgId,
  automationVariant,
  ruleBuilderRef,
}: MarketingAttributionFormProps) => {
  const [closedWonStages, setClosedWonStages] = useState<{ _stageId: string; stageName: string }[]>(
    [],
  );
  const { isPopoverOpen, closePopover, openPopover, anchorElement, anchorRef } = usePopover();
  const { getClosedWonStages } = useMarketingAttributionModels();
  const stage = model.automationDetails?.automationParams?.stageEnter?.toStage?._stageId;
  const actions = model.automationDetails?.actions as MarketingAttributionModelAction[];
  const modelType = actions?.[0]?.actionParams?.modelType;
  const campaignCriteria = getMarketingAttributionCampaignFilter(model);

  const marketingAttributionObjectList = useMemo(
    () => (model ? getMarketingAttributionObjectList(model) : []),
    [model],
  );

  useRunOnce(() => {
    getClosedWonStages().then(setClosedWonStages);
  });

  return (
    <Stack height="100%" gap={2} pt={1.5}>
      <Typography variant="h2">Model type</Typography>
      <SweepSelect
        FormControlProps={{
          sx: { width: '284px' },
        }}
        SelectProps={{
          disabled: readonly,
          placeholder: 'Choose model',
          value: modelType ?? '',
          onChange: (event) => {
            const newModel = event.target.value as MarketingAttributionModelType;
            onChange({
              ...model,
              automationDetails: {
                ...model.automationDetails,
                automationParams: {
                  ...model.automationDetails?.automationParams,
                  stageEnter: {
                    toStage: { _stageId: '', stageName: '' },
                  },
                },
                actions: [
                  {
                    ...actions?.[0],
                    actionParams: { ...actions?.[0]?.actionParams, modelType: newModel },
                  },
                ],
              },
            });
          },
        }}
      >
        <MenuItem value="FIRST_TOUCH">First Touch</MenuItem>
        <MenuItem value="LAST_TOUCH">Last Touch</MenuItem>
        <MenuItem value="EVEN_DISTRIBUTION">Even Distribution</MenuItem>
        <MenuItem value="MULTI_TOUCH">Multi Touch</MenuItem>
      </SweepSelect>

      {modelType && (
        <>
          <Stack direction="row" gap={2} pt={2} pb={2} alignItems="center">
            <Typography variant="body">When Entering</Typography>
            <SweepSelect
              FormControlProps={{
                sx: { minWidth: '120px' },
              }}
              SelectProps={{
                disabled: readonly,
                placeholder: 'Choose stage',
                value: stage ?? '',
                onChange: (event) => {
                  const val = event.target.value;
                  const stage = closedWonStages.find((s) => s._stageId === val);

                  if (!stage) return;

                  onChange({
                    ...model,
                    automationDetails: {
                      ...model.automationDetails,
                      automationParams: {
                        ...model.automationDetails?.automationParams,
                        stageEnter: {
                          toStage: { _stageId: val as string, stageName: stage.stageName },
                        },
                      },
                    },
                  });
                },
              }}
            >
              {closedWonStages.map((s) => (
                <MenuItem key={s._stageId} value={s._stageId}>
                  {s.stageName}
                </MenuItem>
              ))}
            </SweepSelect>

            {stage && marketingAttributionObjectList.length > 0 && (
              <Stack direction="row" gap={2}>
                <IconButton
                  ref={anchorRef}
                  onClick={() => openPopover()}
                  variant="flat"
                  size="small"
                >
                  <Plus />
                </IconButton>

                <Popover
                  open={isPopoverOpen}
                  onClose={closePopover}
                  anchorEl={anchorElement}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                  <Stack p={1}>
                    {marketingAttributionObjectList.map((o) => (
                      <MenuItem
                        onClick={() => {
                          setDisplayObject(o);
                          closePopover();
                        }}
                        key={o}
                        value={o}
                        sx={{ padding: '8px 12px', width: '295px' }}
                      >
                        {o}
                      </MenuItem>
                    ))}
                  </Stack>
                </Popover>
              </Stack>
            )}
          </Stack>
        </>
      )}

      {model.automationDetails?.when?.isCriteria && (
        <ConditionsWrapper
          sx={{ pt: 2 }}
          removeConditionsToTrigger={removeConditionsToTrigger}
          customHeader={'Apply when these conditions from the Opportunity object are met:'}
          ruleBuilderProps={{
            ref: ruleBuilderRef,
            readOnly: readonly,
            crmOrgId: crmOrgId ?? '',
            objectType: 'Opportunity',
            sweepCondition: model.automationDetails?.when?.criteria,
            onChange: (newSweepCondition) => {
              const _criteria = model.automationDetails?.when?.criteria || {
                criteria: [],
                criteriaLogic: '',
              };
              const temp: CriteriaDetails = {
                ...model.automationDetails?.when,
                criteria: { ..._criteria, ...newSweepCondition },
              };
              setAutomationJson({
                ...model,
                automationDetails: {
                  ...model.automationDetails,
                  when: temp,
                },
              });
            },
            showFieldContextMenu: helperShowFieldContextMenu(automationVariant, model),
          }}
        />
      )}

      {getMarketingAttributionCampaignFilter(model)?.isCriteria && (
        <ConditionsWrapper
          sx={{ pt: 2 }}
          removeConditionsToTrigger={removeMarketingAttributionConditions}
          customHeader={'Apply when these conditions from the Campaign object are met:'}
          ruleBuilderProps={{
            ref: ruleBuilderRef,
            readOnly: readonly,
            crmOrgId: crmOrgId ?? '',
            objectType: 'Campaign',
            sweepCondition: {
              criteria: (campaignCriteria?.criteria as any) ?? [],
              criteriaLogic: campaignCriteria?.criteriaLogic ?? '',
            },
            onChange: (newSweepCondition) => {
              const _criteria = campaignCriteria || {
                criteria: [],
                criteriaLogic: '',
                isCriteria: true,
              };
              const action = getMarketingAttributionAction(model);
              const temp: any = { ..._criteria, ...newSweepCondition };

              setAutomationJson({
                ...model,
                automationDetails: {
                  ...model.automationDetails,
                  actions: [
                    {
                      ...action,
                      actionParams: {
                        ...action?.actionParams,
                        campaignFilter: temp,
                      },
                    },
                  ],
                },
              });
            },
            showFieldContextMenu: helperShowFieldContextMenu(automationVariant, model),
          }}
        />
      )}

      {doesStageExist(model) && (
        <Weights
          modelType={getMarketingAttributionModelType(model)}
          weights={getMarketingAttributionWeights(model)}
          onChange={(weights) => {
            const action = getMarketingAttributionAction(model);

            if (action && action.actionParams) {
              const actionParams = action.actionParams as MarketingAttributionMultiTouchModel;

              setAutomationJson({
                ...model,
                automationDetails: {
                  ...model.automationDetails,
                  actions: [
                    {
                      ...action,
                      actionParams: {
                        ...actionParams,
                        weights,
                      },
                    },
                  ],
                },
              });
            }
          }}
        />
      )}
    </Stack>
  );
};
