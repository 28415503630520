import { FunnelType } from '../../../../../types/enums/FunnelType';
import { ListItem } from '../../../../common/CollapseList';

export const stageToConnectStepItem = (stage: SweepStage): ListItem => ({
  value: stage._stageId,
  label: stage.stageName,
});

export const isSalesforceLink = (link: FunnelLink) =>
  link._type === undefined || link._type === FunnelType.SALESFORCE;

export const isThirdPartyLink = (link: FunnelLink) => link._type === FunnelType.THIRD_PARTY;
