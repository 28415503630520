import { useConfigurationCanvas } from '../configuration-canvas/public/useConfigurationCanvas';
import { useSelector } from 'react-redux';
import useAutomationsCanvasInteractions from './useAutomationsCanvasInteractions';
import { colors, Tabs } from '@sweep-io/sweep-design';
import { useState } from 'react';
import Alerts from '../../alerts/Alerts';
import {
  ConfigurationCanvasLeftPanel,
  ConfigurationCanvasPanelContent,
  ConfigurationCanvasPanelHeader,
} from '../configuration-canvas-panel';
import ScheduledReports from '../../alerts/ScheduledReports';
import { MultipleRuleBuildersProvider } from '../../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';
import {
  selectSlackIntegrationStatus,
  selectSlackUpgradeStatus,
  SlackConnectionStatus,
} from '../../../reducers/integrationsReducer';
import { useIntegrations } from '../../../hooks/useIntegrations';
import { useRunOnce } from '../../common/useRunOnce';
import { Stack, Box } from '@mui/material';
import { HORIZONTAL_PADDING } from '../configuration-canvas-panel/consts';

enum AlertsDialogTabTypes {
  ALERTS = 'Alerts',
  SCHEDULED_REPORTS = 'Scheduled reports',
}

export const CanvasAlertsPage = () => {
  const [activeTab, setActiveTab] = useState(AlertsDialogTabTypes.ALERTS);

  const { canvasFunnelMap } = useConfigurationCanvas();
  const { funnelsData, recordTypesData } = canvasFunnelMap;
  const { getSlackStatus } = useIntegrations();

  const slackStatus = useSelector(selectSlackIntegrationStatus);
  const shouldUpgradeSlack = useSelector(selectSlackUpgradeStatus);
  const withScheduledReports =
    slackStatus === SlackConnectionStatus.Connected && !shouldUpgradeSlack;
  const isTabsShown = withScheduledReports;

  useRunOnce(() => {
    getSlackStatus();
  });

  const { setHoveredItem } = useAutomationsCanvasInteractions();

  return (
    <ConfigurationCanvasLeftPanel
      withExpandedModeButton={true}
      sx={{
        gap: isTabsShown ? 1 : undefined,
      }}
    >
      <ConfigurationCanvasPanelHeader withExpandedModeButton={true}>
        Alerts
      </ConfigurationCanvasPanelHeader>
      <MultipleRuleBuildersProvider>
        <ConfigurationCanvasPanelContent
          sx={{
            overflow: 'hidden',
            height: '100%',
            padding: 0, //needed in order to override the existing value (I dont know who needs it)
            display: 'flex',
          }}
        >
          {/* by product definition we should display tabs only when more than one tab is visible */}
          <Stack
            sx={{
              flex: 1,
              overflow: 'hidden',
              gap: 2,
              '& .MuiTabs-root': {
                display: isTabsShown ? 'flex' : 'none',
              },
              '.MuiTabs-root': {
                borderBottom: `1px solid ${colors.grey[120]}`,
              },
            }}
          >
            <Box
              sx={{
                padding: `0 ${HORIZONTAL_PADDING}px`,
              }}
            >
              <Tabs
                value={activeTab}
                tabs={[
                  {
                    label: AlertsDialogTabTypes.ALERTS,
                    value: AlertsDialogTabTypes.ALERTS,
                  },
                  {
                    label: AlertsDialogTabTypes.SCHEDULED_REPORTS,
                    value: AlertsDialogTabTypes.SCHEDULED_REPORTS,
                  },
                ]}
                onChange={(event, tab) => {
                  setActiveTab(tab as AlertsDialogTabTypes);
                }}
              />
            </Box>
            {activeTab === AlertsDialogTabTypes.ALERTS && (
              <Alerts
                funnelsData={funnelsData}
                recordTypesData={recordTypesData}
                setHoveredItem={setHoveredItem}
              />
            )}
            {activeTab === AlertsDialogTabTypes.SCHEDULED_REPORTS && (
              <ScheduledReports
                funnelsData={funnelsData}
                recordTypesData={recordTypesData}
                setHoveredItem={setHoveredItem}
              />
            )}
          </Stack>
        </ConfigurationCanvasPanelContent>
      </MultipleRuleBuildersProvider>
    </ConfigurationCanvasLeftPanel>
  );
};
