import { XYPosition } from '@xyflow/react';

export const xYPositionOperation = (
  firstXy: XYPosition,
  secondXy: XYPosition,
  operation: 'add' | 'subtract' = 'add',
) => {
  const operationsModifier = operation === 'add' ? 1 : -1;
  return {
    x: firstXy.x + operationsModifier * secondXy.x,
    y: firstXy.y + operationsModifier * secondXy.y,
  };
};

/**
 * Adds two XY positions
 * @param first First XY position
 * @param second Second XY position
 * @returns The sum of the two positions
 */
export const addXYPositions = (first: XYPosition, second: XYPosition): XYPosition => ({
  x: first.x + second.x,
  y: first.y + second.y,
});

/**
 * Calculates the Euclidean distance between two XY positions
 * @param firstXy First XY position
 * @param secondXy Second XY position
 * @returns The distance between the two points
 */
export const calculateDistanceBetweenPositions = (
  firstXy: XYPosition,
  secondXy: XYPosition,
): number => {
  const dx = secondXy.x - firstXy.x;
  const dy = secondXy.y - firstXy.y;
  return Math.sqrt(dx * dx + dy * dy);
};
