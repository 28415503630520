import React from 'react';
import { DocumentationTabTypes } from '../../types/enums/DocumentationTabTypes';
import { PanelType } from '../panels/types';
import { ParsedRule } from '../parser/ParserTypes';
import { ConfigurationType } from './dependencies/types';
import { ConfigurationItem } from './dependencies/DependenciesTypes';

export type AutomationStructureWithParentType = AutomationStructureNew & {
  parentType: ConfigurationType;
};

export type ParsedRuleWithParentType = ParsedRule & {
  parentType: ConfigurationType;
};

export interface HoveredPin {
  hoveredObjectTypeName: string;
  hoveredTab: string;
}

export const documentationPanelType: PanelType = {
  name: 'documentation',
  location: 'left'
};

export type SweepElementTypes =
  | ConfigurationType.sweepAutomation
  | ConfigurationType.sweepAssignment
  | ConfigurationType.sweepScheduledAssignment
  | ConfigurationType.sweepAlert
  | ConfigurationType.sweepMatching
  | ConfigurationType.sweepDedupe
  | ConfigurationType.rollups;

export type SelectConfigurationItemProps = {
  crmOrgId: string;
  id: string;
  name: string;
  objectName: string;
};

export type SelectedObjectListElements = {
  value: string;
  label: string;
  content?: React.ReactNode;
  elements: ConfigurationItem[];
  badgeLabel?: number;
}[];

export type RecordTypesLabelsByNameAndObjectName = {
  [objectName: string]: { [rtName: string]: string };
};

export interface ObjectWithPills extends ObjectTypeName {
  pills?: {
    [key in DocumentationTabTypes]: number;
  };
}
export interface DispatchSingleObjectNameAndFocusOnElementProps {
  singleObjectName?: ObjectTypeName;
  tab?: DocumentationTabTypes;
  focusOnElement?: string;
  fitAroundFirstStepOnly?: boolean;
}

export type DispatchSingleObjectNameAndFocusOnElementFuncProps = (
  props: DispatchSingleObjectNameAndFocusOnElementProps,
) => void;
