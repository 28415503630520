import { Box, SxProps } from '@mui/material';
import { DEFAULT_VISIBILITY_MAP } from '../../../../../constants/visibility';
import { CanvasMode } from '../../../../sweep-canvas/canvas-types';
import { SweepCanvas } from '../../../../sweep-canvas/SweepCanvas';
import { colors } from '@sweep-io/sweep-design';
import { TemplateInterface } from '../templatesTypes';
import keyBy from 'lodash/keyBy';
import { useFunnelMapElements } from '../../../../funnel-map-canvas/useFunnelMapElements';
import { useMemo } from 'react';
import noop from 'lodash/noop';

export const TemplatePreview = ({
  sxProps,
  fitViewTimeoutValue,
  template,
}: {
  template: TemplateInterface;
  expanded?: boolean;
  sxProps?: SxProps;
  showPreviewBackground?: boolean;
  fitViewTimeoutValue?: number;
  canvasMode?: CanvasMode;
}) => {
  const funnelMap: FunnelMap = useMemo(
    () => ({
      createdAt: new Date().toISOString(),
      createdById: '1',
      id: '1',
      defaultCrmOrgId: '',
      funnels: keyBy(
        template.funnelTemplates.map((funnelTemplate) => ({
          id: funnelTemplate.id,
          position: funnelTemplate.position,
        })),
        'id',
      ),
      recordTypes: {},
      isPinned: false,
      name: template.name,
      updatedAt: new Date().toISOString(),
      updatedById: '1',
      funnelsData: keyBy(
        template.funnelTemplates.map((funnelTemplate) => ({
          id: funnelTemplate.id,
          accountId: '1',
          createdAt: new Date().toISOString(),
          createdById: '1',
          name: funnelTemplate.name,
          description: '',
          updatedAt: new Date().toISOString(),
          updatedById: '1',
          funnelDetails: funnelTemplate.templateFunnelDetails,
          recordType: {
            description: '',
            id: '1',
            name: '',
            label: '',
            objectName: '',
          },
          snapshotsIds: [],
          isPinned: false,
        })),
        'id',
      ),
      recordTypesData: {},
      hubspotFunnels: {},
      hubspotFunnelsData: {},
      thirdPartyFunnels: {},
      thirdPartyFunnelsData: {},
    }),
    [template.funnelTemplates, template.name],
  );
  const { sweepNodes, sweepEdges, sweepGroups } = useFunnelMapElements({
    funnelMap,
    visibilityMap: DEFAULT_VISIBILITY_MAP,
    objectTypesByName: {},
  });

  return (
    <Box
      sx={{
        height: `168px`,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `1px solid ${colors.grey[300]}`,
        borderRadius: '4px',
        ...sxProps,
      }}
      data-testid="funnel-preview"
    >
      {sweepNodes && sweepNodes.length > 0 && (
        <div
          style={{
            width: '100%',
            height: '100%',
            border: `1px solid transparent`,
            borderRadius: '4px',
          }}
        >
          <SweepCanvas
            sweepGroups={sweepGroups}
            sweepNodes={sweepNodes}
            sweepEdges={sweepEdges}
            canvasMode={CanvasMode.PREVIEW2}
            fitViewTimeoutValue={fitViewTimeoutValue}
            visibilityMap={DEFAULT_VISIBILITY_MAP}
            onConnectClick={noop}
            readonly
          />
        </div>
      )}
    </Box>
  );
};
