import { useCallback, useContext } from 'react';
import { MaybeClosePanelFunc, PanelType } from './types';
import { PanelsContext } from './PanelsContext';

interface IMaybeOpenPanel<DataT = unknown> {
  panelType: PanelType<DataT>;
  instanceId?: string;
  data?: DataT;
  onOpen?: () => void;
  onClose?: () => void;
}

export function usePanels() {
  const {
    currentPanelRight,
    setCurrentPanelRight,
    currentPanelLeft,
    setCurrentPanelLeft,
    confirmOpen,
    isInitialized,
  } = useContext(PanelsContext);
  if (!isInitialized) {
    throw new Error(
      'PanelsProvider is not initialized. Please wrap your app/component with PanelsProvider',
    );
  }

  const maybeOpenPanel = useCallback(
    <DataT,>({ panelType, instanceId, data, onOpen, onClose }: IMaybeOpenPanel<DataT>) => {
      const { name, location } = panelType;
      const nameAndInstance = instanceId ? `${name}-${instanceId}` : name;

      const confirmAction = () => {
        if (location === 'right') {
          setCurrentPanelRight({
            panelType: {
              ...panelType,
              name: nameAndInstance,
            },
            data,
            onClose,
          });
        } else {
          setCurrentPanelLeft({
            panelType: {
              ...panelType,
              name: nameAndInstance,
            },
            data,
            onClose,
          });
        }

        if (onOpen) {
          onOpen();
        }
      };

      const isDirty =
        (location === 'right' && currentPanelRight?.isDirty) ||
        (location === 'left' && currentPanelLeft?.isDirty);
      if (isDirty) {
        confirmOpen({
          action: confirmAction,
        });
      } else {
        confirmAction();
      }
    },
    [
      confirmOpen,
      currentPanelLeft?.isDirty,
      currentPanelRight?.isDirty,
      setCurrentPanelLeft,
      setCurrentPanelRight,
    ],
  );

  const forceCloseAllPanels = useCallback(() => {
    setCurrentPanelRight(undefined);
    setCurrentPanelLeft(undefined);
  }, [setCurrentPanelRight, setCurrentPanelLeft]);

  const isPanelOpen = useCallback(
    <DataT,>(panelType: PanelType<DataT>, instanceId?: string) => {
      const { name, location } = panelType;
      const nameAndInstance = instanceId ? `${name}-${instanceId}` : name;
      return location === 'right'
        ? currentPanelRight?.panelType.name === nameAndInstance
        : currentPanelLeft?.panelType.name === nameAndInstance;
    },
    [currentPanelLeft?.panelType.name, currentPanelRight?.panelType.name],
  );
  const maybeCloseActivePanel: MaybeClosePanelFunc = useCallback(
    (params) => {
      const closeAction = () => {
        setCurrentPanelRight(undefined);
        setCurrentPanelLeft(undefined);

        currentPanelRight?.onClose?.();
        currentPanelLeft?.onClose?.();

        if (params?.onCloseConfirm) {
          params.onCloseConfirm();
        }
      };

      const isDirty = currentPanelRight?.isDirty || currentPanelLeft?.isDirty;
      if (isDirty && !params?.forceClose) {
        confirmOpen({
          action: closeAction,
        });
      } else {
        closeAction();
      }
    },
    [confirmOpen, currentPanelLeft, currentPanelRight, setCurrentPanelLeft, setCurrentPanelRight],
  );

  const getPanelDataIfOpened = useCallback(
    <DataT,>(panelType: PanelType<DataT>, instanceId?: string) => {
      const { location } = panelType;
      if (isPanelOpen(panelType, instanceId)) {
        return location === 'right'
          ? (currentPanelRight?.data as DataT)
          : (currentPanelLeft?.data as DataT);
      }
    },
    [currentPanelLeft?.data, currentPanelRight?.data, isPanelOpen],
  );

  return {
    maybeOpenPanel,
    forceCloseAllPanels,
    isPanelOpen,
    isCurrentPanelDirty: Boolean(currentPanelRight?.isDirty) || Boolean(currentPanelLeft?.isDirty),
    maybeCloseActivePanel,
    getPanelDataIfOpened,
  };
}
