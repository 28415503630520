import {
  ConfigurationCanvasProvider,
  useConfigurationCanvasContext,
} from './ConfigurationCanvasContext';
import { ConfigurationPages } from './configurationPages';
import { PanelsProvider } from '../../panels/PanelsContext';
import { CanvasContextProvider } from '../../sweep-canvas/CanvasContext';
import LoadingSweep from '../../common/LoadingSweep';
import { EnvironmentPageLoader, useLoadEnvironmentCanvas } from './EnvironmentPageLoader';
import { clearLeadingField, setEnvironmentFunnelMap } from '../../../reducers/global/globalReducer';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import noop from 'lodash/noop';
import { RecordTypeLeadingObjectProvider } from './canvas-container/record-type-leading-object-panel/RecordTypeLeadingObjectCtx';
import {
  setLeadingFieldForRecordType as setLeadingFieldForRecordTypeAction,
  setRecordTypeDataValues as setRecordTypeDataValuesAction,
  addRecordTypeDataEntries as addRecordTypeDataEntriesAction,
  hideRecordType as hideRecordTypeAction,
} from '../../../reducers/global/globalReducer';

const ConfigurationCanvasWrapper = ({ page }: { page: ConfigurationPages }) => {
  const { canvasCrmOrgId, showOnlyDeployedElementsInPills } = useConfigurationCanvasContext();

  if (!canvasCrmOrgId) return <LoadingSweep />;

  return (
    <EnvironmentPageLoader
      configurationPage={page}
      crmOrgId={canvasCrmOrgId}
      showOnlyDeployedElementsInPills={!!showOnlyDeployedElementsInPills}
    />
  );
};

interface ConfigurationCanvasProps {
  page: ConfigurationPages;
  showOnlyDeployedElementsInPills?: boolean;
  forceProduction?: boolean;
}

const ConfigurationCanvasWithProviders = ({
  page,
  showOnlyDeployedElementsInPills,
  crmOrgId,
  envFunnelMap,
}: {
  page: ConfigurationPages;
  showOnlyDeployedElementsInPills?: boolean;
  crmOrgId: string;
  envFunnelMap: FunnelMap;
}) => {
  const dispatch = useDispatch();

  const setCanvasFunnelMap = useCallback(
    (funnelMap: FunnelMap) => {
      dispatch(
        setEnvironmentFunnelMap({
          crmOrgId,
          funnelMap,
        }),
      );
    },
    [crmOrgId, dispatch],
  );

  const setLeadingFieldAndValuesForRecordType = useCallback(
    ({
      recordTypeApiName,
      leadingField,
      recordTypeDataValues,
      recordTypeName,
    }: {
      recordTypeApiName: string;
      recordTypeName: string;
      recordTypeDataValues: PicklistValue[];
      leadingField: string;
      crmOrgId: string;
    }) => {
      dispatch(
        setLeadingFieldForRecordTypeAction({
          crmOrgId,
          recordTypeApiName,
          leadingFieldName: recordTypeName,
        }),
      );
      dispatch(
        setRecordTypeDataValuesAction({
          crmOrgId,
          recordTypeDataValues,
          recordTypeApiName,
          leadingField,
        }),
      );
    },
    [crmOrgId, dispatch],
  );

  const clearLeadingFieldForRecordType = useCallback(
    ({ recordTypeApiName, crmOrgId }: { recordTypeApiName: string; crmOrgId: string }) => {
      dispatch(
        clearLeadingField({
          crmOrgId,
          recordTypeApiName,
        }),
      );
    },
    [dispatch],
  );

  const addRecordTypeDataEntries = useCallback(
    ({ crmOrgId, recordTypes }: { crmOrgId: string; recordTypes: SingleRecordType[] }) => {
      dispatch(addRecordTypeDataEntriesAction({ crmOrgId, recordTypes }));
    },
    [dispatch],
  );

  const hideRecordType = useCallback(
    ({ recordTypeApiName, crmOrgId }: { recordTypeApiName: string; crmOrgId: string }) => {
      dispatch(
        hideRecordTypeAction({
          crmOrgId,
          recordTypeApiName,
        }),
      );
    },
    [dispatch],
  );

  return (
    <CanvasContextProvider>
      <PanelsProvider confirmOpen={noop}>
        <RecordTypeLeadingObjectProvider
          setLeadingFieldAndValuesForRecordType={setLeadingFieldAndValuesForRecordType}
          addRecordTypeDataEntries={addRecordTypeDataEntries}
          clearLeadingFieldForRecordType={clearLeadingFieldForRecordType}
          hideRecordType={hideRecordType}
        >
          <ConfigurationCanvasProvider
            appCrmOrgId={crmOrgId}
            canvasCrmOrgId={crmOrgId}
            appFunnelMap={envFunnelMap}
            canvasFunnelMap={envFunnelMap}
            setCanvasFunnelMap={setCanvasFunnelMap}
            onSweepElementsChange={noop}
            showOnlyDeployedElementsInPills={!!showOnlyDeployedElementsInPills}
          >
            <ConfigurationCanvasWrapper page={page} />
          </ConfigurationCanvasProvider>
        </RecordTypeLeadingObjectProvider>
      </PanelsProvider>
    </CanvasContextProvider>
  );
};

const CanvasLoader = ({
  page,
  showOnlyDeployedElementsInPills,
  forceProduction,
}: ConfigurationCanvasProps) => {
  const response = useLoadEnvironmentCanvas({ forceProduction });
  if (!response.envLoadedAndUpdated) return <LoadingSweep />;
  const { crmOrgId, envFunnelMap } = response;
  return (
    <ConfigurationCanvasWithProviders
      page={page}
      showOnlyDeployedElementsInPills={showOnlyDeployedElementsInPills}
      crmOrgId={crmOrgId}
      envFunnelMap={envFunnelMap}
    />
  );
};

const AppConfigurationCanvas = (props: ConfigurationCanvasProps) => {
  /*
    Since we are importing ConfigurationCanvas on the APP ,
    To make sure we use the same instance of the component
    And not re-render it on every route change
    We can't use hooks here has it triggers a very nasty warning
    So we wrap it in a inner component
  */
  return <CanvasLoader {...props} />;
};

export default AppConfigurationCanvas;
