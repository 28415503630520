import { RoutingTabs } from '../components/routing/useRoutingTabs';
import { PAGE_VISIT_EVENTS } from '../services/events';
import { PaywallSource } from '../components/dashboard/paywall/paywallTypes';
import documentationImg from '../components/dashboard/fetching-env-page/images/documentation.png';
import { STORYLANE_URLS } from '../components/common/url-consts';

type AppRoute = {
  route: string;
  withCanvas: boolean;
  skipRedirectCallbackAuth0: boolean;
  disableRedirectToOnboarding: boolean;
  openSideMenu: boolean;
  isRequiredDefaultCreationEnvConnection: boolean;
  paywallBlockSource?: PaywallSource; //relevant only for the pages that are blocked by paywall
  documentTitle?: string; //if not provided, a default will be used as document title
  mixpanelPageVisitEvent?: PAGE_VISIT_EVENTS;
  initialFetchBlock?: {
    //add only in case we need a page block during initial fetch
    imageSrc: string;
    url: string;
    title: string;
  };
};

type AppRoutes = { [key: string]: AppRoute };

export const appRoutes: AppRoutes = {
  documentation: {
    route: '/documentation',
    withCanvas: true,
    skipRedirectCallbackAuth0: false,
    disableRedirectToOnboarding: false,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: true,
    documentTitle: 'Documentation - Sweep',
    mixpanelPageVisitEvent: PAGE_VISIT_EVENTS.documentation,
    initialFetchBlock: {
      imageSrc: documentationImg,
      url: STORYLANE_URLS.documentation,
      title: 'Documentation',
    },
  },
  automations: {
    route: '/automations',
    withCanvas: true,
    skipRedirectCallbackAuth0: false,
    disableRedirectToOnboarding: false,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: true,
    paywallBlockSource: PaywallSource.AUTOMATIONS,
    documentTitle: 'Automations - Sweep',
    mixpanelPageVisitEvent: PAGE_VISIT_EVENTS.automations,
  },
  alerts: {
    route: '/alerts',
    withCanvas: true,
    skipRedirectCallbackAuth0: false,
    disableRedirectToOnboarding: false,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: true,
    paywallBlockSource: PaywallSource.ALERTS,
    documentTitle: 'Alerts - Sweep',
    mixpanelPageVisitEvent: PAGE_VISIT_EVENTS.alerts,
  },
  routing: {
    route: '/routing',
    withCanvas: true,
    skipRedirectCallbackAuth0: false,
    disableRedirectToOnboarding: false,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: true,
    paywallBlockSource: PaywallSource.ROUTING,
    documentTitle: 'Routing - Sweep',
    mixpanelPageVisitEvent: PAGE_VISIT_EVENTS.routing,
  },
  hubspot: {
    route: '/hubspot',
    withCanvas: true,
    skipRedirectCallbackAuth0: false,
    disableRedirectToOnboarding: false,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: false,
    paywallBlockSource: PaywallSource.HUBSPOT,
    mixpanelPageVisitEvent: PAGE_VISIT_EVENTS.hubspot,
  },
  marketingAttribution: {
    route: '/marketing-attribution',
    withCanvas: true,
    skipRedirectCallbackAuth0: false,
    disableRedirectToOnboarding: false,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: true,
    paywallBlockSource: PaywallSource.HUBSPOT, //TODO change to marketing
    mixpanelPageVisitEvent: PAGE_VISIT_EVENTS.hubspot, //TODO change to marketing
  },
  dedup: {
    route: '/dedup',
    withCanvas: true,
    skipRedirectCallbackAuth0: false,
    disableRedirectToOnboarding: false,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: true,
    paywallBlockSource: PaywallSource.DEDUP,
    mixpanelPageVisitEvent: PAGE_VISIT_EVENTS.dedup,
  },
  getStarted: {
    route: '/get-started',
    withCanvas: false,
    skipRedirectCallbackAuth0: false,
    disableRedirectToOnboarding: false,
    openSideMenu: true,
    isRequiredDefaultCreationEnvConnection: false,
    documentTitle: 'Get Started - Sweep',
  },
  funnelMapsPage: {
    route: '/funnels',
    withCanvas: false,
    skipRedirectCallbackAuth0: false,
    disableRedirectToOnboarding: false,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: false,
    documentTitle: 'Funnel Editor - Sweep',
    mixpanelPageVisitEvent: PAGE_VISIT_EVENTS.funnels,
  },
  sfChangeFeed: {
    route: '/change-feed',
    withCanvas: false,
    skipRedirectCallbackAuth0: false,
    disableRedirectToOnboarding: false,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: true,
    documentTitle: 'Change Feed - Sweep',
    mixpanelPageVisitEvent: PAGE_VISIT_EVENTS.changeFeed,
  },
  rollups: {
    route: '/rollups',
    withCanvas: false,
    skipRedirectCallbackAuth0: false,
    disableRedirectToOnboarding: false,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: true,
    documentTitle: 'Rollups - Sweep',
    mixpanelPageVisitEvent: PAGE_VISIT_EVENTS.rollups,
  },
  reports: {
    route: '/reports',
    withCanvas: false,
    skipRedirectCallbackAuth0: false,
    disableRedirectToOnboarding: false,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: false,
    paywallBlockSource: PaywallSource.REPORTS,
    documentTitle: 'Reports - Sweep',
    mixpanelPageVisitEvent: PAGE_VISIT_EVENTS.reports,
  },
  devopsCenter: {
    route: '/compare-and-deploy',
    withCanvas: false,
    skipRedirectCallbackAuth0: false,
    disableRedirectToOnboarding: false,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: true,
    paywallBlockSource: PaywallSource.DEVOPS_CENTER,
    documentTitle: 'Compare and Deploy - Sweep',
    mixpanelPageVisitEvent: PAGE_VISIT_EVENTS.devopsCenter,
  },
  aiAssistant: {
    route: '/support-ai',
    withCanvas: false,
    skipRedirectCallbackAuth0: false,
    disableRedirectToOnboarding: false,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: true,
    paywallBlockSource: PaywallSource.AI_ASSISTANT,
    mixpanelPageVisitEvent: PAGE_VISIT_EVENTS.aiAssistant,
  },
  external: {
    route: '/external',
    withCanvas: false,
    skipRedirectCallbackAuth0: false,
    disableRedirectToOnboarding: false,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: false,
  },
  salesforce: {
    route: '/salesforce-oauth2-redirect',
    withCanvas: false,
    skipRedirectCallbackAuth0: false,
    disableRedirectToOnboarding: true,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: false,
  },
  hubspotRedirect: {
    route: '/hubspot-oauth2-redirect',
    withCanvas: false,
    skipRedirectCallbackAuth0: true,
    disableRedirectToOnboarding: false,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: false,
  },
  teamsRedirect: {
    route: '/teams-oauth2-redirect',
    withCanvas: false,
    skipRedirectCallbackAuth0: true,
    disableRedirectToOnboarding: false,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: false,
  },
  demoComponents: {
    route: '/demo-components',
    withCanvas: false,
    skipRedirectCallbackAuth0: false,
    disableRedirectToOnboarding: false,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: false,
  },
  demoRuleBuilder: {
    route: '/demo-rule-builder',
    withCanvas: false,
    skipRedirectCallbackAuth0: false,
    disableRedirectToOnboarding: false,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: false,
  },
  home: {
    route: '/',
    withCanvas: false,
    skipRedirectCallbackAuth0: false,
    disableRedirectToOnboarding: false,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: false,
  },
  slack: {
    route: '/slack/oauth2-redirect',
    withCanvas: false,
    skipRedirectCallbackAuth0: true,
    disableRedirectToOnboarding: false,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: false,
  },
  version: {
    route: '/version',
    withCanvas: false,
    skipRedirectCallbackAuth0: false,
    disableRedirectToOnboarding: false,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: false,
  },
  freeTierOnBoarding: {
    route: '/onboarding',
    withCanvas: false,
    skipRedirectCallbackAuth0: false,
    disableRedirectToOnboarding: false,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: false,
  },
  canvasUnited: {
    route: '/multi-funnel/flow',
    withCanvas: true,
    skipRedirectCallbackAuth0: false,
    disableRedirectToOnboarding: false,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: false,
    documentTitle: 'Funnel Editor - Sweep',
  },
  canvasUnitedSnapshot: {
    route: '/multi-funnel/view',
    withCanvas: true,
    skipRedirectCallbackAuth0: false,
    disableRedirectToOnboarding: false,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: false,
  },
  questionnaire: {
    route: '/questionnaire',
    withCanvas: false,
    skipRedirectCallbackAuth0: false,
    disableRedirectToOnboarding: false,
    openSideMenu: false,
    isRequiredDefaultCreationEnvConnection: false,
  },
};

export const ROUTING_TAB = 'tab';
export const ROUTING_GROUPS = `${appRoutes.routing.route}/${ROUTING_TAB}/${RoutingTabs.groups}`;
export const ROUTING_TERRITORIES = `${appRoutes.routing.route}/${ROUTING_TAB}/${RoutingTabs.territories}`;
export const ROUTING_MEMBERS = `${appRoutes.routing.route}/${ROUTING_TAB}/${RoutingTabs.members}`;
export const ROUTING_ASSIGNMENT_RULES = `${appRoutes.routing.route}/${ROUTING_TAB}/${RoutingTabs.assignment}`;
export const ROUTING_SCHEDULED_ASSIGNMENTS = `${appRoutes.routing.route}/${ROUTING_TAB}/${RoutingTabs.scheduled}`;

const allAppRoutes = Object.values(appRoutes);
const getRoutesByCriteria = (filterFn: (route: AppRoute) => boolean) =>
  getAppRoutesByCriteria(filterFn).map((r) => r.route);

const getAppRoutesByCriteria = (filterFn: (route: AppRoute) => boolean) =>
  allAppRoutes.filter(filterFn);

//returns the first item that matches this path
export const getAppRouteByPath = (path: string) => {
  return allAppRoutes.find((appRoute) => path.startsWith(appRoute.route));
};

export const RoutesToSkipRedirectCallbackAuth0 = getRoutesByCriteria(
  (route) => route.skipRedirectCallbackAuth0,
);
export const RoutesToDisableRedirectToOnboarding = getRoutesByCriteria(
  (route) => route.disableRedirectToOnboarding,
);
export const RoutesToOpenSideMenu = getRoutesByCriteria((route) => route.openSideMenu);
export const RoutesThatRequireDefaultCreationEnvConnection = getRoutesByCriteria(
  (route) => route.isRequiredDefaultCreationEnvConnection,
);
export const RoutesThatRequireInitialFetch = getRoutesByCriteria(
  (route) => !!route.initialFetchBlock,
);
export const PaywallV2Routes = getRoutesByCriteria((route) => !!route.paywallBlockSource);
export const AppRoutesWithTitles = getAppRoutesByCriteria((route) => !!route.documentTitle);
export const AppRoutesWithPageVisitEvent = getAppRoutesByCriteria(
  (route) => !!route.mixpanelPageVisitEvent,
);
export const ConfigurationCanvasPagesRoutes = getRoutesByCriteria((route) => route.withCanvas);

export const DEFAULT_ROUTE = appRoutes.getStarted.route;
