import pluralize from 'pluralize';
import { humanizeDate } from '../../helpers/humanizeDate';
import { TableWithHeader, TableWithHeaderProps } from './shared/TableWithHeader';
import { TableTypography } from './shared/helpers';
import { InactiveTag } from '../activity-state/InactiveTag';
interface NoButtonDefaultTableProps
  extends Pick<TableWithHeaderProps, 'headerVariant' | 'tableHeader'> {
  items: NoButtonDefaultTableItem[];
  shortListViewCounter?: number;
}

interface NoButtonDefaultTableItem {
  id: string;
  label: string;
  lastModified: string;
  isActive: boolean;
}

const columns = [
  { field: 'label', headerName: 'Name', width: '215px' },
  { field: 'lastModified', headerName: 'Last Modified' },
];

const renderRows = ({ items }: { items: NoButtonDefaultTableItem[] }) => {
  return items.map((row) => ({
    id: row.id,
    label: (
      <>
        <TableTypography>{row.label}</TableTypography>
        <InactiveTag isActive={row.isActive} />
      </>
    ),
    lastModified: (
      <TableTypography>{humanizeDate({ dateOrTimestamp: row.lastModified })}</TableTypography>
    ),
  }));
};

export const NoButtonDefaultTable = ({
  items,
  tableHeader,
  ...props
}: NoButtonDefaultTableProps) => {
  const rows = renderRows({ items });
  return (
    <TableWithHeader
      {...props}
      key={`tableHeader_${tableHeader}`}
      tableHeader={pluralize(tableHeader, rows.length)}
      columns={columns}
      rows={rows}
    />
  );
};
