import { Box, Tooltip } from '@mui/material';
import { Tag, Typography, colors } from '@sweep-io/sweep-design';
import { useEffect, useRef, useState } from 'react';
import { DataTableVariant } from '../../common/table/TableTypes';
import { dataTableVariants } from '../../common/table/dataTableVariants';

const isTextClamped = (elm: any) => elm.scrollHeight > elm.clientHeight;

export const FieldLabelWithNameInTooltip = ({
  name,
  label,
  isRollup,
  fontColor,
}: {
  name: string;
  label: string;
  isRollup?: boolean;
  fontColor?: string;
}) => {
  const ref = useRef();
  const [isClamped, setIsClamped] = useState(false);
  const tooltip = isClamped ? `${label} (${name})` : name;

  useEffect(() => {
    setIsClamped(isTextClamped(ref.current));
  }, [ref]);

  return (
    <Box id={name} sx={{ overflow: 'hidden' }}>
      <Tooltip placement="bottom-start" title={tooltip}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            //TODO add support in design system SWEEP-4335
            '& .MuiTypography-root': {
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              whiteSpace: 'break-spaces',
              wordBreak: 'break-word',
            },
          }}
          ref={ref}
        >
          <Typography
            ellipsis={isClamped}
            variant={dataTableVariants[DataTableVariant.narrow].fontVariant}
            color={fontColor ?? dataTableVariants[DataTableVariant.narrow].fontColor}
          >
            {label}
          </Typography>
          {isRollup && <Tag label="Rollup" color={colors.blue[100]} />}
        </Box>
      </Tooltip>
    </Box>
  );
};
