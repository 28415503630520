import { useEffect, useMemo, useRef } from 'react';
import { CanvasElementType } from '../canvas-types';
import { useSweepCanvasState } from '../internal-context/CanvasStateContext';
import { uniqueId } from '../../../lib/uniqueId';
import { useNewEditableNodeFactory } from '../factories/useNewEditableNodeFactory';
import { RfNodeGroup } from '../canvas-types/nodeTypesData';
import { useSweepCanvasPropsCtx } from '../internal-context/SweepCanvasPropsCtx';

export const useNewNodeOnEmptyGroupEffect = () => {
  const { moveGroups } = useSweepCanvasPropsCtx();
  const { canvasNodes, setCanvasNodes } = useSweepCanvasState();
  const { createNewNode } = useNewEditableNodeFactory();

  const firstEmptyGroupId = useMemo(() => {
    const nonEmptyGroups = new Set(
      canvasNodes
        .filter((node) => node.type === CanvasElementType.REGULAR)
        .map((node) => node.parentId),
    );
    const firstEmptyGroupId = canvasNodes.find(
      (node) => node.type === CanvasElementType.GROUP && !nonEmptyGroups.has(node.id),
    )?.id;

    return firstEmptyGroupId;
  }, [canvasNodes]);

  const emptyNodeGroupIdInEdit = useRef<string | undefined>(undefined);

  useEffect(() => {
    if (firstEmptyGroupId) {
      if (emptyNodeGroupIdInEdit.current !== firstEmptyGroupId) {
        setCanvasNodes((nodes) => {
          const group = nodes.find((node) => node.id === firstEmptyGroupId) as RfNodeGroup;
          if (group) {
            const newNode = createNewNode({
              newNodeId: uniqueId(),
              position: { x: 0, y: 0 },
              isNb: false,
              parentId: group.id,
              objectType: group.data.objectType,
              originStepName: '',
              sourceId: '',
              isInGroupMouseMoveMode: Boolean(moveGroups),
              origin: {
                type: 'none',
              },
            });

            return [...nodes.filter((node) => node.type !== CanvasElementType.EDITABLE), newNode];
          } else {
            emptyNodeGroupIdInEdit.current = undefined;
            return [...nodes.filter((node) => node.type !== CanvasElementType.EDITABLE)];
          }
        });
      }
    } else {
      setCanvasNodes((nodes) => {
        return nodes.filter((node) => node.type !== CanvasElementType.EDITABLE);
      });
      emptyNodeGroupIdInEdit.current = undefined;
    }
  }, [createNewNode, firstEmptyGroupId, moveGroups, setCanvasNodes]);
};
