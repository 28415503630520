import { useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { useSnapshots } from '../../../hooks/useSnapshots';
import sortListByDate from '../../helpers/sortListByDate';
import { selectSnapshotsList } from '../../../reducers/snapshotsReducer';
import { displayUserInfo } from '../../../lib/displayUserInfo';
import orderBy from 'lodash/orderBy';
import { isStageNurturingBucket } from '../../../models/stageModel';
import { humanizeDate, humanizeDateVariants } from '../../helpers/humanizeDate';
import { selectAccountUsers } from '../../../reducers/accountUsersReducer';
import { UserInfo } from '../../../types/UserInfoTypes';
import { OPTION_ALL } from '../../common/single-select-filter/utils';
import { DataTable } from '../../common/table/DataTable';
import { FunnelVersionData } from './FunnelVersionsTypes';
import { __SWEEP_DESIGN_TYPOGRAPHY_STYLES___, Button } from '@sweep-io/sweep-design';
import buildURLQuery from '../../../lib/buildURLQuery';
import { appRoutes } from '../../../constants/appRoutes';
import useSweepNavigate from '../../common/useSweepNavigate';
import { DataTableBaseColumn, DataTableVariant } from '../../common/table/TableTypes';
import { EditableLabel } from '../../common/EditableLabel';
import { dataTableVariants } from '../../common/table/dataTableVariants';

const FunnelVersionsTable = ({
  funnel,
  filterByCrmOrgId,
  onNavigateCallback,
  funnelMapId,
  funnelMapCrmOrgId,
}: {
  funnel: Funnel;
  filterByCrmOrgId: string;
  onNavigateCallback?: () => void;
  funnelMapId: string;
  funnelMapCrmOrgId: string;
}) => {
  const { navigate } = useSweepNavigate();
  const users = useSelector(selectAccountUsers);
  const snapshotsList = useSelector(selectSnapshotsList);
  const { editSnapshot } = useSnapshots();

  const listItems = useMemo(() => {
    let filteredSnapshots;
    const lastEdited = { ...funnel, ord: 1000, isOriginal: true, funnelId: funnel.id };

    switch (filterByCrmOrgId) {
      case OPTION_ALL.value: {
        filteredSnapshots = snapshotsList;
        break;
      }
      default: {
        filteredSnapshots = snapshotsList
          .map((snapshot) => {
            const filteredDeployments = snapshot?.deployments?.filter(
              (deployment) => deployment.crmOrgId === filterByCrmOrgId,
            );

            return { ...snapshot, deployments: filteredDeployments };
          })
          .filter((snapshot) => !!snapshot?.deployments?.length);
      }
    }
    return createFunnelVersionData({
      snapshotsList: [lastEdited, ...filteredSnapshots],
      funnelMapId,
      funnelMapCrmOrgId,
      users: users ?? [],
    });
  }, [filterByCrmOrgId, snapshotsList, funnel, funnelMapId, users, funnelMapCrmOrgId]);

  const handleNameChange = (name: string, snapshotId: string) => {
    editSnapshot(name, funnel.id, snapshotId);
  };

  const onViewClick = useCallback(
    ({
      snapshotId,
      crmOrgId,
      deploymentId,
      funnelId,
    }: {
      snapshotId: string;
      crmOrgId: string;
      deploymentId: string;
      funnelId: string;
    }) => {
      const baseRoute = appRoutes.canvasUnitedSnapshot.route;
      const route = `${baseRoute}/${funnelId}?${buildURLQuery({
        snapshotId,
        crmOrgId,
        deploymentId,
        enableRestore: true,
      })}`;
      navigate(route);
      onNavigateCallback && onNavigateCallback();
    },
    [navigate, onNavigateCallback],
  );

  const funnelTableHeaders: DataTableBaseColumn<FunnelVersionData>[] = [
    { headerName: '#', field: 'index', minWidth: 0 },
    {
      headerName: 'Name',
      field: 'snapshotName',
      renderer: (snapshotName, id, field, row) => {
        return (
          <EditableLabel
            value={snapshotName}
            padding={'2px 8px 2px 0'}
            readonly={row.isOriginal}
            onValueConfirm={(name) => handleNameChange(name, row.id)}
            minCharLength={1}
            inputSx={{
              fontSize:
                __SWEEP_DESIGN_TYPOGRAPHY_STYLES___[
                  dataTableVariants[DataTableVariant.default].fontVariant
                ]?.fontSize,
            }}
          />
        );
      },
    },
    { headerName: 'Date', field: 'createdAt' },
    { headerName: 'By', field: 'createdBy' },
    { headerName: 'Environment', field: 'environment' },
    { headerName: 'Steps', field: 'steps' },
    { headerName: 'Nurturing Buckets', field: 'nurtureBuckets' },
    {
      headerName: '',
      field: 'buttonArea',
      minWidth: 10,
      justifyContent: 'right',
      renderer: (value, id, field, row) => (
        <>
          {!row.isOriginal && (
            <Button
              variant="link"
              onClick={() => {
                onViewClick({
                  snapshotId: value.snapshotId,
                  crmOrgId: value.crmOrgId,
                  deploymentId: value.deploymentId,
                  funnelId: value.funnelId,
                });
              }}
            >
              View
            </Button>
          )}
        </>
      ),
    },
  ];

  return <DataTable columns={funnelTableHeaders} rows={listItems} />;
};

const createFunnelVersionData = ({
  snapshotsList,
  funnelMapCrmOrgId,
  funnelMapId,
  users,
}: {
  snapshotsList: FunnelSnapshotExtended[];
  funnelMapId: string;
  funnelMapCrmOrgId: string;
  users: UserInfo[];
}): FunnelVersionData[] => {
  const newList = snapshotsList.map((snapshot) => {
    const deployments = snapshot?.deployments;
    const {
      id,
      name: snapshotName,
      createdAt,
      updatedAt,
      ord,
      funnelDetails,
      isOriginal,
      funnelId,
      createdById,
      updatedById,
    } = snapshot;
    const createdByName = users.find((user) => user.id === createdById)?.name;
    let funnelVersionData: FunnelVersionData = {
      id,
      index: ord,
      snapshotName,
      createdAt: displayDate(createdAt),
      createdBy: displayUserInfo(createdByName),
      environment: 'Draft',
      // By product definition: when a snapshot doesn't have a crmOrgId we propagate funnelMapCrmOrgId
      crmOrgId: funnelMapCrmOrgId,
      steps: countSteps(funnelDetails),
      nurtureBuckets: countNurturing(funnelDetails),
      isOriginal: !!isOriginal,
      isDeployment: false,
      funnelId,
      funnelMapId,
      buttonArea: {
        snapshotId: id,
        crmOrgId: funnelMapCrmOrgId,
        deploymentId: '',
        funnelId,
      },
    };

    if (isOriginal) {
      const updatedByName = users.find((user) => user.id === updatedById)?.name;

      funnelVersionData = {
        ...funnelVersionData,
        index: '',
        createdAt: displayDate(updatedAt || ''),
        createdBy: displayUserInfo(updatedByName),
      };
    }

    if (deployments && deployments?.length > 1) {
      const sortedDeployments = orderBy(deployments, 'deployedAt', 'desc');
      const [firstVersion, ...restOfDeployments] = sortedDeployments;
      const nestedRows = restOfDeployments.map((deployment: any) => {
        return {
          ...funnelVersionData,
          ...getDeploymentData(deployment, users),
          snapshotName: undefined,
          index: undefined,
          funnelMapId,
        };
      });

      return {
        ...funnelVersionData,
        ...getDeploymentData(firstVersion, users),
        nestedRows: sortListByDate(nestedRows, 'createdAt'),
        funnelMapId,
      };
    }

    return {
      ...funnelVersionData,
      ...(deployments ? getDeploymentData(deployments?.[0], users) : []),
      funnelMapId,
    };
  });

  if (newList.length === 0) {
    return [];
  }

  const currentSnapshot = newList[0].index === '' ? newList[0] : undefined;
  const restOfSnapshots = currentSnapshot ? newList.slice(1) : newList;
  const orderedRestOfSnapshots = orderBy(restOfSnapshots, 'index', 'desc');

  return currentSnapshot ? [currentSnapshot, ...orderedRestOfSnapshots] : orderedRestOfSnapshots;
};

const getDeploymentData = (deployment: FunnelDeployment, users: UserInfo[]) => {
  if (!deployment) {
    return [];
  }

  const { deployedAt, deployedById, crmOrgName, crmOrgColorId, crmOrgId, id } = deployment;
  const deployedByName = users.find((user) => user.id === deployedById)?.name;

  return {
    createdAt: displayDate(deployedAt),
    createdBy: displayUserInfo(deployedByName),
    environment: crmOrgName,
    crmOrgId,
    environmentColor: crmOrgColorId,
    isCollectionItem: true,
    isDeployment: true,
    deploymentId: id,
  };
};

const displayDate = (createdAt: string) => {
  const updated = new Date(createdAt);

  if (!updated) {
    return 'No information';
  }

  return humanizeDate({
    dateOrTimestamp: createdAt,
    variant: humanizeDateVariants.SHORT_WITH_TIME,
  });
};

const countNurturing = (funnelDetails: FunnelDetails) =>
  String(
    funnelDetails?.stages?.filter((stage: SweepStage) => isStageNurturingBucket(stage)).length,
  );

const countSteps = (funnelDetails: FunnelDetails) => String(funnelDetails?.stages?.length);

export default FunnelVersionsTable;
