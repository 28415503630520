import PotentialIssuesTable from '../common/potentialIssuesTable/PotentialIssuesTable';
import { PotentialIssue } from '@server/homepage';
import { colors } from '@sweep-io/sweep-design';
import { Box } from '@mui/material';
import { DataTableVariant } from '../common/table/TableTypes';
import ToggleExpandButton from './ToggleExpandButton';
import useAiAgentCenter from './useAiAgentCenter';
import { useCallback, useState } from 'react';
import PotentialIssuesFilters, { PotentialsIssuesFilters } from './PotentialIssuesFilters';
import { SearchInput } from '../common/SearchInput';
import { filterItemsBySearch } from '../../lib/filterItemsBySearch';
import { AiChatAssistantMessageBox } from '../common/ai-chat/message-atoms/AiChatAssistantMessageBox';
import { AgentType, MonitoringContextComponent } from '@server/ai';
import useObjectTypesWithFetch from '../../hooks/useObjectTypesWithFetch';
import { AI_AGENTS_INFO } from './aiAgentsConsts';
import { useSelector } from 'react-redux';
import { selectDefaultCreationCrmOrgId } from '../../reducers/userInfoReducer';

const PotentialIssuesTableWithFilters = ({
  chatContextComponents: potentialIssues,
  agentContext,
}: {
  chatContextComponents: PotentialIssue[];
  agentContext: MonitoringContextComponent;
}) => {
  const [filters, setFilters] = useState<PotentialsIssuesFilters>({
    selectedConfigurationTypes: [],
    selectedIssues: [],
    selectedSeverity: [],
  });
  const [search, setSearch] = useState('');
  const { isExpanded } = useAiAgentCenter();
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  const { objectType } = agentContext;
  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId: crmOrgId ?? '' });
  const objectLabel = objectTypesByName[objectType]?.label ?? objectType;

  const introMessage = `We’ve found ${potentialIssues.length} potential issues in the ${objectLabel} object. Explore them below, get recommendations from the agent, and uncover your next steps.`;

  const filteredPotentialIssues = potentialIssues.filter((issue: PotentialIssue) => {
    const bySeverity =
      filters.selectedSeverity.length > 0
        ? filters.selectedSeverity.includes(issue.severity)
        : true;
    const byType =
      filters.selectedConfigurationTypes.length > 0
        ? filters.selectedConfigurationTypes.includes(issue.configurationType)
        : true;
    const byIssue =
      filters.selectedIssues.length > 0 ? filters.selectedIssues.includes(issue.title) : true;
    return byType && bySeverity && byIssue;
  });

  const itemsToRender = filterItemsBySearch<PotentialIssue>(
    filteredPotentialIssues,
    search,
    'configurationName',
  );

  const setFilter = useCallback(
    (partialFilters: Partial<PotentialsIssuesFilters>) => {
      setFilters((prev) => ({ ...prev, ...partialFilters }));
    },
    [setFilters],
  );

  const searchJsx = (
    <SearchInput
      TextFieldProps={{
        value: search,
        placeholder: 'Search',
        onChange: (e) => {
          setSearch(e.target.value);
        },
      }}
      withFixedMagnifyingGlassIcon={true}
      onClearButtonClick={() => setSearch('')}
      variant="small"
    />
  );

  const { assistantAvatar } = AI_AGENTS_INFO[AgentType.Monitoring];

  return (
    <>
      <AiChatAssistantMessageBox content={introMessage} avatar={assistantAvatar} />
      {isExpanded && (
        <Box
          sx={{
            paddingTop: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <PotentialIssuesFilters
            potentialIssues={potentialIssues}
            filters={filters}
            setFilter={setFilter}
            searchJsx={searchJsx}
          />
          <PotentialIssuesTable
            tableVariant={DataTableVariant.medium}
            potentialIssues={itemsToRender}
            tableMaxHeight={'330px'}
          />
        </Box>
      )}

      {!isExpanded && (
        <Box
          border={`1px solid ${colors.grey[300]}`}
          borderRadius={2}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#F7F9FA',
            padding: '16px',
            gap: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              justifyContent: 'space-between',
            }}
          >
            <ToggleExpandButton />
          </Box>

          <PotentialIssuesTable
            tableVariant={DataTableVariant.small}
            potentialIssues={itemsToRender}
            tableMaxHeight="200px"
          />
        </Box>
      )}
    </>
  );
};

export default PotentialIssuesTableWithFilters;
