import * as React from "react";
const SvgAgentMonitoringIcon = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 40 40", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("g", { id: "Agents avatars" }, /* @__PURE__ */ React.createElement("circle", { id: "Ellipse 73", cx: 20, cy: 20, r: 20, fill: "#ACE6F9", style: {
  fill: "#ACE6F9",
  fill: "color(display-p3 0.6745 0.9020 0.9765)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { id: "Rectangle 1552", d: "M7.59325 11.9375C6.64271 9.76112 8.80948 7.5272 11.0138 8.41091L11.4419 8.58251C12.121 8.85478 12.879 8.85478 13.5581 8.58251L18.5116 6.5967C19.4669 6.21372 20.5331 6.21372 21.4884 6.5967L26.4419 8.58251C27.121 8.85478 27.879 8.85478 28.5581 8.58251L28.9862 8.41091C31.1905 7.5272 33.3573 9.76112 32.4067 11.9375C32.1109 12.6149 32.1109 13.3851 32.4067 14.0625L34.3007 18.399C34.7466 19.4198 34.7466 20.5802 34.3007 21.601L32.4067 25.9375C32.1109 26.6149 32.1109 27.3851 32.4067 28.0625C33.3573 30.2389 31.1905 32.4728 28.9862 31.5891L28.5581 31.4175C27.879 31.1452 27.121 31.1452 26.4419 31.4175L21.4884 33.4033C20.5331 33.7863 19.4669 33.7863 18.5116 33.4033L13.5581 31.4175C12.879 31.1452 12.121 31.1452 11.4419 31.4175L11.0138 31.5891C8.80948 32.4728 6.64271 30.2389 7.59325 28.0625C7.88914 27.3851 7.88914 26.6149 7.59325 25.9375L5.69925 21.601C5.25342 20.5802 5.25342 19.4198 5.69925 18.399L7.59325 14.0625C7.88914 13.3851 7.88914 12.6149 7.59325 11.9375Z", fill: "#DEFBFF", style: {
  fill: "#DEFBFF",
  fill: "color(display-p3 0.8706 0.9843 1.0000)",
  fillOpacity: 1
} })));
export default SvgAgentMonitoringIcon;
