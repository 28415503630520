import { colors, Typography } from '@sweep-io/sweep-design';
import { ConfigurationType, ConfigurationTypesWithIcon } from '../../types';
import { Box } from '@mui/material';
import isNil from 'lodash/isNil';
import { DotDivider } from '../../../../common/DotDivider';
import { CONFIGURATION_ITEMS_WITH_ACTIVITY_STATE } from '../../../activity-state/helpers';
import { InactiveTag } from '../../../activity-state/InactiveTag';
import { getChips, isSupported } from '../../helpers';
import { configurationItemTypeToIcon } from '../../latest-deployed/templates/organisms/helpers/icons';
import useObjectTypesWithFetch from '../../../../../hooks/useObjectTypesWithFetch';
import { getItemType } from '../utils';
import RuleChips from '../../../shared/RuleChips';
import { ConfigurationItem } from '../../DependenciesTypes';
import { useContext } from 'react';
import { DocumentationDialogContext } from '../../../context/DocumentationContextProvider';

interface TitleWithSubtitleProps {
  configurationItem: ConfigurationItem;
  parentType?: ConfigurationType;
  crmOrgId: string;
}

export const TitleWithSubtitle = ({
  configurationItem,
  parentType,
  crmOrgId,
}: TitleWithSubtitleProps) => {
  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId });
  const { stepNamesUsedInCanvas } = useContext(DocumentationDialogContext);
  const isField =
    parentType === ConfigurationType.fields || parentType === ConfigurationType.rollups;

  const {
    label,
    usage,
    isActive,
    type: dependencyType,
    objectApiNames,
    objectName,
  } = configurationItem ?? {};

  const { chips, RTchips } = getChips(
    configurationItem,
    stepNamesUsedInCanvas,
    objectTypesByName,
    parentType,
  );

  const _objectName = objectApiNames?.[0] ?? objectName;

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" gap={1} alignItems="center">
        <Typography variant="h3" whiteSpace="break-spaces" wordBreak="break-word">
          {label}
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        gap={0.5}
        alignItems="center"
        sx={{ svg: { path: { stroke: colors.grey[700] } } }}
      >
        {parentType && (
          <>
            {configurationItemTypeToIcon[parentType as ConfigurationTypesWithIcon]}
            <Typography variant="caption" color={colors.grey[700]}>
              {getItemType({ objectTypesByName, _objectName, parentType, dependencyType })}
            </Typography>
          </>
        )}

        {!!chips?.length && (
          <>
            <DotDivider />
            <RuleChips
              chips={chips}
              differentColorChips={RTchips}
              differentColor={colors.grey[120]}
            />
          </>
        )}

        {isField && !isNil(usage) && !isNaN(usage) && (
          <Box display="flex" alignItems="center" gap={0.5}>
            <DotDivider />
            <Typography variant="caption" color={colors.grey[700]}>
              {usage}% usage
            </Typography>
          </Box>
        )}

        {!isActive && isSupported(CONFIGURATION_ITEMS_WITH_ACTIVITY_STATE, parentType) && (
          <Box display="flex" alignItems="center" gap={0.5}>
            <DotDivider />
            <InactiveTag isActive={!!isActive} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
