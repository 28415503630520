import { DataTable } from '../common/table/DataTable';
import { AutomationsListProps } from './AutomationsListProps';
import { DeployStatusForTable } from '../../types/enums/DeployStatusForTable';
import { colors, Tag, Typography } from '@sweep-io/sweep-design';
import { Box, Stack } from '@mui/material';
import useObjectTypesWithFetch from '../../hooks/useObjectTypesWithFetch';
import { getDisplayLineTexts } from './helper';
import { humanizeDate, humanizeDateVariants } from '../helpers/humanizeDate';
import { useAutomationsContext } from './AutomationsContext';
import { ReactNode } from 'react';
import { ActionsMenu } from '../common/actions-menu/ActionsMenu';
import { SortOrder } from '../common/types';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import AutomationActionText from './AutomationActionText';
import { ObjectTypeLabelAndKeyTag } from '../common/ObjectTypeLabelAndKey';
import { TruncatedTextTooltip } from '../common/TruncatedTextTooltip';
import UserNameForTable from '../common/UserNameForTable';
import { TableFilteredEmptyState } from '../common/TableFilteredEmptyState';
import { ACTION_BUTTONS_KEY } from '../common/table/utils';
import { useSweepFieldsLabels } from '../../sweep-fields/useSweepFieldsLabels';
import {
  translateConditionsToString,
  translateModelType,
} from './marketing-attribution-form/helpers';

const getColumns = (variant: AutomationFormVariant) =>
  AutomationFormVariant.MARKETING_ATTRIBUTIONS === variant
    ? marketingAttributionColumns
    : [
        {
          field: 'name',
          headerName: 'Name',
          showSortIcon: true,
          width: '340px',
        },
        {
          field: 'objectName',
          headerName: 'Object',
          showSortIcon: true,
          width: '200px',
        },
        {
          field: 'trigger',
          headerName: 'Trigger',
          width: '240px',
          hidden: variant === AutomationFormVariant.DEDUP_MATCHING,
        },
        {
          field: 'actions',
          headerName: 'Action(s)',
          width: '280px',
        },
        {
          field: 'isActive',
          headerName: 'Status',
          showSortIcon: true,
          width: '160px',
        },
        {
          field: 'updatedAt',
          headerName: 'Last modified date',
          showSortIcon: true,
          width: '200px',
        },
        {
          field: 'updatedBy',
          headerName: 'Last modified by',
          showSortIcon: true,
          width: '200px',
        },
        {
          field: 'createdAt',
          headerName: 'Created date',
          showSortIcon: true,
          width: '200px',
        },
        {
          field: 'createdBy',
          headerName: 'Created by',
          showSortIcon: true,
          width: '200px',
        },
      ];

export const marketingAttributionColumns = [
  {
    field: 'name',
    headerName: 'Name',
    showSortIcon: true,
  },
  {
    field: 'modelType',
    headerName: 'Type',
    showSortIcon: true,
    width: '150px',
  },
  {
    field: 'conditions',
    headerName: 'Conditions',
    width: '300px',
  },
  {
    field: 'isActive',
    headerName: 'Status',
    showSortIcon: true,
    width: '200px',
  },
  {
    field: 'updatedAt',
    headerName: 'Last modified at',
    showSortIcon: true,
    width: '150px',
  },
  {
    field: 'updatedBy',
    headerName: 'Last modified by',
    showSortIcon: true,
    width: '250px',
  },
];

const getActiveStatus = (isActive: boolean, status: DeployStatusForTable) => {
  if (status === DeployStatusForTable.Draft) return 'Not deployed';
  return isActive ? 'Active' : 'Inactive';
};

interface AutomationsTableRow {
  id: string;
  name: ReactNode;
  objectName: ReactNode;
  trigger: ReactNode;
  actions: ReactNode;
  isActive: ReactNode;
  updatedAt: ReactNode;
  updatedBy: ReactNode;
  createdAt: ReactNode;
  createdBy: ReactNode;
  automation: AutomationStructureNew;
  isClickable: boolean;
  [ACTION_BUTTONS_KEY]: ReactNode;
}

const INACTIVE_CELL_CLASS = 'automation-inactive';
const INACTIVE_STATUS_CELL_CLASS = 'automation-inactive-status';

const AutomationsTable = ({
  automations,
  crmOrgId,
  onEdit,
  getActionsMenu,
  onActionMenuClick,
  onSortCb,
  onClearFilters,
}: AutomationsListProps & {
  onSortCb: (sortBy: string, sortOrder: SortOrder) => void;
  onClearFilters: () => void;
}) => {
  const { getLabelsFromIds } = useSweepFieldsLabels();
  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId });
  const dataByVariant = useAutomationsContext();

  let columns = getColumns(dataByVariant.automationVariant);
  if (dataByVariant?.isObjectless) {
    columns = columns?.filter((el) => el.field !== 'objectName');
  }

  const rows: AutomationsTableRow[] = automations.map((item) => {
    const { triggerText, actionText } = getDisplayLineTexts(item);
    const activeStatus = getActiveStatus(item.isActive, item.status);
    const isInactive = activeStatus === 'Inactive';
    const cellClass = isInactive ? INACTIVE_CELL_CLASS : undefined;
    const statusCellClass = isInactive ? INACTIVE_STATUS_CELL_CLASS : undefined;
    const campaignCriteria = (
      item.automationDetails?.actions as MarketingAttributionModelAction[]
    )[0]?.actionParams?.campaignFilter;
    const criteria = item.automationDetails?.when?.criteria;

    const translatedCampaignCriteria = campaignCriteria
      ? translateConditionsToString(campaignCriteria?.criteria, getLabelsFromIds)
      : [];

    const translatedCriteria = criteria
      ? translateConditionsToString(criteria.criteria, getLabelsFromIds)
      : [];

    return {
      id: item.automationId,
      name: (
        <Stack overflow="hidden">
          <Stack className={cellClass}>
            <TruncatedTextTooltip>{item.name}</TruncatedTextTooltip>
          </Stack>
          <Box display="flex">
            {item.notifyAdmin && (
              <Tag
                startIconName="Stamp"
                label="Pending admin approval"
                iconColor={colors.grey[800]}
              />
            )}
            {item.status === DeployStatusForTable.Pending && !item.notifyAdmin && (
              <Tag
                startIconName="Refresh"
                label="Updates pending deploy"
                iconColor={colors.grey[800]}
              />
            )}
            {item.status === DeployStatusForTable.Draft && (
              <Tag startIconName="Edit" label="Draft" iconColor={colors.grey[800]} />
            )}
          </Box>
        </Stack>
      ),
      modelType: (
        <Stack className={cellClass}>
          <Typography variant="body">
            {translateModelType(
              (item.automationDetails?.actions[0] as MarketingAttributionModelAction)?.actionParams
                ?.modelType,
            )}
          </Typography>
        </Stack>
      ),
      conditions: (
        <Stack className={cellClass}>
          <Typography variant="body">
            {translatedCriteria?.map((el, index) =>
              el ? <AutomationActionText key={index} element={el} /> : null,
            )}
            {translatedCampaignCriteria?.map((el, index) =>
              el ? <AutomationActionText key={index} element={el} /> : null,
            )}
            {!translatedCriteria?.length && !translatedCampaignCriteria?.length && 'No conditions'}
          </Typography>
        </Stack>
      ),
      objectName: (
        <ObjectTypeLabelAndKeyTag
          label={objectTypesByName[item.objectName]?.label || item.objectName}
          objectType={item.objectName}
          tagVariant="small"
        />
      ),
      trigger: (
        <Stack className={cellClass}>
          <Typography variant="body">{triggerText}</Typography>
        </Stack>
      ),
      actions: (
        <Stack gap="2px" className={cellClass}>
          {actionText.map((el, index) => (
            <AutomationActionText key={index} element={el} />
          ))}
        </Stack>
      ),
      isActive: (
        <Stack className={statusCellClass}>
          <Typography variant="body">{activeStatus}</Typography>
        </Stack>
      ),
      updatedAt: (
        <Stack className={cellClass}>
          <Typography variant="body">
            {humanizeDate({
              dateOrTimestamp: item.updatedAt,
              variant: humanizeDateVariants.TWO_DIGITS_WITH_TIME,
            })}
          </Typography>
        </Stack>
      ),
      updatedBy: (
        <Stack className={cellClass} display="flex" overflow="hidden">
          <UserNameForTable userId={item.updatedById} />
        </Stack>
      ),
      createdAt: item?.createdAt ? (
        <Stack className={cellClass}>
          <Typography variant="body">
            {humanizeDate({
              dateOrTimestamp: item.createdAt,
              variant: humanizeDateVariants.TWO_DIGITS_WITH_TIME,
            })}
          </Typography>
        </Stack>
      ) : (
        ''
      ),
      createdBy: item.createdById ? (
        <Stack className={cellClass} display="flex" overflow="hidden">
          <UserNameForTable userId={item.createdById} />
        </Stack>
      ) : (
        ''
      ),
      [ACTION_BUTTONS_KEY]: (
        <ActionsMenu
          actions={getActionsMenu(item)}
          onClick={(action) => {
            onActionMenuClick(item)(action.value);
          }}
          iconVariant="outlined"
        />
      ),
      automation: item,
      isClickable: true,
    };
  });
  return (
    <Box flex={1} overflow="auto">
      <DataTable
        columns={columns}
        rows={rows}
        stickyHeader
        onRowClick={(
          e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
          row: AutomationsTableRow,
        ) => onEdit(row.automation)}
        onSortChange={({ sortBy, sortOrder }) => {
          onSortCb(sortBy, sortOrder);
        }}
        sxRowFunction={() => ({
          [`td .${INACTIVE_CELL_CLASS} *`]: {
            color: colors.grey[500],
          },
          [`td .${INACTIVE_STATUS_CELL_CLASS} .MuiTypography-root`]: {
            color: colors.blush[600],
          },
        })}
        tableEmptyStateJsx={
          <Box sx={{ position: 'absolute', left: '40vw' }}>
            <TableFilteredEmptyState clearFilters={onClearFilters} isFiltered={true} />
          </Box>
        }
      />
    </Box>
  );
};

export default AutomationsTable;
