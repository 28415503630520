import * as React from "react";
const SvgAgentMonitoringAvatar = (props) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("rect", { width: 24, height: 24, rx: 12, fill: "#DEFBFF", style: {
  fill: "#DEFBFF",
  fill: "color(display-p3 0.8706 0.9843 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clip0_77_16443)" }, /* @__PURE__ */ React.createElement("path", { d: "M7.10347 9.63147V11.8109H19.4538V9.63147H7.10347Z", fill: "#79D2E0", style: {
  fill: "#79D2E0",
  fill: "color(display-p3 0.4745 0.8235 0.8784)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M14.3684 4.54602H12.1889V16.8964H14.3684V4.54602Z", fill: "#79D2E0", style: {
  fill: "#79D2E0",
  fill: "color(display-p3 0.4745 0.8235 0.8784)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M13.2788 12.2627L11.7377 10.7216L4.54639 17.9129L6.08751 19.454L13.2788 12.2627Z", fill: "#2C1A4F", style: {
  fill: "#2C1A4F",
  fill: "color(display-p3 0.1730 0.1028 0.3083)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M9.68324 5.58417L8.14212 7.12529L16.8752 15.8583L18.4163 14.3172L9.68324 5.58417Z", fill: "#79D2E0", style: {
  fill: "#79D2E0",
  fill: "color(display-p3 0.4745 0.8235 0.8784)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M14.8198 10.7214L13.2786 9.18025L11.7377 10.7216L13.2788 12.2627L14.8198 10.7214Z", fill: "#F8F9FA", style: {
  fill: "#F8F9FA",
  fill: "color(display-p3 0.9725 0.9765 0.9804)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M18.4157 7.1251L16.8746 5.58397L13.2786 9.18025L14.8198 10.7214L18.4157 7.1251Z", fill: "#79D2E0", style: {
  fill: "#79D2E0",
  fill: "color(display-p3 0.4745 0.8235 0.8784)",
  fillOpacity: 1
} })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "clip0_77_16443" }, /* @__PURE__ */ React.createElement("rect", { width: 16, height: 16, fill: "white", style: {
  fill: "white",
  fillOpacity: 1
}, transform: "translate(4 4)" }))));
export default SvgAgentMonitoringAvatar;
