import { useCallback } from 'react';
import { MarkerType, useReactFlow } from '@xyflow/react';
import {
  SweepCanvasNode,
  CanvasElementType,
  SweepCanvasEdge,
  CanvasEdgeConnectionType,
} from '../canvas-types';
import { SweepCanvasReactFlowEdgeDataType } from '../edges/SweepCanvasReactFlowEdgeDataType';
import {
  CanvasGridPosition,
  convertGridPositionToXYPosition,
  convertXYPositionToGridIndex,
} from '../helpers/gridPositioningUtils';
import { isNbNode } from '../helpers/isNbNode';
import {
  RfNodeRegularNode,
  NewAddNodeClickProps,
  RFNodeEditRegularNode,
  RfNodeGhostNode,
} from '../canvas-types/nodeTypesData';

import { uniqueId } from '../../../lib/uniqueId';
import { NODE_HEIGHT, NODE_WIDTH } from '../const';
import { useSweepCanvasPropsCtx } from '../internal-context/SweepCanvasPropsCtx';

import { createObjectOperations } from '../node-changes-event-handlers/objectOperations';
import { RFEdgeFloatingEdge } from '../edges';
import { getObjectTypeColor } from '../helpers/getObjectTypeColor';
import { updateGroupBoundingBox } from '../node-changes-event-handlers/updateGroupBoundingBox';
import { useSweepCanvasState } from '../internal-context/CanvasStateContext';
import {
  NodePositionTransformation,
  shiftNodesRight,
} from '../helpers/nodePositionManagementHelper';
import { useNodeAnimations } from '../useNodeAnimations';
import { useTemporaryTransformationsCtx } from './TemporaryTransformationsCtx';
import { useNewEditableNodeFactory } from './useNewEditableNodeFactory';
import {
  calculateNodeVisibility,
  getZoomLevels,
  useGetShowButtonsOnHighlight,
} from '../effects/useContextZoomEffect';
import { useHighlightNodesAndEdges } from '../highlight/useHighlightNodesAndEdges';

function createBaseNode<T extends CanvasElementType>({
  id,
  type,
  gridPosition,
  parentId,
  disableGroups,
}: {
  id: string;
  type: T;
  gridPosition: CanvasGridPosition;
  parentId?: string;
  disableGroups?: boolean;
}) {
  const node = {
    id,
    position: convertGridPositionToXYPosition(gridPosition),
    parentId: disableGroups ? undefined : parentId,
    width: NODE_WIDTH,
    height: NODE_HEIGHT,
    type,
    dragHandle: '.drag-handle',
    draggable: true,
    selected: false,
    zIndex: 1,
  };
  return node;
}

export const useRegularNodesFactory = () => {
  const {
    onNodeClick,
    onPillClick,
    onConnectClick,
    readonly,
    holdNodeHighlighted,
    disableGroups,
    disableNodeHighlight,
    simpleHighlightedEntities,
    visibilityMap,
  } = useSweepCanvasPropsCtx();

  const { clearHighlight } = useHighlightNodesAndEdges();

  const { setCanvasNodes, setCanvasEdges, getCanvasNodes } = useSweepCanvasState();

  const transformedOriginalValuesToRestore = useTemporaryTransformationsCtx();
  const { createNewNode } = useNewEditableNodeFactory();

  const calculateColumnNumber = (currentColumn: number, isNurturingBucketType: boolean) => {
    const moveByColumnNumber = isNurturingBucketType ? 0 : 1;
    return currentColumn + moveByColumnNumber;
  };

  const { withNodeRightShiftAnimation } = useNodeAnimations();

  const onAddNodeClick = useCallback(
    ({ buttonPosition, sourceNodeId }: NewAddNodeClickProps) => {
      if (buttonPosition === 'bottom' || buttonPosition === 'top') {
        const nodes = getCanvasNodes();
        const nodeOperations = createObjectOperations(nodes);

        const { getObject: getNode, addObject: addNode, getObjects: getAllNodes } = nodeOperations;
        const sourceNode = getNode<RfNodeRegularNode>(sourceNodeId);
        if (!sourceNode?.parentId || sourceNode.type !== CanvasElementType.REGULAR) return;

        const getNodesByTypesAndParentId = (types: CanvasElementType[], parentId: string) => {
          return getAllNodes().filter(
            (n) => n.parentId === parentId && types.includes(n.type as CanvasElementType),
          );
        };

        const nodesWithSameParent = getNodesByTypesAndParentId(
          [CanvasElementType.REGULAR, CanvasElementType.NURTURING_BUCKET],
          sourceNode.parentId,
        );

        const addTopTop = buttonPosition !== 'bottom';
        const addToBottom = !addTopTop;

        const { column } = convertXYPositionToGridIndex(sourceNode.position);

        const columnNumber = calculateColumnNumber(column, addToBottom);

        const chosenNodes = addToBottom
          ? nodesWithSameParent.filter(
              (n) => convertXYPositionToGridIndex(n.position).column === columnNumber,
            )
          : nodesWithSameParent;

        const chosenNodesRows = chosenNodes.map(
          (n) => convertXYPositionToGridIndex(n.position).row,
        );

        const newRowNumber = addTopTop
          ? Math.max(...chosenNodesRows) + 1
          : Math.min(...chosenNodesRows) - 1;

        const [newNodeId, newEdgeId] = [uniqueId(), uniqueId()];

        const newNode = createNewNode({
          newNodeId,
          position: convertGridPositionToXYPosition({ column: columnNumber, row: newRowNumber }),
          isNb: addToBottom || Boolean(sourceNode.data.isNb),
          parentId: sourceNode.parentId,
          objectType: sourceNode.data.objectType,
          originStepName: sourceNode.data.name,
          sourceId: sourceNode.id,
          origin: {
            type: 'node',
            id: sourceNode.id,
          },
        });

        addNode(newNode);

        updateGroupBoundingBox({
          groupNodeId: sourceNode.parentId,
          nodeOperations,
        });

        const newEdge: RFEdgeFloatingEdge = {
          id: newEdgeId,
          source: sourceNode.id,
          target: newNodeId,
          type: 'floating',
          markerEnd: { type: MarkerType.Arrow },
          data: {
            label: '',
            type: SweepCanvasReactFlowEdgeDataType.DASHED_CIRCLE,
            sourceColor: getObjectTypeColor(sourceNode.data.objectType).connection,
            targetColor: getObjectTypeColor(sourceNode.data.objectType).connection,
            gateColor: getObjectTypeColor(sourceNode.data.objectType).connection,
            connectionType: CanvasEdgeConnectionType.Bezier,
          },
          deletable: false,
        };

        setCanvasEdges((edges) => [...edges, newEdge]);
        transformedOriginalValuesToRestore.current = {
          nodesToRemove: [newNodeId],
          edgesToRemove: [newEdgeId],
          nodePositions: [],
          edgeSourceAndTarget: [],
        };
        setCanvasNodes(getAllNodes());
        setTimeout(clearHighlight, 100);
      }

      if (buttonPosition === 'left' || buttonPosition === 'right') {
        const nodes = getCanvasNodes();
        const nodeOperations = createObjectOperations(nodes);

        const { getObject: getNode } = nodeOperations;
        const sourceNode = getNode<RfNodeRegularNode>(sourceNodeId);
        if (!sourceNode?.parentId || sourceNode.type !== CanvasElementType.REGULAR) return;

        if (!sourceNode) {
          console.error('Starting node not found');
          return;
        }

        const siblingNodesInSameGroup = nodes.filter(
          (n) => n.parentId === sourceNode.parentId && n.type === CanvasElementType.REGULAR,
        ) as RfNodeRegularNode[];

        const positionChanges = shiftNodesRight({
          startingNode: sourceNode,
          nodes: siblingNodesInSameGroup,
          includeOwn: buttonPosition === 'left',
        });
        const [newNodeId, newEdgeId] = [uniqueId(), uniqueId()];

        const originalNodePositions: NodePositionTransformation[] = positionChanges
          .map((nodeTransformation) => {
            const node = getNode(nodeTransformation.id);

            if (node) {
              return {
                id: node.id,
                position: node.position,
              };
            }
          })
          .filter(Boolean) as NodePositionTransformation[];

        transformedOriginalValuesToRestore.current = {
          ...transformedOriginalValuesToRestore.current,
          nodesToRemove: [newNodeId],
          edgesToRemove: [newEdgeId],
          nodePositions: originalNodePositions,
          edgeSourceAndTarget: [],
        };

        withNodeRightShiftAnimation({
          originalNodePositions,
          nodeIds: positionChanges.map((node) => node.id),
          onComplete: () => {
            if (!sourceNode) {
              console.error('Starting node not found');
              return;
            }

            const newNodeGridPosition = convertXYPositionToGridIndex(sourceNode.position);

            if (buttonPosition === 'right') {
              newNodeGridPosition.column = newNodeGridPosition.column + 1;
            }

            const newNode = createNewNode({
              newNodeId,
              position: convertGridPositionToXYPosition(newNodeGridPosition),
              isNb: Boolean(sourceNode.data.isNb),
              parentId: sourceNode.parentId,
              objectType: sourceNode.data.objectType,
              originStepName: sourceNode.data.name,
              sourceId: sourceNode.id,
              origin: {
                type: 'node',
                id: sourceNode.id,
              },
            });

            const newEdge: RFEdgeFloatingEdge = {
              id: newEdgeId,
              source: sourceNode.id,
              target: newNodeId,
              type: 'floating',
              markerEnd: { type: MarkerType.Arrow },
              data: {
                label: '',
                type: SweepCanvasReactFlowEdgeDataType.DASHED_CIRCLE,
                sourceColor: getObjectTypeColor(sourceNode.data.objectType).connection,
                targetColor: getObjectTypeColor(sourceNode.data.objectType).connection,
                gateColor: getObjectTypeColor(sourceNode.data.objectType).connection,
                connectionType: CanvasEdgeConnectionType.Bezier,
              },
              deletable: false,
            };

            setCanvasEdges((edges) => [...edges, newEdge]);
            setCanvasNodes((nodes) => {
              const nodeOperations = createObjectOperations(nodes);

              nodeOperations.addObject<RFNodeEditRegularNode>(newNode);
              updateGroupBoundingBox({
                groupNodeId: sourceNode.parentId as string,
                nodeOperations,
              });

              return nodeOperations.getObjects();
            });
            setTimeout(clearHighlight, 100);
          },
        });
      }
    },
    [
      clearHighlight,
      createNewNode,
      getCanvasNodes,
      setCanvasEdges,
      setCanvasNodes,
      transformedOriginalValuesToRestore,
      withNodeRightShiftAnimation,
    ],
  );

  const { getZoom } = useReactFlow();

  const showButtonsOnHighlight = useGetShowButtonsOnHighlight();

  const createFlowNodesFromSweepNodes = useCallback(
    ({
      sweepNodes,
      sweepEdges,
      sweepGroupIds,
    }: {
      sweepGroupIds: string[];
      sweepNodes: SweepCanvasNode[];
      sweepEdges: SweepCanvasEdge[];
    }) => {
      const groupNodeIds = new Set(sweepGroupIds);
      const sweepNodeOperations = createObjectOperations(sweepNodes);

      const isRegularOrNbNodeAndValid = (node: SweepCanvasNode) => {
        const isGroupNode = disableGroups || groupNodeIds.has(node.parentId || ''); // Filters nodes with no parents
        const isRegularOrNbNode =
          node.type &&
          [CanvasElementType.NURTURING_BUCKET, CanvasElementType.REGULAR].includes(node.type);
        return isGroupNode && isRegularOrNbNode;
      };

      const { isZoomLowerThan0_3, isZoomHigherThan0_75 } = getZoomLevels(getZoom());

      const regularNodes = sweepNodes.filter(isRegularOrNbNodeAndValid).map((sweepNode) => {
        const hasTargetButton = () => {
          const connectionsTo = sweepEdges.filter((edge) => edge.source === sweepNode.id);
          const onlyConnectionWithinFunnel = connectionsTo.filter(
            (connection) => connection?.data?.type !== SweepCanvasReactFlowEdgeDataType.REMOVABLE,
          );

          if (onlyConnectionWithinFunnel.length === 0) {
            return true;
          }

          const sourceNode = sweepNodeOperations.getObject(sweepNode.id);
          if (isNbNode(sourceNode)) {
            return false;
          }
          return !onlyConnectionWithinFunnel.find((connection) => {
            return !isNbNode(sweepNodeOperations.getObject(connection.target));
          });
        };

        const hasSourceButton = () => {
          const connectionsTo = sweepEdges.filter((edge) => edge.target === sweepNode.id);
          if (connectionsTo.length === 0) {
            return true;
          }
          return !connectionsTo.find(
            (connection) =>
              (sweepNodeOperations.getObject(connection.source)?.position.row || 0) >= 0,
          );
        };

        const { id, position, name, objectType, parentId } = sweepNode;
        const isNurturingBucket = isNbNode(sweepNode);

        const simpleHighlighted = simpleHighlightedEntities?.includes(id);

        const node: RfNodeRegularNode = {
          ...createBaseNode({
            id,
            type: CanvasElementType.REGULAR,
            gridPosition: position,
            parentId,
            disableGroups,
          }),
          data: {
            name,
            objectType,
            onNodeClick,
            onPillClick,
            onConnectClick,
            readonly,
            hasSourceButton: hasSourceButton(),
            hasTargetButton: hasTargetButton(),
            probability: sweepNode.probability,
            forecastCategoryLabel: sweepNode.forecastCategoryLabel,
            holdNodeHighlighted,
            parentId: parentId || '',
            pills: sweepNode.pills,
            disableStepClick: disableNodeHighlight,
            visibilityMap,
            hideNbButton: sweepNode.hideNbButton,
            showSparkleIcon: sweepNode.showSparkleIcon,
            showButtonsOnHighlight,
            onAddNodeClick: onAddNodeClick,
            isNb: isNurturingBucket,
            nodeVisibility: calculateNodeVisibility({
              isZoomLowerThan0_3,
              isZoomHigherThan0_75,
            }),
            simpleHighlighted,
          },
        };
        return node;
      });

      const ghostNodes = sweepNodes
        .filter((node) => node.type === CanvasElementType.GHOST_NODE)
        .map((sweepNode) => {
          const node: RfNodeGhostNode = {
            ...createBaseNode({
              id: sweepNode.id,
              type: CanvasElementType.GHOST_NODE,
              gridPosition: sweepNode.position,
              parentId: sweepNode.parentId,
              disableGroups,
            }),
            data: {
              name: sweepNode.name,
              objectType: sweepNode.objectType,
              onNodeClick,
              parentId: sweepNode.parentId || '',
            },
          };
          return node;
        });
      return [...regularNodes, ...ghostNodes];
    },
    [
      disableGroups,
      disableNodeHighlight,
      getZoom,
      holdNodeHighlighted,
      onAddNodeClick,
      onConnectClick,
      onNodeClick,
      onPillClick,
      readonly,
      showButtonsOnHighlight,
      simpleHighlightedEntities,
      visibilityMap,
    ],
  );

  return { createFlowNodesFromSweepNodes };
};
