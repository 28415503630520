import { PanelType } from '../../../../panels/types';

export interface FunnelPanelDataType {
  funnelId: string;
}

export const funnelPanelType: PanelType<FunnelPanelDataType> = {
  name: 'FUNNEL_PANEL',
  location: 'right'
};
