import { useUnhappyStates } from '../../environments/useUnhappyStates';

export const useHubspotUnhappyStates = () => {
  const { noConnectedHubspot, noProductionCrmConnected, isCurrentlyProductionOrg } =
    useUnhappyStates();
  const isRenderHubspotConnectPage = noProductionCrmConnected || noConnectedHubspot;
  const isRenderHubspotNotInProductionPage = !isCurrentlyProductionOrg;

  return {
    isRenderHubspotConnectPage,
    isRenderHubspotNotInProductionPage,
  };
};
