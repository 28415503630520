import { AI_AGENTS_INFO, SUPPORTED_TYPES_FOR_CREATION } from './aiAgentsConsts';
import { AgentType } from '@server/ai';
import { useFeatureToggle } from '../common/useFeatureToggle';
import { Menu, MenuItem, Stack } from '@mui/material';
import { Button, colors, Tag, Typography, Tooltip } from '@sweep-io/sweep-design';
import usePaywall from '../dashboard/paywall/usePaywall';
import useAiAgents from './useAiAgents';

const NewAgentButton = ({
  onNewAgentClick,
  anchorRef,
  openPopover,
  isPopoverOpen,
  anchorElement,
  closePopover,
}: {
  onNewAgentClick: (type: AgentType) => void;
  openPopover: () => void;
  closePopover: () => void;
  isPopoverOpen: boolean;
  anchorElement: Element;
  anchorRef: React.MutableRefObject<any>;
}) => {
  const { monitoringAiAgent, aiAgentCenter: aiProcessAgent } = useFeatureToggle();
  const { isPaywallActive } = usePaywall();
  const { agents } = useAiAgents();

  const getIsComingSoon = (type: AgentType) =>
    (!aiProcessAgent && type === AgentType.ProcessOptimization) ||
    (!monitoringAiAgent && type === AgentType.Monitoring);

  const orderedSupportedTypes = SUPPORTED_TYPES_FOR_CREATION.toSorted((a, b) => {
    if (getIsComingSoon(a)) {
      return 1;
    }
    if (getIsComingSoon(b)) {
      return -1;
    }
    return a.localeCompare(b);
  });
  return (
    <>
      <Button
        ref={anchorRef}
        size="small"
        startIconName="Plus"
        onClick={(e) => {
          e.stopPropagation();
          openPopover();
        }}
      >
        Create New
      </Button>
      <Menu
        open={isPopoverOpen}
        anchorEl={anchorElement}
        onClose={closePopover}
        slotProps={{
          paper: {
            sx: {
              width: '400px',
            },
          },
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {orderedSupportedTypes.map((type) => {
          const { nameInMenu, description } = AI_AGENTS_INFO[type].create;
          const { agentIcon } = AI_AGENTS_INFO[type];
          const { paywallNumberOfAllowedAgents } = AI_AGENTS_INFO[type];
          const existingAgentsCount = agents?.filter((agent) => agent.type === type).length;
          const isDisabled =
            isPaywallActive &&
            existingAgentsCount &&
            paywallNumberOfAllowedAgents !== undefined &&
            existingAgentsCount >= paywallNumberOfAllowedAgents;
          const isComingSoon = getIsComingSoon(type);
          return (
            <Tooltip
              key={type}
              title={
                isDisabled
                  ? 'Please contact a Sweep expert to activate your trial and create additional agents'
                  : ''
              }
              placement="top"
            >
              <span>
                <MenuItem
                  onClick={(e) => {
                    if (isComingSoon || isDisabled) {
                      return;
                    }
                    e.stopPropagation();
                    onNewAgentClick(type);
                    closePopover();
                  }}
                  disabled={!!isDisabled}
                  sx={
                    isComingSoon
                      ? {
                          '&.MuiMenuItem-root:hover': {
                            backgroundColor: 'inherit',
                            cursor: 'default',
                          },
                        }
                      : {}
                  }
                >
                  <Stack direction="row" justifyContent="space-between">
                    <Stack
                      direction="row"
                      gap={2}
                      alignItems="center"
                      maxWidth="100%"
                      minWidth={0}
                      flex={1}
                    >
                      <Stack width="24px">{agentIcon}</Stack>
                      <Stack minWidth={0} flex={1} overflow="hidden">
                        <Typography variant="body" color={colors.grey[900]}>
                          {nameInMenu}
                        </Typography>
                        <Typography variant="caption" whiteSpace="normal" color={colors.grey[700]}>
                          {description}
                        </Typography>
                      </Stack>
                    </Stack>
                    {isComingSoon && <Tag label="Coming soon" />}
                  </Stack>
                </MenuItem>
              </span>
            </Tooltip>
          );
        })}
      </Menu>
    </>
  );
};

export default NewAgentButton;
