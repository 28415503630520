import { Box, Stack } from '@mui/material';
import { useImpactAnalysisChat } from './useImpactAnalysisChat';
import useContainerWithScrollArrow from '../../../hooks/useContainerWithScrollArrow';
import { AiChatMessages } from '../../common/ai-chat/AiChatMessages';
import { Button, colors, IconButton, Typography } from '@sweep-io/sweep-design';
import { ArrowDown, Refresh } from '@sweep-io/sweep-design/dist/icons';
import { AiChatInput } from '../../common/ai-chat/AiChatInput';
import { useCallback, useEffect, useState } from 'react';
import ImpactAnalysisAvatar from './impactAnalysisAvatar.svg?react';
import ChatNote from '../../aiAgentCenter/ChatNote';
import GeneratingChatLoader from '../../aiAgentCenter/GeneratingChatLoader';

const ImpactAnalysisChat = ({
  isOpen,
  automation,
  close,
}: {
  isOpen: boolean;
  automation: DeepPartial<AutomationStructureNew>;
  close: () => void;
}) => {
  const { isLoading, messages, error, onConfirm, reset } = useImpactAnalysisChat();
  const { showScrollButton, scrollToBottom, containerRef } = useContainerWithScrollArrow();
  const [value, setValue] = useState('');

  const isLoadingFirstResponse = messages.length === 1 && isLoading;
  const messagesToRender = messages.slice(1); //the first message is always empty - it's not interesting

  const _onConfirm = useCallback(
    (message: string) => {
      onConfirm({ message, automation });
    },
    [onConfirm, automation],
  );

  //The first message should be automatically sent as an empty string (to get the initial recommendations)
  useEffect(() => {
    if (isOpen && messages.length === 0) {
      _onConfirm('');
    }
  }, [isOpen, _onConfirm, messages.length]);

  const automationId = automation.automationId;
  useEffect(() => {
    reset(); //reset upon switching automation in edit (can be while the automation dialog is open)
  }, [automationId, reset]);

  return (
    <Box
      sx={{
        position: 'relative',
        width: isOpen ? '460px' : 0,
        minWidth: isOpen ? '460px' : 0,
        transform: isOpen ? 'translateX(0)' : 'translateX(100%)',
        transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1)',
        overflow: 'hidden',
        flexShrink: 0,
        borderLeft: isOpen ? 1 : 0,
        borderColor: colors.grey[300],
        paddingTop: '20px',
      }}
    >
      <Stack position="relative" overflow="hidden" height="100%">
        <Stack pl={1.5} pr={1.5} direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h3" color="#1A1A1A">
            Impact Analysis AI
          </Typography>
          <Stack direction="row" gap={1} alignItems="center">
            <IconButton variant="outlined" size="small" onClick={reset}>
              <Refresh />
            </IconButton>
            <Button variant="outlined" size="small" onClick={close}>
              Done
            </Button>
          </Stack>
        </Stack>
        <Box
          ref={containerRef}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            flex: 1,
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Stack flex={1} padding={1.5} gap={2} paddingTop={3} paddingBottom={3} width="100%">
            {isLoadingFirstResponse && (
              <GeneratingChatLoader
                avatar={<ImpactAnalysisAvatar />}
                texts={[
                  'Scanning metadata',
                  'Identifying potential conflicts',
                  'Compiling results',
                ]}
              />
            )}
            {!isLoadingFirstResponse && (
              <AiChatMessages
                containerElement={containerRef.current}
                errorMsg={error}
                messages={messagesToRender}
                renderLoader={isLoading}
                renderInitialFetchMessage={false}
                renderNoOrgConnectedMessage={false}
                renderChatPaywallBlockMessage={false}
                assistantAvatar={<ImpactAnalysisAvatar />}
              />
            )}
          </Stack>
          {showScrollButton && (
            <Stack
              sx={{ position: 'absolute', bottom: '12px', width: '100%', alignItems: 'center' }}
            >
              <IconButton onClick={scrollToBottom} variant="outlined" size="small">
                <ArrowDown />
              </IconButton>
            </Stack>
          )}
        </Box>
        <Stack
          sx={{
            width: '100%',
            backgroundColor: '#F7F9FA',
            borderTop: `1px solid ${colors.grey[300]}`,
          }}
        >
          <AiChatInput
            value={value}
            setValue={setValue}
            textFieldVariant="secondary"
            onConfirm={_onConfirm}
            isLoading={isLoading}
            isCollapsiblePrompts={false}
            disabled={false}
            inputPlaceholder="Message Impact Analysis AI"
          />
          <Stack p={1.5} pt={0}>
            <ChatNote alignment="left" />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ImpactAnalysisChat;
