import { useCallback } from 'react';
import {
  AiChatConfigurationPayload,
  AiChatFieldPayload,
  useCrmOrgsApiFacade,
} from '../../../apis/facades/useCrmOrgsApiFacade';
import { ImpactAnalysisChatPayload } from './aiChatTypes';
import { ChatCompletionRequestMessageRole } from '@server/ai';
import { useAutomationsApiFacade } from '../../../apis/facades/useAutomationsApiFacade';

export const useAiChat = () => {
  const { post_aiConfigurationChat, post_aiFieldChat } = useCrmOrgsApiFacade();
  const { post_ai_impact_analysis_chat } = useAutomationsApiFacade();

  const onSendConfigurationMessage = useCallback(
    async ({ payload }: { payload: AiChatConfigurationPayload }) => {
      const res = await post_aiConfigurationChat(payload);
      return res;
    },
    [post_aiConfigurationChat],
  );

  const onSendFieldMessage = useCallback(
    async ({ payload }: { payload: AiChatFieldPayload }) => {
      const res = await post_aiFieldChat(payload);
      return res;
    },
    [post_aiFieldChat],
  );

  const onSendImpactAnalysisMessage = useCallback(
    async ({ payload }: { payload: ImpactAnalysisChatPayload }) => {
      const res = await post_ai_impact_analysis_chat(payload);
      return {
        role: ChatCompletionRequestMessageRole.ASSISTANT,
        content: res,
        createdAt: Date.now().toString(),
      };
    },
    [post_ai_impact_analysis_chat],
  );

  return {
    onSendFieldMessage,
    onSendConfigurationMessage,
    onSendImpactAnalysisMessage,
  };
};
