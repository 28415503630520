import { Box, FormControl } from '@mui/material';
import { useCallback } from 'react';
import { colors } from '@sweep-io/sweep-design';
import FieldLabel from '../common/FieldLabel';
import { SlackAdditionalFieldsTable } from './slack/SlackAdditionalFieldsTable';
import { SlackAddActionPanel } from './slack/SlackAddActionPanel';
import { SlackActionType } from '../../types/enums/SlackActionType';
import { TeamsRecipientsChipArray } from './TeamsRecipientsChipArray';
import { TeamsMessageEditor } from './slack/TeamsMessageEditor';

export interface TeamsRecipientInfo {
  label: string;
  id: string;
  type: TeamsRecipientType;
}

export enum TeamsRecipientType {
  CHANNEL = 'channel',
  USER = 'user',
  TEAM = 'team',
}

export interface TeamsRecipientStruct {
  type: TeamsRecipientType;
  value?: string | SlackFieldsStruct;
  label?: string;
}

interface TeamsMessageActionProps {
  readonly?: boolean;
  action: SendTeamsMessageAutomationAction;
  crmOrgId: string;
  onChange: (slackAutomationAction: SendTeamsMessageAutomationAction) => any;
  objectName: string;
}

export const TeamsMessageAction = ({
  readonly,
  action,
  crmOrgId,
  objectName,
  onChange,
}: TeamsMessageActionProps) => {
  const updateOnChange = useCallback(
    (updateProps: Partial<AutomationSendTeamsMessageActionParams>) => {
      onChange({
        ...action,
        actionParams: {
          ...action.actionParams,
          fields: [...(action.actionParams?.fields || [])],
          ...updateProps,
        },
      });
    },
    [action, onChange],
  );

  const _onNewRecipientsChange = useCallback(
    (newRecipients: TeamsRecipientInfo[]) => {
      const newItems = newRecipients.map((el: TeamsRecipientInfo) => ({
        type: el.type,
        value: el.id,
        label: el.label,
      }));
      updateOnChange({ recipients: newItems as TeamsRecipientStruct[] });
    },
    [updateOnChange],
  );

  return (
    <>
      <Box
        sx={{
          background: colors.grey[100],
          padding: '24px 24px 19px',
          borderRadius: '8px',
          margin: '16px 0',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <FormControl
          sx={{
            width: '100%',
            marginBottom: '20px',
            maxWidth: '1148px',
          }}
        >
          <Box
            sx={{
              marginBottom: '3px',
              '& div': {
                color: colors.grey[800],
                fontSize: '12px',
                fontWeight: '400',
              },
            }}
          >
            <FieldLabel label={'Recipients'} markLabelAsRequired={true} />
          </Box>

          <TeamsRecipientsChipArray
            recipients={action.actionParams?.recipients || []}
            onChange={_onNewRecipientsChange}
            readonly={!!readonly}
            customButtonText="Add Users / Teams"
          />
        </FormControl>

        <Box sx={{ position: 'relative' }}>
          <TeamsMessageEditor
            isRequired={true}
            label="Message"
            readonly={readonly}
            messageStruct={action.actionParams?.message}
            crmOrgId={crmOrgId}
            onChange={(newMsg) => {
              updateOnChange({ message: newMsg });
            }}
            objectName={objectName}
          />
        </Box>

        <SlackAdditionalFieldsTable
          readonly={readonly}
          fields={action.actionParams?.fields}
          updateOnChange={updateOnChange}
          crmOrgId={crmOrgId}
          objectName={objectName}
          label={'Attached Fields'}
        />

        <SlackAddActionPanel
          crmOrgId={crmOrgId}
          objectName={objectName}
          slackActions={action.actionParams?.actions}
          onAddAction={(type: SlackActionType) => {
            switch (type) {
              case SlackActionType.UPDATE_RECORD: {
                const currActions = action.actionParams?.actions || [];
                const newUpdateAction: UpdateRecordSlackAction = {
                  buttonText: '',
                  fields: [],
                  type: SlackActionType.UPDATE_RECORD,
                };
                const _currActions = [...currActions, newUpdateAction];
                updateOnChange({ actions: _currActions });
                break;
              }
              case SlackActionType.CREATE_RECORD: {
                const currActions = action.actionParams?.actions || [];
                const newCreateAction: CreateRecordSlackAction = {
                  buttonText: '',
                  fields: [],
                  type: SlackActionType.CREATE_RECORD,
                  objectTypeName: {
                    label: '',
                    objectType: '',
                  },
                };
                updateOnChange({ actions: [...currActions, newCreateAction] });
                break;
              }
              case SlackActionType.LINK_BUTTON: {
                const currActions = action.actionParams?.actions || [];
                const newCreateAction: LinkButtonSlackAction = {
                  buttonText: '',
                  type: SlackActionType.LINK_BUTTON,
                  _valueType: 'dynamic',
                  url: {
                    template: '',
                    variables: [],
                  },
                };
                updateOnChange({ actions: [...currActions, newCreateAction] });
                break;
              }
              default:
                break;
            }
          }}
          onUpdateAction={(_action: SlackAction, index: number) => {
            const currActions = [...(action.actionParams?.actions || [])];
            currActions[index] = _action;
            updateOnChange({ actions: currActions });
          }}
          onDeleteAction={(index: number) => {
            const currActions = [...(action.actionParams?.actions || [])];
            currActions.splice(index, 1);
            updateOnChange({ actions: currActions });
          }}
          onReorderAction={(_actions: SlackAction[]) => {
            const currActions = [...(_actions || [])];
            updateOnChange({ actions: currActions });
          }}
        />
      </Box>
    </>
  );
};
