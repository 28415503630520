import { Button as MuiButton } from '@mui/material';
import ViewInSalesforceIcon from './viewInSf.svg?react';
import { colors, Typography } from '@sweep-io/sweep-design';
import { ViewInSalesforce } from '@sweep-io/sweep-design/dist/icons';

export const ViewInSalesforceButton = ({
  viewInSalesforceLink,
}: {
  viewInSalesforceLink: string;
}) => {
  return (
    <MuiButton
      variant="squared"
      size="small"
      sx={{
        background: colors.blue[100],
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        flexWrap: 'nowrap',
        height: '32px',
        borderRadius: '2px',
      }}
      onClick={() => window.open(viewInSalesforceLink, '_blank')}
    >
      <ViewInSalesforceIcon />{' '}
      <Typography variant="body-medium" color={colors.blue[500]}>
        View in Salesforce
      </Typography>{' '}
      <ViewInSalesforce color={colors.blue[500]} />
    </MuiButton>
  );
};
