import { NodePositionChange, XYPosition } from '@xyflow/react';
import { useCallback } from 'react';
import {
  CanvasElementType,
  SweepNodesChangeType,
  SweepNodesChangeMoveGroupEvent,
  SweepNodesChangeEvent,
} from '../canvas-types';
import { RfNodeGroup, RfNodeGroupLabel, SweepCanvasRfNode } from '../canvas-types/nodeTypesData';
import { convertXYPositionToGridIndex } from '../helpers/gridPositioningUtils';
import { xYPositionOperation } from '../helpers/xYPositionOperation';
import { ObjectOperations } from './objectOperations';
import { MovingNodesORiginalPositionRef, NodeTransformations } from './useOnNodeChange';

export const useOnGroupNodePositionChange = (
  movingNodes: React.MutableRefObject<MovingNodesORiginalPositionRef>,
) => {
  const onGroupNodePositionChange = useCallback(
    ({
      nodeChange,
      node,
      nodeTransformations: { extraNodePositionChanges },
      nodeOperations,
    }: {
      nodeChange: NodePositionChange;
      node: RfNodeGroup | RfNodeGroupLabel;
      nodeTransformations: NodeTransformations; // passed by reference for performance
      nodeOperations: ObjectOperations<SweepCanvasRfNode>; // passed by reference for performance
    }) => {
      const nodeChangeEvents: SweepNodesChangeEvent[] = [];
      if (!nodeChange.position) return;
      const { getObject: getNode } = nodeOperations;
      const onGroupNodeDragStop = (groupNodeId: string, position: XYPosition) => {
        const newIndexPosition = convertXYPositionToGridIndex(position);

        const change: SweepNodesChangeMoveGroupEvent = {
          type: SweepNodesChangeType.MoveGroup,
          change: {
            groupsToMove: [
              {
                nodeId: groupNodeId,
                newPosition: newIndexPosition,
              },
            ],
          },
        };
        nodeChangeEvents.push(change);
      };

      if (node.type === CanvasElementType.GROUP_LABEL) {
        const diff = xYPositionOperation(node.position, nodeChange.position, 'subtract');

        const groupId = node.id.replace('label-', '');
        const groupNode = getNode(groupId);
        if (groupNode) {
          if (!movingNodes.current[groupNode.id]) {
            movingNodes.current[groupNode.id] = { originalPosition: groupNode.position };
          }
          const groupPosition = xYPositionOperation(groupNode.position, diff, 'subtract');

          extraNodePositionChanges.push({
            ...nodeChange,
            id: groupId,
            position: groupPosition,
          });
          if (!nodeChange.dragging) {
            onGroupNodeDragStop(groupId, groupPosition);
          }
        }
      }
      if (node.type === CanvasElementType.GROUP) {
        const groupPosition = nodeChange.position;
        const diff = xYPositionOperation(node.position, nodeChange.position, 'subtract');

        const groupLabelId = `label-${node.id}`;
        const groupLabelNode = getNode(groupLabelId);

        if (groupLabelNode) {
          if (!movingNodes.current[groupLabelNode.id]) {
            movingNodes.current[groupLabelNode.id] = { originalPosition: groupLabelNode.position };
          }

          extraNodePositionChanges.push({
            ...nodeChange,
            id: groupLabelId,
            position: xYPositionOperation(groupLabelNode.position, diff, 'subtract'),
          });
        }
        if (!nodeChange.dragging) {
          onGroupNodeDragStop(nodeChange.id, groupPosition);
        }
      }
      return nodeChangeEvents;
    },
    [movingNodes],
  );

  return {
    onGroupNodePositionChange,
  };
};
