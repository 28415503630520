import {
  SWEEP_AUTOMATIONS_LABEL,
  PLAYBOOK_ALERTS_LABEL,
  ASSIGNMENT_RULES_LABEL,
  VALIDATION_RULES_LABEL,
  SFDC_AUTOMATIONS_LABEL,
  APEX_TRIGGERS_LABEL,
  DEDUPLICATION_LABEL,
  MATCHING_LABEL,
  SCHEDULED_ASSIGNMENTS_LABEL,
  FUNNEL_HUBSPOT_ENROLLMENT_LABEL,
} from '../../../../../constants/visibility';
import { CanvasPillTypes } from '../../../canvas-types';
import { PillType } from './NodePill';

export const pillsMap: {
  [key in CanvasPillTypes]: {
    tooltip: string;
    type: PillType;
  };
} = {
  [CanvasPillTypes.automation]: {
    tooltip: SWEEP_AUTOMATIONS_LABEL,
    type: PillType.sweepAutomations,
  },
  [CanvasPillTypes.playbookAlert]: {
    tooltip: PLAYBOOK_ALERTS_LABEL,
    type: PillType.playbookAlerts,
  },
  [CanvasPillTypes.assignmentRule]: {
    tooltip: ASSIGNMENT_RULES_LABEL,
    type: PillType.assignmentRules,
  },
  [CanvasPillTypes.scheduledAssignment]: {
    tooltip: SCHEDULED_ASSIGNMENTS_LABEL,
    type: PillType.scheduledAssignments,
  },
  [CanvasPillTypes.validationRule]: {
    tooltip: VALIDATION_RULES_LABEL,
    type: PillType.validationRule,
  },
  [CanvasPillTypes.sfdcAutomation]: {
    tooltip: SFDC_AUTOMATIONS_LABEL,
    type: PillType.automation,
  },
  [CanvasPillTypes.apexTrigger]: {
    tooltip: APEX_TRIGGERS_LABEL,
    type: PillType.apex,
  },
  [CanvasPillTypes.groupAutomation]: {
    tooltip: SWEEP_AUTOMATIONS_LABEL,
    type: PillType.sweepAutomations,
  },
  [CanvasPillTypes.groupPlaybookAlert]: {
    tooltip: PLAYBOOK_ALERTS_LABEL,
    type: PillType.playbookAlerts,
  },
  [CanvasPillTypes.groupAssignmentRule]: {
    tooltip: ASSIGNMENT_RULES_LABEL,
    type: PillType.assignmentRules,
  },
  [CanvasPillTypes.groupScheduledAssignment]: {
    tooltip: SCHEDULED_ASSIGNMENTS_LABEL,
    type: PillType.scheduledAssignments,
  },
  [CanvasPillTypes.groupValidationRule]: {
    tooltip: VALIDATION_RULES_LABEL,
    type: PillType.validationRule,
  },
  [CanvasPillTypes.groupSfdcAutomation]: {
    tooltip: SFDC_AUTOMATIONS_LABEL,
    type: PillType.automation,
  },
  [CanvasPillTypes.groupApex]: {
    tooltip: APEX_TRIGGERS_LABEL,
    type: PillType.apex,
  },
  [CanvasPillTypes.groupDeduplication]: {
    tooltip: DEDUPLICATION_LABEL,
    type: PillType.deduplication,
  },
  [CanvasPillTypes.groupMatching]: {
    tooltip: MATCHING_LABEL,
    type: PillType.matching,
  },
  [CanvasPillTypes.groupAggregated]: {
    tooltip: '',
    type: PillType.more,
  },
  [CanvasPillTypes.groupHubspotEnrollment]: {
    tooltip: FUNNEL_HUBSPOT_ENROLLMENT_LABEL,
    type: PillType.hubspotEnrollment,
  },
};
