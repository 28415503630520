import { calculateGroupDimensions } from '../calculateGroupDimensions';
import { CanvasElementType } from '../canvas-types';
import { RfNodeGroup, RfNodeGroupLabel, SweepCanvasRfNode } from '../canvas-types/nodeTypesData';
import { calculateGroupLabelPosition } from '../helpers/calculateGroupLabelPosition';
import { convertXYPositionToGridIndex } from '../helpers/gridPositioningUtils';
import { ObjectOperations } from './objectOperations';

export const updateGroupBoundingBox = ({
  nodeOperations,
  groupNodeId,
}: {
  groupNodeId: string;
  nodeOperations: ObjectOperations<SweepCanvasRfNode>;
}) => {
  const { getObject: getNode, setObject: setNode, getObjects: getNodes } = nodeOperations;
  const groupNode = getNode<RfNodeGroup>(groupNodeId);
  const groupLabelNode = getNode<RfNodeGroupLabel>(`label-${groupNodeId}`);

  // Early return if parent nodes not found or of wrong type
  if (
    groupNode?.type !== CanvasElementType.GROUP ||
    groupLabelNode?.type !== CanvasElementType.GROUP_LABEL
  )
    return;

  // Recalculate group bounding box
  const { groupBoundingBox, groupNodeBoundaries } = calculateGroupDimensions({
    groupId: groupNode.id,
    nodeWithCanvasPositions: getNodes().map((n) => ({
      id: n.id,
      position: convertXYPositionToGridIndex(n.position),
      parentId: n.parentId,
    })),
    isPreview: false,
  });

  // Update group node dimensions and positions
  setNode<RfNodeGroup>(groupNode.id, (node) => {
    const updatedNode: RfNodeGroup = {
      ...node,
      width: groupBoundingBox.width,
      height: groupBoundingBox.height,
      data: {
        ...node.data,
        boundingBox: groupBoundingBox,
        nodeBoundaries: groupNodeBoundaries,
      },
    };
    return updatedNode;
  });
  // Update group label position
  setNode<RfNodeGroupLabel>(`label-${groupNode.id}`, (node) => {
    const updatedNode = {
      ...node,
      position: calculateGroupLabelPosition(groupNode.position, groupBoundingBox),
      data: {
        ...node.data,
        reactFlowPositions: groupBoundingBox,
      },
    };
    return updatedNode;
  });

  return {
    updatedGroupBoundingBox: groupBoundingBox,
    updatedGroupNodeBoundaries: groupNodeBoundaries,
  };
};
