import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { AutomationsContextProvider, AutomationsContextType } from './AutomationsContext';
import { AutomationType } from '../../types/enums/AutomationType';
import { UI_EVENTS } from '../../services/events';
import DrawerEmptyImage from '../common/empty-state/svgs/drawerEmpty.svg?react';
import { useAutomationsApiFacade } from '../../apis/facades/useAutomationsApiFacade';
import { useMemo } from 'react';

export const AutomationScheduledJobsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { patch_automation_admin_notification } = useAutomationsApiFacade();

  const contextValue: AutomationsContextType = useMemo(
    () => ({
      automationType: AutomationType.ScheduledJob,
      permissions: {
        create: 'create:automations',
        edit: 'edit:automations',
        deploy: 'deploy:automations',
        delete: 'delete:automations',
        notifyAdmin: 'edit:automations:notify-admin',
      },
      emptyStateTitle: 'Create your first Scheduled job',
      emptyStateImage: <DrawerEmptyImage />,
      defaultName: 'New Scheduled Job',
      placeholder: 'New Scheduled Job',
      nameInMessage: 'scheduled jobs',
      nameInMessageSingular: 'scheduled job',
      openEvent: UI_EVENTS.scheduledJobsOpen,
      automationVariant: AutomationFormVariant.SCHEDULED_JOBS,
      templateSupport: false,
      requestDeployNotifyAdmin: patch_automation_admin_notification,
      isObjectless: true,
      impactAnalysisAiSupport: false,
    }),
    [patch_automation_admin_notification],
  );

  return <AutomationsContextProvider value={contextValue}>{children}</AutomationsContextProvider>;
};
