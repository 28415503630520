import { MouseEventHandler, useCallback, useContext, useEffect, useMemo } from 'react';
import { useReactFlow, XYPosition } from '@xyflow/react';
import {
  GroupNodeBoundaries,
  RfNodeGroup,
  RfNodeGroupLabel,
  SweepCanvasRfNode,
} from './canvas-types/nodeTypesData';
import { SWEEP_GRID_SIZE } from './const';
import {
  CanvasGridPosition,
  convertGridPositionToXYPosition,
  convertXYPositionToGridIndex,
} from './helpers/gridPositioningUtils';
import { useSweepCanvasPropsCtx } from './internal-context/SweepCanvasPropsCtx';
import { useSweepCanvasState } from './internal-context/CanvasStateContext';
import { createObjectOperations } from './node-changes-event-handlers/objectOperations';
import { calculateGroupLabelPosition } from './helpers/calculateGroupLabelPosition';
import { CanvasWrapperResizeObserverContext } from './internal-context/CanvasWrapperDimensionsContext';
import usePrevious from '../../hooks/usePrevious';

export interface MoveCanvasGroups {
  groupIds: string[];
  onDrop: (groupDropPosition: CanvasGridPosition) => void;
}

// This hook calculates the position of a group node based on mouse coordinates, taking into account the grid size and zoom level
// It returns the position of the group node in the canvas grid and the position in the react flow
const useCalcGroupPosFromMouse = () => {
  const { getZoom, screenToFlowPosition } = useReactFlow();

  return useCallback(
    ({ minCol, minRow }: GroupNodeBoundaries, currentMousePosition: XYPosition) => {
      const zoom = getZoom();
      const position = {
        x: currentMousePosition.x - minCol * SWEEP_GRID_SIZE.width * zoom,
        y: currentMousePosition.y + minRow * SWEEP_GRID_SIZE.height * zoom,
      };

      const xYPosition = screenToFlowPosition(position);
      const canvasGridPosition = convertXYPositionToGridIndex(xYPosition);
      return { canvasGridPosition, xYPosition };
    },
    [getZoom, screenToFlowPosition],
  );
};

export function useMoveGroups() {
  const { moveGroups } = useSweepCanvasPropsCtx();
  const { setCanvasNodes, canvasNodes } = useSweepCanvasState();
  const { sweepGroups } = useSweepCanvasPropsCtx();

  const calcGroupPosFromMouse = useCalcGroupPosFromMouse();
  const { canvasWrapperRef } = useContext(CanvasWrapperResizeObserverContext);

  const moveGroupsToPosition = useCallback(
    (screenPosition: XYPosition, moveGroups: MoveCanvasGroups) => {
      setCanvasNodes((nodes) => {
        const {
          getObject: getNode,
          setObject: setNode,
          getObjects: getAllNodes,
        } = createObjectOperations<SweepCanvasRfNode>(nodes);

        moveGroups.groupIds.forEach((groupId) => {
          const sweepNode = sweepGroups.find((node) => node.id === groupId);
          const groupNode = getNode<RfNodeGroup>(groupId);
          const labelGroupNode = getNode<RfNodeGroupLabel>(`label-${groupId}`);

          if (groupNode && labelGroupNode && sweepNode) {
            const { xYPosition } = calcGroupPosFromMouse(
              groupNode.data.nodeBoundaries,
              screenPosition,
            );

            const groupXyPosition = convertGridPositionToXYPosition(sweepNode.position);

            const groupPosition = {
              x: xYPosition.x + groupXyPosition.x,
              y: xYPosition.y + groupXyPosition.y,
            };

            setNode<RfNodeGroup>(groupId, {
              ...groupNode,
              position: groupPosition,
            });

            setNode<RfNodeGroupLabel>(`label-${groupId}`, {
              ...labelGroupNode,
              position: calculateGroupLabelPosition(groupPosition, groupNode.data.boundingBox),
            });
          }
        });
        return getAllNodes();
      });
    },
    [calcGroupPosFromMouse, setCanvasNodes, sweepGroups],
  );

  const onMoveGroupsMouseMoveHandler: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (!moveGroups) {
        return;
      }
      moveGroupsToPosition(
        {
          x: e.clientX,
          y: e.clientY,
        },
        moveGroups,
      );
    },
    [moveGroups, moveGroupsToPosition],
  );

  const handleOnGroupsDropClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (!moveGroups) {
        return;
      }
      const currentMousePosition = {
        x: e.clientX,
        y: e.clientY,
      };
      const { getObject: getNode } = createObjectOperations<SweepCanvasRfNode>(canvasNodes);

      const groupNode = getNode<RfNodeGroup>(moveGroups.groupIds[0]);

      if (groupNode) {
        const { canvasGridPosition: canvasGridPosition } = calcGroupPosFromMouse(
          groupNode.data.nodeBoundaries,
          currentMousePosition,
        );
        moveGroups.onDrop(canvasGridPosition);
      }
    },
    [calcGroupPosFromMouse, moveGroups, canvasNodes],
  );

  // Before the mouse move event is triggered, we need to ensure the groups are in the center of the canvas
  useEffect(() => {
    if (moveGroups?.groupIds && canvasWrapperRef.current) {
      // viewport center coordinates
      const boundingRect = canvasWrapperRef.current.getBoundingClientRect();
      const viewportCenter = {
        x: boundingRect.left + boundingRect.width / 2,
        y: boundingRect.top + boundingRect.height / 2,
      };

      // We need a small timeout to ensure the DOM has updated and the viewport center calculation is accurate
      // Without this, the initial position of dragged groups may be incorrect
      setTimeout(() => {
        moveGroupsToPosition(
          {
            x: viewportCenter.x,
            y: viewportCenter.y,
          },
          moveGroups,
        );
      }, 5);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveGroups?.groupIds]);

  const previousMoveGroupIds = usePrevious(moveGroups?.groupIds ?? null);

  // When the move groups are removed, we need to remove the nodes that are no longer in the move groups
  // This is to ensure that the nodes are not left behind when the move groups are removed
  useEffect(() => {
    if (!moveGroups?.groupIds && previousMoveGroupIds) {
      setCanvasNodes((nodes) => {
        return nodes.filter((node) => {
          if (previousMoveGroupIds.includes(node.id.replace('label-', ''))) {
            return false;
          }
          if (node.parentId && previousMoveGroupIds.includes(node.parentId)) {
            return false;
          }
          return true;
        });
      });
    }
  }, [moveGroups?.groupIds, previousMoveGroupIds, setCanvasNodes]);

  return useMemo(() => {
    if (!moveGroups) {
      return {
        handleOnGroupsDropClick: undefined,
        onMoveGroupsMouseMoveHandler: undefined,
      };
    }
    return {
      handleOnGroupsDropClick,
      onMoveGroupsMouseMoveHandler,
    };
  }, [handleOnGroupsDropClick, moveGroups, onMoveGroupsMouseMoveHandler]);
}
