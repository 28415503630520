import { useCallback } from 'react';
import { usePanels } from '../../../../../panels/usePanels';
import { usePlacingPluginContext } from '../../placing-plugin/PlacingPluginContext';
import { PluginDynamicPathSvg } from '../icons';
import { PluginTypes } from '../../../../../../types/enums/PluginTypes';

export const useOnAddDynamicPathPlugin = () => {
  const { setPlacingPlugin } = usePlacingPluginContext();
  const { forceCloseAllPanels } = usePanels();

  const onAdd = useCallback(() => {
    forceCloseAllPanels();
    setPlacingPlugin({
      pluginImage: <PluginDynamicPathSvg />,
      type: PluginTypes.DYNAMIC_PATH,
    });
  }, [forceCloseAllPanels, setPlacingPlugin]);

  return onAdd;
};
