import { SearchInput } from './SearchInput';
import { ChevronUp, ChevronDown, ChevronRight } from '@sweep-io/sweep-design/dist/icons';
import { VerticalCenteredBox } from '../pages/environments/styledComponents';
import { Box, Collapse, Divider, ListItemButton, ListItemText } from '@mui/material';
import { Badge, Button, Typography } from '@sweep-io/sweep-design';
import {
  Fragment,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ObjectTypeLabelAndKeyTag } from './ObjectTypeLabelAndKey';
import { filterItemsBySearch } from '../../lib/filterItemsBySearch';
import { useRunOnceWhenTruthy } from './useRunOnceWhenTruthy';
import { highlightMatch } from '../../lib/highlightMatch';
import { StyledListItem } from './StyledListItem';
import { TruncatedTextTooltip } from './TruncatedTextTooltip';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';

export type OnCollapsedListItemClick = ({
  item,
  parentGroup,
}: {
  item?: ListItem;
  parentGroup: ListGroup;
}) => any;

interface CollapseListProps {
  isOpen: boolean;
  groups: ListGroup[];
  showSearchField?: boolean;
  onItemClick?: OnCollapsedListItemClick;
  openAllExpanded?: boolean;
  openFirstExpanded?: boolean;
  componentHeaderText?: string;
  noItemsFoundJsx?: React.ReactNode;
  selectedItem?: ListItemWithObjectName;
  withCounter?: boolean;
  withDivider?: boolean;
  disableVirtuoso?: boolean;
  variant?: 'start' | 'end';
  groupSelectButtonLabel?: string;
  showSelectButtons?: boolean;
}

export interface ListGroup {
  label: string;
  labelColor?: string;
  objectName?: string;
  value: string; //ID
  items: ListItem[];
  selectable?: boolean;
  parentName?: string; //if we want to display label with tag
}

export interface ListItem {
  value: string;
  label: string;
  ListItemTag?: React.ReactNode;
  EndAdornment?: React.ReactNode;
}

export interface ListItemWithObjectName extends ListItem {
  objectName: string; //objectApiName
}

interface GroupIdOpenStatusMap {
  [groupId: string]: boolean;
}

const setAllGroupsOpenStatus = (groups: ListGroup[], openStatus: boolean): GroupIdOpenStatusMap => {
  return groups?.reduce((prev: GroupIdOpenStatusMap, group, idx) => {
    prev[idx] = openStatus;
    return prev;
  }, {});
};

export const CollapseList = forwardRef<any, CollapseListProps>(
  (
    {
      isOpen,
      componentHeaderText,
      showSearchField,
      groups,
      onItemClick,
      openAllExpanded,
      openFirstExpanded,
      selectedItem,
      noItemsFoundJsx,
      withCounter,
      withDivider,
      disableVirtuoso,
      variant = 'end',
      groupSelectButtonLabel = 'Select',
      showSelectButtons,
    },
    ref,
  ) => {
    const virtuoso = useRef<VirtuosoHandle>(null);
    const [search, setSearch] = useState('');
    const [groupsOpenStatus, setGroupsOpenStatus] = useState<GroupIdOpenStatusMap>(
      setAllGroupsOpenStatus(groups, !!openAllExpanded),
    );

    const toggleGroupStatus = useCallback(
      (groupIdx: number) => {
        const chosenGroup = groupsOpenStatus[groupIdx];

        setGroupsOpenStatus({
          ...groupsOpenStatus,
          [groupIdx]: !chosenGroup,
        });
      },
      [groupsOpenStatus],
    );

    // Filters items and groups based on the search
    //
    // By product definition:
    // display entire children list if str matches parent label
    // display only relevant children if str doesn't match parent label
    const filteredGroups = useMemo(() => {
      return groups.reduce((filteredElements: ListGroup[], group) => {
        //Check if string matches parent label
        const parentContainsString = filterItemsBySearch([{ label: group.label }], search, 'label');
        const childrenContainsString = filterItemsBySearch<ListItem>(group.items, search, 'label');

        if (
          (parentContainsString.length && group.items.length) ||
          group.selectable ||
          childrenContainsString.length
        ) {
          filteredElements.push({
            ...group,
            label: group.label,
            items: parentContainsString.length ? group.items : childrenContainsString,
          });
        }

        return filteredElements;
      }, []);
    }, [groups, search]);

    // Expands every group that has items matching the search
    useEffect(() => {
      if (search !== '') {
        const newGroupsOpenStatus = { ...groupsOpenStatus };
        filteredGroups.filter((group) => !!group.items.length);
        // .forEach((group, groupIdx) => ([groupIdx] = true));
        setGroupsOpenStatus(newGroupsOpenStatus);
      }
      //to reduce amount of rerenders
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredGroups, search]);

    useEffect(() => {
      if (!isOpen) {
        setSearch('');
        setGroupsOpenStatus(setAllGroupsOpenStatus(groups, !!openAllExpanded));
      }
    }, [isOpen, openAllExpanded, groups]);

    const setSearchFunc = (searchTerm: string) => {
      setSearch(searchTerm);
      virtuoso.current?.scrollToIndex({
        index: 0,
      });
      if (searchTerm === '') {
        setGroupsOpenStatus(setAllGroupsOpenStatus(groups, false));
      }
    };

    useRunOnceWhenTruthy(() => {
      toggleGroupStatus(0);
    }, !!openFirstExpanded);

    useImperativeHandle(ref, () => {
      return {
        clearSearch: () => {
          setSearch('');
        },
        search,
      };
    }, [search]);

    const isVariantStart = variant === 'start';
    const isVariantEnd = variant === 'end';

    const renderItem = (group: ListGroup, rowIndex: number) => {
      const isGroupOpened = groupsOpenStatus[rowIndex];
      const expandIconComponentVariantEnd = isGroupOpened ? <ChevronUp /> : <ChevronDown />;
      const expandIconComponentVariantStart = isGroupOpened ? <ChevronDown /> : <ChevronRight />;
      const expandIconComponent = isVariantStart
        ? expandIconComponentVariantStart
        : expandIconComponentVariantEnd;

      const groupHasItems = group.items.length > 0;

      return (
        <>
          <ListItemButton
            key={group.value}
            onClick={() => toggleGroupStatus(rowIndex)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 2,
              '.MuiButton-root': {
                minWidth: '1px',
              },
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {isVariantStart && groupHasItems && (
                <Box component="span" display="flex" mr="3px">
                  {expandIconComponent}
                </Box>
              )}
              <ListItemText
                sx={{
                  display: 'flex',
                  gap: '12px',
                  alignItems: 'center',
                }}
                primary={
                  group?.parentName ? (
                    <TruncatedTextTooltip variant="body-bold">
                      {group.parentName}
                    </TruncatedTextTooltip>
                  ) : null
                }
                secondary={
                  <Box sx={{ display: 'flex', gap: 0.5 }}>
                    <ObjectTypeLabelAndKeyTag
                      label={highlightMatch(group.label, search)}
                      objectType={group.objectName ?? group.value}
                      background={group.labelColor}
                    />
                    {withCounter && (
                      <Badge variant="secondary" showZero label={group.items.length} />
                    )}
                  </Box>
                }
              />
            </Box>

            {group.selectable && showSelectButtons && (
              <Button
                variant="flat"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onItemClick?.({ parentGroup: group });
                }}
              >
                {groupSelectButtonLabel}
              </Button>
            )}
            {isVariantEnd && groupHasItems && (
              <Box component="span" display="flex" ml="3px">
                {expandIconComponent}
              </Box>
            )}
          </ListItemButton>

          <Collapse in={groupsOpenStatus[rowIndex]} timeout="auto">
            {group.items.map((item, idx) => {
              const isActive = !selectedItem?.objectName
                ? selectedItem?.value === item.value
                : selectedItem?.value === item.value && selectedItem?.objectName === group.value;

              const endAdornments: React.ReactNode[] = [];

              if (item.EndAdornment) {
                endAdornments.push(item.EndAdornment);
              }
              if (isVariantStart && showSelectButtons) {
                endAdornments.push(
                  <Box sx={{ display: 'flex', gap: 0.5 }} className="select-button-wrapper">
                    <Button
                      variant="flat"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        onItemClick?.({ item, parentGroup: group });
                      }}
                    >
                      {groupSelectButtonLabel}
                    </Button>
                  </Box>,
                );
              }

              return (
                <StyledListItem
                  key={item.value}
                  onClick={
                    onItemClick ? () => onItemClick({ item, parentGroup: group }) : undefined
                  }
                  isActive={isActive}
                  title={highlightMatch(item.label, search)}
                  sx={{
                    display: 'flex',
                    marginTop: idx === 0 ? 1 : '',
                    pl: isVariantStart ? 5 : 2,
                    '.select-button-wrapper': {
                      display: 'none',
                      marginRight: '-2px',
                    },
                    '&:hover': {
                      '.select-button-wrapper': {
                        display: 'flex',
                      },
                    },
                  }}
                  ListItemTag={item.ListItemTag}
                  EndAdornment={endAdornments}
                />
              );
            })}
          </Collapse>

          {withDivider && (
            <Box mb={1} mt={1}>
              <Divider />
            </Box>
          )}
        </>
      );
    };

    const maybeRenderVirtuosoList = () => {
      if (!disableVirtuoso) {
        return (
          <Virtuoso
            ref={virtuoso}
            data={filteredGroups}
            style={{ height: '100%', minHeight: '150px', overflowX: 'hidden' }}
            totalCount={filteredGroups.length}
            itemContent={(rowIndex: number, group) => (
              <Fragment key={group.value + rowIndex}>{renderItem(group, rowIndex)}</Fragment>
            )}
          />
        );
      }
    };

    const maybeRenderRawList = () => {
      if (disableVirtuoso) {
        return filteredGroups.map((group, rowIndex) => (
          <Fragment key={group.value + rowIndex}>{renderItem(group, rowIndex)}</Fragment>
        ));
      }
    };

    return (
      <Box sx={{ height: '100%' }}>
        <Box sx={{ background: 'white', position: 'sticky', top: 0, zIndex: 1 }}>
          {componentHeaderText && (
            <VerticalCenteredBox
              px={2}
              sx={{
                height: 55,
              }}
            >
              <Typography variant="caption-bold">{componentHeaderText}</Typography>
            </VerticalCenteredBox>
          )}

          {showSearchField && (
            <Box sx={{ padding: '0 16px 12px 16px' }} className="search-input-wrapper">
              <SearchInput
                withFixedMagnifyingGlassIcon
                TextFieldProps={{
                  ref,
                  value: search,
                  onChange: (event) => {
                    setSearchFunc(event.target.value);
                  },
                }}
                onClearButtonClick={() => setSearchFunc('')}
              />
            </Box>
          )}
        </Box>

        <Box className="collapse-list" pt={1} sx={{ height: '100%', minWidth: '300px' }}>
          {!filteredGroups.length && Boolean(noItemsFoundJsx)}
          {maybeRenderVirtuosoList()}
          {maybeRenderRawList()}
        </Box>
      </Box>
    );
  },
);
