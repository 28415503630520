import { createContext, ReactNode, useContext, useState } from 'react';
import _noop from 'lodash/noop';

interface BoxSelectionContextType {
  mouseDown: boolean;
  setMouseDown: (v: boolean) => void;
  selection: DOMRect | null;
  setSelection: (v: DOMRect | null) => void;
  resetSelectionBox: (() => void) | null;
  setResetSelectionBox: (v: () => void) => void;
}

const BoxSelectionContext = createContext<BoxSelectionContextType>({
  mouseDown: false,
  setMouseDown: _noop,
  selection: null,
  setSelection: _noop,
  resetSelectionBox: null,
  setResetSelectionBox: _noop,
});

export const BoxSelectionProvider = ({ children }: { children: ReactNode }) => {
  const [mouseDown, setMouseDown] = useState<boolean>(false);
  const [selection, setSelection] = useState<DOMRect | null>(null);
  const [resetSelectionBox, setResetSelectionBox] = useState<(() => void) | null>(null);

  return (
    <BoxSelectionContext.Provider
      value={{
        mouseDown,
        setMouseDown,
        selection,
        setSelection,
        resetSelectionBox,
        setResetSelectionBox,
      }}
    >
      {children}
    </BoxSelectionContext.Provider>
  );
};

export const useBoxSelectionContext = () => {
  const context = useContext(BoxSelectionContext);
  if (!context) {
    throw new Error('useBoxSelectionContext must be used within a BoxSelectionProvider');
  }
  return context;
};
