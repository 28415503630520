import debounce from 'lodash/debounce';
import { useContext, useEffect } from 'react';
import { CanvasWrapperResizeObserverContext } from '../internal-context/CanvasWrapperDimensionsContext';
import { useSweepCanvasState } from '../internal-context/CanvasStateContext';
import { useSweepCanvasPropsCtx } from '../internal-context/SweepCanvasPropsCtx';
import { CanvasMode } from '../canvas-types';
import { getFitViewOptions } from './useCenterOnFirstNodeEffect';

export const useFitViewIfPreviewOnResizeEffect = () => {
  const { reactFlowInstance } = useSweepCanvasState();
  const { canvasMode } = useSweepCanvasPropsCtx();

  const { width: canvasWrapperWidth, height: canvasWrapperHeight } = useContext(
    CanvasWrapperResizeObserverContext,
  );

  const isPreview =
    canvasMode === CanvasMode.FIT_NODES_PREVIEW ||
    canvasMode === CanvasMode.PREVIEW1 ||
    canvasMode === CanvasMode.PREVIEW2 ||
    canvasMode === CanvasMode.PREVIEW3;

  useEffect(() => {
    const debouncedFitView = debounce(() => {
      if (isPreview) {
        const options = getFitViewOptions(canvasMode);

        reactFlowInstance?.fitView({
          ...options,
          duration: 300,
        });
      }
    }, 1);

    debouncedFitView();

    return () => {
      debouncedFitView.cancel();
    };
  }, [reactFlowInstance, isPreview, canvasWrapperWidth, canvasWrapperHeight, canvasMode]);
};
