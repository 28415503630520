import { AiChatMessage, ChatContextComponents } from '../common/ai-chat/aiChatTypes';
import { AiChatMessages } from '../common/ai-chat/AiChatMessages';
import { Box, Stack } from '@mui/material';
import { useAiAgentMessages } from './useAiAgentMessages';
import { IconButton } from '@sweep-io/sweep-design';
import { ArrowDown } from '@sweep-io/sweep-design/dist/icons';
import useContainerWithScrollArrow from '../../hooks/useContainerWithScrollArrow';
import StartChatWithPrompts from './StartChatWithPrompts';
import GeneratingChatLoader from './GeneratingChatLoader';
import { AI_AGENTS_INFO, PromptsLocation } from './aiAgentsConsts';
import {
  AgentType,
  ChatCompletionRequestMessageRole,
  MonitoringContextComponent,
  ProcessOptimizationContextComponent,
} from '@server/ai';
import useAiAgents from './useAiAgents';
import useAiAgentCenter from './useAiAgentCenter';
import { AiChatAssistantMessageBox } from '../common/ai-chat/message-atoms/AiChatAssistantMessageBox';
import PromptBubblesGrid from './PromptBubblesGrid';
import { useCallback } from 'react';
import useAiAgentChats from './useAiAgentChats';
import { PotentialIssue } from '@server/homepage';

interface AiAgentScrollAreaProps {
  messages: AiChatMessage[];
  isLoadingResponse: boolean;
  isLoadingChat: boolean;
  maxWidth?: string;
  searchTermToHighlight?: string;
  chatContextComponents?: ChatContextComponents;
}

const getLoaderTexts = (type: AgentType) => {
  switch (type) {
    case AgentType.ProcessOptimization:
      return ['Thinking...', 'Analyzing...', 'Generating recommendations...'];
    case AgentType.Monitoring:
      return ['Reviewing metadata...', 'Identifying issues...', 'Gathering insights...'];
    default:
      return undefined;
  }
};

const AiAgentScrollArea = ({
  messages,
  isLoadingChat,
  isLoadingResponse,
  maxWidth,
  searchTermToHighlight,
  chatContextComponents,
}: AiAgentScrollAreaProps) => {
  const { activeAgent } = useAiAgents();
  const { isExpanded } = useAiAgentCenter();
  const { onConfirmMessage, activeChatId } = useAiAgentChats();

  const { prompts, introMessage, assistantAvatar, RenderContext } =
    AI_AGENTS_INFO[activeAgent?.type ?? AgentType.Documentation];

  const {
    renderChatPaywallBlockMessage,
    renderInitialFetchMessage,
    renderNoOrgConnectedMessage,
    messagesToRender,
  } = useAiAgentMessages({
    messages,
    agentType: activeAgent?.type ?? AgentType.Documentation,
  });

  const { showScrollButton, scrollToBottom, containerRef } = useContainerWithScrollArrow();

  const renderAutoResponse =
    renderChatPaywallBlockMessage || renderInitialFetchMessage || renderNoOrgConnectedMessage;
  const renderResponseLoader = isLoadingResponse && !renderAutoResponse;
  const isEmpty = messages.length === 0;
  const hasUserMessage = messages.some((msg) => msg.role === ChatCompletionRequestMessageRole.USER);

  const isRenderOnlyPrompts =
    isEmpty && activeAgent && !!prompts && prompts.timing === PromptsLocation.WHOLE_CHAT_AREA;

  const centerVertically = isRenderOnlyPrompts;

  const onClickPrompt = useCallback(
    (prompt: string) =>
      onConfirmMessage({
        messageStr: prompt,
        agentId: activeAgent?.id ?? '',
        agentType: activeAgent?.type ?? AgentType.Documentation,
      }),
    [onConfirmMessage, activeAgent],
  );

  const renderAgentContext = () => {
    if (!RenderContext || !activeAgent) return null;

    if (activeAgent.type === AgentType.Monitoring) {
      if (!chatContextComponents) return null;
      const MonitoringRenderContext = RenderContext as React.FC<{
        chatContextComponents: PotentialIssue[];
        agentContext: MonitoringContextComponent;
      }>;
      return (
        <MonitoringRenderContext
          chatContextComponents={chatContextComponents}
          agentContext={activeAgent.contextComponents as MonitoringContextComponent}
        />
      );
    }

    if (activeAgent.type === AgentType.ProcessOptimization) {
      const ProcessRenderContext = RenderContext as React.FC<{
        agentContext: ProcessOptimizationContextComponent[];
      }>;
      return (
        <ProcessRenderContext
          agentContext={activeAgent.contextComponents as ProcessOptimizationContextComponent[]}
        />
      );
    }

    return null;
  };

  if (!activeAgent) return null;

  return (
    <Stack position="relative" overflow="hidden" flex={1} width="100%">
      <Box
        ref={containerRef}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          flex: 1,
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Stack
          padding={1.5}
          gap={2}
          paddingBottom={4}
          sx={{
            maxWidth,
            width: '100%',
            flex: 1,
            justifyContent: centerVertically ? 'center' : 'start',
          }}
        >
          {isLoadingChat && (
            <Stack flex={1} alignSelf="flex-start" alignItems="flex-start" padding="8px 12px">
              <GeneratingChatLoader
                avatar={assistantAvatar}
                texts={getLoaderTexts(activeAgent.type)}
              />
            </Stack>
          )}
          {!isLoadingChat && (
            <>
              {isRenderOnlyPrompts && (
                <StartChatWithPrompts
                  agent={activeAgent}
                  prompts={prompts.texts}
                  variant={isExpanded ? 'expanded' : 'collapsed'}
                />
              )}
              {!isRenderOnlyPrompts && (
                <>
                  {introMessage && introMessage.text && (
                    <AiChatAssistantMessageBox
                      content={introMessage.text}
                      avatar={assistantAvatar}
                    />
                  )}
                  {renderAgentContext()}
                  {!hasUserMessage &&
                    prompts &&
                    prompts.timing === PromptsLocation.BELOW_CONTEXT && (
                      <Stack p="8px 0">
                        <PromptBubblesGrid
                          variant={isExpanded ? 'expanded' : 'collapsed'}
                          prompts={prompts.texts}
                          onClickPrompt={onClickPrompt}
                        />
                      </Stack>
                    )}
                  <Stack gap={2}>
                    <AiChatMessages
                      activeChatId={activeChatId}
                      messages={messagesToRender}
                      renderLoader={renderResponseLoader}
                      containerElement={containerRef.current}
                      searchTermToHighlight={searchTermToHighlight}
                      assistantAvatar={assistantAvatar}
                      renderInitialFetchMessage={renderInitialFetchMessage}
                      renderNoOrgConnectedMessage={renderNoOrgConnectedMessage}
                      renderChatPaywallBlockMessage={renderChatPaywallBlockMessage}
                    />
                  </Stack>
                </>
              )}
            </>
          )}
        </Stack>
      </Box>
      {showScrollButton && (
        <Stack sx={{ position: 'absolute', bottom: '12px', width: '100%', alignItems: 'center' }}>
          <IconButton onClick={scrollToBottom} variant="outlined" size="small">
            <ArrowDown />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
};

export default AiAgentScrollArea;
