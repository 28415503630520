import { useCallback } from 'react';
import uniq from 'lodash/uniq';
import { useSweepCanvasFitView } from '../../sweep-canvas/useSweepCanvasFitView';
import { getRecordTypeIdentifier } from '../configuration-canvas/utils/getRecordTypeIdentifier';

export const calculateElementsToFit = ({
  funnelsData,
  recordTypesData,
  selectedValues,
}: {
  recordTypesData?: RecordTypesData;
  funnelsData: FunnelsData;
  selectedValues: string[];
}) => {
  const recordTypesFunnelIds = selectedValues
    .map((selectedValue) => {
      const [objectName, recordTypeName] = selectedValue.split('.');
      const recordTypeFunnelIds = Object.values(funnelsData)
        .filter(
          (funnel) =>
            (funnel.recordType.objectName === objectName &&
              funnel.recordType.name === recordTypeName) ||
            funnel.funnelDetails.leadingObject.objectName === selectedValue,
        )
        .map((funnel) => funnel.id);
      return recordTypeFunnelIds;
    })
    .flat();

  const recordTypesFromObjects = selectedValues
    .map((selectedValue) => {
      const possibleOBjectName = selectedValue;
      return Object.values(recordTypesData || {})
        .filter((recordType) => recordType.objectName === possibleOBjectName)
        .map((recordType) => getRecordTypeIdentifier(recordType.name, recordType.objectName));
    })
    .flat();

  const allSelectedValues = uniq([
    ...selectedValues,
    ...recordTypesFunnelIds,
    ...recordTypesFromObjects,
  ]);
  return allSelectedValues;
};

export const useFitAroundElements = ({
  funnelsData,
  recordTypesData,
}: {
  recordTypesData?: RecordTypesData;
  funnelsData?: FunnelsData;
}) => {
  const { fitAroundNodes } = useSweepCanvasFitView();

  const fitOnElements = useCallback(
    (selectedValues: string[], fitAroundFirstStepOnly?: boolean) => {
      if (!funnelsData) return;
      const allSelectedValues = calculateElementsToFit({
        funnelsData,
        recordTypesData,
        selectedValues,
      });

      fitAroundNodes(allSelectedValues, {}, fitAroundFirstStepOnly);
    },
    [fitAroundNodes, funnelsData, recordTypesData],
  );

  return { fitOnElements };
};
