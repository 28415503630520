import { uniqueId } from '../../../lib/uniqueId';
import { AddAndDeleteRowButtons } from '../../common/AddAndDeleteRowButtons';

interface ActionButtonsProps {
  isFieldUndefined?: boolean;
  _field: AutomationActionField;
  onDelete?: () => void;
  onAdd?: (field: AutomationActionField) => void;
}

export const AutomationActionRowButtons = ({
  isFieldUndefined,
  _field,
  onDelete,
  onAdd,
}: ActionButtonsProps) => {
  return (
    <AddAndDeleteRowButtons
      onDelete={onDelete}
      onAdd={() => {
        onAdd?.({
          _id: uniqueId(),
          _fieldIds: [],

          fieldType: '',
          value: '',
        });
      }}
      deleteButtonDisabled={isFieldUndefined || _field?._isMandatory}
      addButtonDisabled={isFieldUndefined}
    />
  );
};
