import { Box, Stack } from '@mui/material';
import { Typography } from '@sweep-io/sweep-design';
import { useState } from 'react';
import { ConfirmDialog } from '../common/dialogs/ConfirmDialog';
import { SweepObjectSelector } from '../common/sweep-object-field-selectors/SweepObjectSelector';
import { selectDefaultCreationCrmOrgId } from '../../reducers/userInfoReducer';
import { useSelector } from 'react-redux';
import useCreateAgent from './useCreateAgent';
import { AgentType } from '@server/ai';
import useAiAgentChats from './useAiAgentChats';
import useAiAgents from './useAiAgents';
import useObjectTypesWithFetch from '../../hooks/useObjectTypesWithFetch';

const NewMonitoringAgentDialog = ({ closeDialog }: { closeDialog: () => void }) => {
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  const [selectedObjectType, setSelectedObjectType] = useState<string>();
  const onConfirmCreateAgent = useCreateAgent();
  const { startChatWithAgent } = useAiAgentChats();
  const { agents } = useAiAgents();
  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId: crmOrgId ?? '' });

  const objectLabel= objectTypesByName[selectedObjectType ?? '']?.label;

  const existingAgent = agents?.find(
    (agent) =>
      agent.contextComponents &&
      'objectType' in agent.contextComponents &&
      agent.contextComponents.objectType &&
      agent.contextComponents.objectType === selectedObjectType,
  );

  return (
    <ConfirmDialog
      isOpen={true}
      handleClose={closeDialog}
      PaperProps={{ sx: { width: '632px', padding: 0 } }}
      title="Select an Object to Monitor"
      titleVariant="h2"
      confirmText="Next"
      disableConfirmButton={!selectedObjectType}
      onConfirm={() => {
        if (!selectedObjectType) {
          return;
        }
        if (existingAgent) {
          startChatWithAgent({
            agent: existingAgent,
          });
          return;
        }

        onConfirmCreateAgent({
          type: AgentType.Monitoring,
          name: `${objectLabel} Monitoring`,
          selectedContext: { objectType: selectedObjectType },
        });
      }}
    >
      <Stack gap={2}>
        <Typography variant="body">
          Detect configuration issues and collaborate with an agent to resolve them.
        </Typography>
        <Box sx={{ minWidth: 159 }}>
          <SweepObjectSelector
            value={selectedObjectType}
            crmOrgId={crmOrgId ?? ''}
            onChange={(objectType) => {
              setSelectedObjectType(objectType);
            }}
            fullWidth
            autoOpen
          />
        </Box>
      </Stack>
    </ConfirmDialog>
  );
};

export default NewMonitoringAgentDialog;
