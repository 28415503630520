import { AI_AGENTS_INFO, CHAT_AREA_MAX_WIDTH } from './aiAgentsConsts';
import { ReactNode } from 'react';
import { Box, Stack } from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';
import AgentCard from './AgentCard';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import useAiAgents from './useAiAgents';
import usePermission from '../common/permissions/usePermission';
import { AgentType } from '@server/ai';
import ToggleExpandButton from './ToggleExpandButton';
import useAiAgentChats from './useAiAgentChats';
import { useFeatureToggle } from '../common/useFeatureToggle';
import usePopover from '../../hooks/usePopover';
import NewAgentButton from './NewAgentButton';

const AgentsCardsExpanded = ({
  closeButton,
  onNewAgentClick,
}: {
  closeButton: ReactNode;
  onNewAgentClick: (type: AgentType) => void;
}) => {
  const { startChatWithAgent } = useAiAgentChats();
  const { agents, agentsIsLoading } = useAiAgents();
  const [isAllowedCreate] = usePermission(['create:ai-agent']);
  const menuPopoverProps = usePopover();
  const {
    documentationAiAgent,
    aiAgentCenter: aiProcessAgent,
    monitoringAiAgent,
  } = useFeatureToggle();

  const agentsToRender = documentationAiAgent
    ? agents
    : agents?.filter((agent) => agent.type !== AgentType.Documentation);

  const allowedCreateAtLeastOneType = aiProcessAgent || monitoringAiAgent;
  const showNewAgentsButton = isAllowedCreate && allowedCreateAtLeastOneType;

  return (
    <Stack
      alignItems="center"
      width="100%"
      height="100%"
      sx={{
        backgroundColor: '#ECF8FF',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '24px',
          right: '12px',
        }}
      >
        <ToggleExpandButton />
        {closeButton}
      </Box>

      <Stack
        sx={{
          width: '100%',
          overflow: 'auto',
          alignItems: 'center',
        }}
      >
        <Stack
          p="64px 16px"
          gap={3}
          maxWidth={CHAT_AREA_MAX_WIDTH}
          sx={{
            width: `min(${CHAT_AREA_MAX_WIDTH}, 100%)`,
          }}
        >
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '16px',
              alignItems: 'start',
            }}
          >
            <Typography variant="h1-bold" color={colors.grey[800]}>
              Agentic Center
            </Typography>
            {showNewAgentsButton && (
              <Stack minWidth="fit-content">
                <NewAgentButton onNewAgentClick={onNewAgentClick} {...menuPopoverProps} />
              </Stack>
            )}
          </Stack>
          {agentsIsLoading && (
            <Stack pt={5}>
              <CenteredCircularProgress />
            </Stack>
          )}
          {!agentsIsLoading && (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '24px',
                width: '100%',
                '& > *': {
                  // This targets all direct children of the grid
                  minWidth: 0, // Allows grid items to shrink below their minimum content size
                  width: '100%', // Forces all items to take full width of their grid cell
                },
              }}
            >
              {agentsToRender?.map((agent) => (
                <AgentCard
                  key={agent.id}
                  agentName={agent.name}
                  agentType={agent.type}
                  onClick={() =>
                    startChatWithAgent({
                      agent,
                      biEventProps: {
                        origin: 'agent_card',
                        mode: 'expanded',
                      },
                    })
                  }
                  opacity={menuPopoverProps.isPopoverOpen ? 0.6 : 1}
                />
              ))}
              {!documentationAiAgent && (
                <AgentCard
                  key={AgentType.Documentation}
                  agentName={AI_AGENTS_INFO[AgentType.Documentation].nameForComingSoon}
                  agentType={AgentType.Documentation}
                  isRenderComingSoon={true}
                />
              )}
            </Box>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AgentsCardsExpanded;
