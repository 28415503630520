import { Box } from '@mui/material';
import { useCallback, useState, useRef, useEffect } from 'react';
import { useSweepApi } from '../../../apis/sweep';
import { DataTable } from '../table/DataTable';
import { humanizeDate, humanizeDateVariants } from '../../helpers/humanizeDate';
import { Button, colors, IconButton, Tag, Tooltip, Typography } from '@sweep-io/sweep-design';
import { DataTableVariant } from '../table/TableTypes';
import { useSelector } from 'react-redux';
import { selectSlackRecipients } from '../../../reducers/slackReducer';
import { Info, ViewInSalesforce } from '@sweep-io/sweep-design/dist/icons';
import { AutomationActionType } from '../../../types/enums/AutomationActionType';
import { selectCustomIntegrations } from '../../../reducers/integrationsReducer';
import { getColumns } from './auditTableColumns';
import isEqual from 'lodash/isEqual';
import { AuditFilters } from './AuditFilters';
import buildURLQuery from '../../../lib/buildURLQuery';
import { telemetry } from '../../../telemetry';
import { selectCrmOrgs } from '../../pages/environments/environmentsReducer';
import debounce from 'lodash/debounce';
import { useIntegrations } from '../../../hooks/useIntegrations';
import { useRunOnceWhenTruthy } from '../useRunOnceWhenTruthy';

export enum AuditReportType {
  SLACK = 'SLACK',
  AUTOMATION_WEBHOOK = 'AUTOMATION_WEBHOOK',
  INTEGRATION_WEBHOOKS = 'INTEGRATION_WEBHOOKS',
}
export type AuditTableFilters = {
  automationId?: string;
  search?: string;
  crmOrgId?: string[];
  status?: string[];
  actionType?: string[];
  customIntegrationId?: string[];
  startTime?: string;
  endTime?: string;
};

export interface FilterOptions {
  showEnvironments?: boolean;
  showActions?: boolean;
  showIntegrations?: boolean;
  actionType?: string[];
  customIntegrationId?: string[];
  status?: string[];
}
interface AuditDialogProps {
  automation?: AutomationStructureNew;
  auditType: AuditReportType;
  integrationId?: string;
}
export enum AuditStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  PARTIAL = 'PARTIAL',
}
enum RecipientErrorCode {
  EMAIL_MISMATCH = 'EMAIL_MISMATCH',
  MISSING_FIELD = 'MISSING_FIELD',
  INVALID_CHANNEL = 'INVALID_CHANNEL',
}
interface RecipientReport {
  id: string;
  status: AuditStatus;
  errorCode?: RecipientErrorCode;
  data?: {
    fieldName?: string;
    email?: string;
  };
}
interface SubActionReport {
  actionType: string;
  status: AuditStatus.SUCCESS | AuditStatus.ERROR;
  data?: any;
  errorMessage?: string;
}
export interface ActionReport {
  actionType: string;
  subActionsReport?: SubActionReport[];
  recipientsReport?: RecipientReport[];
}

export interface DataIntegrationMessageAudit {
  id: string;
  automationName: string;
  createdAt: string;
  recordUrl: string;
  recordId: string;
  status: AuditStatus.SUCCESS | AuditStatus.PARTIAL | AuditStatus.ERROR;
  actionReport?: ActionReport;
  customIntegrationId?: string;
  crmOrgId?: string;
}
export interface DataIntegrationResponse {
  nextId?: string;
  recordCount: number;
  records: DataIntegrationMessageAudit[];
}
const RECIPIENT_STATUS_TO_ERROR_MESSAGE = {
  [RecipientErrorCode.EMAIL_MISMATCH]: "Email wasn't found in Slack",
  [RecipientErrorCode.MISSING_FIELD]: 'Slack channel field is missing',
  [RecipientErrorCode.INVALID_CHANNEL]: 'Invalid channel ID',
};

export const SLACK_ACTION_TYPES = [
  AutomationActionType.Slack,
  AutomationActionType.SlackAddMember,
  AutomationActionType.SlackArchiveDealroom,
  AutomationActionType.SlackDealRoom,
  AutomationActionType.SlackRenameDealroom,
  AutomationActionType.SendReportToSlack,
];

export const getSlackActionText = (type: AutomationActionType | string) => {
  switch (type) {
    case AutomationActionType.Slack:
      return 'Post to Slack';
    case AutomationActionType.SlackDealRoom:
      return 'Create Slack deal room';
    case AutomationActionType.SlackAddMember:
      return 'Add members to deal room';
    case AutomationActionType.SendReportToSlack:
      return 'Send Report to Slack';
    case AutomationActionType.SlackRenameDealroom:
      return 'Rename deal room';
    case AutomationActionType.SlackArchiveDealroom:
      return 'Archive deal room';
    default:
      return '';
  }
};

export const AuditTable = ({ auditType, automation, integrationId }: AuditDialogProps) => {
  const [auditData, setAuditData] = useState<DataIntegrationResponse>();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const tableRef = useRef<HTMLDivElement>(null);
  const _crmOrgs = useSelector(selectCrmOrgs);
  const slackRecipients = useSelector(selectSlackRecipients);
  const customIntegrations = useSelector(selectCustomIntegrations);
  const isSlackAudit = auditType === AuditReportType.SLACK;
  const isAutomationWebhookAudit = auditType === AuditReportType.AUTOMATION_WEBHOOK;
  const { getSlackRecipients } = useIntegrations();

  useRunOnceWhenTruthy(async () => {
    await getSlackRecipients();
  }, isSlackAudit);

  const automationSlackActions =
    isSlackAudit && automation?.automationDetails?.actions
      ? new Set(
          automation.automationDetails.actions
            .filter((action) =>
              SLACK_ACTION_TYPES.includes(action.actionType as AutomationActionType),
            )
            .map((action) => action.actionType),
        )
      : [];

  const isMultipleSlackActions = Array.isArray(automationSlackActions)
    ? false
    : automationSlackActions.size > 1;

  const automationIntegrationIds =
    isAutomationWebhookAudit && automation?.automationDetails?.actions
      ? new Set(
          automation.automationDetails.actions
            .filter((action) => action.actionType === AutomationActionType.Webhook)
            .map((action) => (action as WebhookAutomationAction)?.actionParams?.integrationId),
        )
      : [];

  const isMultipleIntegrationActions = Array.isArray(automationIntegrationIds)
    ? false
    : automationIntegrationIds.size > 1;
  const [sortAndFilter, setSortAndFilter] = useState<AuditTableFilters>({
    automationId:
      auditType !== AuditReportType.INTEGRATION_WEBHOOKS ? automation?.automationId : undefined,
    customIntegrationId:
      auditType == AuditReportType.INTEGRATION_WEBHOOKS && integrationId
        ? [integrationId]
        : undefined,
    actionType:
      auditType === AuditReportType.SLACK
        ? Array.from(automationSlackActions)
        : auditType === AuditReportType.AUTOMATION_WEBHOOK
          ? ['WEBHOOK']
          : undefined,
  });
  const getIntegrationName = useCallback(
    (integrationId: string) => {
      const integration = customIntegrations.find((d) => d.id === integrationId);
      return integration?.name;
    },
    [customIntegrations],
  );

  const sweepApi = useSweepApi();

  const getAuditData = useCallback(
    async (nextId?: string, filters?: AuditTableFilters) => {
      const currentFilters = filters || sortAndFilter;
      const searchParams = buildURLQuery({
        ...currentFilters,
        crmOrgId: currentFilters.crmOrgId?.join(','),
        status: currentFilters.status?.join(','),
        actionType: currentFilters.actionType?.join(','),
        customIntegrationId: currentFilters.customIntegrationId?.join(','),
        nextId,
      });
      try {
        const response = await sweepApi.get(
          `/data-integrations/audit${searchParams ? `?${searchParams}` : ''}`,
        );
        return response.data as DataIntegrationResponse;
      } catch (e) {
        telemetry.captureError(e);
      }
    },
    [sortAndFilter, sweepApi],
  );

  const getData = useCallback(
    async (nextId?: string, filters?: AuditTableFilters) => {
      const data = await getAuditData(nextId, filters);
      if (data) {
        setAuditData((prevData) => ({
          ...data,
          records: nextId ? [...(prevData?.records || []), ...data.records] : data.records,
        }));
        setHasMore(!!data.nextId);
        setIsLoading(false);
        setIsLoadingMore(false);
      }
    },
    [getAuditData],
  );

  const loadMore = useCallback(async () => {
    if (!isLoadingMore && hasMore && auditData?.nextId) {
      setIsLoadingMore(true);
      await getData(auditData.nextId);
    }
  }, [auditData?.nextId, getData, hasMore, isLoadingMore]);

  useEffect(() => {
    const handleScroll = debounce(() => {
      if (!tableRef.current) return;
      const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
      if (scrollHeight - scrollTop - clientHeight < 100) {
        if (!isLoadingMore) {
          loadMore();
        }
      }
    }, 150);

    const tableElement = tableRef.current;
    if (tableElement) {
      tableElement.addEventListener('scroll', handleScroll);
      return () => {
        tableElement.removeEventListener('scroll', handleScroll);
        handleScroll.cancel();
      };
    }
  }, [loadMore, isLoadingMore]);

  const filterOptions: FilterOptions = {
    showEnvironments: auditType === AuditReportType.INTEGRATION_WEBHOOKS,
    showActions: auditType === AuditReportType.SLACK && isMultipleSlackActions,
    showIntegrations:
      auditType === AuditReportType.AUTOMATION_WEBHOOK && isMultipleIntegrationActions,
    actionType: Array.from(automationSlackActions),
    customIntegrationId: Array.from(automationIntegrationIds),
  };

  const getStatusColorAndText = (status: string) => {
    switch (status) {
      case AuditStatus.SUCCESS:
        return [colors.mint[100], 'Success'];
      case AuditStatus.ERROR:
        return [colors.blush[300], 'Failed'];
      case AuditStatus.PARTIAL:
        return [colors.sun[300], 'Partially succeeded'];
      default:
        return [colors.mint[100], 'Success'];
    }
  };
  const getRecipientData = useCallback(
    (recipient: RecipientReport) => {
      if (recipient.id) {
        if (recipient.errorCode === RecipientErrorCode.INVALID_CHANNEL) {
          return <Tag label={recipient.id} />;
        }
        const slackRecord = slackRecipients.find((el) => el.id === recipient.id);
        if (slackRecord) {
          return <Tag label={`${slackRecord.label} [${slackRecord.type}]`} />;
        } else {
          return <Tag label={'[Private Channel]'} />;
        }
      } else {
        const identifier = recipient?.data?.email ?? recipient?.data?.fieldName ?? 'Custom field';
        return <Tag label={identifier} />;
      }
    },
    [slackRecipients],
  );

  const getRecipientTag = (recipient: RecipientReport) => {
    if (recipient?.errorCode) {
      return (
        <>
          - Not sent
          <Tooltip title={RECIPIENT_STATUS_TO_ERROR_MESSAGE[recipient.errorCode] ?? ''}>
            <Info />
          </Tooltip>
        </>
      );
    }
  };

  const getRecipientContent = useCallback(
    (data: DataIntegrationMessageAudit) => {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {data?.actionReport?.recipientsReport?.map((recipient) => {
            return (
              <Box key={recipient.id} sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                {getRecipientData(recipient)}
                {data.status !== AuditStatus.SUCCESS
                  ? (getRecipientTag(recipient) ?? ` - Sent`)
                  : ''}
              </Box>
            );
          })}
        </Box>
      );
    },
    [getRecipientData],
  );

  const getSlackDetailsContent = useCallback((data: DataIntegrationMessageAudit) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {data?.actionReport?.subActionsReport?.map((subAction) => {
          return (
            <Box
              key={subAction.actionType}
              sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}
            >
              {getSlackActionText(subAction.actionType as AutomationActionType)}-{' '}
              {subAction.status === AuditStatus.SUCCESS ? 'Success' : 'Failed'}
              {subAction.status !== AuditStatus.SUCCESS ? (
                <Tooltip title={subAction.errorMessage ?? ''}>
                  <Info />
                </Tooltip>
              ) : (
                ''
              )}
            </Box>
          );
        })}
      </Box>
    );
  }, []);

  const getSlackDetails = useCallback(
    (data: DataIntegrationMessageAudit) => {
      if (data?.actionReport?.actionType === AutomationActionType.Slack) {
        return getRecipientContent(data);
      } else {
        return getSlackDetailsContent(data);
      }
    },
    [getRecipientContent, getSlackDetailsContent],
  );

  const getSlackRows = useCallback(() => {
    return (
      auditData?.records?.map((data) => {
        return {
          id: data.id,
          createdAt: humanizeDate({
            dateOrTimestamp: data.createdAt ?? '',
            variant: humanizeDateVariants.TWO_DIGITS_WITH_TIME,
          }),
          recordUrl: (
            <>
              {data?.recordId}
              <Button
                endIconName="ViewInSalesforce"
                onClick={() => window.open(data.recordUrl, '_blank')}
                variant="flat"
              ></Button>
            </>
          ),
          status: (
            <Tag
              label={getStatusColorAndText(data.status)[1]}
              size="medium"
              textTheme="default"
              color={getStatusColorAndText(data.status)[0]}
            />
          ),
          details: (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {getSlackDetails(data)}
            </Box>
          ),
          action: isMultipleSlackActions
            ? getSlackActionText(data?.actionReport?.actionType as AutomationActionType)
            : undefined,
        };
      }) ?? []
    );
  }, [auditData?.records, getSlackDetails, isMultipleSlackActions]);

  const getAutomationWebhookRows = useCallback(() => {
    return (
      auditData?.records?.map((data) => {
        return {
          id: data.id,
          createdAt: humanizeDate({
            dateOrTimestamp: data.createdAt ?? '',
            variant: humanizeDateVariants.TWO_DIGITS_WITH_TIME,
          }),
          integrationName: isMultipleIntegrationActions
            ? getIntegrationName(data?.customIntegrationId ?? '')
            : undefined,
          recordUrl: (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
              {data.recordId}
              <IconButton
                onClick={() => window.open(data.recordUrl, '_blank')}
                variant="flat"
                size="small"
              >
                <ViewInSalesforce />
              </IconButton>
            </Box>
          ),
          status: (
            <Tag
              label={getStatusColorAndText(data.status)[1]}
              size="medium"
              textTheme="default"
              color={getStatusColorAndText(data.status)[0]}
            />
          ),
        };
      }) ?? []
    );
  }, [auditData?.records, getIntegrationName, isMultipleIntegrationActions]);

  const getWebhooksRows = useCallback(() => {
    return (
      auditData?.records?.map((data) => {
        return {
          id: data.id,
          createdAt: humanizeDate({
            dateOrTimestamp: data.createdAt ?? '',
            variant: humanizeDateVariants.TWO_DIGITS_WITH_TIME,
          }),
          recordUrl: (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
              {data.recordId}
              <IconButton
                onClick={() => window.open(data.recordUrl, '_blank')}
                variant="flat"
                size="small"
              >
                <ViewInSalesforce />
              </IconButton>
            </Box>
          ),
          environment: _crmOrgs?.find((org) => org.id === data.crmOrgId)?.name ?? '',
          automationName: data.automationName,
          status: (
            <Tag
              label={getStatusColorAndText(data.status)[1]}
              size="medium"
              textTheme="default"
              color={getStatusColorAndText(data.status)[0]}
            />
          ),
        };
      }) ?? []
    );
  }, [auditData?.records, _crmOrgs]);

  const rows = useCallback(() => {
    switch (auditType) {
      case AuditReportType.INTEGRATION_WEBHOOKS:
        return getWebhooksRows();
      case AuditReportType.AUTOMATION_WEBHOOK:
        return getAutomationWebhookRows();
      case AuditReportType.SLACK:
        return getSlackRows();
    }
  }, [auditType, getAutomationWebhookRows, getSlackRows, getWebhooksRows]);

  const columns = () => {
    return getColumns(auditType, isMultipleIntegrationActions, isMultipleSlackActions);
  };

  const handleFilterChange = useCallback(
    async (newFilter: Partial<AuditTableFilters>) => {
      if (!isEqual(newFilter, sortAndFilter)) {
        const newObj = { ...sortAndFilter, ...newFilter };
        setIsLoading(true);
        setSortAndFilter(newObj);
        await getData(undefined, newObj);
      }
    },
    [sortAndFilter, getData],
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <AuditFilters
        setFilter={handleFilterChange}
        sortAndFilter={sortAndFilter}
        disableFilters={false}
        filterOptions={filterOptions}
        searchPlaceholder={
          auditType === AuditReportType.INTEGRATION_WEBHOOKS
            ? 'Search record ID or automation'
            : undefined
        }
      />
      <Box ref={tableRef} sx={{ maxHeight: 'calc(100vh - 300px)', overflow: 'auto' }}>
        <DataTable
          variant={DataTableVariant.medium}
          columns={columns()}
          rows={rows() as any}
          isLoading={isLoading}
          tableEmptyStateJsx={
            <Box sx={{ mt: 3, textAlign: 'center' }}>
              <Typography variant="caption" color={colors.grey[800]}>
                No activity logs found
              </Typography>
            </Box>
          }
        />
        {isLoadingMore && (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
            <Typography variant="body" color={colors.grey[700]}>
              Loading...
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
