import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  connectSfStages,
  connectThirdPartyFunnelSteps,
} from '../../../../../reducers/united-canvas/unitedCanvasReducer';
import { ConnectStepProps } from './ConnectSteps';
import { FunnelType } from '../../../../../types/enums/FunnelType';
import { ListGroup, OnCollapsedListItemClick } from '../../../../common/CollapseList';
import { getFunnelTypeFromNodeId } from '../../../../funnel-map-canvas/helper';
import { OnConnectFunnelOrStepsClick } from '../../../../funnel-map-canvas/types';
import { getGroupsForSalesforceFunnel, getGroupsForSalesforceStep } from './getGroupsForSalesforce';
import { CurrentStep } from './types';
import { getGroupsForThirdPartyFunnel, getGroupsForThirdPartyStep } from './getGroupsForThirdParty';
import { useFeatureToggle } from '../../../../common/useFeatureToggle';

export const useConnectSteps = (funnelMap: FunnelMap) => {
  const { thirdPartyFunnelConnections } = useFeatureToggle();
  const { funnelsData, thirdPartyFunnelsData } = funnelMap;

  const dispatch = useDispatch();
  const [connectStepsButtonAnchorEl, setConnectStepsButtonAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [holdNodeHighlighted, setHoldNodeHighlighted] = useState(false);

  const [currentStageAndFunnel, setCurrentStageAndFunnel] = useState<CurrentStep>();

  const handleConnectStepsButtonClick: OnConnectFunnelOrStepsClick = useCallback(
    (event, { funnelId, stepId: stageId, funnelType }) => {
      if (funnelType === FunnelType.THIRD_PARTY) {
        setCurrentStageAndFunnel({ funnelId, funnelType, stageId });
        setConnectStepsButtonAnchorEl(event.currentTarget);
        setHoldNodeHighlighted(true);
      }

      if (funnelType === FunnelType.SALESFORCE) {
        setCurrentStageAndFunnel({ funnelId, stageId, funnelType });
        setConnectStepsButtonAnchorEl(event.currentTarget);
        setHoldNodeHighlighted(true);
      }
    },
    [],
  );

  const handleConnectStepsButtonClose = () => {
    setConnectStepsButtonAnchorEl(null);
    setHoldNodeHighlighted(false);
  };

  const addConnection = useCallback(
    (targetFunnelId: string, targetStageId?: string) => {
      if (!currentStageAndFunnel) {
        console.error('Unexpected. No currentStageAndFunnel');
        return;
      }
      const {
        funnelId: sourceFunnelId,
        funnelType: sourceFunnelType,
        stageId: sourceStageId,
      } = currentStageAndFunnel;

      const targetFunnelType = getFunnelTypeFromNodeId(funnelMap, targetFunnelId);
      if (!targetFunnelType) {
        console.error('Unexpected. No targetFunnelType found');
        return;
      }

      if (sourceFunnelType === FunnelType.SALESFORCE) {
        dispatch(
          connectSfStages({
            stageA: {
              funnelId: sourceFunnelId,
              stageId: sourceStageId,
            },
            stageB: {
              funnelId: targetFunnelId,
              stageId: targetStageId,
              funnelType: targetFunnelType,
            },
          }),
        );
      }

      if (sourceFunnelType === FunnelType.THIRD_PARTY) {
        dispatch(
          connectThirdPartyFunnelSteps({
            stageA: {
              funnelId: sourceFunnelId,
              stageId: sourceStageId,
            },
            stageB: {
              funnelId: targetFunnelId,
              stageId: targetStageId,
              funnelType: targetFunnelType,
            },
          }),
        );
      }

      handleConnectStepsButtonClose();
    },
    [currentStageAndFunnel, dispatch, funnelMap],
  );

  const stepGroups: ListGroup[] = useMemo(() => {
    if (!currentStageAndFunnel) {
      return [];
    }
    if (currentStageAndFunnel.funnelType === FunnelType.SALESFORCE) {
      if (currentStageAndFunnel.stageId) {
        return getGroupsForSalesforceStep({
          step: currentStageAndFunnel,
          funnelsData,
          thirdPartyFunnelsData,
          showConnectFunnel: thirdPartyFunnelConnections,
        });
      } else {
        return getGroupsForSalesforceFunnel({
          step: currentStageAndFunnel,
          funnelsData,
          thirdPartyFunnelsData,
          showConnectFunnel: thirdPartyFunnelConnections,
        });
      }
    }
    if (currentStageAndFunnel.funnelType === FunnelType.THIRD_PARTY) {
      if (currentStageAndFunnel.stageId) {
        return getGroupsForThirdPartyStep({
          step: currentStageAndFunnel,
          funnelsData,
          thirdPartyFunnelsData,
          showConnectFunnel: thirdPartyFunnelConnections,
        });
      } else {
        return getGroupsForThirdPartyFunnel({
          step: currentStageAndFunnel,
          funnelsData,
          thirdPartyFunnelsData,
          showConnectFunnel: thirdPartyFunnelConnections,
        });
      }
    }
    return [];
  }, [currentStageAndFunnel, funnelsData, thirdPartyFunnelConnections, thirdPartyFunnelsData]);

  const onItemClick: OnCollapsedListItemClick = useCallback(
    ({ item, parentGroup }) => {
      const targetStageId = item?.value;
      const targetFunnelId = parentGroup.value;

      addConnection(targetFunnelId, targetStageId);
    },
    [addConnection],
  );

  const connectStepsProps: ConnectStepProps = useMemo(
    () => ({
      anchorEl: connectStepsButtonAnchorEl || null,
      handleClose: handleConnectStepsButtonClose,
      stepGroups,
      onItemClick,
      startAllExpanded: false,
    }),
    [connectStepsButtonAnchorEl, onItemClick, stepGroups],
  );

  return {
    handleConnectStepsButtonClick,
    connectStepsProps,
    holdNodeHighlighted,
  };
};
