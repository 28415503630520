import { appRoutes } from '../../../../constants/appRoutes';
import { ADDITIONAL_CASES_BOXES_WITH_DIALOG } from '../getStartedConsts';
import changeFeedPreview from './previews/change-feed-preview.png';
import funnelsPreview from './previews/funnels-preview.png';
import rollupsPreview from './previews/rollups-preview.png';
import BlackFlag from './icons/black-flag.svg?react';
import BlueTool from './icons/blue-tool.svg?react';
import RedWarning from './icons/red-warning.svg?react';
import SadRedFace from './icons/sad-red-face.svg?react';
import { ReactElement } from 'react';
import {
  ConfigurationWithCounters,
  PotentialIssue,
  OrgConfigurationCounts,
} from '@server/homepage';
import { colors } from '@sweep-io/sweep-design';
import { ArrowUp, ArrowDown, Minus } from '@sweep-io/sweep-design/dist/icons';
import { OpenAIConfigurationKeys } from '@server/parser';
import alerts from './images/alerts.png';
import automations from './images/automations.png';
import assignments from './images/assignments.png';
import changeFeed from './images/changeFeed.png';
import dedupe from './images/dedupe.png';
import funnels from './images/funnels.png';
import rollups from './images/rollups.png';
import hubspot from './images/hubspot.png';
import documentation from './images/documentation.png';

export const HIGH_SEVERITY = 'High';
export const MEDIUM_SEVERITY = 'Medium';
export const LOW_SEVERITY = 'Low';
import { HOMEPAGE_EVENTS } from '../../../../services/events';

export const displayGreeting = () => {
  const currentHour = new Date().getHours();
  let greeting;

  if (currentHour < 12) {
    greeting = 'Good morning';
  } else if (currentHour < 18) {
    greeting = 'Good afternoon';
  } else {
    greeting = 'Good evening';
  }

  return greeting;
};

export const sections = {
  startUsingSweep: {
    title: `Start using Sweep's powerful tools`,
    subtitle: `Discover new ways to simplify Salesforce workflows while boosting productivity and efficiency.`,
    cards: [
      {
        title: 'Change feed',
        subtitle: 'Track every change in Salesforce, automatically',
        previewSrc: changeFeedPreview,
        onClickRoute: appRoutes.sfChangeFeed.route,
        biEvent: HOMEPAGE_EVENTS.getStartedChangeFeed,
      },
      {
        title: 'Simplified rollups',
        subtitle: 'Create rollups for any object relationship, whether master-detail or lookup.',
        previewSrc: rollupsPreview,
        onClickRoute: appRoutes.rollups.route,
        biEvent: HOMEPAGE_EVENTS.getStartedRollups,
      },
      {
        title: 'Visual workspace',
        subtitle: 'Bring your processes to life on an intuitive visual platform',
        previewSrc: funnelsPreview,
        onClickRoute: appRoutes.funnelMapsPage.route,
        biEvent: HOMEPAGE_EVENTS.getStartedFunnels,
      },
    ],
  },
  getMore: {
    title: `Get even more out of Sweep`,
    subtitle: `Activate additional features to accelerate business goals, optimize data hygiene and improve overall efficiency`,
    cards: ADDITIONAL_CASES_BOXES_WITH_DIALOG,
  },
};

export const ConfigurationTypeToPotentialIssueIcon: {
  [key: string]: ReactElement;
} = {
  [OpenAIConfigurationKeys.VALIDATION_RULES]: <SadRedFace width="100%" />,
  [OpenAIConfigurationKeys.APPROVAL_PROCESSES]: <BlackFlag width="100%" />,
  [OpenAIConfigurationKeys.WORKFLOW_RULES]: <RedWarning width="100%" />,
  [OpenAIConfigurationKeys.PROCESS_BUILDER_FLOWS]: <BlueTool width="100%" />,
  [OpenAIConfigurationKeys.FLOWS]: <BlackFlag width="100%" />,
  [OpenAIConfigurationKeys.LAYOUTS]: <SadRedFace width="100%" />,
  [OpenAIConfigurationKeys.APEX_CLASSES]: <RedWarning width="100%" />,
  [OpenAIConfigurationKeys.APEX_TRIGGERS]: <BlueTool width="100%" />,
  [OpenAIConfigurationKeys.CPQ_DATA]: <SadRedFace width="100%" />,
};

export const PotentialIssueSeverityIcon: {
  [key: string]: ReactElement;
} = {
  High: <ArrowUp color={colors.blush[600]} />,
  Medium: <Minus color={colors.blue[500]} />,
  Low: <ArrowDown color={colors.seafoam[500]} />,
};

export const sortBySeverity = (arr?: PotentialIssue[]) => {
  if (!arr) {
    return [];
  }
  const severityOrder = {
    [HIGH_SEVERITY]: 1,
    [MEDIUM_SEVERITY]: 2,
    [LOW_SEVERITY]: 3,
  };

  return arr.toSorted((a, b) => severityOrder[a.severity] - severityOrder[b.severity]);
};

export const sortByType = (obj: OrgConfigurationCounts): OrgConfigurationCounts => {
  const order = [
    ConfigurationWithCounters.customFields,
    ConfigurationWithCounters.recordTypes,
    ConfigurationWithCounters.flows,
    ConfigurationWithCounters.validationRules,
    ConfigurationWithCounters.apexClasses,
    ConfigurationWithCounters.apexTriggers,
    ConfigurationWithCounters.approvalProcesses,
  ];
  return Object.fromEntries(
    order.filter((key) => key in obj).map((key) => [key, obj[key]]),
  ) as OrgConfigurationCounts;
};

export const getPotentialIssues = (items?: PotentialIssue[]) => {
  if (!items) {
    return [];
  }

  const typeOrder = [
    OpenAIConfigurationKeys.APEX_CLASSES,
    OpenAIConfigurationKeys.VALIDATION_RULES,
    OpenAIConfigurationKeys.FLOWS,
    OpenAIConfigurationKeys.APEX_TRIGGERS,
    OpenAIConfigurationKeys.APPROVAL_PROCESSES,
    OpenAIConfigurationKeys.PROCESS_BUILDER_FLOWS,
    OpenAIConfigurationKeys.WORKFLOW_RULES,
    OpenAIConfigurationKeys.CPQ_DATA,
    OpenAIConfigurationKeys.LAYOUTS,
  ];
  const requiredCount = 4;
  const perTypeCount = 1;

  const grouped: Record<OpenAIConfigurationKeys, PotentialIssue[]> = {
    [OpenAIConfigurationKeys.VALIDATION_RULES]: [],
    [OpenAIConfigurationKeys.FLOWS]: [],
    [OpenAIConfigurationKeys.APEX_TRIGGERS]: [],
    [OpenAIConfigurationKeys.APPROVAL_PROCESSES]: [],
    [OpenAIConfigurationKeys.APEX_CLASSES]: [],
    [OpenAIConfigurationKeys.WORKFLOW_RULES]: [],
    [OpenAIConfigurationKeys.PROCESS_BUILDER_FLOWS]: [],
    [OpenAIConfigurationKeys.CPQ_DATA]: [],
    [OpenAIConfigurationKeys.LAYOUTS]: [],
  };

  items.forEach((item) => {
    grouped[item.configurationType].push(item);
  });

  let result: PotentialIssue[] = [];

  for (const type of typeOrder) {
    const needed = perTypeCount - result.filter((i) => i.configurationType === type).length;
    if (needed > 0) {
      result = result.concat(grouped[type].splice(0, needed));
    }
  }

  let index = 0;
  while (result.length < requiredCount && index < typeOrder.length) {
    const type = typeOrder[index];
    while (result.length < requiredCount && grouped[type].length > 0) {
      result.push(grouped[type].shift()!);
    }
    index++;
  }

  return result;
};

export const descriptionsMap = {
  'Inconsistent Naming & Structure': 'Misleading naming conventions',
  'Weak Error Handling': 'Hardcoded errors, poor handling',
  'Unclear Variable Names': 'Unreadable variable names',
  'Performance Issues': 'Excessive SOQL queries',
  'Incorrect Sharing Setup': 'Improper access control',
  'Hardcoded Values': 'Hardcoded values in code',
  'Inefficient Queries': 'Queries inside loops',
  'Duplicate Code': 'Redundant logic across methods',
  'Security Risks': 'SOQL injection risk',
  'Low Test Coverage': 'Insufficient unit tests',
  'Too Many Entry Points': 'Multiple triggers per object',
  'Lack of Reusable Components': 'No reusable subflows',
  'Inefficient Resource Use': 'Unoptimized system resources',
  'Missing Error Handling': 'No fault connectors',
  'Outdated Automation Method': 'Using Workflow/Process Builder',
  'Unnecessary Flow Triggers': 'Flow triggers on all changes',
  'Missing Field Size Check': 'No validation for field sizes',
  'Outdated Validation Rules': 'Unnecessary or obsolete rules',
  'Too Many Validation Rules': 'Excessive validation checks',
  'Missing Profile-Based Rules': 'No profile-specific conditions',
  'Unclear Validation Criteria': 'Ambiguous rule conditions',
  'Validation Rule Overuse': 'Complex logic better in flows',
};

export const carouselData = [
  {
    name: 'Custom Slack Alert',
    route: appRoutes.alerts.route,
    imgSrc: alerts,
    bgColor: colors.storm[50],
    navigateTo: appRoutes.alerts.route,
  },
  {
    name: 'Custom Automation',
    route: appRoutes.automations.route,
    imgSrc: automations,
    bgColor: colors.sky[100],
    navigateTo: appRoutes.automations.route,
  },
  {
    name: 'Documentation',
    route: appRoutes.documentation.route,
    imgSrc: documentation,
    bgColor: colors.storm[50],
    navigateTo: appRoutes.documentation.route,
  },
  {
    name: 'Rollups',
    route: appRoutes.rollups.route,
    imgSrc: rollups,
    bgColor: colors.blue[100],
    navigateTo: appRoutes.rollups.route,
  },
  {
    name: 'Assignment Rules',
    route: appRoutes.routing.route,
    imgSrc: assignments,
    bgColor: colors.blush[100],
    navigateTo: appRoutes.routing.route,
  },
  {
    name: 'Deduplication & Matching',
    route: appRoutes.dedup.route,
    imgSrc: dedupe,
    bgColor: colors.sky[100],
    navigateTo: appRoutes.dedup.route,
  },
  {
    name: 'Visualize your sales motion',
    route: appRoutes.funnelMapsPage.route,
    imgSrc: funnels,
    bgColor: colors.storm[50],
    navigateTo: appRoutes.funnelMapsPage.route,
  },
  {
    name: 'Track Changes',
    route: appRoutes.sfChangeFeed.route,
    imgSrc: changeFeed,
    bgColor: colors.blue[100],
    navigateTo: appRoutes.sfChangeFeed.route,
  },
  {
    name: 'Marketing Attribution',
    route: appRoutes.hubspot.route,
    imgSrc: hubspot,
    bgColor: colors.blush[100],
    navigateTo: appRoutes.hubspot.route,
  },
];
