import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Button, colors, Typography } from '@sweep-io/sweep-design';
import { CtaDialog } from './CtaDialog';
import { useState } from 'react';
import usePaywall from '../../usePaywall';
import { PaywallSource } from '../../paywallTypes';

interface HeaderWithPaywallCtaProps {
  headerText: string;
  subHeaderText: string;
  imgSrc: string;
  imgAlt: string;
  name: string;
  source: PaywallSource;
}

export const HeaderWithPaywallCta = ({
  headerText,
  subHeaderText,
  imgSrc,
  imgAlt,
  name,
  source,
}: HeaderWithPaywallCtaProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { ctaClickedCallback } = usePaywall();

  return (
    <Stack
      direction="row"
      sx={{
        background: colors.grey[100],
        p: '64px 102px',
        alignItems: 'center',
        width: '100%',
      }}
      justifyContent="center"
    >
      {isDialogOpen && (
        <CtaDialog name={name} source={source} onClose={() => setIsDialogOpen(false)} />
      )}
      <Stack direction="row" sx={{ width: '1080px', gap: 18 }} justifyContent="space-between">
        <Stack gap={4}>
          <Stack gap={1}>
            <Typography variant="h1-bold" color={colors.night[900]}>
              {headerText}
            </Typography>
            <Typography variant="body" color={colors.night[900]}>
              {subHeaderText}
            </Typography>
          </Stack>
          <Box>
            <Button
              onClick={() => {
                ctaClickedCallback(source);
                setIsDialogOpen(true);
              }}
            >
              Start your trial
            </Button>
          </Box>

          <Stack width="448px">
            <Typography variant="caption" color={colors.grey[700]}>
              You're on Sweep's free tier—unlock premium features like record updates, process
              orchestration, and integrations with a 14-day trial.
            </Typography>
          </Stack>
        </Stack>
        <img src={imgSrc} alt={imgAlt} style={{ height: '100%', width: '436px' }} />
      </Stack>
    </Stack>
  );
};
