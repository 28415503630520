import { Box } from '@mui/material';
import pluralize from 'pluralize';
import { SearchInput } from '../../../common/SearchInput';
import { FlexBox } from '../../../common/FlexBox';
import { Button, colors, Typography } from '@sweep-io/sweep-design';
import { useCallback, useState } from 'react';
import { HubspotCampaignDialog } from './campaign-dialog/HubspotCampaignDialog';
import { FormCampaign, HubspotCampaignContent } from './campaign-dialog/HubspotCampaignContent';
import { selectCampaigns, selectCampaignWorkflows } from '../../../../reducers/hubspotReducer';
import { useSelector } from 'react-redux';
import ListItemActionCard from '../../../common/ListItemActionCard';
import { Commands } from '../../../../types/enums/Common';
import { Edit } from '@sweep-io/sweep-design/dist/icons/Edit';
import { filterItemsBySearch } from '../../../../lib/filterItemsBySearch';
import { HubspotCampaignCtxProvider } from './campaign-dialog/HubspotCampaignCtx';
import { useLoadSfCampaignFieldsForHubspot } from './useLoadSfCampaignFieldsForHubspot';
import { Enrollment } from '@sweep-io/sweep-design/dist/icons/Enrollment';
import { useCanvasFilters } from '../../configuration-canvas-filters/useCanvasFilters';
import { HubspotFilterTypes } from './HubspotCanvasPanel';
import { useCampaigns } from './campaign-dialog/useCampaigns';
import { SortDisplayTypes, SweepSort, SweepSortOption } from '../../../common/SweepSort';
import RestrictedTooltip from '../../../common/permissions/RestrictedTooltip';
import usePermission from '../../../common/permissions/usePermission';
import { newSearchSx } from '../canvasPanelConsts';
import { HubspotFilters } from './HubspotFilters';
import { CampaignMemberStatusProvider } from '../campaign-member-status/CampaignMemberStatusProvider';
import { Copy } from '@sweep-io/sweep-design/dist/icons/Copy';
import { Campaign } from './campaign-dialog/campaignTypes';
import useSendBiEvent from '../../../../hooks/useSendBiEvent';
import { ACTIONS_EVENTS } from '../../../../services/events';

const actionsMenuItems = [
  {
    label: Commands.Edit,
    value: Commands.Edit,
    actionIcon: <Edit />,
    // addBottomDivider: true,
  },
  // {
  //   label: Commands.Delete,
  //   value: Commands.Delete,
  //   actionIcon: <Delete color={colors.blush[600]} />,
  //   actionSx: { color: colors.blush[600] },
  // },
  { label: Commands.Duplicate, value: Commands.Duplicate, actionIcon: <Copy /> },
];

const disabledActionsMenuItems: any = [];

const sortOptions: SweepSortOption<'LastModifiedDate' | 'CreatedDate' | 'Name'>[] = [
  { label: 'Campaign name', value: 'Name' },
  { label: 'Last modified date', value: 'LastModifiedDate' },
  { label: 'Create date', value: 'CreatedDate' },
];

const EnrolmentRulesByCampaign = ({ campaignId }: { campaignId: string }) => {
  const campaignActiveWorkflows = useSelector(selectCampaignWorkflows(campaignId)).length;
  if (campaignActiveWorkflows === 0) {
    return null;
  }
  return (
    <FlexBox alignItems="center" gap={1} mt={'2px'}>
      <Enrollment color={colors.mint[500]} />
      <Typography variant="body" color={colors.grey[700]}>
        {campaignActiveWorkflows} Active Enrolment {pluralize('Rule', campaignActiveWorkflows)}
      </Typography>
    </FlexBox>
  );
};
export const PanelCampaignsTab = ({ crmOrgId, hsOrgId }: { crmOrgId: string; hsOrgId: string }) => {
  const { deleteCampaign } = useCampaigns();
  const allCampaigns = useSelector(selectCampaigns);
  const sendBiEvent = useSendBiEvent();

  const [campaignDialog, setCampaignDialog] = useState<{
    open: boolean;
    campaign?: FormCampaign;
  }>();

  const [sortBy, setSortBy] = useState<'LastModifiedDate' | 'CreatedDate' | 'Name'>('Name');

  const { campaignStatusOptions, campaignTypeOptions } = useLoadSfCampaignFieldsForHubspot();

  const { filterValues: canvasFilterValues } = useCanvasFilters();

  const closeCampaignDialog = useCallback(() => setCampaignDialog(undefined), []);

  const newCampaign = useCallback(() => setCampaignDialog({ open: true }), []);

  const editCampaign = useCallback(
    (campaign: Campaign) => () => {
      setCampaignDialog({ open: false });
      setTimeout(() => {
        setCampaignDialog({ open: true, campaign });
      }, 0);
    },
    [],
  );

  const duplicateCampaign = useCallback(
    (campaign: Campaign) => {
      setCampaignDialog({ open: false });
      sendBiEvent({ name: ACTIONS_EVENTS.hubspotDuplicateCampaignForm });
      setTimeout(() => {
        setCampaignDialog({
          open: true,
          campaign: { ...campaign, Id: undefined, Name: `Copy of ${campaign.Name}` },
        });
      }, 0);
    },
    [sendBiEvent],
  );

  const [search, setSearch] = useState('');
  const canvasFiltersCampaigns = allCampaigns?.filter((campaign) => {
    const activityFilter = canvasFilterValues[HubspotFilterTypes.ACTIVITY];
    if (activityFilter && activityFilter.selectedValues.length > 0) {
      if (campaign.IsActive) {
        if (!activityFilter.selectedValues.includes('active')) {
          return false;
        }
      } else {
        if (!activityFilter.selectedValues.includes('inactive')) {
          return false;
        }
      }
    }
    const statusFilter = canvasFilterValues[HubspotFilterTypes.CAMPAIGN_STATUS];
    if (statusFilter && statusFilter.selectedValues.length > 0) {
      if (!statusFilter.selectedValues.includes(campaign.Status || '')) {
        return false;
      }
    }

    const typeFilter = canvasFilterValues[HubspotFilterTypes.CAMPAIGN_TYPE];
    if (typeFilter && typeFilter.selectedValues.length > 0) {
      if (!typeFilter.selectedValues.includes(campaign.Type || '')) {
        return false;
      }
    }

    return true;
  });
  const filteredCampaigns = filterItemsBySearch(
    canvasFiltersCampaigns || [],
    search,
    (item) => item.Name,
  );

  const filteredAndSortedCampaigns = filteredCampaigns.sort((campaignA, campaignB) => {
    if (!campaignA[sortBy] || !campaignB[sortBy] || !campaignA.Name || !campaignB.Name) {
      return 0;
    }
    switch (sortBy) {
      case 'Name':
        return campaignA.Name.localeCompare(campaignB.Name);
      case 'LastModifiedDate':
        return new Date(campaignB[sortBy]).getTime() - new Date(campaignA[sortBy]).getTime();
      case 'CreatedDate':
        return new Date(campaignA[sortBy]).getTime() - new Date(campaignB[sortBy]).getTime();
    }
  });

  const [isCreateCampaignsAllowed] = usePermission(['create:campaigns']);

  return (
    <>
      <Box mt={0} id="panel-campaigns-tab" height="calc(100% - 115px)" flex={1}>
        <FlexBox
          sx={{
            ml: 0,
            mr: 0,
            mb: 1,
            ...newSearchSx,
          }}
        >
          <Box
            display="flex"
            gap={1}
            flex={1}
            sx={{ button: { pl: 3, pr: 3 }, alignItems: 'center' }}
          >
            <SearchInput
              TextFieldProps={{
                value: search,
                placeholder: 'Search',
                onChange: (e) => setSearch(e.target.value),
                fullWidth: true,
              }}
              withFixedMagnifyingGlassIcon={true}
              onClearButtonClick={() => setSearch('')}
              variant="small"
            />
            <RestrictedTooltip
              to={['create:campaigns']}
              notAllowedTitle={'To create Hubspot campaigns, please contact your admin'}
              component={(isAllowed) => (
                <Button
                  dataTestId="create-new-campaign-button"
                  startIconName="Plus"
                  size="small"
                  onClick={newCampaign}
                  disabled={!isAllowed}
                >
                  New
                </Button>
              )}
            ></RestrictedTooltip>
          </Box>
        </FlexBox>
        <FlexBox
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            m: '8px 16px',
            mt: 0,
          }}
        >
          <Typography variant="body" color={colors.grey[700]}>
            {pluralize('Active Campaign', filteredAndSortedCampaigns.length, true)}
          </Typography>
          <Box display="flex" gap={1}>
            <HubspotCampaignCtxProvider
              campaignStatusOptions={campaignStatusOptions}
              campaignTypeOptions={campaignTypeOptions}
              crmOrgId={crmOrgId}
              hsOrgId={hsOrgId}
              objectType="Contact"
            >
              {campaignDialog?.open && (
                <HubspotCampaignDialog isOpen={true} onClose={closeCampaignDialog}>
                  <CampaignMemberStatusProvider>
                    <HubspotCampaignContent initialCampaign={campaignDialog.campaign} />
                  </CampaignMemberStatusProvider>
                </HubspotCampaignDialog>
              )}
              <HubspotFilters withPanelWrapper />
            </HubspotCampaignCtxProvider>
            <SweepSort
              selectedValue={sortBy}
              onChange={setSortBy}
              options={sortOptions}
              displayType={SortDisplayTypes.IconAndLabel}
            />
          </Box>
        </FlexBox>
        <Box sx={{ height: '100%', overflow: 'scroll' }}>
          {filteredAndSortedCampaigns?.map((campaign) => {
            return (
              <ListItemActionCard
                key={campaign.Id}
                actionsMenuIconSize="tiny"
                title={campaign.Name}
                onLineClick={editCampaign(campaign)}
                content={<EnrolmentRulesByCampaign campaignId={campaign.Id} />}
                actionsMenu={isCreateCampaignsAllowed ? actionsMenuItems : disabledActionsMenuItems}
                isAllowedBtn={false}
                onActionMenuClick={(action) => {
                  switch (action) {
                    case Commands.Edit:
                      editCampaign(campaign)();
                      break;
                    case Commands.Delete:
                      if (campaignDialog?.campaign?.Id === campaign.Id) {
                        closeCampaignDialog();
                      }
                      deleteCampaign(campaign.Id);
                      break;
                    case Commands.Duplicate:
                      duplicateCampaign(campaign);
                      break;
                  }
                }}
              />
            );
          })}
        </Box>
      </Box>
    </>
  );
};
