import { useCallback, useMemo } from 'react';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import useAssignments from '../routing/useAssignments';
import useAlerts from './useAlerts';
import useAutomations from './useAutomations';
import useScheduledAssignments from '../routing/useScheduledAssignments';
import { AutomationAddEventMap, AutomationDeleteEventMap, getPropsForBiEvent } from './helper';
import useScheduledReports from './useScheduledReports';
import { useSelector } from 'react-redux';
import { selectAutomations } from '../../reducers/global/automationReducers';
import { selectAlerts } from '../../reducers/global/alertsReducers';
import { selectAssignments } from '../../reducers/global/assignmentReducers';
import { selectScheduledAssignments } from '../../reducers/global/scheduledAssignmentReducers';
import { selectScheduledReports } from '../../reducers/global/scheduledReportReducers';
import useSendBiEvent from '../../hooks/useSendBiEvent';
import useScheduledJobs from './useScheduledJobs';
import { selectScheduledJobs } from '../../reducers/global/scheduledJobReducers';
import { useMarketingAttributionModels } from '../pages/canvas-pages/canvas-hubspot-page/marketing-attribution/useMarketingAttributionModels';
import { selectMarketingAttributionModels } from '../../reducers/global/marketingAttributionReducer';

enum ActionsTypes {
  EDIT = 'EDIT',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  TOGGLE = 'TOGGLE',
  DEPLOY = 'DEPLOY',
}

const useAutomationApiPerType = ({
  automationVariant,
}: {
  automationVariant: AutomationFormVariant;
}) => {
  const sendBiEvent = useSendBiEvent();

  const { editAutomation, createAutomation, deleteAutomation, toggleAutomation, deployAutomation } =
    useAutomations();
  const { editAlert, createAlert, deleteAlert, toggleAlert, deployAlert } = useAlerts();
  const { editAssignment, createAssignment, deleteAssignment, toggleAssignment, deployAssignment } =
    useAssignments();

  const {
    editScheduledAssignment,
    createScheduledAssignment,
    deleteScheduledAssignment,
    toggleScheduledAssignment,
    deployScheduledAssignment,
  } = useScheduledAssignments();

  const {
    editScheduledReport,
    createScheduledReport,
    toggleScheduledReport,
    deleteScheduledReport,
    deployScheduledReport,
  } = useScheduledReports();

  const {
    editScheduledJob,
    createScheduledJob,
    toggleScheduledJob,
    deleteScheduledJob,
    deployScheduledJob,
  } = useScheduledJobs();

  const { onEditModel, onDeployModel, onDeleteModel, onAddModel } = useMarketingAttributionModels();

  const functionToCallByType = useCallback(
    (automationVariant: AutomationFormVariant, action: ActionsTypes) => {
      const elem: { [key: string]: any } = {
        [AutomationFormVariant.PLAYBOOK_ALERT]: {
          [ActionsTypes.CREATE]: createAlert,
          [ActionsTypes.EDIT]: editAlert,
          [ActionsTypes.DELETE]: deleteAlert,
          [ActionsTypes.TOGGLE]: toggleAlert,
          [ActionsTypes.DEPLOY]: deployAlert,
        },
        [AutomationFormVariant.AUTOMATIONS]: {
          [ActionsTypes.CREATE]: createAutomation,
          [ActionsTypes.EDIT]: editAutomation,
          [ActionsTypes.DELETE]: deleteAutomation,
          [ActionsTypes.TOGGLE]: toggleAutomation,
          [ActionsTypes.DEPLOY]: deployAutomation,
        },
        [AutomationFormVariant.ASSIGNMENT_RULES]: {
          [ActionsTypes.CREATE]: createAssignment,
          [ActionsTypes.EDIT]: editAssignment,
          [ActionsTypes.DELETE]: deleteAssignment,
          [ActionsTypes.TOGGLE]: toggleAssignment,
          [ActionsTypes.DEPLOY]: deployAssignment,
        },
        [AutomationFormVariant.SCHEDULED_ASSIGNMENTS]: {
          [ActionsTypes.CREATE]: createScheduledAssignment,
          [ActionsTypes.EDIT]: editScheduledAssignment,
          [ActionsTypes.DELETE]: deleteScheduledAssignment,
          [ActionsTypes.TOGGLE]: toggleScheduledAssignment,
          [ActionsTypes.DEPLOY]: deployScheduledAssignment,
        },
        [AutomationFormVariant.SCHEDULED_REPORTS]: {
          [ActionsTypes.CREATE]: createScheduledReport,
          [ActionsTypes.EDIT]: editScheduledReport,
          [ActionsTypes.DELETE]: deleteScheduledReport,
          [ActionsTypes.TOGGLE]: toggleScheduledReport,
          [ActionsTypes.DEPLOY]: deployScheduledReport,
        },
        [AutomationFormVariant.SCHEDULED_JOBS]: {
          [ActionsTypes.CREATE]: createScheduledJob,
          [ActionsTypes.EDIT]: editScheduledJob,
          [ActionsTypes.DELETE]: deleteScheduledJob,
          [ActionsTypes.TOGGLE]: toggleScheduledJob,
          [ActionsTypes.DEPLOY]: deployScheduledJob,
        },
        [AutomationFormVariant.MARKETING_ATTRIBUTIONS]: {
          [ActionsTypes.CREATE]: onAddModel,
          [ActionsTypes.EDIT]: onEditModel,
          [ActionsTypes.DELETE]: onDeleteModel,
          [ActionsTypes.TOGGLE]: () => {},
          [ActionsTypes.DEPLOY]: onDeployModel,
        },
      };
      return elem[automationVariant][action];
    },
    [
      createAlert,
      createAssignment,
      createAutomation,
      createScheduledAssignment,
      createScheduledJob,
      createScheduledReport,
      deleteAlert,
      deleteAssignment,
      deleteAutomation,
      deleteScheduledAssignment,
      deleteScheduledJob,
      deleteScheduledReport,
      deployAlert,
      deployAssignment,
      deployAutomation,
      deployScheduledAssignment,
      deployScheduledJob,
      deployScheduledReport,
      editAlert,
      editAssignment,
      editAutomation,
      editScheduledAssignment,
      editScheduledJob,
      editScheduledReport,
      toggleAlert,
      toggleAssignment,
      toggleAutomation,
      toggleScheduledAssignment,
      toggleScheduledJob,
      toggleScheduledReport,
      onAddModel,
      onEditModel,
      onDeployModel,
      onDeleteModel,
    ],
  );

  const selectorToCallByType = useMemo(() => {
    switch (automationVariant) {
      case AutomationFormVariant.PLAYBOOK_ALERT:
        return selectAlerts;
      case AutomationFormVariant.ASSIGNMENT_RULES:
        return selectAssignments;
      case AutomationFormVariant.SCHEDULED_ASSIGNMENTS:
        return selectScheduledAssignments;
      case AutomationFormVariant.SCHEDULED_REPORTS:
        return selectScheduledReports;
      case AutomationFormVariant.SCHEDULED_JOBS:
        return selectScheduledJobs;
      case AutomationFormVariant.MARKETING_ATTRIBUTIONS:
        return selectMarketingAttributionModels;
      case AutomationFormVariant.AUTOMATIONS:
      default:
        return selectAutomations;
    }
  }, [automationVariant]);

  const items = useSelector(selectorToCallByType);

  const onSaveOrCreate = useCallback(
    async (createAutomationDto: AutomationStructureNew, crmOrgIds: string[], isEdit: boolean) => {
      const toSend = {
        ...createAutomationDto,
        deployToOrgIds: crmOrgIds,
      };

      if (isEdit) {
        const fnToCall = functionToCallByType(automationVariant, ActionsTypes.EDIT);
        return await fnToCall({
          deployToOrgIds: crmOrgIds,
          updateAutomationDto: toSend,
          versionId: toSend.versionId,
        });
      } else {
        const fnToCall = functionToCallByType(automationVariant, ActionsTypes.CREATE);
        sendBiEvent({ name: AutomationAddEventMap[automationVariant] });

        return await fnToCall({ deployToOrgIds: crmOrgIds, createAutomationDto: toSend });
      }
    },
    [automationVariant, functionToCallByType, sendBiEvent],
  );

  const onToggleActivation = useCallback(
    async (
      toggleAutomationDto: AutomationStructureNew,
      deployToOrgIds: string[],
      isActive: boolean,
    ) => {
      const toSend = {
        ...toggleAutomationDto,
        deployToOrgIds,
        isActive,
      };

      const fnToCall = functionToCallByType(automationVariant, ActionsTypes.TOGGLE);
      await fnToCall({
        deployToOrgIds,
        updateAutomationDto: toSend,
        versionId: toSend.versionId,
      });
    },
    [automationVariant, functionToCallByType],
  );

  const onDeleteAutomation = useCallback(
    async (automationId: string, automationType: AutomationType) => {
      const fnToCall = functionToCallByType(automationVariant, ActionsTypes.DELETE);
      await fnToCall({ automationId, automationType });
      sendBiEvent({
        name: AutomationDeleteEventMap[automationVariant],
        props: getPropsForBiEvent(automationId, items),
      });
    },
    [automationVariant, functionToCallByType, items, sendBiEvent],
  );

  const onDeployAutomation = useCallback(
    async (deployAutomationDto: AutomationStructureNew, deployToOrgIds: string[]) => {
      const toSend = {
        ...deployAutomationDto,
        deployToOrgIds,
      };

      const fnToCall = functionToCallByType(automationVariant, ActionsTypes.DEPLOY);
      return await fnToCall({
        deployAutomationDto: toSend,
        automationId: toSend.automationId,
        versionId: toSend.versionId,
        deployToOrgIds,
      });
    },
    [automationVariant, functionToCallByType],
  );

  return {
    onSaveOrCreate,
    onToggleActivation,
    onDeleteAutomation,
    onDeployAutomation,
  };
};

export default useAutomationApiPerType;
