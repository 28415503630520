import { Box, InputAdornment, TextField } from '@mui/material';
import { useWebhooksContext } from './WebhooksContext';
import { CustomIntegrationAuthenticationType } from './WebhookContent';
import { Button, colors, Typography } from '@sweep-io/sweep-design';
import { FormSection } from './FormSection';
import { User, Key, Password } from '@sweep-io/sweep-design/dist/icons';
import { useState } from 'react';
import { useIntegrations } from '../../../hooks/useIntegrations';

export const AuthenticationComponents = () => {
  const { editWebhook, setEditWebhook } = useWebhooksContext();
  const { getCustomIntegrationPassword } = useIntegrations();
  const [showPassword, setShowPassword] = useState(false);
  const webhookType = editWebhook?.authentication?.type;
  const isEditState = editWebhook?.id;
  const isBlockSection =
    webhookType === CustomIntegrationAuthenticationType.BASIC ||
    webhookType === CustomIntegrationAuthenticationType.QUERY_PARAM_KEY ||
    webhookType === CustomIntegrationAuthenticationType.API_KEY;

  const handleClickShowPassword = async () => {
    const userPassword = await getCustomIntegrationPassword(editWebhook?.id ?? '');
    setEditWebhook({
      ...editWebhook,
      authentication: { ...editWebhook?.authentication, password: userPassword } as any,
    });
    setShowPassword(!showPassword);
  };

  const passwordLabel =
    webhookType === CustomIntegrationAuthenticationType.BASIC ? 'Password' : 'API key';

  return (
    <Box>
      {webhookType && isBlockSection && (
        <Box
          sx={{
            backgroundColor: colors.grey[100],
            border: `1px solid ${colors.grey[120]}`,
            borderRadius: 1,
            p: 2,
          }}
        >
          <FormSection
            label={
              webhookType === CustomIntegrationAuthenticationType.BASIC
                ? 'User name'
                : webhookType === CustomIntegrationAuthenticationType.QUERY_PARAM_KEY
                  ? 'Query param'
                  : 'Header'
            }
          >
            <TextField
              sx={{ width: '100%' }}
              InputProps={{
                startAdornment:
                  webhookType === CustomIntegrationAuthenticationType.BASIC ? <User /> : <Key />,
              }}
              placeholder={
                webhookType === CustomIntegrationAuthenticationType.BASIC
                  ? 'User name'
                  : webhookType === CustomIntegrationAuthenticationType.QUERY_PARAM_KEY
                    ? 'Query param'
                    : 'Header'
              }
              value={editWebhook?.authentication?.key ?? ''}
              onChange={(event) => {
                const newValue = event?.target.value;
                setEditWebhook({
                  ...editWebhook,
                  authentication: { ...editWebhook.authentication, key: newValue } as any,
                });
              }}
            />
          </FormSection>
          <FormSection label={passwordLabel}>
            <TextField
              sx={{ width: '100%' }}
              InputProps={{
                startAdornment: <Password />,
                endAdornment: isEditState && !showPassword && (
                  <InputAdornment position="end">
                    <Button onClick={handleClickShowPassword} startIconName="Show" variant="flat">
                      Show password
                    </Button>
                  </InputAdornment>
                ),
              }}
              placeholder={!isEditState ? passwordLabel : '••••••••••••'}
              type={!isEditState || showPassword ? 'text' : 'password'}
              value={editWebhook?.authentication?.password ?? ''}
              onChange={(event) => {
                const newValue = event?.target.value;
                setEditWebhook({
                  ...editWebhook,
                  authentication: { ...editWebhook.authentication, password: newValue } as any,
                });
              }}
            />
          </FormSection>
        </Box>
      )}
      {webhookType && !isBlockSection && (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 1 }}>
          <Typography variant="body" textAlign="center">
            {webhookType === CustomIntegrationAuthenticationType.BEARER_TOKEN ? 'Bearer' : 'Token'}
          </Typography>
          <TextField
            sx={{ width: '100%' }}
            InputProps={{
              startAdornment: <Key />,
              endAdornment: isEditState && !showPassword && (
                <InputAdornment position="end">
                  <Button onClick={handleClickShowPassword} startIconName="Show" variant="flat">
                    Show password
                  </Button>
                </InputAdornment>
              ),
            }}
            placeholder={
              !isEditState
                ? webhookType === CustomIntegrationAuthenticationType.BEARER_TOKEN
                  ? 'Bearer token'
                  : 'Token'
                : '••••••••••••'
            }
            type={!isEditState || showPassword ? 'text' : 'password'}
            value={editWebhook?.authentication?.password ?? ''}
            onChange={(event) => {
              const newValue = event?.target.value;
              setEditWebhook({
                ...editWebhook,
                authentication: { ...editWebhook.authentication, password: newValue } as any,
              });
            }}
          />
        </Box>
      )}
    </Box>
  );
};
