import { CssBaseline } from '@mui/material';
import { Route, Navigate, createRoutesFromElements, createBrowserRouter } from 'react-router';
import { ApiProvider } from '../apis/sweep';
import Auth0ProviderWithHistory from '../auth/Auth0ProviderWithHistory';
import { appRoutes, ROUTING_TAB } from '../constants/appRoutes';
import { lazyLoader } from '../lazyLoader';
import { App } from './App';
import { ImpersonationMode } from './ImpersonationMode';
import { Dashboard } from './dashboard/Dashboard';
import Home from './pages/Home';
import { Version } from './pages/Version';
import AppConfigurationCanvas from './pages/configuration-canvas/AppConfigurationCanvas';
import { ConfigurationCanvasRedirectToDefaultCreationEnv } from './pages/configuration-canvas/ConfigurationCanvasRedirectToDefaultCreationEnv';
import { ConfigurationPages } from './pages/configuration-canvas/configurationPages';
import { RuleBuilderDemoPage } from './pages/demos/RuleBuilderDemoPage';
import { HubspotOAuth2Redirect } from './pages/environments/HubspotOAuth2Redirect';
import { SalesforceOAuth2Redirect } from './pages/environments/SalesforceOAuth2Redirect';
import { SlackOAuth2Redirect } from './pages/environments/SlackOAuth2Redirect';
import { UnitedDashboard } from './pages/canvas-pages/united-canvas/UnitedConfigurationCanvas';
import { PanelsProvider } from './panels/PanelsContext';
import noop from 'lodash/noop';
import { ReadOnlyUnitedDashboard } from './pages/canvas-pages/united-canvas/read-only/ReadOnlyUnitedConfigurationCanvas';
import { TeamsOAuth2Redirect } from './pages/environments/TeamsOAuth2Redirect';
interface RouteRendererProps<T = any> {
  Comp: React.FC<T>;
  withDashboard: boolean;
  propsForComp?: T;
}

function RouteRenderer<T = any>({ Comp, withDashboard, propsForComp }: RouteRendererProps<T>) {
  const comp = Comp(propsForComp as T);

  return (
    <>
      <CssBaseline />
      <ImpersonationMode />
      {withDashboard ? (
        <PanelsProvider confirmOpen={noop}>
          <Dashboard>{comp}</Dashboard>
        </PanelsProvider>
      ) : (
        comp
      )}
    </>
  );
}

const AppAuth0AndApiProvidersWrapper = () => {
  return (
    <Auth0ProviderWithHistory>
      <ApiProvider>
        <App />
      </ApiProvider>
    </Auth0ProviderWithHistory>
  );
};

const routes = (
  //official guidelines don't cover integration between auth0 and createBrowserRouter
  //I used solution mentioned in issue linked below
  //https://github.com/remix-run/react-router/discussions/9321
  <Route element={<AppAuth0AndApiProvidersWrapper />}>
    <Route path={appRoutes.salesforce.route} element={<SalesforceOAuth2Redirect />} />
    <Route path={appRoutes.hubspotRedirect.route} element={<HubspotOAuth2Redirect />} />
    <Route path={appRoutes.teamsRedirect.route} element={<TeamsOAuth2Redirect />} />
    <Route path={appRoutes.slack.route} element={<SlackOAuth2Redirect />} />
    <Route path={appRoutes.home.route} element={<Home />}>
      <Route
        path={appRoutes.getStarted.route}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={lazyLoader(() => import('./pages/get-started/GetStartedEntryPoint'))}
          />
        }
      />
      <Route
        path={appRoutes.funnelMapsPage.route}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={lazyLoader(() => import('./pages/funnel-maps/FunnelMapsPage'))}
          />
        }
      />
      <Route
        path={`${appRoutes.devopsCenter.route}`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={lazyLoader(() => import('./pages/devops-center/DevOpsCenterPage'))}
          />
        }
      />
      <Route
        path={appRoutes.reports.route}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={lazyLoader(() => import('./pages/reports/ReportsPage'))}
          />
        }
      />
      <Route
        path={appRoutes.sfChangeFeed.route}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={lazyLoader(() => import('./pages/salesforce-logs/SalesforceLogsPage'))}
          />
        }
      />
      <Route
        path={appRoutes.rollups.route}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={lazyLoader(() => import('./pages/rollups/RollupsPage'))}
          />
        }
      />
      <Route
        path={`${appRoutes.rollups.route}/:crmOrgId`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={lazyLoader(() => import('./pages/rollups/RollupsPage'))}
          />
        }
      />
      <Route
        path={appRoutes.aiAssistant.route}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={lazyLoader(() => import('./pages/ai-assistant/AiAssistantPage'))}
          />
        }
      />

      {/* Demos */}
      <Route
        path={appRoutes.demoRuleBuilder.route}
        element={<RouteRenderer withDashboard={true} Comp={RuleBuilderDemoPage} />}
      />
      <Route
        path={appRoutes.demoComponents.route}
        element={
          <RouteRenderer withDashboard={true} Comp={lazyLoader(() => import('./pages/demos'))} />
        }
      />
      <Route path={appRoutes.version.route} element={<Version />} />
      <Route
        path={`${appRoutes.automations.route}/:crmOrgId`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={AppConfigurationCanvas}
            propsForComp={{
              page: ConfigurationPages.Automations,
              route: appRoutes.automations.route,
            }}
          />
        }
      />

      <Route
        path={`${appRoutes.automations.route}`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={ConfigurationCanvasRedirectToDefaultCreationEnv}
          />
        }
      />
      <Route
        path={`${appRoutes.canvasUnited.route}/:funnelMapId`}
        element={<RouteRenderer Comp={UnitedDashboard} withDashboard={false} />}
      />
      <Route
        path={`${appRoutes.canvasUnitedSnapshot.route}/:funnelId`}
        element={<RouteRenderer Comp={ReadOnlyUnitedDashboard} withDashboard={false} />}
      />
      <Route
        path={`${appRoutes.documentation.route}/:crmOrgId`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={AppConfigurationCanvas}
            propsForComp={{
              page: ConfigurationPages.Documentation,
              showOnlyDeployedElementsInPills: true,
              route: appRoutes.documentation.route,
            }}
          />
        }
      />

      <Route
        path={`${appRoutes.documentation.route}`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={ConfigurationCanvasRedirectToDefaultCreationEnv}
          />
        }
      />
      <Route
        path={`${appRoutes.routing.route}/:crmOrgId/${ROUTING_TAB}/:routingTab`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={AppConfigurationCanvas}
            propsForComp={{
              page: ConfigurationPages.Routing,
              route: appRoutes.routing.route,
            }}
          />
        }
      />
      <Route
        path={`${appRoutes.routing.route}/:crmOrgId`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={AppConfigurationCanvas}
            propsForComp={{
              page: ConfigurationPages.Routing,
              route: appRoutes.routing.route,
            }}
          />
        }
      />

      <Route
        path={`${appRoutes.routing.route}/${ROUTING_TAB}/:routingTab`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={ConfigurationCanvasRedirectToDefaultCreationEnv}
          />
        }
      />
      <Route
        path={`${appRoutes.routing.route}`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={ConfigurationCanvasRedirectToDefaultCreationEnv}
          />
        }
      />

      <Route
        path={`${appRoutes.alerts.route}/:crmOrgId`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={AppConfigurationCanvas}
            propsForComp={{
              page: ConfigurationPages.Alerts,
              route: appRoutes.alerts.route,
            }}
          />
        }
      />

      <Route
        path={`${appRoutes.alerts.route}`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={ConfigurationCanvasRedirectToDefaultCreationEnv}
          />
        }
      />
      <Route
        path={appRoutes.freeTierOnBoarding.route}
        element={
          <RouteRenderer
            withDashboard={false}
            Comp={lazyLoader(() => import('./pages/onboarding/SignupEntryPoint'))}
          />
        }
      />
      <Route
        path={appRoutes.questionnaire.route}
        element={
          <RouteRenderer
            withDashboard={false}
            Comp={lazyLoader(() => import('./pages/questionnaire/QuestionnaireEntryPoint'))}
          />
        }
      />
      <Route
        path={appRoutes.hubspot.route}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={AppConfigurationCanvas}
            propsForComp={{
              page: ConfigurationPages.Hubspot,
              route: appRoutes.hubspot.route,
              forceProduction: true,
            }}
          />
        }
      />

      <Route
        path={appRoutes.marketingAttribution.route}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={ConfigurationCanvasRedirectToDefaultCreationEnv}
          />
        }
      />
      <Route
        path={`${appRoutes.marketingAttribution.route}/:crmOrgId`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={AppConfigurationCanvas}
            propsForComp={{
              page: ConfigurationPages.MarketingAttribution,
              route: appRoutes.marketingAttribution.route,
            }}
          />
        }
      />

      <Route
        path={`${appRoutes.dedup.route}/:crmOrgId`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={AppConfigurationCanvas}
            propsForComp={{
              page: ConfigurationPages.DedupAndMatching,
              route: appRoutes.dedup.route,
            }}
          />
        }
      />
      <Route
        path={`${appRoutes.dedup.route}`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={ConfigurationCanvasRedirectToDefaultCreationEnv}
          />
        }
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Route>
  </Route>
);

export const router = createBrowserRouter(createRoutesFromElements(routes));
