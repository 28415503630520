import { AiChatMessage } from '../../../common/ai-chat/aiChatTypes';
import usePaywall from '../../../dashboard/paywall/usePaywall';
import { useUnhappyStates } from '../../../pages/environments/useUnhappyStates';

export const useConfigurationChatMessages = ({ messages }: { messages: AiChatMessage[] }) => {
  const { isPaywallActive } = usePaywall();
  const { noConnectedOrg, isInitialFetch } = useUnhappyStates();

  const renderNoOrgConnectedMessage = !isPaywallActive && noConnectedOrg;
  const renderInitialFetchMessage = !isPaywallActive && isInitialFetch;
  const renderChatPaywallBlockMessage = false;

  return {
    messagesToRender: messages,
    renderNoOrgConnectedMessage,
    renderChatPaywallBlockMessage,
    renderInitialFetchMessage,
  };
};
