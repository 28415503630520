import { useDispatch, useSelector } from 'react-redux';
import {
  removePlugin,
  setPlugin,
} from '../../../../../../reducers/united-canvas/unitedCanvasReducer';
import { PluginTypes } from '../../../../../../types/enums/PluginTypes';
import { BaseCriteriaPluginPanel } from './BaseCriteriaPluginPanel';
import { PluginPanelData } from '../../panel/BasePluginPanel';
import { MaybeClosePanelFunc } from '../../../../../panels/types';
import { useCallback } from 'react';
import { CriteriaPluginSection } from './types';
import { useSweepCanvas } from '../../../../../sweep-canvas/useSweepCanvas';
import {
  selectSfFunnelPlugin,
  selectFunnelsData,
} from '../../../../../../reducers/united-canvas/sfFunnelReducer';

export interface CriteriaPanelData extends PluginPanelData {
  crmOrgId: string;
  objectType: string;
}

type ConnectedCriteriaPanelProps = CriteriaPanelData & {
  maybeClosePanel: MaybeClosePanelFunc;
  setIsDirty: (isDirty: boolean) => void;
  pluginType: PluginTypes;
  pluginIcon: React.ReactNode;
  title: string;
  pluginStepMapping: CriteriaPluginSection[];
};

export type CustomCriteriaPanelProps = Omit<
  ConnectedCriteriaPanelProps,
  'title' | 'pluginIcon' | 'pluginStepMapping' | 'pluginType'
>;

function ConnectedBaseCriteriaPanel({
  funnelId,
  anchorEl,
  maybeClosePanel,
  setIsDirty,
  crmOrgId,
  objectType,
  pluginType,
  pluginIcon,
  title,
  pluginStepMapping,
}: ConnectedCriteriaPanelProps) {
  const defaultBantPlugin: CriteriaPlugin = {
    id: pluginType,
    criterionMap: [],
    fieldMapping: [],
  };
  const plugin = useSelector(selectSfFunnelPlugin(funnelId, pluginType)) as CriteriaPlugin;
  const { removePluginButton } = useSweepCanvas();
  const dispatch = useDispatch();

  const funnelsData = useSelector(selectFunnelsData);

  const regularStagesListWithExitCriteria =
    funnelsData?.[funnelId]?.funnelDetails.stages
      .filter((stage) => stage.exitCriteria.length > 0)
      .filter((stage) => stage.stageType === 'regular')
      .map((stage) => ({ id: stage._stageId, name: stage.stageName })) || [];

  const onRemove = useCallback(() => {
    maybeClosePanel({
      onCloseConfirm: () => {
        dispatch(removePlugin({ funnelId, pluginId: pluginType }));
        removePluginButton(funnelId, pluginType);
      },
    });
  }, [dispatch, funnelId, maybeClosePanel, pluginType, removePluginButton]);

  const onChange = useCallback(
    (plugin: CriteriaPlugin) => {
      dispatch(setPlugin({ funnelId, plugin }));
      maybeClosePanel({ forceClose: true });
    },
    [dispatch, funnelId, maybeClosePanel],
  );

  return (
    <BaseCriteriaPluginPanel
      anchorEl={anchorEl}
      maybeClosePanel={maybeClosePanel}
      onRemove={onRemove}
      pluginIcon={pluginIcon}
      title={title}
      plugin={plugin || defaultBantPlugin}
      onChange={onChange}
      pluginTemplateSections={pluginStepMapping}
      stepList={regularStagesListWithExitCriteria}
      crmOrgId={crmOrgId}
      objectType={objectType}
      setIsDirty={setIsDirty}
    />
  );
}

export { ConnectedBaseCriteriaPanel };
