import { useCallback, useState } from 'react';
import { InfoDialog } from '../common/dialogs/InfoDialog';
import { Box } from '@mui/material';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { Button, colors, Tag, Typography } from '@sweep-io/sweep-design';
import { DataTable } from '../common/table/DataTable';
import { DataTableSortableColumn, SortCompareTypes } from '../common/table/TableTypes';
import { AutomationVersionHistoryFilters } from './AutomationVersionHistoryFilters';
import isEqual from 'lodash/isEqual';
import { humanizeDate, humanizeDateVariants } from '../helpers/humanizeDate';
import { DeployStatusForTable } from '../../types/enums/DeployStatusForTable';
import { Edit } from '@sweep-io/sweep-design/dist/icons';
import { InfoTooltip } from '../common/InfoTooltip';
import { AutomationTemplate } from '../documentation/dependencies/latest-deployed/templates/AutomationTemplate';
import { ConfigurationTypesWithIcon } from '../documentation/dependencies/types';
import { useAutomationsContext } from './AutomationsContext';
import { SortOrder } from '../common/types';
import { useRunOnce } from '../common/useRunOnce';
import { useAutomationsApiFacade } from '../../apis/facades/useAutomationsApiFacade';
import { telemetry } from '../../telemetry';

export type AutomationVersionHistoryTableFilters = {
  status?: string[];
  deployedAtStartTime?: string;
  deployedAtEndTime?: string;
  deployedByName?: string[];
};
export interface AutomationVersionHistoryFilterOptions {
  status?: string[];
  deployedAtStartTime?: string;
  deployedAtEndTime?: string;
  deployedByName?: string[];
}

export interface AutomationVersionHistoryData extends AutomationStructureNew {
  deployedAt?: string;
  deployedById: string;
  deployedByName: string;
  isLive?: boolean;
}

interface DataTableRow {
  isRowActive: boolean;
  id: string;
  versionName: JSX.Element;
  deployedByName: string | JSX.Element;
  deployedAt: string;
  status: string;
}

enum ActiveStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export const AutomationVersionHistoryDialog = ({
  automation,
  closeDialog,
}: {
  automation: AutomationStructureNew;
  closeDialog: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [sortAndFilter, setSortAndFilter] = useState<AutomationVersionHistoryTableFilters>({});
  const [historyDataList, setHistoryDataList] = useState<AutomationVersionHistoryData[]>([]);
  const [originalVersionList, setOriginalVersionList] = useState<AutomationVersionHistoryData[]>(
    [],
  );
  const [activeRow, setActiveRow] = useState<string>(automation.automationId);
  const [sortState, setSortState] = useState<{ sortBy: string; sortOrder: SortOrder }>({
    sortBy: 'deployedAt',
    sortOrder: SortOrder.DESC,
  });
  const { setAutomationJson } = useAutomationsContext();

  const { get_automation_version_history } = useAutomationsApiFacade();

  useRunOnce(() => {
    const fetchData = async () => {
      try {
        const data = await get_automation_version_history(automation.automationId);
        setHistoryDataList(data);
        setOriginalVersionList(data);
      } catch (error) {
        telemetry.captureError(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  });

  const tableColumns: DataTableSortableColumn[] = [
    {
      field: 'versionName',
      headerName: 'Version',
      width: '110px',
      showSortIcon: true,
      sortCompare: {
        type: SortCompareTypes.Custom,
        compareFunction: (a: DataTableRow, b: DataTableRow, order: SortOrder) => {
          const aData = historyDataList.find((item) => item.automationId === a.id);
          const bData = historyDataList.find((item) => item.automationId === b.id);
          // make sure the draft is always first
          if (!aData?.versionName && !bData?.versionName) return 0;
          if (!aData?.versionName) return -1;
          if (!bData?.versionName) return 1;
          return order === SortOrder.ASC
            ? aData.versionName.localeCompare(bData.versionName)
            : bData.versionName.localeCompare(aData.versionName);
        },
      },
    },
    {
      field: 'deployedByName',
      headerName: 'Deployed by',
      width: '320px',
      showSortIcon: true,
      sortCompare: {
        type: SortCompareTypes.Custom,
        compareFunction: (a: DataTableRow, b: DataTableRow, order: SortOrder) => {
          const aData = historyDataList.find((item) => item.automationId === a.id);
          const bData = historyDataList.find((item) => item.automationId === b.id);
          if (!aData?.deployedByName && !bData?.deployedByName) return 0;
          if (!aData?.deployedByName) return -1;
          if (!bData?.deployedByName) return 1;
          return order === SortOrder.ASC
            ? aData.deployedByName.localeCompare(bData.deployedByName)
            : bData.deployedByName.localeCompare(aData.deployedByName);
        },
      },
    },
    {
      field: 'deployedAt',
      headerName: 'Deployed At',
      width: '160px',
      showSortIcon: true,
      sortCompare: {
        type: SortCompareTypes.Custom,
        compareFunction: (a: DataTableRow, b: DataTableRow, order: SortOrder) => {
          const aData = historyDataList.find((item) => item.automationId === a.id);
          const bData = historyDataList.find((item) => item.automationId === b.id);
          if (!aData?.deployedAt && !bData?.deployedAt) return 0;
          if (!aData?.deployedAt) return -1;
          if (!bData?.deployedAt) return 1;
          const aDate = new Date(aData.deployedAt).getTime();
          const bDate = new Date(bData.deployedAt).getTime();
          return order === SortOrder.ASC ? aDate - bDate : bDate - aDate;
        },
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: '120px',
    },
  ];

  const getRows = useCallback(() => {
    return (
      historyDataList?.map((data) => {
        const isDraft = data.status === DeployStatusForTable.Draft;
        const isSFplatform = data?.deployedByName === 'Salesforce Platform';
        return {
          isRowActive: data.automationId === activeRow,
          id: data.automationId,
          versionName: (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="body-medium">{data.versionName}</Typography>
                {data.isLive && (
                  <Tag label="Live" size="medium" textTheme="light" color={colors.mint[700]} />
                )}
              </Box>
              {isDraft && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5,
                    backgroundColor: colors.white,
                    justifyContent: 'center',
                    border: `1px solid ${colors.grey[900]}`,
                    width: '66px',
                    height: '24px',
                    borderRadius: '2px',
                  }}
                >
                  <Typography variant="body-medium">Draft</Typography>
                  <Edit variant="tiny" />
                </Box>
              )}
            </>
          ),
          deployedByName: isDraft ? (
            `Last edited by ${data.deployedByName} ${humanizeDate({
              dateOrTimestamp: data.updatedAt ?? '',
              variant: humanizeDateVariants.TWO_DIGITS_WITH_TIME,
            })}`
          ) : (
            <>
              {data.deployedByName}
              {isSFplatform && (
                <InfoTooltip
                  title={
                    'This version was not deployed to this org by Sweep. It may have been added via Change Sets or copied from another org'
                  }
                />
              )}
            </>
          ),
          deployedAt:
            isDraft || isSFplatform
              ? '-'
              : humanizeDate({
                  dateOrTimestamp: data.deployedAt ?? '',
                  variant: humanizeDateVariants.TWO_DIGITS_WITH_TIME,
                }),

          status: data.isActive ? ActiveStatus.ACTIVE : ActiveStatus.INACTIVE,
        };
      }) ?? []
    );
  }, [historyDataList, activeRow]);

  const filterOptions: AutomationVersionHistoryFilterOptions = {
    status: [ActiveStatus.ACTIVE, ActiveStatus.INACTIVE],
    deployedByName: [
      ...new Set(originalVersionList.map((item) => item.deployedByName).filter(Boolean)),
    ] as string[],
  };

  const handleFilterChange = useCallback(
    async (newFilter: Partial<AutomationVersionHistoryTableFilters>) => {
      if (!isEqual(newFilter, sortAndFilter)) {
        const newObj = { ...sortAndFilter, ...newFilter };
        setSortAndFilter(newObj);
        // Apply filters to the data
        const newData = originalVersionList.filter((item) => {
          if (newObj.status?.length) {
            const isActive = item.isActive;
            const statusMatch = newObj.status.includes(
              isActive ? ActiveStatus.ACTIVE : ActiveStatus.INACTIVE,
            );
            if (!statusMatch) return false;
          }

          if (newObj.deployedByName?.length && item.deployedByName) {
            if (!newObj.deployedByName.includes(item.deployedByName)) return false;
          }

          if (newObj.deployedAtStartTime && newObj.deployedAtEndTime) {
            const deployedAt = new Date(item?.deployedAt ?? '');
            const startTime = new Date(newObj.deployedAtStartTime);
            const endTime = new Date(newObj.deployedAtEndTime);
            if (deployedAt < startTime || deployedAt > endTime) return false;
          }

          return true;
        });

        setHistoryDataList(newData);

        // Handle activeRow selection after filtering
        if (newData.length === 0) {
          setActiveRow('');
        } else if (!newData.some((item) => item.automationId === activeRow)) {
          setActiveRow(newData[0].automationId);
        }
      }
    },
    [sortAndFilter, activeRow, originalVersionList],
  );

  const onRowClick = useCallback(
    (_: React.MouseEvent<HTMLTableCellElement, MouseEvent>, row: DataTableRow) => {
      setActiveRow(row.id);
    },
    [],
  );

  const handleSetAsDraft = useCallback(() => {
    let currentAutomation = historyDataList?.find(
      (item) => item.automationId === activeRow,
    ) as AutomationStructureNew;
    if (currentAutomation?.status === DeployStatusForTable.Deployed) {
      currentAutomation = {
        ...automation,
        automationDetails: currentAutomation?.automationDetails as AutomationDetailsDtoNew,
        funnelRecordType: currentAutomation?.funnelRecordType,
      };
    }
    if (currentAutomation) {
      setAutomationJson(currentAutomation);
      closeDialog();
    }
  }, [historyDataList, activeRow, closeDialog, setAutomationJson, automation]);

  const handleSortChange = useCallback((newSortState: { sortBy: string; sortOrder: SortOrder }) => {
    setSortState(newSortState);
  }, []);

  return (
    <InfoDialog
      handleClose={(e) => {
        e.stopPropagation();
        closeDialog();
      }}
      open={true}
      PaperPropsSx={{ width: '1480px' }}
      showCloseButton
      titleJsx={`'${automation.name}' - Version history`}
      titleTypographyVariant="h3"
      dialogContentSx={{ borderTop: `1px solid ${colors.grey[200]}`, p: 0 }}
    >
      <Box>
        {isLoading && (
          <Box m={2}>
            <CenteredCircularProgress />
          </Box>
        )}
        {!isLoading && (
          <Box sx={{ display: 'flex' }}>
            <Box
              sx={{
                width: '1024px',
                p: '16px 24px 24px',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <AutomationVersionHistoryFilters
                setFilter={handleFilterChange}
                sortAndFilter={sortAndFilter}
                disableFilters={false}
                filterOptions={filterOptions}
              />
              <Box sx={{ maxHeight: '750px' }}>
                <DataTable
                  stickyHeader={true}
                  columns={tableColumns}
                  rows={getRows()}
                  isLoading={isLoading}
                  onRowClick={onRowClick}
                  defaultSortState={sortState}
                  onSortChange={handleSortChange}
                  tableEmptyStateJsx={
                    <Box sx={{ mt: 3, textAlign: 'center', mb: 2 }}>
                      <Typography variant="caption" color={colors.grey[800]}>
                        No versions found
                      </Typography>
                    </Box>
                  }
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: '456px',
                height: '830px',
                backgroundColor: colors.grey[100],
                borderLeft: `1px solid ${colors.grey[300]}`,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                p: '16px 24px 24px',
              }}
            >
              <Typography variant="body" color={colors.grey[700]}>
                {activeRow && originalVersionList.length > 0 ? (
                  <>
                    Showing{' '}
                    <Typography variant="body-medium" color={colors.grey[900]}>
                      "
                      {historyDataList?.find((item) => item.automationId === activeRow)
                        ?.versionName ?? 'Draft'}
                      "
                    </Typography>
                  </>
                ) : (
                  'Version details will be displayed here'
                )}
              </Typography>
              <Box
                sx={{
                  height: '694px',
                  backgroundColor: colors.white,
                  borderRadius: '8px',
                  border: `1px solid ${colors.grey[300]}`,
                  p: 2,
                  overflow: 'auto',
                }}
              >
                <AutomationTemplate
                  isRemoveName={true}
                  elementType={'automations' as ConfigurationTypesWithIcon}
                  readOnlyVersion={
                    historyDataList?.find((item) => item.automationId === activeRow)?.readVersion
                  }
                />
              </Box>
              <Box>
                <Button
                  onClick={handleSetAsDraft}
                  startIconName=""
                  variant="outlined"
                  disabled={!activeRow}
                >
                  {historyDataList?.find((item) => item.automationId === activeRow)?.status ===
                  DeployStatusForTable.Draft
                    ? 'Edit draft'
                    : 'Restore as draft'}
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </InfoDialog>
  );
};
