import { TableContainer, Box } from '@mui/material';
import { StyledTable, StyledTableBody, StyledTableHead } from './StyledTableComponents';
import { useState, useCallback, useMemo, useRef, RefObject } from 'react';
import {
  DataTableBaseColumn,
  DataTableDraggableProps,
  DataTableRow,
  DataTableSortableColumn,
  DataTableSortableProps,
  DataTableVariant,
  SortCompareTypes,
} from './TableTypes';
import { keyBy } from 'lodash';
import { SortOrder } from '../types';
import { TableHeadRow } from './TableHeadRow';
import { TableLoader } from './TableLoader';
import { TableBodyRows } from './TableBodyRows';
import { TableEmptyStateComponentWrapper } from './TableEmptyStateComponentWrapper';
import { DraggableTableBodyRows } from './DraggableTableBodyRows';
import { colors } from '@sweep-io/sweep-design';

/** Current limitation: "renderRow" is applicable only for non-draggable tables (when allowReorder is false) **/

export function DataTable<TRow extends DataTableRow = any>({
  rows: allRows,
  columns: allColumns,
  sx,
  isLoading,
  tableMaxHeight,
  tableEmptyStateJsx,
  TableFooter,
  variant = DataTableVariant.medium,
  containerRef,
  stickyHeader,
  onRowClick,
  ...dataTableProps
}: DataTableDraggableProps<TRow> | DataTableSortableProps<TRow>) {
  const isDraggable = 'onOrderChange' in dataTableProps;

  const columnsByField = keyBy(allColumns, 'field');
  const [sortState, setSortState] = useState(
    'defaultSortState' in dataTableProps ? dataTableProps.defaultSortState : undefined,
  );

  const tableRef: RefObject<HTMLDivElement> = useRef(null);
  const columns = allColumns.filter((column) => !column.hidden);
  const noActionColumns: DataTableSortableColumn<TRow>[] | DataTableBaseColumn[] = columns.filter(
    (column) => column.field !== '__actions',
  );

  const rows = useMemo(() => {
    if (!sortState) {
      return allRows;
    }
    const columnToSortBy = columnsByField[sortState.sortBy];
    if (!columnToSortBy) {
      return allRows;
    }
    if (typeof columnToSortBy === 'object' && 'sortCompare' in columnToSortBy) {
      const sortCompare = columnToSortBy.sortCompare;
      const orderModifier = sortState.sortOrder === SortOrder.ASC ? 1 : -1;
      switch (sortCompare?.type) {
        case SortCompareTypes.String:
          return allRows.sort((a, b) => {
            const aVal = a[sortState.sortBy];
            const bVal = b[sortState.sortBy];
            return orderModifier * aVal.localeCompare(bVal);
          });

        case SortCompareTypes.Number:
          return allRows.sort((a, b) => {
            const aVal = a[sortState.sortBy];
            const bVal = b[sortState.sortBy];
            return orderModifier * (aVal - bVal);
          });

        case SortCompareTypes.Custom:
          if (sortCompare.compareFunction) {
            return allRows.sort((a, b) => {
              const aRow = a;
              const bRow = b;
              return sortCompare.compareFunction(aRow, bRow, sortState.sortOrder);
            });
          }
          break;

        default:
          break;
      }
    }
    return allRows;
  }, [allRows, columnsByField, sortState]);

  const sort = useCallback(
    (field: string) => {
      setSortState((prevState) => {
        const order = prevState?.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
        if ('onSortChange' in dataTableProps) {
          dataTableProps.onSortChange?.({
            sortBy: field,
            sortOrder: order,
          });
        }
        return {
          sortBy: field,
          sortOrder: order,
        };
      });
    },
    [dataTableProps],
  );

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        border: `1px solid ${colors.grey[120]}`,
        borderRadius: '8px',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          maxHeight: tableMaxHeight ?? '100%',
          height: '100%',
          width: '100%',
          overflow: isLoading ? 'hidden' : 'auto',
          position: 'relative',
        }}
        data-testid="data-table"
        className="data-table-container"
        ref={containerRef}
      >
        <TableContainer ref={tableRef} sx={{ ...sx, overflow: 'unset', height: '100%' }}>
          <StyledTable>
            <StyledTableHead data-testid="data-table-head">
              <TableHeadRow
                stickyHeader={stickyHeader}
                columns={noActionColumns}
                sort={sort}
                sortState={sortState}
                variant={variant}
              />
            </StyledTableHead>
            <StyledTableBody
              sx={{
                visibility: isLoading ? 'hidden' : 'visible',
                position: 'relative',
              }}
            >
              {!isDraggable && rows.length > 0 && (
                <TableBodyRows
                  rows={rows}
                  columns={noActionColumns}
                  containerRef={containerRef}
                  variant={variant}
                  onRowClick={onRowClick}
                  {...dataTableProps}
                />
              )}
              {isDraggable && rows.length > 0 && (
                <DraggableTableBodyRows
                  rows={rows}
                  columns={noActionColumns}
                  variant={variant}
                  {...dataTableProps}
                />
              )}
              {!rows.length && (
                <TableEmptyStateComponentWrapper
                  TableEmptyStateJsx={tableEmptyStateJsx}
                  columnsCount={noActionColumns.length}
                />
              )}
            </StyledTableBody>
            {TableFooter}
          </StyledTable>
        </TableContainer>

        {isLoading && <TableLoader topMargin={'32px'} />}
      </Box>
    </Box>
  );
}
