import { Box, Stack } from '@mui/material';
import { Typography, colors, Tooltip } from '@sweep-io/sweep-design';
import AiAgentListItemLayout from './AiAgentListItemLayout';
import { styled } from '@mui/material';
import ThreeAvatars from './svg/threeAvatars.svg?react';
import CollapsibleSectionLayout from './CollapsibleSectionLayout';
import useAiAgentChats from './useAiAgentChats';
import { AI_AGENTS_INFO } from './aiAgentsConsts';
import useAiAgents from './useAiAgents';
import { NotePencil } from '@sweep-io/sweep-design/dist/icons';
import { ClampedText } from '../common/ClampedText';
import { AgentType } from '@server/ai';
import { useFeatureToggle } from '../common/useFeatureToggle';

const FlexBoxItem = styled(Box)({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
});

const AiAgentsList = () => {
  const { setActiveChatId, startChatWithAgent, activeIsEmptyChat, activeChat } = useAiAgentChats();
  const { agents, activeAgent } = useAiAgents();
  const { documentationAiAgent } = useFeatureToggle();

  const agentsToRender = documentationAiAgent
    ? agents
    : agents?.filter((agent) => agent.type !== AgentType.Documentation);

  return (
    <Stack gap={3}>
      <AiAgentListItemLayout
        isActive={!activeChat}
        onClick={() => {
          setActiveChatId(undefined);
        }}
      >
        <FlexBoxItem gap="14px">
          <ThreeAvatars />
          <Typography variant="body">All Agents</Typography>
        </FlexBoxItem>
      </AiAgentListItemLayout>
      {agentsToRender && agentsToRender.length > 0 && (
        <CollapsibleSectionLayout title="Agents">
          {agentsToRender?.map((agent) => {
            const { agentIcon } = AI_AGENTS_INFO[agent.type];
            return (
              <AiAgentListItemLayout
                key={agent.id}
                isActive={activeAgent?.id === agent.id && activeIsEmptyChat}
                onClick={() => {
                  startChatWithAgent({
                    agent,
                    biEventProps: {
                      origin: 'agent_list_item',
                      mode: 'expanded',
                    },
                  });
                }}
              >
                <Tooltip title={`Start a new chat with ${agent.name}`} placement={'right'}>
                  <FlexBoxItem
                    justifyContent="space-between"
                    gap={1}
                    sx={{
                      '&:hover .pencil-icon': {
                        opacity: 1,
                      },
                    }}
                  >
                    <FlexBoxItem gap="14px">
                      <Stack flex="0 0 12px">{agentIcon}</Stack>
                      <ClampedText
                        text={agent.name}
                        typographyVariant="body"
                        disableHoverListener={true}
                      />
                    </FlexBoxItem>
                    <Stack className="pencil-icon" sx={{ opacity: 0 }}>
                      <NotePencil color={colors.grey[800]} />
                    </Stack>
                  </FlexBoxItem>
                </Tooltip>
              </AiAgentListItemLayout>
            );
          })}
        </CollapsibleSectionLayout>
      )}
    </Stack>
  );
};

export default AiAgentsList;
