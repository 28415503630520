import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStageMetadata } from '../../../../hooks/useStageMetadata';
import { useUnsavedPrompt } from '../../../../hooks/useUnsavedPrompt';
import { clearFunnelMap } from '../../../../reducers/united-canvas/unitedCanvasReducer';
import {
  setIsPageLoading,
  setHasFetchingErrors,
  selectHasUnsavedChanges,
} from '../../../../reducers/multiFunnelFlowNoHistoryReducer';
import { useRunOnceWhenTruthy } from '../../../common/useRunOnceWhenTruthy';
import { usePanels } from '../../../panels/usePanels';
import { useFunnelMapFlowPageApi } from '../../funnel-map-flow/useFunnelMapFlowPageApi';
import { usePageHelpers } from './usePageHelpers';
import { useRunOnce } from '../../../common/useRunOnce';
import { ActionCreators as UndoActions } from 'redux-undo';
import { selectDefaultCreationCrmOrgId } from '../../../../reducers/userInfoReducer';
import { useFetchGlobal } from '../../../../hooks/global-reducer/useFetchGlobal';
import { telemetry } from '../../../../telemetry';

export const useLoadEnv = () => {
  const { fetchGlobal } = useFetchGlobal();
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  const { getAllStagesMetadata } = useStageMetadata();
  const { getOpportunityStageMetadataOptions } = useStageMetadata();

  const lastCrmOrgId = useRef<string>();

  useEffect(() => {
    if (crmOrgId && lastCrmOrgId.current !== crmOrgId) {
      fetchGlobal({ crmOrgId });
      getOpportunityStageMetadataOptions(crmOrgId);

      //Stage metadata provided in funnels contains partial info per leadingFieldId,
      //On mount we need information for all the objects in the map
      getAllStagesMetadata(crmOrgId);

      lastCrmOrgId.current = crmOrgId;
    }
  }, [crmOrgId, fetchGlobal, getAllStagesMetadata, getOpportunityStageMetadataOptions]);
};

export const useInitializeFunnelMapPage = () => {
  const { funnelMapId, isLoading, closeDialogs } = usePageHelpers();

  const dispatch = useDispatch();
  const { loadFunnelMap } = useFunnelMapFlowPageApi();

  const { isCurrentPanelDirty } = usePanels();

  useRunOnce(() => dispatch(setIsPageLoading({ isPageLoading: true })));

  useRunOnceWhenTruthy(
    async () => {
      //This check is just for typescript, the RunOnce effect shouldn't fire if the condition (second argument) is not met
      if (!funnelMapId) return;
      try {
        await loadFunnelMap(funnelMapId);
        dispatch(setIsPageLoading({ isPageLoading: false }));
      } catch (error) {
        telemetry.captureError(error);
        dispatch(setHasFetchingErrors({ hasErrors: true }));
      }
    },
    Boolean(funnelMapId && isLoading),
  );

  const hasUnsavedChanges = useSelector(selectHasUnsavedChanges);

  // To handle navigation
  useUnsavedPrompt(hasUnsavedChanges || isCurrentPanelDirty);

  //clear on component unmount
  useEffect(() => {
    return () => {
      dispatch(clearFunnelMap());
      dispatch(UndoActions.clearHistory());
      closeDialogs();
    };
  }, [dispatch, closeDialogs]);
};
