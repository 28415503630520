import { memo } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { NodeProps, useStore } from '@xyflow/react';
import { RfNodeGroupOverlay } from '../canvas-types/nodeTypesData';
import { colors } from '@sweep-io/sweep-design';
import { NodeEntityTypes } from '../canvas-types';

const StyledGroupNode = styled(Box)({
  position: 'relative',
  borderRadius: 8,
});

const GroupOverlay = ({
  id,
  data: { boundingBox, onClick, groupId },
}: NodeProps<RfNodeGroupOverlay>) => {
  const { height, marginLeft, marginTop, width } = boundingBox;
  const zoomLevel = useStore((store) => store.transform[2]);
  const borderSize = 2 / zoomLevel;

  return (
    <StyledGroupNode
      id={id}
      onClick={(event) => {
        onClick?.({ event, entity: { type: NodeEntityTypes.GroupOverlay }, id: groupId });
      }}
      className="sweep-group-node-overlay"
      sx={{
        backgroundColor: 'transparent',
        height: height + 'px',
        marginLeft: marginLeft + 'px',
        marginTop: marginTop + 'px',
        width: width + 'px',
        '&:hover': {
          border: `${borderSize}px solid`,
          borderColor: colors.blue[500],
        },
      }}
    />
  );
};

const _memoGroupOverlay = memo(GroupOverlay);

export { _memoGroupOverlay as GroupOverlay };
