import { Popover } from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';
import { CollapseList, ListGroup, OnCollapsedListItemClick } from '../../../../common/CollapseList';
import { useFeatureToggle } from '../../../../common/useFeatureToggle';

export interface ConnectStepProps {
  anchorEl: HTMLButtonElement | null;
  handleClose: () => any;
  stepGroups: ListGroup[];
  onItemClick: OnCollapsedListItemClick;
  startAllExpanded?: boolean;
}

export const ConnectStep = ({
  anchorEl,
  handleClose,
  stepGroups,
  onItemClick,
  startAllExpanded,
}: ConnectStepProps) => {
  const { thirdPartyFunnelConnections } = useFeatureToggle();
  const isOpen = Boolean(anchorEl);
  const allItems = Object.values(stepGroups).filter((v) => v.items.length || v.selectable);

  const showSearchField = allItems.length >= 7;

  if (allItems.length === 0) {
    return (
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            marginLeft: '10px',
            marginTop: '-2px',
            background: colors.grey[900],
            padding: '8px',
            borderRadius: '4px',
            color: colors.white,
          },
        }}
      >
        <Typography variant="caption">
          No available destination steps. Steps can only be connected to a later step
        </Typography>
      </Popover>
    );
  }

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        sx: {
          maxWidth: 447,
          overflow: 'scroll',
          maxHeight: 800,
          pb: 1,

          '& .collapse-list': {
            p: '0 8px',
          },
        },
      }}
    >
      <CollapseList
        isOpen={isOpen}
        groups={stepGroups}
        showSearchField={showSearchField}
        onItemClick={onItemClick}
        openAllExpanded={startAllExpanded}
        componentHeaderText="Choose destination step"
        openFirstExpanded
        disableVirtuoso
        variant={thirdPartyFunnelConnections ? 'start' : 'end'}
        groupSelectButtonLabel="Connect"
        showSelectButtons={thirdPartyFunnelConnections}
      />
    </Popover>
  );
};
