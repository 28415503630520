import { IconButton, SxProps } from '@mui/material';
import { Close } from '@sweep-io/sweep-design/dist/icons';

export const CloseIconButton = ({ sx, handleClose }: { sx?: SxProps; handleClose: any }) => {
  return (
    <IconButton sx={sx} onClick={handleClose}>
      <Close variant="tiny" />
    </IconButton>
  );
};
