import { Box, Divider } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { useSelector } from 'react-redux';
import { selectConnectedCrmOrgs } from '../environmentsReducer';
import { FetchStatus } from '@server/fetch_status.type';
import { EnvironmentTypes } from '../EnvironmentTypeEnum';
import { EnvironmentCard } from './EnvironmentCard';
import { ConnectEnvButton } from './ConnectEnvButton';
import { Fragment } from 'react';

const sectionTitleSubtitle = {
  [EnvironmentTypes.Production]: {
    title: 'Production',
    subtitle: (
      <>
        You can only have one Salesforce Production environment connected to Sweep
        <br /> at the same time.
      </>
    ),
  },
  [EnvironmentTypes.Sandbox]: {
    title: 'Sandbox',
    subtitle: (
      <>
        You can have multiple sandboxes in your organization for different purposes and
        <br /> team members.
      </>
    ),
  },
};

const ManageEnvSection = ({
  children,
  desiredEnvType,
  showSubtitle,
  hideConnectButton,
}: {
  desiredEnvType: EnvironmentTypes.Production | EnvironmentTypes.Sandbox;
  children: React.ReactNode;
  showSubtitle?: boolean;
  hideConnectButton?: boolean;
}) => {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h4">{sectionTitleSubtitle[desiredEnvType].title}</Typography>
          {!hideConnectButton && <ConnectEnvButton desiredEnvType={desiredEnvType} />}
        </Box>
        {showSubtitle && (
          <Box borderBottom={`1px solid ${colors.grey['300']}`} pb={2}>
            <Typography variant="caption" color={colors.grey[700]}>
              {sectionTitleSubtitle[desiredEnvType].subtitle}
            </Typography>
          </Box>
        )}
        <Box pb={0.5}>{children}</Box>
      </Box>
    </>
  );
};

export const ManageEnv = ({ allowDeleteLastEnv }: { allowDeleteLastEnv: boolean }) => {
  const environments = useSelector(selectConnectedCrmOrgs);
  const [prodEnvs, sandboxEnvs] = environments
    .filter((env) => env.isConnected)
    .reduce(
      (acc, curr) => {
        curr.isMain ? acc[0].push(curr) : acc[1].push(curr);
        return acc;
      },
      [[] as CrmOrg[], [] as CrmOrg[]],
    );
  const prodEnv = prodEnvs.length ? prodEnvs[0] : undefined;
  sandboxEnvs.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
  const allowDelete = allowDeleteLastEnv || prodEnvs.length + sandboxEnvs.length > 1;

  return (
    <Box sx={{ width: '600px', display: 'flex', flexDirection: 'column', gap: 2 }}>
      <ErrorCard envs={environments} />
      <ManageEnvSection
        desiredEnvType={EnvironmentTypes.Production}
        showSubtitle={!prodEnv}
        hideConnectButton={Boolean(prodEnv)}
      >
        {prodEnv && (
          <>
            <EnvironmentCard env={prodEnv} allowDelete={allowDelete} />
            <Divider />
          </>
        )}
      </ManageEnvSection>
      <ManageEnvSection
        desiredEnvType={EnvironmentTypes.Sandbox}
        showSubtitle={sandboxEnvs.length === 0}
      >
        {sandboxEnvs.map((env) => (
          <Fragment key={env.id}>
            <EnvironmentCard env={env} allowDelete={allowDelete} />
            <Divider />
          </Fragment>
        ))}
      </ManageEnvSection>
    </Box>
  );
};

const ErrorCard = ({ envs }: { envs: CrmOrg[] }) => {
  const envsWithInvalidCredentials = envs.filter(
    (env) => env.fetchStatus === FetchStatus.InvalidCredentials,
  );

  if (envsWithInvalidCredentials.length === 0) {
    return null;
  }

  const invalidEnvsNames = envsWithInvalidCredentials.map((env) => env.name);
  const asMoreThanOneEnv = invalidEnvsNames.length > 1;
  const envsText = asMoreThanOneEnv ? 'environments' : 'environment';
  const invalidEnvsText = invalidEnvsNames.join(', ');

  return (
    <Box mt={3}>
      <Box
        sx={{
          background: colors.grey[120],
          padding: '12px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="caption">
          We are having issues connecting to your {envsText} {invalidEnvsText}.
        </Typography>
        <Typography variant="caption">
          Reconnect, or create a new environment and set it as default
        </Typography>
      </Box>
    </Box>
  );
};
