import { useCallback } from 'react';
import { FunnelType } from '../../../../types/enums/FunnelType';
import { useFunnelsApiFacade } from '../../../../apis/facades/useFunnelsApiFacade';
import { telemetry } from '../../../../telemetry';
import { EnrichedFunnelLink, EnrichedSfFunnelLink, EnrichedThirdPartyFunnelLink } from './types';
import { useSelector } from 'react-redux';
import { selectThirdPartyFunnels } from '../../../third-party-funnels/thirdPartyFunnelsReducer';

const extractAllFunnelLinksFromFunnel = (funnel: Funnel) => {
  return [
    ...(funnel.funnelDetails.funnelLinks || []),
    ...funnel.funnelDetails.stages.map((stage) => stage.funnelLinks || []).flat(),
  ];
};

const extractAllFunnelLinksFromThirdPartyFunnelMap = (
  thirdPartyFunnelData: ThirdPartyFunnelData,
) => {
  return [
    ...(thirdPartyFunnelData.funnelLinks || []),
    ...thirdPartyFunnelData.steps.map((step) => step.funnelLinks || []).flat(),
  ];
};

const extractFunnelLinksFromFunnelMap = (funnelMap: FunnelMap) => {
  const sfFunnelLinks = Object.values(funnelMap.funnelsData)
    .map(extractAllFunnelLinksFromFunnel)
    .flat();
  const thirdPartyFunnelLinks = Object.values(funnelMap.thirdPartyFunnelsData)
    .map(extractAllFunnelLinksFromThirdPartyFunnelMap)
    .flat();

  return [...sfFunnelLinks, ...thirdPartyFunnelLinks];
};

const possiblyEnrichSfFunnelLink = (
  funnelLink: FunnelLink,
  funnelMap: FunnelMap,
): EnrichedSfFunnelLink | null => {
  const sfFunnel = funnelMap.funnelsData[funnelLink.funnelId];
  if (!sfFunnel) {
    return null;
  }

  const stage = funnelLink.stageId
    ? sfFunnel.funnelDetails.stages.find((stage) => stage._stageId === funnelLink.stageId)
    : undefined;

  if (funnelLink.stageId && !stage) {
    return null;
  }

  return {
    ...funnelLink,
    stageName: stage?.stageName,
    funnelName: sfFunnel.name,
    _type: FunnelType.SALESFORCE,
    objectName: sfFunnel.funnelDetails.leadingObject.objectName,
  };
};

const possiblyEnrichThirdPartyFunnelLink = (
  funnelLink: FunnelLink,
  funnelMap: FunnelMap,
): EnrichedThirdPartyFunnelLink | null => {
  const thirdPartyFunnel = funnelMap.thirdPartyFunnelsData[funnelLink.funnelId];
  if (!thirdPartyFunnel) {
    return null;
  }

  const stage = funnelLink.stageId
    ? thirdPartyFunnel.steps.find((step) => step.id === funnelLink.stageId)
    : undefined;

  if (funnelLink.stageId && !stage) {
    return null;
  }

  const enrichedFunnelLink: EnrichedThirdPartyFunnelLink = {
    ...funnelLink,
    funnelName: thirdPartyFunnel.name,
    stageName: stage?.name,
    _type: FunnelType.THIRD_PARTY,
    system: thirdPartyFunnel.system,
  };
  return enrichedFunnelLink;
};

export const getPossibleEnrichedFunnelLinksFromFunnelMap = ({
  funnelLinkIdsToFilter,
  funnelMap,
}: {
  funnelLinkIdsToFilter?: string[];
  funnelMap: FunnelMap;
}) => {
  const enrichedFunnelLinks: EnrichedFunnelLink[] = [];
  const notFoundFunnelLinksIds: FunnelLink[] = [];

  let funnelLinks = extractFunnelLinksFromFunnelMap(funnelMap);
  if (funnelLinkIdsToFilter) {
    funnelLinks = funnelLinks.filter((funnelLink) =>
      funnelLinkIdsToFilter.includes(funnelLink._id),
    );
  }

  for (const funnelLink of funnelLinks) {
    if (!funnelLink._type || funnelLink._type === FunnelType.SALESFORCE) {
      const enrichedFunnelLink = possiblyEnrichSfFunnelLink(funnelLink, funnelMap);
      if (enrichedFunnelLink) {
        enrichedFunnelLinks.push(enrichedFunnelLink);
      } else {
        notFoundFunnelLinksIds.push(funnelLink);
      }
    }
    if (funnelLink._type === FunnelType.THIRD_PARTY) {
      const enrichedFunnelLink = possiblyEnrichThirdPartyFunnelLink(funnelLink, funnelMap);
      if (enrichedFunnelLink) {
        enrichedFunnelLinks.push(enrichedFunnelLink);
      } else {
        notFoundFunnelLinksIds.push(funnelLink);
      }
    }
  }
  return { enrichedFunnelLinks, notFoundFunnelLinksIds };
};

export const useGetEnrichedFunnelLinks = () => {
  const { get_funnel } = useFunnelsApiFacade();
  const thirdPartyFunnels = useSelector(selectThirdPartyFunnels);

  const getEnrichedFunnelLinks = useCallback(
    async (funnelLinks: FunnelLink[], funnelMap: FunnelMap) => {
      const { enrichedFunnelLinks, notFoundFunnelLinksIds: notFoundFunnelLinksIdsInFunnelMap } =
        getPossibleEnrichedFunnelLinksFromFunnelMap({
          funnelMap,
          funnelLinkIdsToFilter: funnelLinks.map((funnelLink) => funnelLink._id),
        });

      const allThirdPartyFunnelsLinks = notFoundFunnelLinksIdsInFunnelMap.filter(
        (funnelLink) => funnelLink._type === FunnelType.THIRD_PARTY,
      );
      const allSfFunnelsLinks = notFoundFunnelLinksIdsInFunnelMap.filter(
        (funnelLink) => !funnelLink._type || funnelLink._type === FunnelType.SALESFORCE,
      );

      const notFoundFunnelLinksIds: FunnelLink[] = [];

      const getSfEnrichedFunnelLinkFromServer = async (
        funnelLink: FunnelLink,
      ): Promise<EnrichedSfFunnelLink | null> => {
        let funnel: Funnel;
        try {
          funnel = await get_funnel(funnelLink.funnelId);
        } catch (error) {
          telemetry.captureError('Error getting SF funnel', error);
          notFoundFunnelLinksIds.push(funnelLink);
          return null;
        }

        const stage = funnelLink.stageId
          ? funnel.funnelDetails.stages.find((stage) => stage._stageId === funnelLink.stageId)
          : undefined;

        return {
          ...funnelLink,
          stageName: stage?.stageName,
          funnelName: funnel.name,
          _type: FunnelType.SALESFORCE,
          objectName: funnel.funnelDetails.leadingObject.objectName,
        };
      };

      const getThirdPartyEnrichedFunnelLinks = async (funnelLinks: FunnelLink[]) => {
        return funnelLinks.map((funnelLink) => {
          const thirdPartyFunnel = thirdPartyFunnels.find(
            (thirdPartyFunnel) => thirdPartyFunnel.id === funnelLink.funnelId,
          );
          if (!thirdPartyFunnel) {
            notFoundFunnelLinksIds.push(funnelLink);
            return null;
          }

          const stageName = funnelLink.stageId
            ? thirdPartyFunnel?.steps.find((step) => step.id === funnelLink.stageId)?.name
            : undefined;

          const enrichedFunnelLink: EnrichedThirdPartyFunnelLink = {
            ...funnelLink,
            stageName,
            funnelName: thirdPartyFunnel.name,
            _type: FunnelType.THIRD_PARTY,
            system: thirdPartyFunnel.system,
          };
          return enrichedFunnelLink;
        });
      };

      const ret: EnrichedFunnelLink[] = (
        await Promise.all([
          ...allSfFunnelsLinks.map(getSfEnrichedFunnelLinkFromServer),
          getThirdPartyEnrichedFunnelLinks(allThirdPartyFunnelsLinks),
        ])
      )
        .flat()
        .filter(Boolean) as EnrichedFunnelLink[];

      const _notFoundFunnelLinksIds = [
        ...notFoundFunnelLinksIds,
        ...notFoundFunnelLinksIdsInFunnelMap,
      ];
      return {
        enrichedFunnelLinks: [...ret, ...enrichedFunnelLinks],
        notFoundFunnelLinksIds:
          _notFoundFunnelLinksIds.length > 0 ? _notFoundFunnelLinksIds : undefined,
      };
    },
    [get_funnel, thirdPartyFunnels],
  );

  return getEnrichedFunnelLinks;
};
