import { useMemo } from 'react';
import { SearchSelect, SearchSelectItem } from '../search-select/SearchSelect';
import { ObjectTypeLabelAndKeyTag } from '../ObjectTypeLabelAndKey';
import { highlightMatch } from '../../../lib/highlightMatch';
import useObjectTypesWithFetch, {
  ObjectTypePropertyFilterType,
  objectTypesPropertyFilter,
} from '../../../hooks/useObjectTypesWithFetch';

interface SweepObjectSelectorProps {
  crmOrgId: string;
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  autoOpen?: boolean;
  fullWidth?: boolean;
  filterBy?: (objectType: ObjectTypeName) => boolean;
  objectTypeFilters?: ObjectTypePropertyFilterType;
}

const promotedObjectTypes = ['Lead', 'Account', 'Opportunity', 'Contact', 'Case'];

const emptyArray: ObjectTypeName[] = [];

export const SweepObjectSelector = ({
  crmOrgId,
  onChange,
  value,
  disabled,
  fullWidth,
  filterBy,
  objectTypeFilters,
  autoOpen,
}: SweepObjectSelectorProps) => {
  const { objectTypes: _objectTypes = emptyArray } = useObjectTypesWithFetch({ crmOrgId });

  const objectTypes = useMemo(() => {
    const __objectTypes = objectTypesPropertyFilter({
      objectTypes: _objectTypes,
      filters: objectTypeFilters || {},
    }) as ObjectTypeName[];
    if (!filterBy) {
      return __objectTypes;
    }
    return __objectTypes.filter((ot) => value === ot.objectType || filterBy(ot));
  }, [_objectTypes, filterBy, objectTypeFilters, value]);

  const objectTypesSet = useMemo(
    () => new Set(objectTypes.map(({ objectType }) => objectType)),
    [objectTypes],
  );

  const allObjects = objectTypes.filter(
    (objectType) => !promotedObjectTypes.includes(objectType.objectType),
  );

  const { items } = useMemo(() => {
    const promotedItems: SearchSelectItem<ObjectTypeName>[] = promotedObjectTypes
      .filter((objectType) => objectTypesSet.has(objectType))
      .map((objectType) => ({
        label: objectType,
        value: objectType,
      }));
    const allItems: SearchSelectItem<ObjectTypeName>[] = allObjects.map((objectType) => ({
      label: objectType.label,
      value: objectType.objectType,
    }));

    const items: SearchSelectItem<ObjectTypeName>[] = [];
    if (promotedItems.length > 0) {
      items.push(
        ...[
          {
            label: 'Promoted',
            value: 'Promoted',
            isSection: true,
          },
          ...promotedItems,
        ],
      );
    }
    if (allItems.length > 0) {
      items.push(
        ...[
          {
            label: 'All',
            value: 'All',
            isSection: true,
          },
          ...allItems,
        ],
      );
    }

    return { items };
  }, [allObjects, objectTypesSet]);

  return (
    <SearchSelect
      items={items}
      onChange={(item) => onChange && onChange(item.value)}
      SelectProps={{
        value,
        placeholder: 'e.g Lead, Opportunity',
      }}
      FormControlProps={{
        fullWidth,
      }}
      dataTestId="sweep-object-selector"
      itemRenderer={(item, search) => (
        <ObjectTypeLabelAndKeyTag
          objectType={item.label}
          label={highlightMatch(item.label, search)}
        />
      )}
      disabled={disabled}
      autoOpen={autoOpen}
    />
  );
};
