import { RootState } from '.';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdvancedFilterItem } from '../components/common/deprecated-advanced-filter/DeprecatedAdvancedFilter';
import {
  addBlanks,
  extractFilters,
  getAdvanceFilterItemStructure,
} from '../components/pages/salesforce-logs/utils';
import {
  SalesforceLoginHistoryFilter,
  SalesforceLoginHistoryFiltersPayload,
  SalesforceLoginHistoryItem,
} from '@server/salesforce-login-history';
import { DEFAULT_SORT_BY } from '../components/pages/salesforce-logs/login-history/utils';
import { SortOrder } from '../components/common/types';
import orderBy from 'lodash/orderBy';

export interface SfLoginHistoryState {
  list: SalesforceLoginHistoryItem[];
  filtersOptions: {
    country: AdvancedFilterItem[];
    loginType: AdvancedFilterItem[];
    loginUrl: AdvancedFilterItem[];
    application: AdvancedFilterItem[];
    status: AdvancedFilterItem[];
    users: AdvancedFilterItem[];
  };
  sortAndFilter: SalesforceLoginHistoryFiltersPayload;
  isBeingActivated: boolean;
}

const initialState: SfLoginHistoryState = {
  list: [],
  filtersOptions: {
    country: [],
    loginType: [],
    loginUrl: [],
    application: [],
    status: [],
    users: [],
  },
  sortAndFilter: {
    sortBy: DEFAULT_SORT_BY,
    sortKey: SortOrder.DESC,
    withFilters: false,
  },
  isBeingActivated: false,
};

export const sfLoginHistorySlice = createSlice({
  name: 'sfLoginHistory',
  initialState,
  reducers: {
    setSfLoginHistoryList: (
      state,
      action: PayloadAction<{
        loginHistoryList: SalesforceLoginHistoryItem[];
      }>,
    ) => {
      state.list = action.payload.loginHistoryList;
    },
    setSfLoginHistoryFilter: (
      state,
      action: PayloadAction<{
        newFilter: Partial<SalesforceLoginHistoryFiltersPayload>;
        clear?: boolean;
      }>,
    ) => {
      const { clear, newFilter } = action.payload;
      state.sortAndFilter = {
        ...(clear ? {} : state.sortAndFilter),
        ...newFilter,
        //adding properties below cause TS is complaning bc of Partial
        sortBy: newFilter.sortBy ?? state.sortAndFilter.sortBy,
        sortKey: newFilter.sortKey ?? state.sortAndFilter.sortKey,
        withFilters: newFilter.withFilters ?? state.sortAndFilter.withFilters,
      };
    },
    setFiltersOptions: (
      state,
      action: PayloadAction<{ filters: SalesforceLoginHistoryFilter }>,
    ) => {
      const { filters } = action.payload;
      state.filtersOptions = {
        country: orderBy(
          filters.country?.map((item) => ({
            label: addBlanks([item.name])[0],
            value: addBlanks([item.id])[0],
          })),
          'label',
        ),
        users: orderBy(
          filters.users?.map((item) => ({
            label: addBlanks([item.name])[0],
            value: addBlanks([item.id])[0],
          })),
          'label',
        ),
        loginType: getAdvanceFilterItemStructure(
          extractFilters(addBlanks(filters?.loginType ?? [])),
        ),
        loginUrl: getAdvanceFilterItemStructure(extractFilters(addBlanks(filters?.loginUrl ?? []))),
        application: getAdvanceFilterItemStructure(
          extractFilters(addBlanks(filters?.application ?? [])),
        ),
        status: getAdvanceFilterItemStructure(extractFilters(addBlanks(filters?.status ?? []))),
      };
    },
    setIsBeingActivated: (state, action: PayloadAction<boolean>) => {
      state.isBeingActivated = action.payload;
    },
  },
});

export const {
  setSfLoginHistoryList,
  setSfLoginHistoryFilter,
  setFiltersOptions,
  setIsBeingActivated,
} = sfLoginHistorySlice.actions;

export const selectSfLoginHistoryList = (state: RootState) => state.sfLoginHistory.list;
export const selectSfLoginHistoryFilters = (state: RootState) => state.sfLoginHistory.sortAndFilter;
export const selectSfLoginHistoryFiltersOptions = (state: RootState) =>
  state.sfLoginHistory.filtersOptions;
export const selectIsLoginHistoryBeingActivated = (state: RootState) =>
  state.sfLoginHistory.isBeingActivated;

export default sfLoginHistorySlice.reducer;
