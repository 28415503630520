import { Button, IconButton, Tooltip } from '@sweep-io/sweep-design';
import { Sparkle } from '@sweep-io/sweep-design/dist/icons';
import { BiEvent } from '../../services/events';
import useSendBiEvent from '../../hooks/useSendBiEvent';
import { useCallback } from 'react';
import useIsAiAgentsEnabled from './useIsAiAgentsEnabled';

const AiAgentCenterButton = ({
  onClick,
  variant,
  disabled,
  asIcon,
  biEvent,
}: {
  onClick: () => void;
  variant: 'primary' | 'secondary';
  disabled?: boolean;
  asIcon?: boolean;
  biEvent?: BiEvent;
}) => {
  const isAiAgentsEnabled = useIsAiAgentsEnabled();
  const sendBiEvent = useSendBiEvent();
  const buttonVariant = variant === 'primary' ? 'filled' : 'outlined';

  const onClickAndSendBiEvent = useCallback(() => {
    onClick();
    if (biEvent) {
      sendBiEvent(biEvent);
    }
  }, [biEvent, onClick, sendBiEvent]);

  if (!isAiAgentsEnabled) {
    return null;
  }

  return asIcon ? (
    <Tooltip title="Agentic" placement="bottom">
      <span>
        <IconButton
          onClick={onClickAndSendBiEvent}
          variant={buttonVariant}
          size="small"
          disabled={disabled}
        >
          <Sparkle />
        </IconButton>
      </span>
    </Tooltip>
  ) : (
    <Button
      onClick={onClickAndSendBiEvent}
      variant={buttonVariant}
      size="small"
      startIconName="Sparkle"
      disabled={disabled}
    >
      Agentic
    </Button>
  );
};

export default AiAgentCenterButton;
