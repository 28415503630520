import { Handle, InternalNode, Position, XYPosition } from '@xyflow/react';
import { ArrowDirection } from './FloatingEdge';
import { xYPositionOperation } from '../helpers/xYPositionOperation';

const TO_STEEP_ANGLE = 25;

/**
 * Calculates the Euclidean distance between two XY positions
 * @param firstXy First XY position
 * @param secondXy Second XY position
 * @returns The distance between the two points
 */
export const calculateDistanceBetweenPositions = (
  firstXy: XYPosition,
  secondXy: XYPosition,
): number => {
  const dx = secondXy.x - firstXy.x;
  const dy = secondXy.y - firstXy.y;
  return Math.sqrt(dx * dx + dy * dy);
};

/**
 * Calculates the absolute position of a handle on a node
 * @param node The React Flow internal node
 * @param handle The handle to get the position for
 * @returns The absolute XY position of the handle
 */

const getHandlePosition = (node: InternalNode | undefined, handle: Handle): XYPosition => {
  if (!node || !node.internals.handleBounds) return { x: 0, y: 0 };
  return (
    xYPositionOperation(node.internals.positionAbsolute, {
      x: handle?.x || 0,
      y: handle?.y || 0,
    }) || node.internals.positionAbsolute
  );
};

/**
 * Calculates the arrow direction based on the target handle position
 * @param targetHandle The target handle
 * @returns The arrow direction
 */
const getArrowDirection = (targetHandle: Handle) => {
  if (targetHandle.position === Position.Right) {
    return ArrowDirection.RightLeft;
  }
  if (targetHandle.position === Position.Left) {
    return ArrowDirection.LeftRight;
  }
  if (targetHandle.position === Position.Top) {
    return ArrowDirection.TopBottom;
  }
  if (targetHandle.position === Position.Bottom) {
    return ArrowDirection.BottomTop;
  }
  throw new Error('Invalid handle position');
};

/**
 * Calculates the angle in degrees between two XY positions
 * @param firstXy First XY position
 * @param secondXy Second XY position
 * @returns The angle in degrees between the two points
 */
export const calculateAngleBetweenPositions = (
  firstXy: XYPosition,
  secondXy: XYPosition,
): number => {
  const dx = secondXy.x - firstXy.x;
  const dy = secondXy.y - firstXy.y;
  return Math.atan2(dy, dx) * (180 / Math.PI);
};

/**
 * Calculates the shortest path between two handles on two nodes
 * @param sourceNode The source node
 * @param targetNode The target node
 * @returns The shortest path handles and the arrow direction
 */
export const getShortestPathHandles = (
  sourceNode: InternalNode,
  targetNode: InternalNode,
  skipSteepAngleCheck: boolean = false,
): {
  sourceHandle: Handle;
  targetHandle: Handle;
  sourceHandleAbsolutePosition: XYPosition;
  targetHandleAbsolutePosition: XYPosition;
  arrowDirection: ArrowDirection;
} => {
  let shortestDistance = Infinity;
  let shortestPathHandles:
    | {
        sourceHandle: Handle;
        targetHandle: Handle;
        sourceHandleAbsolutePosition: XYPosition;
        targetHandleAbsolutePosition: XYPosition;
      }
    | undefined;
  const sourceHandles = sourceNode.internals.handleBounds?.source || [];
  const targetHandles = targetNode.internals.handleBounds?.target || [];

  const sourceTargetHandlesCombinations = sourceHandles
    .map((sourceHandle) =>
      targetHandles.map((targetHandle) => ({
        sourceHandle,
        targetHandle,
      })),
    )
    .flat()
    .filter(({ sourceHandle, targetHandle }) => sourceHandle.position !== targetHandle.position);

  for (const { sourceHandle, targetHandle } of sourceTargetHandlesCombinations) {
    const sourceHandleXY = getHandlePosition(sourceNode, sourceHandle);
    const targetHandleXY = getHandlePosition(targetNode, targetHandle);

    if (!skipSteepAngleCheck || false) {
      const angle = Math.abs(calculateAngleBetweenPositions(sourceHandleXY, targetHandleXY));
      const isAngleTooSteep =
        [Position.Left, Position.Right].includes(targetHandle.position) &&
        angle > 90 - TO_STEEP_ANGLE &&
        angle < 90 + TO_STEEP_ANGLE;

      if (isAngleTooSteep) {
        continue;
      }
    }

    const distance = calculateDistanceBetweenPositions(sourceHandleXY, targetHandleXY);
    if (distance < shortestDistance) {
      shortestDistance = distance;
      shortestPathHandles = {
        sourceHandle,
        targetHandle,
        sourceHandleAbsolutePosition: sourceHandleXY,
        targetHandleAbsolutePosition: targetHandleXY,
      };
    }
  }

  if (!shortestPathHandles && !skipSteepAngleCheck) {
    // if no shortest path handles are found, skip the steep angle check
    shortestPathHandles = getShortestPathHandles(sourceNode, targetNode, true);
  }
  if (!shortestPathHandles) {
    throw new Error('No shortest path handles found');
  }

  return {
    ...shortestPathHandles,
    arrowDirection: getArrowDirection(shortestPathHandles.targetHandle),
  };
};
