import { Stack } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';
import AiAgentScrollArea from './AiAgentScrollArea';
import AiAgentInput from './AiAgentInput';
import useAiAgentCenter from './useAiAgentCenter';
import useAiAgents from './useAiAgents';
import ChatNote, { NoteAlignment } from './ChatNote';
import { useCallback } from 'react';
import useAiAgentChats from './useAiAgentChats';
import { isMessagesPaywallLimitReached } from './helper';
import usePaywall from '../dashboard/paywall/usePaywall';

const AiAgentChat = ({
  noteAlignment,
  maxWidth,
  searchTermToHighlight,
}: {
  noteAlignment: NoteAlignment;
  maxWidth?: string;
  searchTermToHighlight?: string; // The highlight should only be applied "expanded", so we get it as a prop
}) => {
  const { isExpanded } = useAiAgentCenter();
  const { activeAgent } = useAiAgents();
  const { isPaywallActive } = usePaywall();

  const { onConfirmMessage, activeChat } = useAiAgentChats();
  const messagesPaywallLimitReached =
    isPaywallActive &&
    isMessagesPaywallLimitReached({
      messages: activeChat?.data?.aiChatDetails.messages,
      agentType: activeAgent?.type,
    });

  const messages = activeChat?.data?.aiChatDetails?.messages ?? [];

  const onConfirm = useCallback(
    async (message: string) => {
      if (!activeAgent) {
        return;
      }
      await onConfirmMessage({
        messageStr: message,
        agentId: activeAgent.id,
        agentType: activeAgent.type,
      });
    },
    [onConfirmMessage, activeAgent],
  );
  const isLoadingChat = !!activeChat?.isLoading;
  const isLoadingResponse = !!activeChat?.data?.isLoading;
  const chatContextComponents = activeChat?.data?.chatContextComponents;

  return (
    <Stack
      sx={{
        width: '100%',
        alignItems: 'center',
        overflow: 'auto',
        flex: 1,
      }}
    >
      <AiAgentScrollArea
        messages={isLoadingChat ? [] : messages}
        isLoadingResponse={isLoadingChat ? false : isLoadingResponse}
        isLoadingChat={isLoadingChat}
        maxWidth={maxWidth}
        searchTermToHighlight={searchTermToHighlight}
        chatContextComponents={chatContextComponents}
      />
      <Stack
        sx={{
          width: '100%',
          maxWidth,
          backgroundColor: isExpanded ? undefined : '#F7F9FA',
          borderTop: isExpanded ? undefined : `1px solid ${colors.grey[300]}`,
        }}
      >
        <AiAgentInput
          onConfirm={onConfirm}
          isLoading={isLoadingResponse}
          disabled={messagesPaywallLimitReached || isLoadingChat}
        />
        <Stack p={1.5} pt={0}>
          <ChatNote alignment={noteAlignment} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AiAgentChat;
