import { PanelType } from '../../../../panels/types';

export interface GatePanelDataType {
  funnelId: string;
  stageId: string;
  exitCriteriaId: string;
}

const GATE_PANEL = 'GATE_PANEL';

export const gatePanelType: PanelType<GatePanelDataType> = {
  name: GATE_PANEL,
  location: 'right'
};
