import { _blanks, SalesforceLoginHistoryFiltersPayload } from '@server/salesforce-login-history';
import buildURLQuery from '../../../lib/buildURLQuery';
import { SfChangeFeedFilters, SfChangeFeedSortAndFilter } from './change-feed/types';
import { AdvancedFilterItem } from '../../common/advanced-filter/AdvancedFilter';

export const UI_BLANKS = '(Blanks)';

export const getAdvanceFilterItemStructure = ({
  items,
  valueKey,
  labelKey,
  decorationFunc,
}: {
  items: any;
  valueKey: string;
  labelKey: string;
  decorationFunc?: (item: any) => void;
}): AdvancedFilterItem[] =>
  items.map((item: any) => ({
    label: item[labelKey],
    value: item[valueKey],
    decoration: decorationFunc && decorationFunc(item),
  }));

export const prepareURLQuery = (
  filters: (SfChangeFeedFilters & { withFilters?: boolean }) | SalesforceLoginHistoryFiltersPayload,
) => {
  const result: any = { ...filters };
  Object.keys(filters)?.forEach((itemKey) => {
    const key = itemKey as any;
    const items =
      filters[
        key as keyof (
          | (SfChangeFeedFilters & { withFilters?: boolean | undefined })
          | SalesforceLoginHistoryFiltersPayload
        )
      ];
    if (Array.isArray(items)) {
      result[key] = items.join(',').replace(UI_BLANKS, _blanks);
    }
  });

  const searchParams = result ? buildURLQuery(result) : '';
  return searchParams ? '?' + searchParams : '';
};

export const extractFilters = (content?: string[]) => ({
  items: content?.map((item) => ({ label: item, value: item })),
  labelKey: 'label',
  valueKey: 'value',
});

export const addBlanks = (listFilter?: (string | null)[]): string[] =>
  listFilter
    ?.map((item) => (item === null || item === '' ? UI_BLANKS : item))
    .sort((a, b) => (a < b ? -1 : 1)) ?? [];

export const hasFilters = (
  sortAndFilter: SfChangeFeedSortAndFilter | SalesforceLoginHistoryFiltersPayload,
): boolean => {
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { sortKey, sortBy, ...filters } = sortAndFilter;
  return Object.values(filters).some((filter) => (Array.isArray(filter) ? !!filter?.length : true));
};
