import { useEffect } from 'react';
import {
  CanvasElementType,
  CanvasMode,
  DEFAULT_CANVAS_MIN_ZOOM,
  DEFAULT_CANVAS_ZOOM,
} from '../canvas-types';
import { FitViewOptions } from '@xyflow/react';
import usePrevious from '../../../hooks/usePrevious';
import { useSweepCanvasState } from '../internal-context/CanvasStateContext';

const fitViewOptions: { [K in CanvasMode]: FitViewOptions } = {
  [CanvasMode.DEFAULT]: {
    padding: 0.3,
    minZoom: DEFAULT_CANVAS_MIN_ZOOM,
    maxZoom: DEFAULT_CANVAS_ZOOM,
  },
  [CanvasMode.PREVIEW1]: {
    padding: 0.15,
    minZoom: 0.01,
    maxZoom: 0.3,
  },
  [CanvasMode.PREVIEW2]: {
    padding: 0.15,
    minZoom: 0.01,
    maxZoom: 0.9,
  },
  [CanvasMode.PREVIEW3]: {
    padding: 0.15,
    minZoom: 0.01,
    maxZoom: 0.9,
  },
  [CanvasMode.FIT_NODES_PREVIEW]: {
    padding: 0.15,
    minZoom: 0.01,
    maxZoom: 0.9,
  },
};

export const getFitViewOptions = (canvasMode: CanvasMode) => {
  return fitViewOptions[canvasMode];
};
export const useCenterOnFirstNodeEffect = () => {
  const { canvasNodes: nodes, reactFlowInstance } = useSweepCanvasState();

  const filteredNodes = nodes.filter((node) => node.type === CanvasElementType.REGULAR);

  const isEmpty = filteredNodes.length === 0;
  const wasEmpty = usePrevious(isEmpty);

  useEffect(() => {
    if (!isEmpty && wasEmpty && reactFlowInstance?.viewportInitialized) {
      setTimeout(() => {
        reactFlowInstance?.fitView({
          nodes: filteredNodes,
          duration: 500,
        });
      }, 50);
    }
  }, [filteredNodes, isEmpty, reactFlowInstance, wasEmpty]);
};
