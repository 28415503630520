import { Typography } from '@sweep-io/sweep-design';
import pluralize from 'pluralize';
import { Box } from '@mui/material';
import { SystemTag } from '../../third-party-funnels/SystemTag';
import { ObjectTypeLabelAndKeyTag } from '../../common/ObjectTypeLabelAndKey';

interface FunnelLinkDescriptionProps {
  from: string;
  to?: string;
  conditions?: number;
  showConditions?: boolean;
  tag: React.ReactNode;
}

export const FunnelLinkDescription = ({
  from,
  to,
  conditions = 0,
  showConditions = false,
  tag,
}: FunnelLinkDescriptionProps) => {
  const maybeRenderConditions = () => {
    if (!showConditions) return null;
    return (
      <Box sx={{ paddingLeft: '10px' }}>
        <Typography variant="caption-bold">
          {conditions} {pluralize('Condition', conditions)}
        </Typography>
      </Box>
    );
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Typography variant="body-medium">{`${from} -> ${to || ''}`}</Typography>
      {tag}
      {maybeRenderConditions()}
    </Box>
  );
};

type SfLinkFunnelLinkDescriptionProps = Omit<FunnelLinkDescriptionProps, 'tag'> & {
  targetObjectName: string;
  targetFunnelName: string;
};
export const SfLinkFunnelLinkDescription = ({
  conditions,
  showConditions,
  from,
  to,
  targetObjectName,
  targetFunnelName,
}: SfLinkFunnelLinkDescriptionProps) => {
  return (
    <FunnelLinkDescription
      from={from}
      to={to}
      conditions={conditions}
      showConditions={showConditions}
      tag={<ObjectTypeLabelAndKeyTag objectType={targetObjectName} label={targetFunnelName} />}
    />
  );
};

type ThirdPartyLinkFunnelLinkDescriptionProps = Omit<FunnelLinkDescriptionProps, 'tag'> & {
  system?: {
    name: string;
    domain: string;
  };
  funnelName: string;
};
export const ThirdPartyLinkFunnelLinkDescription = ({
  conditions,
  showConditions,
  from,
  to,
  system,
  funnelName,
}: ThirdPartyLinkFunnelLinkDescriptionProps) => {
  return (
    <FunnelLinkDescription
      from={from}
      to={to}
      conditions={conditions}
      showConditions={showConditions}
      tag={<SystemTag system={system} tooltip={system?.name} forceName={funnelName} />}
    />
  );
};
