import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { AutomationDialogContent } from './AutomationDialogContent';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { useSelector } from 'react-redux';
import { selectDefaultCreationEnvironment } from '../pages/environments/environmentsReducer';
import useAutomationApiPerType from './useAutomationApiPerType';
import { automationsSortOptions } from '../pages/canvas-pages/consts';
import { useGetAutomationFilters } from '../pages/canvas-pages/automationFilters';
import { WithConfigurationCanvas } from '../pages/configuration-canvas/WithConfigurationCanvas';
import { VisibilityMap } from '../../types/VisibilityTypes';
import { VisibilityLayers } from '../../types/enums/VisibilityLayers';
import { VisibilityElementMap } from '../pages/configuration-canvas/types';
import useEntitiesToHighlight from '../pages/canvas-pages/useEntitiesToHighlight';
import useAutomationsCanvasInteractions from '../pages/canvas-pages/useAutomationsCanvasInteractions';
import { AutomationAutomationsContextProvider } from '../alerts/AutomationAutomationsContextProvider';
import { useConfigurationCanvas } from '../pages/configuration-canvas/public/useConfigurationCanvas';
import { selectCrmOrgAutomations } from '../../reducers/global/automationReducers';

interface AutomationsProps {
  setHoveredItem?: (automationId?: string | undefined) => void;
}

const alertsPageVisibilityMap: Partial<VisibilityMap> = {
  [VisibilityLayers.NURTURING_STEPS]: true,
  [VisibilityLayers.COMMENTS]: false,
  [VisibilityLayers.PLAYBOOK_ALERTS]: true,
  [VisibilityLayers.FUNNEL_PLAYBOOK_ALERTS]: true,
};
const visibilityElements: VisibilityElementMap = {
  type: 'map',
  visibilityMap: alertsPageVisibilityMap,
};

const Automations = ({ setHoveredItem }: AutomationsProps) => {
  const { canvasFunnelMap, canvasCrmOrgId } = useConfigurationCanvas();
  const automations = useSelector(selectCrmOrgAutomations(canvasCrmOrgId));
  const automationsVariant = AutomationFormVariant.AUTOMATIONS;
  const { onSaveOrCreate, onToggleActivation, onDeleteAutomation, onDeployAutomation } =
    useAutomationApiPerType({ automationVariant: automationsVariant });

  const filters = useGetAutomationFilters(automations);
  const crmOrg = useSelector(selectDefaultCreationEnvironment);

  if (!automations) return <CenteredCircularProgress />;

  return (
    <>
      {crmOrg && (
        <AutomationDialogContent
          onSaveOrCreate={onSaveOrCreate}
          onToggleActivation={onToggleActivation}
          onDeleteAutomation={onDeleteAutomation}
          automations={automations}
          crmOrgId={canvasCrmOrgId}
          recordTypesData={canvasFunnelMap.recordTypesData}
          funnelsData={canvasFunnelMap.funnelsData}
          sortOptions={automationsSortOptions}
          setHoveredItem={setHoveredItem}
          onDeployAutomation={onDeployAutomation}
          filtersForExpandedMode={filters}
        />
      )}
    </>
  );
};

const AutomationsWithContext = (automationsProps: AutomationsProps) => {
  const { onEntityClick } = useAutomationsCanvasInteractions();
  const automationsVariant = AutomationFormVariant.AUTOMATIONS;
  const { entitiesToHighlight } = useEntitiesToHighlight(automationsVariant);
  return (
    <WithConfigurationCanvas
      visibilityElements={visibilityElements}
      onFunnelStepClick={onEntityClick}
      onRecordTypeStepClick={onEntityClick}
      onFunnelLabelPillClick={onEntityClick}
      onRecordTypeLabelPillClick={onEntityClick}
      highlightEntities={entitiesToHighlight}
    >
      <AutomationAutomationsContextProvider>
        <Automations {...automationsProps} />
      </AutomationAutomationsContextProvider>
    </WithConfigurationCanvas>
  );
};

export default AutomationsWithContext;
