import * as React from "react";
const SvgViewInSf = (props) => /* @__PURE__ */ React.createElement("svg", { width: 30, height: 20, viewBox: "0 0 30 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("g", { id: "Frame 1707480394" }, /* @__PURE__ */ React.createElement("path", { id: "Vector", d: "M11.0909 5.90906L4.2727 5.90906L4.27271 19L11.0909 19L11.0909 5.90906Z", fill: "#F8BCF2", style: {
  fill: "#F8BCF2",
  fill: "color(display-p3 0.9708 0.7362 0.9474)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { id: "Vector_2", d: "M18 1L11 1L11 19L18 19L18 1Z", fill: "#FFD28D", style: {
  fill: "#FFD28D",
  fill: "color(display-p3 1.0000 0.8217 0.5542)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { id: "Vector_3", d: "M25 9L18 9L18 19L25 19L25 9Z", fill: "#DFF8D5", style: {
  fill: "#DFF8D5",
  fill: "color(display-p3 0.8745 0.9725 0.8353)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { id: "Vector_4", d: "M27 19L2 19M4.27273 19L4.27273 5.90909L11.0909 5.90909M17.9091 9.18182L24.7273 9.18182L24.7273 19M11.0909 19L11.0909 1L17.9091 1L17.9091 19L11.0909 19Z", stroke: "#1198FF", style: {
  stroke: "#1198FF",
  stroke: "color(display-p3 0.0667 0.5968 1.0000)",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" })));
export default SvgViewInSf;
