import { useRunOnce } from '../../common/useRunOnce';
import { useSweepApi } from '../../../apis/sweep';
import { telemetry } from '../../../telemetry';
import { useLocation } from 'react-router';

const TeamsOAuth2Redirect = () => {
  const { search } = useLocation();
  const sweepApi = useSweepApi();

  useRunOnce(async () => {
    const urlSearchParams = new URLSearchParams(search);
    const code = urlSearchParams.get('code') || 'awaiting-approval';
    const state = urlSearchParams.get('state');

    if (state && code) {
      try {
        await sweepApi.post(`/data-integrations/teams/oauth2-callback`, {
          code,
          state,
        });
      } catch (error) {
        telemetry.captureError(error);
      }
    }
    window.close();
  });

  return null;
};

export { TeamsOAuth2Redirect };
