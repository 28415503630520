import { Box, Divider } from '@mui/material';
import { DateBy, CustomDatePicker } from './types';
import { colors, Typography } from '@sweep-io/sweep-design';
import { DateTime } from 'luxon';
import { DatePickerElement } from './DatePickerElement';

const shouldDisableDate = (chosenDate: DateTime, customDate: CustomDatePicker, key: DateBy) => {
  if (customDate[DateBy[key]]) {
    const ISOday = chosenDate.toISO() ?? '';
    const customDateISO = customDate[DateBy[key]] ?? '';
    return key === DateBy.To ? ISOday > customDateISO : ISOday < customDateISO;
  }
  return false;
};

export const CustomDatePickerSection = ({
  customDate,
  onCustomDateChange,
}: {
  customDate: CustomDatePicker;
  onCustomDateChange: (date: string, direction: DateBy) => void;
}) => {
  return (
    <Box sx={{ paddingRight: '8px', display: 'flex', width: '240px' }}>
      <Box sx={{ padding: '12px', marginRight: '8px' }}>
        <DatePickerItem
          currentValues={customDate}
          dateBy={DateBy.From}
          onSelectDate={onCustomDateChange}
          shouldDisableDate={(chosenDate: DateTime) =>
            shouldDisableDate(chosenDate, customDate, DateBy.To)
          }
        />

        <DatePickerItem
          currentValues={customDate}
          dateBy={DateBy.To}
          onSelectDate={onCustomDateChange}
          shouldDisableDate={(chosenDate: DateTime) =>
            shouldDisableDate(chosenDate, customDate, DateBy.From)
          }
        />
      </Box>
      <Divider orientation="vertical" />
    </Box>
  );
};

const DatePickerItem = ({
  currentValues,
  dateBy,
  onSelectDate,
  shouldDisableDate,
}: {
  currentValues: CustomDatePicker;
  dateBy: DateBy;
  onSelectDate: (date: string, direction: DateBy) => void;
  shouldDisableDate?: (chosenDate: DateTime) => boolean;
}) => {
  const currentDate =
    currentValues[dateBy] !== null ? DateTime.fromISO(currentValues[dateBy] as string) : null;

  const parseDate = (val: DateTime | null) => {
    return val ? (dateBy === DateBy.From ? val.startOf('day') : val.endOf('day')).toString() : '';
  };

  return (
    <Box>
      <Typography variant="caption" color={colors.grey[800]}>
        {dateBy}
      </Typography>

      <DatePickerElement
        defaultValue={currentDate}
        value={currentDate}
        disableFuture={true}
        shouldDisableDate={shouldDisableDate}
        callback={(value) => {
          onSelectDate(parseDate(value), dateBy);
        }}
      />
    </Box>
  );
};
