import { Box, Stack } from '@mui/material';
import { Button, colors, IconButton, Typography } from '@sweep-io/sweep-design';
import { ChevronLeft } from '@sweep-io/sweep-design/dist/icons';
import AgentInfo from './AgentInfo';
import AiAgentChat from './AiAgentChat';
import { ReactNode } from 'react';
import useAiAgentChats from './useAiAgentChats';
import { AI_AGENTS_INFO, TOP_BAR_COMMON_SX } from './aiAgentsConsts';
import useAiAgents from './useAiAgents';
import { AgentType } from '@server/ai';
import AgentSettingsMenu from './agentSettings/AgentSettingsMenu';
import ToggleExpandButton from './ToggleExpandButton';

const ChatWithAgentCollapsed = ({ closeButton }: { closeButton: ReactNode }) => {
  const { activeChat, startChatWithAgent, setActiveChatId } = useAiAgentChats();
  const { activeAgent, renameAgent } = useAiAgents();

  const returnToAgentsList = () => setActiveChatId(undefined);

  if (!activeAgent) {
    return null;
  }

  const { agentIcon, persona, addingFiles } = AI_AGENTS_INFO[activeAgent.type];

  const newButton = (
    <Button
      startIconName="Plus"
      variant="outlined"
      onClick={() =>
        startChatWithAgent({
          agent: activeAgent,
          biEventProps: {
            origin: 'button',
            mode: 'collapsed',
          },
        })
      }
      size="small"
    >
      New chat
    </Button>
  );

  const isDocumentationAgent = activeAgent?.type === AgentType.Documentation;
  const isNewChat = activeChat?.data?.aiChatDetails?.messages.length === 0;
  const hideNameAndMenu = isNewChat && !isDocumentationAgent;

  return (
    <Stack height="100%">
      <Box
        sx={{
          ...TOP_BAR_COMMON_SX,
          borderBottom: `1px solid ${colors.grey[300]}`,
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" gap={1} alignItems="center">
          <IconButton variant="flat" size="tiny" onClick={returnToAgentsList}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="body-medium">AI Agents</Typography>
        </Box>

        <Box display="flex" gap={1} alignItems="center">
          {newButton}
          <ToggleExpandButton />
          {closeButton}
        </Box>
      </Box>

      {!hideNameAndMenu && (
        <Stack
          sx={{
            padding: '8px 16px 8px',
            borderBottom: `1px solid ${colors.grey[300]}`,
            height: '56px',
            justifyContent: 'center',
            transition: 'height 0.1s linear',
          }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <AgentInfo
              icon={agentIcon}
              persona={persona}
              name={activeAgent.name}
              onEditName={(name) => renameAgent(activeAgent.id, name)}
              iconSize="28px"
            />
            <AgentSettingsMenu
              agent={activeAgent}
              withoutFiles={!addingFiles || !addingFiles.isAllowed}
            />
          </Stack>
        </Stack>
      )}

      <AiAgentChat noteAlignment="left" />
    </Stack>
  );
};

export default ChatWithAgentCollapsed;
