import { DateKeys, DateBy, CustomDatePicker } from './types';
import { DateTime } from 'luxon';

export const getDateFromKey = (key: DateKeys): CustomDatePicker => {
  const todayStart = DateTime.local().startOf('day');
  const todayEnd = DateTime.local().endOf('day');
  const todayEndISO = todayEnd?.toISO() ?? '';

  switch (key) {
    case DateKeys.Today:
      return {
        key,
        [DateBy.From]: todayStart.toISO() ?? '',
        [DateBy.To]: todayEndISO,
      };

    case DateKeys.Yesterday:
      const yesterdayStart = todayStart.minus({ day: 1 });
      const yesterdayEnd = todayEnd.minus({ day: 1 });

      return {
        key,
        [DateBy.From]: yesterdayStart.toISO() ?? '',
        [DateBy.To]: yesterdayEnd.toISO() ?? '',
      };

    case DateKeys.Last7Days:
      return {
        key,
        [DateBy.From]: todayStart.minus({ day: 7 }).toISO() ?? '',
        [DateBy.To]: todayEndISO,
      };

    case DateKeys.Last28Days:
      return {
        key,
        [DateBy.From]: todayStart.minus({ day: 28 }).toISO() ?? '',
        [DateBy.To]: todayEndISO,
      };

    case DateKeys.Last90Days:
      return {
        key,
        [DateBy.From]: todayStart.minus({ day: 90 }).toISO() ?? '',
        [DateBy.To]: todayEndISO,
      };

    case DateKeys.Last12Months:
      return {
        key,
        [DateBy.From]: todayStart.minus({ year: 1 }).toISO() ?? '',
        [DateBy.To]: todayEndISO,
      };

    default:
      return {
        key,
        [DateBy.From]: undefined,
        [DateBy.To]: undefined,
      };
  }
};
