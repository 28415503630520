import { SweepNodeHandles } from './SweepNodeHandles';
import { NodeComponent } from './components/SweepNodeComponent';
import { NODE_WIDTH } from '../const';
import { SxProps } from '@mui/material';

export const BaseGhostNode = ({
  children,
  handleColor,
  backgroundColor,
  sx,
}: {
  children: React.ReactNode;
  handleColor: string;
  backgroundColor: string;
  sx?: SxProps;
}) => {
  return (
    <SweepNodeHandles>
      <NodeComponent
        sx={{
          border: '2px dashed',
          background: backgroundColor,
          borderColor: handleColor,
          width: NODE_WIDTH,
          ...sx,
        }}
      >
        {children}
      </NodeComponent>
    </SweepNodeHandles>
  );
};
