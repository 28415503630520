import { Box, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  selectSlackIntegrationStatus,
  SlackConnectionStatus,
} from '../../../reducers/integrationsReducer';
import { colors } from '@sweep-io/sweep-design';
import { SlackNotConnected } from './SlackNotConnected';
import { SlackSelectDealroomType } from './SlackSelectDealroomType';
import { useRunOnce } from '../../common/useRunOnce';
import { SlackConstant } from './utils';
import { SlackNewMessageEditor } from './SlackNewMessageEditor';
import { SlackAdditionalFieldsTable } from './SlackAdditionalFieldsTable';

interface SlackArchiveDealroomActionProps {
  readonly?: boolean;
  action: SlackArchiveDealroomAutomationAction;
  crmOrgId: string;
  onChange: (slackDealRoomAutomationAction: SlackArchiveDealroomAutomationAction) => any;
  objectName: string;
}

export const SlackArchiveDealroomAction = ({
  readonly,
  action,
  crmOrgId,
  objectName,
  onChange,
}: SlackArchiveDealroomActionProps) => {
  const slackStatus = useSelector(selectSlackIntegrationStatus);

  useRunOnce(() => {
    updateOnChange({
      channelRef: action.actionParams?.channelRef
        ? { ...action.actionParams?.channelRef }
        : {
            type: SlackConstant.Sweep_Created_Slack_Channel,
          },
    });
  });

  const updateOnChange = useCallback(
    (updateProps: Partial<AutomationSlackArchiveDealroomActionParams>) => {
      onChange({
        ...action,
        actionParams: {
          ...action.actionParams,
          ...updateProps,
        },
      });
    },
    [action, onChange],
  );

  return (
    <>
      {slackStatus !== SlackConnectionStatus.Connected && (
        <Box sx={{ marginTop: '32px' }}>
          <SlackNotConnected slackStatus={slackStatus} />
        </Box>
      )}

      {slackStatus === SlackConnectionStatus.Connected && (
        <Box
          sx={{
            background: colors.grey[100],
            padding: '24px 24px 19px',
            borderRadius: '8px',
            margin: '16px 0',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <SlackSelectDealroomType
            readonly={readonly}
            value={action?.actionParams?.channelRef}
            crmOrgId={crmOrgId}
            objectName={objectName}
            onChange={(val) => {
              updateOnChange({ channelRef: val });
            }}
          />

          <SlackNewMessageEditor
            placeholder="Type message body..."
            isRequired={false}
            label="Message (optional)"
            readonly={readonly}
            messageStruct={action.actionParams?.message ?? { template: '', variables: [] }}
            crmOrgId={crmOrgId}
            onChange={(newMsg) => {
              updateOnChange({ message: newMsg });
            }}
            objectName={objectName}
          />
          <SlackAdditionalFieldsTable
            readonly={readonly}
            fields={action.actionParams?.fields ?? []}
            updateOnChange={updateOnChange}
            crmOrgId={crmOrgId}
            objectName={objectName}
          />

          <Typography variant="body">
            Note: this automation would work as expected only if the record has a dedicated deal
            room
          </Typography>
        </Box>
      )}
    </>
  );
};
