import { useEffect } from 'react';
import { useSweepCanvasState } from '../internal-context/CanvasStateContext';
import { useSweepCanvasPropsCtx } from '../internal-context/SweepCanvasPropsCtx';
import { useReactFlow, useViewport } from '@xyflow/react';
import { CanvasElementType, CanvasMode } from '../canvas-types';

// Test for css-edges-context-zoom in params
const params = new URLSearchParams(window.location.search);
const cssEdgesContextZoom = params.get('css-edges-context-zoom') === 'true';

export const getZoomLevels = (zoom: number) => {
  const isZoomLowerThan0_3 = zoom < 0.3;
  const isZoomHigherThan0_3 = zoom >= 0.3;
  const isZoomLowerThan0_35 = zoom < 0.35;
  const isZoomHigherThan0_35 = zoom >= 0.35;
  const isZoomLowerThan0_4 = zoom < 0.4;
  const isZoomHigherThan0_4 = zoom >= 0.4;
  const isZoomLowerThan0_75 = zoom < 0.75;
  const isZoomHigherThan0_75 = zoom >= 0.75;
  return {
    isZoomLowerThan0_3,
    isZoomHigherThan0_3,
    isZoomLowerThan0_35,
    isZoomHigherThan0_35,
    isZoomLowerThan0_4,
    isZoomHigherThan0_4,
    isZoomLowerThan0_75,
    isZoomHigherThan0_75,
  };
};

export const getEdgeHiddenProperty = ({
  sourceParentId,
  targetParentId,
  isZoomLowerThan0_3,
}: {
  sourceParentId?: string;
  targetParentId?: string;
  isZoomLowerThan0_3: boolean;
}) => {
  if (cssEdgesContextZoom) {
    return false;
  }
  const hasSameParent = sourceParentId === targetParentId;
  const hidden = isZoomLowerThan0_3;
  return hasSameParent && hidden;
};

const getShowButtonsOnHighlight = ({
  isZoomHigherThan0_4,
  readonly,
  canvasMode,
}: {
  isZoomHigherThan0_4: boolean;
  readonly?: boolean;
  canvasMode?: CanvasMode;
}) => {
  const preview = canvasMode === CanvasMode.PREVIEW1;
  if (cssEdgesContextZoom) {
    return true;
  }
  return isZoomHigherThan0_4 && !readonly && !preview;
};

export const useGetShowButtonsOnHighlight = () => {
  const { readonly, canvasMode } = useSweepCanvasPropsCtx();
  const { getZoom } = useReactFlow();
  return getShowButtonsOnHighlight({
    isZoomHigherThan0_4: getZoomLevels(getZoom()).isZoomHigherThan0_4,
    readonly,
    canvasMode,
  });
};

export const calculateNodeVisibility = ({
  isZoomLowerThan0_3,
  isZoomHigherThan0_75,
}: {
  isZoomLowerThan0_3: boolean;
  isZoomHigherThan0_75: boolean;
}): 'onlyStep' | 'doNotShowMetadata' | 'showAll' => {
  if (isZoomHigherThan0_75) {
    return 'showAll';
  }
  if (isZoomLowerThan0_3) {
    return 'onlyStep';
  }
  return 'doNotShowMetadata';
};

export const useContextZoomEffect = () => {
  const { setCanvasEdges, setCanvasNodes } = useSweepCanvasState();
  const { readonly } = useSweepCanvasPropsCtx();
  const { zoom } = useViewport();

  const { isZoomHigherThan0_4, isZoomLowerThan0_3, isZoomHigherThan0_75 } = getZoomLevels(zoom);

  // Effect that handles the visibility of the buttons on the edges and regular nodes
  useEffect(() => {
    const showButtonsOnHighlight = getShowButtonsOnHighlight({
      isZoomHigherThan0_4,
      readonly,
    });
    if (!cssEdgesContextZoom) {
      setCanvasEdges((edges) => {
        return edges.map((edge) => {
          if (edge.data && edge.data.showButtonsOnHighlight !== showButtonsOnHighlight) {
            return {
              ...edge,
              data: {
                ...edge.data,
                showButtonsOnHighlight,
              },
            };
          }
          return edge;
        });
      });
    }
    setCanvasNodes((nodes) => {
      return nodes.map((node) => {
        if (
          node.type === CanvasElementType.REGULAR &&
          node.data.showButtonsOnHighlight !== showButtonsOnHighlight
        ) {
          return {
            ...node,
            data: {
              ...node.data,
              showButtonsOnHighlight,
            },
          };
        }
        return node;
      });
    });
  }, [isZoomHigherThan0_4, readonly, setCanvasEdges, setCanvasNodes]);

  // Effect that handles the visibility of the edges
  useEffect(() => {
    if (!cssEdgesContextZoom) {
      setCanvasEdges((edges) => {
        return edges.map((edge) => {
          const hidden = getEdgeHiddenProperty({
            sourceParentId: edge.data?.sourceParentId,
            targetParentId: edge.data?.targetParentId,
            isZoomLowerThan0_3,
          });
          if (edge.hidden !== hidden) {
            return {
              ...edge,
              hidden,
            };
          }
          return edge;
        });
      });
    }
  }, [isZoomLowerThan0_3, setCanvasEdges]);

  // Effect that handles the visibility of the elements in the nodes
  useEffect(() => {
    setCanvasNodes((nodes) => {
      return nodes.map((node) => {
        const nodeVisibility = calculateNodeVisibility({
          isZoomLowerThan0_3,
          isZoomHigherThan0_75,
        });
        if (node.type === CanvasElementType.REGULAR) {
          if (node.data.nodeVisibility !== nodeVisibility) {
            return {
              ...node,
              data: {
                ...node.data,
                nodeVisibility,
              },
            };
          }
        }
        return node;
      });
    });
  }, [isZoomHigherThan0_75, isZoomLowerThan0_3, setCanvasNodes]);
};
