import { AgentType, ChatCompletionRequestMessageRole } from '@server/ai';
import { AiChatMessage } from '../common/ai-chat/aiChatTypes';
import usePaywall from '../dashboard/paywall/usePaywall';
import { useUnhappyStates } from '../pages/environments/useUnhappyStates';
import { isMessagesPaywallLimitReached } from './helper';

interface UseAiAgentMessageProps {
  messages: AiChatMessage[];
  introMessage?: AiChatMessage;
  agentType: AgentType;
}

export const useAiAgentMessages = ({
  messages,
  introMessage,
  agentType,
}: UseAiAgentMessageProps) => {
  const { isPaywallActive } = usePaywall();
  const { noConnectedOrg, isInitialFetch } = useUnhappyStates();
  const messagesPaywallLimitReached = isMessagesPaywallLimitReached({ messages, agentType });

  const userMessagesCount = messages.filter(
    (msg) => msg.role === ChatCompletionRequestMessageRole.USER,
  ).length;

  const isUserSentMessage = userMessagesCount > 0;

  const withoutLastResponseIfFromServer =
    messages.length < 1
      ? messages
      : messages[messages.length - 1]?.role === ChatCompletionRequestMessageRole.ASSISTANT
        ? messages.slice(0, -1)
        : messages;

  const renderNoOrgConnectedMessage = isUserSentMessage && noConnectedOrg;
  const renderInitialFetchMessage = isUserSentMessage && isInitialFetch;
  const isNoOrgState = noConnectedOrg || isInitialFetch;
  const renderChatPaywallBlockMessage =
    isPaywallActive && !isNoOrgState && messagesPaywallLimitReached;

  const isRenderAutoMessage =
    renderNoOrgConnectedMessage || renderInitialFetchMessage || renderChatPaywallBlockMessage;

  const allowedMessages = isRenderAutoMessage ? withoutLastResponseIfFromServer : messages;

  const messagesToRender = introMessage ? [introMessage].concat(allowedMessages) : allowedMessages;

  return {
    messagesToRender,
    renderNoOrgConnectedMessage,
    renderChatPaywallBlockMessage,
    renderInitialFetchMessage,
  };
};
