export const NODE_WIDTH = 171;
export const NODE_HEIGHT = 64;

export const GRID_H_SPACING = 174;
export const GRID_V_SPACING = 95;

export const SWEEP_GRID_SIZE = {
  width: NODE_WIDTH + GRID_H_SPACING,
  height: NODE_HEIGHT + GRID_V_SPACING,
};

export const CONTEXT_ZOOM_LEVEL_1 = 0.75;
export const CONTEXT_ZOOM_LEVEL_1_5 = 0.4;
export const CONTEXT_ZOOM_LEVEL_2 = 0.35;

export const EDGE_EXIT_ENTRY_GAP = 12;

export const GATE_DISTANCE_PERCENTAGE = 0.25;
export const DELETE_CONNECTION_DISTANCE_PERCENTAGE = 0.5;

export const HALF_NODE_GAP = 98;

export const CANVAS_COLOR_HIGHLIGHT = '#1198FF';
