import {
  ConfigurationCanvasLeftPanel,
  ConfigurationCanvasPanelContent,
  ConfigurationCanvasPanelHeader,
} from '../../configuration-canvas-panel';
import { PanelCampaignsTab } from './PanelCampaignsTab';

import { useSelector } from 'react-redux';
import { selectDefaultCreationCrmOrgId } from '../../../../reducers/userInfoReducer';
import LoadingSweep from '../../../common/LoadingSweep';
import { useRunOnceWhenTruthy } from '../../../common/useRunOnceWhenTruthy';
import { useHubspot } from './useHubspot';
import { useHubspotWorkflows } from './workflow-dialog/useHubspotWorkflows';
import { useCampaigns } from './campaign-dialog/useCampaigns';

export enum HubspotFilterTypes {
  ACTIVITY = 'ACTIVITY',
  WORKFLOWS = 'WORKFLOWS',
  CAMPAIGN_STATUS = 'CAMPAIGN_STATUS',
  CAMPAIGN_TYPE = 'CAMPAIGN_TYPE',
}

export const useLoadHubspotFieldsAndWorkflows = () => {
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  const { getCampaigns } = useCampaigns();
  const { getWorkflows } = useHubspotWorkflows();

  const {
    connectedHubspotOrg,
    loadHubspotForms,
    loadHubspotPages,
    loadHubspotEmails,
    loadContactHubspotFieldsForConnectedHsOrg,
  } = useHubspot();

  useRunOnceWhenTruthy(
    async () => {
      const hsOrgId = connectedHubspotOrg?.id as string;

      await Promise.all([
        getCampaigns(),
        getWorkflows(),
        loadContactHubspotFieldsForConnectedHsOrg(),
        loadHubspotForms(hsOrgId),
        loadHubspotPages(hsOrgId),
        loadHubspotEmails(hsOrgId),
      ]);
    },

    Boolean(connectedHubspotOrg?.id),
  );

  return { crmOrgId, connectedHubspotOrg };
};

export const HubspotCanvasPanel = () => {
  const { connectedHubspotOrg, crmOrgId } = useLoadHubspotFieldsAndWorkflows();

  return (
    <>
      <ConfigurationCanvasLeftPanel
        sx={{
          '.configuration-canvas-panel-content': {
            overflow: 'hidden',
          },
        }}
      >
        <ConfigurationCanvasPanelHeader>Campaign Enrollments</ConfigurationCanvasPanelHeader>
        <ConfigurationCanvasPanelContent sx={{ padding: [1, 0, 0, 0] }}>
          {!connectedHubspotOrg?.id || !crmOrgId ? (
            <LoadingSweep />
          ) : (
            <PanelCampaignsTab crmOrgId={crmOrgId} hsOrgId={connectedHubspotOrg?.id} />
          )}
        </ConfigurationCanvasPanelContent>
      </ConfigurationCanvasLeftPanel>
    </>
  );
};
