import { Box } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';
import { ReactNode } from 'react';

const AiAgentListItemLayout = ({
  isActive,
  onClick,
  children,
  setIsHovered,
}: {
  isActive: boolean;
  onClick: () => void;
  setIsHovered?: (v: boolean) => void;
  children: ReactNode;
}) => {
  return (
    <>
      <Box
        onClick={onClick}
        onMouseEnter={() => setIsHovered?.(true)}
        onMouseLeave={() => setIsHovered?.(false)}
        className={isActive ? 'is-active' : ''}
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '0 16px',
          minHeight: '40px',
          '&:hover, &.is-active': {
            cursor: 'pointer',
            backgroundColor: colors.grey[150],
          },
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            padding: '8px 0',
            borderBottom: `1px solid ${colors.grey[300]}`,
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};

export default AiAgentListItemLayout;
