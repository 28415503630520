import { Permission } from '@server/permissions';
import usePermission from '../common/permissions/usePermission';
import ConfirmDestructiveDialog from '../common/dialogs/ConfirmDestructiveDialog';
import { humanizeDate } from '../helpers/humanizeDate';
import { Commands } from '../../types/enums/Common';
import { ActionsMenu } from '../common/actions-menu/ActionsMenu';
import useConfirm from '../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import UserNameForTable from '../common/UserNameForTable';
import { DataTable } from '../common/table/DataTable';
import { DataTableRow, SortCompareTypes } from '../common/table/TableTypes';
import { sortByLastName } from '../../utils/sortByLastName';
import { ReactNode } from 'react';
import { SortOrder } from '../common/types';
import { sortByDate } from '../../utils/sortByDate';
import keyBy from 'lodash/keyBy';
import { useSelector } from 'react-redux';
import { selectAccountUsers } from '../../reducers/accountUsersReducer';
import { Typography } from '@sweep-io/sweep-design';
import { TableFilteredEmptyState } from '../common/TableFilteredEmptyState';
import { ACTION_BUTTONS_KEY } from '../common/table/utils';

interface TerritoryRow extends DataTableRow {
  name: ReactNode;
  updatedAt: ReactNode;
  updatedBy: ReactNode;
  updatedByName?: string;
  territory: Territory;
  [ACTION_BUTTONS_KEY]: ReactNode;
}

const columns = [
  {
    field: 'name',
    headerName: 'Territory name',
    showSortIcon: true,
    sortCompare: {
      type: SortCompareTypes.Custom,
      compareFunction: (territoryA: TerritoryRow, territoryB: TerritoryRow, order: SortOrder) => {
        const aVal = territoryA.territory.name;
        const bVal = territoryB.territory.name;
        return order === SortOrder.ASC ? aVal.localeCompare(bVal) : bVal.localeCompare(aVal);
      },
    },
    width: '30%',
  },
  {
    field: 'updatedAt',
    headerName: 'Last updated',
    showSortIcon: true,
    sortCompare: {
      type: SortCompareTypes.Custom,
      compareFunction: (territoryA: TerritoryRow, territoryB: TerritoryRow, order: SortOrder) =>
        sortByDate(
          territoryA.territory.updatedAt ?? '',
          territoryB.territory.updatedAt ?? '',
          order,
        ),
    },
    width: '30%',
  },
  {
    field: 'updatedBy',
    headerName: 'Last updated by',
    showSortIcon: true,
    sortCompare: {
      type: SortCompareTypes.Custom,
      compareFunction: (territoryA: TerritoryRow, territoryB: TerritoryRow, order: SortOrder) =>
        sortByLastName(territoryA.updatedByName ?? '', territoryB.updatedByName ?? '', order),
    },
    width: '30%',
  },
];

const TerritoriesTable = ({
  territories,
  onEdit,
  onDuplicate,
  onDelete,
  onClearFilters,
}: {
  territories: Territory[];
  onEdit: (territory: Territory) => void;
  onDuplicate: (territory: Territory) => void;
  onDelete: (territory: Territory) => void;
  onClearFilters: () => void;
}) => {
  const permissionStr: Permission[] = [
    'create:territories',
    'edit:territories',
    'delete:territories',
  ];
  const [isAllowedBtnCreate, isAllowedBtnEdit, isAllowedBtnDelete] = usePermission(permissionStr);
  const { openConfirm, onCancel, onConfirm } = useConfirm();
  const accountUsers = keyBy(useSelector(selectAccountUsers), 'id');

  const menuActions = [
    {
      label: Commands.Edit,
      value: Commands.Edit,
      disabled: !isAllowedBtnEdit,
    },
    {
      label: Commands.Duplicate,
      value: Commands.Duplicate,
      disabled: !isAllowedBtnCreate,
    },
    {
      label: Commands.Delete,
      value: Commands.Delete,
      disabled: !isAllowedBtnDelete,
    },
  ];

  const onMenuActionClick = (territory: Territory) => async (actionName: string) => {
    switch (actionName) {
      case Commands.Edit:
        onEdit(territory);
        break;
      case Commands.Duplicate:
        onDuplicate(territory);
        break;
      case Commands.Delete:
        const isConfirmed = await openConfirm(
          <ConfirmDestructiveDialog
            open={true}
            onConfirm={onConfirm}
            onClose={onCancel}
            title="Delete Territory?"
            confirmText="Delete"
            cancelText="Cancel"
          >
            Are you sure you want to delete "{territory.name}"?
          </ConfirmDestructiveDialog>,
        );
        if (!isConfirmed) return;
        onDelete(territory);
        break;
    }
  };

  const rows = territories.map((territory: Territory) => {
    const user = territory?.updatedById ? accountUsers[territory.updatedById] : undefined;

    return {
      id: territory.id,
      name: <Typography variant="body">{territory.name}</Typography>,
      updatedAt: humanizeDate({
        dateOrTimestamp: territory.updatedAt ?? '',
      }),
      updatedBy: territory.updatedById ? <UserNameForTable userId={territory.updatedById} /> : '',
      updatedByName: user?.name,
      territory,
      [ACTION_BUTTONS_KEY]: (
        <ActionsMenu
          actions={menuActions}
          onClick={(action) => onMenuActionClick(territory)(action.value)}
          iconVariant="flat"
        />
      ),
    };
  });

  return (
    <DataTable
      columns={columns}
      rows={rows}
      stickyHeader
      onRowClick={(e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, row: TerritoryRow) =>
        onEdit(row.territory)
      }
      defaultSortState={{
        sortBy: 'name',
        sortOrder: SortOrder.ASC,
      }}
      tableEmptyStateJsx={
        <TableFilteredEmptyState clearFilters={onClearFilters} isFiltered={true} />
      }
    />
  );
};

export default TerritoriesTable;
