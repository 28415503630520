import {
  SalesforceLoginHistoryItem,
  LoginHistorySortByKeys,
} from '@server/salesforce-login-history';
import { DateKeys } from '../../../common/datepicker/types';
import { SortOrder } from '../../../common/types';
import { humanizeDate, humanizeDateVariants } from '../../../helpers/humanizeDate';

export const DEFAULT_SORT_KEY = SortOrder.DESC;
export const DEFAULT_SORT_BY = 'loginTime' as LoginHistorySortByKeys;
export const DEFAULT_DATEPICKER_STATE = DateKeys.Last28Days;

const WIDE_WIDTH = '190px';
const MEDIUM_WIDTH = '162px';
const SMALL_WIDTH = '142px';

export const columns = [
  { field: 'sfUsername', headerName: 'Username', width: MEDIUM_WIDTH },
  { field: 'loginTime', headerName: 'Login Time', width: MEDIUM_WIDTH, showSortIcon: true },
  { field: 'sourceIp', headerName: 'Source IP', width: MEDIUM_WIDTH, showSortIcon: true },
  { field: 'countryIso', headerName: 'Location', width: MEDIUM_WIDTH, showSortIcon: true },
  { field: 'loginType', headerName: 'Login Type', width: MEDIUM_WIDTH, showSortIcon: true },
  { field: 'status', headerName: 'Status', width: SMALL_WIDTH, showSortIcon: true },
  { field: 'browser', headerName: 'Browser', width: MEDIUM_WIDTH, showSortIcon: true },
  { field: 'platform', headerName: 'Platform', width: SMALL_WIDTH, showSortIcon: true },
  { field: 'application', headerName: 'Application', width: WIDE_WIDTH, showSortIcon: true },
  { field: 'clientVersion', headerName: 'Client Version', width: SMALL_WIDTH, showSortIcon: true },
  { field: 'apiType', headerName: 'Api Type', width: SMALL_WIDTH, showSortIcon: true },
  { field: 'apiVersion', headerName: 'Api Version', width: SMALL_WIDTH, showSortIcon: true },
  { field: 'loginUrl', headerName: 'Login URL', width: WIDE_WIDTH, showSortIcon: true },
  { field: 'httpMethod', headerName: 'HTTP Method', width: SMALL_WIDTH },
];

export const parseRows = (rows: SalesforceLoginHistoryItem[]) => {
  return (
    rows?.map((row) => {
      return {
        ...row,
        loginTime: humanizeDate({
          dateOrTimestamp: row.loginTime as any,
          variant: humanizeDateVariants.TWO_DIGITS_WITH_TIME,
        }),
        countryIso: row.country,
        id: row.sfId + row.crmOrgId,
        isClickable: false,
        apiType: row.apiType ?? 'N/A',
      };
    }) ?? []
  );
};
