import { NodePositionChange } from '@xyflow/react';
import { useCallback } from 'react';
import { CanvasElementType, SweepNodesChangeEvent, SweepNodesChangeType } from '../canvas-types';
import { RfNodeRegularNode, DropZoneNodes, SweepCanvasRfNode } from '../canvas-types/nodeTypesData';
import { snapPositionToGrid } from '../helpers/gridPositioningUtils';
import { ObjectOperations } from './objectOperations';
import { useCalculateDragAndDrop } from '../useCalculateDragAndDrop';
import { MovingNodesORiginalPositionRef, NodeTransformations } from './useOnNodeChange';

import { updateGroupBoundingBox } from './updateGroupBoundingBox';
import { getNormalizedGroupPositions } from '../normalizeGroupPositions';

const DROPPING_ZONE_NODE_ID = 'dropping-zone-node';

/**
 * Hook to handle position changes for regular nodes (non-group nodes)
 * Handles both dragging and dropping of nodes within groups
 */
export const useOnRegularNodePositionChange = (
  movingNodes: React.MutableRefObject<MovingNodesORiginalPositionRef>,
) => {
  const { getDropInvalidReason } = useCalculateDragAndDrop();

  const onRegularNodePositionChange = useCallback(
    ({
      nodeChange,
      node,
      nodeOperations,
    }: {
      nodeChange: NodePositionChange;
      node: RfNodeRegularNode;
      nodeTransformations: NodeTransformations; // passed by reference for performance
      nodeOperations: ObjectOperations<SweepCanvasRfNode>; // passed by reference for performance
    }) => {
      const nodeChangeEvents: SweepNodesChangeEvent[] = [];
      // Early return if no position change or node has no parent
      if (!nodeChange.position || !node.parentId) return;

      const {
        setObject: setNode,
        removeObject: removeNode,
        hasObject: hasNode,
        addObject: addNode,
      } = nodeOperations;

      // Check if drop position is valid
      const dropInvalidReason = getDropInvalidReason(nodeChange.position, nodeChange.id);

      if (nodeChange.dragging) {
        // While dragging, update node and group visuals

        // Update node with any drop validation feedback
        setNode<RfNodeRegularNode>(node.id, (node) => ({
          ...node,
          data: {
            ...node.data,
            dropInvalidReason,
          },
        }));

        const updatedGroupDimensions = updateGroupBoundingBox({
          nodeOperations,
          groupNodeId: node.parentId,
        });
        if (!updatedGroupDimensions) return;
        const { updatedGroupNodeBoundaries } = updatedGroupDimensions;

        // Add the drop zone node to the node transformations if it doesn't exist
        const dropZoneNodeXYPosition = dropInvalidReason
          ? movingNodes.current[node.id].originalPosition
          : snapPositionToGrid(nodeChange.position);

        if (!hasNode(DROPPING_ZONE_NODE_ID)) {
          addNode({
            id: DROPPING_ZONE_NODE_ID,
            position: dropZoneNodeXYPosition,
            parentId: node.parentId,
            data: {
              dropZoneType: CanvasElementType.REGULAR,
              nodeBoundaries: updatedGroupNodeBoundaries,
            },
            type: CanvasElementType.DROP_ZONE_NODE,
          });
        } else {
          // Update the drop zone node position in nodeOverrides

          setNode<DropZoneNodes>(DROPPING_ZONE_NODE_ID, (node) => ({
            ...node,
            position: dropZoneNodeXYPosition,
            data: {
              ...node.data,
              dropZoneType: CanvasElementType.REGULAR,
              nodeBoundaries: updatedGroupNodeBoundaries,

              // positions: {
              //   sweepCanvasPosition: convertXYPositionToGridIndex(dropZoneNodeXYPosition),
              // },
            },
          }));
        }
      } else {
        // On drop
        if (dropInvalidReason) {
          // If invalid drop location, reset to original position
          nodeChange.position = movingNodes.current[node.id].originalPosition;
          setNode<RfNodeRegularNode>(node.id, (node) => {
            return {
              ...node,
              position: nodeChange.position!,
              data: {
                ...node.data,
                dropInvalidReason: null,
              },
            };
          });
          removeNode(DROPPING_ZONE_NODE_ID);
        } else {
          if (!nodeChange.position) return;

          const { normalizedGroupPosition, normalizedStepPositions } = getNormalizedGroupPositions({
            getNode: (id) => nodeOperations.getObject(id),
            newNodePosition: nodeChange.position,
            nodeId: node.id,
            parentNodeId: node.parentId,
            nodes: nodeOperations.getObjects(),
            isPreview: false,
          });

          nodeChangeEvents.push(
            {
              type: SweepNodesChangeType.MoveNode,
              change: {
                nodesToMove: normalizedStepPositions.map(({ id, position }) => ({
                  parentId: node.parentId as string,
                  nodeId: id,
                  newPosition: position,
                })),
              },
            },
            {
              type: SweepNodesChangeType.MoveGroup,
              change: {
                groupsToMove: [
                  {
                    nodeId: normalizedGroupPosition.id,
                    newPosition: normalizedGroupPosition.position,
                  },
                ],
              },
            },
          );

          // Reset moving nodes tracking
          movingNodes.current = {};
        }
      }
      return nodeChangeEvents;
    },
    [getDropInvalidReason, movingNodes],
  );

  return {
    onRegularNodePositionChange,
  };
};
