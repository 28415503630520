import { Box, MenuItem, SelectChangeEvent, Stack, TextField } from '@mui/material';
import { Button, colors, Typography } from '@sweep-io/sweep-design';
import SweepSelect from '../common/SweepSelect';
import { HttpVerb, WebhookBodyType } from '../../types/enums/AutomationTypes';
import { MandatoryCircle } from '@sweep-io/sweep-design/dist/icons';
import { InputWithVariables } from './InputWithVariables';
import { useSelector } from 'react-redux';
import { selectCustomIntegrations } from '../../reducers/integrationsReducer';
import { KeyValueTable } from './KeyValueTable';
import { useCallback, useState, useEffect, ChangeEvent, useRef } from 'react';
import { useRunOnce } from '../common/useRunOnce';
import { uniqueId } from '../../lib/uniqueId';
import { labelBoxDesign } from './AutomationsForm';
import { ActionsMenu } from '../common/actions-menu/ActionsMenu';
import ConditionalActionsEditor from './ConditionalActionsEditor';
import { useSweepApi } from '../../apis/sweep';
import { TruncatedTextTooltip } from '../common/TruncatedTextTooltip';
import { useFeatureToggle } from '../common/useFeatureToggle';
interface WebhookActionWrapperProps {
  readonly?: boolean;
  action: WebhookAutomationAction;
  crmOrgId: string;
  onChange: (action: WebhookAutomationAction) => any;
  objectName: string;
  objectTypes?: ObjectTypeName[];
}

export interface WebhookTestResult {
  data?: {
    rawRequest: string;
    rawResponse: string;
    webhookResult: { key: string; value: string }[];
  };
  errors?: {
    message: string;
    code: string;
  }[];
}

const WebhookBodyTypeList = [
  {
    value: WebhookBodyType.JSON,
    label: 'JSON',
  },
  {
    value: WebhookBodyType.URL_ENCODED,
    label: 'X-www-form-ulencodded',
  },
  {
    value: 'NONE',
    label: 'None',
  },
];

const responseBoxStyle = {
  backgroundColor: colors.white,
  border: `1px solid ${colors.grey[300]}`,
  padding: 2,
  borderRadius: 1,
  whiteSpace: 'pre-wrap',
  fontFamily: 'monospace',
  fontSize: '12px',
  overflow: 'auto',
  maxHeight: '300px',
};

export const onKeyDownDisableSpaces = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.key === ' ' || event.key === 'Tab' || event.key === 'Enter') {
    event.preventDefault();
  }
};

export const WebhookActionWrapper = ({
  action,
  crmOrgId,
  objectName,
  onChange,
  readonly,
  objectTypes,
}: WebhookActionWrapperProps) => {
  const { webhookActivity } = useFeatureToggle();
  const responseBoxRef = useRef<HTMLDivElement>(null);
  const sweepApi = useSweepApi();
  const [isFollowUpActionOpen, setIsFollowUpActionOpen] = useState(
    (action?.actionParams?.followUpActions?.[0]?.actions?.[0] as ConditionalActionsAutomationAction)
      ?.actionParams?.prioritizedActions?.length > 0,
  );
  const getSfParams = useCallback(() => {
    const _params = [
      // Get fieldIds from path variables
      ...(action?.actionParams?.path?.variables?.flatMap(
        (variable: { fieldIds: string[] }) => variable.fieldIds,
      ) ?? []),
      // Get fieldIds from query params
      ...(action?.actionParams?.queryParams?.flatMap(
        (param: KeyValueStringTemplate) => param.value.variables?.flatMap((v) => v.fieldIds) ?? [],
      ) ?? []),
      // Get fieldIds from headers
      ...(action?.actionParams?.headers?.flatMap(
        (header: KeyValueStringTemplate) =>
          header.value.variables?.flatMap((v) => v.fieldIds) ?? [],
      ) ?? []),
      // Get fieldIds from body if it exists
      ...(action?.actionParams?.body?.type === WebhookBodyType.JSON
        ? ((action?.actionParams?.body as WebhookJsonBody)?.value?.variables?.flatMap(
            (variable: { fieldIds: string[] }) => variable.fieldIds,
          ) ?? [])
        : []),
      // Get fieldIds from URL encoded body if it exists
      ...(action?.actionParams?.body?.type === WebhookBodyType.URL_ENCODED
        ? ((action?.actionParams?.body as WebhookUrlencodedBody)?.value?.flatMap(
            (param: KeyValueStringTemplate) =>
              param.value.variables?.flatMap((v) => v.fieldIds) ?? [],
          ) ?? [])
        : []),
    ];
    // Create a Map with fieldIds as keys and empty strings as initial values
    return new Map([...new Set(_params)].map((fieldId) => [fieldId, '']));
  }, [action]);

  const [sfParamsList, setSfParamsList] = useState<Map<string, string>>(getSfParams());

  useEffect(() => {
    setSfParamsList(getSfParams());
  }, [action, getSfParams]);

  const handleFieldValueChange = (fieldId: string, value: string) => {
    setSfParamsList((prev) => new Map(prev).set(fieldId, value));
  };

  const [isTestIntegrationOpen, setIsTestIntegrationOpen] = useState(false);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [isShowTestResponse, setIsShowTestResponse] = useState(false);
  const [testResponse, setTestResponse] = useState<WebhookTestResult>({
    data: {
      rawRequest: '',
      rawResponse: '',
      webhookResult: [],
    },
    errors: [],
  });
  const customIntegrations = useSelector(selectCustomIntegrations);
  const integrationSelected = customIntegrations?.find(
    (el) => el.id === action?.actionParams?.integrationId,
  );

  useRunOnce(async () => {
    onChange({
      ...action,
      actionParams: {
        ...(action as WebhookAutomationAction).actionParams,
        queryParams: action?.actionParams?.queryParams ?? [],
        headers: action?.actionParams?.headers ?? [],
        followUpActions: action?.actionParams?.followUpActions ?? [],
      },
    });
  });

  const handleChangeAction = useCallback(
    (prop: string, value: any) => {
      onChange({
        ...action,
        actionParams: {
          ...(action as WebhookAutomationAction).actionParams,
          [prop]: value,
        },
      });
    },
    [action, onChange],
  );

  const bodyJsonObject = {
    type: WebhookBodyType.JSON,
    value: { template: '', variables: [] },
  };
  const bodyUrlObject = {
    type: WebhookBodyType.URL_ENCODED,
    value: [
      {
        key: '',
        value: { template: '', variables: [] },
      },
    ],
  };
  const getTestResponse = async (): Promise<WebhookTestResult> => {
    const params = Array.from(sfParamsList.entries()).map(([key, value]) => ({
      name: key,
      value,
    }));
    return new Promise(async (resolve) => {
      const response = await sweepApi.post(`/data-integrations/webhook/test`, {
        fields: params,
        action,
      });
      resolve(response.data as WebhookTestResult);
    });
  };

  return (
    <Stack spacing={3} mt={3}>
      <Stack direction="row" spacing={'8px'} alignItems={'center'}>
        <Typography variant="body-medium" textAlign="center">
          Method
        </Typography>
        <MandatoryCircle />
        <SweepSelect
          FormControlProps={{
            sx: { minWidth: '120px' },
          }}
          menuMaxHeight={'360px'}
          SelectProps={{
            placeholder: 'Choose',
            disabled: readonly,
            value: action?.actionParams?.verb ?? '',
            onChange: (event: SelectChangeEvent<unknown>) => {
              const val = event.target.value as HttpVerb;
              handleChangeAction('verb', val);
            },
          }}
        >
          {Object.keys(HttpVerb).map((Item) => {
            return (
              <MenuItem key={Item} value={Item.toUpperCase()}>
                {Item}
              </MenuItem>
            );
          })}
        </SweepSelect>
      </Stack>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Typography variant="body-medium" textAlign="center">
          Path
        </Typography>
        <MandatoryCircle />
        <Typography variant="body" textAlign="center">
          {integrationSelected?.baseUrl ?? 'https.www.snowflake.com/'}
        </Typography>
        <Box
          sx={{
            width: '100%',
            '.ql-container .ql-editor': {
              borderRadius: '4px',
            },
          }}
        >
          <InputWithVariables
            isPreventLineBreaks={true}
            customOnKeyDown={onKeyDownDisableSpaces}
            crmOrgId={crmOrgId}
            objectName={objectName}
            withBorder={true}
            data={action?.actionParams?.path}
            placeholder={'examples: verify-url, enrichment/phone'}
            onChange={(newMsg: SlackMessageStruct) => {
              handleChangeAction('path', newMsg);
            }}
          />
        </Box>
      </Box>
      <Stack spacing={'12px'}>
        <Typography variant="body-medium">Query params</Typography>
        <KeyValueTable
          keyString="queryParams"
          crmOrgId={crmOrgId}
          objectName={objectName}
          params={action?.actionParams?.queryParams ?? []}
          onChange={(params) => {
            handleChangeAction('queryParams', params);
          }}
        />
      </Stack>

      <Stack spacing={'12px'}>
        <Typography variant="body-medium">Headers</Typography>
        <KeyValueTable
          keyString="headers"
          crmOrgId={crmOrgId}
          objectName={objectName}
          params={action?.actionParams?.headers ?? []}
          onChange={(params) => {
            handleChangeAction('headers', params);
          }}
        />
      </Stack>

      <Stack direction="row" spacing={'8px'} alignItems={'center'}>
        <Typography variant="body-medium" textAlign="center">
          Body
        </Typography>
        <SweepSelect
          FormControlProps={{
            sx: { minWidth: '120px' },
          }}
          menuMaxHeight={'360px'}
          SelectProps={{
            placeholder: 'Choose method',
            disabled: readonly,
            value: action?.actionParams?.body?.type ?? '',
            onChange: (event: SelectChangeEvent<unknown>) => {
              const val = event.target.value as string;
              if (val === 'NONE') {
                handleChangeAction('body', undefined);
              } else {
                const elem = val === WebhookBodyType.JSON ? bodyJsonObject : bodyUrlObject;
                handleChangeAction('body', elem);
              }
            },
          }}
        >
          {WebhookBodyTypeList.map((item) => {
            return (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </SweepSelect>
      </Stack>
      {action?.actionParams?.body?.type === WebhookBodyType.JSON && (
        <Box>
          <Box
            sx={{
              width: '100%',
              '.ql-container .ql-editor': {
                borderRadius: '4px',
              },
            }}
          >
            <InputWithVariables
              variableButtonSX={{ bottom: '1px', left: '4px', top: 'auto' }}
              isShowFieldSelectorAlways={true}
              isBigInput={true}
              crmOrgId={crmOrgId}
              objectName={objectName}
              withBorder={true}
              data={(action?.actionParams?.body as WebhookJsonBody)?.value}
              placeholder={'Insert JSON file here'}
              onChange={(newMsg: SlackMessageStruct) => {
                const _obj = {
                  type: WebhookBodyType.JSON,
                  value: newMsg,
                };
                handleChangeAction('body', _obj);
              }}
            />
          </Box>
        </Box>
      )}
      {action?.actionParams?.body?.type === WebhookBodyType.URL_ENCODED && (
        <Stack spacing={'12px'}>
          <KeyValueTable
            keyString="body"
            crmOrgId={crmOrgId}
            objectName={objectName}
            params={(action?.actionParams?.body as WebhookUrlencodedBody)?.value ?? []}
            onChange={(params) => {
              const _obj = {
                type: WebhookBodyType.URL_ENCODED,
                value: params,
              };
              handleChangeAction('body', _obj);
            }}
          />
        </Stack>
      )}
      {!isFollowUpActionOpen && (
        <Box>
          <Button
            onClick={() => {
              setIsFollowUpActionOpen(true);
            }}
            startIconName="Plus"
            variant="flat"
          >
            Use response in follow up action
          </Button>
        </Box>
      )}
      {isFollowUpActionOpen && (
        <Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Box
              sx={{
                ...labelBoxDesign,
                background: colors.storm[400],
              }}
            >
              <Typography variant="caption-bold" textAlign="center" color={colors.white}>
                And use response in follow up actions
              </Typography>
            </Box>
            <ActionsMenu
              iconSize={'tiny'}
              actions={[
                {
                  label: 'Remove',
                  value: 'clear',
                },
              ]}
              onClick={(action) => {
                switch (action.value) {
                  case 'clear':
                    handleChangeAction('followUpActions', []);
                    setIsFollowUpActionOpen(false);
                    break;
                }
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
            <Typography variant="body">
              Set up branches using the response, and execute the first action that meets the
              criteria:
            </Typography>
            <ConditionalActionsEditor
              isShowWebhookOption={true}
              objectTypes={objectTypes}
              conditionalActions={
                (
                  action?.actionParams?.followUpActions?.[0]
                    ?.actions?.[0] as ConditionalActionsAutomationAction
                )?.actionParams?.prioritizedActions ?? []
              }
              onChange={(prioritizedActions) => {
                const _inner: ConditionalActionsAutomationAction = {
                  _id:
                    (
                      action?.actionParams?.followUpActions?.[0]
                        ?.actions?.[0] as ConditionalActionsAutomationAction
                    )?._id ?? uniqueId(),
                  actionType: 'PRIORITIZED_ACTIONS',
                  actionParams: {
                    prioritizedActions: prioritizedActions,
                  },
                };

                const _tmpAction: AutomationConditionalAction = {
                  _id: action?.actionParams?.followUpActions?.[0]?._id ?? uniqueId(),
                  _name: 'AutomationConditionalAction',
                  actions: [_inner],
                };

                handleChangeAction('followUpActions', [_tmpAction]);
              }}
              crmOrgId={crmOrgId}
              objectName={objectName}
              readonly={readonly}
            />
          </Box>
        </Box>
      )}
      {webhookActivity && (
        <Button
          endIconName={isTestIntegrationOpen ? 'ChevronUp' : 'ChevronDown'}
          onClick={() => {
            setIsTestIntegrationOpen(!isTestIntegrationOpen);
          }}
          size="large"
          type="secondary"
          variant="flat"
        >
          Test integration
        </Button>
      )}
      {isTestIntegrationOpen && (
        <Box
          sx={{
            backgroundColor: colors.grey[100],
            padding: 2,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Typography variant="body">
            To test the integration, insert temporary values for the following Salesforce fields:
          </Typography>
          <Box display="flex" flexDirection="column" gap={2}>
            {Array.from(sfParamsList.entries()).map(([fieldId]) => (
              <Box key={fieldId} display="flex" gap={2} alignItems="center">
                <Box
                  sx={{
                    minWidth: '200px',
                    borderBottom: `1px solid ${colors.grey[200]}`,
                    lineHeight: '40px',
                  }}
                >
                  <TruncatedTextTooltip title={fieldId}>
                    <Typography variant="body">{fieldId}</Typography>
                  </TruncatedTextTooltip>
                </Box>
                <TextField
                  fullWidth
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFieldValueChange(fieldId, event.target.value)
                  }
                />
              </Box>
            ))}
          </Box>
          <Box display="flex" gap={2} alignItems="center" justifyContent="space-between">
            <Typography variant="caption" color={colors.grey[700]}>
              For ensuring the automation is configured correctly Sweep will call the API. If you're
              not interested in
              <br />
              preserving the outcome of the action, make sure to manually revert it in the external
              integration.
            </Typography>
            <Button
              size="large"
              loading={isWaitingForResponse}
              onClick={async () => {
                setIsWaitingForResponse(true);
                const response = await getTestResponse();
                setTestResponse(response);
                setIsWaitingForResponse(false);
                setIsShowTestResponse(true);
                // Add a small delay before scrolling to ensure the response box is rendered
                setTimeout(() => {
                  responseBoxRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }, 100);
              }}
            >
              Test integration
            </Button>
          </Box>
          {testResponse?.errors && testResponse?.errors?.length > 0 && (
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography variant="body">Response</Typography>
              <Box
                ref={responseBoxRef}
                sx={{ ...responseBoxStyle, border: `1px solid ${colors.blush[600]}` }}
              >
                {testResponse.errors.map((error) => (
                  <Typography key={error.code} variant="body">
                    {error.message}
                  </Typography>
                ))}
              </Box>
            </Box>
          )}

          {isShowTestResponse && testResponse?.data?.rawRequest && (
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography variant="body">Request</Typography>
              <Box ref={responseBoxRef} sx={responseBoxStyle}>
                {testResponse.data.rawRequest}
              </Box>
            </Box>
          )}
          {isShowTestResponse && testResponse?.data?.rawResponse && (
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography variant="body">Response</Typography>
              <Box ref={responseBoxRef} sx={responseBoxStyle}>
                {testResponse.data.rawResponse}
              </Box>
            </Box>
          )}
          {isShowTestResponse &&
            testResponse?.data?.webhookResult &&
            testResponse?.data?.webhookResult?.length > 0 && (
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="body">Parameters</Typography>
                <Box ref={responseBoxRef} sx={responseBoxStyle}>
                  {testResponse.data.webhookResult.map((param) => (
                    <Box key={param.key}>
                      <Typography variant="body">{`${param.key}: ${param.value}`}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
        </Box>
      )}
    </Stack>
  );
};
