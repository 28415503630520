import { useCallback, useRef, useState } from 'react';
import {
  createObjectOperations,
  ObjectOperations,
  ObjectWithId,
} from '../node-changes-event-handlers/objectOperations';

export type SetStateActionWithObjectOperation<S extends ObjectWithId> =
  | S[]
  | ((prevState: S[], objectOperations: ObjectOperations<S>) => S[]);

export function useStateWithGetter<T extends ObjectWithId>(initialState: T[]) {
  const [state, _setState] = useState<T[]>(initialState);
  // Use a ref to store the latest state
  const stateRef = useRef(state);

  // Update the ref whenever state changes
  stateRef.current = state;

  // Getter function that always returns current state
  const getState = useCallback(() => stateRef.current, []);

  // Custom setState that provides object operations as second param to callback
  const setState = useCallback((action: SetStateActionWithObjectOperation<T>) => {
    _setState((prevState) => {
      if (action instanceof Function) {
        let ops: ObjectOperations<T>;
        return action(
          prevState,
          new Proxy({} as ObjectOperations<T>, {
            get: (_, prop) => {
              if (!ops) {
                ops = createObjectOperations(prevState);
              }
              return ops[prop as keyof ObjectOperations<T>];
            },
          }),
        );
      }
      return action;
    });
  }, []);

  return [state, setState, getState] as const;
}
