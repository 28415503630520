import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useRegularNodesFactory } from './factories/useRegularNodesFactory';
import { useGroupNodesFactory } from './factories/useGroupNodesFactory';
import { useSweepCanvasPropsCtx } from './internal-context/SweepCanvasPropsCtx';
import { useSweepCanvasState } from './internal-context/CanvasStateContext';
import { useEdgesFactory } from './factories/useEdgesFactory';
import { CanvasElementType } from './canvas-types';

export const useLoadNodesAndEdges = () => {
  const { sweepNodes, sweepEdges, sweepGroups } = useSweepCanvasPropsCtx();
  const stateInitialized = useRef(false);

  const {
    canvasNodes: _nodes,
    canvasEdges: _edges,
    setCanvasNodes,
    setCanvasEdges,
  } = useSweepCanvasState();

  const { createFlowNodesFromSweepNodes } = useRegularNodesFactory();
  const { calculateGroupNodes } = useGroupNodesFactory();
  const { calculateEdges } = useEdgesFactory();

  const calculateNodes = useCallback(() => {
    const { groupNodes, groupLabelNodes, groupOverlayNodes } = calculateGroupNodes({
      sweepNodes,
      sweepGroups,
    });

    const regularNodes = createFlowNodesFromSweepNodes({
      sweepNodes,
      sweepEdges,
      sweepGroupIds: sweepGroups.map(({ id }) => id),
    });
    return {
      allNodes: [...groupNodes, ...regularNodes, ...groupLabelNodes, ...groupOverlayNodes],
      regularNodes,
    };
  }, [calculateGroupNodes, sweepNodes, sweepGroups, createFlowNodesFromSweepNodes, sweepEdges]);

  const mergeNodes = useCallback(() => {
    setCanvasNodes((_nodes, nodeOperations) => {
      const {
        getObject: getNode,
        addObject: addNode,
        setObject: setNode,
        getObjects: getNodes,
        removeObject: removeNode,
      } = nodeOperations;
      const { allNodes: newNodes } = calculateNodes();

      newNodes.forEach((node) => {
        const existingNode = getNode(node.id);
        if (existingNode) {
          setNode(node.id, {
            ...existingNode,
            ...node,
            data: { ...existingNode.data, ...node.data },
          } as any);
        } else {
          addNode(node);
        }
      });

      _nodes.forEach((node) => {
        if (!newNodes.some((n) => n.id === node.id)) {
          removeNode(node.id);
        }
      });
      // Removes regular nodes with no parents
      getNodes().forEach((node) => {
        if (node.type === CanvasElementType.REGULAR && !node.parentId) {
          removeNode(node.id);
        }
        if (node.parentId && !getNode(node.parentId)) {
          removeNode(node.id);
        }
      });

      return getNodes();
    });
  }, [calculateNodes, setCanvasNodes]);

  const { nodes, edges, regularNodes } = useMemo(() => {
    if (stateInitialized.current) {
      return { nodes: _nodes, edges: _edges, regularNodes: [] };
    }

    const { allNodes, regularNodes: regularNodesForFitView } = calculateNodes();
    const edges = calculateEdges();

    // The fit view function gets messed up if we pass all the node, so we need regularNodesForFitView
    return { nodes: allNodes, edges, regularNodes: regularNodesForFitView };
  }, [_edges, _nodes, calculateEdges, calculateNodes]);

  useEffect(() => {
    mergeNodes();
  }, [mergeNodes]);

  useEffect(() => {
    setCanvasEdges(calculateEdges());
    stateInitialized.current = true;
  }, [calculateEdges, setCanvasEdges]);

  return { nodes, edges, regularNodes };
};
