export interface ObjectWithId {
  id: string;
}
export type ObjectsWithId = ObjectWithId[];

export function createObjectOperations<MainT extends ObjectWithId>(_nodes: MainT[]) {
  const nodes = [..._nodes];
  const nodesIndexMap = new Map<string, MainT>();
  nodes.forEach((node) => {
    nodesIndexMap.set(btoa(node.id), node);
  });

  function createObjectGetter() {
    return function <T extends MainT>(id: string) {
      return nodesIndexMap.get(btoa(id)) as T | undefined;
    };
  }

  function createObjectSetter() {
    return function <T extends MainT>(id: string, nodeOrUpdater: T | ((node: T) => T)) {
      const currentNode = nodesIndexMap.get(btoa(id));

      if (currentNode === undefined) return;

      if (typeof nodeOrUpdater === 'function') {
        nodesIndexMap.set(btoa(id), nodeOrUpdater(currentNode as T));
      } else {
        nodesIndexMap.set(btoa(id), nodeOrUpdater as MainT);
      }
    };
  }

  function createObjectRemover() {
    return function (id: string) {
      nodesIndexMap.delete(btoa(id));
    };
  }

  function createObjectAdder() {
    return function <T extends MainT>(node: T) {
      nodesIndexMap.set(btoa(node.id), node);
    };
  }

  return {
    getObject: createObjectGetter(),
    setObject: createObjectSetter(),
    removeObject: createObjectRemover(),
    addObject: createObjectAdder(),
    hasObject: (nodeId: string) => nodesIndexMap.has(btoa(nodeId)),
    getObjects: () => Array.from(nodesIndexMap.values()),
  };
}

export type ObjectOperations<MainT extends ObjectWithId> = ReturnType<
  typeof createObjectOperations<MainT>
>;
