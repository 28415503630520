import { UI_EVENTS } from '../../services/events';
import { AutomationType } from '../../types/enums/AutomationType';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import {
  AutomationsContextProvider,
  AutomationsContextType,
} from '../Automations/AutomationsContext';
import DrawerClockImage from '../common/empty-state/svgs/drawerClock.svg?react';
import { useMemo } from 'react';
import { useAutomationsApiFacade } from '../../apis/facades/useAutomationsApiFacade';

export const AutomationScheduledAssignmentsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { patch_assignments_admin_notification } = useAutomationsApiFacade();
  const contextValue: AutomationsContextType = useMemo(() => {
    return {
      automationType: AutomationType.ScheduledAssignment,
      permissions: {
        create: 'create:assignments',
        edit: 'edit:assignments',
        deploy: 'deploy:assignments',
        delete: 'delete:assignments',
        notifyAdmin: 'edit:assignments:notify-admin',
      },
      emptyStateTitle: 'Create your first Scheduled assignment',
      emptyStateImage: <DrawerClockImage />,
      defaultName: 'New Scheduled Assignment',
      placeholder: 'New Scheduled Assignment',
      nameInMessage: 'scheduled assignments',
      nameInMessageSingular: 'scheduled assignment',
      openEvent: UI_EVENTS.scheduledAssignmentsOpen,
      automationVariant: AutomationFormVariant.SCHEDULED_ASSIGNMENTS,
      templateSupport: false,
      emptyStateSubTitle: `to manage re-engagement and outbound campaigns, redistribute leads after an employee leaves the company and more`,
      requestDeployNotifyAdmin: patch_assignments_admin_notification,
      impactAnalysisAiSupport: true,
    };
  }, [patch_assignments_admin_notification]);

  return <AutomationsContextProvider value={contextValue}>{children}</AutomationsContextProvider>;
};
