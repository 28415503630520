import { Box, Stack } from '@mui/material';
import {
  Tooltip,
  Typography,
  __SWEEP_DESIGN_TYPOGRAPHY_STYLES___,
  colors,
} from '@sweep-io/sweep-design';
import { ReactNode, useRef, useState } from 'react';
import { isEllipsisActive } from '../../lib/isEllipsisActive';
import { SweepTypographyVariants } from '@sweep-io/sweep-design/dist/components/Typography/types';
import { getObjectTypeColor } from '../sweep-canvas/helpers/getObjectTypeColor';
import useObjectTypesWithFetch from '../../hooks/useObjectTypesWithFetch';
import { useSelector } from 'react-redux';
import { selectDefaultCreationCrmOrgId } from '../../reducers/userInfoReducer';

//sizes are based on "Tag" definitions
const HEIGHT = {
  tiny: '16px',
  small: '20px',
  medium: '24px',
};
const PADDING = {
  tiny: '0 2px',
  small: '0 4px',
  medium: '2px 4px', //to maintain existing style
};

const TYPOGRAPHY_VARIANT: { [key: string]: SweepTypographyVariants } = {
  tiny: 'caption',
  small: 'caption',
  medium: 'body',
};

//TODO use "Tag" component from design system
export const ObjectTypeLabelAndKeyTag = ({
  objectType,
  label,
  background,
  dataTestId,
  noObjectNameTooltip,
  startIcon,
  tagVariant = 'small',
  displayAsText,
}: {
  objectType: string;
  label?: ReactNode;
  background?: string;
  dataTestId?: string;
  noObjectNameTooltip?: boolean;
  startIcon?: ReactNode;
  tagVariant?: 'medium' | 'small' | 'tiny';
  displayAsText?: boolean;
}) => {
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId: crmOrgId ?? '' });
  const ref = useRef<HTMLSpanElement>(null);
  const _isEllipsisActive = isEllipsisActive(ref.current);
  const [showTooltip, setShowTooltip] = useState(false);

  const currentLabel = label ?? objectTypesByName[objectType]?.label ?? objectType;
  const _label = _isEllipsisActive ? `${currentLabel} (${objectType})` : objectType;
  const typographyVariant = TYPOGRAPHY_VARIANT[tagVariant];
  const padding = PADDING[tagVariant];
  const height = HEIGHT[tagVariant];

  if (displayAsText) {
    return (
      <Stack data-testid={dataTestId}>
        <Typography variant="body">{currentLabel}</Typography>
        <Typography variant="caption">API Name: {objectType}</Typography>
      </Stack>
    );
  }

  return (
    <Box
      data-testid={dataTestId}
      sx={{
        height,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        background: background ?? getObjectTypeColor(objectType)?.step,
        borderRadius: '2px',
      }}
      onMouseEnter={() => setShowTooltip(noObjectNameTooltip ? _isEllipsisActive : true)}
      onMouseLeave={() => setShowTooltip(false)}
      className="object-type-chip"
    >
      <Tooltip title={noObjectNameTooltip ? currentLabel : _label} open={!!showTooltip}>
        <Box
          ref={ref}
          component="div"
          sx={{
            padding,
            lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___[typographyVariant]?.lineHeight,
            fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___[typographyVariant]?.fontSize,
            fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___[typographyVariant]?.fontWeight,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',

            '& > span': { color: colors.grey[900] + '!important' },
          }}
        >
          {startIcon}
          <span>{currentLabel}</span>
        </Box>
      </Tooltip>
    </Box>
  );
};
