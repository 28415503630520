import { Box } from '@mui/material';
import { ChevronDown, ChevronUp } from '@sweep-io/sweep-design/dist/icons';
import { TruncatedTextTooltip } from '../TruncatedTextTooltip';
import { SortOrder } from '../types';
import { dataTableVariants } from './dataTableVariants';
import { TableRow as MuiTableRow, TableCell as MuiTableCell } from '@mui/material';
import {
  DataTableBaseColumn,
  DataTableRow,
  DataTableSortableColumn,
  DataTableSortStateProps,
  DataTableVariant,
} from './TableTypes';
import { colors } from '@sweep-io/sweep-design';
import { CellDivider } from './CellDivider';
import { RefObject, useRef } from 'react';

const stickyHeaderStyles = {
  zIndex: 3,
  position: 'sticky',
  top: '0px',
};

interface TableHeadProps<TRow extends DataTableRow> {
  columns: DataTableSortableColumn<TRow>[] | DataTableBaseColumn[];
  stickyHeader?: boolean;
  variant: DataTableVariant;
  sortState?: DataTableSortStateProps;
  sort: (columnName: string) => void;
}

export function TableHeadRow<TRow extends DataTableRow = any>({
  columns,
  stickyHeader,
  variant,
  sortState,
  sort,
}: TableHeadProps<TRow>) {
  const rowRef: RefObject<HTMLTableRowElement> = useRef(null);

  return (
    <MuiTableRow
      sx={{
        ...(stickyHeader ? stickyHeaderStyles : {}),
      }}
      ref={rowRef}
    >
      {columns.map((column, idx) => {
        const isSortCompare = 'sortCompare' in column && column.sortCompare;
        const isShowSortIcon = 'showSortIcon' in column && column.showSortIcon;
        const isSortable = isSortCompare || isShowSortIcon;

        return (
          <MuiTableCell
            sx={{
              padding: dataTableVariants[variant].cellPadding,
              cursor: isSortable ? 'pointer' : 'default',
              height: '40px', //design team asked for it, if your design doesn't match please talk to them
              minWidth: column.minWidth,
            }}
            onClick={() => isSortable && sort && sort(column.field)}
            key={column.field}
            className={column.className}
            data-testid={`data-table-header-${column.field}`}
          >
            <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                  flex: 1,
                  '& > .chevron-wrapper': {
                    opacity: sortState?.sortBy === column.field ? 1 : 0,
                    marginLeft: 1,
                  },
                  '&:hover .chevron-wrapper': { opacity: 1, transition: '.5s' },
                }}
              >
                <TruncatedTextTooltip
                  color={dataTableVariants[variant].headerFontColor}
                  variant={dataTableVariants[variant].headerFontVariant}
                >
                  {column.headerName}
                </TruncatedTextTooltip>

                {isShowSortIcon && (
                  <Box
                    data-testid="data-table-sort-icon"
                    width="16px"
                    height="16px"
                    className="chevron-wrapper"
                    display="flex"
                    alignItems="center"
                    sx={{ cursor: 'pointer' }}
                  >
                    {sortState?.sortBy === column.field &&
                    sortState?.sortOrder === SortOrder.ASC ? (
                      <ChevronDown variant="tiny" color={colors.blue[500]} />
                    ) : (
                      <ChevronUp variant="tiny" color={colors.blue[500]} />
                    )}
                  </Box>
                )}
                {!isShowSortIcon && <Box width="24px" />}
              </Box>
              {idx < columns.length - 1 && <CellDivider />}
            </Box>
          </MuiTableCell>
        );
      })}
      <MuiTableCell sx={{ p: 0 }} key="action-column" />
    </MuiTableRow>
  );
}
