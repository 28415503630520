import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { CustomWebhookInterface } from '../components/dashboard/webhooks/WebhooksContext';

export enum SlackConnectionStatus {
  Connected = 'connected',
  Error = 'error',
  InvalidCredentials = 'invalid_credentials',
  Initialization = 'initialization',
  NotInitialized = 'not_initialized',
}

export enum TeamsConnectionStatus {
  Connected = 'connected',
  Error = 'error',
  InvalidCredentials = 'invalid_credentials',
  AwaitingApproval = 'awaiting_approval',
  NotInitialized = 'not_initialized',
  Initialization = 'initialization',
}

export interface IntegrationsState {
  slackIntegrationStatus: SlackConnectionStatus;
  teamsIntegrationStatus: TeamsConnectionStatus;
  upgradeRequired?: boolean;
  includeSandboxes: boolean;
  customIntegrations: CustomWebhookInterface[];
}

const initialState: IntegrationsState = {
  slackIntegrationStatus: SlackConnectionStatus.NotInitialized,
  teamsIntegrationStatus: TeamsConnectionStatus.NotInitialized,
  upgradeRequired: false,
  includeSandboxes: false,
  customIntegrations: [],
};

export const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    setSlackIntegrationStatus: (state, action: PayloadAction<SlackConnectionStatus>) => {
      state.slackIntegrationStatus = action.payload;
    },
    setTeamsIntegrationStatus: (state, action: PayloadAction<TeamsConnectionStatus>) => {
      state.teamsIntegrationStatus = action.payload;
    },
    setSlackIntegrationFullStatus: (
      state,
      action: PayloadAction<{
        slackIntegrationStatus: SlackConnectionStatus;
        upgradeRequired: boolean;
        includeSandboxes: boolean;
      }>,
    ) => {
      state.slackIntegrationStatus = action.payload.slackIntegrationStatus;
      state.upgradeRequired = action.payload.upgradeRequired ?? false;
      state.includeSandboxes = action.payload.includeSandboxes ?? false;
    },
    setIncludeSandboxes: (state, action: PayloadAction<{ includeSandboxes: boolean }>) => {
      state.includeSandboxes = action.payload.includeSandboxes;
    },
    setCustomIntegrations: (state, action: PayloadAction<CustomWebhookInterface[]>) => {
      state.customIntegrations = action.payload;
    },
  },
});

export const {
  setSlackIntegrationStatus,
  setSlackIntegrationFullStatus,
  setIncludeSandboxes,
  setTeamsIntegrationStatus,
  setCustomIntegrations,
} = integrationsSlice.actions;

export const selectSlackIntegrationStatus = (state: RootState) =>
  state.integrations.slackIntegrationStatus;
export const selectSlackUpgradeStatus = (state: RootState) => state.integrations.upgradeRequired;
export const selectSlackIncludeSandboxes = (state: RootState) =>
  state.integrations.includeSandboxes;
export const selectCustomIntegrations = (state: RootState) => state.integrations.customIntegrations;
export const selectTeamsIntegrationStatus = (state: RootState) =>
  state.integrations.teamsIntegrationStatus;

export default integrationsSlice.reducer;
