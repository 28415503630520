import { VisibilityLayers } from '../../../types/enums/VisibilityLayers';
import { CanvasMode } from '../canvas-types';

const CONTEXT_ZOOM_LEVEL_1_CLASS_NAME = 'context-zoom-level-1';
const CONTEXT_ZOOM_LEVEL_1_5_CLASS_NAME = 'context-zoom-level-1-5';
export const CONTEXT_ZOOM_LEVEL_2_CLASS_NAME = 'context-zoom-level-2';
export const CONTEXT_ZOOM_DEFAULT_CLASS_NAME = 'context-zoom-default';

export const contextZoomTypeToClass: {
  [key: string]: string;
} = {
  ['LEVEL_1']: CONTEXT_ZOOM_LEVEL_1_CLASS_NAME,
  ['LEVEL1_5']: CONTEXT_ZOOM_LEVEL_1_5_CLASS_NAME,
  ['LEVEL2']: CONTEXT_ZOOM_LEVEL_2_CLASS_NAME,
  ['DEFAULT']: CONTEXT_ZOOM_DEFAULT_CLASS_NAME,
};

export const canvasModeToClass = {
  [CanvasMode.DEFAULT]: 'canvas-mode-default',
  [CanvasMode.PREVIEW1]: 'canvas-mode-preview1',
  [CanvasMode.PREVIEW2]: 'canvas-mode-preview2',
  [CanvasMode.PREVIEW3]: 'canvas-mode-preview3',
  [CanvasMode.FIT_NODES_PREVIEW]: 'canvas-mode-fit-nodes-preview',
};

export const visibilityMapClasses: { [key in VisibilityLayers]: string } = {
  [VisibilityLayers.GATES]: 'visibly-gates',
  [VisibilityLayers.VALIDATION_RULES]: 'visibly-validation-rules',
  [VisibilityLayers.SFDC_AUTOMATIONS]: 'visibly-sfdc-automations',
  [VisibilityLayers.APEX]: 'visibly-apex',
  [VisibilityLayers.COMMENTS]: 'visibly-comments',
  [VisibilityLayers.PLAYBOOK_ALERTS]: 'visibly-playbook-alerts',
  [VisibilityLayers.ASSIGNMENT_RULES]: 'visibly-assignment-rules',
  [VisibilityLayers.SCHEDULED_ASSIGNMENTS]: 'visibly-scheduled-assignments',
  [VisibilityLayers.FUNNEL_SFDC_AUTOMATIONS]: 'visibly-funnel-sfdc-automations',
  [VisibilityLayers.FUNNEL_VALIDATION_RULES]: 'visibly-funnel-validation-rules',
  [VisibilityLayers.FUNNEL_APEX]: 'visibly-funnel-apex',
  [VisibilityLayers.FUNNEL_ASSIGNMENT_RULES]: 'visibly-funnel-assignment-rules',
  [VisibilityLayers.FUNNEL_DEDUPLICATION]: 'visibly-funnel-deduplication',
  [VisibilityLayers.FUNNEL_MATCHING]: 'visibly-funnel-matching',
  [VisibilityLayers.FUNNEL_SWEEP_AUTOMATIONS]: 'visibly-funnel-sweep-automations',
  [VisibilityLayers.FUNNEL_PLAYBOOK_ALERTS]: 'visibly-funnel-playbook-alerts',
  [VisibilityLayers.FUNNEL_MATCHING_AND_DEDUPLICATION]: 'visibly-funnel-matching-and-deduplication',
  [VisibilityLayers.NURTURING_STEPS]: 'visibly-nurturing-steps',
  [VisibilityLayers.SWEEP_AUTOMATIONS]: 'visibly-sweep-automations',
  [VisibilityLayers.FUNNEL_HUBSPOT_ENROLLMENTS]: 'visibly-funnel-hubspot-enrollments',
};
