const EXPIRE_TOKEN_MS = 30 * 60000; // 30 Minutes
const TEAMS_STATE_TOKEN = 'TEAMS_STATE_TOKEN';

interface TeamsConnectionTransientData {
  randomId: string;
  expires: number;
}

const setTeamsStateToken = (randomId: string) => {
  const data: TeamsConnectionTransientData = {
    randomId,
    expires: Date.now() + EXPIRE_TOKEN_MS,
  };
  localStorage.setItem(TEAMS_STATE_TOKEN, JSON.stringify(data));
};

const getTeamsStateToken = () => {
  const teamsStateTokenData = localStorage.getItem(TEAMS_STATE_TOKEN);
  if (!teamsStateTokenData) return;
  try {
    const teamsStateToken = JSON.parse(teamsStateTokenData) as TeamsConnectionTransientData;

    if (teamsStateToken.expires > Date.now()) {
      return teamsStateToken.randomId;
    }
    localStorage.removeItem(TEAMS_STATE_TOKEN);
  } catch {}
};

function randomId(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export { setTeamsStateToken, getTeamsStateToken, randomId };
