import { InfoDialog } from '../common/dialogs/InfoDialog';
import { Typography,Button } from '@sweep-io/sweep-design';

const InitialFetchNoticeDialog = ({closeDialog}:{closeDialog: () => void}) => {

  return (
    <InfoDialog
      handleClose={closeDialog}
      open={true}
      PaperPropsSx={{ minWidth: '370px', width: '370px' }}
      titleJsx="Scanning your environment"
      titleTypographyVariant="h2"
      dialogContentSx={{ pt: '8px' }}
      dialogActions={
        <Button onClick={closeDialog} size="small">
          Got it
        </Button>
      }
    >
      <Typography variant="body">
        We’re currently scanning your Salesforce environment. This may take a few minutes.
        <br />
        <br />
        When scanning is complete, you can create a new agent.
      </Typography>
    </InfoDialog>
  );
}

export default InitialFetchNoticeDialog;
