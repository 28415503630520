import {
  __SWEEP_DESIGN_TYPOGRAPHY_STYLES___,
  colors,
  IconButton,
  TabList,
} from '@sweep-io/sweep-design';
import { EditableLabel, EditableLabelValidationFunction } from '../../../../common/EditableLabel';
import { Box } from '@mui/material';
import { TabContext } from '@mui/lab';
import { Close, MoreActionsVertical } from '@sweep-io/sweep-design/dist/icons';
import { useMemo, useState } from 'react';
import { FunnelDialogTabTypes } from '../../../../../types/enums/FunnelDialogTabTypes';
import { styled } from '@mui/material/styles';
import { ActionsMenu } from '../../../../common/actions-menu/ActionsMenu';
import VersionHistoryTab from './VersionHistoryTab';
import { useSelector } from 'react-redux';
import { selectAssignments } from '../../../../../reducers/global/assignmentReducers';
import { getFunnelAutomations } from '../../../../Automations/helper';
import { selectAutomations } from '../../../../../reducers/global/automationReducers';
import { selectAlerts } from '../../../../../reducers/global/alertsReducers';
import { RightPanelAutomationsTab } from '../stage/RightPanelAutomationsTab';
import { AutomationFormVariant } from '../../../../../types/enums/AutomationFormVariant';
import {
  ConfigurationCanvasRightPanel,
  ConfigurationCanvasPanelContent,
} from '../../../configuration-canvas-panel';
import { AutomationAutomationsContextProvider } from '../../../../alerts/AutomationAutomationsContextProvider';
import { AutomationAssignmentsContextProvider } from '../../../../routing/AutomationAssignmentsContextProvider';
import { AutomationAlertsContextProvider } from '../../../../alerts/AutomationAlertsContextProvider';
import usePermission from '../../../../common/permissions/usePermission';

const StyledPanelTab = styled(Box)({
  marginTop: '36px',
});

const enum FunnelActions {
  Remove = 'Remove Funnel',
}

interface FunnelDialogProps {
  funnel: Funnel;
  closeDialog: () => void;
  readonly: boolean;
  validateFunnelName: EditableLabelValidationFunction;
  onRenameConfirm: (name: string) => void;
  onDescriptionUpdate: (description: string) => void;
  onRemoveFunnel: () => void;
  crmOrgId: string;
  funnelMapId: string;
}

const FunnelDialog = ({
  funnel,
  closeDialog,
  validateFunnelName,
  onRenameConfirm,
  onDescriptionUpdate,
  onRemoveFunnel,
  readonly,
  crmOrgId,
  funnelMapId,
}: FunnelDialogProps) => {
  const [allowFunnelRemoval] = usePermission(['edit:funnel-maps']);

  const [activeTab, setActiveTab] = useState(FunnelDialogTabTypes.AUTOMATIONS);
  const { name, description, funnelDetails, recordType } = funnel;
  const { objectName } = funnelDetails.leadingObject;

  const assignmentsList = useSelector(selectAssignments);
  const automationsList = useSelector(selectAutomations);
  const alertsList = useSelector(selectAlerts);

  const funnelAssignmentsList = useMemo(
    () =>
      getFunnelAutomations({
        funnelId: funnel.id,
        objectName,
        automations: assignmentsList,
        recordType,
      }),
    [assignmentsList, funnel.id, objectName, recordType],
  );
  const funnelAutomationsList = useMemo(
    () =>
      getFunnelAutomations({
        funnelId: funnel.id,
        objectName,
        automations: automationsList,
        recordType,
      }),
    [automationsList, funnel.id, objectName, recordType],
  );
  const funnelAlertsList = useMemo(
    () =>
      getFunnelAutomations({
        funnelId: funnel.id,
        objectName,
        automations: alertsList,
        recordType,
      }),
    [alertsList, funnel.id, objectName, recordType],
  );

  const titleJsx = (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: 1,
          ml: '-10px', //same as the stage dialog, to align the layout
        }}
      >
        <EditableLabel
          readonly={readonly}
          value={name}
          onValueConfirm={onRenameConfirm}
          maxCharLength={40}
          height={36}
          padding={'8px'}
          inputSx={{
            ...__SWEEP_DESIGN_TYPOGRAPHY_STYLES___.h3,
            lineHeight: '36px',
            borderRadius: '6px',
            color: colors.grey[900],
          }}
          errorSx={{
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '15px',
          }}
          validateClbk={async (name: string) => await validateFunnelName(name)}
        />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ActionsMenu
            actions={[
              {
                label: FunnelActions.Remove,
                value: FunnelActions.Remove,
                disabled: readonly || !allowFunnelRemoval,
              },
            ]}
            onClick={(action) => {
              switch (action.value) {
                case FunnelActions.Remove:
                  onRemoveFunnel();
                  break;
                default:
                  break;
              }
            }}
            MenuButtonIcon={<MoreActionsVertical />}
            iconSize="tiny"
          />
          <IconButton onClick={closeDialog} variant="flat" size="tiny">
            <Close />
          </IconButton>
        </Box>
      </Box>
    </>
  );

  const tabs = [
    {
      label: FunnelDialogTabTypes.AUTOMATIONS,
      value: FunnelDialogTabTypes.AUTOMATIONS,
      badgeLabel: String(funnelAutomationsList?.length || ''),
      content: (
        <StyledPanelTab>
          <AutomationAutomationsContextProvider>
            <RightPanelAutomationsTab
              readonly={readonly}
              funnelDetails={funnelDetails}
              automations={funnelAutomationsList}
              onCreateData={{
                newElement: {
                  objectName: funnelDetails?.leadingObject.objectName,
                },
              }}
              automationVariant={AutomationFormVariant.AUTOMATIONS}
            />
          </AutomationAutomationsContextProvider>
        </StyledPanelTab>
      ),
    },
    {
      label: FunnelDialogTabTypes.ALERTS,
      value: FunnelDialogTabTypes.ALERTS,
      badgeLabel: String(funnelAlertsList?.length || ''),
      content: (
        <StyledPanelTab>
          <AutomationAlertsContextProvider>
            <RightPanelAutomationsTab
              automationVariant={AutomationFormVariant.PLAYBOOK_ALERT}
              readonly={readonly}
              funnelDetails={funnelDetails}
              automations={funnelAlertsList}
              onCreateData={{
                newElement: {
                  objectName: funnelDetails?.leadingObject.objectName,
                },
              }}
            />
          </AutomationAlertsContextProvider>
        </StyledPanelTab>
      ),
    },
    {
      label: FunnelDialogTabTypes.ASSIGNMENTS,
      value: FunnelDialogTabTypes.ASSIGNMENTS,
      badgeLabel: String(funnelAssignmentsList?.length || ''),
      content: (
        <StyledPanelTab>
          <AutomationAssignmentsContextProvider>
            <RightPanelAutomationsTab
              automationVariant={AutomationFormVariant.ASSIGNMENT_RULES}
              readonly={readonly}
              funnelDetails={funnelDetails}
              automations={funnelAssignmentsList}
              onCreateData={{
                newElement: {
                  objectName: funnelDetails?.leadingObject.objectName,
                },
              }}
            />
          </AutomationAssignmentsContextProvider>
        </StyledPanelTab>
      ),
    },
    {
      label: FunnelDialogTabTypes.HISTORY,
      value: FunnelDialogTabTypes.HISTORY,
      content: (
        <StyledPanelTab>
          <VersionHistoryTab
            funnel={funnel}
            crmOrgId={crmOrgId}
            funnelMapId={funnelMapId}
            onNavigateCallback={closeDialog}
          />
        </StyledPanelTab>
      ),
    },
  ];
  return (
    <ConfigurationCanvasRightPanel>
      <ConfigurationCanvasPanelContent sx={{ overflowY: 'auto' }}>
        <Box>
          {titleJsx}
          <Box sx={{ display: 'flex', ml: '-10px', width: '100%', overflowX: 'scroll' }}>
            <EditableLabel
              readonly={readonly}
              value={description}
              placeholder="Add description"
              onValueConfirm={onDescriptionUpdate}
              minCharLength={0}
              maxCharLength={256}
              height={36}
              padding={'8px'}
              inputSx={{
                borderRadius: '6px',
                color: colors.grey[900],
                ...__SWEEP_DESIGN_TYPOGRAPHY_STYLES___['body-medium'],
              }}
              errorSx={{
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '15px',
              }}
            />
          </Box>
        </Box>
        <TabContext value={activeTab}>
          <Box
            mt={3}
            sx={{ '.MuiTabPanel-root': { outline: 0 } }} //to solve an issue of an annoying outline that appears when closing the dialog
          >
            <TabList
              variant="scrollable"
              value={activeTab}
              list={tabs}
              onChange={(event: React.SyntheticEvent, val: FunnelDialogTabTypes) =>
                setActiveTab(val)
              }
            />
          </Box>
        </TabContext>
      </ConfigurationCanvasPanelContent>
    </ConfigurationCanvasRightPanel>
  );
};

export default FunnelDialog;
