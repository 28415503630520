import { animate } from 'popmotion';
import { useCallback } from 'react';
import { NodePositionTransformation } from './helpers/nodePositionManagementHelper';
import { useSweepCanvasState } from './internal-context/CanvasStateContext';
import { SWEEP_GRID_SIZE } from './const';
import { keyBy } from 'lodash';
import { createObjectOperations } from './node-changes-event-handlers/objectOperations';
import { RFNodeEditRegularNode } from './canvas-types/nodeTypesData';
import { calculateAndAssignEdgeConnectionTypes } from './assignConnectionTypeToEdges';
import { addXYPositions } from './helpers/xYPositionOperation';

export const useNodeAnimations = () => {
  const { setCanvasNodes, setCanvasEdges, getCanvasNodes } = useSweepCanvasState();

  const withNodeRightShiftAnimation = useCallback(
    ({
      nodeIds,
      onComplete,
      originalNodePositions,
    }: {
      originalNodePositions: NodePositionTransformation[];
      nodeIds: string[];
      onComplete: () => any;
    }) => {
      const originalNodePositionsDict = keyBy(originalNodePositions, 'id');
      return animate({
        to: SWEEP_GRID_SIZE.width,
        duration: 150,
        onUpdate: (latest) => {
          setCanvasNodes((_, nodeOperations) => {
            nodeIds.forEach((nodeId) => {
              nodeOperations.setObject<RFNodeEditRegularNode>(nodeId, (node) => ({
                ...node,
                position: {
                  x: originalNodePositionsDict[node.id].position.x + latest,
                  y: node.position.y,
                },
              }));
            });
            return nodeOperations.getObjects();
          });
        },
        onComplete: () => {
          setTimeout(() => {
            setCanvasEdges((_, edgeOperations) => {
              const nodeOperations = createObjectOperations(getCanvasNodes());

              return calculateAndAssignEdgeConnectionTypes({
                edgeOperations: edgeOperations,
                nodesPositions: nodeOperations.getObjects().map((node) => {
                  const parentGroupPosition = node.parentId
                    ? (nodeOperations.getObject(node.parentId)?.position ?? { x: 0, y: 0 })
                    : { x: 0, y: 0 };

                  return {
                    id: node.id,
                    positionAbsolute: addXYPositions(parentGroupPosition, node.position),
                    parentId: node.parentId,
                  };
                }),
              });
            });
            onComplete();
          }, 5); // On complete is called before the edges are updated so we need to wait for the edges to be updated
        },
      });
    },
    [getCanvasNodes, setCanvasEdges, setCanvasNodes],
  );

  return {
    withNodeRightShiftAnimation,
  };
};
